import * as React from "react";

import { Layout } from "antd";

import * as configurationLib from "@lib/configuration/configuration";

import { ButtonsPortal } from "../molecules/ButtonsPortal";

import { Menu } from "../header/Menu";
import config from "../../config";

import { BreadcrumbsPortal } from "./Breadcrumbs";

const { Header } = Layout;

interface AppHeaderProps {
	onChangePassword: () => void;
	onLogout: () => void;
	isLoggedIn: boolean;
}

export const AppHeader = (props: AppHeaderProps) => {
	return (
		<Header
			style={{
				paddingLeft: 0,
				paddingRight: 0,
				height: "66px",
				backgroundColor:
					configurationLib.DEFAULT_HEADER_BACKGROUND_COLOR,
			}}
		>
			<div
				style={{
					float: "left",
					height: "64px",
					width: "171px",
					backgroundImage: `url('${config.headerImage}')`,
					backgroundSize: "171px 64px",
					backgroundRepeat: "no-repeat",
					color: "#FFFFFF",
					marginLeft: "8px",
					marginRight: "16px",
					marginTop: "2px",
					marginBottom: 0,
				}}
			>
				&nbsp;
			</div>
			<div
				style={{
					float: "left",
				}}
			>
				<BreadcrumbsPortal />
			</div>
			<div
				style={{
					float: "right",
					paddingLeft: "16px",
					paddingRight: "24px",
				}}
			>
				<Menu
					isLoggedIn={props.isLoggedIn}
					logout={props.onLogout}
					handleChangePasswordClick={props.onChangePassword}
				></Menu>
			</div>
			<div
				style={{
					float: "right",
				}}
			>
				<ButtonsPortal />
			</div>
		</Header>
	);
};
