import { Properties } from '@lib/box/box-properties';

export interface BoxDimensions {
	boxWidthInPixels: number,
	boxInternalWidthInPixels: number,
	boxHeightInPixels: number,
	boxInternalHeightInPixels: number,
	boxHorizontalMarginInPixels: number,
	boxVerticalMarginInPixels: number,
	textWidthInPixels: number,
	headerHeightInPixels: number,
	headerFontSizeInPixels: number,
	isHeaderVisible: boolean,
	childrenLeftInPixels: number,
	childrenTopInPixels: number,
	childrenWidthInPixels: number,
	childrenHeightInPixels: number,
	childBoxesWidthInPixels: number,
	childBoxesHeightInPixels: number,
	buttonSizeInPixels: number,
	buttonBorderRadiusInPixels: number,
}

// TODO: Make sure the padding is applied correctly
export const BOX_HORIZONTAL_PADDING_IN_PIXELS: number = 0;
export const BOX_VERTICAL_PADDING_IN_PIXELS: number = 0;

const BOX_BUTTON_SIZE_IN_PIXELS: number = 24;

const HEADER_HEIGHT_IN_PIXELS: number = 24;
const HEADER_VERTICAL_PADDING_IN_PIXELS: number = 4;

export const getBoxDimensions = (boxKey: string,
	boxText: string,
	boxProperties: Properties,
	widthInPixels: number,
	heightInPixels: number,
	horizontalBoxGapInPixels: number,
	verticalBoxGapInPixels: number,
	hasChildren: boolean,
	showHeader: boolean,
	currentlyEditingInPlaceBoxKey: string): BoxDimensions => {
	const boxHorizontalMarginInPixels = horizontalBoxGapInPixels;
	const boxVerticalMarginInPixels = verticalBoxGapInPixels;

	// Get the box width in pixels
	let boxWidthInPixels = widthInPixels;
	if (isNaN(boxWidthInPixels) || boxWidthInPixels < 0) {
		boxWidthInPixels = 0;
	}

	// Calculate the box height in pixels
	let boxHeightInPixels = heightInPixels;
	if (isNaN(boxHeightInPixels) || boxHeightInPixels < 0) {
		boxHeightInPixels = 0;
	}

	// Get the internal width and height of the box in pixels
	let boxInternalWidthInPixels =
		boxWidthInPixels - boxProperties.borderSizeInPixels * 2;
	if (isNaN(boxInternalWidthInPixels) || boxInternalWidthInPixels < 0) {
		boxInternalWidthInPixels = 0;
	}

	let boxInternalHeightInPixels =
		boxHeightInPixels - boxProperties.borderSizeInPixels * 2;
	if (isNaN(boxInternalHeightInPixels) || boxInternalHeightInPixels < 0) {
		boxInternalHeightInPixels = 0;
	}

	// Get the text width and height in pixels
	const textWidthInPixels = boxProperties.textIsVertical
		? boxInternalHeightInPixels
		: boxInternalWidthInPixels;
	const textHeightInPixels = boxProperties.textIsVertical
		? boxInternalWidthInPixels
		: boxInternalHeightInPixels;

	// If we don't have any text, don't show a header unless we're editing it
	const isHeaderVisible = boxText.length > 0 || currentlyEditingInPlaceBoxKey === boxKey;

	// The header height
	let headerHeightInPixels = 0;

	// Is the header visible?
	if (isHeaderVisible) {
		// Set the header height based on whether the box has children
		headerHeightInPixels = hasChildren
			? HEADER_HEIGHT_IN_PIXELS
			: textHeightInPixels;
	}

	// If the header is too tall, clamp its height
	if (headerHeightInPixels > textHeightInPixels) {
		headerHeightInPixels = textHeightInPixels;
	}

	// Calculate the available header height (in pixels)
	let availableHeaderHeightInPixels = headerHeightInPixels - HEADER_VERTICAL_PADDING_IN_PIXELS;
	if (availableHeaderHeightInPixels < 0) {
		availableHeaderHeightInPixels = 0;
	}

	// If we're not showing a header, or the header height is invalid or less
	// than zero, set the height to zero
	if (
		!showHeader ||
		isNaN(headerHeightInPixels) ||
		headerHeightInPixels < 0
	) {
		headerHeightInPixels = 0;
	}

	// Get the header font size
	let headerFontSizeInPixels = boxProperties.textSizeInPixels;

	// If the text size is too big for the box, scale it down
	if (headerFontSizeInPixels > availableHeaderHeightInPixels) {
		headerFontSizeInPixels = availableHeaderHeightInPixels - 4;
	}
	if (isNaN(headerFontSizeInPixels) || headerFontSizeInPixels < 0) {
		boxProperties.textSizeInPixels = 0;
	}

	// Calculate the dimensions (in pixels) of the children
	let childrenLeftInPixels = boxHorizontalMarginInPixels;
	let childrenTopInPixels = headerHeightInPixels + boxVerticalMarginInPixels;
	let childrenWidthInPixels = boxInternalWidthInPixels - childrenLeftInPixels - boxHorizontalMarginInPixels;
	let childrenHeightInPixels = boxInternalHeightInPixels - childrenTopInPixels - boxVerticalMarginInPixels;

	if (boxProperties.textIsVertical) {
		childrenLeftInPixels = headerHeightInPixels + boxHorizontalMarginInPixels;
		childrenTopInPixels = boxVerticalMarginInPixels;
		childrenWidthInPixels = boxInternalWidthInPixels - childrenLeftInPixels - boxHorizontalMarginInPixels;
		childrenHeightInPixels = boxInternalHeightInPixels - childrenTopInPixels - boxVerticalMarginInPixels;
	}

	if (isNaN(childrenWidthInPixels) || childrenWidthInPixels < 0) {
		childrenWidthInPixels = 0;
	}
	if (isNaN(childrenHeightInPixels) || childrenHeightInPixels < 0) {
		childrenHeightInPixels = 0;
	}

	// Get the child boxes width in pixels
	const childBoxesWidthInPixels = childrenWidthInPixels;

	// Calculate the child boxes height in pixels
	const childBoxesHeightInPixels = childrenHeightInPixels;

	// Get the Add/Remove Box buttons size (in pixels)
	const buttonSizeInPixels = BOX_BUTTON_SIZE_IN_PIXELS;

	// Calculate the border radius (in pixels)
	const buttonBorderRadiusInPixels = buttonSizeInPixels / 2;

	const boxDimensions: BoxDimensions = {
		boxWidthInPixels,
		boxInternalWidthInPixels,
		boxHeightInPixels,
		boxInternalHeightInPixels,
		boxHorizontalMarginInPixels,
		boxVerticalMarginInPixels,
		textWidthInPixels,
		headerHeightInPixels,
		headerFontSizeInPixels,
		isHeaderVisible,
		childrenLeftInPixels,
		childrenTopInPixels,
		childrenWidthInPixels,
		childrenHeightInPixels,
		childBoxesWidthInPixels,
		childBoxesHeightInPixels,
		buttonSizeInPixels,
		buttonBorderRadiusInPixels
	}

	return boxDimensions;
}