import React from 'react';

export interface FormHeadingProps { }

const FormHeading: React.FC<FormHeadingProps> = (props) => {
	return <h3
		style={{

			marginTop: "2px",
			paddingTop: "8px",
			paddingBottom: "8px",
			paddingLeft: "12px",
			paddingRight: "8px",
			color: "#202A45",
			backgroundColor: "#F0F0F0",
			display: "flex",
			justifyContent: "space-between",
		}}
	>
		{props.children}
	</h3>;

}
export default FormHeading;