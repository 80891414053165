import { PlusOutlined } from "@ant-design/icons";
import AttributeTypeExpressionTable from "@components/molecules/AttributeTypeExpressionTable";
import LabelledElement from "@components/molecules/LabelledElement";
import FormHeading from "@components/organisms/FormHeading";
import FormHeadingText from "@components/organisms/FormHeadingText";
import { Button, Select } from "antd";
import React from "react";
import useAttributeTypeQueryRestrictions from "../hooks/useAttributeTypeQueryRestrictions";
import * as lensesLib from "@lib/lenses/lenses";
import AddEditAttributeVisibilityDialog from "../AddEditAttributeVisibilityDialog";
import { AttributeTypeMap } from "@lib/box/attribute-type";
import useAttributeTypeVisibilityList, { AttributeTypeVisibilityItem } from "../hooks/useAttributeTypeVisibilityList";
import * as attributeTypeLib from "@lib/box/attribute-type";
import AttributeTypeVisibilityTable from "@components/molecules/AttributeTypeVisibilityTable";

interface AttributeTypeVisibilityListProps {
	attributeTypes: AttributeTypeMap,
	attributeTypeVisibilityMap: attributeTypeLib.AttributeTypeVisibilityMap | undefined,

	isAddEditAttributeTypeVisibilityItemDialogVisible: boolean,
	isAddEditAttributeTypeVisibilityItemDialogAdding: boolean,
	addEditAttributeTypeVisibilityItem: AttributeTypeVisibilityItem | null,

	onDeleteAttributeTypeVisibilityItem: (key: string) => void,
	onAddEditAttributeTypeVisibilityItemFormOk: (attributeTypeVisibilityItem: AttributeTypeVisibilityItem) => void,
	onCloseAddEditAttributeTypeVisibilityForm: () => void,
	onOpenEditAttributeTypeVisibilityForm: (key: string) => void,
	onOpenAddAttributeTypeVisibilityForm: () => void
}

// ## Attribute Type Visibility - List, where you can pick the attribute type and a true/false value

const AttributeTypeVisibilityList: React.FC<AttributeTypeVisibilityListProps> = ({ 
	attributeTypes,
	attributeTypeVisibilityMap,

	isAddEditAttributeTypeVisibilityItemDialogVisible,
	isAddEditAttributeTypeVisibilityItemDialogAdding,
	addEditAttributeTypeVisibilityItem,

	onDeleteAttributeTypeVisibilityItem,
	onAddEditAttributeTypeVisibilityItemFormOk,
	onCloseAddEditAttributeTypeVisibilityForm,
	onOpenEditAttributeTypeVisibilityForm,
	onOpenAddAttributeTypeVisibilityForm
}) => {

	return <>
		<AddEditAttributeVisibilityDialog
			onCancelButtonClick={onCloseAddEditAttributeTypeVisibilityForm}
			isAdding={isAddEditAttributeTypeVisibilityItemDialogAdding}
			isVisible={isAddEditAttributeTypeVisibilityItemDialogVisible}

			attributeTypes={attributeTypes}
			onOKButtonClick={onAddEditAttributeTypeVisibilityItemFormOk}

			attributeTypeVisibilityItem={addEditAttributeTypeVisibilityItem}
		/>
		<FormHeading>
			<FormHeadingText>Attribute Type Visibility</FormHeadingText>
			<div>
				<Button
					icon={<PlusOutlined />}
					type="primary"
					style={{
						paddingRight: "12px",
						marginRight: "12px",
					}}
					onClick={onOpenAddAttributeTypeVisibilityForm}
				>
					Add
				</Button>
			</div>
		</FormHeading>
		<AttributeTypeVisibilityTable
			attributeTypeVisibilityMap={attributeTypeVisibilityMap !== undefined ? attributeTypeVisibilityMap : {}}
			attributeTypes={attributeTypes}
			canDelete={true}
			canEdit={true}
			onDelete={onDeleteAttributeTypeVisibilityItem}
			onEdit={onOpenEditAttributeTypeVisibilityForm}
		/>
	</>;
}

export default AttributeTypeVisibilityList;