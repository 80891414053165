import React, { useEffect, useState } from "react";

import { Modal, Select, Button, Checkbox, Tree } from "antd";

import * as boxTypeLib from "@lib/box/box-type";

import FormHeading from "@components/organisms/FormHeading";
import FormHeadingText from "@components/organisms/FormHeadingText";
import LabelledElement from "@components/molecules/LabelledElement";
import SelectIllustrationDialog from "./SelectIllustrationDialog/SelectIllustrationDialog";
import boxTypeService from "@services/box-type";

const { Option } = Select;

export interface ImportTypesDialogProps {
	onOKButtonClick: (
		boxTypes: boxTypeLib.BoxTypeMap,
		mergeOntoTypes : Array<string> | null
	) => void;
	onCancelButtonClick: () => void;
	isVisible: boolean;
	existingBoxTypes: boxTypeLib.BoxTypeMap | undefined;
}

interface TreeNode {
	title: string;
	key: string;
	children: TreeNode[];
}

const ImportTypesDialog: React.FC<ImportTypesDialogProps> = (props) => {
	// Get the title
	const title = `Import Types`;

	const [showSelectIllustrationDialog, setShowSelectIllustrationDialog] = useState(false);
	const [illustrationId, setIllustrationId] = useState('');

	const [types, setTypes] = useState<boxTypeLib.BoxTypeMap>({});

	const [treeData, setTreeData] = useState<TreeNode[]>([]);
	const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
	const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
	const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
	const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);

	const [mergeOntoExistingTypes, setMergeOntoExistingTypes] = useState<boolean>(false);

	const [mergeTypes, setMergeTypes] = useState<Array<string>>([]);

	// Clear the selected illustration Id when visibility of the form changes
	useEffect(() => {
		setIllustrationId('');
	}, [props.isVisible]);


	useEffect((() => {
		// If we've got a non-blank illustration ID then load the types from the boxTypeService
		if (illustrationId !== "") {
			boxTypeService.getBoxTypesByIllustrationId(illustrationId, (results) => {
				setTypes(results);
				setTreeData(createTreeData(results))
			})
		}
		// Otherwise clear out all the data
		else {
			setTypes({});
			setTreeData([]);
			setExpandedKeys([]);
			setCheckedKeys([]);
			setSelectedKeys([]);
			setMergeTypes([]);
			setMergeOntoExistingTypes(false);
		}
	}), [illustrationId, props.isVisible])

	const handleOKButtonClick = () => {
		// Let's figure out the types that we need to bring back.
		const checkedBoxTypes: boxTypeLib.BoxTypeMap = {};

		// Get a list of all the selected box types and then get a list of all the attributes for those box types
		// We can then add the box type and delete any attributes that aren't in the resulting list.

		checkedKeys.forEach((selectedKey, index) => {
			const splitStrings = selectedKey.toString().split('_');

			const isBoxType = splitStrings.length === 1;

			const isAttributeType = splitStrings.length === 2;

			if (isBoxType || isAttributeType) {
				const boxTypeKey = splitStrings[0];

				// Check to see if the box type has already been added
				const boxTypeAlreadyProcessed = Object.keys(checkedBoxTypes).findIndex((value) => boxTypeKey === value) > -1;

				// If the box type hasn't been processed
				if (!boxTypeAlreadyProcessed) {
					// Get a copy of the type
					const newBoxTypeCopy: boxTypeLib.BoxType = JSON.parse(JSON.stringify(types[boxTypeKey]));

					// Clear the attribute types
					newBoxTypeCopy.attributeTypes = {};

					// Add the box type to our list
					checkedBoxTypes[boxTypeKey] = newBoxTypeCopy;
				}

				if (isAttributeType) {
					const attributeTypeKey = splitStrings[1];

					// Add the attribute to the box type that's already in our list
					checkedBoxTypes[boxTypeKey].attributeTypes[attributeTypeKey] = JSON.parse(JSON.stringify(types[boxTypeKey].attributeTypes[attributeTypeKey]));
				}
			}
		});

		const mergeOntoTypesKeyList = mergeOntoExistingTypes ? mergeTypes : null;

		// Notify the callback that the OK button has been clicked 
		props.onOKButtonClick(checkedBoxTypes, mergeOntoTypesKeyList);
	};

	const createTreeData = (boxTypes: boxTypeLib.BoxTypeMap): TreeNode[] => {
		return Object.keys(boxTypes).map((key, index) => {
			const boxType: boxTypeLib.BoxType = boxTypes[key];

			const children = Object.keys(boxType.attributeTypes).map((attributeTypeKey, index) => {
				const attributeType = boxType.attributeTypes[attributeTypeKey];
				return { title: attributeType.name, key: `${key}_${attributeTypeKey}`, children: [] };
			});

			return {
				title: boxType.name, key: key,
				children
			};
		});
	}

	const onExpand = (expandedKeysValue: React.Key[]) => {
		// if not set autoExpandParent to false, if children expanded, parent can not collapse.
		// or, you can remove all expanded children keys.
		setExpandedKeys(expandedKeysValue);
		setAutoExpandParent(false);
	};

	const onCheck = (checkedKeysValue: any) => {
		setCheckedKeys(checkedKeysValue);
	};

	const onSelect = (selectedKeysValue: any, info: any) => {
		setSelectedKeys(selectedKeysValue);
	};
	return (
		<Modal
			title={title}
			open={props.isVisible}
			onOk={handleOKButtonClick}
			onCancel={props.onCancelButtonClick}
			zIndex={9999}
			width="75%"
		>
			<FormHeading>
				<FormHeadingText>Import Types from Illustration</FormHeadingText>
				<Button type="primary" onClick={() => { setShowSelectIllustrationDialog(true) }}>Select Illustration</Button>
			</FormHeading>
			<div><b>Illustration Id:</b> {illustrationId}</div>
			<Tree
				checkable
				onExpand={onExpand}
				expandedKeys={expandedKeys}
				autoExpandParent={autoExpandParent}
				onCheck={onCheck}
				checkedKeys={checkedKeys}
				onSelect={onSelect}
				selectedKeys={selectedKeys}
				treeData={treeData}
			/>

			{illustrationId !== '' && props.existingBoxTypes !== undefined ? <>

				<div
					style={{
						width: "100%",
						display: "grid",
						gridTemplateColumns: "1fr",
						gap: "4px 12px",
						padding: 0,
						margin: 0,
						overflowY: "visible",
					}}
				>
					<br />
					<div>
						<b>Merge Attributes Onto Existing Type(s)?</b> &nbsp;
						<Checkbox checked={mergeOntoExistingTypes} onChange={(e)=>{
							setMergeOntoExistingTypes(e.target.checked);
						}} /></div>

					{mergeOntoExistingTypes ? 
					<LabelledElement labelText="Select Types to Merge Onto">
						<Select
							size="large"
							placeholder="Please select" mode={'multiple'}
							style={{ width: '100%' }}
							getPopupContainer={(node) => {
								let popupContainer: HTMLElement | null =
									window.document.documentElement;
								if (node && node.parentElement) {
									popupContainer = node.parentElement;
								}
								return (popupContainer as HTMLElement);
							}}

							filterOption={(inputValue, option) => {
								const optionBox = props.existingBoxTypes !== undefined && option && option.value !== null && option.value !== undefined ? props.existingBoxTypes[option.value] : null;
		
								// Note: some of the box names are numbers!
								// Convert it to a string just to be sure
								const optionBoxNameAsString = optionBox != null &&
									optionBox.name !== undefined &&
									optionBox.name !== null ? String(optionBox.name) : "";
		
								return optionBoxNameAsString.toLowerCase().includes(inputValue.toLowerCase());
							}}

							value={mergeTypes}

							onChange={(value) => {
								setMergeTypes(value);
							}}
						>
							{Object.keys(props.existingBoxTypes).map((key) => {
								// This is needed because of TypeScript's strict mode
								// commplaining that this value could be undefined
								if (props.existingBoxTypes !== undefined) {
									const boxType = props.existingBoxTypes[key];
									return <Option key={key} value={key}>{boxType.name}</Option>
								}
								else {
									return <Option key={key} value={1}>No Type Found</Option>
								}
							})}
						</Select>
					</LabelledElement> : null }
				</div>
			</> : null}
			<SelectIllustrationDialog isVisible={showSelectIllustrationDialog}
				onCancelButtonClick={() => { setShowSelectIllustrationDialog(false) }}
				onOKButtonClick={(id) => { setIllustrationId(id); setShowSelectIllustrationDialog(false) }}
			/>
		</Modal>);
};

export default ImportTypesDialog;