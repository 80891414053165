import * as lensesLib from "@lib/lenses/lenses";
import { useState, useEffect } from "react";
import { AttributeTypeExpression } from "../AddEditAttributeTypeExpressionDialog";


// ## Attribute Type Expressions - List, where you can pick the operator
// - attributeTypeExpressions - list
// - attributeTypeExpressionOperator - dropdown

const useAttributeTypeQueryRestrictions = (query: lensesLib.Query) => {
	
	const [attributeTypeExpressions, setAttributeTypeExpressions] = useState<lensesLib.AttributeTypeExpressionMap | undefined>(query.attributeTypeExpressions)
	const [attributeTypeExpressionOperator, setAttributeTypeExpressionOperator] = useState(query.attributeTypeExpressionOperator);
	// Add/Edit Attribute Type Expression Form Variables
	const [isAddEditAttributeTypeExpressionDialogVisible, setIsAddEditAttributeTypeExpressionDialogVisible] = useState(false);
	const [isAddEditAttributeTypeExpressionDialogAdding, setIsAddEditAttributeTypeExpressionDialogAdding] = useState(false);
	const [addEditAttributeTypeExpression, setAddEditAttributeTypeExpression] = useState<AttributeTypeExpression | null>(null);

	useEffect(() => {
		setAttributeTypeExpressions(query.attributeTypeExpressions)
		setAttributeTypeExpressionOperator(query.attributeTypeExpressionOperator);
	
		// Reset the form values		
		setIsAddEditAttributeTypeExpressionDialogVisible(false);
		setIsAddEditAttributeTypeExpressionDialogAdding(false);
		setAddEditAttributeTypeExpression(null);
	}, [query])

	const onAttributeTypeExpressionOperatorChanged = (value: lensesLib.BooleanOperator) => {
		setAttributeTypeExpressionOperator(value);
	};

	const onOpenAddAttributeTypeExpressionForm = () => {
		setIsAddEditAttributeTypeExpressionDialogAdding(false);
		setAddEditAttributeTypeExpression(null);
		setIsAddEditAttributeTypeExpressionDialogVisible(true);
	}

	const onOpenEditAttributeTypeExpressionForm = (key: string) => {
		if (attributeTypeExpressions !== undefined) {
			setAddEditAttributeTypeExpression({ key, value: attributeTypeExpressions[key] })
			setIsAddEditAttributeTypeExpressionDialogAdding(false);
			setIsAddEditAttributeTypeExpressionDialogVisible(true);
		}
	}

	const onCloseAddEditAttributeTypeExpressionForm = () => {
		setIsAddEditAttributeTypeExpressionDialogVisible(false);
		setIsAddEditAttributeTypeExpressionDialogAdding(false);
		setAddEditAttributeTypeExpression(null);
	};

	const onAddEditAttributeTypeExpressionFormOk = (attributeTypeExpression: AttributeTypeExpression) => {
		const newAttributeTypeExpressions = attributeTypeExpressions !== undefined && attributeTypeExpressions !== null ? JSON.parse(JSON.stringify(attributeTypeExpressions)) : {};

		// If we have changed the key, delete the old value)
		if (addEditAttributeTypeExpression !== null && addEditAttributeTypeExpression.key !== attributeTypeExpression.key) {
			delete newAttributeTypeExpressions[addEditAttributeTypeExpression.key];
		}

		newAttributeTypeExpressions[attributeTypeExpression.key] = attributeTypeExpression.value;
		setAttributeTypeExpressions(newAttributeTypeExpressions)
		setIsAddEditAttributeTypeExpressionDialogVisible(false);
		setAddEditAttributeTypeExpression(null);
	};


	const onDeleteAttributeTypeExpression = (key: string) => {
		const newAttributeTypeExpressions = JSON.parse(JSON.stringify(attributeTypeExpressions));
		delete newAttributeTypeExpressions[key];
		setAttributeTypeExpressions(newAttributeTypeExpressions);
	};


	const onParentFormOk = (query: lensesLib.Query) => {
		return query;
	};

	return {
		attributeTypeExpressions,
		attributeTypeExpressionOperator,

		isAddEditAttributeTypeExpressionDialogVisible,
		isAddEditAttributeTypeExpressionDialogAdding,
		addEditAttributeTypeExpression,

		onAttributeTypeExpressionOperatorChanged,
		onDeleteAttributeTypeExpression,
		onAddEditAttributeTypeExpressionFormOk,
		onCloseAddEditAttributeTypeExpressionForm,
		onOpenEditAttributeTypeExpressionForm,
		onOpenAddAttributeTypeExpressionForm,

		onParentFormOk
	};
}

export default useAttributeTypeQueryRestrictions;