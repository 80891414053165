import { v4 as uuid } from 'uuid';

import * as boxTypeLib from './box-type';
import * as boxLib from './box';
import * as lensesLib from '../lenses/lenses';

export interface VisibilityState {
  // The name of the visibilty state
  name: string,

  // The box type visibility map
  boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap,

  // The box visibility map
  boxVisibilityMap: boxLib.BoxVisibilityMap,

  // The lens visibility map
  lensVisibilityMap: lensesLib.LensVisibilityMap,

  // The lens group visibility map
  lensGroupVisibilityMap: lensesLib.LensGroupVisibilityMap,
}

export interface VisibilityStateMap {
  [key: string]: VisibilityState
}

export const getNewVisibilityStateKey = () => uuid();

// Hide a box type, including all its attribute and mixin box types.
const hideBoxTypeVisibility = (v: boxTypeLib.BoxTypeVisibility): void => {
	v.areBoxesInLayout = false;
	v.areBoxesVisible = false;
	v.isVisible = false;
	const { attributeTypeVisibilityMap, mixinBoxTypeVisibilityMap } = v;  
	if (attributeTypeVisibilityMap) {
		Object
			.keys(attributeTypeVisibilityMap)
			.forEach((attributeBoxTypeKey: string) => {
				attributeTypeVisibilityMap[attributeBoxTypeKey] = false;
			});
	}

	if (mixinBoxTypeVisibilityMap) {
		Object
		.keys(mixinBoxTypeVisibilityMap)
		.forEach((mixinBoxTypeKey: string) => {
			const mixinBoxTypeVisibility = mixinBoxTypeVisibilityMap[mixinBoxTypeKey];
			hideBoxTypeVisibility(mixinBoxTypeVisibility);
		});		
	}
}

// If a key does not exist in the destination, copy the box type visibility from
// the source and hide it.
const hideBoxTypeVisibilityIfKeyDoesNotExist = (destination: boxTypeLib.BoxTypeVisibilityMap,
	source: boxTypeLib.BoxTypeVisibilityMap,
	key: string): void => {
	if(!Object.prototype.hasOwnProperty.call(destination, key)) {
		const destinationBoxTypeVisibility = JSON.parse(JSON.stringify(source[key])) as boxTypeLib.BoxTypeVisibility;

		hideBoxTypeVisibility(destinationBoxTypeVisibility);

		destination[key] = destinationBoxTypeVisibility;
	}
}

const hideBoxTypeVisibilitiesIfKeysDoNotExist = (destination: boxTypeLib.BoxTypeVisibilityMap,
	source: boxTypeLib.BoxTypeVisibilityMap): void => {
	Object
		.keys(source)
		.forEach((key: string) => {
			hideBoxTypeVisibilityIfKeyDoesNotExist(destination, source, key);
		});
}

// If a key does not exist in the destination, hide the box.
const showBoxVisibilityIfKeyDoesNotExist = (destination: boxLib.BoxVisibilityMap,
	key: string): void => {
	if(!Object.prototype.hasOwnProperty.call(destination, key)) {
		destination[key] = { isVisible: true, isInLayout: true };
	}
}

const showBoxVisibilitiesIfKeysDoNotExist = (destination: boxLib.BoxVisibilityMap,
	source: boxLib.BoxVisibilityMap): void => {
	Object
		.keys(source)
		.forEach((key: string) => {
			showBoxVisibilityIfKeyDoesNotExist(destination, key);
		});
}

// If a key does not exist in the destination, hide the lens.
const hideLensVisibilityIfKeyDoesNotExist = (destination: lensesLib.LensVisibilityMap,
	key: string): void => {
	if(!Object.prototype.hasOwnProperty.call(destination, key)) {
		destination[key] = false;
	}
}

const hideLensVisibilitiesIfKeysDoNotExist = (destination: lensesLib.LensVisibilityMap,
	source: lensesLib.LensVisibilityMap): void => {
	Object
		.keys(source)
		.forEach((key: string) => {
			hideLensVisibilityIfKeyDoesNotExist(destination, key);
		});
}

// If a key does not exist in the destination, hide the lens group.
const hideLensGroupVisibilityIfKeyDoesNotExist = (destination: lensesLib.LensGroupVisibilityMap,
	key: string): void => {
	if(!Object.prototype.hasOwnProperty.call(destination, key)) {
		destination[key] = false;
	}
}

const hideLensGroupVisibilitiesIfKeysDoNotExist = (destination: lensesLib.LensGroupVisibilityMap,
	source: lensesLib.LensVisibilityMap): void => {
	Object
		.keys(source)
		.forEach((key: string) => {
			hideLensGroupVisibilityIfKeyDoesNotExist(destination, key);
		});
}

// Makes sure there's an entry in the next visibility state for every entry in
// the previous one. If an entry doesn't exist, it will be added and set to the
// state of the previous visibility. 
export const syncNextVisibilityState = (previousVisibilityState: VisibilityState,
	nextVisibilityState: VisibilityState): VisibilityState => {
	console.log('syncNextVisibilityState');
	console.log(previousVisibilityState);
	console.log(nextVisibilityState);

	const synchedVisibilityState = JSON.parse(JSON.stringify(nextVisibilityState)) as VisibilityState;

	if(!synchedVisibilityState.boxTypeVisibilityMap) {
		synchedVisibilityState.boxTypeVisibilityMap = {};
	}
	if(!synchedVisibilityState.boxVisibilityMap) {
		synchedVisibilityState.boxVisibilityMap = {};
	}
	if(!synchedVisibilityState.lensVisibilityMap) {
		synchedVisibilityState.lensVisibilityMap = {};
	}
	if(!synchedVisibilityState.lensGroupVisibilityMap) {
		synchedVisibilityState.lensGroupVisibilityMap = {};
	}

	hideBoxTypeVisibilitiesIfKeysDoNotExist(synchedVisibilityState.boxTypeVisibilityMap,
		previousVisibilityState.boxTypeVisibilityMap);
	showBoxVisibilitiesIfKeysDoNotExist(synchedVisibilityState.boxVisibilityMap,
		previousVisibilityState.boxVisibilityMap);
	hideLensVisibilitiesIfKeysDoNotExist(synchedVisibilityState.lensVisibilityMap,
		previousVisibilityState.lensVisibilityMap);
	hideLensGroupVisibilitiesIfKeysDoNotExist(synchedVisibilityState.lensGroupVisibilityMap,
		previousVisibilityState.lensGroupVisibilityMap);

	return synchedVisibilityState;
}