import * as React from "react";

import { BoxSelectionInfoMap } from "@lib/box/box";

export interface BoxSelectionProps {
	isSelected: boolean;

	boxKey: string;
	boxSelectionInfoMap?: BoxSelectionInfoMap

	boxInternalWidthInPixels: number;
	boxInternalHeightInPixels: number;

	borderRadiusInPixels: number;
}

export const BoxSelection = (props: BoxSelectionProps) => {
	const {
		isSelected,
		boxKey,
		boxSelectionInfoMap,
		boxInternalWidthInPixels,
		boxInternalHeightInPixels,
		borderRadiusInPixels,
	} = props;

	if (!isSelected || !boxSelectionInfoMap) {
		return null
	}

	// Get the box selection color
	const boxSelectionColor = boxSelectionInfoMap[boxKey].color;

	// Get the selection dimensions
	const selectionWidthInPixels = boxInternalWidthInPixels / 2;
	const selectionHeightInPixels = boxInternalHeightInPixels / 2;

	const selectionLeftInPixels = selectionWidthInPixels / 2;
	const selectionTopInPixels = selectionHeightInPixels / 2;

	// Calculate the text height
	const textHeightInPixels = selectionHeightInPixels / 4;

	// Calculate the line height
	const lineHeightInPixels =
		selectionHeightInPixels - textHeightInPixels / 2;

	// Render the selection
	return (
		<div
			style={{
				display: "table-cell",
				position: "absolute",
				left: selectionLeftInPixels,
				top: selectionTopInPixels,
				width: selectionWidthInPixels,
				height: selectionHeightInPixels,
				lineHeight: `${lineHeightInPixels}px`,
				color: "#FFF",
				backgroundColor: boxSelectionColor,
				textAlign: "center",
				verticalAlign: "middle",
				fontSize: `${textHeightInPixels}px`,
				borderRadius: borderRadiusInPixels,
			}}
		>
			{"\u2713"}
		</div>
	);
}