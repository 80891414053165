import * as React from "react";

import {
	Modal,
	Button,
	Layout,
	Spin,
	Tooltip,
	notification
} from "antd";
import {
	LoadingOutlined,
	PrinterOutlined,
	RedoOutlined,
	SaveOutlined,
	SettingOutlined,
	ToolOutlined,
	UndoOutlined,
	ExclamationCircleOutlined,
	AppstoreOutlined,
	BorderOutlined,
	ShareAltOutlined,
	LinkOutlined,
	StopOutlined,
	DeleteOutlined,
	CheckOutlined,
	EyeOutlined,
	FullscreenOutlined,
	FullscreenExitOutlined
} from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import ReactToPrint, { PrintContextConsumer } from "react-to-print";

import produce, { Patch, enablePatches, applyPatches, setAutoFreeze } from "immer";

import { SizeMe, SizeMeProps } from "react-sizeme";

import { v4 as uuid } from "uuid";

import screenfull, { Screenfull } from "screenfull";

import * as boxTypeLib from "@lib/box/box-type";
import * as boxTypeCounterLib from "@lib/box/box-type-counter";
import * as lensesLib from "@lib/lenses/lenses";
import * as boxLib from "@lib/box/box";
import * as attributeTypeLib from "@lib/box/attribute-type";
import * as valueTypeLib from "@lib/box/value-type";
import * as visibilityStateLib from "@lib/box/visibility-state";
import { setDefaultBoxTypeVisibilities } from '@lib/box/box-type';

import * as illustrationLib from "@lib/illustration/illustration";
import * as configurationLib from "@lib/configuration/configuration";

import * as authorizationService from "../../../services/authorization";
import illustrationService from "../../../services/illustration";
import clientService from "../../../services/client";
import projectService from "../../../services/project";

import { Illustration } from "../../illustration/Illustration";

import { ViewHistoryDialog } from "../../organisms/ViewHistoryDialog";
import { EditBoxDialog } from "../../organisms/EditBoxDialog";
import AddEditBoxTypeDialog from "./edit-mode/add-edit-box-type-dialog/AddEditBoxTypeDialog";
import { AddEditVisibilityStateDialog } from "./AddEditVisibilityStateDialog";
import { ImportExportIllustrationDialog } from "./edit-mode/ImportExportIllustrationDialog";
import { ImportExportIllustrationExcelDialog } from "./edit-mode/ImportExportIllustrationExcelDialog";

import AddEditIllustrationDialog from "./AddEditIllustrationDialog";
import CreateAssociationsDialog from "./edit-mode/CreateAssociationsDialog";
import { MultiEditBoxesDialog } from "./edit-mode/MultiEditBoxesDialog";
import ViewBoxDialog from "./view-mode/ViewBoxDialog";

import { renderBreadcrumbsInPortal } from "../../organisms/Breadcrumbs";
import { renderButtonsInPortal } from "../../molecules/ButtonsPortal";

import CreateSimpleAssociationsDialog from "./edit-mode/CreateSimpleAssociationsDialog";
import ViewMenu from "./view-mode/ViewMenu";
import ConfigurationMenu from "./edit-mode/ConfigurationMenu";
import JsonEditorMenu from "./edit-mode/JsonEditorMenu";
import IllustrationDetailsMenu from "./edit-mode/IllustrationDetailsMenu";
import TypesMenu from "./edit-mode/TypesMenu";
import AssociationsMenu from "./edit-mode/AssociationsMenu";
import StoriesMenu from "./edit-mode/StoriesMenu";
import ImportExportMenu from "./edit-mode/ImportExportMenu";
import SimpleLensesMenu from "./edit-mode/SimpleLensesMenu";
import { AttributeTypeVisibilityMap } from "@lib/box/attribute-type";
import { getUrlWithPrefix, openInNewTab } from "@lib/url";

import { withRouter } from '../../../routes/withRouter';
import PowerLensesMenu from "./edit-mode/PowerLensesMenu";
import PowerLensGroupsMenu from "./edit-mode/PowerLensGroupsMenu";
import { DragSource } from "react-dnd";
import features from "../../../config/features";
import { AssociationToEnable } from "./edit-mode/CreateAutomaticFilterChildBoxes";

const confirm = Modal.confirm;

// Turn off AutoFreeze for Immer
setAutoFreeze(false);

// Used to toggle fullscreen mode.
const fullscreen = screenfull.isEnabled
	? (screenfull as Screenfull)
	: undefined;

// The time we wait (in milliseconds) before showing that we're busy
const BUSY_DELAY_IN_MILLISECONDS = 0;

// The time (in milliseconds) to wait before showing what the result of a drag
// and drop would look like.
const DRAG_AND_DROP_SHOW_DELAY_IN_MILLISECONDS = 500;

export interface Bookmark {
	boxKey: string;
	name: string;
	smartPageKey: string;
	bookmarkOrder: number;
}

export interface BookmarkMap {
	[key: string]: Bookmark;
}

export interface CurrentIllustrationPageProps {
	match: {
		params: {
			illustrationId: string;
			clientId: string;
			projectId: string;
		};
	};
	history: any;
	abilities: authorizationService.AbilitiesMap | undefined;
}

const ILLUSTRATION_STATE_ENTER_FULLSCREEN_ICON = "fullscreen";
const ILLUSTRATION_STATE_EXIT_FULLSCREEN_ICON = "fullscreen-exit";


const SELECTED_BOX_COLOR = "rgba(72, 108, 132, 0.75)";
const FIRST_SELECTED_BOX_COLOR = "rgba(151, 215, 61, 0.75)";

const DEFAULT_VISIBILITY_STATE_KEY = "default";

const toggleFullscreen = () => {
	if (fullscreen) {
		fullscreen.toggle();
	}
};

const getCurrentVisibilityStateKey = (illustrationData: any) => {
	const currentVisibilityStateKey =
		illustrationData && illustrationData.currentVisibilityStateKey
			? illustrationData.currentVisibilityStateKey
			: DEFAULT_VISIBILITY_STATE_KEY;

	return currentVisibilityStateKey;
};

const getCurrentVisibilityState = (
	currentVisibilityStateKey: string,
	illustrationData: any
): visibilityStateLib.VisibilityState => {
	let currentVisibilityState: visibilityStateLib.VisibilityState = {
		name: "Default",
		boxTypeVisibilityMap: {},
		boxVisibilityMap: {},
		lensVisibilityMap: {},
		lensGroupVisibilityMap: {}
	};

	if (illustrationData && illustrationData.visibilityStateMap) {
		currentVisibilityState =
			illustrationData.visibilityStateMap[currentVisibilityStateKey];
	}

	return currentVisibilityState;
};

export interface CurrentIllustrationPageState {
	// Whether the page is fullscreen
	isFullscreen: boolean;

	// Whether the page is busy. If the page is busy and a certain period of time
	// has elapsed, we'll display a progress bar
	isBusy: boolean;

	// The state of the illustration
	illustrationState: illustrationLib.IllustrationState;

	// The client
	client: any;

	// The project
	project: any;

	// The loaded illustration
	illustration: any;

	// The data of the illustration
	illustrationData: illustrationLib.Illustration | undefined;

	// The flattened box map
	flattenedBoxMap: boxLib.BoxMap | undefined;

	// The box parent map
	boxParentMap: boxLib.BoxParentMap;
	// The lensPage map
	lensPageMap: BookmarkMap | undefined;

	// The lens box type and box visibility maps
	lensBoxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap;
	lensBoxVisibilityMap: boxLib.BoxVisibilityMap;

	// The box associations map
	boxAssociationsMap: boxLib.BoxAssociationsMap;

	// The box selection info map
	boxSelectionInfoMap: boxLib.BoxSelectionInfoMap;

	// The key of the first box selected
	firstSelectedBoxKey: string | undefined;

	// The keys of the currently drag source and drop target boxes
	currentDragSourceBoxKey: string;
	currentlyHighlightedDropTargetBoxKey: string;

	// The initial illustration data to use calculate the result of dragging-and-dropping.
	initialDragAndDropIllustrationData: illustrationLib.Illustration | undefined;

	// The illustration data to use to represent the result of dragging-and-dropping.
	dragAndDropIllustrationData: illustrationLib.Illustration | undefined;

	// The key of the fake box in the drag-and-drop illustration data.
	dragAndDropIllustrationBoxKey: string;

	// A copy of the box being dragged.
	dragAndDropBox: boxLib.Box | undefined;

	// The timeout used to update the state of the illustration when dragging
	// and dropping
	dragAndDropTimeout: NodeJS.Timeout | undefined;

	// Whether a box is being dragged.
	isBoxDragging: boolean;

	// Import/export model
	isImportExportIllustrationModalVisible: boolean;
	isImportExportIllustrationModalImporting: boolean;

	// Import/export to Excel
	isImportExportIllustrationExcelModalVisible: boolean;
	isImportExportIllustrationExcelModalImporting: boolean;

	// Edit box modal
	isEditBoxModalVisible: boolean;
	editBoxModalBoxKey: string;
	editBoxModalBox: boxLib.Box | undefined;

	// Add/edit box type modal
	isAddEditBoxTypeModalAdding: boolean;
	isAddEditBoxTypeModalVisible: boolean;
	addEditBoxTypeModalBoxTypeKey: string;

	// Add/edit visibility state modal
	isAddEditVisibilityStateModalAdding: boolean;
	isAddEditVisibilityStateModalVisible: boolean;
	addEditVisibilityStateModalVisibilityStateKey: string;

	// View history modal
	isViewHistoryModalVisible: boolean;

	// Edit illustration modal
	editIllustrationModalIllustration: any;
	isEditIllustrationModalVisible: boolean;

	// Association modal
	isCreateAssociationsModalVisible: boolean;

	// Simple Association modal
	isSimpleCreateAssociationsModalVisible: boolean;

	// Multi-edit boxes dialog
	isMultiEditBoxesModalVisible: boolean;

	// View Boxdialog
	isViewBoxModalVisible: boolean;
	viewBoxModalBox: boxLib.Box | undefined;
	viewBoxModalBoxKey: string;
	viewBoxModalSmartPageKey: string;

	// The box type counters
	boxTypeCounters: boxTypeCounterLib.BoxTypeCounters;

	// Used to support undo/redo
	undos: Patch[][];
	redos: Patch[][];
}

class CurrentIllustrationPageC extends React.Component<
	CurrentIllustrationPageProps,
	CurrentIllustrationPageState
> {
	private illustrationComponentRef: Illustration | null = null;

	private lastDragX: number = -1;
	private lastDragY: number = -1;

	// Used for instance access to the updated drag-and-drop state, before the
	// delayed state update
	private dragAndDropIllustrationData: illustrationLib.Illustration | undefined;
	private dragAndDropIllustrationBoxKey: string = '';
	private dragAndDropIllustrationBox: boxLib.Box | undefined = undefined;

	constructor(props: any) {
		super(props);
		// console.log('CurrentIllustrationPage')
		// console.log(props)

		// Determine whether the browser is fullscreen.
		const isFullscreen = fullscreen ? fullscreen.isFullscreen : false;

		this.getClientId = this.getClientId.bind(this);
		this.getProjectId = this.getProjectId.bind(this);

		// Get the client ID
		const clientId = this.getClientId();

		// Determine the illustration state, defaulting to 'view'
		let illustrationState = illustrationLib.IllustrationState.VIEW;

		if (
			authorizationService.isAbilityPermitted(
				this.props.abilities,
				authorizationService.AbilityType.IllustrationBoxesEdit,
				clientId
			)
		) {
			// If we can edit boxes, default to 'edit' state
			illustrationState = illustrationLib.IllustrationState.EDIT_BOX;
		}

		this.state = {
			isFullscreen,
			// Initially we're busy, as we're going to be loading an illustration
			isBusy: true,

			illustrationState,

			illustration: undefined,
			illustrationData: undefined,

			isImportExportIllustrationModalVisible: false,
			isImportExportIllustrationModalImporting: false,

			isImportExportIllustrationExcelModalVisible: false,
			isImportExportIllustrationExcelModalImporting: false,

			isEditBoxModalVisible: false,
			editBoxModalBoxKey: '',
			editBoxModalBox: undefined,

			isAddEditBoxTypeModalAdding: false,
			isAddEditBoxTypeModalVisible: false,
			addEditBoxTypeModalBoxTypeKey: '',

			isAddEditVisibilityStateModalAdding: false,
			isAddEditVisibilityStateModalVisible: false,
			addEditVisibilityStateModalVisibilityStateKey: '',

			isViewHistoryModalVisible: false,

			currentDragSourceBoxKey: '',
			currentlyHighlightedDropTargetBoxKey: '',
			initialDragAndDropIllustrationData: undefined,
			dragAndDropIllustrationData: undefined,
			dragAndDropIllustrationBoxKey: '',
			dragAndDropBox: undefined,
			dragAndDropTimeout: undefined,
			isBoxDragging: false,

			flattenedBoxMap: {},
			boxParentMap: {},
			lensPageMap: {},
			lensBoxTypeVisibilityMap: {},
			lensBoxVisibilityMap: {},
			boxAssociationsMap: {},
			boxSelectionInfoMap: {},
			firstSelectedBoxKey: undefined,

			client: {},
			project: {},

			editIllustrationModalIllustration: null,
			isEditIllustrationModalVisible: false,

			isCreateAssociationsModalVisible: false,

			isSimpleCreateAssociationsModalVisible: false,

			isMultiEditBoxesModalVisible: false,
			isViewBoxModalVisible: false,
			viewBoxModalBoxKey: '',
			viewBoxModalBox: undefined,
			viewBoxModalSmartPageKey: '',

			boxTypeCounters: {},

			// Used to support undo/redo
			undos: [],
			redos: [],
		};
	}

	getProjectId() {
		return this.props.match.params.projectId;
	}

	getClientId() {
		return this.props.match.params.clientId;
	}

	getUpdatedStateForIllustrationData = (
		illustrationData: illustrationLib.Illustration | undefined,
		currentIllustrationData: illustrationLib.Illustration | undefined,
		currentFlattenedBoxMap: boxLib.BoxMap | undefined,
		currentVisibilityState: visibilityStateLib.VisibilityState,
	) => {
		if (illustrationData === undefined) {
			return null;
		}

		// The updated state
		let updatedState: any = {};

		// Do we have any illustration data?
		if (illustrationData) {
			const boxTypes = illustrationData.boxTypes
				? illustrationData.boxTypes
				: {};
			const boxes = illustrationData.boxes ? illustrationData.boxes : {};

			// Get the box type keys
			const boxTypeKeys = Object.keys(boxTypes);

			// Get a flattened box map
			const flattenedBoxMap = boxLib.getFlattenedBoxMap(boxes, {});

			// Get the box parent map
			const updatedBoxParentMap: boxLib.BoxParentMap = {};
			boxLib.setBoxParents(updatedBoxParentMap, "root", flattenedBoxMap);

			// Get the box keys
			const boxKeys = flattenedBoxMap ? Object.keys(flattenedBoxMap) : [];

			// Update the box type visibility map
			const updatedBoxTypeVisibilityMap =
				currentIllustrationData &&
					currentVisibilityState &&
					currentVisibilityState.boxTypeVisibilityMap &&
					Object.keys(currentVisibilityState.boxTypeVisibilityMap)
						.length > 0
					? boxTypeLib.getUpdatedBoxTypeVisibilityMapForBoxTypes(
						currentVisibilityState.boxTypeVisibilityMap,
						illustrationData.boxTypes,
						currentIllustrationData.boxTypes
					)
					: boxTypeLib.buildBoxTypeVisibilityMap(
						boxTypes,
						boxTypeKeys
					);

			// Update the box visibility map
			const updatedBoxVisibilityMap =
				currentFlattenedBoxMap &&
					currentVisibilityState &&
					currentVisibilityState.boxVisibilityMap &&
					Object.keys(currentVisibilityState.boxVisibilityMap).length > 0
					? boxLib.getUpdatedBoxVisibilityMapForBoxes(
						currentVisibilityState.boxVisibilityMap,
						flattenedBoxMap,
						currentFlattenedBoxMap
					)
					: boxLib.buildBoxVisibilityMap(flattenedBoxMap, boxKeys);

			// Update the lens visibility map
			const updatedLensVisibilityMap =
				currentIllustrationData &&
					currentVisibilityState &&
					currentVisibilityState.lensVisibilityMap &&
					Object.keys(currentVisibilityState.lensVisibilityMap).length > 0
					? lensesLib.getUpdatedLensVisibilityMapForLenses(
						currentVisibilityState.lensVisibilityMap,
						{ ...illustrationData.lenses, ...illustrationData.simpleLenses },
						{ ...currentIllustrationData.lenses, ...currentIllustrationData.simpleLenses }
					)
					: lensesLib.buildLensVisibilityMap({ ...illustrationData.lenses, ...illustrationData.simpleLenses });

			// Get the box type visibility map that'll include the lens visibilities
			const updatedLensBoxTypeVisibilityMap = JSON.parse(
				JSON.stringify(updatedBoxTypeVisibilityMap)
			);

			// Get the box visibility map that'll include the lens visibilities
			const updatedLensBoxVisibilityMap = JSON.parse(
				JSON.stringify(updatedBoxVisibilityMap)
			);


			// Update the box and box type visibilities from the lens
			lensesLib.setVisibilityMapsForLenses(
				updatedLensBoxTypeVisibilityMap,
				updatedLensBoxVisibilityMap,
				illustrationData.boxTypes,
				flattenedBoxMap,
				{ ...illustrationData.lenses, ...illustrationData.simpleLenses },
				updatedLensVisibilityMap,
			);

			// Build the box associations map
			const updatedBoxAssociationsMap: boxLib.BoxAssociationsMap = {};
			boxLib.setBoxAssociations(
				updatedBoxAssociationsMap,
				flattenedBoxMap,
				updatedBoxParentMap,
				illustrationData.boxTypes,
				updatedLensBoxTypeVisibilityMap
			);

			// If we don't have any configuration data, create the object
			if (
				!illustrationData.configuration ||
				!illustrationData.configuration.ui ||
				!illustrationData.configuration.ui.viewSidebar ||
				!illustrationData.configuration.ui.editSidebar
			) {
				// console.log("Creating the configuration");
				illustrationData.configuration = configurationLib.createDefaultConfiguration();
			}

			// Build up our lensPages
			const lensPageMap: BookmarkMap = {};
			if (flattenedBoxMap !== undefined) {
				Object.keys(flattenedBoxMap).forEach((boxKey) => {
					const box = flattenedBoxMap[boxKey];

					if (box.smartPages !== undefined) {
						Object.keys(box.smartPages).forEach((smartPageKey) => {
							const smartPage = box.smartPages![smartPageKey];

							if (smartPage.isBookmarked) {
								lensPageMap[boxKey + "_" + smartPageKey] = {
									boxKey,
									smartPageKey,
									name: smartPage.name,
									bookmarkOrder: smartPage.bookmarkOrder,
								};
							}
						});
					}
				});
			}

			// TODO: Do we need to update this with the new renderFunction visibility stuff?

			// Build the updated visibility state
			const updateVisibilityState = {
				...currentVisibilityState,
				boxTypeVisibilityMap: updatedBoxTypeVisibilityMap
					? updatedBoxTypeVisibilityMap
					: {},
				boxVisibilityMap: updatedBoxVisibilityMap
					? updatedBoxVisibilityMap
					: {},
				lensVisibilityMap: updatedLensVisibilityMap
					? updatedLensVisibilityMap
					: {},
				lensGroupVisibilityMap:
					currentVisibilityState.lensGroupVisibilityMap
			};

			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				illustrationData
			);

			// Get the visibility state map
			let visibilityStateMap = illustrationData.visibilityStateMap;

			if (!visibilityStateMap) {
				visibilityStateMap = {};
			}

			// Update the current visibility
			visibilityStateMap[currentVisibilityStateKey] = updateVisibilityState;

			// Set the updated state
			updatedState = {
				flattenedBoxMap,
				boxParentMap: updatedBoxParentMap,
				lensPageMap,
				boxAssociationsMap: updatedBoxAssociationsMap,
				lensBoxTypeVisibilityMap: updatedLensBoxTypeVisibilityMap,
				lensBoxVisibilityMap: updatedLensBoxVisibilityMap,
				illustrationData,
			};
		}

		return updatedState;
	};

	public componentDidMount() {
		enablePatches();

		// Set up a fullscreen listener
		if (fullscreen) {
			fullscreen.on("change", () =>
				this.setState({ isFullscreen: fullscreen.isFullscreen })
			);
		}

		// Get the illustration ID
		const illustrationId = this.props.match.params.illustrationId;

		// Get the client ID
		const clientId = this.getClientId();

		if (
			!authorizationService.isAbilityPermitted(
				this.props.abilities,
				authorizationService.AbilityType.ProjectView,
				clientId
			)
		) {
			return;
		}

		let projectId = this.getProjectId();

		if (projectId) {
			projectService.get(projectId, (project) => {
				if (project) {
					this.setState({ project: project });

					if (project.clientId) {
						clientService.get(project.clientId, (client) => {
							this.setState({ client: client });
						}, (message) => {
							this.setState({
								isBusy: false
							});
							console.log(`Error occurred: ${message}.`);
							notification.open({
								message: 'Error: Could not load Client',
								description:
									`Failed to load Client Id ${project.clientId}`,
								icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
							});
						});
					}
				}
			}, (message) => {
				this.setState({
					isBusy: false
				});

				console.log(`Error occurred: ${message}.`);
				notification.open({
					message: 'Error: Could not load Project',
					description:
						`Failed to load Project Id ${projectId}`,
					icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
				});
			});
		} else {
			// console.log("no project Id")
		}

		// Get the illustration
		illustrationService.get(illustrationId, (illustration) => {
			// console.log('loaded illustration');
			// console.log(illustration);

			if (illustration) {
				// Get the illustration data
				const illustrationData: illustrationLib.Illustration = illustration.data as illustrationLib.Illustration;

				// Do we have any illustration data?
				if (illustrationData) {
					if (!illustrationData.boxes) {
						illustrationData.boxes = {};
					}

					// Make sure `number` value type exists.
					if (!illustrationData.valueTypes) {
						illustrationData.valueTypes = {}
					}
					illustrationData.valueTypes['number'] = {
						name: "Number",
					};

					// Initialize the box type attribute cache.
					boxTypeLib.initializeBoxTypeAttributeTypeCache(illustrationData.boxTypes);

					// Force the name of the root box to "background"
					if (
						Object.prototype.hasOwnProperty.call(
							illustrationData.boxes,
							"root"
						)
					) {
						illustrationData.boxes["root"].name = "Background";
					}

					if (!illustrationData.configuration) {
						illustrationData.configuration = configurationLib.createDefaultConfiguration()
					}

					if (illustrationData.configuration.ui.illustration) {
						if (illustrationData.configuration.ui.illustration.viewModeHorizontalBoxGapInPixels === undefined || illustrationData.configuration.ui.illustration.viewModeHorizontalBoxGapInPixels === null) {
							illustrationData.configuration.ui.illustration.viewModeHorizontalBoxGapInPixels = configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS;
						}
						if (illustrationData.configuration.ui.illustration.viewModeVerticalBoxGapInPixels === undefined || illustrationData.configuration.ui.illustration.viewModeVerticalBoxGapInPixels === null) {
							illustrationData.configuration.ui.illustration.viewModeVerticalBoxGapInPixels = configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_VERTICAL_BOX_GAP_IN_PIXELS;
						}
						if (illustrationData.configuration.ui.illustration.editModeHorizontalBoxGapInPixels === undefined || illustrationData.configuration.ui.illustration.editModeHorizontalBoxGapInPixels === null) {
							illustrationData.configuration.ui.illustration.editModeHorizontalBoxGapInPixels = configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS;
						}
						if (illustrationData.configuration.ui.illustration.editModeVerticalBoxGapInPixels === undefined || illustrationData.configuration.ui.illustration.editModeVerticalBoxGapInPixels === null) {
							illustrationData.configuration.ui.illustration.editModeVerticalBoxGapInPixels = configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_VERTICAL_BOX_GAP_IN_PIXELS;
						}
					}

					// Add the default attribute types for all box types
					boxTypeLib.setDefaultAttributeTypesForBoxTypes(
						illustrationData.boxTypes
					);
					boxLib.setDefaultBoxesAttributes(
						illustrationData.boxes,
						illustrationData.boxTypes
					);

					// Get the highest box ID attribute and add one to it to get the new
					// highest box ID
					const boxTypeCounters = boxLib.getHighestBoxIDAttributeForTypes(
						illustrationData.boxes,
						illustrationData.boxTypes
					);

					// Get the current visibility state key
					const currentVisibilityStateKey = getCurrentVisibilityStateKey(
						illustrationData
					);

					// Get the visibility state
					const currentVisibilityState = getCurrentVisibilityState(
						currentVisibilityStateKey,
						illustrationData
					);

					// If we don't have lenses in the illustration, add them
					if (!illustrationData.lenses) {
						illustrationData.lenses = {};
					}

					// If we don't have lens groups in the illustration, add them
					if (!illustrationData.lensGroups) {
						illustrationData.lensGroups = {};
					}

					// Add the default lenses
					lensesLib.setDefaultLenses(
						illustrationData.lenses,
						illustrationData.lensGroups
					);

					// If we don't have a visibility state map in the illustration, add one
					if (!illustrationData.visibilityStateMap) {
						illustrationData.visibilityStateMap = {};
					}

					if (
						!Object.prototype.hasOwnProperty.call(
							illustrationData.visibilityStateMap,
							currentVisibilityStateKey
						)
					) {
						illustrationData.visibilityStateMap[
							currentVisibilityStateKey
						] = currentVisibilityState;
					}

					// If we don't have a persistent state in the illustartion, use either
					// the edit state (if permitted) or the view state
					if (!illustrationData.persistentState) {
						if (
							authorizationService.isAbilityPermitted(
								this.props.abilities,
								authorizationService.AbilityType
									.IllustrationBoxesEdit,
								clientId
							)
						) {
							illustrationData.persistentState =
								illustrationLib.IllustrationPersistentState.EDIT_BOX;
						} else {
							illustrationData.persistentState =
								illustrationLib.IllustrationPersistentState.VIEW;
						}
					}

					// Determine the illustration state, defaulting to 'view'
					let illustrationState =
						illustrationLib.IllustrationState.VIEW;

					if (
						illustrationData.persistentState ===
						illustrationLib.IllustrationPersistentState.EDIT_BOX
					) {
						// If we can edit boxes, default to 'edit' state
						illustrationState =
							illustrationLib.IllustrationState.EDIT_BOX;
					}

					// Get a copy of the current visibility state
					const updatedCurrentVisibilityState = JSON.parse(
						JSON.stringify(currentVisibilityState)
					);

					// Make sure the default visibilities are set
					setDefaultBoxTypeVisibilities(
						updatedCurrentVisibilityState.boxTypeVisibilityMap
					);


					// Get a flattened box map
					const currentFlattenedBoxMap = boxLib.getFlattenedBoxMap(illustrationData.boxes, {});

					// Get the updated state for the illustration data
					const updatedState = this.getUpdatedStateForIllustrationData(
						illustrationData,
						illustrationData,
						currentFlattenedBoxMap,
						updatedCurrentVisibilityState,
					);

					this.setState({
						isBusy: false,
						illustrationState,
						illustration,
						...updatedState,
						boxTypeCounters,
					});
				}
			}
		}, (message) => {
			this.setState({
				isBusy: false
			});
			console.log(`Error occurred: ${message}.`);
			notification.open({
				message: 'Error: Could not load Illustration',
				description:
					`Failed to load Illustration Id ${illustrationId}`,
				icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
			});
		});
	}

	toggleViewSidebarVisibility = () => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Clone the illustration data
			const updatedIllustrationData = this.cloneIllustrationData(
				this.state.illustrationData
			);

			// Toggle the visibility
			if (
				updatedIllustrationData &&
				updatedIllustrationData.configuration &&
				updatedIllustrationData.configuration.ui &&
				updatedIllustrationData.configuration.ui.viewSidebar
			) {
				updatedIllustrationData.configuration.ui.viewSidebar.isVisible = !updatedIllustrationData
					.configuration.ui.viewSidebar.isVisible;
			}

			// Update the configuration
			this.setState({
				illustrationData: updatedIllustrationData,
			});
		}
	};

	toggleEditSidebarVisibility = () => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Clone the illustration data
			const updatedIllustrationData = this.cloneIllustrationData(
				this.state.illustrationData
			);

			// Toggle the visibility
			if (
				updatedIllustrationData &&
				updatedIllustrationData.configuration &&
				updatedIllustrationData.configuration.ui &&
				updatedIllustrationData.configuration.ui.editSidebar
			) {
				updatedIllustrationData.configuration.ui.editSidebar.isVisible = !updatedIllustrationData
					.configuration.ui.editSidebar.isVisible;
			}

			// Update the configuration
			this.setState({
				illustrationData: updatedIllustrationData,
			});
		}
	};

	private renderBreadcrumbs = () => {
		const BREADCRUMBS = [
			{
				onGetPath: () => "/",
				onGetDisplayName: () => "Clients",
			},
			{
				onGetPath: (params: any) => `/clients/${params.clientId}`,
				onGetDisplayName: () =>
					this.state.client ? this.state.client.name : '',
			},
			{
				onGetPath: (params: any) =>
					`/clients/${params.clientId}#projects`,
				onGetDisplayName: () => "Projects",
			},
			{
				onGetPath: (params: any) =>
					`/clients/${params.clientId}/projects/${params.projectId}`,
				onGetDisplayName: () =>
					this.state.project ? this.state.project.name : '',
			},
			{
				onGetPath: (params: any) =>
					`/clients/${params.clientId}/projects/${params.projectId}#illustrations`,
				onGetDisplayName: () => "Illustrations",
			},
			{
				onGetPath: (params: any) =>
					`/clients/${params.clientId}/projects/${params.projectId}/illustrations/${params.illustrationId}`,
				onGetDisplayName: () =>
					this.state.illustration ? this.state.illustration.name : '',
			},
		];

		return renderBreadcrumbsInPortal(BREADCRUMBS, this.props.match.params);
	};

	private renderButtons = (
		isViewSidebarVisible: boolean,
		isViewSidebarButtonDisabled: boolean,
		isEditSidebarVisible: boolean,
		isEditSidebarButtonDisabled: boolean,
		isSaveButtonDisabled: boolean,
		isPrintButtonDisabled: boolean
	) => {
		// Determine the toggle view sidebar button color
		const toggleViewSidebarButtonGhost =
			!isViewSidebarVisible || isViewSidebarButtonDisabled;

		// Determine the toggle edit sidebar button color
		const toggleEditSidebarButtonGhost =
			!isEditSidebarVisible || isEditSidebarButtonDisabled;

		// Render the illustration state buttons
		const renderedIllustrationStateButtons = this.renderIllustrationStateButtons();

		// Figure out whether fullscreen is enabled (some browsers don't support it)
		const isFullscreenEnabled = fullscreen && fullscreen.isEnabled;

		// Figure out whether we're showing the "enter" or "exit" fullscreen icon
		const fullscreenIcon = this.state.isFullscreen
			? ILLUSTRATION_STATE_EXIT_FULLSCREEN_ICON
			: ILLUSTRATION_STATE_ENTER_FULLSCREEN_ICON;

		const isUndoEnabled = this.state.undos.length > 0;
		const isRedoEnabled = this.state.redos.length > 0;

		const renderedButtons = (
			<div
				style={{
					height: "100%",
					float: "right",
					paddingTop: "12px",
					paddingRight: "16px",
				}}
			>
				{isFullscreenEnabled && (
					<Tooltip title="Fullscreen">
						<Button
							size="large"
							shape={configurationLib.DEFAULT_BUTTON_SHAPE}
							style={{
								float: "right",
								marginLeft: "0.5em",
								color:
									configurationLib.DEFAULT_BUTTON_EDIT_TOGGLE_FULLSCREEN_COLOR,
								backgroundColor:
									!this.state.isFullscreen ? "transparent" : configurationLib.DEFAULT_BUTTON_EDIT_TOGGLE_FULLSCREEN_BACKGROUND_COLOR,
								border: 0,
							}}
							icon={fullscreenIcon === "fullscreen" ? <FullscreenOutlined /> : <FullscreenExitOutlined />}
							ghost={!this.state.isFullscreen}
							onClick={() => toggleFullscreen()}
						/>
					</Tooltip>
				)}
				{!isPrintButtonDisabled && (
					<ReactToPrint content={() => this.illustrationComponentRef}>
						<PrintContextConsumer>
							{({ handlePrint }) => (
								<Tooltip title="Print">
									<Button
										size="large"
										shape={
											configurationLib.DEFAULT_BUTTON_SHAPE
										}
										style={{
											float: "right",
											marginLeft: "0.5em",
											color:
												configurationLib.DEFAULT_BUTTON_SAVE_COLOR,
											backgroundColor:
												true ? "transparent" : configurationLib.DEFAULT_BUTTON_SAVE_BACKGROUND_COLOR,
											border: 0,
										}}
										ghost={true}
										icon={<PrinterOutlined />}
										onClick={handlePrint}
									/>
								</Tooltip>
							)}
						</PrintContextConsumer>
					</ReactToPrint>
				)}
				{!isSaveButtonDisabled && (
					<Tooltip title="Save">
						<Button
							size="large"
							shape={configurationLib.DEFAULT_BUTTON_SHAPE}
							style={{
								float: "right",
								marginLeft: "0.5em",
								color:
									configurationLib.DEFAULT_BUTTON_SAVE_COLOR,
								backgroundColor:
									true ? "transparent" : configurationLib.DEFAULT_BUTTON_SAVE_BACKGROUND_COLOR,
								border: 0,
							}}
							ghost={true}
							icon={<SaveOutlined />}
							onClick={this.handleIllustrationSaveButtonClick}
						/>
					</Tooltip>
				)}
				{!isEditSidebarButtonDisabled && (
					<>
						<Button
							size="large"
							shape={configurationLib.DEFAULT_BUTTON_SHAPE}
							style={{
								float: "right",
								marginLeft: "0.5em",
								color:
									configurationLib.DEFAULT_BUTTON_EDIT_SIDEBAR_COLOR,
								backgroundColor:
									!isRedoEnabled ? "transparent" : configurationLib.DEFAULT_BUTTON_EDIT_SIDEBAR_BACKGROUND_COLOR,
								border: 0,
							}}
							ghost={!isRedoEnabled}
							icon={<RedoOutlined />}
							disabled={!isRedoEnabled}
							onClick={this.applyRedo}
						/>
						<Button
							size="large"
							shape={configurationLib.DEFAULT_BUTTON_SHAPE}
							style={{
								float: "right",
								marginLeft: "0.5em",
								color:
									configurationLib.DEFAULT_BUTTON_EDIT_SIDEBAR_COLOR,
								backgroundColor:
									!isUndoEnabled ? "transparent" : configurationLib.DEFAULT_BUTTON_EDIT_SIDEBAR_BACKGROUND_COLOR,
								border: 0,
							}}
							ghost={!isUndoEnabled}
							icon={<UndoOutlined />}
							disabled={!isUndoEnabled}
							onClick={this.applyUndo}
						/>
						<Tooltip title="Show Right Hand Menu">
							<Button
								size="large"
								shape={configurationLib.DEFAULT_BUTTON_SHAPE}
								style={{
									float: "right",
									marginLeft: "0.5em",
									color:
										configurationLib.DEFAULT_BUTTON_EDIT_SIDEBAR_COLOR,
									backgroundColor:
										toggleEditSidebarButtonGhost ? "transparent" : configurationLib.DEFAULT_BUTTON_EDIT_SIDEBAR_BACKGROUND_COLOR,
									border: 0,
								}}
								ghost={toggleEditSidebarButtonGhost}
								icon={<SettingOutlined />}
								onClick={this.toggleEditSidebarVisibility}
							/>
						</Tooltip>
					</>
				)}
				{!isViewSidebarButtonDisabled && (
					<Tooltip title="Show Left Hand Menu">
						<Button
							size="large"
							shape={configurationLib.DEFAULT_BUTTON_SHAPE}
							style={{
								float: "right",
								marginLeft: "0.5em",
								color:
									configurationLib.DEFAULT_BUTTON_VIEW_SIDEBAR_COLOR,
								backgroundColor:
									toggleViewSidebarButtonGhost ? "transparent" : configurationLib.DEFAULT_BUTTON_VIEW_SIDEBAR_BACKGROUND_COLOR,
								border: 0,
							}}
							ghost={toggleViewSidebarButtonGhost}
							icon={<ToolOutlined />}
							onClick={this.toggleViewSidebarVisibility}
						/>
					</Tooltip>
				)}
				<div
					style={{
						float: "right",
						height: "24px",
						marginRight: "0.5em",
						marginTop: "8px",
						paddingRight: "1em",
						borderRightWidth: "1px",
						borderRightColor: "rgba(255, 255, 255, 0.45)",
						borderRightStyle: "solid",
					}}
				>
					&nbsp;
				</div>
				{renderedIllustrationStateButtons}
			</div>
		);

		return renderButtonsInPortal(renderedButtons);
	};

	public render() {
		// If we don't have an illustration, don't render anything
		if (!this.state.illustrationData) {
			return null;
		}

		// Get the client ID
		const clientId = this.getClientId();

		const illustrationData = (this.state.isBoxDragging && this.state.dragAndDropIllustrationData)
			? this.state.dragAndDropIllustrationData
			: this.state.illustrationData

		// const illustrationData = this.state.illustrationData;

		// Build up an illustration
		const illustration: illustrationLib.Illustration = {
			...illustrationData,
			defaultProperties: {
				backgroundColor: "#FF0",
			},
			attributes: {},
		};

		// Get the boxes, box types, and value types
		const boxes = illustrationData.boxes;
		const boxTypes = illustrationData.boxTypes;
		const valueTypes = illustrationData.valueTypes;

		// Get the box styles
		const boxStyles = illustrationData.boxStyles;

		// Get the configuration
		const configuration = illustrationData.configuration;

		// Get whether the view sidebar is visible
		let isViewSidebarVisible =
			configuration && configuration.ui && configuration.ui.viewSidebar
				? configuration.ui.viewSidebar.isVisible
				: configurationLib.DEFAULT_UI_VIEW_SIDEBAR_IS_VISIBLE;

		// Whether the view sidebar is disabled
		let isViewSidebarDisabled = false;
		let isViewSidebarButtonDisabled = false;

		// Get the view sidebar tool visibilities
		const viewSidebarToolVisibilities =
			configuration && configuration.ui && configuration.ui.viewSidebar
				? configuration.ui.viewSidebar.toolVisibility
				: configurationLib.DEFAULT_UI_VIEW_SIDEBAR_TOOL_VISIBILITY;

		// Get the view sidebar width (in percent)
		const viewSidebarWidthInPercent =
			configuration && configuration.ui && configuration.ui.viewSidebar
				? configuration.ui.viewSidebar.widthInPercent
				: configurationLib.DEFAULT_UI_VIEW_SIDEBAR_WIDTH_IN_PERCENT;

		// Get the Illustration Width (in percent)
		const illustrationWidthInPercent =
			configuration && configuration.ui && configuration.ui.illustration
				? configuration.ui.illustration.widthInPercent
				: configurationLib.DEFAULT_UI_ILLUSTRATION_WIDTH_IN_PERCENT;

		// Get the Illustration Width (in percent)
		const illustrationHeightInPercent =
			configuration && configuration.ui && configuration.ui.illustration
				? configuration.ui.illustration.heightInPercent
				: configurationLib.DEFAULT_UI_ILLUSTRATION_HEIGHT_IN_PERCENT;

		// Get the view mode padding (in pixels)
		const viewModeHorizontalBoxGapInPixels = configuration && configuration.ui && configuration.ui.illustration
			? configuration.ui.illustration.viewModeHorizontalBoxGapInPixels
			: configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS;
		const viewModeVerticalBoxGapInPixels = configuration && configuration.ui && configuration.ui.illustration
			? configuration.ui.illustration.viewModeVerticalBoxGapInPixels
			: configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_VERTICAL_BOX_GAP_IN_PIXELS;

		// Get the edit mode padding (in pixels)
		const editModeHorizontalBoxGapInPixels = configuration && configuration.ui && configuration.ui.illustration
			? configuration.ui.illustration.editModeHorizontalBoxGapInPixels
			: configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS;
		const editModeVerticalBoxGapInPixels = configuration && configuration.ui && configuration.ui.illustration
			? configuration.ui.illustration.editModeVerticalBoxGapInPixels
			: configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_VERTICAL_BOX_GAP_IN_PIXELS;

		// Get whether the edit sidebar is visible
		let isEditSidebarVisible =
			configuration && configuration.ui && configuration.ui.editSidebar
				? configuration.ui.editSidebar.isVisible
				: configurationLib.DEFAULT_UI_EDIT_SIDEBAR_IS_VISIBLE;

		// Whether the edit sidebar is disabled
		let isEditSidebarDisabled = false;
		let isEditSidebarButtonDisabled = false;

		// Get the edit sidebar width (in percent)
		const editSidebarWidthInPercent =
			configuration && configuration.ui && configuration.ui.editSidebar
				? configuration.ui.editSidebar.widthInPercent
				: configurationLib.DEFAULT_UI_VIEW_SIDEBAR_WIDTH_IN_PERCENT;

		// Figure out the disabled states of the UI components
		if (
			this.state.illustrationState ===
			illustrationLib.IllustrationState.EDIT_BOX
		) {
			// EDIT_BOX - everything is enabled
			isViewSidebarDisabled = false;
			isViewSidebarButtonDisabled = false;
			isEditSidebarDisabled = false;
			isEditSidebarButtonDisabled = false;
		} else if (
			this.state.illustrationState ===
			illustrationLib.IllustrationState.EDIT_MULTI_BOX
		) {
			// EDIT_MULTI_BOX - only parts of illustration is enabled
			isViewSidebarDisabled = true;
			isViewSidebarButtonDisabled = true;
			isEditSidebarDisabled = true;
			isEditSidebarButtonDisabled = true;
		} else if (
			this.state.illustrationState ===
			illustrationLib.IllustrationState.EDIT_ASSOCIATIONS ||
			this.state.illustrationState ===
			illustrationLib.IllustrationState.SIMPLE_EDIT_ASSOCIATIONS
		) {
			// EDIT_ASSOCIATIONS/SIMPLE_EDIT_ASSOCIATIONS - only parts of illustration are enabled
			isViewSidebarDisabled = true;
			isViewSidebarButtonDisabled = true;
			isEditSidebarDisabled = true;
			isEditSidebarButtonDisabled = true;
		} else if (
			this.state.illustrationState ===
			illustrationLib.IllustrationState.VIEW
		) {
			// VIEW - edit sidebar is disabled and not visible
			isViewSidebarDisabled = false;
			isViewSidebarButtonDisabled = false;
			isEditSidebarDisabled = true;
			isEditSidebarButtonDisabled = false;
			isEditSidebarVisible = false;
		}

		// Whether the save button is disabled
		let isSaveButtonDisabled = !authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.IllustrationSave,
			clientId
		);

		// Whether the print button is disabled
		// TODO: Do we need a new permissions type for printing?
		let isPrintButtonDisabled = !authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.IllustrationSave,
			clientId
		);

		// Override the sidebar states based on abilities
		if (
			!authorizationService.isAbilityPermitted(
				this.props.abilities,
				authorizationService.AbilityType.IllustrationViewSidebarView,
				clientId
			)
		) {
			isViewSidebarVisible = false;
			isViewSidebarDisabled = true;
			isViewSidebarButtonDisabled = true;
		}
		if (
			!authorizationService.isAbilityPermitted(
				this.props.abilities,
				authorizationService.AbilityType.IllustrationViewSidebarEdit,
				clientId
			)
		) {
			isViewSidebarDisabled = true;
		}

		if (
			!authorizationService.isAbilityPermitted(
				this.props.abilities,
				authorizationService.AbilityType.IllustrationEditSidebarView,
				clientId
			)
		) {
			isEditSidebarVisible = false;
			isEditSidebarDisabled = true;
			isEditSidebarButtonDisabled = true;
		}
		if (
			!authorizationService.isAbilityPermitted(
				this.props.abilities,
				authorizationService.AbilityType.IllustrationEditSidebarEdit,
				clientId
			)
		) {
			isEditSidebarDisabled = true;
		}

		// Determine the view sidebar display
		const viewSidebarDisplay = isViewSidebarVisible
			? "inline-block"
			: "none";

		// Determine the edit sidebar display
		const editSidebarDisplay = isEditSidebarVisible
			? "inline-block"
			: "none";

		// Get the selected box keys
		const selectedBoxKeys = Object.keys(this.state.boxSelectionInfoMap);

		// Determine whether the configuration inputs are disabled
		let areConfigurationInputsDisabled = !authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.IllustrationConfigurationEdit,
			clientId
		);

		// Determine whether the JSON editor inputs are disabled
		let areJSONEditorInputsDisabled = !authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.IllustrationJSONEdit,
			clientId
		);

		// Determine whether the JSON import/export buttons are disabled
		let isJSONImportButtonsDisabled = !authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.IllustrationJSONImport,
			clientId
		);
		let isJSONExportButtonsDisabled = !authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.IllustrationJSONExport,
			clientId
		);

		// Determine whether the Excel import/export buttons are disabled
		let isExcelImportButtonsDisabled = !authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.IllustrationExcelImport,
			clientId
		);
		let isExcelExportButtonsDisabled = !authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.IllustrationExcelExport,
			clientId
		);

		// Determine whether the illustration types can be edited or deleted
		const canEditIllustrationTypes = authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.IllustrationTypesEdit,
			clientId
		);
		const canDeleteIllustrationTypes = authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.IllustrationTypesDelete,
			clientId
		);

		// Determine whether the illustration visibility states can be edited or deleted
		let canEditIllustrationVisibilityStates = authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.IllustrationTypesEdit,
			clientId
		);
		let canDeleteIllustrationVisibilityStates = authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.IllustrationTypesDelete,
			clientId
		);

		// Determine whether boxes can be added/removed/moved
		let disableAddBox = !authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.IllustrationBoxesAdd,
			clientId
		);
		let disableEditBox = !authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.IllustrationBoxesEdit,
			clientId
		);
		let disableRemoveBox = !authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.IllustrationBoxesDelete,
			clientId
		);
		let disableDragBox = !authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.IllustrationBoxesMove,
			clientId
		);

		// If we're busy, prevent interactions
		if (this.state.isBusy) {
			isViewSidebarButtonDisabled = true;
			isViewSidebarDisabled = true;

			isEditSidebarButtonDisabled = true;
			isEditSidebarDisabled = true;

			isSaveButtonDisabled = true;
			isPrintButtonDisabled = true;

			areConfigurationInputsDisabled = true;

			areJSONEditorInputsDisabled = true;
			isJSONImportButtonsDisabled = true;
			isJSONExportButtonsDisabled = true;

			canEditIllustrationVisibilityStates = true;
			canDeleteIllustrationVisibilityStates = true;
			disableAddBox = true;
			disableEditBox = true;
			disableRemoveBox = true;
			disableDragBox = true;
		}

		// Get the visibility state map
		const visibilityStateMap = illustrationData.visibilityStateMap
			? illustrationData.visibilityStateMap
			: undefined;

		// Get the current visibility state key
		const currentVisibilityStateKey = getCurrentVisibilityStateKey(illustrationData);

		// Get the visibility state
		const currentVisibilityState = getCurrentVisibilityState(
			currentVisibilityStateKey,
			illustrationData
		);

		// Get whether the illustration is starting in a view state
		const startInViewState = illustrationData.persistentState ===
			illustrationLib.IllustrationPersistentState.VIEW;

		const breadcrumbs = this.renderBreadcrumbs();

		const buttons = this.renderButtons(
			isViewSidebarVisible,
			isViewSidebarButtonDisabled,
			isEditSidebarVisible,
			isEditSidebarButtonDisabled,
			isSaveButtonDisabled,
			isPrintButtonDisabled
		);

		const busyIcon = <LoadingOutlined style={{ fontSize: 192 }} spin />;

		return (
			<Layout
				style={{
					width: "100%",
					height: "100%",
				}}
			>
				{breadcrumbs}
				{buttons}
				<Layout.Content>
					<SizeMe
						monitorWidth
						monitorHeight
						refreshRate={16}
						refreshMode="throttle"
						noPlaceholder
					>
						{({ size }: SizeMeProps) => {
							// Get the width and height of the component (in pixels)
							const componentWidthInPixels =
								!!size.width && !isNaN(size.width)
									? size.width
									: 0;
							const componentHeightInPixels =
								!!size.height && !isNaN(size.height)
									? size.height
									: 0;

							// Calculate the view  and edit sidebar dimensions (in pixels)
							const viewSidebarWidthInPixels = isViewSidebarVisible
								? (componentWidthInPixels *
									viewSidebarWidthInPercent) /
								100
								: 0;
							const editSidebarWidthInPixels = isEditSidebarVisible
								? (componentWidthInPixels *
									editSidebarWidthInPercent) /
								100
								: 0;

							// Calculate the illustration width and height (in pixels)
							const illustrationWidthInPixels =
								componentWidthInPixels -
								viewSidebarWidthInPixels -
								editSidebarWidthInPixels;
							const illustrationHeightInPixels = componentHeightInPixels;

							// scale the height and width of the boxes inside the illustration to create scrolling if
							// needed
							const scaledIllustrationWidthInPixels =
								illustrationWidthInPixels *
								(illustrationWidthInPercent / 100);
							const scaledIllustrationHeightInPixels =
								illustrationHeightInPixels *
								(illustrationHeightInPercent / 100);

							const scrollIllustrationWidth =
								illustrationWidthInPercent > 100
									? "scroll"
									: "hidden";
							const scrolllustrationHeight =
								illustrationHeightInPercent > 100
									? "scroll"
									: "hidden";

							return (
								<div
									style={{
										height: "100%",
										width: "100%",
										position: "relative",
										overflow: "hidden",
									}}
								>
									<Spin
										indicator={busyIcon}
										spinning={this.state.isBusy}
										delay={BUSY_DELAY_IN_MILLISECONDS}
									>
										<ViewMenu
											viewSidebarDisplay={
												viewSidebarDisplay
											}
											viewSidebarWidthInPixels={
												viewSidebarWidthInPixels
											}
											componentHeightInPixels={
												componentHeightInPixels
											}
											isViewSidebarDisabled={
												isViewSidebarDisabled
											}
											currentVisibilityStateKey={
												currentVisibilityStateKey
											}
											visibilityStateMap={
												visibilityStateMap
											}
											onVisibilityStateChange={
												this.handleVisibilityStateChange
											}
											lensPageMap={this.state.lensPageMap}
											onBookmarkSelect={
												this.handleBookmarkSelect
											}
											boxStyles={boxStyles}
											showStoryMenu={
												viewSidebarToolVisibilities.visibilityStateMenuVisibility
											}
											showLensesMenu={
												viewSidebarToolVisibilities.lensesMenuVisibility
											}
											showSimpleLensesMenu={
												viewSidebarToolVisibilities.simpleLensesMenuVisibility
											}
											showLensPagesMenu={
												viewSidebarToolVisibilities.lensPagesMenuVisibility
											}
											showLegendMenu={
												viewSidebarToolVisibilities.legendMenuVisibility
											}
											showTypesMenu={
												viewSidebarToolVisibilities.boxTypesMenuVisibility
											}
											showSimpleTypesMenu={
												viewSidebarToolVisibilities.simpleBoxTypesMenuVisibility
											}
											showBoxesMenu={
												viewSidebarToolVisibilities.boxesMenuVisibility
											}
											showLatchModeMenu={
												viewSidebarToolVisibilities.latchModeMenuVisibility
											}
											boxes={boxes}

											onRenderFunctionLatchModeVisibilityChange={this.handleRenderFunctionLatchModeVisibilityChange}

											boxVisibilityMap={
												currentVisibilityState.boxVisibilityMap
											}
											onBoxVisibilityChange={
												this.handleBoxVisibilityChange
											}
											onBoxVisibilityOrLayoutChange={
												this
													.handleBoxVisibilityOrLayoutChange
											}
											onBoxTypeBoxesVisibilityChange={
												this
													.handleBoxTypeBoxesVisibilityChange
											}
											onAttributeTypeVisibilityChange={
												this
													.handleAttributeTypeVisibilityChange
											}
											boxTypeMap={boxTypes}
											boxTypeVisibilityMap={
												currentVisibilityState.boxTypeVisibilityMap
											}
											onBoxTypeVisibilityChange={
												this
													.handleBoxTypeVisibilityChange
											}
											lensGroupVisibilityMap={
												currentVisibilityState.lensGroupVisibilityMap
											}
											lensGroups={illustrationData.lensGroups}
											lenses={illustrationData.lenses}
											lensVisibilityMap={
												currentVisibilityState.lensVisibilityMap
											}
											onLensGroupVisibilityChange={
												this
													.handleLensGroupVisibilityChange
											}
											onLensVisibilityChange={
												this.handleLensVisibilityChange
											}

											simpleLensGroupVisibilityMap={
												currentVisibilityState.lensGroupVisibilityMap
											}
											simpleLensGroups={illustrationData.simpleLensGroups}
											simpleLenses={illustrationData.simpleLenses}
											simpleLensVisibilityMap={
												currentVisibilityState.lensVisibilityMap
											}
											onSimpleLensGroupVisibilityChange={
												this
													.handleSimpleLensGroupVisibilityChange
											}
											onSimpleLensVisibilityChange={
												this.handleSimpleLensVisibilityChange
											}

										/>
										<div
											style={{
												display: "inline-block",
												float: "left",
												width: illustrationWidthInPixels,
												height: illustrationHeightInPixels,
												overflowX: scrollIllustrationWidth,
												overflowY: scrolllustrationHeight,
											}}
										>
											{authorizationService.renderIfAbilityPermitted(
												this.props.abilities,
												authorizationService.AbilityType
													.IllustrationBoxesView,
												clientId,
												() => (
													<Illustration
														ref={(el) =>
															(this.illustrationComponentRef = el)
														}
														onBoxAcceptEditInPlace={
															this
																.handleBoxAcceptEditInPlace
														}
														onBoxEditSelect={
															this
																.handleBoxEditSelect
														}
														onBoxMultiEditSelect={
															this
																.handleBoxMultiEditSelect
														}
														onBoxAssociationsEditSelect={
															this
																.handleBoxAssociationsEditSelect
														}
														onBoxViewSelect={
															this
																.handleBoxViewSelect
														}
														onBoxAddChild={
															this
																.handleBoxAddChild
														}
														onBoxRemoveChild={
															this
																.handleBoxRemoveChild
														}
														onBoxChildLayoutChange={
															this
																.handleBoxChildLayoutChange
														}
														onBoxCanDrop={
															this
																.handleBoxCanDrop
														}
														onBoxDragStart={
															this
																.handleBoxDragStart
														}
														onBoxDragCancel={
															this
																.handleBoxDragCancel
														}
														onBoxDragHover={
															this
																.handleBoxDragHover
														}
														onBoxDragDrop={
															this
																.handleBoxDragDrop
														}
														illustration={
															illustration
														}
														illustrationState={
															this.state
																.illustrationState
														}
														illustrationFlattenedBoxMap={this.state.flattenedBoxMap}
														boxTypeVisibilityMap={
															this.state
																.lensBoxTypeVisibilityMap
														}

														boxAssociationsMap={
															this.state
																.boxAssociationsMap
														}
														boxSelectionInfoMap={
															this.state
																.boxSelectionInfoMap
														}
														boxVisibilityMap={
															this.state
																.lensBoxVisibilityMap
														}
														widthInPixels={
															scaledIllustrationWidthInPixels
														}
														heightInPixels={
															scaledIllustrationHeightInPixels
														}
														viewModeHorizontalBoxGapInPixels={
															viewModeHorizontalBoxGapInPixels
														}
														viewModeVerticalBoxGapInPixels={
															viewModeVerticalBoxGapInPixels
														}
														editModeHorizontalBoxGapInPixels={
															editModeHorizontalBoxGapInPixels
														}
														editModeVerticalBoxGapInPixels={
															editModeVerticalBoxGapInPixels
														}
														currentDragSourceBoxKey={this.state.currentDragSourceBoxKey}
														currentlyHighlightedDropTargetBoxKey={
															this.state
																.currentlyHighlightedDropTargetBoxKey
														}
														disableAddBox={
															disableAddBox
														}
														disableEditBox={
															disableEditBox
														}
														disableRemoveBox={
															disableRemoveBox
														}
														disableDragBox={
															disableDragBox
														}
														shouldUpdate={!(this.state.isImportExportIllustrationModalVisible ||
															this.state.isImportExportIllustrationExcelModalVisible ||
															this.state.isEditBoxModalVisible ||
															this.state.isAddEditBoxTypeModalVisible ||
															this.state.isAddEditVisibilityStateModalVisible ||
															this.state.isViewHistoryModalVisible ||
															this.state.isEditIllustrationModalVisible ||
															this.state.isCreateAssociationsModalVisible ||
															this.state.isSimpleCreateAssociationsModalVisible ||
															this.state.isMultiEditBoxesModalVisible ||
															this.state.isViewBoxModalVisible)}
													/>
												)
											)}
										</div>
										<div
											style={{
												display: editSidebarDisplay,
												position: "relative",
												float: "right",
												width: editSidebarWidthInPixels,
												height: componentHeightInPixels,
												padding: 0,
												overflow: "hidden",
												pointerEvents: isEditSidebarDisabled
													? "none"
													: "auto",
											}}
										>
											<div
												style={{
													height: "100%",
													width: "100%",
													overflow: "auto",
													padding: 0,
													backgroundColor:
														configurationLib.DEFAULT_UI_EDIT_SIDEBAR_BACKGROUND_COLOR,
												}}
											>
												{authorizationService.renderIfAbilityPermitted(
													this.props.abilities,
													authorizationService
														.AbilityType
														.IllustrationConfigurationView,
													clientId,
													() => (
														<ConfigurationMenu
															areConfigurationInputsDisabled={areConfigurationInputsDisabled}
															editSidebarWidthInPercent={editSidebarWidthInPercent}
															viewSidebarToolVisibilities={viewSidebarToolVisibilities}
															onEditSidebarWidthInPercentChange={this.handleEditSidebarWidthInPercentChange}
															onIllustrationHeightInPercentChange={this.handleIllustrationHeightInPercentChange}
															onIllustrationWidthInPercentChange={this.handleIllustrationWidthInPercentChange}
															onViewSidebarMenuStartInViewStateChange={this.handleViewSidebarMenuStartInViewStateChange}
															onViewSidebarMenuVisibilityChange={this.handleViewSidebarMenuVisibilityChange}
															onViewSidebarWidthInPercentChange={this.handleViewSidebarWidthInPercentChange}
															onViewModeHorizontalBoxGapInPixelsChange={this.handleViewModeHorizontalBoxGapInPixelsChange}
															onViewModeVerticalBoxGapInPixelsChange={this.handleViewModeVerticalBoxGapInPixelsChange}
															onEditModeHorizontalBoxGapInPixelsChange={this.handleEditModeHorizontalBoxGapInPixelsChange}
															onEditModeVerticalBoxGapInPixelsChange={this.handleEditModeVerticalBoxGapInPixelsChange}
															illustrationHeightInPercent={illustrationHeightInPercent}
															illustrationWidthInPercent={illustrationWidthInPercent}
															viewModeHorizontalBoxGapInPixels={viewModeHorizontalBoxGapInPixels}
															viewModeVerticalBoxGapInPixels={viewModeVerticalBoxGapInPixels}
															editModeHorizontalBoxGapInPixels={editModeHorizontalBoxGapInPixels}
															editModeVerticalBoxGapInPixels={editModeVerticalBoxGapInPixels}
															viewSidebarWidthInPercent={viewSidebarWidthInPercent}
															startInViewState={startInViewState}

														/>
													)
												)}
												{authorizationService.renderIfAbilityPermitted(
													this.props.abilities,
													authorizationService
														.AbilityType
														.IllustrationJSONView,
													clientId,
													() => (
														<ImportExportMenu
															isJSONImportButtonsDisabled={isJSONImportButtonsDisabled}
															isJSONExportButtonsDisabled={isJSONExportButtonsDisabled}
															isExcelExportButtonsDisabled={isExcelExportButtonsDisabled}
															isExcelImportButtonsDisabled={isExcelImportButtonsDisabled}
															onIllustrationExportButtonClick={this.handleIllustrationExportButtonClick}
															onIllustrationExportExcelButtonClick={this.handleIllustrationExportExcelButtonClick}
															onIllustrationImportButtonClick={this.handleIllustrationImportButtonClick}
															onIllustrationImportExcelButtonClick={this.handleIllustrationImportExcelButtonClick}
														/>
													)
												)}
												{authorizationService.renderIfAbilityPermitted(
													this.props.abilities,
													authorizationService
														.AbilityType
														.IllustrationTypesView,
													clientId,
													() => (
														<TypesMenu
															canAddTypes={authorizationService.isAbilityPermitted(
																this.props.abilities,
																authorizationService.AbilityType.IllustrationTypesAdd,
																clientId
															)}
															onBoxTypeAdd={this.handleBoxTypeAdd}
															canDeleteTypes={canDeleteIllustrationTypes}
															canEditTypes={canEditIllustrationTypes}
															onBoxTypeDelete={this.handleBoxTypeDelete}
															onBoxTypeEdit={this.handleBoxTypeEdit}
															boxTypeMap={boxTypes}
															onImportBoxTypes={this.handleImportBoxTypes}
														/>
													)
												)}
												{authorizationService.renderIfAbilityPermitted(
													this.props.abilities,
													authorizationService
														.AbilityType
														.IllustrationTypesView,
													clientId,
													() => (
														<>
															<SimpleLensesMenu
																onEdit={
																	this
																		.handleSimpleLensGroupAddEdit
																}
																onDelete={
																	this
																		.handleSimpleLensGroupDelete
																}
																onAdd={this.handleSimpleLensGroupAddEdit}
																lensGroupMap={
																	illustrationData.unprocessedSimpleLensGroups
																}
																canEdit={
																	canEditIllustrationVisibilityStates
																}
																canDelete={
																	canDeleteIllustrationVisibilityStates
																}
																canAdd={authorizationService.isAbilityPermitted(
																	this.props.abilities,
																	authorizationService.AbilityType.IllustrationTypesAdd,
																	clientId
																)}

																boxTypes={boxTypes}
															/>
														</>
													)
												)}
												{features.powerLenses && authorizationService.renderIfAbilityPermitted(
													this.props.abilities,
													authorizationService
														.AbilityType
														.IllustrationTypesView,
													clientId,
													() => (
														<>
															{boxes !== undefined && boxTypes !== undefined &&
																<PowerLensesMenu
																	boxTypes={boxTypes}
																	boxes={boxes}
																	attributes={{}}
																	onEdit={
																		this.handlePowerLensAddEdit
																	}
																	onDelete={
																		this.handlePowerLensDelete
																	}
																	onAdd={this.handlePowerLensAddEdit}
																	lensesMap={
																		illustrationData.lenses
																	}
																	canEdit={
																		canEditIllustrationVisibilityStates
																	}
																	canDelete={
																		canDeleteIllustrationVisibilityStates
																	}
																	canAdd={authorizationService.isAbilityPermitted(
																		this.props.abilities,
																		authorizationService.AbilityType.IllustrationTypesAdd,
																		clientId
																	)}

																/>}
														</>
													)
												)}
												{features.powerLenses && authorizationService.renderIfAbilityPermitted(
													this.props.abilities,
													authorizationService
														.AbilityType
														.IllustrationTypesView,
													clientId,
													() => (
														<PowerLensGroupsMenu
															onEdit={
																this
																	.handlePowerLensGroupAddEdit
															}
															onDelete={
																this
																	.handlePowerLensGroupDelete
															}
															onAdd={this.handlePowerLensGroupAddEdit}
															lensMap={
																illustrationData.lenses
															}
															lensGroupMap={
																illustrationData.lensGroups
															}
															canEdit={
																canEditIllustrationVisibilityStates
															}
															canDelete={
																canDeleteIllustrationVisibilityStates
															}
															canAdd={authorizationService.isAbilityPermitted(
																this.props.abilities,
																authorizationService.AbilityType.IllustrationTypesAdd,
																clientId
															)}

														/>
													)
												)}
												{authorizationService.renderIfAbilityPermitted(
													this.props.abilities,
													authorizationService
														.AbilityType
														.IllustrationTypesView,
													clientId,
													() => (
														<StoriesMenu
															onVisibilityStateEdit={
																this
																	.handleVisibilityStateEdit
															}
															onVisibilityStateDelete={
																this
																	.handleVisibilityStateDelete
															}
															onVisibilityStateAdd={this.handleVisibilityStateAdd}
															visibilityStateMap={
																visibilityStateMap
															}
															canEdit={
																canEditIllustrationVisibilityStates
															}
															canDelete={
																canDeleteIllustrationVisibilityStates
															}
															currentVisibilityStateKey={
																currentVisibilityStateKey
															}
															canAdd={authorizationService.isAbilityPermitted(
																this.props.abilities,
																authorizationService.AbilityType.IllustrationTypesAdd,
																clientId
															)}

														/>
													)
												)}
												{authorizationService.renderIfAbilityPermitted(
													this.props.abilities,
													authorizationService
														.AbilityType
														.IllustrationBulkAssociationEdit,
													clientId,
													() => (
														<AssociationsMenu
															boxTypeMap={boxTypes}
															canDeleteTypes={canDeleteIllustrationTypes}
															onBoxTypeDelete={this
																.handleBoxTypeClearAllAssociations}
															onDeleteAllAssociations={this
																.handleIllustrationClearAllAssociationsButtonClick}
														/>
													)
												)}
												{authorizationService.renderIfAbilityPermitted(
													this.props.abilities,
													authorizationService
														.AbilityType
														.IllustrationJSONView,
													clientId,
													() => (
														<JsonEditorMenu
															areJSONEditorInputsDisabled={areJSONEditorInputsDisabled}
															isJSONImportButtonsDisabled={isJSONImportButtonsDisabled}
															boxStyles={this.state.illustrationData ? this.state.illustrationData.boxStyles : undefined}
															boxTypes={this.state.illustrationData ? this.state.illustrationData.boxTypes : undefined}
															boxVisualisations={this.state.illustrationData ? this.state.illustrationData.boxVisualisations : undefined}
															boxes={this.state.illustrationData ? this.state.illustrationData.boxes : undefined}
															lensGroups={this.state.illustrationData ? this.state.illustrationData.lensGroups : undefined}
															lenses={this.state.illustrationData ? this.state.illustrationData.lenses : undefined}
															valueTypes={this.state.illustrationData ? this.state.illustrationData.valueTypes : undefined}
															onBoxStylesChange={this.handleBoxStylesChange}
															onBoxTypesChange={this.handleBoxTypesChange}
															onBoxVisualisationsChange={this.handleBoxVisualisationsChange}
															onBoxesChange={this.handleBoxesChange}
															onLensGroupsChange={this.handleLensGroupsChange}
															onLensesChange={this.handleLensesChange}
															onValueTypesChange={this.handleValueTypesChange}
														/>
													)
												)}
												{this.state.illustration
													? authorizationService.renderIfAbilityPermitted(
														this.props
															.abilities,
														authorizationService
															.AbilityType
															.IllustrationDetailsView,
														clientId,
														() => (
															<IllustrationDetailsMenu
																showEditButton={authorizationService.isAbilityPermitted(
																	this.props.abilities,
																	authorizationService.AbilityType.IllustrationDetailsEdit,
																	clientId
																)}
																showDeleteButton={
																	authorizationService.isAbilityPermitted(
																		this.props.abilities,
																		authorizationService.AbilityType.IllustrationDetailsDelete,
																		clientId
																	)
																}
																illustrationDescription={this.state.illustration.description}
																illustrationName={this.state.illustration.name}
																createdAt={this.state.illustration.createdAt}
																updatedAt={this.state.illustration.updatedAt}
																onEditIllustrationClick={this.handleEditIllustrationClick}
																onRemoveIllustrationClick={this.handleRemoveIllustrationClick}
															/>)
													) : null
												}
											</div>
											{isEditSidebarDisabled ? (
												<div
													style={{
														display: "inline",
														position: "absolute",
														top: 0,
														left: 0,
														width: editSidebarWidthInPixels,
														height: componentHeightInPixels,
														padding: 0,
														overflow: "hidden",
														backgroundColor:
															"rgba(0, 0, 0, 0.5)",
													}}
												/>
											) : null}
										</div>
										<ViewHistoryDialog
											onOKButtonClick={
												this.handleViewHistoryModalOK
											}
											onCancelButtonClick={
												this
													.handleViewHistoryModalCancel
											}
											isVisible={
												this.state
													.isViewHistoryModalVisible
											}
										/>
										<EditBoxDialog
											onOKButtonClick={
												this.handleEditBoxModalOK
											}
											onCancelButtonClick={
												this.handleEditBoxModalCancel
											}
											isVisible={
												this.state.isEditBoxModalVisible
											}
											box={this.state.editBoxModalBox}
											boxMap={boxes}
											flattenedBoxMap={this.state.flattenedBoxMap}
											boxTypes={boxTypes}
											boxStyles={boxStyles}
											boxKey={
												this.state.editBoxModalBoxKey
											}
											boxTypeCounters={this.state.boxTypeCounters}
											abilities={this.props.abilities}
											clientId={clientId}
										/>
										<AddEditBoxTypeDialog
											onOKButtonClick={
												this.handleAddEditBoxTypeModalOK
											}
											onCancelButtonClick={
												this
													.handleAddEditBoxTypeModalCancel
											}
											isAdding={
												this.state
													.isAddEditBoxTypeModalAdding
											}
											isVisible={
												this.state
													.isAddEditBoxTypeModalVisible
											}
											boxTypeKey={
												this.state
													.addEditBoxTypeModalBoxTypeKey
											}
											boxTypes={boxTypes}
											valueTypes={valueTypes}
											boxStyles={boxStyles}
										/>
										<AddEditVisibilityStateDialog
											onOKButtonClick={
												this
													.handleAddEditVisibilityStateModalOK
											}
											onCancelButtonClick={
												this
													.handleAddEditVisibilityStateModalCancel
											}
											isAdding={
												this.state
													.isAddEditVisibilityStateModalAdding
											}
											isVisible={
												this.state
													.isAddEditVisibilityStateModalVisible
											}
											visibilityStateKey={
												this.state
													.addEditVisibilityStateModalVisibilityStateKey
											}
											visibilityStateMap={
												visibilityStateMap
											}
											currentVisibilityState={
												currentVisibilityState
											}
										/>
										<ImportExportIllustrationDialog
											illustrationName={this.state.illustration.name}
											onImportButtonClick={
												this
													.handleImportExportIllustrationModalImport
											}
											onCloseOrCancelButtonClick={
												this
													.handleImportExportIllustrationModalCancelOrClose
											}
											isImporting={
												this.state
													.isImportExportIllustrationModalImporting
											}
											isVisible={
												this.state
													.isImportExportIllustrationModalVisible
											}
											illustrationData={
												this.state.illustrationData
											}
										/>
										<ImportExportIllustrationExcelDialog
											onImportButtonClick={
												this
													.handleImportExportIllustrationExcelModalImport
											}
											onCloseOrCancelButtonClick={
												this
													.handleImportExportIllustrationExcelModalCancelOrClose
											}
											isImporting={
												this.state
													.isImportExportIllustrationExcelModalImporting
											}
											isVisible={
												this.state
													.isImportExportIllustrationExcelModalVisible
											}
											illustration={illustration}
											illustrationName={
												this.state.illustration.name
											}
											boxMap={boxes}
											flattenedBoxMap={
												this.state.flattenedBoxMap
											}
											boxTypeMap={boxTypes}
										/>
										{this.state
											.editIllustrationModalIllustration ? (
											<AddEditIllustrationDialog
												isAdding={false}
												onAddOkButtonClick={this.handleAddIllustrationModalOK}
												onEditOkButtonClick={this.handleEditIllustrationModalOK}
												onCancelButtonClick={
													this
														.handleEditIllustrationCancel
												}
												isVisible={
													this.state
														.isEditIllustrationModalVisible
												}
												name={this.state.illustration.name}
												description={this.state.illustration.description}
												illustrationId={this.state.illustration._id}
												projectId={this.state.illustration.projectId}
											/>
										) : null}
										{this.state
											.isCreateAssociationsModalVisible &&
											boxTypes !== undefined &&
											this.state.firstSelectedBoxKey !==
											undefined &&
											this.state.boxSelectionInfoMap !==
											null &&
											this.state.flattenedBoxMap !== null &&
											this.state.flattenedBoxMap !== undefined ? (
											<CreateAssociationsDialog
												onCancelButtonClick={
													this
														.handleCreateAssociationsCancel
												}
												onOKButtonClick={
													this
														.handleCreateAssociationsOk
												}
												isVisible={
													this.state
														.isCreateAssociationsModalVisible
												}
												firstSelectedBoxKey={
													this.state
														.firstSelectedBoxKey
												}
												boxSelectionInfoMap={
													this.state
														.boxSelectionInfoMap
												}
												flattenedBoxMap={
													this.state.flattenedBoxMap
												}
												boxTypeMap={boxTypes}
											/>
										) : null}
										{this.state
											.isSimpleCreateAssociationsModalVisible &&
											boxTypes !== undefined &&
											this.state.firstSelectedBoxKey !==
											undefined &&
											this.state.boxSelectionInfoMap !==
											null &&
											this.state.flattenedBoxMap !== null &&
											this.state.flattenedBoxMap !== undefined ? (
											<CreateSimpleAssociationsDialog
												onCancelButtonClick={
													this
														.handleSimpleCreateAssociationsCancel
												}
												onOKButtonClick={
													this
														.handleSimpleCreateAssociationsOk
												}
												isVisible={
													this.state
														.isSimpleCreateAssociationsModalVisible
												}
												firstSelectedBoxKey={
													this.state
														.firstSelectedBoxKey
												}
												boxSelectionInfoMap={
													this.state
														.boxSelectionInfoMap
												}
												flattenedBoxMap={
													this.state.flattenedBoxMap
												}
												boxTypeMap={boxTypes}
											/>
										) : null}
										{this.state
											.isMultiEditBoxesModalVisible ? (
											<MultiEditBoxesDialog
												onOKButtonClick={
													this.handleMultiEditBoxesOK
												}
												onCancelButtonClick={
													this
														.handleMultiEditBoxesCancel
												}
												isVisible={
													this.state
														.isMultiEditBoxesModalVisible
												}
												boxKeys={selectedBoxKeys}
												flattenedBoxMap={
													this.state.flattenedBoxMap
												}
												boxParentMap={
													this.state.boxParentMap
												}
												boxTypes={boxTypes}
												boxStyles={boxStyles}
											/>
										) : null}
										{this.state.isViewBoxModalVisible &&
											this.state.viewBoxModalBox !==
											undefined &&
											this.state.viewBoxModalBox ? (
											<ViewBoxDialog
												isVisible={
													this.state
														.isViewBoxModalVisible
												}
												onOKButtonClick={
													this.handleViewBoxOK
												}
												onCancelButtonClick={
													this.handleViewBoxCancel
												}
												boxKey={
													this.state
														.viewBoxModalBoxKey
												}
												box={this.state.viewBoxModalBox}
												smartPageKey={
													this.state
														.viewBoxModalSmartPageKey
												}
												boxTypes={boxTypes}
												boxes={
													this.state.flattenedBoxMap
												}
											/>
										) : null}
									</Spin>
								</div>
							);
						}}
					</SizeMe>
				</Layout.Content>
			</Layout>
		);
	}

	private handleViewBoxOK = () => {
		this.setState({
			isViewBoxModalVisible: false,
			viewBoxModalBoxKey: '',
			viewBoxModalBox: undefined,
		});
	};

	private handleViewBoxCancel = () => {
		this.setState({
			isViewBoxModalVisible: false,
			viewBoxModalBoxKey: '',
			viewBoxModalBox: undefined,
		});
	};

	private handleVisibilityStateChange = (visibilityStateKey: string) => {
		// The updated state
		let updatedState: any = {};

		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get a copy of the illustration data
			const updatedIllustrationData = this.cloneIllustrationData(
				this.state.illustrationData
			);

			const previousVisibilityStateKey = getCurrentVisibilityStateKey(
				updatedIllustrationData
			);

			const previousVisibilityState = getCurrentVisibilityState(
				previousVisibilityStateKey,
				updatedIllustrationData
			);

			// Set the new current visibility state
			updatedIllustrationData.currentVisibilityStateKey = visibilityStateKey;

			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				updatedIllustrationData
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				currentVisibilityStateKey,
				updatedIllustrationData
			);

			const nextVisibilityState = visibilityStateLib
				.syncNextVisibilityState(previousVisibilityState, currentVisibilityState);

			console.log(`switching to ${currentVisibilityStateKey}`);
			console.log(currentVisibilityState);

			// Get the updated state for the illustration data
			updatedState = this.getUpdatedStateForIllustrationData(
				updatedIllustrationData,
				this.state.illustrationData,
				this.state.flattenedBoxMap,
				nextVisibilityState
			);
		}

		// Update the current visibility state in our state
		this.setState({
			...updatedState,
		});
	};

	private renderIllustrationStateButtons = () => {
		// The states of the buttons (assume "inactive" by default)
		let viewButtonGhost = true;
		let editBoxButtonGhost = true;
		let editMultiBoxButtonGhost = true;
		let editAssociationsButtonGhost = true;
		let advancedEditAssociationsButtonGhost = true;

		// The rendered accept/reject selection buttons
		let renderedAcceptRejectSelectionButtons = null;

		// Whether we're showing accept/reject selection buttons
		let showAcceptRejectSelectionButtons = false;

		// Figure out the button states based on the current state
		if (
			this.state.illustrationState ===
			illustrationLib.IllustrationState.EDIT_BOX
		) {
			// Don't make the edit box button inactive
			editBoxButtonGhost = false;
		} else if (
			this.state.illustrationState ===
			illustrationLib.IllustrationState.EDIT_MULTI_BOX
		) {
			// Don't make the multi-edit box button inactive
			editMultiBoxButtonGhost = false;

			// Do we have any boxes selected?
			if (Object.keys(this.state.boxSelectionInfoMap).length > 0) {
				// Show the accept/reject selection buttons
				showAcceptRejectSelectionButtons = true;
			}
		} else if (
			this.state.illustrationState ===
			illustrationLib.IllustrationState.EDIT_ASSOCIATIONS
		) {
			// Don't make the edit association button inactive
			editAssociationsButtonGhost = false;

			// Do we have any boxes selected?
			if (Object.keys(this.state.boxSelectionInfoMap).length > 0) {
				// Show the accept/reject selection buttons
				showAcceptRejectSelectionButtons = true;
			}
		} else if (
			this.state.illustrationState ===
			illustrationLib.IllustrationState.SIMPLE_EDIT_ASSOCIATIONS
		) {
			// Don't make the edit association button inactive
			advancedEditAssociationsButtonGhost = false;

			// Do we have any boxes selected?
			if (Object.keys(this.state.boxSelectionInfoMap).length > 0) {
				// Show the accept/reject selection buttons
				showAcceptRejectSelectionButtons = true;
			}
		} else if (
			this.state.illustrationState ===
			illustrationLib.IllustrationState.VIEW
		) {
			// Don't make the view button inactive
			viewButtonGhost = false;
		}

		// Should we show the accept/reject selection buttons?
		if (showAcceptRejectSelectionButtons) {
			renderedAcceptRejectSelectionButtons = (
				<div
					style={{
						float: "right",
					}}
				>
					<div
						style={{
							float: "right",
							height: "24px",
							marginRight: "0.5em",
							marginTop: "8px",
							paddingRight: "1em",
							borderRightWidth: "1px",
							borderRightColor: "rgba(0, 0, 0, 0.45)",
							borderRightStyle: "solid",
						}}
					>
						&nbsp;
					</div>
					<Tooltip title="Cancel Selection">
						<Button
							size="large"
							shape={configurationLib.DEFAULT_BUTTON_SHAPE}
							style={{
								float: "right",
								marginLeft: "1.5em",
								color:
									configurationLib.DEFAULT_BUTTON_REJECT_SELECTED_BOXES_COLOR,
								backgroundColor:
									configurationLib.DEFAULT_BUTTON_REJECT_SELECTED_BOXES_BACKGROUND_COLOR,
								border: 0,
							}}
							icon={<StopOutlined />}
							onClick={() =>
								this.handlRejectSelectedBoxesButtonClick()
							}
						/>
					</Tooltip>
					<Tooltip title="Delete Selection">
						<Button
							size="large"
							shape={configurationLib.DEFAULT_BUTTON_SHAPE}
							style={{
								float: "right",
								marginLeft: "1.5em",
								color:
									configurationLib.DEFAULT_BUTTON_DELETE_SELECTED_BOXES_COLOR,
								backgroundColor:
									configurationLib.DEFAULT_BUTTON_DELETE_SELECTED_BOXES_BACKGROUND_COLOR,
								border: 0,
							}}
							icon={<DeleteOutlined />}
							onClick={() =>
								this.handleDeleteSelectedBoxesButtonClick()
							}
						/>
					</Tooltip>
					<Tooltip title="Accept Selection">
						<Button
							size="large"
							shape={configurationLib.DEFAULT_BUTTON_SHAPE}
							style={{
								float: "right",
								marginLeft: "0.5em",
								color:
									configurationLib.DEFAULT_BUTTON_ACCEPT_SELECTED_BOXES_COLOR,
								backgroundColor:
									configurationLib.DEFAULT_BUTTON_ACCEPT_SELECTED_BOXES_BACKGROUND_COLOR,
								border: 0,
							}}
							icon={<CheckOutlined />}
							onClick={() =>
								this.handleAcceptSelectedBoxesButtonClick()
							}
						/>
					</Tooltip>
				</div>
			);
		}

		return (
			<div
				style={{
					float: "right",
				}}
			>
				<Tooltip title="Advanced Association Mode">
					<Button
						size="large"
						shape={configurationLib.DEFAULT_BUTTON_SHAPE}
						style={{
							float: "right",
							marginLeft: "0.5em",
							color:
								configurationLib.DEFAULT_BUTTON_EDIT_ASSOCIATION_COLOR,
							backgroundColor:
								editAssociationsButtonGhost ? "transparent" : configurationLib.DEFAULT_BUTTON_EDIT_ASSOCIATION_BACKGROUND_COLOR,
							border: 0,
						}}
						icon={
							<LinkOutlined />
						}
						ghost={editAssociationsButtonGhost}
						onClick={() =>
							this.setIllustrationState(
								illustrationLib.IllustrationState
									.EDIT_ASSOCIATIONS
							)
						}
					/>
				</Tooltip>
				<Tooltip title="Association Mode">
					<Button
						size="large"
						shape={configurationLib.DEFAULT_BUTTON_SHAPE}
						style={{
							float: "right",
							marginLeft: "0.5em",
							color:
								configurationLib.DEFAULT_BUTTON_EDIT_ASSOCIATION_COLOR,
							backgroundColor:
								advancedEditAssociationsButtonGhost ? "transparent" : configurationLib.DEFAULT_BUTTON_EDIT_ASSOCIATION_BACKGROUND_COLOR,
							border: 0,
						}}
						icon={
							<ShareAltOutlined />
						}
						ghost={advancedEditAssociationsButtonGhost}
						onClick={() =>
							this.setIllustrationState(
								illustrationLib.IllustrationState
									.SIMPLE_EDIT_ASSOCIATIONS
							)
						}
					/>
				</Tooltip>
				<Tooltip title="Multi-Edit Mode">
					<Button
						size="large"
						shape={configurationLib.DEFAULT_BUTTON_SHAPE}
						style={{
							float: "right",
							marginLeft: "0.5em",
							color:
								configurationLib.DEFAULT_BUTTON_EDIT_MULTI_BOX_COLOR,
							backgroundColor:
								editMultiBoxButtonGhost ? "transparent" : configurationLib.DEFAULT_BUTTON_EDIT_MULTI_BOX_BACKGROUND_COLOR,
							border: 0,
						}}
						icon={
							<AppstoreOutlined />
						}
						ghost={editMultiBoxButtonGhost}
						onClick={() =>
							this.setIllustrationState(
								illustrationLib.IllustrationState.EDIT_MULTI_BOX
							)
						}
					/>
				</Tooltip>
				<Tooltip title="Edit Mode">
					<Button
						size="large"
						shape={configurationLib.DEFAULT_BUTTON_SHAPE}
						style={{
							float: "right",
							marginLeft: "0.5em",
							color:
								configurationLib.DEFAULT_BUTTON_EDIT_EDIT_BOX_COLOR,
							backgroundColor:
								editBoxButtonGhost ?
									"transparent" : configurationLib.DEFAULT_BUTTON_EDIT_EDIT_BOX_BACKGROUND_COLOR,
							border: 0,
						}}
						icon={
							<BorderOutlined />
						}
						ghost={editBoxButtonGhost}
						onClick={() =>
							this.setIllustrationState(
								illustrationLib.IllustrationState.EDIT_BOX
							)
						}
					/>
				</Tooltip>
				<Tooltip title="View Mode">
					<Button
						size="large"
						shape={configurationLib.DEFAULT_BUTTON_SHAPE}
						style={{
							float: "right",
							marginLeft: "0.5em",
							color:
								configurationLib.DEFAULT_BUTTON_EDIT_VIEW_COLOR,
							backgroundColor:
								viewButtonGhost ? "transparent" : configurationLib.DEFAULT_BUTTON_EDIT_VIEW_BACKGROUND_COLOR,
							border: 0,
						}}
						icon={
							<EyeOutlined />
						}
						ghost={viewButtonGhost}
						onClick={() =>
							this.setIllustrationState(
								illustrationLib.IllustrationState.VIEW
							)
						}
					/>
				</Tooltip>
				{renderedAcceptRejectSelectionButtons}
			</div>
		);
	};

	private setIllustrationState = (
		illustrationState: illustrationLib.IllustrationState
	) => {
		// Update the illustration state and clear any selected boxes
		this.setState({
			illustrationState,
			boxSelectionInfoMap: {},
			firstSelectedBoxKey: undefined,
		});
	};

	private handleAddUndo = (patches: Patch[], inversePatches: Patch[]) => {
		const undos = JSON.parse(JSON.stringify(this.state.undos));
		undos.push(inversePatches);
		this.setState({
			undos,
		});
	};

	private handleAddRedo = (patches: Patch[], inversePatches: Patch[]) => {
		// console.log("handleAddRedo");
		// console.log(inversePatches);

		const redos = JSON.parse(JSON.stringify(this.state.redos));
		redos.push(inversePatches);
		this.setState({
			redos,
		});
	};

	private applyUndo = () => {
		if (this.state.undos.length > 0) {
			const undos = JSON.parse(JSON.stringify(this.state.undos));

			const inversePatches = undos.pop();

			const updatedIllustrationData =
				inversePatches && this.state.illustrationData
					? produce(
						this.state.illustrationData,
						(draft) => {
							return applyPatches(draft, inversePatches);
						},
						this.handleAddRedo
					)
					: this.state.illustrationData;

			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				updatedIllustrationData
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				currentVisibilityStateKey,
				updatedIllustrationData
			);

			// Get the updated state for the illustration data
			const updatedState = this.getUpdatedStateForIllustrationData(
				updatedIllustrationData,
				this.state.illustrationData,
				this.state.flattenedBoxMap,
				currentVisibilityState
			);

			this.setState({
				...updatedState,
				undos,
			});
		}
	};

	private applyRedo = () => {
		if (this.state.redos.length > 0) {
			const redos = JSON.parse(JSON.stringify(this.state.redos));
			const inversePatches = redos.pop();
			const updatedIllustrationData =
				inversePatches && this.state.illustrationData
					? produce(
						this.state.illustrationData,
						(draft) => {
							return applyPatches(draft, inversePatches);
						},
						this.handleAddUndo
					)
					: this.state.illustrationData;

			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				updatedIllustrationData
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				currentVisibilityStateKey,
				updatedIllustrationData
			);

			// Get the updated state for the illustration data
			const updatedState = this.getUpdatedStateForIllustrationData(
				updatedIllustrationData,
				this.state.illustrationData,
				this.state.flattenedBoxMap,
				currentVisibilityState
			);

			this.setState({
				...updatedState,
				redos,
			});
		}
	};

	private handleDeleteSelectedBoxesButtonClick = () => {
		let updatedState = {}

		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get the selected box keys
			const selectedBoxKeys = Object.keys(this.state.boxSelectionInfoMap);

			// Get a copy of the illustration data
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					if (draft && draft.boxes) {
						selectedBoxKeys.forEach((boxKey) => {
							// Find the parent box of the box we'll be removing
							const parentBox = boxLib.findBoxParentInBoxMapForKey(
								undefined,
								draft.boxes,
								boxKey
							);

							// Did we find the box?
							if (parentBox) {
								// Remove the child from its parent
								if (parentBox.children) {
									if (parentBox.children[boxKey]) {
										delete parentBox.children[boxKey];
									}
								}
							}
						});
					}
				},
				this.handleAddUndo
			);

			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				updatedIllustrationData
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				currentVisibilityStateKey,
				updatedIllustrationData
			);

			// Get the updated state for the illustration data
			updatedState = this.getUpdatedStateForIllustrationData(
				updatedIllustrationData,
				this.state.illustrationData,
				this.state.flattenedBoxMap,
				currentVisibilityState
			);
		}

		// Clear the selection and go back to the edit box state
		this.setState({
			...updatedState,
			boxSelectionInfoMap: {},
			firstSelectedBoxKey: undefined,
			illustrationState: illustrationLib.IllustrationState.EDIT_BOX,
		});
	};

	private handlRejectSelectedBoxesButtonClick = () => {
		// Clear the selection and go back to the edit box state
		this.setState({
			boxSelectionInfoMap: {},
			firstSelectedBoxKey: undefined,
			illustrationState: illustrationLib.IllustrationState.EDIT_BOX,
		});
	};

	private handleAcceptSelectedBoxesButtonClick = () => {
		// console.log('handleAcceptSelectedBoxesButtonClick');

		// console.log(`First selected box: ${this.state.firstSelectedBoxKey}`);
		// console.log(`Selected boxes: ${Object.keys(this.state.boxSelectionInfoMap).join(', ')}`)

		// The updated state
		const updatedState: any = {};

		// TODO: Handle the selected boxes based on the illustration state
		if (
			this.state.illustrationState ===
			illustrationLib.IllustrationState.EDIT_MULTI_BOX
		) {
			// console.log('Handling multi-edit of boxes');

			// Show the multi-edit box dialog
			updatedState.isMultiEditBoxesModalVisible = true;
		} else if (
			this.state.illustrationState ===
			illustrationLib.IllustrationState.EDIT_ASSOCIATIONS
		) {
			// console.log('Handling associations of boxes');

			// Show the associations dialog
			updatedState.isCreateAssociationsModalVisible = true;
		} else if (
			this.state.illustrationState ===
			illustrationLib.IllustrationState.SIMPLE_EDIT_ASSOCIATIONS
		) {
			// console.log('Handling associations of boxes');

			// Show the associations dialog
			updatedState.isSimpleCreateAssociationsModalVisible = true;
		}

		// Clear the selection and go back to the edit box state
		this.setState({
			illustrationState: illustrationLib.IllustrationState.EDIT_BOX,
			...updatedState,
		});
	};

	private handleBoxTypeAdd = () => {
		// console.log('handleBoxTypeAdd()');

		// Open the add/edit box type modal dialog
		this.setState({
			isAddEditBoxTypeModalAdding: true,
			isAddEditBoxTypeModalVisible: true,
			addEditBoxTypeModalBoxTypeKey: boxTypeLib.getNewBoxTypeKey(),
		});
	};

	private handleBoxTypeEdit = (boxTypeKey: string) => {
		// console.log(`handleBoxTypeEdit(${boxTypeKey})`);

		// Open the add/edit box type modal dialog
		this.setState({
			isAddEditBoxTypeModalAdding: false,
			isAddEditBoxTypeModalVisible: true,
			addEditBoxTypeModalBoxTypeKey: boxTypeKey,
		});
	};

	private handleBoxTypeDelete = (boxTypeKey: string) => {
		// console.log(`handleBoxTypeDelete(${boxTypeKey})`);

		// Save 'this'
		const self = this;

		// As the user if they're sure they want to delete the box type
		Modal.confirm({
			zIndex: 9999,
			content: "Are you sure you want to delete this box type?",
			onOk() {
				// Do we have illustration data?
				if (self.state.illustrationData) {
					// Get a copy of the illustration data
					const updatedIllustrationData = produce(
						self.state.illustrationData,
						(draft) => {
							if (draft && draft.boxTypes) {
								// Delete the box type
								delete draft.boxTypes[boxTypeKey];
							}
						},
						self.handleAddUndo
					);

					// Store the updated box types
					self.setState({
						illustrationData: updatedIllustrationData,
					});
				}
			},
			onCancel() {
				// console.log('Cancel');
			},
		});
	};

	private getSimpleLensGroupsForUnprocessedSimpleLensGroups = (simpleLensGroups: lensesLib.SimpleLensGroupMap) => {
		const groups: lensesLib.LensGroupMap = {};

		Object.keys(simpleLensGroups).forEach((key) => {
			const simpleLensGroup = simpleLensGroups[key];

			const group: lensesLib.LensGroup = {
				name: simpleLensGroup.name,
				lensKeys: Object.keys(simpleLensGroup.lenses),
				order: simpleLensGroup.order
			}

			groups[key] = group;
		});

		return groups;

	}

	private getSimpleLensesForUnprocessedSimpleLensGroups = (simpleLensGroups: lensesLib.SimpleLensGroupMap) => {
		const lenses: lensesLib.LensMap = {};

		Object.keys(simpleLensGroups).forEach((groupKey) => {
			const simpleLensGroup = simpleLensGroups[groupKey];

			Object.keys(simpleLensGroup.lenses).forEach((lensKey) => {
				const simpleLens = simpleLensGroup.lenses[lensKey];
				const lens: lensesLib.Lens = {
					name: simpleLens.name,
					order: simpleLens.order,
					parameters: {},
					queries: simpleLens.entries.map<lensesLib.Query>(
						(entry): lensesLib.Query => {
							const attributeTypeExpressions: lensesLib.AttributeTypeExpressionMap = {};
							attributeTypeExpressions[entry.attributeTypeKey] = lensesLib.EqualityOperator.EQUAL;

							const boxTypeExpressions: lensesLib.BoxTypeExpressionMap = {};
							boxTypeExpressions[entry.boxTypeKey] = lensesLib.EqualityOperator.EQUAL;


							const boxTypeAttributeTypeVisibilityMap: AttributeTypeVisibilityMap = {};
							boxTypeAttributeTypeVisibilityMap[entry.attributeTypeKey] = true;

							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							const query: lensesLib.Query = {
								boxTypeExpressions: boxTypeExpressions,
								attributeTypeExpressions: attributeTypeExpressions,
								"attributeTypeExpressionOperator": lensesLib.BooleanOperator.OR,
								"operator": lensesLib.BooleanOperator.OR,
								"applyBoxTypeVisibility": true,
								"boxTypeAttributeTypeVisibilityMap": boxTypeAttributeTypeVisibilityMap,
							};

							// "simpleLenses": {
							// 	"641c67a0-366e-4f97-8ddc-84e00f41f275": {
							// 	  "name": "Set the background",
							// 	  "order": 0,
							// 	  "parameters": {},
							// 	  "queries": [
							// 		{
							// 		  "boxTypeExpressions": {},
							// 		  "attributeTypeExpressions": {
							// 			"815aef5e-e7a9-4316-b2ca-23216d3b550c": "==",
							// 			"base": "=="
							// 		  },
							// 		  "attributeTypeExpressionOperator": "or",
							// 		  "operator": "or",
							// 		  "applyBoxTypeVisibility": true
							// 		}
							// 	  ]
							// 	}

							return query;

						}
					)
				}

				lenses[lensKey] = lens;
			});
		});

		return lenses;

	};


	private handlePowerLensGroupAddEdit = (lensGroupKey: string, lensGroup: lensesLib.LensGroup) => {
		// The updated state
		let updatedState: any = {};

		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Update the illustration data in a way that can be undone
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					if (draft) {
						if (draft.lensGroups === undefined) {
							draft.lensGroups = {};
						}

						console.log("The lens group is...")
						console.log(lensGroup);

						draft.lensGroups[lensGroupKey] = lensGroup;
					}
				},
				this.handleAddUndo
			);

			// Update the simple lens group
			this.setState({
				illustrationData: updatedIllustrationData,
			});
		}



		// Update our state and close the box modal dialog
		this.setState({
			...updatedState,
			//boxTypeCounters,
			// isEditBoxModalVisible: false,
			// editBoxModalBoxKey: '',
			// editBoxModalBox: undefined,
		});

	}

	private handlePowerLensGroupDelete = (lensGroupKey: string) => {
		// The updated state
		let updatedState: any = {};

		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				this.state.illustrationData
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				currentVisibilityStateKey,
				this.state.illustrationData
			);


			// Update the illustration data in a way that can be undone
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					if (draft) {
						if (draft.lensGroups === undefined) {
							draft.lensGroups = {};
						}

						delete draft.lensGroups[lensGroupKey];
					}
				},
				this.handleAddUndo
			);

			// Get the updated state for the illustration data
			updatedState = this.getUpdatedStateForIllustrationData(
				updatedIllustrationData,
				this.state.illustrationData,
				this.state.flattenedBoxMap,
				currentVisibilityState
			);
		}
		// Update our state and close the box modal dialog
		this.setState({
			...updatedState,
			//boxTypeCounters,
			// isEditBoxModalVisible: false,
			// editBoxModalBoxKey: '',
			// editBoxModalBox: undefined,
		});

	}

	private handlePowerLensAddEdit = (powerLensKey: string, powerLens: lensesLib.Lens) => {
		// The updated state
		let updatedState: any = {};

		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Update the illustration data in a way that can be undone
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					if (draft) {
						if (draft.lenses === undefined) {
							draft.lenses = {};
						}

						draft.lenses[powerLensKey] = powerLens;
					}
				},
				this.handleAddUndo
			);

			// TODO: Do we need to call this.getUpdatedStateForIllustrationData?
			// Update the simple lens group
			this.setState({
				illustrationData: updatedIllustrationData,
			});
		}

		// TODO: is this doing what it's meant to do? UpdatedState never gets set./

		// Update our state and close the box modal dialog
		this.setState({
			...updatedState,
			//boxTypeCounters,
			// isEditBoxModalVisible: false,
			// editBoxModalBoxKey: '',
			// editBoxModalBox: undefined,
		});

	}

	private handlePowerLensDelete = (powerLensKey: string) => {
		// The updated state
		let updatedState: any = {};

		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				this.state.illustrationData
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				currentVisibilityStateKey,
				this.state.illustrationData
			);


			// Update the illustration data in a way that can be undone
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					if (draft) {
						if (draft.lenses === undefined) {
							draft.lenses = {};
						}

						// Delete the Lens from all the lens groups that it's used in

						if (draft.lensGroups !== undefined) {
							Object.keys(draft.lensGroups).forEach((lensGroupKey) => {
								if (
									draft.lensGroups !== undefined &&
									draft.lensGroups[lensGroupKey] !== undefined &&
									draft.lensGroups[lensGroupKey].lensKeys !== undefined
								) {
									draft.lensGroups[lensGroupKey].lensKeys = draft.lensGroups[lensGroupKey].lensKeys.filter((x) => x !== powerLensKey);
								}
							});
						}

						delete draft.lenses[powerLensKey];
					}
				},
				this.handleAddUndo
			);

			// Get the updated state for the illustration data
			updatedState = this.getUpdatedStateForIllustrationData(
				updatedIllustrationData,
				this.state.illustrationData,
				this.state.flattenedBoxMap,
				currentVisibilityState
			);
		}
		// Update our state and close the box modal dialog
		this.setState({
			...updatedState,
			//boxTypeCounters,
			// isEditBoxModalVisible: false,
			// editBoxModalBoxKey: '',
			// editBoxModalBox: undefined,
		});

	}


	private handleSimpleLensGroupAddEdit = (lensGroupKey: string, simpleLensGroup: lensesLib.SimpleLensGroup) => {
		// The updated state
		let updatedState: any = {};

		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Update the illustration data in a way that can be undone
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					if (draft) {
						if (draft.unprocessedSimpleLensGroups === undefined) {
							draft.unprocessedSimpleLensGroups = {};
						}

						draft.unprocessedSimpleLensGroups[lensGroupKey] = simpleLensGroup;

						draft.simpleLensGroups = this.getSimpleLensGroupsForUnprocessedSimpleLensGroups(draft.unprocessedSimpleLensGroups);
						draft.simpleLenses = this.getSimpleLensesForUnprocessedSimpleLensGroups(draft.unprocessedSimpleLensGroups);
					}
				},
				this.handleAddUndo
			);

			// Update the simple lens group
			this.setState({
				illustrationData: updatedIllustrationData,
			});
		}
		// Update our state and close the box modal dialog
		this.setState({
			...updatedState,
			//boxTypeCounters,
			// isEditBoxModalVisible: false,
			// editBoxModalBoxKey: '',
			// editBoxModalBox: undefined,
		});

	}


	private handleSimpleLensGroupDelete = (lensGroupKey: string) => {
		// The updated state
		let updatedState: any = {};

		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				this.state.illustrationData
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				currentVisibilityStateKey,
				this.state.illustrationData
			);


			// Update the illustration data in a way that can be undone
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					if (draft) {
						if (draft.unprocessedSimpleLensGroups === undefined) {
							draft.unprocessedSimpleLensGroups = {};
						}

						delete draft.unprocessedSimpleLensGroups[lensGroupKey];

						draft.simpleLensGroups = this.getSimpleLensGroupsForUnprocessedSimpleLensGroups(draft.unprocessedSimpleLensGroups);
						draft.simpleLenses = this.getSimpleLensesForUnprocessedSimpleLensGroups(draft.unprocessedSimpleLensGroups);
					}
				},
				this.handleAddUndo
			);

			// Get the updated state for the illustration data
			updatedState = this.getUpdatedStateForIllustrationData(
				updatedIllustrationData,
				this.state.illustrationData,
				this.state.flattenedBoxMap,
				currentVisibilityState
			);
		}
		// Update our state and close the box modal dialog
		this.setState({
			...updatedState,
			//boxTypeCounters,
			// isEditBoxModalVisible: false,
			// editBoxModalBoxKey: '',
			// editBoxModalBox: undefined,
		});

	}


	private handleVisibilityStateAdd = () => {
		// console.log('handleVisibilityStateAdd()');

		// Open the add/edit box type modal dialog
		this.setState({
			isAddEditVisibilityStateModalAdding: true,
			isAddEditVisibilityStateModalVisible: true,
			addEditVisibilityStateModalVisibilityStateKey: visibilityStateLib.getNewVisibilityStateKey(),
		});
	};

	private handleVisibilityStateEdit = (visibilityStateKey: string) => {
		// console.log(`handleVisibilityStateEdit(${visibilityStateKey})`);

		// Open the add/edit box type modal dialog
		this.setState({
			isAddEditVisibilityStateModalAdding: false,
			isAddEditVisibilityStateModalVisible: true,
			addEditVisibilityStateModalVisibilityStateKey: visibilityStateKey,
		});
	};

	private handleVisibilityStateDelete = (visibilityStateKey: string) => {
		// console.log(`handleVisibilityStateDelete(${visibilityStateKey})`);

		// Save 'this'
		const self = this;

		// As the user if they're sure they want to delete the visibility state
		Modal.confirm({
			zIndex: 9999,
			content: "Are you sure you want to delete this filter?",
			onOk() {
				// Do we have illustration data?
				if (self.state.illustrationData) {
					// Get a copy of the illustration data
					const updatedIllustrationData = produce(
						self.state.illustrationData,
						(draft) => {
							if (draft && draft.visibilityStateMap) {
								// Delete the visibility state
								delete draft.visibilityStateMap[
									visibilityStateKey
								];
							}
						},
						self.handleAddUndo
					);

					// Store the updated box types
					self.setState({
						illustrationData: updatedIllustrationData,
					});
				}
			},
			onCancel() {
				// console.log('Cancel');
			},
		});
	};

	private handleRenderFunctionLatchModeVisibilityChange = (
		boxTypeKey: string,
		attributeTypeKey: string,
		renderFunctionKey: string,
		enabled: boolean) => {

		// Get the current visibility state key
		const currentVisibilityStateKey = getCurrentVisibilityStateKey(
			this.state.illustrationData
		);

		// Get the visibility state
		const currentVisibilityState = getCurrentVisibilityState(
			currentVisibilityStateKey,
			this.state.illustrationData
		);

		// Get a copy of the current visibility state
		const updatedCurrentVisibilityState = JSON.parse(
			JSON.stringify(currentVisibilityState)
		);

		// Get the box type visibility map
		const updatedBoxTypeVisibilityMap =
			updatedCurrentVisibilityState.boxTypeVisibilityMap;

		// Get the box type visibility
		const updatedBoxTypeVisibility = boxTypeLib.getBoxTypeVisibilityForBoxTypeKeyHierarchy(
			updatedBoxTypeVisibilityMap,
			[boxTypeKey]
		);
		
		if (updatedBoxTypeVisibility) {

			const newRenderFunctionMap = { [`${boxTypeKey}_${attributeTypeKey}_${renderFunctionKey}`] : enabled };

			const updatedRenderFunctionMap = updatedBoxTypeVisibility.renderFunctionLatchModeVisibilityMap ? 
				{...updatedBoxTypeVisibility.renderFunctionLatchModeVisibilityMap, ...newRenderFunctionMap} : newRenderFunctionMap
		
			updatedBoxTypeVisibility.renderFunctionLatchModeVisibilityMap = updatedRenderFunctionMap;

			updatedCurrentVisibilityState.boxTypeVisibilityMap[boxTypeKey] = updatedBoxTypeVisibility;

			// Get the illustration data
			const illustrationData = this.state.illustrationData;
			if (illustrationData) {
				// Get the updated state for the illustration data
				const updatedState = this.getUpdatedStateForIllustrationData(
					illustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					updatedCurrentVisibilityState
				);

				// Update the state
				this.setState({
					...updatedState,
				});
			}
		}

	};

	private handleBoxTypeBoxesVisibilityChange = (
		boxTypeKeyHierarchy: string[],
		areBoxesVisible: boolean,
		areBoxesInLayout: boolean
	) => {
		// console.log(`handleBoxTypeBoxesVisibilityChange(${boxTypeKeyHierarchy.join(',')}, ${areBoxesVisible}, ${areBoxesInLayout})`);

		// Get the current visibility state key
		const currentVisibilityStateKey = getCurrentVisibilityStateKey(
			this.state.illustrationData
		);

		// Get the visibility state
		const currentVisibilityState = getCurrentVisibilityState(
			currentVisibilityStateKey,
			this.state.illustrationData
		);

		// Get a copy of the current visibility state
		const updatedCurrentVisibilityState = JSON.parse(
			JSON.stringify(currentVisibilityState)
		);

		// Get the box type visibility map
		const updatedBoxTypeVisibilityMap =
			updatedCurrentVisibilityState.boxTypeVisibilityMap;

		// Get the box type visibility
		const updatedBoxTypeVisibility = boxTypeLib.getBoxTypeVisibilityForBoxTypeKeyHierarchy(
			updatedBoxTypeVisibilityMap,
			boxTypeKeyHierarchy
		);

		// console.log('updatedBoxTypeVisibility')
		// console.log(updatedBoxTypeVisibility)

		if (updatedBoxTypeVisibility) {
			// Toggle the box type visibility
			updatedBoxTypeVisibility.areBoxesVisible = areBoxesVisible;
			updatedBoxTypeVisibility.areBoxesInLayout = areBoxesInLayout;

			// Get the illustration data
			const illustrationData = this.state.illustrationData;
			if (illustrationData) {
				// Get the updated state for the illustration data
				const updatedState = this.getUpdatedStateForIllustrationData(
					illustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					updatedCurrentVisibilityState
				);

				// Update the state
				this.setState({
					...updatedState,
				});
			}
		}
	};

	private handleBoxTypeVisibilityChange = (
		boxTypeKeyHierarchy: string[],
		isVisible: boolean,
		includeAttributes: boolean,
	) => {
		// console.log(`handleBoxTypeVisibilityChange(${boxTypeKeyHierarchy.join(',')}, ${isVisible})`);

		// Get the current visibility state key
		const currentVisibilityStateKey = getCurrentVisibilityStateKey(
			this.state.illustrationData
		);

		// Get the visibility state
		const currentVisibilityState = getCurrentVisibilityState(
			currentVisibilityStateKey,
			this.state.illustrationData
		);

		// Get a copy of the current visibility state
		const updatedCurrentVisibilityState = JSON.parse(
			JSON.stringify(currentVisibilityState)
		);

		// Get the box type visibility map
		const updatedBoxTypeVisibilityMap =
			updatedCurrentVisibilityState.boxTypeVisibilityMap;

		// Get the box type visibility
		const updatedBoxTypeVisibility = boxTypeLib.getBoxTypeVisibilityForBoxTypeKeyHierarchy(
			updatedBoxTypeVisibilityMap,
			boxTypeKeyHierarchy
		);

		// console.log('updatedBoxTypeVisibility')
		// console.log(updatedBoxTypeVisibility)

		if (updatedBoxTypeVisibility) {
			// Toggle the box type visibility
			updatedBoxTypeVisibility.isVisible = isVisible;
			updatedBoxTypeVisibility.areBoxesVisible = isVisible;
			updatedBoxTypeVisibility.areBoxesInLayout = isVisible;

			if (includeAttributes) {
				// Toggle the visibility of its attribute types
				if (updatedBoxTypeVisibility.attributeTypeVisibilityMap) {
					Object.keys(
						updatedBoxTypeVisibility.attributeTypeVisibilityMap
					).forEach((attributeTypeKey: string) => {
						// Do we have attribute type visibilities?
						if (updatedBoxTypeVisibility.attributeTypeVisibilityMap) {
							// Set the attribute type visibility
							updatedBoxTypeVisibility.attributeTypeVisibilityMap[
								attributeTypeKey
							] = isVisible;
						}
					});
				}
			}

			// TODO: Set the visibility of our mixins

			// console.log("handleBoxTypeVisibilityChange - Type Visibility Map");
			// console.log(updatedBoxTypeVisibilityMap);

			// Get the illustration data
			const illustrationData = this.state.illustrationData;
			if (illustrationData) {
				// Get the updated state for the illustration data
				const updatedState = this.getUpdatedStateForIllustrationData(
					illustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					updatedCurrentVisibilityState
				);

				// Update the state
				this.setState({
					...updatedState,
				});
			}
		}
	};

	private handleAttributeTypeVisibilityChange = (
		boxTypeKeyHierarchy: string[],
		attributeTypeKey: string,
		isVisible: boolean
	) => {
		// console.log(`handleAttributeTypeVisibilityChange(${boxTypeKeyHierarchy.join(',')}, ${attributeTypeKey}, ${isVisible})`);

		// Get the current visibility state key
		const currentVisibilityStateKey = getCurrentVisibilityStateKey(
			this.state.illustrationData
		);

		// Get the visibility state
		const currentVisibilityState = getCurrentVisibilityState(
			currentVisibilityStateKey,
			this.state.illustrationData
		);

		// Get a copy of the current visibility state
		const updatedCurrentVisibilityState = JSON.parse(
			JSON.stringify(currentVisibilityState)
		);

		// Get the box type visibility map
		const updatedBoxTypeVisibilityMap =
			updatedCurrentVisibilityState.boxTypeVisibilityMap;

		// Get the box type visibility
		const updatedBoxTypeVisibility = boxTypeLib.getBoxTypeVisibilityForBoxTypeKeyHierarchy(
			updatedBoxTypeVisibilityMap,
			boxTypeKeyHierarchy
		);
		if (updatedBoxTypeVisibility) {
			// console.log('updatedBoxTypeVisibility')
			// console.log(updatedBoxTypeVisibility)

			// Do we have attribute type visibilities?
			if (updatedBoxTypeVisibility.attributeTypeVisibilityMap) {
				// Set the attribute type visibility
				updatedBoxTypeVisibility.attributeTypeVisibilityMap[
					attributeTypeKey
				] = isVisible;
			}

			// console.log("handleAttributeTypeVisibilityChange - Type Visibility Map");
			// console.log(updatedBoxTypeVisibilityMap);

			// Get the illustration data
			const illustrationData = this.state.illustrationData;
			if (illustrationData) {
				// Get the updated state for the illustration data
				const updatedState = this.getUpdatedStateForIllustrationData(
					illustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					updatedCurrentVisibilityState
				);

				// Update the state
				this.setState({
					...updatedState,
				});
			}
		}
	};

	private handleBoxVisibilityOrLayoutChange = (
		boxKey: string,
		isVisible: boolean,
		isInLayout: boolean
	) => {
		// console.log(`handleBoxVisibilityOrLayoutChange(${boxKey}, ${isVisible}, ${isInLayout})`);

		// Get the current visibility state key
		const currentVisibilityStateKey = getCurrentVisibilityStateKey(
			this.state.illustrationData
		);

		// Get the visibility state
		const currentVisibilityState = getCurrentVisibilityState(
			currentVisibilityStateKey,
			this.state.illustrationData
		);

		// Get a copy of the current visibility state
		const updatedCurrentVisibilityState = JSON.parse(
			JSON.stringify(currentVisibilityState)
		);

		// Get the box type visibility map
		const updatedBoxVisibilityMap =
			updatedCurrentVisibilityState.boxVisibilityMap;

		// Get the box type visibility
		const updatedBoxVisibility = updatedBoxVisibilityMap[boxKey];

		// console.log('updatedBoxVisibility')
		// console.log(updatedBoxVisibility)

		if (updatedBoxVisibility) {
			// Toggle the box type visibility
			updatedBoxVisibility.isVisible = isVisible;
			updatedBoxVisibility.isInLayout = isInLayout;

			// console.log("handleBoxVisibilityChange - Type Visibility Map");
			// console.log(updatedBoxVisibilityMap);

			// Get the illustration data
			const illustrationData = this.state.illustrationData;
			if (illustrationData) {
				// Get the updated state for the illustration data
				const updatedState = this.getUpdatedStateForIllustrationData(
					illustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					updatedCurrentVisibilityState
				);

				// Update the state
				this.setState({
					...updatedState,
				});
			}
		}
	};

	private handleBoxVisibilityChange = (
		boxKey: string,
		isVisible: boolean
	) => {
		// console.log(`handleBoxVisibilityChange(${boxKey}, ${isVisible})`);

		// Set both the visibility and layout in one go
		this.handleBoxVisibilityOrLayoutChange(boxKey, isVisible, isVisible);
	};

	private handleLensVisibilityChange = (
		lensKey: string,
		isVisible: boolean
	) => {
		// console.log(`handleLensVisibilityChange(${lensKey}, ${isVisible})`);

		// Get the illustration data
		const illustrationData = this.state.illustrationData;
		if (illustrationData) {
			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				this.state.illustrationData
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				currentVisibilityStateKey,
				this.state.illustrationData
			);

			// Get a copy of the current visibility state
			const updatedCurrentVisibilityState = JSON.parse(
				JSON.stringify(currentVisibilityState)
			);

			// Get the lens visibility map
			const updatedLensVisibilityMap =
				updatedCurrentVisibilityState.lensVisibilityMap;

			// Update the lens visibility
			updatedLensVisibilityMap[lensKey] = isVisible;

			// Get the updated state for the illustration data
			const updatedState = this.getUpdatedStateForIllustrationData(
				illustrationData,
				this.state.illustrationData,
				this.state.flattenedBoxMap,
				updatedCurrentVisibilityState
			);

			// Update the state
			this.setState({
				...updatedState,
			});
		}
	};

	private handleLensGroupVisibilityChange = (
		lensGroupKey: string,
		isVisible: boolean
	) => {
		console.log(
			`handleLensGroupVisibilityChange(${lensGroupKey}, ${isVisible})`
		);

		// Get the illustration data
		const illustrationData = this.state.illustrationData;
		if (illustrationData) {
			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				this.state.illustrationData
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				currentVisibilityStateKey,
				this.state.illustrationData
			);

			// Get a copy of the current visibility state
			const updatedCurrentVisibilityState = JSON.parse(
				JSON.stringify(currentVisibilityState)
			);

			// Get the lens group visibility map
			const updatedLensGroupVisibilityMap =
				updatedCurrentVisibilityState.lensGroupVisibilityMap;

			// Update the lens group visibility
			updatedLensGroupVisibilityMap[lensGroupKey] = isVisible;

			// Get the lens visibility map
			const updatedLensVisibilityMap =
				updatedCurrentVisibilityState.lensVisibilityMap;

			// Do we have lens groups?
			if (illustrationData.lensGroups) {
				// Get the lens group
				const lensGroup = illustrationData.lensGroups[lensGroupKey];

				// Update the lens visibility map based on lens group visibilities
				lensesLib.setLensVisibilityMapForLensGroupVisibility(
					updatedLensVisibilityMap,
					lensGroup,
					isVisible
				);

				// Get the updated state for the illustration data
				const updatedState = this.getUpdatedStateForIllustrationData(
					illustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					updatedCurrentVisibilityState
				);

				// Update the state
				this.setState({
					...updatedState,
				});
			}
		}
	};
	private handleSimpleLensVisibilityChange = (
		lensKey: string,
		isVisible: boolean
	) => {
		console.log(`handleLensVisibilityChange(${lensKey}, ${isVisible})`);

		// Get the illustration data
		const illustrationData = this.state.illustrationData;
		if (illustrationData) {
			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				this.state.illustrationData
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				currentVisibilityStateKey,
				this.state.illustrationData
			);

			// Get a copy of the current visibility state
			const updatedCurrentVisibilityState = JSON.parse(
				JSON.stringify(currentVisibilityState)
			);

			// Get the lens visibility map
			const updatedLensVisibilityMap =
				updatedCurrentVisibilityState.lensVisibilityMap ? updatedCurrentVisibilityState.lensVisibilityMap : {};

			// Update the lens visibility
			updatedLensVisibilityMap[lensKey] = isVisible;

			// Get the updated state for the illustration data
			const updatedState = this.getUpdatedStateForIllustrationData(
				illustrationData,
				this.state.illustrationData,
				this.state.flattenedBoxMap,
				updatedCurrentVisibilityState
			);

			// Update the state
			this.setState({
				...updatedState,
			});
		}
	};

	private handleSimpleLensGroupVisibilityChange = (
		lensGroupKey: string,
		isVisible: boolean
	) => {
		console.log(
			`handleLensGroupVisibilityChange(${lensGroupKey}, ${isVisible})`
		);

		// Get the illustration data
		const illustrationData = this.state.illustrationData;
		if (illustrationData) {
			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				this.state.illustrationData
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				currentVisibilityStateKey,
				this.state.illustrationData
			);

			// Get a copy of the current visibility state
			const updatedCurrentVisibilityState = JSON.parse(
				JSON.stringify(currentVisibilityState)
			);

			// Get the lens group visibility map
			const updatedLensGroupVisibilityMap =
				updatedCurrentVisibilityState.lensGroupVisibilityMap ? updatedCurrentVisibilityState.lensGroupVisibilityMap : {};

			// Update the lens group visibility
			updatedLensGroupVisibilityMap[lensGroupKey] = isVisible;

			// TODO: Is something needed here? Do we need to update the lens visibility map at all?

			// Get the lens visibility map
			const updatedLensVisibilityMap =
				updatedCurrentVisibilityState.lensVisibilityMap ? updatedCurrentVisibilityState.lensVisibilityMap : {};

			// Do we have lens groups?
			if (illustrationData.simpleLensGroups) {
				// Get the lens group
				const simpleLensGroup = illustrationData.simpleLensGroups[lensGroupKey];

				// Update the lens visibility map based on lens group visibilities
				lensesLib.setLensVisibilityMapForLensGroupVisibility(
					updatedLensVisibilityMap,
					simpleLensGroup,
					isVisible
				);

				// Get the updated state for the illustration data
				const updatedState = this.getUpdatedStateForIllustrationData(
					illustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					updatedCurrentVisibilityState
				);

				// Update the state
				this.setState({
					...updatedState,
				});
			}
		}
	};

	private handleEditBoxModalOK = (
		editedBox: boxLib.Box | undefined,
		editedBoxTypes: boxTypeLib.BoxTypeMap | undefined,
		associationsToEnable: AssociationToEnable[],
		lensGroupsToAdd: lensesLib.LensGroupMap,
		lensesToAdd: lensesLib.LensMap
	) => {
		// console.log(editedBox);

		// Do we have an edited box?
		if (editedBox) {
			// The updated state
			let updatedState: any = {};

			// Get the box type counters
			let boxTypeCounters = JSON.parse(
				JSON.stringify(this.state.boxTypeCounters)
			);

			// Do we have illustration data?
			if (this.state.illustrationData) {

				// Update the illustration data in a way that can be undone
				const updatedIllustrationData = produce(
					this.state.illustrationData,
					(draft) => {
						if (draft && draft.boxes && draft.boxTypes) {
							if (editedBoxTypes) {
								draft.boxTypes = JSON.parse(JSON.stringify(editedBoxTypes));
							}

							// Find the box who we'll be updating
							const updatedBox = boxLib.findBoxInBoxMapForKey(
								draft.boxes,
								this.state.editBoxModalBoxKey
							);
							if (updatedBox) {
								// Whether the box type has changed
								const hasBoxTypeChanged =
									updatedBox.boxType !== editedBox.boxType;

								// Update the box
								// TODO: make this cleaner
								updatedBox.name = editedBox.name;
								updatedBox.url = editedBox.url;
								updatedBox.boxType = editedBox.boxType;
								updatedBox.defaultChildBoxType = editedBox.defaultChildBoxType;
								updatedBox.inheritParentProperties = editedBox.inheritParentProperties;
								updatedBox.defaultProperties = editedBox.defaultProperties
									? JSON.parse(
										JSON.stringify(
											editedBox.defaultProperties
										)
									)
									: {};
								updatedBox.inheritParentAttributes =
									editedBox.inheritParentAttributes;
								updatedBox.attributes = editedBox.attributes
									? JSON.parse(
										JSON.stringify(editedBox.attributes)
									)
									: '';
								updatedBox.smartPages = editedBox.smartPages
									? JSON.parse(
										JSON.stringify(editedBox.smartPages)
									)
									: undefined;

								updatedBox.children = editedBox.children
									? JSON.parse(
										JSON.stringify(editedBox.children)
									)
									: undefined;

								// If the box type changes, switch to the counter for the new
								// type
								if (hasBoxTypeChanged) {
									let boxTypeCounter = boxTypeCounterLib.getCounter(
										boxTypeCounters,
										updatedBox.boxType
									);

									// Add an ID to the child box if it isn't a container type
									boxTypeCounter = boxLib.setDefaultBoxAttributes(
										draft.boxTypes,
										updatedBox,
										boxTypeCounter,
										true
									);

									boxTypeCounters[
										updatedBox.boxType
									] = boxTypeCounter;
								}

								// Update whether the box has a Lens Page
								if (draft.boxTypes) {
									boxLib.setBoxHasSmartPagesAttribute(
										updatedBox,
										draft.boxTypes[updatedBox.boxType]
									);
								}


								// Set the default attributes of any newly created box children
								if (this.state.flattenedBoxMap) {
									// Get the original box children
									const originalBox = this.state.flattenedBoxMap[this.state.editBoxModalBoxKey];
									const originalBoxChildren = originalBox.children;
									if (originalBoxChildren) {
										const originalBoxChildKeys = Object.keys(originalBoxChildren);

										// Get the updated box children
										const defaultChildBoxType = updatedBox.defaultChildBoxType;

										const boxChildren = updatedBox.children;
										if (defaultChildBoxType && boxChildren) {
											const boxChildKeys = Object.keys(boxChildren);

											// If the number of children has changed and there originally were children
											// we need to increase the highest ID for the child box type
											if (boxChildKeys.length !== originalBoxChildKeys.length && originalBoxChildKeys.length > 0) {
												boxTypeCounters[defaultChildBoxType] = boxTypeCounters[defaultChildBoxType] + 1;
											}

											boxChildKeys.forEach((boxChildKey: string) => {
												// Is this a new child box?
												if (originalBoxChildKeys.indexOf(boxChildKey) < 0) {
													const childBox = boxChildren[boxChildKey];
													if (childBox) {
														let boxTypeCounter = boxTypeCounterLib.getCounter(
															boxTypeCounters,
															childBox.boxType
														);

														boxTypeCounter = boxLib.setDefaultBoxAttributes(draft.boxTypes,
															childBox,
															boxTypeCounter,
															true);

														boxTypeCounters[
															childBox.boxType
														] = boxTypeCounter;
													}
												}
											})
										}
									}
								}
							}

							// Create the new lens groups if we have any
							if (draft.lensGroups) {
								draft.lensGroups = { ...draft.lensGroups, ...lensGroupsToAdd };
							}

							// Create the new lenses if we have any
							if (draft.lenses) {
								draft.lenses = { ...draft.lenses, ...lensesToAdd };
							}
						}
					},
					this.handleAddUndo
				);

				// Update the box type cache 
				boxTypeLib.initializeBoxTypeAttributeTypeCache(updatedIllustrationData.boxTypes);

				// Get the current visibility state key
				const currentVisibilityStateKey = getCurrentVisibilityStateKey(
					updatedIllustrationData
				);

				// Get the visibility state
				const currentVisibilityState = getCurrentVisibilityState(
					currentVisibilityStateKey,
					updatedIllustrationData
				);

				// Get the updated state for the illustration data
				// This will set up all the visibilities for anything new that's
				// been added
				updatedState = this.getUpdatedStateForIllustrationData(
					updatedIllustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					currentVisibilityState
				);

				const newIllustrationData = updatedState.illustrationData as illustrationLib.Illustration

				// Get the current visibility state key
				const newCurrentVisibilityStateKey = getCurrentVisibilityStateKey(
					newIllustrationData
				);

				// Get the visibility state
				const newCurrentVisibilityState = getCurrentVisibilityState(
					newCurrentVisibilityStateKey,
					newIllustrationData
				);

				// Get a copy of the current visibility state
				const updatedCurrentVisibilityState = JSON.parse(
					JSON.stringify(newCurrentVisibilityState)
				);

				// Get the box type visibility map
				const updatedBoxTypeVisibilityMap =
					updatedCurrentVisibilityState.boxTypeVisibilityMap;

				// Make the associations visibile
				associationsToEnable.forEach((associationToEnable) => {
					const { boxTypeKey, attributeTypeKey } = associationToEnable;

					// If the box type doesn't exist in the visibility map, then create it
					const boxTypeVisibility = updatedBoxTypeVisibilityMap[boxTypeKey];
					if (boxTypeVisibility) {
						if (boxTypeVisibility.attributeTypeVisibilityMap === undefined) {
							boxTypeVisibility.attributeTypeVisibilityMap = {}
						}
						boxTypeVisibility.attributeTypeVisibilityMap[attributeTypeKey] = true;
					}
				});

				// Get the updated state for the illustration data
				updatedState = this.getUpdatedStateForIllustrationData(
					newIllustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					updatedCurrentVisibilityState
				);
			}

			// Update our state and close the box modal dialog
			this.setState({
				...updatedState,
				boxTypeCounters,
				isEditBoxModalVisible: false,
				editBoxModalBoxKey: '',
				editBoxModalBox: undefined,
			});
		}
	};

	private handleEditBoxModalCancel = () => {
		// Close the box modal dialog
		this.setState({
			isEditBoxModalVisible: false,
			editBoxModalBoxKey: '',
			editBoxModalBox: undefined,
		});
	};

	private handleAddEditBoxTypeModalOK = (
		editedBoxType: boxTypeLib.BoxType | undefined,
		boxTypeKey: string
	) => {
		// The updated state
		let updatedState: any = {};

		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get a copy of the illustration data
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					if (draft && draft.boxTypes) {
						// Make sure the box type has the default types
						const updatedBoxType = JSON.parse(
							JSON.stringify(editedBoxType)
						);
						boxTypeLib.setDefaultAttributeTypesForBoxType(
							updatedBoxType
						);

						// Update the box type
						draft.boxTypes[boxTypeKey] = updatedBoxType;
					}
				},
				this.handleAddUndo
			);

			const attributeTypes: attributeTypeLib.AttributeTypeMap = {};
			boxTypeLib.setAttributeTypesForBoxTypeRecursive(attributeTypes,
				boxTypeKey,
				updatedIllustrationData.boxTypes);

			// Update the box type cache 
			boxTypeLib.initializeBoxTypeAttributeTypeCache(updatedIllustrationData.boxTypes);

			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				updatedIllustrationData
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				currentVisibilityStateKey,
				updatedIllustrationData
			);

			// Get the updated state for the illustration data
			updatedState = this.getUpdatedStateForIllustrationData(
				updatedIllustrationData,
				this.state.illustrationData,
				this.state.flattenedBoxMap,
				currentVisibilityState
			);
		}

		// Update the box types and close the box type modal dialog
		this.setState({
			...updatedState,
			isAddEditBoxTypeModalAdding: false,
			isAddEditBoxTypeModalVisible: false,
			addEditBoxTypeModalBoxTypeKey: '',
		});
	};

	private handleAddEditBoxTypeModalCancel = () => {
		// Close the box type modal dialog
		this.setState({
			isAddEditBoxTypeModalAdding: false,
			isAddEditBoxTypeModalVisible: false,
			addEditBoxTypeModalBoxTypeKey: '',
		});
	};

	private handleAddEditVisibilityStateModalOK = (
		editedVisibilityState: visibilityStateLib.VisibilityState,
		visibilityStateKey: string
	) => {
		// The updated state
		let updatedState: any = {};

		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get a copy of the illustration data
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					if (draft && draft.visibilityStateMap) {
						// Update the visibility state
						draft.visibilityStateMap[
							visibilityStateKey
						] = JSON.parse(JSON.stringify(editedVisibilityState));

						// Set the visibility state as the current
						draft.currentVisibilityStateKey = visibilityStateKey;
					}
				},
				this.handleAddUndo
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				visibilityStateKey,
				updatedIllustrationData
			);

			// Get the updated state for the illustration data
			updatedState = this.getUpdatedStateForIllustrationData(
				updatedIllustrationData,
				this.state.illustrationData,
				this.state.flattenedBoxMap,
				currentVisibilityState
			);
		}

		// Update the box types and close the box type modal dialog
		this.setState({
			...updatedState,
			isAddEditVisibilityStateModalAdding: false,
			isAddEditVisibilityStateModalVisible: false,
			addEditVisibilityStateModalVisibilityStateKey: '',
		});
	};

	private handleAddEditVisibilityStateModalCancel = () => {
		// Close the box type modal dialog
		this.setState({
			isAddEditVisibilityStateModalAdding: false,
			isAddEditVisibilityStateModalVisible: false,
			addEditVisibilityStateModalVisibilityStateKey: '',
		});
	};


	private handleViewHistoryModalOK = () => {
		// Close the View History modal dialog
		this.setState({
			isViewHistoryModalVisible: false,
		});
	};

	private handleViewHistoryModalCancel = () => {
		// Close the View History dialog
		this.setState({
			isViewHistoryModalVisible: false,
		});
	};

	private handleIllustrationSaveButtonClick = () => {
		console.log("handleIllustrationSaveButtonClick");

		// Do we have an illustration and illustration data?
		if (this.state.illustration && this.state.illustrationData) {
			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				this.state.illustrationData
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				currentVisibilityStateKey,
				this.state.illustrationData
			);

			// Build the updated illustration
			const updatedIllustration = JSON.parse(
				JSON.stringify(this.state.illustration)
			);
			updatedIllustration.data = this.cloneIllustrationData(
				this.state.illustrationData
			);

			// Update the current visibility state in the illustration data
			updatedIllustration.data.visibilityStateMap[
				currentVisibilityStateKey
			] = JSON.parse(JSON.stringify(currentVisibilityState));

			console.log(updatedIllustration);

			// Get the illustration ID
			const illustrationId = this.props.match.params.illustrationId;

			const self = this;

			this.setState(
				{
					isBusy: true,
				},
				() => {
					// Save the illustration
					illustrationService.put(
						illustrationId,
						updatedIllustration,
						() => {
							self.setState(
								{
									isBusy: false,
								},
								() => {
									Modal.success({
										zIndex: 9999,
										content: "Saved current illustration!",
										onOk() {
											console.log("OK");
										},
									});
								}
							);
						}, (message) => {
							self.setState({
								isBusy: false
							});
							console.log(`Error occurred: ${message}.`);
							notification.open({
								message: 'Error: Could not save Illustration',
								description:
									`Failed to save Illustration Id ${illustrationId}`,
								icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
							});
						});
				}
			);
		}
	};


	private handleIllustrationImportButtonClick = () => {
		// Open the Illustration import/export modal dialog
		this.setState({
			isImportExportIllustrationModalVisible: true,
			isImportExportIllustrationModalImporting: true,
		});
	};

	private handleIllustrationExportButtonClick = () => {
		// Open the Illustration import/export modal dialog
		this.setState(
			{
				isBusy: true,
			},
			() => {
				// Let the UI indicate that it's busy first, then start opening the Export
				// dialog
				setTimeout(() => {
					this.setState({
						isImportExportIllustrationModalVisible: true,
						isImportExportIllustrationModalImporting: false,
					});
				}, 10);
			}
		);
	};

	private handleImportExportIllustrationModalImport = (
		illustrationData: any
	) => {
		this.setState(
			{
				isBusy: true,
			},
			() => {
				// The updated state
				let updatedState: any = {};

				// Do we have illustration data?
				if (illustrationData) {
					// Get the current visibility state key
					const currentVisibilityStateKey = getCurrentVisibilityStateKey(
						illustrationData
					);

					// Get the visibility state
					const currentVisibilityState = getCurrentVisibilityState(
						currentVisibilityStateKey,
						illustrationData
					);

					// If we don't have lenses in the illustration, add them
					if (!illustrationData.lenses) {
						illustrationData.lenses = {};
					}

					// If we don't have lens groups in the illustration, add them
					if (!illustrationData.lensGroups) {
						illustrationData.lensGroups = {};
					}

					// If we don't have a visibility state map in the illustration date, add
					// one
					if (!illustrationData.visibilityStateMap) {
						illustrationData.visibilityStateMap = {};
					}

					if (
						!Object.prototype.hasOwnProperty.call(
							illustrationData.visibilityStateMap,
							currentVisibilityStateKey
						)
					) {
						illustrationData.visibilityStateMap[
							currentVisibilityStateKey
						] = currentVisibilityState;
					}

					boxTypeLib.initializeBoxTypeAttributeTypeCache(illustrationData.boxTypes);

					// Get a copy of the current visibility state
					const updatedCurrentVisibilityState = JSON.parse(
						JSON.stringify(currentVisibilityState)
					);

					// Get the updated state for the illustration data
					updatedState = this.getUpdatedStateForIllustrationData(
						illustrationData,
						illustrationData,
						undefined,
						updatedCurrentVisibilityState
					);
				}

				// Close the Illustration import/export modal dialog
				this.setState({
					...updatedState,
					isBusy: false,
					isImportExportIllustrationModalVisible: false,
					isImportExportIllustrationModalImporting: false,
				});
			}
		);
	};

	private handleImportExportIllustrationModalCancelOrClose = () => {
		// Close the Illustration import/export Excel modal dialog
		this.setState({
			isBusy: false,
			isImportExportIllustrationModalVisible: false,
			isImportExportIllustrationModalImporting: false,
		});
	};

	private handleIllustrationImportExcelButtonClick = () => {
		// Open the Illustration import/export Excel modal dialog
		this.setState({
			isImportExportIllustrationExcelModalVisible: true,
			isImportExportIllustrationExcelModalImporting: true,
		});
	};

	private handleIllustrationExportExcelButtonClick = () => {
		// Open the Illustration import/export Excel modal dialog
		this.setState(
			{
				isBusy: true,
			},
			() => {
				// Let the UI indicate that it's busy first, then start opening the Export
				// dialog
				setTimeout(() => {
					this.setState({
						isBusy: true,
						isImportExportIllustrationExcelModalVisible: true,
						isImportExportIllustrationExcelModalImporting: false,
					});
				});
			}
		);
	};

	private handleImportExportIllustrationExcelModalImport = (
		illustrationData: illustrationLib.Illustration
	) => {
		this.setState(
			{
				isBusy: true,
			},
			() => {
				// The updated state
				let updatedState: any = {};

				// Do we have illustration data?
				if (illustrationData) {
					// Get the current visibility state key
					const currentVisibilityStateKey = getCurrentVisibilityStateKey(
						illustrationData
					);

					// Get the visibility state
					const currentVisibilityState = getCurrentVisibilityState(
						currentVisibilityStateKey,
						illustrationData
					);

					// If we don't have lenses in the illustration, add them
					if (!illustrationData.lenses) {
						illustrationData.lenses = {};
					}
					// If we don't have simple lenses in the illustration, add them
					if (!illustrationData.unprocessedSimpleLensGroups) {
						illustrationData.unprocessedSimpleLensGroups = {};
					}

					if (!illustrationData.simpleLensGroups) {
						illustrationData.simpleLensGroups = {};
					}

					if (!illustrationData.simpleLenses) {
						illustrationData.simpleLenses = {};
					}

					// If we don't have lens groups in the illustration, add them
					if (!illustrationData.lensGroups) {
						illustrationData.lensGroups = {};
					}

					// If we don't have a visibility state map in the illustration date, add
					// one
					if (!illustrationData.visibilityStateMap) {
						illustrationData.visibilityStateMap = {};
					}

					if (
						!Object.prototype.hasOwnProperty.call(
							illustrationData.visibilityStateMap,
							currentVisibilityStateKey
						)
					) {
						illustrationData.visibilityStateMap[
							currentVisibilityStateKey
						] = currentVisibilityState;
					}

					// Get a copy of the current visibility state
					const updatedCurrentVisibilityState = JSON.parse(
						JSON.stringify(currentVisibilityState)
					);

					// Get the updated state for the illustration data
					updatedState = this.getUpdatedStateForIllustrationData(
						illustrationData,
						illustrationData,
						undefined,
						updatedCurrentVisibilityState
					);
				}

				// Close the Illustration import/export Excel modal dialog
				this.setState({
					...updatedState,
					isBusy: false,
					isImportExportIllustrationExcelModalVisible: false,
					isImportExportIllustrationExcelModalImporting: false,
				});
			}
		);
	};

	private handleImportExportIllustrationExcelModalCancelOrClose = () => {
		// Close the Illustration import/export Excel modal dialog
		this.setState({
			isBusy: false,
			isImportExportIllustrationExcelModalVisible: false,
			isImportExportIllustrationExcelModalImporting: false,
		});
	};

	private handleBoxTypeClearAllAssociations = (boxTypeKey: string) => {
		const self = this;

		Modal.confirm({
			zIndex: 9999,
			content:
				"Are you sure you want to delete all associations for this type?",
			onOk() {
				// Do we have illustration data?
				if (self.state.illustrationData) {
					// Clone the illustration data
					const updatedIllustrationData = produce(
						self.state.illustrationData,
						(draft) => {
							if (draft && draft.boxes && draft.boxTypes) {
								// Set up the box type we want to delete.
								const boxTypes: boxTypeLib.BoxTypeMap = {
									[boxTypeKey]: draft.boxTypes[boxTypeKey],
								};

								console.log(
									"Deleting all assocations for the following box types:"
								);
								console.log(boxTypes);

								// Delete the associations for the box type
								boxLib.deleteAllBoxAssociations(
									draft.boxes,
									boxTypes
								);
							}
						},
						self.handleAddUndo
					);

					// Get the current visibility state key
					const currentVisibilityStateKey = getCurrentVisibilityStateKey(
						updatedIllustrationData
					);

					// Get the visibility state
					const currentVisibilityState = getCurrentVisibilityState(
						currentVisibilityStateKey,
						updatedIllustrationData
					);

					// console.log('OK');
					// Get the updated state for the illustration data
					const updatedState = self.getUpdatedStateForIllustrationData(
						updatedIllustrationData,
						self.state.illustrationData,
						self.state.flattenedBoxMap,
						currentVisibilityState
					);

					self.setState({
						...updatedState,
					});
				}
			},
			onCancel() {
				// console.log('Cancel');
			},
		});
	};

	private handleIllustrationClearAllAssociationsButtonClick = () => {
		const self = this;

		Modal.confirm({
			zIndex: 9999,
			content: "Are you sure you want to delete all associations?",
			onOk() {
				// Do we have illustration data?
				if (self.state.illustrationData) {
					const updatedIllustrationData = produce(
						self.state.illustrationData,
						(draft) => {
							if (draft && draft.boxes && draft.boxTypes) {
								// Delete all box associations
								boxLib.deleteAllBoxAssociations(
									draft.boxes,
									draft.boxTypes
								);
							}
						},
						self.handleAddUndo
					);

					// Get the current visibility state key
					const currentVisibilityStateKey = getCurrentVisibilityStateKey(
						updatedIllustrationData
					);

					// Get the visibility state
					const currentVisibilityState = getCurrentVisibilityState(
						currentVisibilityStateKey,
						updatedIllustrationData
					);

					// console.log('OK');
					// Get the updated state for the illustration data
					const updatedState = self.getUpdatedStateForIllustrationData(
						updatedIllustrationData,
						self.state.illustrationData,
						self.state.flattenedBoxMap,
						currentVisibilityState
					);

					self.setState({
						...updatedState,
					});
				}
			},
			onCancel() {
				// console.log('Cancel');
			},
		});
	};

	private handleBoxEditSelect = (boxKey: string) => {
		// console.log(`Box ${boxKey} selected for editing`);

		// Do we have any illustration data?
		if (this.state.illustrationData) {
			// Find the box
			const foundBox = boxLib.findBoxInBoxMapForKey(
				this.state.illustrationData.boxes,
				boxKey
			);
			if (foundBox) {
				// Get a copy of the box
				const boxToEdit = JSON.parse(JSON.stringify(foundBox));

				// Open the box modal dialog
				this.setState({
					isEditBoxModalVisible: true,
					editBoxModalBoxKey: boxKey,
					editBoxModalBox: boxToEdit,
				});
			}
		}
	};

	private handleBoxAcceptEditInPlace = (boxKey: string, boxText: string) => {
		// console.log(`accept edit inplace box ${boxKey}`)

		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get a copy of the illustration data
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					if (draft && draft.boxes) {
						// Find the box we'll be changing the name of
						const box = boxLib.findBoxInBoxMapForKey(
							draft.boxes,
							boxKey
						);
						if (box) {
							// Change the name
							box.name = boxText;
						}
					}
				},
				this.handleAddUndo
			);


			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				updatedIllustrationData
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				currentVisibilityStateKey,
				updatedIllustrationData
			);

			// Get the updated state for the illustration data
			const updatedState = this.getUpdatedStateForIllustrationData(
				updatedIllustrationData,
				this.state.illustrationData,
				this.state.flattenedBoxMap,
				currentVisibilityState
			);

			// Update the edited box
			this.setState({
				...updatedState,
			});
		}
	};

	// Click - Select all childless children recursively.
	// SHIFT+Click - Select only direct children.
	// CTRL+Click - Select just the box.
	private handleBoxMultiEditSelect = (boxKey: string, ctrlKey: boolean, shiftKey: boolean) => {
		// console.log(`Box ${boxKey} selected for multi-editing`);

		// Get the updated box selection state
		const updatedBoxSelectionInfoMap = JSON.parse(
			JSON.stringify(this.state.boxSelectionInfoMap)
		);

		const box = (this.state.flattenedBoxMap)
			? this.state.flattenedBoxMap[boxKey]
			: undefined;

		// Does the box have children and CTRL isn't pressed?
		if (box && box.children && (Object.keys(box.children).length > 0) && !ctrlKey) {
			// If SHIFT is held down, only work with the direct children of the box.
			const allChildBoxKeys = (shiftKey)
				? Object.keys(box.children)
				: boxLib.getBoxChildren(this.state.flattenedBoxMap, boxKey);
			const selectableChildBoxKeys = (shiftKey)
				? Object.keys(box.children)
				: boxLib.getBoxChildlessChildren(this.state.flattenedBoxMap, boxKey);

			const isChildSelected = allChildBoxKeys.some((childBoxKey: string) => {
				return Object.prototype.hasOwnProperty.call(
					updatedBoxSelectionInfoMap,
					childBoxKey
				)
			});

			if (isChildSelected) {
				// Unselect all the children
				allChildBoxKeys.forEach((childBoxKey: string) => {
					delete updatedBoxSelectionInfoMap[childBoxKey];
				});
			} else {
				// Build the box selection info
				const boxSelectionInfo: boxLib.BoxSelectionInfo = {
					color: SELECTED_BOX_COLOR,
				};

				// Select all the selectable children
				selectableChildBoxKeys.forEach((childBoxKey: string) => {
					// Mark the box as selected
					updatedBoxSelectionInfoMap[childBoxKey] = boxSelectionInfo;
				});
			}
		} else {
			// Is the box already selected?
			if (
				Object.prototype.hasOwnProperty.call(
					updatedBoxSelectionInfoMap,
					boxKey
				)
			) {
				// Unselect it
				delete updatedBoxSelectionInfoMap[boxKey];
			} else {
				// Build the box selection info
				const boxSelectionInfo: boxLib.BoxSelectionInfo = {
					color: SELECTED_BOX_COLOR,
				};

				// Mark the box as selected
				updatedBoxSelectionInfoMap[boxKey] = boxSelectionInfo;
			}
		}

		// Update our state
		this.setState({
			boxSelectionInfoMap: updatedBoxSelectionInfoMap,
		});
	};

	private handleBoxAssociationsEditSelect = (boxKey: string) => {
		// console.log(`Box ${boxKey} selected for associations editing`);

		// Get the updated box selection state
		const updatedBoxSelectionInfoMap = JSON.parse(
			JSON.stringify(this.state.boxSelectionInfoMap)
		);
		let updatedFirstSelectedBoxKey = this.state.firstSelectedBoxKey;

		// Is the box already selected?
		if (
			Object.prototype.hasOwnProperty.call(
				updatedBoxSelectionInfoMap,
				boxKey
			)
		) {
			// Unselect it
			delete updatedBoxSelectionInfoMap[boxKey];

			// If it's the first selected box key, clear it and use the
			if (updatedFirstSelectedBoxKey === boxKey) {
				updatedFirstSelectedBoxKey = undefined;
			}
		} else {
			// The box selection color, use the first selected box color if it's the
			// first selected box
			const boxSelectionColor =
				updatedFirstSelectedBoxKey === undefined
					? FIRST_SELECTED_BOX_COLOR
					: SELECTED_BOX_COLOR;

			// Build the box selection info
			const boxSelectionInfo: boxLib.BoxSelectionInfo = {
				color: boxSelectionColor,
			};

			// Mark the box as selected
			updatedBoxSelectionInfoMap[boxKey] = boxSelectionInfo;

			// Is it the first selected box key?
			if (updatedFirstSelectedBoxKey === undefined) {
				updatedFirstSelectedBoxKey = boxKey;
			}
		}

		// Update our state
		this.setState({
			boxSelectionInfoMap: updatedBoxSelectionInfoMap,
			firstSelectedBoxKey: updatedFirstSelectedBoxKey,
		});
	};

	private handleBookmarkSelect = (boxKey: string, smartPageKey: string) => {
		this.setState({
			isViewBoxModalVisible: true,
			viewBoxModalSmartPageKey: smartPageKey,
			viewBoxModalBoxKey: boxKey,
			viewBoxModalBox: this.state.flattenedBoxMap![boxKey],
		});
	};

	private handleBoxViewSelect = (boxKey: string) => {
		console.log(`Box ${boxKey} selected for viewing`);

		var box = this.state.flattenedBoxMap![boxKey];

		// If we have a URL then we want to try and open it
		if (box && box.url && box.url !== "") {
			var url = getUrlWithPrefix(box.url);

			openInNewTab(url);
		}
		// If not then open the normal view window
		else {
			this.setState({
				isViewBoxModalVisible: true,
				viewBoxModalSmartPageKey: '',
				viewBoxModalBoxKey: boxKey,
				viewBoxModalBox: this.state.flattenedBoxMap![boxKey],
			});

		}

	};

	private handleBoxAddChild = (boxKey: string): void => {
		// console.log(`handleBoxAddChild(boxKey=${boxKey})`);

		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get the box type counters
			const boxTypeCounters = JSON.parse(
				JSON.stringify(this.state.boxTypeCounters)
			);

			// Get a copy of the illustration data
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					if (draft && draft.boxes && draft.boxTypes) {
						// Find the box we'll be adding a child to
						const parentBox = boxLib.findBoxInBoxMapForKey(
							draft.boxes,
							boxKey
						);

						// Did we find the box?
						if (parentBox) {
							// console.log(parentBox);

							// Generate a new key for the child
							const boxChildKey: string = uuid();

							// If the box does't have a children prop, add one
							if (
								!Object.prototype.hasOwnProperty.call(
									parentBox,
									"children"
								)
							) {
								parentBox.children = {};
							}

							// Get the number of children already in the parent
							const childCount = parentBox.children
								? Object.keys(parentBox.children).length
								: 0;

							// Get the order of the new child (we'll be adding it at the end)
							const boxChildOrder = childCount > 0 ? childCount - 1 : 0;

							// If the parent doesn't have a place to store children, make one
							if (!parentBox.children) {
								parentBox.children = {};
							}

							// Figure out the box type
							const newBoxType = (parentBox.defaultChildBoxType)
								? parentBox.defaultChildBoxType
								: parentBox.boxType;

							// Create a child box, defaulting to the type of its parent
							const childBox = boxLib.createDefaultBox(newBoxType,
								draft.boxTypes,
								boxChildOrder);

							// Get the current box type counter
							let boxTypeCounter = boxTypeCounterLib.getCounter(
								boxTypeCounters,
								childBox.boxType
							);

							// Add an ID to the child box if it isn't a container type
							boxTypeCounter = boxLib.setDefaultBoxAttributes(
								draft.boxTypes,
								childBox,
								boxTypeCounter,
								true
							);

							boxTypeCounters[childBox.boxType] = boxTypeCounter;

							// Add the new child to its parent
							parentBox.children[boxChildKey] = childBox;
						}
					}
				},
				this.handleAddUndo
			);

			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				updatedIllustrationData
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				currentVisibilityStateKey,
				updatedIllustrationData
			);

			// Get the updated state for the illustration data
			const updatedState = this.getUpdatedStateForIllustrationData(
				updatedIllustrationData,
				this.state.illustrationData,
				this.state.flattenedBoxMap,
				currentVisibilityState
			);

			// Update our state
			this.setState({
				...updatedState,
				boxTypeCounters,
			});
		}
	};

	private handleBoxRemoveChild = (boxKey: string): void => {
		// console.log(`handleBoxRemoveChild(boxKey=${boxKey})`);

		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get a copy of the illustration data
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					if (draft && draft.boxes) {
						// Find the parent box of the box we'll be removing
						const parentBox = boxLib.findBoxParentInBoxMapForKey(
							undefined,
							draft.boxes,
							boxKey
						);

						// Did we find the box?
						if (parentBox) {
							// Remove the child from its parent
							if (parentBox.children) {
								delete parentBox.children[boxKey];
							}
						}
					}
				},
				this.handleAddUndo
			);

			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				updatedIllustrationData
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				currentVisibilityStateKey,
				updatedIllustrationData
			);

			// Get the updated state for the illustration data
			const updatedState = this.getUpdatedStateForIllustrationData(
				updatedIllustrationData,
				this.state.illustrationData,
				this.state.flattenedBoxMap,
				currentVisibilityState
			);

			// Update our state
			this.setState({
				...updatedState,
			});
		}
	};

	private handleBoxChildLayoutChange = (
		boxKey: string,
		childLayout: string
	) => {
		// console.log(`handleBoxChildLayoutChange(${boxKey} -> ${childLayout})`)

		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get a copy of the illustration data
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					if (draft && draft.boxes) {
						// Find the box we'll be updating the child layout of
						const foundBox = boxLib.findBoxInBoxMapForKey(
							draft.boxes,
							boxKey
						);

						// console.log(foundBox)

						// Did we find the box?
						if (foundBox) {
							// If the box doesn't have default properies, make them
							if (!foundBox.defaultProperties) {
								foundBox.defaultProperties = {};
							}

							// Update the child layout
							foundBox.defaultProperties.childLayout = childLayout;
						}
					}
				},
				this.handleAddUndo
			);

			// Update the layout of the child
			this.setState({
				illustrationData: updatedIllustrationData,
			});
		}
	};

	private handleBoxCanDrop = (
		dragSourceBoxKey: string,
		dragSourceBoxParentKey: string,
		dropTargetBoxKey: string): boolean => {
		//  console.log('handleBoxCanDrop')
		// console.log(`\tdragSourceBoxKey=${dragSourceBoxKey}, dragSourceBoxParentKey=${dragSourceBoxParentKey}, dropTargetBoxKey=${dropTargetBoxKey}, dropTargetBoxParentKey=${dropTargetBoxParentKey}`)

		// If the box is being dragged on to itself, don't allow a drop
		if (dropTargetBoxKey === dragSourceBoxKey) {
			// console.log('cant drop')
			return false;
		}

		// Otherwise allow the drop
		return true;
	};

	private handleBoxDragStart = (dragSourceBoxKey: string): void => {
		// console.log('handleBoxDragStart')
		// console.log(`\tdragSourceBoxKey=${dragSourceBoxKey}, dragSourceBoxParentKey=${dragSourceBoxParentKey}`)

		// Clear any existing drag-and-drop timeout.
		if (this.state.dragAndDropTimeout) {
			clearTimeout(this.state.dragAndDropTimeout);
		}

		if (!this.state.illustrationData) {
			return;
		}

		// Set up the illustration data that represents the state of the
		// illustration as the box is dragged around.
		const dragAndDropIllustrationData: illustrationLib.Illustration = JSON.parse(JSON.stringify(this.state.illustrationData)) as illustrationLib.Illustration;

		// Get the box being dragged
		const dragSourceBox = boxLib.findBoxInBoxMapForKey(
			dragAndDropIllustrationData.boxes,
			dragSourceBoxKey
		);

		const dragAndDropBox = JSON.parse(JSON.stringify(dragSourceBox))

		this.setState({
			isBoxDragging: true,
			currentDragSourceBoxKey: dragSourceBoxKey,
			currentlyHighlightedDropTargetBoxKey: '',
			initialDragAndDropIllustrationData: dragAndDropIllustrationData,
			dragAndDropIllustrationData,
			dragAndDropIllustrationBoxKey: dragSourceBoxKey,
			dragAndDropBox,
			dragAndDropTimeout: undefined,
		});

		this.lastDragX = -1;
		this.lastDragY = -1;
	}

	private handleBoxDragCancel = (): void => {
		// console.log('handleBoxDragCancel')

		// Clear any existing drag-and-drop timeout.
		if (this.state.dragAndDropTimeout) {
			clearTimeout(this.state.dragAndDropTimeout);
		}

		this.setState({
			isBoxDragging: false,
			currentDragSourceBoxKey: '',
			currentlyHighlightedDropTargetBoxKey: '',
			initialDragAndDropIllustrationData: undefined,
			dragAndDropIllustrationData: undefined,
			dragAndDropIllustrationBoxKey: '',
			dragAndDropBox: undefined,
			dragAndDropTimeout: undefined,
		});
	}

	private handleBoxDragHover = (
		dragSourceBoxKey: string,
		dragSourceBoxParentKey: string,
		dropTargetBoxKey: string,
		dropTargetBoxParentKey: string,
		sortedBoxKeys: string[],
		dragX: number,
		dragY: number
	) => {
		// console.log('handleBoxDragHover')
		// console.log(`\tdragSourceBoxKey=${dragSourceBoxKey}, dragSourceBoxParentKey=${dragSourceBoxParentKey}, dropTargetBoxKey=${dropTargetBoxKey}, dropTargetBoxParentKey=${dropTargetBoxParentKey}`)

		if (!this.state.isBoxDragging) {
			return;
		}

		// If the box is being dragged on to itself, don't allow a drop
		if (dropTargetBoxKey === dragSourceBoxKey) {
			return;
		}

		// If the box is being dragged on to the fake box, do nothing
		if (this.state.dragAndDropIllustrationBoxKey === dropTargetBoxKey) {
			return;
		}

		// If the box is being dragged on to a child of itself or the fake box then
		// do nothing
		if (this.state.dragAndDropBox) {
			const childBox = boxLib.findBoxInBoxMapForKey(this.state.dragAndDropBox.children,
				dropTargetBoxKey);
			if (childBox) {
				return;
			}
		}
		if (this.dragAndDropIllustrationBox) {
			const childBox = boxLib.findBoxInBoxMapForKey(this.dragAndDropIllustrationBox.children,
				dropTargetBoxKey);
			if (childBox) {
				return;
			}
		}

		// Set the currently highlighted drop target box key to the drop target box key
		const currentlyHighlightedDropTargetBoxKey = dropTargetBoxKey;

		// Has the currently highlighted box drop target changed?
		if ((this.state.currentlyHighlightedDropTargetBoxKey !== currentlyHighlightedDropTargetBoxKey) ||
			(dragX !== this.lastDragX) ||
			(dragY !== this.lastDragY)) {
			// console.log(`handleBoxDragHover(${currentlyHighlightedDropTargetBoxKey}, ${this.state.currentlyHighlightedDropTargetBoxKey})`);

			// Clear any existing drag-and-drop timeout.
			if (this.state.dragAndDropTimeout) {
				clearTimeout(this.state.dragAndDropTimeout);
			}

			// Do we have illustration data?
			const dragAndDropIllustrationData = this.state.initialDragAndDropIllustrationData;

			let updatedIllustrationData = dragAndDropIllustrationData;
			let newDragSourceBoxKey = dragSourceBoxKey;
			let newDragSourceBox = this.dragAndDropIllustrationBox;

			if (dragAndDropIllustrationData && this.state.dragAndDropBox) {
				// Get a copy of the illustration data
				updatedIllustrationData = JSON.parse(JSON.stringify(dragAndDropIllustrationData));
				if (updatedIllustrationData) {
					// Set up the drag source box
					newDragSourceBoxKey = uuid();
					newDragSourceBox = boxLib.cloneBox(this.state.dragAndDropBox);

					const dropTargetBox = boxLib.findBoxInBoxMapForKey(
						updatedIllustrationData.boxes,
						dropTargetBoxKey
					);

					if (dropTargetBox) {
						// If the drop target doesn't have a place to store children, make one.
						if (!dropTargetBox.children) {
							dropTargetBox.children = {};
						}

						const dropTargetBoxChildren = dropTargetBox.children;

						// Add it to the drop target box
						dropTargetBoxChildren[newDragSourceBoxKey] = newDragSourceBox;

						// Set the order of the boxes
						sortedBoxKeys.forEach((boxKey: string, boxKeyIndex: number) => {
							const actualBoxKey = (boxKey === dragSourceBoxKey)
								? newDragSourceBoxKey
								: boxKey;

							const boxChild = dropTargetBoxChildren[actualBoxKey];
							if (boxChild) {
								boxChild.order = boxKeyIndex;
							}
						});
					}
				}
			}

			// Make the changes instantly accessible if the user drops before the drag
			// preview updates
			this.dragAndDropIllustrationData = updatedIllustrationData;
			this.dragAndDropIllustrationBoxKey = newDragSourceBoxKey;
			this.dragAndDropIllustrationBox = newDragSourceBox;

			// Update our state, with a delay to make the drag preview jump around
			// less.
			const dragAndDropTimeout = setTimeout(() => {
				this.setState({
					dragAndDropIllustrationBoxKey: newDragSourceBoxKey,
					dragAndDropIllustrationData: updatedIllustrationData,
				});
			}, DRAG_AND_DROP_SHOW_DELAY_IN_MILLISECONDS);

			this.setState({
				currentlyHighlightedDropTargetBoxKey,
				dragAndDropTimeout
			});
		}

		this.lastDragX = dragX;
		this.lastDragY = dragY;
	};

	private handleBoxDragDrop = (
		dragSourceBoxKey: string,
		dragSourceBoxParentKey: string) => {
		// console.log('handleBoxDragDrop')
		// console.log(`\tdragSourceBoxKey=${dragSourceBoxKey}, dragSourceBoxParentKey=${dragSourceBoxParentKey}, dropTargetBoxKey=${dropTargetBoxKey}, dropTargetBoxParentKey=${dropTargetBoxParentKey}`)

		if (!this.state.isBoxDragging) {
			return;
		}

		// Clear any existing drag-and-drop timeout.
		if (this.state.dragAndDropTimeout) {
			clearTimeout(this.state.dragAndDropTimeout);
		}

		let updatedState: any = {};

		const currentlyHighlightedDropTargetBoxKey = this.state.currentlyHighlightedDropTargetBoxKey;

		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get a copy of the illustration data
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					if (!draft || !draft.boxes) {
						return;
					}
					const newIllustrationBoxes = draft.boxes;

					const dragAndDropIllustrationBoxKey = this.dragAndDropIllustrationBoxKey;
					const dragAndDropIllustrationData = this.dragAndDropIllustrationData;

					if (!dragAndDropIllustrationData) {
						return;
					}

					// Get the parent box of the drag source
					const dragSourceBoxParent = boxLib.findBoxInBoxMapForKey(
						newIllustrationBoxes,
						dragSourceBoxParentKey
					);
					if (!dragSourceBoxParent ||
						!dragSourceBoxParent.children ||
						!Object.prototype.hasOwnProperty.call(dragSourceBoxParent.children, dragSourceBoxKey)) {
						return;
					}

					// Get the drop target box
					const dropTargetBox = boxLib.findBoxInBoxMapForKey(
						newIllustrationBoxes,
						currentlyHighlightedDropTargetBoxKey
					);
					if (!dropTargetBox) {
						return;
					}

					// Get the box that has been used to preview the drag-and-drop
					const previewDropTargetBox = boxLib.findBoxInBoxMapForKey(
						dragAndDropIllustrationData.boxes,
						currentlyHighlightedDropTargetBoxKey
					);
					if (!previewDropTargetBox) {
						return;
					}

					// If the drop target doesn't have a place to store children, make
					// one
					if (!dropTargetBox.children) {
						dropTargetBox.children = {};
					}

					// Are we just moving the box?
					if ((dragSourceBoxParentKey !== currentlyHighlightedDropTargetBoxKey) ||
						!Object.prototype.hasOwnProperty.call(dropTargetBox.children, dragSourceBoxKey)) {

						// Move the original box
						dropTargetBox.children[dragSourceBoxKey] = dragSourceBoxParent.children[dragSourceBoxKey];

						// Delete the original drag source box.
						delete dragSourceBoxParent.children[dragSourceBoxKey];
					}

					// Update the order of the child boxes
					Object
						.keys(dropTargetBox.children)
						.forEach((childBoxKey: string) => {
							const previewBoxKey = (childBoxKey === dragSourceBoxKey)
								? dragAndDropIllustrationBoxKey
								: childBoxKey

							// console.log(`${childBoxKey} / ${previewBoxKey}`);

							// Update the order from the preview box
							if (!dropTargetBox.children || !previewDropTargetBox.children) {
								return;
							}

							// console.log(`\t${dropTargetBox.children[childBoxKey].name} = ${previewDropTargetBox.children[previewBoxKey].order}`)
							const dropTargetBoxChild = dropTargetBox.children[childBoxKey];
							const previewDropTargetBoxChild = previewDropTargetBox.children[previewBoxKey];

							if (!dropTargetBoxChild || !previewDropTargetBoxChild) {
								return;
							}

							dropTargetBoxChild.order = previewDropTargetBoxChild.order;
						});
				},
				this.handleAddUndo
			);

			// Get the current visibility state key
			const currentVisibilityStateKey = getCurrentVisibilityStateKey(
				updatedIllustrationData
			);

			// Get the visibility state
			const currentVisibilityState = getCurrentVisibilityState(
				currentVisibilityStateKey,
				updatedIllustrationData
			);

			// Get the updated state for the illustration data
			updatedState = this.getUpdatedStateForIllustrationData(
				updatedIllustrationData,
				this.state.illustrationData,
				this.state.flattenedBoxMap,
				currentVisibilityState
			);
		}

		// Clear the instant access drag-and-drop state
		this.dragAndDropIllustrationBoxKey = '';
		this.dragAndDropIllustrationData = undefined;
		this.dragAndDropIllustrationBox = undefined;

		// Update our state, clearing the currently highlighted drop target box
		// key
		this.setState({
			...updatedState,
			currentDragSourceBoxKey: '',
			currentlyHighlightedDropTargetBoxKey: '',
			initialDragAndDropIllustrationData: undefined,
			dragAndDropIllustrationData: undefined,
			dragAndDropBox: undefined,
			dragAndDropTimeout: undefined,
			isBoxDragging: false
		});
	};

	private handleValueTypesChange = (valueTypes: any) => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			try {
				// Get a copy of the illustration data
				const updatedIllustrationData = produce(
					this.state.illustrationData,
					(draft) => {
						if (draft) {
							// Update the value types
							draft.valueTypes = valueTypes;
						}
					},
					this.handleAddUndo
				);

				// Get the current visibility state key
				const currentVisibilityStateKey = getCurrentVisibilityStateKey(
					updatedIllustrationData
				);

				// Get the visibility state
				const currentVisibilityState = getCurrentVisibilityState(
					currentVisibilityStateKey,
					updatedIllustrationData
				);

				// Get the updated state for the illustration data
				const updatedState = this.getUpdatedStateForIllustrationData(
					updatedIllustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					currentVisibilityState
				);

				this.setState({
					...updatedState,
				});
			} catch (e) {
			}
		}
	};

	private handleViewSidebarMenuVisibilityChange = (
		event: CheckboxChangeEvent
	) => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Does the event target have a name?
			if (event.target.name) {
				// Get the name of the tool visibility
				const toolVisibilityName: string = event.target.name;

				// Clone the illustration data
				const updatedIllustrationData = produce(
					this.state.illustrationData,
					(draft) => {
						// Toggle the visibility
						if (
							draft &&
							draft.configuration &&
							draft.configuration.ui &&
							draft.configuration.ui.viewSidebar
						) {
							draft.configuration.ui.viewSidebar.toolVisibility[
								toolVisibilityName
							] = event.target.checked;
						}
					},
					this.handleAddUndo
				);

				// Update the configuration
				this.setState({
					illustrationData: updatedIllustrationData,
				});
			}
		}
	};

	private handleViewSidebarMenuStartInViewStateChange = (
		event: CheckboxChangeEvent
	) => {
		// Get the client ID
		const clientId = this.getClientId();

		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get the new persistent state
			let persistentState =
				illustrationLib.IllustrationPersistentState.VIEW;
			if (
				authorizationService.isAbilityPermitted(
					this.props.abilities,
					authorizationService.AbilityType.IllustrationBoxesEdit,
					clientId
				)
			) {
				if (!event.target.checked) {
					persistentState =
						illustrationLib.IllustrationPersistentState.EDIT_BOX;
				}
			}

			// Clone the illustration data
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					if (draft) {
						// Set the persistent state
						draft.persistentState = persistentState;
					}
				},
				this.handleAddUndo
			);

			// Update the configuration
			this.setState({
				illustrationData: updatedIllustrationData,
			});
		}
	};

	private handleIllustrationHeightInPercentChange = (
		value: number | undefined
	) => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get the value as a number
			let valueAsNumber: number =
				value !== undefined
					? value
					: configurationLib.DEFAULT_UI_ILLUSTRATION_HEIGHT_IN_PERCENT;

			// Clamp it to the minimum and maximum
			if (
				valueAsNumber <
				configurationLib.MINIMUM_UI_ILLUSTRATION_HEIGHT_IN_PERCENT
			) {
				valueAsNumber =
					configurationLib.MINIMUM_UI_ILLUSTRATION_HEIGHT_IN_PERCENT;
			}


			// Clone the illustration data
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					// Set the view sidebar width
					if (
						draft &&
						draft.configuration &&
						draft.configuration.ui
					) {
						if (!draft.configuration.ui.illustration) {
							draft.configuration.ui.illustration = {
								heightInPercent: valueAsNumber,
								widthInPercent: configurationLib.DEFAULT_UI_ILLUSTRATION_WIDTH_IN_PERCENT,
								viewModeHorizontalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS,
								viewModeVerticalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_VERTICAL_BOX_GAP_IN_PIXELS,
								editModeHorizontalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS,
								editModeVerticalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_VERTICAL_BOX_GAP_IN_PIXELS
							};
						} else {
							draft.configuration.ui.illustration.heightInPercent = valueAsNumber;
						}

						console.log(`Setting the height to ${valueAsNumber}`);
					}
				},
				this.handleAddUndo
			);

			// Update the configuration
			this.setState({
				illustrationData: updatedIllustrationData,
			});
		}
	};

	private handleIllustrationWidthInPercentChange = (
		value: number | undefined
	) => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get the value as a number
			let valueAsNumber: number =
				value !== undefined
					? value
					: configurationLib.DEFAULT_UI_ILLUSTRATION_WIDTH_IN_PERCENT;

			// Clamp it to the minimum and maximum
			if (
				valueAsNumber <
				configurationLib.MINIMUM_UI_ILLUSTRATION_WIDTH_IN_PERCENT
			) {
				valueAsNumber =
					configurationLib.MINIMUM_UI_ILLUSTRATION_WIDTH_IN_PERCENT;
			}


			// Clone the illustration data
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					// Set the view sidebar width
					if (
						draft &&
						draft.configuration &&
						draft.configuration.ui
					) {
						if (!draft.configuration.ui.illustration) {
							draft.configuration.ui.illustration = {
								widthInPercent: valueAsNumber,
								heightInPercent: configurationLib.DEFAULT_UI_ILLUSTRATION_HEIGHT_IN_PERCENT,
								viewModeHorizontalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS,
								viewModeVerticalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_VERTICAL_BOX_GAP_IN_PIXELS,
								editModeHorizontalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS,
								editModeVerticalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_VERTICAL_BOX_GAP_IN_PIXELS
							};
						} else {
							draft.configuration.ui.illustration.widthInPercent = valueAsNumber;
						}
					}
				},
				this.handleAddUndo
			);

			// Update the configuration
			this.setState({
				illustrationData: updatedIllustrationData,
			});
		}
	};

	private handleViewSidebarWidthInPercentChange = (
		value: number | undefined
	) => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get the value as a number
			let valueAsNumber: number =
				value !== undefined
					? value
					: configurationLib.DEFAULT_UI_VIEW_SIDEBAR_WIDTH_IN_PERCENT;

			// Clamp it to the minimum and maximum
			if (
				valueAsNumber <
				configurationLib.MINIMUM_UI_VIEW_SIDEBAR_WIDTH_IN_PERCENT
			) {
				valueAsNumber =
					configurationLib.MINIMUM_UI_VIEW_SIDEBAR_WIDTH_IN_PERCENT;
			}
			if (
				valueAsNumber >
				configurationLib.MAXIMUM_UI_VIEW_SIDEBAR_WIDTH_IN_PERCENT
			) {
				valueAsNumber =
					configurationLib.MAXIMUM_UI_VIEW_SIDEBAR_WIDTH_IN_PERCENT;
			}

			// Clone the illustration data
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					// Set the view sidebar width
					if (
						draft &&
						draft.configuration &&
						draft.configuration.ui &&
						draft.configuration.ui.viewSidebar
					) {
						draft.configuration.ui.viewSidebar.widthInPercent = valueAsNumber;
					}
				},
				this.handleAddUndo
			);

			// Update the configuration
			this.setState({
				illustrationData: updatedIllustrationData,
			});
		}
	};

	private handleEditSidebarWidthInPercentChange = (
		value: number | undefined
	) => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get the value as a number
			let valueAsNumber: number =
				value !== undefined
					? value
					: configurationLib.DEFAULT_UI_EDIT_SIDEBAR_WIDTH_IN_PERCENT;

			// Clamp it to the minimum and maximum
			if (
				valueAsNumber <
				configurationLib.MINIMUM_UI_EDIT_SIDEBAR_WIDTH_IN_PERCENT
			) {
				valueAsNumber =
					configurationLib.MINIMUM_UI_EDIT_SIDEBAR_WIDTH_IN_PERCENT;
			}
			if (
				valueAsNumber >
				configurationLib.MAXIMUM_UI_EDIT_SIDEBAR_WIDTH_IN_PERCENT
			) {
				valueAsNumber =
					configurationLib.MAXIMUM_UI_EDIT_SIDEBAR_WIDTH_IN_PERCENT;
			}

			// Clone the illustration data
			const updatedIllustrationData = produce(
				this.state.illustrationData,
				(draft) => {
					// Set the view sidebar wiidth
					if (
						draft &&
						draft.configuration &&
						draft.configuration.ui &&
						draft.configuration.ui.editSidebar
					) {
						draft.configuration.ui.editSidebar.widthInPercent = valueAsNumber;
					}
				},
				this.handleAddUndo
			);

			// Update the configuration
			this.setState({
				illustrationData: updatedIllustrationData,
			});
		}
	};

	private handleViewModeHorizontalBoxGapInPixelsChange = (
		value: number | null
	) => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get the value as a number
			let valueAsNumber: number = value !== null
				? value
				: configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS;

			// Clamp it to the minimum and maximum
			if (valueAsNumber < configurationLib.MINIMUM_UI_ILLUSTRATION_VIEW_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS) {
				valueAsNumber = configurationLib.MAXIMUM_UI_ILLUSTRATION_VIEW_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS;
			}

			// Clone the illustration data
			const updatedIllustrationData = produce(this.state.illustrationData,
				(draft) => {
					// Set the view sidebar width
					if (
						draft &&
						draft.configuration &&
						draft.configuration.ui
					) {
						if (!draft.configuration.ui.illustration) {
							draft.configuration.ui.illustration = {
								heightInPercent: configurationLib.DEFAULT_UI_ILLUSTRATION_HEIGHT_IN_PERCENT,
								widthInPercent: configurationLib.DEFAULT_UI_ILLUSTRATION_WIDTH_IN_PERCENT,
								viewModeHorizontalBoxGapInPixels: valueAsNumber,
								viewModeVerticalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_VERTICAL_BOX_GAP_IN_PIXELS,
								editModeHorizontalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS,
								editModeVerticalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_VERTICAL_BOX_GAP_IN_PIXELS
							};
						} else {
							draft.configuration.ui.illustration.viewModeHorizontalBoxGapInPixels = valueAsNumber;
						}

						console.log(`Setting the view mode horizontal padding to ${valueAsNumber}`);
					}
				},
				this.handleAddUndo
			);

			// Update the configuration
			this.setState({
				illustrationData: updatedIllustrationData,
			});
		}
	};

	private handleViewModeVerticalBoxGapInPixelsChange = (
		value: number | null
	) => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get the value as a number
			let valueAsNumber: number = value !== null
				? value
				: configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_VERTICAL_BOX_GAP_IN_PIXELS;

			// Clamp it to the minimum and maximum
			if (valueAsNumber < configurationLib.MINIMUM_UI_ILLUSTRATION_VIEW_MODE_VERTICAL_BOX_GAP_IN_PIXELS) {
				valueAsNumber = configurationLib.MAXIMUM_UI_ILLUSTRATION_VIEW_MODE_VERTICAL_BOX_GAP_IN_PIXELS;
			}

			// Clone the illustration data
			const updatedIllustrationData = produce(this.state.illustrationData,
				(draft) => {
					// Set the view sidebar width
					if (
						draft &&
						draft.configuration &&
						draft.configuration.ui
					) {
						if (!draft.configuration.ui.illustration) {
							draft.configuration.ui.illustration = {
								heightInPercent: configurationLib.DEFAULT_UI_ILLUSTRATION_HEIGHT_IN_PERCENT,
								widthInPercent: configurationLib.DEFAULT_UI_ILLUSTRATION_WIDTH_IN_PERCENT,
								viewModeHorizontalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS,
								viewModeVerticalBoxGapInPixels: valueAsNumber,
								editModeHorizontalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS,
								editModeVerticalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_VERTICAL_BOX_GAP_IN_PIXELS
							};
						} else {
							draft.configuration.ui.illustration.viewModeVerticalBoxGapInPixels = valueAsNumber;
						}

						console.log(`Setting the view mode vertical padding to ${valueAsNumber}`);
					}
				},
				this.handleAddUndo
			);

			// Update the configuration
			this.setState({
				illustrationData: updatedIllustrationData,
			});
		}
	};

	private handleEditModeHorizontalBoxGapInPixelsChange = (
		value: number | null
	) => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get the value as a number
			let valueAsNumber: number = value !== null
				? value
				: configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS;

			// Clamp it to the minimum and maximum
			if (valueAsNumber < configurationLib.MINIMUM_UI_ILLUSTRATION_EDIT_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS) {
				valueAsNumber = configurationLib.MAXIMUM_UI_ILLUSTRATION_EDIT_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS;
			}

			// Clone the illustration data
			const updatedIllustrationData = produce(this.state.illustrationData,
				(draft) => {
					// Set the view sidebar width
					if (
						draft &&
						draft.configuration &&
						draft.configuration.ui
					) {
						if (!draft.configuration.ui.illustration) {
							draft.configuration.ui.illustration = {
								heightInPercent: configurationLib.DEFAULT_UI_ILLUSTRATION_HEIGHT_IN_PERCENT,
								widthInPercent: configurationLib.DEFAULT_UI_ILLUSTRATION_WIDTH_IN_PERCENT,
								viewModeHorizontalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS,
								viewModeVerticalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_VERTICAL_BOX_GAP_IN_PIXELS,
								editModeHorizontalBoxGapInPixels: valueAsNumber,
								editModeVerticalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_VERTICAL_BOX_GAP_IN_PIXELS
							};
						} else {
							draft.configuration.ui.illustration.editModeHorizontalBoxGapInPixels = valueAsNumber;
						}

						console.log(`Setting the view mode horizontal padding to ${valueAsNumber}`);
					}
				},
				this.handleAddUndo
			);

			// Update the configuration
			this.setState({
				illustrationData: updatedIllustrationData,
			});
		}
	};

	private handleEditModeVerticalBoxGapInPixelsChange = (
		value: number | null
	) => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			// Get the value as a number
			let valueAsNumber: number = value !== null
				? value
				: configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_VERTICAL_BOX_GAP_IN_PIXELS;

			// Clamp it to the minimum and maximum
			if (valueAsNumber < configurationLib.MINIMUM_UI_ILLUSTRATION_EDIT_MODE_VERTICAL_BOX_GAP_IN_PIXELS) {
				valueAsNumber = configurationLib.MAXIMUM_UI_ILLUSTRATION_EDIT_MODE_VERTICAL_BOX_GAP_IN_PIXELS;
			}

			// Clone the illustration data
			const updatedIllustrationData = produce(this.state.illustrationData,
				(draft) => {
					// Set the view sidebar width
					if (
						draft &&
						draft.configuration &&
						draft.configuration.ui
					) {
						if (!draft.configuration.ui.illustration) {
							draft.configuration.ui.illustration = {
								heightInPercent: configurationLib.DEFAULT_UI_ILLUSTRATION_HEIGHT_IN_PERCENT,
								widthInPercent: configurationLib.DEFAULT_UI_ILLUSTRATION_WIDTH_IN_PERCENT,
								viewModeHorizontalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS,
								viewModeVerticalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_VERTICAL_BOX_GAP_IN_PIXELS,
								editModeHorizontalBoxGapInPixels: configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS,
								editModeVerticalBoxGapInPixels: valueAsNumber
							};
						} else {
							draft.configuration.ui.illustration.editModeVerticalBoxGapInPixels = valueAsNumber;
						}

						console.log(`Setting the view mode vertical padding to ${valueAsNumber}`);
					}
				},
				this.handleAddUndo
			);

			// Update the configuration
			this.setState({
				illustrationData: updatedIllustrationData,
			});
		}
	};


	private cloneIllustrationData = (
		illustration: illustrationLib.Illustration
	): illustrationLib.Illustration => {
		return JSON.parse(JSON.stringify(illustration));
	};

	private handleImportBoxTypes = (importedBoxTypes: boxTypeLib.BoxTypeMap, mergeOntoTypes: Array<string> | null) => {
		// Do we have illustration data?
		if (this.state.illustrationData) {

			try {
				// Get a copy of the illustration data
				const updatedIllustrationData = produce(
					this.state.illustrationData,
					(draft) => {
						if (draft) {
							// Make a copy of the box types to work with
							const boxTypesCopy: boxTypeLib.BoxTypeMap = JSON.parse(JSON.stringify(draft.boxTypes));

							Object.keys(importedBoxTypes).forEach((importedBoxTypeKey) => {
								const importedBoxType = importedBoxTypes[importedBoxTypeKey];

								// If we're doing a traditional merge
								if (mergeOntoTypes === null) {
									// If the imported box type already exists then we want to process each 
									// attribute on it individually so that we don't overwrite any existing attributes
									if (boxTypesCopy.hasOwnProperty(importedBoxTypeKey)) {
										Object.keys(importedBoxType.attributeTypes).forEach((importedAttributeTypeKey) => {

											// Assumption here is that we want to override the attribute if we're importing it
											boxTypesCopy[importedBoxTypeKey].attributeTypes[importedAttributeTypeKey] = importedBoxType.attributeTypes[importedAttributeTypeKey];
										});
									}
									// Otherwise we just want to add the box type to the list
									else {
										// We can't import a whole type if the mixin doesn't exist, so we need to filter them out
										if (importedBoxType.hasOwnProperty("mixinBoxTypes") && importedBoxType.mixinBoxTypes) {
											const mixinBoxTypes: Array<string> = importedBoxType.mixinBoxTypes.split(',');

											// Filter out any mixin box types that don't exist
											const approvedBoxTypes = mixinBoxTypes.filter((value) => {
												const mixinTypeIsBeingImported = Object.keys(importedBoxTypes).indexOf(value) >= 0;
												const mixinTypeAlreadyExistsInIllustration = Object.keys(boxTypesCopy).indexOf(value) >= 0;
												return mixinTypeIsBeingImported || mixinTypeAlreadyExistsInIllustration;
											}).map((value) => {
												return value;
											})

											importedBoxType.mixinBoxTypes = approvedBoxTypes.join(',');

										}

										// importedBoxType.mixinBoxTypes = '';
										if (!importedBoxType.hasOwnProperty("disableBoxCreation")) {
											importedBoxType.disableBoxCreation = false;
										}
										boxTypesCopy[importedBoxTypeKey] = importedBoxType;
									}
								}
								// Otherwise what we want to do is take the attribute and copy it onto every type in the mergeOntoTypes list
								else {
									mergeOntoTypes.forEach((mergeTypeKey) => {
										if (boxTypesCopy.hasOwnProperty(mergeTypeKey)) {
											Object.keys(importedBoxType.attributeTypes).forEach((importedAttributeTypeKey) => {
												// Assumption here is that we want to override the attribute if we're importing it
												boxTypesCopy[mergeTypeKey].attributeTypes[importedAttributeTypeKey] = importedBoxType.attributeTypes[importedAttributeTypeKey];
											});
										}
									});
								}
							});
							draft.boxTypes = boxTypesCopy;
						}
					},
					this.handleAddUndo
				);

				// Update the box type cache 
				boxTypeLib.initializeBoxTypeAttributeTypeCache(updatedIllustrationData.boxTypes);

				// Get the current visibility state key
				const currentVisibilityStateKey = getCurrentVisibilityStateKey(
					updatedIllustrationData
				);

				// Get the visibility state
				const currentVisibilityState = getCurrentVisibilityState(
					currentVisibilityStateKey,
					updatedIllustrationData
				);

				// Get the updated state for the illustration data
				const updatedState = this.getUpdatedStateForIllustrationData(
					updatedIllustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					currentVisibilityState
				);

				this.setState({
					...updatedState,
				});
			} catch (e) {
			}
		}
	};

	private handleBoxTypesChange = (boxTypes: any) => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			try {
				// Get a copy of the illustration data
				const updatedIllustrationData = produce(
					this.state.illustrationData,
					(draft) => {
						if (draft) {
							// Update the box types
							draft.boxTypes = boxTypes;
						}
					},
					this.handleAddUndo
				);

				// Get the current visibility state key
				const currentVisibilityStateKey = getCurrentVisibilityStateKey(
					updatedIllustrationData
				);

				// Get the visibility state
				const currentVisibilityState = getCurrentVisibilityState(
					currentVisibilityStateKey,
					updatedIllustrationData
				);

				// Get the updated state for the illustration data
				const updatedState = this.getUpdatedStateForIllustrationData(
					updatedIllustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					currentVisibilityState
				);

				this.setState({
					...updatedState,
				});
			} catch (e) {
			}
		}
	};

	private handleBoxesChange = (boxes: any) => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			try {
				// Get a copy of the illustration data
				const updatedIllustrationData = produce(
					this.state.illustrationData,
					(draft) => {
						if (draft) {
							// Update the boxes
							draft.boxes = boxes;
						}
					},
					this.handleAddUndo
				);

				// Get the current visibility state key
				const currentVisibilityStateKey = getCurrentVisibilityStateKey(
					updatedIllustrationData
				);

				// Get the visibility state
				const currentVisibilityState = getCurrentVisibilityState(
					currentVisibilityStateKey,
					updatedIllustrationData
				);

				// Get the updated state for the illustration data
				const updatedState = this.getUpdatedStateForIllustrationData(
					updatedIllustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					currentVisibilityState
				);

				this.setState({
					...updatedState,
				});
			} catch (e) {
			}
		}
	};

	private handleBoxVisualisationsChange = (boxVisualisations: any) => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			try {
				// Get a copy of the illustration data
				const updatedIllustrationData = produce(
					this.state.illustrationData,
					(draft) => {
						if (draft) {
							// Update the box visualisations
							draft.boxVisualisations = boxVisualisations;
						}
					},
					this.handleAddUndo
				);

				// Get the current visibility state key
				const currentVisibilityStateKey = getCurrentVisibilityStateKey(
					updatedIllustrationData
				);

				// Get the visibility state
				const currentVisibilityState = getCurrentVisibilityState(
					currentVisibilityStateKey,
					updatedIllustrationData
				);

				// Get the updated state for the illustration data
				const updatedState = this.getUpdatedStateForIllustrationData(
					updatedIllustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					currentVisibilityState
				);

				this.setState({
					...updatedState,
				});
			} catch (e) {
			}
		}
	};

	private handleBoxStylesChange = (boxStyles: any) => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			try {
				// Get a copy of the illustration data
				const updatedIllustrationData = produce(
					this.state.illustrationData,
					(draft) => {
						if (draft) {
							// Update the box styles
							draft.boxStyles = boxStyles;
						}
					},
					this.handleAddUndo
				);

				// Get the current visibility state key
				const currentVisibilityStateKey = getCurrentVisibilityStateKey(
					updatedIllustrationData
				);

				// Get the visibility state
				const currentVisibilityState = getCurrentVisibilityState(
					currentVisibilityStateKey,
					updatedIllustrationData
				);

				// Get the updated state for the illustration data
				const updatedState = this.getUpdatedStateForIllustrationData(
					updatedIllustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					currentVisibilityState
				);

				this.setState({
					...updatedState,
				});
			} catch (e) {
			}
		}
	};

	private handleLensesChange = (lenses: any) => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			try {
				// Get a copy of the illustration data
				const updatedIllustrationData = produce(
					this.state.illustrationData,
					(draft) => {
						if (draft) {
							// Update the lenses
							draft.lenses = lenses;
						}
					},
					this.handleAddUndo
				);

				// Get the current visibility state key
				const currentVisibilityStateKey = getCurrentVisibilityStateKey(
					updatedIllustrationData
				);

				// Get the visibility state
				const currentVisibilityState = getCurrentVisibilityState(
					currentVisibilityStateKey,
					updatedIllustrationData
				);

				// Get the updated state for the illustration data
				const updatedState = this.getUpdatedStateForIllustrationData(
					updatedIllustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					currentVisibilityState
				);

				this.setState({
					...updatedState,
				});
			} catch (e) {
			}
		}
	};

	private handleLensGroupsChange = (lensGroups: any) => {
		// Do we have illustration data?
		if (this.state.illustrationData) {
			try {
				// Get a copy of the illustration data
				const updatedIllustrationData = produce(
					this.state.illustrationData,
					(draft) => {
						if (draft) {
							// Update the lensGroups
							draft.lensGroups = lensGroups;
						}
					},
					this.handleAddUndo
				);

				// Get the current visibility state key
				const currentVisibilityStateKey = getCurrentVisibilityStateKey(
					updatedIllustrationData
				);

				// Get the visibility state
				const currentVisibilityState = getCurrentVisibilityState(
					currentVisibilityStateKey,
					updatedIllustrationData
				);

				// Get the updated state for the illustration data
				const updatedState = this.getUpdatedStateForIllustrationData(
					updatedIllustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					currentVisibilityState
				);

				this.setState({
					...updatedState,
				});
			} catch (e) {
			}
		}
	};

	private handleEditIllustrationModalOK = (illustrationId: string, name: string, description: string) => {
		const copy = JSON.parse(JSON.stringify(this.state.illustration));
		copy.name = name;
		copy.description = description;


		this.setState({
			illustration: copy,
			editIllustrationModalIllustration: null,
			isEditIllustrationModalVisible: false,
		});
	};
	private handleAddIllustrationModalOK = (projectId: string, name: string, description: string, templateId: string | undefined) => {
		// We don't use this, so just ignore it
	}

	private handleRemoveIllustrationClick = () => {
		confirm({
			title:
				"Are you sure delete this illustration? THIS CANNOT BE UNDONE",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				illustrationService.remove(
					this.state.illustration._id,
					() => {
						this.props.history.push(
							"/clients/" +
							this.getClientId() +
							"/projects/" +
							this.getProjectId()
						);
					}, (message) => {
						this.setState({
							isBusy: false
						});

						console.log(`Error occurred: ${message}.`);
						notification.open({
							message: 'Error: Could not delete Illustration',
							description:
								`Failed to delete Illustration Id ${this.state.illustration._id}`,
							icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
						});
					});
			},
			onCancel: () => { },
		});
	};

	private handleEditIllustrationCancel = () => {
		// Close the box modal dialog
		this.setState({
			editIllustrationModalIllustration: null,
			isEditIllustrationModalVisible: false,
		});
	};

	private handleEditIllustrationClick = () => {
		// Open the box modal dialog
		this.setState({
			editIllustrationModalIllustration: this.state.illustration,
			isEditIllustrationModalVisible: true,
		});
	};

	private handleSimpleCreateAssociationsOk = (
		boxes: boxLib.BoxMap | undefined
	) => {
		// console.log('handleCreateAssociationsOk');
		// console.log(boxes)

		// The updated state
		let updatedState: any = {};

		// Do we have updated boxes?
		if (boxes) {
			// Do we have illustration data?
			if (
				this.state.illustrationData &&
				this.state.illustrationData.boxTypes
			) {
				// Get a copy of the illustration data
				const updatedIllustrationData = produce(
					this.state.illustrationData,
					(draft) => {
						if (draft && draft.boxes && draft.boxTypes) {
							// Update each box
							Object.keys(boxes).forEach((boxKey: string) => {
								// Find the box who we'll be updating
								const updatedBox = boxLib.findBoxInBoxMapForKey(
									draft.boxes,
									boxKey
								);
								if (updatedBox) {
									// Add attributes to the updated box if it doesn't have any
									if (!updatedBox.attributes) {
										updatedBox.attributes = {};
									}

									// Get the edited box
									const editedBox = boxes[boxKey];
									if (editedBox.attributes) {
										// Get the box type key
										const editedBoxTypeKey = editedBox.boxType === undefined ? 'base' : editedBox.boxType;

										// Get the box type
										const editedBoxType = draft.boxTypes![editedBoxTypeKey];

										if (editedBoxType) {
											// Get the attributes types of the edited box
											const editedBoxAttributeTypes = editedBoxType.attributeTypes;
											if (editedBoxAttributeTypes) {
												// Update the box
												Object
													.keys(editedBoxAttributeTypes)
													.forEach((attributeTypeKey: string) => {
														// Get the attribute type
														const editedAttributeType = editedBoxAttributeTypes[attributeTypeKey];

														const { valueType, associationsType } = editedAttributeType;

														const isUuidAssociation = !associationsType || associationsType === attributeTypeLib.AssociationType.Uuids;

														// Is the attribute a UUIDs association?
														if (valueType === valueTypeLib.ValueTypeKey.Associations && isUuidAssociation) {
															// Update the attribute in the box
															updatedBox.attributes![attributeTypeKey] = editedBox.attributes![attributeTypeKey];
														}
													});
											}
										}
									}
								}
							});
						}
					},
					this.handleAddUndo
				);

				// console.log(updatedIllustrationData.boxes);

				boxTypeLib.initializeBoxTypeAttributeTypeCache(updatedIllustrationData.boxTypes);

				// Get the current visibility state key
				const currentVisibilityStateKey = getCurrentVisibilityStateKey(
					updatedIllustrationData
				);

				// Get the visibility state
				const currentVisibilityState = getCurrentVisibilityState(
					currentVisibilityStateKey,
					updatedIllustrationData
				);

				// Get the updated state for the illustration data
				updatedState = this.getUpdatedStateForIllustrationData(
					updatedIllustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					currentVisibilityState
				);
			}
		}

		// Close the Association Dialog
		this.setState({
			isSimpleCreateAssociationsModalVisible: false,
			boxSelectionInfoMap: {},
			firstSelectedBoxKey: undefined,
			...updatedState,
		});
	};

	private handleSimpleCreateAssociationsCancel = () => {
		// Close the Association Dialog
		this.setState({
			isSimpleCreateAssociationsModalVisible: false,
			boxSelectionInfoMap: {},
			firstSelectedBoxKey: undefined,
		});
	};

	private handleCreateAssociationsOk = (boxes: boxLib.BoxMap | undefined) => {
		// console.log('handleCreateAssociationsOk');
		// console.log(boxes)

		// The updated state
		let updatedState: any = {};

		// Do we have updated boxes?
		if (boxes) {
			// Do we have illustration data?
			if (
				this.state.illustrationData &&
				this.state.illustrationData.boxTypes
			) {
				// Get a copy of the illustration data
				const updatedIllustrationData = produce(
					this.state.illustrationData,
					(draft) => {
						if (draft && draft.boxes && draft.boxTypes) {
							// Update each box
							Object.keys(boxes).forEach((boxKey: string) => {
								// Find the box who we'll be updating
								const updatedBox = boxLib.findBoxInBoxMapForKey(
									draft.boxes,
									boxKey
								);
								if (updatedBox) {
									// Add attributes to the updated box if it doesn't have any
									if (!updatedBox.attributes) {
										updatedBox.attributes = {};
									}

									// Get the edited box
									const editedBox = boxes[boxKey];
									if (editedBox.attributes) {
										// Get the box type key
										const editedBoxTypeKey = editedBox.boxType;

										// Get the box type
										const editedBoxType = draft.boxTypes![editedBoxTypeKey];

										// Get the attributes types of the edited box
										const editedBoxAttributeTypes = editedBoxType.attributeTypes;
										if (editedBoxAttributeTypes) {
											// Update the box
											Object
												.keys(editedBoxAttributeTypes)
												.forEach((attributeTypeKey: string) => {
													// Get the attribute type
													const editedAttributeType = editedBoxAttributeTypes[attributeTypeKey];

													const { valueType, associationsType } = editedAttributeType;

													const isUuidAssociation = !associationsType || associationsType === attributeTypeLib.AssociationType.Uuids;

													// Is the attribute a UUIDs association?
													if (valueType === valueTypeLib.ValueTypeKey.Associations && isUuidAssociation) {
														// Update the attribute in the box
														updatedBox.attributes![attributeTypeKey] = editedBox.attributes![attributeTypeKey];
													}
												});
										}
									}
								}
							});
						}
					},
					this.handleAddUndo
				);

				// console.log(updatedIllustrationData.boxes);

				boxTypeLib.initializeBoxTypeAttributeTypeCache(updatedIllustrationData.boxTypes);

				// Get the current visibility state key
				const currentVisibilityStateKey = getCurrentVisibilityStateKey(
					updatedIllustrationData
				);

				// Get the visibility state
				const currentVisibilityState = getCurrentVisibilityState(
					currentVisibilityStateKey,
					updatedIllustrationData
				);

				// Get the updated state for the illustration data
				updatedState = this.getUpdatedStateForIllustrationData(
					updatedIllustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					currentVisibilityState
				);
			}
		}

		// Close the Association Dialog
		this.setState({
			isCreateAssociationsModalVisible: false,
			boxSelectionInfoMap: {},
			firstSelectedBoxKey: undefined,
			...updatedState,
		});
	};

	private handleCreateAssociationsCancel = () => {
		// Close the Association Dialog
		this.setState({
			isCreateAssociationsModalVisible: false,
			boxSelectionInfoMap: {},
			firstSelectedBoxKey: undefined,
		});
	};

	private handleMultiEditBoxesOK = (boxes: boxLib.BoxMap | undefined) => {
		// console.log('handleMultiEditBoxesOK');
		// console.log(boxes)

		// The updated state
		let updatedState: any = {};

		// Get the box type counters
		const boxTypeCounters = JSON.parse(
			JSON.stringify(this.state.boxTypeCounters)
		);

		// Do we have updated boxes?
		if (boxes) {
			// Do we have illustration data?
			if (this.state.illustrationData) {
				// Get a copy of the illustration data
				const updatedIllustrationData = produce(
					this.state.illustrationData,
					(draft) => {
						if (draft && draft.boxes) {
							// Update each box
							Object.keys(boxes).forEach((boxKey: string) => {
								// Find the box who we'll be updating
								const updatedBox = boxLib.findBoxInBoxMapForKey(
									draft.boxes,
									boxKey
								);
								if (updatedBox) {
									// Get the edited box
									const editedBox = boxes[boxKey];

									// Whether the box type has changed
									const hasBoxTypeChanged =
										updatedBox.boxType !==
										editedBox.boxType;

									// Update the box
									// TODO: make this cleaner
									updatedBox.name = editedBox.name;
									updatedBox.boxType = editedBox.boxType;
									updatedBox.defaultChildBoxType = editedBox.defaultChildBoxType;
									updatedBox.inheritParentProperties = editedBox.inheritParentProperties;
									updatedBox.defaultProperties = JSON.parse(
										JSON.stringify(
											editedBox.defaultProperties
										)
									);
									updatedBox.inheritParentAttributes =
										editedBox.inheritParentAttributes;
									updatedBox.attributes = JSON.parse(
										JSON.stringify(editedBox.attributes)
									);

									// If the box type changes, switch to the counter for the new
									// type
									if (hasBoxTypeChanged) {
										let boxTypeCounter = boxTypeCounterLib.getCounter(
											boxTypeCounters,
											updatedBox.boxType
										);

										// Add an ID to the child box if it isn't a container type
										boxTypeCounter = boxLib.setDefaultBoxAttributes(
											draft.boxTypes,
											updatedBox,
											boxTypeCounter,
											true
										);

										boxTypeCounters[
											updatedBox.boxType
										] = boxTypeCounter;
									}
								}
							});
						}
					},
					this.handleAddUndo
				);

				// Get the current visibility state key
				const currentVisibilityStateKey = getCurrentVisibilityStateKey(
					updatedIllustrationData
				);

				// Get the visibility state
				const currentVisibilityState = getCurrentVisibilityState(
					currentVisibilityStateKey,
					updatedIllustrationData
				);

				// console.log(updatedIllustrationData.boxes);

				// Get the updated state for the illustration data
				updatedState = this.getUpdatedStateForIllustrationData(
					updatedIllustrationData,
					this.state.illustrationData,
					this.state.flattenedBoxMap,
					currentVisibilityState
				);
			}
		}

		// Merge in the updated state, close the multi-edit boxes dialog, and clear
		// the selection
		this.setState({
			isMultiEditBoxesModalVisible: false,
			boxSelectionInfoMap: {},
			firstSelectedBoxKey: undefined,
			...updatedState,
			boxTypeCounters,
		});
	};

	private handleMultiEditBoxesCancel = () => {
		// Close the multi-edit boxes dialog and clear the selection
		this.setState({
			isMultiEditBoxesModalVisible: false,
			boxSelectionInfoMap: {},
			firstSelectedBoxKey: undefined,
		});
	};
}

export const CurrentIllustrationPage = withRouter(CurrentIllustrationPageC);
