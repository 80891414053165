import * as lensesLib from "@lib/lenses/lenses";
import * as attributeLib from "@lib/box/attribute";

import { useEffect, useState } from "react";

export interface AttributeExpressionViewModel {
	// UUID of the attribute type that we want to match
	attributeType: string;

	// The value that we are going to test against for equaility etc.based on the operator
	attributeValue: attributeLib.AttributeValue;

	// This should be taken from a list of the parameters in the top Power Lens form. It's basically picking values that are getting passed in. if this is set then we don't want to specify the attribute value. Parameter takes precedence.
	attributeParameter: string;

	// This is the comparison operator to test the attribute's value against either attributeParameter OR attributeValue (depending on which one is set)
	operator: lensesLib.AttributeExpressionOperator;
};

const createAttributeExpressionViewModelFromAttributeExpression = (attributeExpression: lensesLib.AttributeExpression) : AttributeExpressionViewModel => {
	return {
		attributeType: attributeExpression.attributeType,
		attributeParameter: attributeExpression.attributeParameter,
		attributeValue: attributeExpression.attributeValue,
		operator: attributeExpression.operator
	}
};

const firstAttributeHasNoChildren = (attributeExpression: lensesLib.AttributeExpression | undefined) => {
	return attributeExpression !== undefined && (attributeExpression.children === undefined || attributeExpression.children.length <= 0);
};

const childrenAreOnlyOneLevelDeep = (attributeExpression: lensesLib.AttributeExpression | undefined) => {
	return attributeExpression !== undefined && attributeExpression.children !== undefined && attributeExpression.children.findIndex((item) => {
		return item.children !== undefined && item.children.length > 0;
	}) === -1;
};


const createAttributeExpressionList = (attributeExpression: lensesLib.AttributeExpression | undefined) => {

	const attributeExpressionList: AttributeExpressionViewModel[] =  [];

	if (attributeExpression !== undefined && (firstAttributeHasNoChildren(attributeExpression) || childrenAreOnlyOneLevelDeep(attributeExpression))) {
		// Push the first element and the first level of children to the array
		attributeExpressionList.push(createAttributeExpressionViewModelFromAttributeExpression(attributeExpression));

		// Iterate through the first level of children and map them
		if (attributeExpression.children !== undefined) {
			const mappedChildren = attributeExpression.children.map((value) => {
				return createAttributeExpressionViewModelFromAttributeExpression(value);
			});

			attributeExpressionList.push(...mappedChildren);
		}
	}
	
	return attributeExpressionList;
};

const createJSONStringForAttribute = (attributeExpression: lensesLib.AttributeExpression | undefined) => {
	return JSON.stringify(attributeExpression !== undefined ? attributeExpression : {})
};


const isJSONValid = (json: string) => {
	// Get whether the JSON is valid (assume it's not)
	let jsonIsValid = false;
	try {
		JSON.parse(json);
		jsonIsValid = true;
	} catch (e) {
		jsonIsValid = false;
	}

	return jsonIsValid;
};

const useAttributeQueryRestrictions = (query: lensesLib.Query) => {

	// Base attribute that can be set as an override.

	const [attributeExpressionList, setAttributeExpressionList] = useState<AttributeExpressionViewModel[]>(createAttributeExpressionList(query.attributeExpression));

	const [childrenOperator, setChildrenOperator] = useState<lensesLib.BooleanOperator>(lensesLib.BooleanOperator.AND);

	const [useAdvancedMode, setUseAdvancedMode] = useState<boolean>(false);

	const onUseAdvancedModeChanged = (value: boolean) => {
		setUseAdvancedMode(value);
	}


	// TODO: Fix this so it actually decides if we need to use the JSON editor or not
	const requiresJSONEditor = false; //attributeExpression !== undefined && (firstAttributeHasNoChildren(attributeExpression) || childrenAreOnlyOneLevelDeep(attributeExpression)));

	///////////////////////////////
	// JSON State Values
	///////////////////////////////

	const [JSONString, setJSONString] = useState(createJSONStringForAttribute(query.attributeExpression));
	const [JSONIsValid, setJSONIsValid] = useState(isJSONValid(createJSONStringForAttribute(query.attributeExpression)))


	const JSONEditorStyle = JSONIsValid ? {}
	: {
		borderWidth: "3px",
		borderStyle: "solid",
		borderColor: "red",
	};

	const onJSONStringChange = (code: string) => {
		setJSONString(code);
		setJSONIsValid(isJSONValid(code));
	}

	const showJSONEditor = requiresJSONEditor || useAdvancedMode;


	// Update the local state when the query changes.
	useEffect(() => {

		const newAttributeExpressionList = createAttributeExpressionList(query.attributeExpression);

		setAttributeExpressionList(newAttributeExpressionList);

		// Set JSON State
		const localJSONString = createJSONStringForAttribute(query.attributeExpression);
		setJSONString(localJSONString);
		setJSONIsValid(isJSONValid(localJSONString));
		
	}, [query]);

	// // When we switch to advanced mode we want to make sure that the latest JSON has been loaded
	// // This will need to make the actual attributeExpression and then serialise it.
	// useEffect(() => {

	// }, [useAdvancedMode])


	///////////////////////////////////////////////////////
	// Form State Values
	///////////////////////////////////////////////////////

	// Add/Edit Attribute Type Expression Form Variables
	const [isAddEditAttributeExpressionDialogVisible, setIsAddEditAttributeExpressionDialogVisible] = useState(false);
	const [isAddEditAttributeExpressionDialogAdding, setIsAddEditAttributeExpressionDialogAdding] = useState(false);
	const [addEditAttributeExpression, setAddEditAttributeExpression] = useState<AttributeExpressionViewModel | null>(null);
	const [addEditAttributeExpressionIndex, setAddEditAttributeExpressionIndex] = useState(-1);

	const onAddEditAttributeExpressionFormOk = (index: number, attributeExpression: AttributeExpressionViewModel) => {
		
		const newAttributeExpressionList: AttributeExpressionViewModel[]  = attributeExpressionList !== undefined && attributeExpressionList !== null ? JSON.parse(JSON.stringify(attributeExpressionList)) : [];

		// If we're editing
		if (index > -1) {
			newAttributeExpressionList[index] = attributeExpression;
		}
		// Otherwise if we're adding, just push the record into the array
		else {
			newAttributeExpressionList.push(attributeExpression);
		}
		
		setIsAddEditAttributeExpressionDialogVisible(false);
		setAttributeExpressionList(newAttributeExpressionList);
		setAddEditAttributeExpression(null);
		setAddEditAttributeExpressionIndex(-1);
	};



	const onOpenAddAttributeExpressionForm = () => {
		setAddEditAttributeExpressionIndex(-1);
		setIsAddEditAttributeExpressionDialogAdding(false);
		setAddEditAttributeExpression(null);
		setIsAddEditAttributeExpressionDialogVisible(true);
	}

	const onOpenEditAttributeExpressionForm = (index: number) => {
		if (attributeExpressionList !== undefined) {

			const editVar = JSON.parse(JSON.stringify(attributeExpressionList[index]));

			setAddEditAttributeExpression(editVar);
			setIsAddEditAttributeExpressionDialogAdding(false);
			setIsAddEditAttributeExpressionDialogVisible(true);
		}
	}

	const onCloseAddEditAttributeExpressionForm = () => {
		setIsAddEditAttributeExpressionDialogVisible(false);
		setIsAddEditAttributeExpressionDialogAdding(false);
		setAddEditAttributeExpression(null);
	};

	const onDeleteAttributeExpression = (index: number) => {
		const newAttributeExpressionList = JSON.parse(JSON.stringify(attributeExpressionList));
		delete newAttributeExpressionList[index];
		setAttributeExpressionList(newAttributeExpressionList);
		setIsAddEditAttributeExpressionDialogVisible(false);
	};

	return {
		attributeExpressionList,
		requiresJSONEditor,
		JSONString,
		JSONEditorStyle,
		useAdvancedMode,
		showJSONEditor,
		JSONIsValid,
		onUseAdvancedModeChanged,
		onJSONStringChange,

		isAddEditAttributeExpressionDialogVisible,
		isAddEditAttributeExpressionDialogAdding,
		addEditAttributeExpression,
		addEditAttributeExpressionIndex,

		onAddEditAttributeExpressionFormOk,
		onOpenAddAttributeExpressionForm,
		onOpenEditAttributeExpressionForm,
		onCloseAddEditAttributeExpressionForm,
		onDeleteAttributeExpression,
	};
};

export default useAttributeQueryRestrictions;