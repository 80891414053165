import { PlusOutlined } from "@ant-design/icons";
import LabelledElement from "@components/molecules/LabelledElement";
import FormHeading from "@components/organisms/FormHeading";
import FormHeadingText from "@components/organisms/FormHeadingText";
import { Button, Checkbox, Input } from "antd";
import React from "react";

import * as lensesLib from "@lib/lenses/lenses";
import AttributeExpressionTable from "@components/molecules/AttributeExpressionTable";
import BoxQueryRestrictions from "./BoxQueryRestrictions";
import useAttributeQueryRestrictions, { AttributeExpressionViewModel } from "../hooks/useAttributeQueryRestrictions";
import JsonEditor from "@components/molecules/JsonEditor";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import AddEditAttributeExpressionDialog from "../AddEditAttributeExpressionDialog";

import { AttributeTypeMap } from "@lib/box/attribute-type";

interface AttributeQueryRestrictionsProps {
	attributeTypes: AttributeTypeMap,
	parameters: lensesLib.ParameterMap,
	attributeExpressionList: AttributeExpressionViewModel[],

	requiresJSONEditor: boolean,
	JSONString: string,
	JSONEditorStyle: {}
	| {
		borderWidth: "3px",
		borderStyle: "solid",
		borderColor: "red",
	},
	useAdvancedMode: boolean,
	showJSONEditor: boolean,
	JSONIsValid: boolean,

	onUseAdvancedModeChanged: (value: boolean) => void,
	onJSONStringChange: (code: string) => void,

	isAddEditAttributeExpressionDialogVisible: boolean,
	isAddEditAttributeExpressionDialogAdding: boolean,
	addEditAttributeExpression: AttributeExpressionViewModel | null
	addEditAttributeExpressionIndex: number,

	onAddEditAttributeExpressionFormOk: (index: number, attributeExpression: AttributeExpressionViewModel) => void,
	onOpenAddAttributeExpressionForm: () => void,
	onOpenEditAttributeExpressionForm: (index: number) => void,
	onCloseAddEditAttributeExpressionForm: () => void,
	onDeleteAttributeExpression: (index: number) => void
}

// ## Attribute Expressions - A List, where you can pick the operator
// - need to double up on the operator here.


const AttributeQueryRestrictions: React.FC<AttributeQueryRestrictionsProps> = ({
	attributeTypes,
	parameters,
	attributeExpressionList,

	requiresJSONEditor,
	JSONString,
	JSONEditorStyle,
	useAdvancedMode,
	showJSONEditor,
	JSONIsValid,

	onUseAdvancedModeChanged,
	onJSONStringChange,

	isAddEditAttributeExpressionDialogVisible,
	isAddEditAttributeExpressionDialogAdding,
	addEditAttributeExpression,
	addEditAttributeExpressionIndex,

	onAddEditAttributeExpressionFormOk,
	onOpenAddAttributeExpressionForm,
	onOpenEditAttributeExpressionForm,
	onCloseAddEditAttributeExpressionForm,
	onDeleteAttributeExpression
}) => {



	return <>

		<FormHeading>
			<FormHeadingText>Attribute Expressions</FormHeadingText>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr auto",
					gridColumnGap: "12px",
				}}
			>
				<div>
					<LabelledElement labelText="Use Advanced Mode">
						<Checkbox
							checked={useAdvancedMode}
							onChange={(event: CheckboxChangeEvent) => { onUseAdvancedModeChanged(event.target.checked); }
							}
						/>
					</LabelledElement>
				</div>
				{!showJSONEditor && <Button
					icon={<PlusOutlined />}
					type="primary"
					style={{
						paddingRight: "12px",
						marginRight: "12px",
					}}
					onClick={onOpenAddAttributeExpressionForm}
				>
					Add
				</Button>}
			</div>
		</FormHeading>

		{showJSONEditor ?
			<JsonEditor code={JSONString} onChange={onJSONStringChange} style={JSONEditorStyle} />
			:
			<>
				<AddEditAttributeExpressionDialog
					index={addEditAttributeExpressionIndex}
					onOKButtonClick={onAddEditAttributeExpressionFormOk}
					onCancelButtonClick={onCloseAddEditAttributeExpressionForm}
					isAdding={isAddEditAttributeExpressionDialogAdding}
					isVisible={isAddEditAttributeExpressionDialogVisible}
					attributeExpression={addEditAttributeExpression}
					attributeTypes={attributeTypes}
					parameters={parameters}
				/>
				<AttributeExpressionTable
					attributeTypes={attributeTypes}
					attributeExpressionList={attributeExpressionList}
					onEdit={onOpenEditAttributeExpressionForm}
					onDelete={onDeleteAttributeExpression}
					canDelete={true}
					canEdit={true}
				/>
			</>
		}

	</>;
}

export default AttributeQueryRestrictions;