
export interface NameValue {
  name: string;
  value: string;
}

export enum ValueTypeKey {
  URL = 'url',
  File = 'file',
  Presentation = 'presentation',
  Text = 'text',
  Number = 'number',
  CalculatedField = 'calculatedField',
  Choice = 'choice',
  Associations = 'associations'
}

export interface ValueType {
  name: string;
  options?: NameValue[];
}

export type ValueTypeChoiceKeyToValueMap = { [key: string]: string }

export type ValueTypeMap = { [key: string]: ValueType }