import { ModelService, makeModelService } from './model-service';
import { Client, CreateClientCommand, UpdateClientCommand } from '@contracts/client';

const entityName = 'clients';

export interface ClientService extends ModelService<Client, Client, CreateClientCommand, UpdateClientCommand> {
}

const genericService = makeModelService<Client, Client, CreateClientCommand, UpdateClientCommand>(entityName);

const clientService: ClientService = { ...genericService };

export default clientService;