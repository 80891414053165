import React, { useEffect, useState } from "react";

import { Modal, Input, Button, Checkbox } from "antd";

import * as valueTypeLib from "@lib/box/value-type";
import * as boxTypeLib from "@lib/box/box-type";
import * as attributeTypeLib from "@lib/box/attribute-type";

import FormHeading from "@components/organisms/FormHeading";
import FormHeadingText from "@components/organisms/FormHeadingText";
import MixinBoxTypes from "./MixinBoxTypes";
import { PlusOutlined, DownloadOutlined } from "@ant-design/icons";
import AttributeTypeTable from "./AttributeTypeTable";
import AddEditAttributeTypeDialog from "./add-edit-attribute-type-dialog/AddEditAttributeTypeDialog";
import LabelledElement from "@components/molecules/LabelledElement";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import ImportAttributeTypesDialog from "./ImportAttributeTypesDialog";
import { BoxStyleMap } from '@lib/box/box-style';
export interface AddEditBoxTypeDialogProps {
	onOKButtonClick: (
		boxType: boxTypeLib.BoxType | undefined,
		boxTypeKey: string
	) => void;
	onCancelButtonClick: () => void;
	isAdding: boolean;
	isVisible: boolean;
	boxTypeKey: string;
	boxTypes: boxTypeLib.BoxTypeMap | undefined;
	valueTypes: valueTypeLib.ValueTypeMap | undefined;
	boxStyles: BoxStyleMap | undefined;
}

const getBoxType = (
	boxTypeKey: string,
	boxTypes: boxTypeLib.BoxTypeMap | undefined,
	isAdding: boolean
): boxTypeLib.BoxType | undefined => {
	// The box type (initially undefined)
	let boxBoxType: boxTypeLib.BoxType | undefined = undefined;

	// Do we have box types?
	if (boxTypes) {
		// Are we adding?
		if (isAdding) {
			// Create a default box type
			boxBoxType = boxTypeLib.createDefaultBoxType();
		} else if (
			Object.prototype.hasOwnProperty.call(boxTypes, boxTypeKey) &&
			boxTypes[boxTypeKey]
		) {
			// Otherwise, try and retrieve an existing box type
			boxBoxType = boxTypes[boxTypeKey];
		}
	}

	return boxBoxType;
};


const AddEditBoxTypeDialog: React.FC<AddEditBoxTypeDialogProps> = (props) => {
	// Get the title
	const title = props.isAdding ? "Add Box Type" : "Edit Box Type";

	const initialBoxTypeFromProps = getBoxType(
		props.boxTypeKey,
		props.boxTypes,
		props.isAdding
	);

	// Configure the state
	const [boxType, setBoxType] = useState(initialBoxTypeFromProps);

	const [addEditAttributeDialogVisible, setAddEditAttributeTypeDialogVisible] = useState(false);
	const [showImportAttributeTypesDialog, setShowImportAttributeTypesDialog] = useState(false);

	const [isAddEditAttributeTypeDialogAdding, setisAddEditAttributeTypeDialogAdding] = useState(false);


	const [attributeType, setAttributeType] = useState<attributeTypeLib.AttributeType | null>(null);
	const [attributeTypeKey, setAttributeTypeKey] = useState<string>("");


	// Update the box type based on props
	useEffect(() => {
		setBoxType(getBoxType(
			props.boxTypeKey,
			props.boxTypes,
			props.isAdding
		))
	}, [props.boxTypes, props.boxTypeKey, props.isAdding]);


	const handleOKButtonClick = () => {
		// Notify the callback that the OK button has been clicked, passing the new
		// box type (if we have one)
		if (boxType) {
			props.onOKButtonClick(
				boxType,
				props.boxTypeKey
			);
		}
	};

	const handleMixinBoxTypesInputChange = (value: string[]) => {
		// Get the new mixin box types
		const mixinBoxTypes = value.join(",");

		// Get a copy of the box type
		const updatedBoxType: boxTypeLib.BoxType = JSON.parse(
			JSON.stringify(boxType)
		);

		// Set the new name of the box type
		updatedBoxType.mixinBoxTypes = mixinBoxTypes;

		// Update the state with the new box type mixins
		setBoxType(updatedBoxType);
	};

	const handleBoxTypeNameChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		// Get the new box type name
		// TODO: Disable the OK button if the box type name is empty
		const boxTypeName = event.target.value;

		// Get a copy of the box type
		const updatedBoxType = JSON.parse(JSON.stringify(boxType));

		// Set the new name of the box type
		updatedBoxType.name = boxTypeName;

		// Update the state with the new box type name
		setBoxType(updatedBoxType);
	};
	const handleBoxTypeDescriptionChange = (
		event: any
	) => {
		// Get the new box name
		// TODO: Disable the OK button if the box type description is empty
		const boxTypeDescription = event.target.value;

		// Get a copy of the box type
		const updatedBoxType = JSON.parse(JSON.stringify(boxType));

		// Set the new description of the box type
		updatedBoxType.description = boxTypeDescription;

		// Update the state with the new box type description
		setBoxType(updatedBoxType)
	};

	const handleImportAttributeTypes = () => {
		setShowImportAttributeTypesDialog(true);
	};

	const handleAddAttributeType = () => {
		const newKey = attributeTypeLib.getNewAttributeTypeKey();
		setAttributeType(attributeTypeLib.createDefaultAttributeType());
		setAttributeTypeKey(newKey);
		setisAddEditAttributeTypeDialogAdding(true);
		setAddEditAttributeTypeDialogVisible(true);
	};

	const handleEditAttributeType = (key: string) => {
		if (boxType) {
			setAttributeType(boxType.attributeTypes[key]);
			setAttributeTypeKey(key);
			setisAddEditAttributeTypeDialogAdding(false);
			setAddEditAttributeTypeDialogVisible(true);
		}
	};

	const handleDisableBoxCreationChange = (checked: boolean) => {
		const updatedBoxType = JSON.parse(JSON.stringify(boxType));
		updatedBoxType.disableBoxCreation = checked;
		setBoxType(updatedBoxType);
	};

	const handleDeleteAttributeType = (key: string) => {
		// Get a copy of the box type
		const updatedBoxType = JSON.parse(JSON.stringify(boxType));

		// Delete the attribute type
		delete updatedBoxType.attributeTypes[key];

		// Update the state with the new box type description
		setBoxType(updatedBoxType);
	};

	return <Modal
		title={title}
		open={props.isVisible}
		onOk={handleOKButtonClick}
		onCancel={props.onCancelButtonClick}
		zIndex={9999}
		width="75%"
	>
		{boxType ?
			<div>
				<FormHeading>
					<FormHeadingText>Type Details</FormHeadingText>
				</FormHeading>
				<div
					style={{
						width: "100%",
						display: "grid",
						gridTemplateColumns: "0.5fr 1fr 0.5fr 1fr",
						gap: "4px 12px",
						padding: 0,
						margin: 0,
						overflowY: "visible",
					}}
				>
					<LabelledElement labelText="Name">
						<Input
							value={boxType.name}
							onChange={(
								event: React.ChangeEvent<HTMLInputElement>
							) => handleBoxTypeNameChange(event)}
						/>
					</LabelledElement>

					<LabelledElement labelText="UUID">
						<Input
							value={props.boxTypeKey}
							readOnly
							onChange={(
								event: React.ChangeEvent<HTMLInputElement>
							) => { }}
						/>
					</LabelledElement>

					<LabelledElement labelText="Description">
						<Input.TextArea
							value={boxType.description}
							style={{ gridColumnStart: 2, gridColumnEnd: 5 }}
							rows={4}
							onChange={handleBoxTypeDescriptionChange}
						/>
					</LabelledElement>

					<MixinBoxTypes boxTypes={props.boxTypes} mixinBoxTypes={boxType.mixinBoxTypes} onMixinBoxTypesInputChange={handleMixinBoxTypesInputChange} />

					<LabelledElement labelText="Disable Box Creation Using This Type">
						<Checkbox
							checked={boxType.disableBoxCreation}
							onChange={(event: CheckboxChangeEvent) =>
								handleDisableBoxCreationChange(event.target.checked)
							}
						/>
					</LabelledElement>
				</div>

				<FormHeading>
					<FormHeadingText>Attribute Types</FormHeadingText>
					<div>
						<Button
							icon={<PlusOutlined />}
							type="primary"
							style={{
								paddingRight: "12px",
								marginRight: "12px",
							}}
							onClick={handleAddAttributeType}
						>
							Add
						</Button>
						<Button
							icon={<DownloadOutlined />}
							type="primary"
							style={{
								paddingRight: "12px",
							}}
							onClick={handleImportAttributeTypes}
						>
							Import
						</Button>
					</div>
				</FormHeading>

				<AttributeTypeTable
					attributes={boxType.attributeTypes}
					onEdit={handleEditAttributeType}
					onDelete={handleDeleteAttributeType}
				/>
				<ImportAttributeTypesDialog isVisible={showImportAttributeTypesDialog}
					onCancelButtonClick={() => { setShowImportAttributeTypesDialog(false) }}
					onOKButtonClick={() => { setShowImportAttributeTypesDialog(false) }}

				/>

				{attributeType ?
					<AddEditAttributeTypeDialog isVisible={addEditAttributeDialogVisible}
						onCancelButtonClick={() => { setAddEditAttributeTypeDialogVisible(false) }}
						onOKButtonClick={
							(
								newAttributeType: attributeTypeLib.AttributeType | undefined,
								newAttributeTypeKey: string
							) => {
								if (newAttributeType) {
									const newBoxType: boxTypeLib.BoxType = JSON.parse(JSON.stringify(boxType));
									newBoxType.attributeTypes[newAttributeTypeKey] = newAttributeType;
									setBoxType(newBoxType);
								}

								setAddEditAttributeTypeDialogVisible(false);
							}
						}
						boxTypes={props.boxTypes !== undefined ? props.boxTypes : {}}
						attributeType={attributeType}
						attributeTypeKey={attributeTypeKey}
						boxTypeKey={props.boxTypeKey}
						boxTypeName={boxType.name}
						valueTypes={props.valueTypes}
						isAdding={isAddEditAttributeTypeDialogAdding}
						boxStyles={props.boxStyles}
					/> : null}
			</div>
			: null}
	</Modal >
}

export default AddEditBoxTypeDialog;