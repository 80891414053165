import React, { useEffect, useState } from "react";

import { Modal, Input, InputNumber, Tree } from "antd";
import * as lensesLib from "@lib/lenses/lenses";
import * as boxTypeLib from "@lib/box/box-type";
import FormHeading from "@components/organisms/FormHeading";
import FormHeadingText from "@components/organisms/FormHeadingText";
import LabelledElement from "@components/molecules/LabelledElement";
import TextArea from "antd/lib/input/TextArea";
export interface AddEditSimpleLensDialogProps {
	onOKButtonClick: (simpleLens: lensesLib.SimpleLens) => void;
	onCancelButtonClick: () => void;
	isAdding: boolean;
	isVisible: boolean;
	simpleLens: lensesLib.SimpleLens;
	simpleLensKey: string;
	boxTypes: boxTypeLib.BoxTypeMap;
}

interface TreeNode {
	title: string;
	key: string;
	children: TreeNode[];
}

const AddEditSimpleLensDialog: React.FC<AddEditSimpleLensDialogProps> = (props) => {
	// Get the title
	const title = props.isAdding ? "Add Lens" : "Edit Lens";

	// Configure the state
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [order, setOrder] = useState(0);


	const [treeData, setTreeData] = useState<TreeNode[]>([]);
	const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
	const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
	const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
	const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);

	useEffect(()=>{
		setName(props.simpleLens.name);
		setDescription(props.simpleLens.description);
		setOrder(props.simpleLens.order);
		setTreeData(createTreeData(props.boxTypes));
		setExpandedKeys([]);
		setCheckedKeys(convertSimpleLensEntriesToCheckedKeys(props.simpleLens.entries));
		setSelectedKeys([]);

	}, [props.simpleLens, props.simpleLensKey, props.boxTypes])

	const convertSimpleLensEntriesToCheckedKeys = (simpleLensEntries: lensesLib.SimpleLensEntry[]) => {
		return simpleLensEntries.map((simpleLensEntry) => { return `${simpleLensEntry.boxTypeKey}_${simpleLensEntry.attributeTypeKey}`;});
	};

	const handleOKButtonClick = () => {

		const simpleLens : lensesLib.SimpleLens = { name, description, order, entries: [] }

		// Get a list of all the selected box types and then get a list of all the attributes for those box types
		// We can then add the box type and delete any attributes that aren't in the resulting list.

		checkedKeys.forEach((selectedKey, index) => {
			const splitStrings = selectedKey.toString().split('_');

			const isAttributeType = splitStrings.length === 2;

			if (isAttributeType) {
				simpleLens.entries.push({boxTypeKey: splitStrings[0], attributeTypeKey: splitStrings[1]});
			}
		});

		props.onOKButtonClick(simpleLens);
	};


	const onExpand = (expandedKeysValue: React.Key[]) => {
		// if not set autoExpandParent to false, if children expanded, parent can not collapse.
		// or, you can remove all expanded children keys.
		setExpandedKeys(expandedKeysValue);
		setAutoExpandParent(false);
	};

	const onCheck = (checkedKeysValue: any) => {
		setCheckedKeys(checkedKeysValue);
	};

	const onSelect = (selectedKeysValue: any, info: any) => {
		setSelectedKeys(selectedKeysValue);
	};

	const createTreeData = (boxTypes: boxTypeLib.BoxTypeMap): TreeNode[] => {
		return Object.keys(boxTypes).map((key, index) => {
			const boxType = boxTypes[key];

			const children = Object.keys(boxType.attributeTypes).map((attributeTypeKey, index) => {
				const attributeType = boxType.attributeTypes[attributeTypeKey];
				return { title: attributeType.name, key: `${key}_${attributeTypeKey}`, children: [] };
			});

			return {
				title: boxType.name, key: key,
				children
			};
		});
	}

	return <Modal
		title={title}
		open={props.isVisible}
		onOk={handleOKButtonClick}
		onCancel={props.onCancelButtonClick}
		zIndex={9999}
		width="75%"
	>
			<div>
				<FormHeading>
					<FormHeadingText>Lens Details</FormHeadingText>
				</FormHeading>
				<div
					style={{
						width: "100%",
						display: "grid",
						gridTemplateColumns: "0.1fr 0.9fr",
						gap: "4px 12px",
						padding: 0,
						margin: 0,
						overflowY: "visible",
					}}
				>
				<LabelledElement labelText="Name">
					<Input

						value={name}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>
						) => { setName(event.target.value) }}
					/>
				</LabelledElement>
				<LabelledElement labelText="Description">
					<TextArea
						value={description}
						onChange={(
							event: React.ChangeEvent<HTMLTextAreaElement>
						) => { setDescription(event.target.value) }}
					/>	
				</LabelledElement>
				<LabelledElement labelText="Order">
					<InputNumber
						value={order}
						onChange={(value: number | null)  => { if (value !== null) { setOrder(value);}}}
					/>
				</LabelledElement>

				</div>
			</div>
			<FormHeading>
					<FormHeadingText>Lens Attributes</FormHeadingText>
			</FormHeading>
			<Tree
				checkable
				onExpand={onExpand}
				expandedKeys={expandedKeys}
				autoExpandParent={autoExpandParent}
				onCheck={onCheck}
				checkedKeys={checkedKeys}
				onSelect={onSelect}
				selectedKeys={selectedKeys}
				treeData={treeData}
			/>

	</Modal>
}

export default AddEditSimpleLensDialog;