import * as React from "react";

import { Divider, Modal, Table } from "antd";

import * as lensesLib from "@lib/lenses/lenses";
import Link from "antd/lib/typography/Link";
import { validateLocaleAndSetLanguage } from "typescript";

const confirm = Modal.confirm;

interface PowerLensQueryTableProps {
	onEdit: (index: number) => void;
	onDelete: (index: number) => void;
	queries: lensesLib.Query[];
	canEdit: boolean;
	canDelete: boolean;
}

interface QueryTableRow {
	key: number;
	name: string;
	type: string;
	operator: string;
	applyBoxVisibility: string;
	boxIsVisible: string;
	boxIsInLayout: string;
}

const PowerLensQueryTable: React.FC<PowerLensQueryTableProps> = (props) => {
	// Build the box type list items

	const handleEdit = (index: number) => {
		// Notify the callback that the box type should be edited
		props.onEdit(index);
	};

	const handleDelete = (key: number) => {
		// Notify the callback that the box type should be removed
		props.onDelete(key);
	};

	const buildRows = (
		lensMap: lensesLib.Query[]
	): QueryTableRow[] => {
		// If we don't have a box map there's no list items
		if (!lensMap) {
			return [];
		}

		// Render each of the box types
		const queries = props.queries.map(
			(value, index) => {
				// Get the box type
				// Build the box type list item
				const queryRow: QueryTableRow = {
					key: index,
					name: value.name ? value.name : '',
					type: value.type ? value.type.toString() : lensesLib.QueryType.DEFAULT.toString(),
					operator: value.operator,
					applyBoxVisibility: value.applyBoxVisibility ? "True" : "False",
					boxIsVisible: value.boxIsVisible ? "True" : "False",
					boxIsInLayout: value.boxIsInLayout ? "True" : "False"
				};

				return queryRow;
			}
		);

		return queries;
	};

	const queries: QueryTableRow[] = buildRows(
		props.queries
	);

	const queryTableColumns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
		},
		{
			title: "Action",
			key: "action",
			render: (text: string, record: QueryTableRow) => {
				return (
					<span>
						{record.type && record.type !== lensesLib.QueryType.DEFAULT.toString() && <Link onClick={() => {
							(handleEdit(record.key))
						}}>
							Edit
						</Link>}
						<Divider type="vertical" />
						<Link
							onClick={() => {
								confirm({
									title:
										"Are you sure delete this Query?",
									okText: "Yes",
									okType: "danger",
									cancelText: "No",
									zIndex: 9999999,
									onOk: () => { handleDelete(record.key) },
									onCancel: () => { },
								});
							}}
						>
							Delete
						</Link>
					</span>
				);
			},
		},
	];

	return (
		<Table
			dataSource={queries}
			pagination={false}
			columns={queryTableColumns}
		/>
	);
}

export default PowerLensQueryTable;