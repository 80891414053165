import * as React from "react";

import { Input, Select, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";

import * as propertyLib from "@lib/box/box-properties";
import * as attributeTypeLib from "@lib/box/attribute-type";
import { createBoxDefaultPropertyNameMap } from '@lib/box/box-properties'
import { BoxStyleMap } from '@lib/box/box-style';

interface PropertyListProps {
	onPropertyChange: (
		propertyKey: string,
		propertyValue: propertyLib.PropertyValue | undefined
	) => void;
	defaultProperties: propertyLib.PropertyMap | undefined;
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined;
	boxStyles: BoxStyleMap | undefined;
	displayChangedCheckboxes?: boolean;
}

export class PropertyList extends React.Component<PropertyListProps> {
	public render() {
		// Do we not have defaulut properties? If so, return an empty div
		if (!this.props.defaultProperties) {
			return <div></div>;
		}

		// Render the properties
		const renderedProperties = this.renderProperties(
			this.props.defaultProperties
		);

		const gridTemplateColumns = this.props.displayChangedCheckboxes
			? "1fr 0.9fr 0.1fr 1fr 0.9fr 0.1fr 1fr 0.9fr 0.1fr 1fr 0.9fr 0.1fr"
			: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

		return (
			<div
				style={{
					width: "100%",
					display: "grid",
					gridTemplateColumns,
					gap: "4px 12px",
					alignItems: "start",
					padding: 0,
					margin: 0,
					overflowY: "visible",
				}}
			>
				{renderedProperties}
			</div>
		);
	}

	private handlePropertyInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		propertyKey: string
	) => {
		// Get the new property value
		// TODO: Handle different types of property values
		const propertyValue = event.target.value;

		// Notify the callback that the property has changed
		this.props.onPropertyChange(propertyKey, propertyValue);
	};

	private handlePropertyCheckboxChange = (
		event: CheckboxChangeEvent,
		propertyKey: string,
		propertyValue: propertyLib.PropertyValue | undefined
	) => {
		const actualPropertyValue = event.target.checked
			? propertyValue
			: undefined;

		// Notify the callback that the attribute has changed
		this.props.onPropertyChange(propertyKey, actualPropertyValue);
	};

	private renderProperties = (
		propertyMap: propertyLib.PropertyMap | undefined
	) => {
		// If we don't have properties there's nothing to render
		if (!propertyMap) {
			return null;
		}

		// Get the default properties of a box
		const defaultPropertiesKeyToNameMap = createBoxDefaultPropertyNameMap();

		// Render each of the properties
		const renderedProperties = Object.keys(
			defaultPropertiesKeyToNameMap
		).map((defaultPropertyKey, index) => {
			// Get the property name
			const propertyName =
				defaultPropertiesKeyToNameMap[defaultPropertyKey];

			// Get the property value
			const propertyValue = Object.prototype.hasOwnProperty.call(
				propertyMap,
				defaultPropertyKey
			)
				? propertyMap[defaultPropertyKey]
				: undefined;

			// Render the property type
			const renderedPropertyType = this.renderPropertyType(
				defaultPropertyKey,
				propertyName,
				propertyValue
			);

			return <React.Fragment key={index}>{renderedPropertyType}</React.Fragment>;
		});

		return renderedProperties;
	};

	private handleBadgeAttributeTypeChange = (attributeTypeKey: string) => {
		// Notify the callback that the badge attribute type property has changed
		this.props.onPropertyChange("badgeAttributeType", attributeTypeKey);
	};

	private handleBadgeAttributeTypeCheckboxChange = (
		event: CheckboxChangeEvent,
		attributeTypeKey: string | undefined
	) => {
		const actualAttributeTypeKey = event.target.checked
			? attributeTypeKey
			: undefined;

		// Notify the callback that the badge attribute type property has changed
		this.props.onPropertyChange(
			"badgeAttributeType",
			actualAttributeTypeKey
		);
	};

	private renderBadgeAttributeType = (
		badgeAttributeType: string | undefined
	) => {
		// Get the attribute types
		const attributeTypes = this.props.attributeTypes;

		// Render each of the attribute types as an option
		const renderedAttributeTypeOptions = attributeTypes
			? Object.keys(attributeTypes).map((currentAttributeTypeKey) => {
				// Get the attribute type
				const attributeType =
					attributeTypes[currentAttributeTypeKey];

				// Get the name of the attribute type
				const attributeTypeName = attributeType.name;

				// Render the box type option
				return (
					<Select.Option
						key={currentAttributeTypeKey}
						value={currentAttributeTypeKey}
					>
						{attributeTypeName}
					</Select.Option>
				);
			})
			: null;

		const hasBadgeAttributeType = badgeAttributeType !== undefined;

		return (
			<React.Fragment key={`fragment-${badgeAttributeType}`}>
				<b
					key={`label-${badgeAttributeType}`}
					style={{ textAlign: "left", paddingTop: "4px" }}
				>
					Badge Attribute Type:
				</b>
				<Select
					key={`input-${badgeAttributeType}`}
					onChange={this.handleBadgeAttributeTypeChange}
					value={badgeAttributeType}
					style={{ width: "100%" }}
					getPopupContainer={(node) => {
						let popupContainer: HTMLElement | null =
							window.document.documentElement;
						if (node && node.parentElement) {
							popupContainer = node.parentElement;
						}
						return popupContainer as HTMLElement;
					}}
				>
					{renderedAttributeTypeOptions}
				</Select>
				{this.props.displayChangedCheckboxes && (
					<Checkbox
						key={`checkbox-${badgeAttributeType}`}
						checked={hasBadgeAttributeType}
						onChange={(event: CheckboxChangeEvent) =>
							this.handleBadgeAttributeTypeCheckboxChange(
								event,
								badgeAttributeType
							)
						}
					/>
				)}
			</React.Fragment>
		);
	};

	private handleStyleInputChange = (value: string[] | undefined) => {
		// Get the new styles
		const styles = value !== undefined ? value.join(",") : undefined;

		// Notify the callback that the styles have changed
		this.props.onPropertyChange("styles", styles);
	};

	private handleStyleInputCheckboxChange = (
		event: CheckboxChangeEvent,
		value: string[] | undefined
	) => {
		const actualValue = event.target.checked ? value : undefined;

		// Notify the callback that the styles have changed
		this.handleStyleInputChange(actualValue);
	};
	private renderStyles = (styles: string | undefined) => {
		// Get the box styles
		const boxStyles = this.props.boxStyles;

		// Get the actual styles
		const actualStyles = styles ? styles : "";

		// Use those to get the select current value
		const selectValue: string[] =
			actualStyles.length > 0 ? actualStyles.split(",") : [];

		// The box style options
		let boxStyleOptions = null;

		const hasSelectValue = styles !== undefined;

		// Do we have box styles?
		if (boxStyles) {
			// Build the box style options
			boxStyleOptions = Object.keys(boxStyles)
				.sort((boxStyleKeyA: string, boxStyleKeyB: string) => {
					const boxStyleA = boxStyles[boxStyleKeyA];
					const boxStyleB = boxStyles[boxStyleKeyB];
					
					const hasOrderAttribute = boxStyleA.hasOwnProperty('order') &&
					boxStyleB.hasOwnProperty('order');
					
					// Get the names of the box styles
					const boxStyleNameA = hasOrderAttribute && boxStyleA.order !== undefined ? boxStyleA.order : boxStyleA.name;
					const boxStyleNameB =hasOrderAttribute && boxStyleB.order !== undefined ? boxStyleB.order :  boxStyleB.name;

					// If the order of box style A is before that of box style B, put box
					// style A first
					if (boxStyleNameA < boxStyleNameB) {
						return -1;
					}

					// If the order of box style A is after that of box style B, put box
					// style B first
					if (boxStyleNameA > boxStyleNameB) {
						return 1;
					}

					// Otherwise, don't change the order
					return 0;
				})
				.map((boxStyleKey) => {
					// Get the box style
					const optionBoxStyle = boxStyles[boxStyleKey];

					return (
						<Select.Option key={boxStyleKey} value={boxStyleKey}>
							{optionBoxStyle.name}
						</Select.Option>
					);
				});
		}

		return (
			<>
				<b
					key="label-styles"
					style={{ textAlign: "left", paddingTop: "4px" }}
				>
					Styles:
				</b>
				<Select
					key="input-styles"
					mode="multiple"
					size="large"
					allowClear
					placeholder="Please select"
					value={selectValue}
					onChange={(value) => this.handleStyleInputChange(value)}
					style={{ width: "100%" }}
					filterOption={(inputValue, option) => {
						const optionStyle = option && boxStyles && option.value !== null && option.value !== undefined ? boxStyles[option.value] : null;

						// Note: some of the names are numbers!
						// Convert it to a string just to be sure
						const optionBoxNameAsString = optionStyle != null &&
							optionStyle.name !== undefined &&
							optionStyle.name !== null ? String(optionStyle.name) : "";

						return optionBoxNameAsString.toLowerCase().includes(inputValue.toLowerCase());
					}}
					getPopupContainer={(node) => {
						let popupContainer: HTMLElement | null =
							window.document.documentElement;
						if (node && node.parentElement) {
							popupContainer = node.parentElement;
						}
						return popupContainer as HTMLElement;
					}}
				>
					{boxStyleOptions}
				</Select>
				{this.props.displayChangedCheckboxes && (
					<Checkbox
						key={`checkbox-styles`}
						checked={hasSelectValue}
						onChange={(event: CheckboxChangeEvent) =>
							this.handleStyleInputCheckboxChange(
								event,
								selectValue
							)
						}
					/>
				)}
			</>
		);
	};

	private renderPropertyType = (
		defaultPropertyKey: string,
		propertyName: string,
		propertyValue: propertyLib.PropertyValue | undefined
	) => {
		// Handle the badget attribute type property
		if (defaultPropertyKey === "badgeAttributeType") {
			return this.renderBadgeAttributeType(propertyValue);
		} else if (defaultPropertyKey === "styles") {
			return this.renderStyles(propertyValue);
		}

		const hasPropertyValue = propertyValue !== undefined;

		const stringPropertyValue = hasPropertyValue
			? String(propertyValue)
			: "";

		// Render the property
		// TODO: Use different render methods for different types of properties
		// TODO: Add an onChange callback to report modifications to an property
		const renderedProperty = (
			<React.Fragment key={`propertyType-${defaultPropertyKey}`}>
				<b
					key={`label-${defaultPropertyKey}`}
					style={{ textAlign: "left", paddingTop: "4px" }}
				>
					{propertyName}:
				</b>
				<Input
					key={`input-${defaultPropertyKey}`}
					value={stringPropertyValue}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						this.handlePropertyInputChange(
							event,
							defaultPropertyKey
						)
					}
				/>
				{this.props.displayChangedCheckboxes && (
					<Checkbox
						key={`checkbox-${defaultPropertyKey}`}
						checked={hasPropertyValue}
						onChange={(event: CheckboxChangeEvent) =>
							this.handlePropertyCheckboxChange(
								event,
								defaultPropertyKey,
								stringPropertyValue
							)
						}
					/>
				)}
			</React.Fragment>
		);

		return renderedProperty;
	};
}
