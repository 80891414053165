import { useState, useEffect } from "react";
import * as attributeTypeLib from "@lib/box/attribute-type";
import * as lensesLib from "@lib/lenses/lenses";
// ## Attribute Type Visiblity - List, where you can pick the attribute type and whether or not it is 
// - attributeTypeVisibilitys - list
// - attributeTypeVisibilityOperator - dropdown

export interface AttributeTypeVisibilityItem {
	key: string;
	value: boolean;
}

const createAttributeVisibilityItemList = (visibilityMap: attributeTypeLib.AttributeTypeVisibilityMap | undefined) => {
	return visibilityMap !== undefined ? Object.keys(visibilityMap).map((key) => {
		return { key: key, value: visibilityMap[key] };
	}) : [];
};

const useAttributeTypeVisibilityList = (sourceVisibilityMap: 	attributeTypeLib.AttributeTypeVisibilityMap | undefined) => {
	// Cache this so that we can return it at the end
	const [attributeTypeVisibilityMap, setAttributeTypeVisibilityMap] = useState<attributeTypeLib.AttributeTypeVisibilityMap | undefined>(sourceVisibilityMap);

		// Add/Edit Attribute Type Visibility Form Variables
	const [isAddEditAttributeTypeVisibilityItemDialogVisible, setIsAddEditAttributeTypeVisibilityItemDialogVisible] = useState(false);
	const [isAddEditAttributeTypeVisibilityItemDialogAdding, setIsAddEditAttributeTypeVisibilityItemDialogAdding] = useState(false);
	const [addEditAttributeTypeVisibilityItem, setAddEditAttributeTypeVisibilityItem] = useState<AttributeTypeVisibilityItem | null>(null);


	useEffect(() => {
		setAttributeTypeVisibilityMap(sourceVisibilityMap);

		// Reset the form values
		setIsAddEditAttributeTypeVisibilityItemDialogVisible(false);
		setIsAddEditAttributeTypeVisibilityItemDialogAdding(false);
		setAddEditAttributeTypeVisibilityItem(null);
	}, [sourceVisibilityMap])

	const onOpenAddAttributeTypeVisibilityForm = () => {
		setIsAddEditAttributeTypeVisibilityItemDialogAdding(false);
		setAddEditAttributeTypeVisibilityItem(null);
		setIsAddEditAttributeTypeVisibilityItemDialogVisible(true);
	}

	const onOpenEditAttributeTypeVisibilityForm = (key: string) => {
		if (attributeTypeVisibilityMap !== undefined) {
			setAddEditAttributeTypeVisibilityItem({ key, value: attributeTypeVisibilityMap[key] });
			setIsAddEditAttributeTypeVisibilityItemDialogAdding(false);
			setIsAddEditAttributeTypeVisibilityItemDialogVisible(true);
		}
	}

	const onCloseAddEditAttributeTypeVisibilityForm = () => {
		setIsAddEditAttributeTypeVisibilityItemDialogVisible(false);
		setIsAddEditAttributeTypeVisibilityItemDialogAdding(false);
		setAddEditAttributeTypeVisibilityItem(null);
	};

	const onAddEditAttributeTypeVisibilityItemFormOk = (attributeTypeVisibilityItem: AttributeTypeVisibilityItem) => {
		const newLocalVisibilityMap = attributeTypeVisibilityMap !== undefined && attributeTypeVisibilityMap !== null ? JSON.parse(JSON.stringify(attributeTypeVisibilityMap)) : {};

		// If we have changed the key, delete the old value)
		if (addEditAttributeTypeVisibilityItem !== null && addEditAttributeTypeVisibilityItem.key !== attributeTypeVisibilityItem.key) {
			delete newLocalVisibilityMap[addEditAttributeTypeVisibilityItem.key];
		}

		newLocalVisibilityMap[attributeTypeVisibilityItem.key] = attributeTypeVisibilityItem.value;
		setAttributeTypeVisibilityMap(newLocalVisibilityMap)
		setIsAddEditAttributeTypeVisibilityItemDialogVisible(false);
	};

	const onDeleteAttributeTypeVisibilityItem = (key: string) => {
		const newAttributeTypeVisibilitys = JSON.parse(JSON.stringify(attributeTypeVisibilityMap));
		delete newAttributeTypeVisibilitys[key];
		setAttributeTypeVisibilityMap(newAttributeTypeVisibilitys);
		setIsAddEditAttributeTypeVisibilityItemDialogVisible(false);
	};


	return {
		attributeTypeVisibilityMap,

		isAddEditAttributeTypeVisibilityItemDialogVisible,
		isAddEditAttributeTypeVisibilityItemDialogAdding,
		addEditAttributeTypeVisibilityItem,

		onDeleteAttributeTypeVisibilityItem,
		onAddEditAttributeTypeVisibilityItemFormOk,
		onCloseAddEditAttributeTypeVisibilityForm,
		onOpenEditAttributeTypeVisibilityForm,
		onOpenAddAttributeTypeVisibilityForm,
	};
}

export default useAttributeTypeVisibilityList;