import React, { useEffect, useState } from "react";

import { Modal, Button } from "antd";

import JsonEditor from "@components/molecules/JsonEditor";

export interface JsonEditorDialogProps {
	onOKButtonClick: (
		jsonString: string,
	) => void;
	onCancelButtonClick: () => void;
	isVisible: boolean;
	jsonType: string;
	jsonString: string;
}

const isJsonValid = (json: string) => {
	// Get whether the JSON is valid (assume it's not)
	let jsonIsValid = false;
	try {
		JSON.parse(json);
		jsonIsValid = true;
	} catch (e) {
		jsonIsValid = false;
	}

	return jsonIsValid;
};


const JsonEditorDialog: React.FC<JsonEditorDialogProps> = (props) => {
	// Get the title
	const title = `Edit JSON - ${props.jsonType}`;

	// Configure the state
	const [jsonString, setJsonString] = useState(props.jsonString);

	const [isValid, setIsValid] = useState(isJsonValid(props.jsonString));

	// Update the box type based on props
	useEffect(() => {
		setJsonString(props.jsonString);
		setIsValid(isJsonValid(props.jsonString));
	}, [props.jsonString]);


	const formatJson = () => {
		const localIsValid = isJsonValid(jsonString);
		setIsValid(localIsValid);

		// If the JSON is valid then format it
		setJsonString(JSON.stringify(JSON.parse(jsonString), null, 2));
	};

	const handleOKButtonClick = () => {
		// Notify the callback that the OK button has been clicked 

		if (isJsonValid(jsonString)) {

			setIsValid(true);

			props.onOKButtonClick(jsonString);
		}
		else {
			setIsValid(false);
		}
	};

	const style = isValid ? {}
		: {
			borderWidth: "3px",
			borderStyle: "solid",
			borderColor: "red",
		};

	return (
		<Modal
			title={title}
			open={props.isVisible}
			onOk={handleOKButtonClick}
			onCancel={props.onCancelButtonClick}
			zIndex={9999}
			width="75%"
		>
			<Button onClick={() => { formatJson() }} size="large" type="primary">Format and Validate Inputs</Button>&nbsp;
			<div style={style}>
				<JsonEditor
					code={jsonString}
					style={style}
					onChange={(code: string) => { setJsonString(code) }}
				/>
			</div>
		</Modal>);
};

export default JsonEditorDialog;