import { Client } from '@contracts/client';
import clientService from '@services/client';
import { Button, Table, notification } from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

export interface ClientListProps {
	onSelectClient : (client : Client) => void
};

const ClientList : React.FC<ClientListProps> = (props) => {

	const [data, setData] = useState<Client[]>([]);

	useEffect(() => {
		clientService.getList((clients) => {
		  if (clients) {	
			setData(clients);
		  }
		}, (message)=>{
			console.log(`Error occurred: ${message}.`);
			notification.open({
				message: 'Error: Could not load Client List',
				description:
					`Failed to load Client List.`,
				icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
			});
		});
	  }, []);


	const columns = [{
		title: 'Name',
		dataIndex: 'name',
		key: '_id',
		render: (text: any, record: any) => {
		  return <b>{text}</b>;
		},
	  }, {
		title: 'Action',
		key: 'action',
		render: (text: any, record: any) => {
		  return (
			<span>
			  <Button type="primary" size="large" onClick={() => {props.onSelectClient(record)}}>Select</Button>
			</span>
		  );
		},
	  }];
	
	
	return <Table columns={columns} pagination={false} rowKey="_id" dataSource={data} />
}

export default ClientList;