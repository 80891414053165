import { Client } from '@contracts/client';

export const getClientNameForClientId = (clients: Client[],
  clientId: string) => {
  // If the client ID is a wildcard, we're dealing with all clients
  if (clientId === '*') {
    return 'All';
  }

  // The name of the client
  let clientName = '';

  // Try and find the client that has the ID we're looking for
  const clientIndex = clients.findIndex((client) => client._id === clientId);
  if (clientIndex >= 0) {
    clientName = clients[clientIndex].name;
  }

  return clientName;
}
