import * as React from 'react';

import { Modal } from 'antd';

interface ViewHistoryDialogProps {
  onOKButtonClick: (() => void),
  onCancelButtonClick: (() => void),
  isVisible: boolean,
}

export class ViewHistoryDialog extends React.Component<ViewHistoryDialogProps> {
  public render() {
    return (
      <Modal
        title={'Version History'}
        open={this.props.isVisible}
        onOk={this.props.onOKButtonClick}
        onCancel={this.props.onCancelButtonClick}
        zIndex={9999}
      >
        Test Version History
      </Modal>
    );
  }
}
