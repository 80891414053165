import * as CSS from "csstype";

export enum TextSizing {
	MANUAL = "manual",
	AUTO = "auto",
}

export enum TextAlignment {
	START = "start",
	END = "end",
	CENTER = "center",
}

export const getTextStyle = (
	textWidthInPixels: number,
	textHeightInPixels: number,
	textAlignment: string,
	textAlignmentPaddingInPixels: number,
	textIsVertical: boolean,
	rotateIfVertical: boolean,
	fontFamily: CSS.Property.FontFamily,
	fontStyle: CSS.Property.FontStyle,
	fontSizeInPixels: number,
	fontWeight: CSS.Property.FontWeight,
	borderSizeInPixels: number,
): React.CSSProperties => {
	let alignment = "center";
	let paddingLeft: string | number = 0;
	let paddingRight: string | number = 0;
	let paddingTop: string | number = "auto";
	let paddingBottom: string | number = "auto";

	// If the text is vertical, treat the top of the box as the "start". We also
	// add 1rem of padding to the start of the text so it doesn't touch the edge
	// of the box.
	if (textAlignment === TextAlignment.START) {
		alignment = (textIsVertical) ? "end" : "start";
		paddingLeft = (textIsVertical) ? 0 : `${textAlignmentPaddingInPixels}px`;
		paddingRight = (textIsVertical) ? `${textAlignmentPaddingInPixels}px` : 0;
	} else if (textAlignment === TextAlignment.END) {
		alignment = (textIsVertical) ? "start" : "end";
		paddingLeft = (textIsVertical) ? `${textAlignmentPaddingInPixels}px` : 0;
		paddingRight = (textIsVertical) ? 0 : `${textAlignmentPaddingInPixels}px`;
	}

	// Build the text style
	const textStyle: React.CSSProperties = {
		display: "flex",
		justifyContent: alignment,
		alignItems: "center",
		width: textWidthInPixels,
		height: textHeightInPixels,
		lineHeight: "normal",
		fontFamily,
		fontStyle,
		fontSize: `${fontSizeInPixels}px`,
		fontWeight: fontWeight,
		marginTop: 0,
		marginLeft: 0,
		marginRight: 0,
		marginBottom: 0,
		paddingTop,
		paddingLeft,
		paddingRight,
		paddingBottom,
		borderWidth: 0,
		borderRadius: 0,
		overflow: "hidden",
		wordWrap: "normal",
		textOverflow: "ellipses",
		textAlign: "center",
		verticalAlign: "middle",
	};

	// If the text is vertical, rotate it
	if (textIsVertical) {
		if (rotateIfVertical) {
			textStyle.position = "relative";
			textStyle.left = -textWidthInPixels;
			textStyle.top = 0;
			textStyle.transform = "rotate(270deg)";
			textStyle.transformOrigin = "top right";
		} else {
			textStyle.width = textHeightInPixels;
			textStyle.height = textWidthInPixels;
		}
	}

	return textStyle;
};

export const wordwrap = (text: string) => {
	if (!text) {
		return text;
	}

	return text.replace(/(?![^\n]{1,8}$)([^\n]{1,8})\s/g, "$1<br/>");
};