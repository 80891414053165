import React, { useEffect, useState } from "react";
import { Modal, Input, Select } from "antd";
import { Illustration } from "@contracts/illustration";
import illustrationService from "@services/illustration";
import projectService from "@services/project";
import clientService from "@services/client";
import LabelledElement from "@components/molecules/LabelledElement";



interface AddEditIllustrationDialogProps {
	name: string,
	description: string,
	projectId: string,
	illustrationId: string,
	onAddOkButtonClick: ((projectId: string, name: string, description: string, templateId: string | undefined) => void),
	onEditOkButtonClick: ((illustrationId: string, name: string, description: string) => void),
	onCancelButtonClick: (() => void),
	isVisible: boolean,
	isAdding: boolean
};

const AddEditIllustrationDialog: React.FC<AddEditIllustrationDialogProps> = (props) => {

	const [name, setName] = useState(props.name);
	const [description, setDescription] = useState(props.description);

	const [templateId, setTemplateId] = useState<string>("");

	const [templateList, setTemplateList] = useState<Illustration[]>([]);

	useEffect(() => {
		if (props.isVisible) {
			setName(props.name);
			setDescription(props.description);

			setTemplateId("");

			// We need to see if the client has a template id. Do we need to fetch that?
			if (props.isAdding) {

				// We then need to get the client, and see what the default template folder is, if any.
				projectService.get(props.projectId, (project) => {
					if (project) {
						clientService.get(project.clientId, (client) => {
							if (client && client.templateProjectId) {
								illustrationService.getListByProjectId((client.templateProjectId), (results) => {
									setTemplateList(results);
								})
							}
							else {
								setTemplateList([]);
							}
						}, (error) =>{})
					}

				}, (error) => {});				

			} else {
				setTemplateList([]);
			}
		}
	}, [props.description, props.isAdding, props.isVisible, props.name, props.projectId]);


	const handleOKButtonClick = () => {
		// Notify the callback that the OK button has been clicked, passing the new
		// box name and box type key
		if (props.isAdding) {
			props.onAddOkButtonClick(props.projectId, name, description, templateId);
		}
		else {
			props.onEditOkButtonClick(props.illustrationId, name, description);
		}

	};
	return (

		<Modal
			title={props.isAdding ? 'Add Illustration' : 'Edit Illustration'}
			open={props.isVisible}
			onOk={handleOKButtonClick}
			onCancel={props.onCancelButtonClick}
			zIndex={9999}
		>

<div
					style={{
						width: "100%",
						display: "grid",
						gridTemplateColumns: "1fr",
						gap: "4px 12px",
						padding: 0,
						margin: 0,
						overflowY: "visible",
					}}>
					<LabelledElement labelText="Name">
						<Input name="name" value={name}
							onChange={(event) => {setName(event.target.value);}} />
					</LabelledElement>
					<LabelledElement labelText="Description">
						<Input.TextArea name="description" value={description}
							onChange={(event) => {setDescription(event.target.value);}} />
					</LabelledElement>

					{props.isAdding &&
						<LabelledElement labelText="Template Illustration">
							<Select
								onChange={(value) => {
									setTemplateId(value);
								}}
								value={templateId}
								style={{ width: "100%" }}
								getPopupContainer={(node) => {
									let popupContainer: HTMLElement | null =
										window.document.documentElement;
									if (node && node.parentElement) {
										popupContainer = node.parentElement;
									}
									return popupContainer as HTMLElement;
								}}
							>
								<Select.Option value="">Default Template</Select.Option>
								{templateList.map(
									(template) => {
										return <Select.Option key={`project-${template._id}`} value={template._id}>{template.name}</Select.Option>
									}
								)}
							</Select>
						</LabelledElement>}
				</div>
		</Modal>
	);

}

export default AddEditIllustrationDialog;