import * as React from "react";

import { Divider, Modal, Table } from "antd";
import * as boxTypeLib from "@lib/box/box-type";
import * as lensesLib from "@lib/lenses/lenses";
import Link from "antd/lib/typography/Link";

const confirm = Modal.confirm;

interface BoxTypeExpressionTableProps {
	onEdit: (key: string) => void;
	onDelete: (key: string) => void;
	boxTypes: boxTypeLib.BoxTypeMap;
	boxTypeExpressions: lensesLib.BoxTypeExpressionMap | null;
	canEdit: boolean;
	canDelete: boolean;
}

interface BoxTypeRow {
	key: string;
	name: string;
	value: string;
}

const BoxTypeExpressionTable : React.FC<BoxTypeExpressionTableProps> = (props) => {
	// Build the box type list items

	const handleEdit = (index: string) => {
		// Notify the callback that the box type should be edited
		props.onEdit(index);
	};

	const handleDelete = (key: string) => {
		// Notify the callback that the box type should be removed
		props.onDelete(key);
	};

	const buildRows = (
		map: lensesLib.BoxTypeExpressionMap | null
	) : BoxTypeRow[] => {
		// If we don't have a box map there's no list items
		if (!map) {
			return [];
		}

		// Render each of the box types
		const rows = Object.keys(map).map(
			(key, index) => {
				const boxTypeExpression = map[key];
				const boxType = props.boxTypes[key];
				// Get the box type
				// Build the box type list item
				const row: BoxTypeRow = {
					key: key,
					name: boxType?.name,
					value: boxTypeExpression
				};

				return row;
			}
		);

		return rows;
	};

	const queries: BoxTypeRow[] = buildRows(
		props.boxTypeExpressions
	);

	const queryTableColumns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Value",
			dataIndex: "value",
			key: "value",
		},
		{
			title: "Action",
			key: "action",
			render: (text: string, record: BoxTypeRow) => (
				<span>
					<Link onClick={() => {
						(handleEdit(record.key))
					}}>
						Edit
					</Link>
					<Divider type="vertical" />
					<Link
						onClick={() => {
							confirm({
								title:
									"Are you sure delete this Box Type Expression?",
								okText: "Yes",
								okType: "danger",
								cancelText: "No",
								zIndex: 9999999,
								onOk: () => { handleDelete(record.key) },
								onCancel: () => { },
							});
						}}
					>
						Delete
					</Link>
				</span>
			),
		},
	];

	return (
		<Table
			dataSource={queries}
			pagination={false}
			columns={queryTableColumns}
		/>
	);
}

export default BoxTypeExpressionTable;