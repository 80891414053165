import * as React from "react";

import { createPortal } from "react-dom";



const BUTTONS_PORTAL_DOM_ELEMENT_ID = "buttons-portal";

export const ButtonsPortal = () => {
	return (
		<div
			id={BUTTONS_PORTAL_DOM_ELEMENT_ID}
			style={{
				float: "left",
			}}
		/>
	);
};

export const renderButtonsInPortal = (buttons: React.ReactNode) => {
	const buttonsRootDOMElement = document.getElementById(
		BUTTONS_PORTAL_DOM_ELEMENT_ID
	);
	if (!buttonsRootDOMElement) {
		return null;
	}

	return createPortal(buttons, buttonsRootDOMElement);
};
