export interface UIViewToolVisibility {
	[key: string]: boolean;

	visibilityStateMenuVisibility: boolean;
	lensesMenuVisibility: boolean;
	simpleLensesMenuVisibility: boolean;
	lensPagesMenuVisibility: boolean;
	legendMenuVisibility: boolean;
	simpleBoxTypesMenuVisibility: boolean;
	boxTypesMenuVisibility: boolean;
	boxesMenuVisibility: boolean;
	latchModeMenuVisibility: boolean;
}

export interface UIViewSidebar {
	widthInPercent: number;
	isVisible: boolean;
	toolVisibility: UIViewToolVisibility;
}

export interface UIEditSidebar {
	widthInPercent: number;
	isVisible: boolean;
}

export interface UIIllustration {
	widthInPercent: number;
	heightInPercent: number;
	viewModeHorizontalBoxGapInPixels: number;
	viewModeVerticalBoxGapInPixels: number;
	editModeHorizontalBoxGapInPixels: number;
	editModeVerticalBoxGapInPixels: number;
}

export interface UI {
	viewSidebar: UIViewSidebar;
	editSidebar: UIEditSidebar;
	illustration: UIIllustration;
}

export interface Configuration {
	ui: UI;
}

// The configuration defaults
export const DEFAULT_HEADER_BACKGROUND_COLOR = "#B3B3B3";

export const DEFAULT_UI_VIEW_SIDEBAR_TEXT_COLOR = "#000";
export const DEFAULT_UI_VIEW_SIDEBAR_BACKGROUND_COLOR = "#FFFFFF";
export const DEFAULT_UI_VIEW_SIDEBAR_WIDTH_IN_PERCENT = 9;
export const DEFAULT_UI_VIEW_SIDEBAR_IS_VISIBLE = true;
export const DEFAULT_UI_VIEW_SIDEBAR_VISIBILITY_STATE_MENU_VISIBILITY = true;
export const DEFAULT_UI_VIEW_SIDEBAR_LENSES_MENU_VISIBILITY = true;
export const DEFAULT_UI_VIEW_SIDEBAR_SIMPLE_LENSES_MENU_VISIBILITY = true;
export const DEFAULT_UI_VIEW_SIDEBAR_BOOKMARKS_MENU_VISIBILITY = true;
export const DEFAULT_UI_VIEW_SIDEBAR_LEGEND_MENU_VISIBILITY = true;
export const DEFAULT_UI_VIEW_SIDEBAR_BOX_TYPES_MENU_VISIBILITY = true;
export const DEFAULT_UI_VIEW_SIDEBAR_SIMPLE_BOX_TYPES_MENU_VISIBILITY = true;
export const DEFAULT_UI_VIEW_SIDEBAR_BOXES_MENU_VISIBILITY = true;
export const DEFAULT_UI_VIEW_SIDEBAR_LATCH_MODE_MENU_VISIBILITY = false;

export const DEFAULT_UI_VIEW_SIDEBAR_TOOL_VISIBILITY: UIViewToolVisibility = {
	visibilityStateMenuVisibility: DEFAULT_UI_VIEW_SIDEBAR_VISIBILITY_STATE_MENU_VISIBILITY,
	lensesMenuVisibility: DEFAULT_UI_VIEW_SIDEBAR_LENSES_MENU_VISIBILITY,
	simpleLensesMenuVisibility: DEFAULT_UI_VIEW_SIDEBAR_SIMPLE_LENSES_MENU_VISIBILITY,
	lensPagesMenuVisibility: DEFAULT_UI_VIEW_SIDEBAR_BOOKMARKS_MENU_VISIBILITY,
	legendMenuVisibility: DEFAULT_UI_VIEW_SIDEBAR_LEGEND_MENU_VISIBILITY,
	boxTypesMenuVisibility: DEFAULT_UI_VIEW_SIDEBAR_BOX_TYPES_MENU_VISIBILITY,
	simpleBoxTypesMenuVisibility: DEFAULT_UI_VIEW_SIDEBAR_SIMPLE_BOX_TYPES_MENU_VISIBILITY,
	boxesMenuVisibility: DEFAULT_UI_VIEW_SIDEBAR_BOXES_MENU_VISIBILITY,
	latchModeMenuVisibility: DEFAULT_UI_VIEW_SIDEBAR_LATCH_MODE_MENU_VISIBILITY,
};

export const DEFAULT_UI_EDIT_SIDEBAR_BACKGROUND_COLOR = "#FFF";
export const DEFAULT_UI_EDIT_SIDEBAR_WIDTH_IN_PERCENT = 15;
export const DEFAULT_UI_EDIT_SIDEBAR_IS_VISIBLE = true;

// The configuration minimum and maximum values
export const MINIMUM_UI_VIEW_SIDEBAR_WIDTH_IN_PERCENT = 5;
export const MAXIMUM_UI_VIEW_SIDEBAR_WIDTH_IN_PERCENT = 40;

export const MINIMUM_UI_EDIT_SIDEBAR_WIDTH_IN_PERCENT = 5;
export const MAXIMUM_UI_EDIT_SIDEBAR_WIDTH_IN_PERCENT = 40;

export const DEFAULT_UI_ILLUSTRATION_WIDTH_IN_PERCENT = 100;
export const MINIMUM_UI_ILLUSTRATION_WIDTH_IN_PERCENT = 100;

export const DEFAULT_UI_ILLUSTRATION_HEIGHT_IN_PERCENT = 100;
export const MINIMUM_UI_ILLUSTRATION_HEIGHT_IN_PERCENT = 100;

export const DEFAULT_UI_ILLUSTRATION_VIEW_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS = 2;
export const MINIMUM_UI_ILLUSTRATION_VIEW_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS = 0;
export const MAXIMUM_UI_ILLUSTRATION_VIEW_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS = 32;

export const DEFAULT_UI_ILLUSTRATION_VIEW_MODE_VERTICAL_BOX_GAP_IN_PIXELS = 2;
export const MINIMUM_UI_ILLUSTRATION_VIEW_MODE_VERTICAL_BOX_GAP_IN_PIXELS = 0;
export const MAXIMUM_UI_ILLUSTRATION_VIEW_MODE_VERTICAL_BOX_GAP_IN_PIXELS = 32;

export const DEFAULT_UI_ILLUSTRATION_EDIT_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS = 8;
export const MINIMUM_UI_ILLUSTRATION_EDIT_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS = 0;
export const MAXIMUM_UI_ILLUSTRATION_EDIT_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS = 32;

export const DEFAULT_UI_ILLUSTRATION_EDIT_MODE_VERTICAL_BOX_GAP_IN_PIXELS = 8;
export const MINIMUM_UI_ILLUSTRATION_EDIT_MODE_VERTICAL_BOX_GAP_IN_PIXELS = 0;
export const MAXIMUM_UI_ILLUSTRATION_EDIT_MODE_VERTICAL_BOX_GAP_IN_PIXELS = 32;

// The default button shape
export const DEFAULT_BUTTON_SHAPE = undefined;

// The default button background colors
export const DEFAULT_BUTTON_EDIT_SIDEBAR_COLOR = "#FFF";
export const DEFAULT_BUTTON_EDIT_SIDEBAR_BACKGROUND_COLOR = "#8C8C8C";

export const DEFAULT_BUTTON_VIEW_SIDEBAR_COLOR = "#FFF";
export const DEFAULT_BUTTON_VIEW_SIDEBAR_BACKGROUND_COLOR = "#8C8C8C";

export const DEFAULT_BUTTON_ACCEPT_SELECTED_BOXES_COLOR = "#FFF";
export const DEFAULT_BUTTON_ACCEPT_SELECTED_BOXES_BACKGROUND_COLOR = "#32CD32";

export const DEFAULT_BUTTON_REJECT_SELECTED_BOXES_COLOR = "#FFF";
export const DEFAULT_BUTTON_REJECT_SELECTED_BOXES_BACKGROUND_COLOR = "#FFA500";

export const DEFAULT_BUTTON_DELETE_SELECTED_BOXES_COLOR = "#FFF";
export const DEFAULT_BUTTON_DELETE_SELECTED_BOXES_BACKGROUND_COLOR = "#F00";

export const DEFAULT_BUTTON_EDIT_ASSOCIATION_COLOR = "#FFF";
export const DEFAULT_BUTTON_EDIT_ASSOCIATION_BACKGROUND_COLOR = "#8C8C8C";

export const DEFAULT_BUTTON_EDIT_MULTI_BOX_COLOR = "#FFF";
export const DEFAULT_BUTTON_EDIT_MULTI_BOX_BACKGROUND_COLOR = "#8C8C8C";

export const DEFAULT_BUTTON_EDIT_EDIT_BOX_COLOR = "#FFF";
export const DEFAULT_BUTTON_EDIT_EDIT_BOX_BACKGROUND_COLOR = "#8C8C8C";

export const DEFAULT_BUTTON_EDIT_VIEW_COLOR = "#FFF";
export const DEFAULT_BUTTON_EDIT_VIEW_BACKGROUND_COLOR = "#8C8C8C";

export const DEFAULT_BUTTON_EDIT_TOGGLE_FULLSCREEN_COLOR = "#FFF";
export const DEFAULT_BUTTON_EDIT_TOGGLE_FULLSCREEN_BACKGROUND_COLOR = "#8C8C8C";

export const DEFAULT_BUTTON_SAVE_COLOR = "#FFF";
export const DEFAULT_BUTTON_SAVE_BACKGROUND_COLOR = "#8C8C8C";

export const createDefaultConfiguration = (): Configuration => ({
	ui: {
		viewSidebar: {
			widthInPercent: DEFAULT_UI_VIEW_SIDEBAR_WIDTH_IN_PERCENT,
			isVisible: DEFAULT_UI_VIEW_SIDEBAR_IS_VISIBLE,
			toolVisibility: DEFAULT_UI_VIEW_SIDEBAR_TOOL_VISIBILITY,
		},
		editSidebar: {
			widthInPercent: DEFAULT_UI_EDIT_SIDEBAR_WIDTH_IN_PERCENT,
			isVisible: DEFAULT_UI_EDIT_SIDEBAR_IS_VISIBLE,
		},
		illustration: {
			widthInPercent: DEFAULT_UI_ILLUSTRATION_WIDTH_IN_PERCENT,
			heightInPercent: DEFAULT_UI_ILLUSTRATION_HEIGHT_IN_PERCENT,
			viewModeHorizontalBoxGapInPixels: DEFAULT_UI_ILLUSTRATION_VIEW_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS,
			viewModeVerticalBoxGapInPixels: DEFAULT_UI_ILLUSTRATION_VIEW_MODE_VERTICAL_BOX_GAP_IN_PIXELS,
			editModeHorizontalBoxGapInPixels: DEFAULT_UI_ILLUSTRATION_EDIT_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS,
			editModeVerticalBoxGapInPixels: DEFAULT_UI_ILLUSTRATION_EDIT_MODE_VERTICAL_BOX_GAP_IN_PIXELS
		},
	},
});
