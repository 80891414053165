/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { Table, Divider, Tag } from 'antd';
export interface BrowseLibraryPageProps {

}
const columns = [{
  title: 'Name',
  dataIndex: 'name',
  key: 'name',
  render: (text: any) => <a href="javascript:;">{text}</a>,
}, {
  title: 'Age',
  dataIndex: 'age',
  key: 'age',
}, {
  title: 'Address',
  dataIndex: 'address',
  key: 'address',
}, {
  title: 'Tags',
  key: 'tags',
  dataIndex: 'tags',
  render: (tags: any) => (
    <span>
      {tags.map((tag: any) => <Tag color="blue" key={tag}>{tag}</Tag>)}
    </span>
  ),
}, {
  title: 'Action',
  key: 'action',
  render: (text: any, record: any) => (
    <span>
      <a href="javascript:;">Invite {record.name}</a>
      <Divider type="vertical" />
      <a href="javascript:;">Delete</a>
    </span>
  ),
}];

const data = [{
  key: '1',
  name: 'John Brown',
  age: 32,
  address: 'New York No. 1 Lake Park',
  tags: ['nice', 'developer'],
}, {
  key: '2',
  name: 'Jim Green',
  age: 42,
  address: 'London No. 1 Lake Park',
  tags: ['loser'],
}, {
  key: '3',
  name: 'Joe Black',
  age: 32,
  address: 'Sidney No. 1 Lake Park',
  tags: ['cool', 'teacher'],
}];
export class BrowseLibraryPage extends React.Component<BrowseLibraryPageProps> {
  public render() {
    return (
      <div style={{ padding: "16px", backgroundColor: "#FFFFFF" }}>
        <h1>Library</h1>
        <Table columns={columns} pagination={false} dataSource={data} />
      </div>
    );
  }
}