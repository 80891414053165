import { PlusOutlined } from "@ant-design/icons";
import BoxExpressionTable from "@components/molecules/BoxExpressionTable";
import LabelledElement from "@components/molecules/LabelledElement";
import FormHeading from "@components/organisms/FormHeading";
import FormHeadingText from "@components/organisms/FormHeadingText";
import { Button, Select, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React from "react";
import AddEditBoxExpressionDialog, { BoxExpression } from "../AddEditBoxExpressionDialog";
import useBoxQueryRestrictions from "../hooks/useBoxQueryRestrictions";
import * as lensesLib from "@lib/lenses/lenses";
import * as boxLib from "@lib/box/box";

interface BoxQueryRestrictionsProps {
	flattenedBoxes: boxLib.BoxMap | undefined,
	boxExpressions: lensesLib.BoxExpressionMap | undefined,
	applyBoxVisibility: boolean,
	boxIsInLayout: boolean,
	boxIsVisible: boolean,
	boxExpressionOperator: lensesLib.BooleanOperator,

	isAddEditBoxExpressionDialogVisible: boolean,
	isAddEditBoxExpressionDialogAdding: boolean,
	addEditBoxExpression: BoxExpression | null,

	onApplyBoxVisibilityChanged: (value: boolean) => void,
	onBoxIsInLayoutChanged: (value: boolean) => void,
	onBoxIsVisibleChanged: (value: boolean) => void,
	onBoxExpressionOperatorChanged: (value: lensesLib.BooleanOperator) => void,

	onDeleteBoxExpression: (key: string) => void,
	onAddEditBoxExpressionFormOk: (boxExpression: BoxExpression) => void,
	onCloseAddEditBoxExpressionForm: () => void,
	onOpenEditBoxExpressionForm: (key: string) => void,
	onOpenAddBoxExpressionForm: () => void
}

// ## Box Expressions: A list, where you can pick the operator
// - boxExpressions
// - applyBoxVisibility
// - boxIsVisible
// - boxIsInLayout

const BoxQueryRestrictions: React.FC<BoxQueryRestrictionsProps> = ({ 
	flattenedBoxes,
	boxExpressions,
	applyBoxVisibility,
	boxIsInLayout,
	boxIsVisible,
	boxExpressionOperator,

	isAddEditBoxExpressionDialogVisible,
	isAddEditBoxExpressionDialogAdding,
	addEditBoxExpression,

	onApplyBoxVisibilityChanged,
	onBoxIsInLayoutChanged,
	onBoxIsVisibleChanged,
	onBoxExpressionOperatorChanged,

	onDeleteBoxExpression,
	onAddEditBoxExpressionFormOk,
	onCloseAddEditBoxExpressionForm,
	onOpenEditBoxExpressionForm,
	onOpenAddBoxExpressionForm

}) => {



	return <>
		<FormHeading>
			<FormHeadingText>Box Expressions</FormHeadingText>
			<div>
				<Button
					icon={<PlusOutlined />}
					type="primary"
					style={{
						paddingRight: "12px",
						marginRight: "12px",
					}}
					onClick={onOpenAddBoxExpressionForm}
				>
					Add
				</Button>
			</div>
		</FormHeading>

		{flattenedBoxes !== undefined &&
			<AddEditBoxExpressionDialog
				onCancelButtonClick={onCloseAddEditBoxExpressionForm}
				isAdding={isAddEditBoxExpressionDialogAdding}
				isVisible={isAddEditBoxExpressionDialogVisible}
				boxes={flattenedBoxes}
				onOKButtonClick={onAddEditBoxExpressionFormOk}

				boxExpression={addEditBoxExpression}
			/>}
		{flattenedBoxes !== undefined &&
			<BoxExpressionTable
				onDelete={onDeleteBoxExpression}
				onEdit={onOpenEditBoxExpressionForm}
				boxExpressions={boxExpressions}
				boxes={flattenedBoxes}
				canEdit={true} canDelete={true} />
		}
		<div
			style={{
				width: "100%",
				display: "grid",
				gridTemplateColumns: "repeat(auto-fill, 6)",
				gap: "4px 12px",
				padding: 0,
				margin: 0,
				overflowY: "visible",
			}}
		>
			<LabelledElement labelText="Box Expression Operator">
				<Select
					key={`input-box-expression-operator`}
					size="large"
					placeholder="Please select"
					value={boxExpressionOperator}
					onChange={onBoxExpressionOperatorChanged}
					style={{ width: "100%" }}
					getPopupContainer={(node) => {
						let popupContainer: HTMLElement | null =
							window.document.documentElement;
						if (node && node.parentElement) {
							popupContainer = node.parentElement;
						}
						return popupContainer as HTMLElement;
					}}
				>
					<Select.Option key="and">and</Select.Option>
					<Select.Option key="or">or</Select.Option>
				</Select>
			</LabelledElement>

			<div>
				<LabelledElement labelText="Enable Box Visibility Controls for Matched Boxes">
					<Checkbox
						checked={applyBoxVisibility}
						onChange={(event: CheckboxChangeEvent) => { onApplyBoxVisibilityChanged(event.target.checked); }
						}
					/>
				</LabelledElement>
			</div>

			{ applyBoxVisibility && <div>
				<LabelledElement labelText="Should Matched Boxes take up space in the layout if they are hidden?">
					<Checkbox
						checked={boxIsInLayout}
						onChange={(event: CheckboxChangeEvent) => { onBoxIsInLayoutChanged(event.target.checked); }
						}
					/>
				</LabelledElement>
			</div> }

			{ applyBoxVisibility && <div>
				<LabelledElement labelText="Should Matched Boxes be Visible?">
					<Checkbox
						checked={boxIsVisible}
						onChange={(event: CheckboxChangeEvent) => { onBoxIsVisibleChanged(event.target.checked); }
						}
					/>
				</LabelledElement>
			</div> }
		</div>
	</>;
}

export default BoxQueryRestrictions;