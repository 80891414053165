import React from "react";

export interface LabelledElementProps {
	labelText: string
}

const LabelledElement: React.FC<LabelledElementProps> = (props) => {
	return <>
		<b
			style={{
				textAlign: "left",
				paddingTop: "4px",
				paddingRight: "12px",
			}}
		>
			{props.labelText}:
		</b>
		{props.children}
	</>
};

export default LabelledElement;
