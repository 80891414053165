import React, { ReactElement } from "react";
import * as renderFunctionLib from "@lib/box/render-function";
import { Select, Input, InputNumber } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import JsonEditor from "@components/molecules/JsonEditor";
import { BoxStyleMap } from '@lib/box/box-style';

export interface RenderFunctionInputsEditorProps {
	inputs: any;
	type: string;
	isValid: boolean;
	onChange: (value: string) => void;
	boxStyles: BoxStyleMap | undefined;
};

interface InputControlMappingType {
	[key: string]: (value: string, isValid: boolean, onChange: (value: string) => void, boxStyles: BoxStyleMap | undefined) => ReactElement<any, any> | null
}

const inputControlMapping: InputControlMappingType = {
	[renderFunctionLib.RenderFunctionInputsType.STRING]: (value: string, isValid: boolean, onChange: (value: string) => void, boxStyles: BoxStyleMap | undefined) => {
		return (
			<Input
				type="text"
				value={value}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
					onChange(
						event.target.value
					)
				}
			/>);
	},
	[renderFunctionLib.RenderFunctionInputsType.COLOR]: (value: string, isValid: boolean, onChange: (value: string) => void, boxStyles: BoxStyleMap | undefined) => {
		return <Input
			type="color"
			value={value}
			onChange={
				(event: React.ChangeEvent<HTMLInputElement>) => {
					onChange(event.target.value);
				}
			}
		/>
	},
	[renderFunctionLib.RenderFunctionInputsType.NUMBER]: (value: string, isValid: boolean, onChange: (value: string) => void, boxStyles: BoxStyleMap | undefined) => {
		return <InputNumber
			min={0}
			max={+Infinity}
			value={Number(value)}
			onChange={
				(value: number | null) => {
					if (value !== null) onChange(String(value));
				}
			}
		/>
	},
	[renderFunctionLib.RenderFunctionInputsType.BOOLEAN]: (value: string, isValid: boolean, onChange: (value: string) => void, boxStyles: BoxStyleMap | undefined) => {
		return <Checkbox
			checked={Boolean(value)}
			onChange={
				(event: CheckboxChangeEvent) => {
					onChange(String(event.target.checked));
				}
			}
		/>
	},
	[renderFunctionLib.RenderFunctionInputsType.OBJECT]: (value: string, isValid: boolean, onChange: (value: string) => void, boxStyles: BoxStyleMap | undefined) => {
		const style = isValid ? {}
			: {
				color: "white",
				backgroundColor: "red",
			};

		// Render the inputs value as a text area
		return (
			<JsonEditor
				code={value}
				style={style}
				onChange={(code: string) =>
					onChange(
						code
					)
				}
			/>
		)
	},
	[renderFunctionLib.RenderFunctionInputsType.STYLE]: (value: string, isValid: boolean, onChange: (value: string) => void, boxStyles: BoxStyleMap | undefined) => {
		// The box style options
		let boxStyleOptions = null;

		// Do we have box styles?
		if (boxStyles) {
			// Build the box style options
			boxStyleOptions = Object.keys(boxStyles)
				.sort((boxStyleKeyA: string, boxStyleKeyB: string) => {
					const boxStyleA = boxStyles[boxStyleKeyA];
					const boxStyleB = boxStyles[boxStyleKeyB];

					const hasOrderAttribute = boxStyleA.hasOwnProperty('order') &&
						boxStyleB.hasOwnProperty('order');

					// Get the names of the box styles
					const boxStyleNameA = hasOrderAttribute && boxStyleA.order !== undefined ? boxStyleA.order : boxStyleA.name;
					const boxStyleNameB = hasOrderAttribute && boxStyleB.order !== undefined ? boxStyleB.order : boxStyleB.name;

					// If the order of box style A is before that of box style B, put box
					// style A first
					if (boxStyleNameA < boxStyleNameB) {
						return -1;
					}

					// If the order of box style A is after that of box style B, put box
					// style B first
					if (boxStyleNameA > boxStyleNameB) {
						return 1;
					}

					// Otherwise, don't change the order
					return 0;
				})
				.map((boxStyleKey) => {
					// Get the box style
					const optionBoxStyle = boxStyles[boxStyleKey];

					return (
						<Select.Option key={boxStyleKey} value={boxStyleKey}>
							{optionBoxStyle.name}
						</Select.Option>
					);
				});
		}



		return (<Select
			key="input-styles"
			mode="multiple"
			size="large"
			allowClear
			placeholder="Please select"
			value={
				value !== null ? value.split(',').filter((style: string) => {
					return boxStyles !== undefined && Object.keys(boxStyles).indexOf(style) > -1;
				}) : []
			}
			onChange={(value) => onChange(value.join(','))}
			style={{ width: "100%" }}
			filterOption={(inputValue, option) => {
				const optionStyle = option && boxStyles && option.value !== undefined && option.value !== null ? boxStyles[option.value] : null;

				// Note: some of the names are numbers!
				// Convert it to a string just to be sure
				const optionBoxNameAsString = optionStyle != null &&
					optionStyle.name !== undefined &&
					optionStyle.name !== null ? String(optionStyle.name) : "";

				return optionBoxNameAsString.toLowerCase().includes(inputValue.toLowerCase());
			}}
			getPopupContainer={(node) => {
				let popupContainer: HTMLElement | null =
					window.document.documentElement;
				if (node && node.parentElement) {
					popupContainer = node.parentElement;
				}
				return popupContainer as HTMLElement;
			}}
		>
			{boxStyleOptions}
		</Select>)
	}
}

const RenderFunctionInputsEditor: React.FC<RenderFunctionInputsEditorProps> = (props) => {

	// Get the inputs type of the render function
	const renderFunctionInputsType = renderFunctionLib.TYPES[props.type].inputsType;

	var value = props.inputs;

	return inputControlMapping[renderFunctionInputsType](value, props.isValid, props.onChange, props.boxStyles);
};

export default RenderFunctionInputsEditor;
