import React, { useEffect, useState } from "react";

import { Modal, Input, Button, InputNumber, Tree } from "antd";

import FormHeading from "@components/organisms/FormHeading";
import FormHeadingText from "@components/organisms/FormHeadingText";
import LabelledElement from "@components/molecules/LabelledElement";
import * as lensesLib from "@lib/lenses/lenses";
// import AddEditPowerLensDialog from "./add-edit-simple-lens-dialog/AddEditPowerLensDialog";
import { v4 as uuid } from "uuid";
import TextArea from "antd/lib/input/TextArea";
import { DataNode } from "antd/lib/tree";
import { Key } from "react";


export interface AddEditPowerLensGroupDialogProps {
	onOKButtonClick: (lensGroup: lensesLib.LensGroup) => void;
	onCancelButtonClick: () => void;
	isAdding: boolean;
	isVisible: boolean;
	powerLensGroup: lensesLib.LensGroup;
	lenses: lensesLib.LensMap |  undefined;
}

const AddEditPowerLensGroupDialog: React.FC<AddEditPowerLensGroupDialogProps> = (props) => {
	// Get the title
	const title = props.isAdding ? "Add Power Lens Group" : "Edit Power Lens Group";

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [order, setOrder] = useState(0);
	const [checkedLensKeys, setCheckedLensKeys] = useState<Key[]>([])


	const lensKeyTreeData: DataNode[] = props.lenses !== undefined ? Object.keys(props.lenses).map((lensKey) => {
		
		const lensName = props.lenses !==  undefined ? props.lenses[lensKey].name : "";

		return {
			key: lensKey, 
			title: lensName
		};
	}) : [];

	// Add the useEffect to update stuff from props
	// Base it on the object and the key coming in.

	useEffect(() => {
		setName(props.powerLensGroup.name);
		setDescription(props.powerLensGroup.description !== undefined ? props.powerLensGroup.description : "");
		setOrder(props.powerLensGroup.order);
		setCheckedLensKeys(props.powerLensGroup.lensKeys);
	}, [props.powerLensGroup])


	// Configure the state
	const handleOKButtonClick = () => {
		const lensGroup: lensesLib.LensGroup = {
			name, description, order, lensKeys: checkedLensKeys.map((val) => val.toString())
		};
		props.onOKButtonClick(lensGroup);
	};

	return <Modal
		title={title}
		open={props.isVisible}
		onOk={handleOKButtonClick}
		onCancel={props.onCancelButtonClick}
		zIndex={9999}
		width="75%"
	>
		<div>
			<FormHeading>
				<FormHeadingText>Power Lens Group Details</FormHeadingText>
			</FormHeading>
			<div
				style={{
					width: "100%",
					display: "grid",
					gridTemplateColumns: "0.1fr 0.9fr",
					gap: "4px 12px",
					padding: 0,
					margin: 0,
					overflowY: "visible",
				}}
			>
				<LabelledElement labelText="Name">
					<Input

						value={name}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>
						) => { setName(event.target.value) }}
					/>
				</LabelledElement>
				<LabelledElement labelText="Description">
					<TextArea
						value={description}
						onChange={(
							event: React.ChangeEvent<HTMLTextAreaElement>
						) => { setDescription(event.target.value) }}
					/>
				</LabelledElement>
				<LabelledElement labelText="Order">
					<InputNumber
						value={order}
						onChange={(value: number | null) => { if (value !== null) { setOrder(value); } }}
					/>
				</LabelledElement>
			</div>
			<FormHeading>
				<FormHeadingText>Lenses</FormHeadingText>
			</FormHeading>

			<Tree
				checkable
				checkStrictly
				treeData={lensKeyTreeData}
				onCheck={(checkedKeysValue) => {
					var checkedKeys = [];

					if (Array.isArray(checkedKeysValue)) {
						checkedKeys = checkedKeysValue;
					}
					else {
						checkedKeys = checkedKeysValue.checked;
					}
					
					setCheckedLensKeys(checkedKeys);
				}}

				checkedKeys={checkedLensKeys}
				// onSelect={this.onSelect}
				// selectedKeys={this.state.selectedKeys}
			/>
		</div>
	</Modal>
}

export default AddEditPowerLensGroupDialog;