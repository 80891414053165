import { PlusOutlined } from "@ant-design/icons";
import AttributeTypeExpressionTable from "@components/molecules/AttributeTypeExpressionTable";
import LabelledElement from "@components/molecules/LabelledElement";
import FormHeading from "@components/organisms/FormHeading";
import FormHeadingText from "@components/organisms/FormHeadingText";
import { Button, Select } from "antd";
import React from "react";
import useAttributeTypeQueryRestrictions from "../hooks/useAttributeTypeQueryRestrictions";
import * as lensesLib from "@lib/lenses/lenses";
import AddEditAttributeTypeExpressionDialog, { AttributeTypeExpression } from "../AddEditAttributeTypeExpressionDialog";
import { AttributeTypeMap } from "@lib/box/attribute-type";
interface AttributeTypeQueryRestrictionsProps {
	attributeTypes: AttributeTypeMap,
	attributeTypeExpressions: lensesLib.AttributeTypeExpressionMap | undefined,
	attributeTypeExpressionOperator: lensesLib.BooleanOperator,

	isAddEditAttributeTypeExpressionDialogVisible: boolean,
	isAddEditAttributeTypeExpressionDialogAdding: boolean,
	addEditAttributeTypeExpression: AttributeTypeExpression | null,

	onAttributeTypeExpressionOperatorChanged: (value: lensesLib.BooleanOperator) => void,
	onDeleteAttributeTypeExpression: (key: string) => void,
	onAddEditAttributeTypeExpressionFormOk: (attributeTypeExpression: AttributeTypeExpression) => void,
	onCloseAddEditAttributeTypeExpressionForm: () => void,
	onOpenEditAttributeTypeExpressionForm: (key: string) => void,
	onOpenAddAttributeTypeExpressionForm: () => void
}

// ## Attribute Type Expressions - List, where you can pick the operator
// - attributeTypeExpressions - list
// - attributeTypeExpressionOperator - dropdown


const AttributeTypeQueryRestrictions: React.FC<AttributeTypeQueryRestrictionsProps> = ({ 
	attributeTypes,
	attributeTypeExpressions,
	attributeTypeExpressionOperator,

	isAddEditAttributeTypeExpressionDialogVisible,
	isAddEditAttributeTypeExpressionDialogAdding,
	addEditAttributeTypeExpression,

	onAttributeTypeExpressionOperatorChanged,
	onDeleteAttributeTypeExpression,
	onAddEditAttributeTypeExpressionFormOk,
	onCloseAddEditAttributeTypeExpressionForm,
	onOpenEditAttributeTypeExpressionForm,
	onOpenAddAttributeTypeExpressionForm }) => {

	return <>
		<AddEditAttributeTypeExpressionDialog
			onCancelButtonClick={onCloseAddEditAttributeTypeExpressionForm}
			isAdding={isAddEditAttributeTypeExpressionDialogAdding}
			isVisible={isAddEditAttributeTypeExpressionDialogVisible}

			attributeTypes={attributeTypes}
			onOKButtonClick={onAddEditAttributeTypeExpressionFormOk}

			attributeTypeExpression={addEditAttributeTypeExpression}
		/>
		<FormHeading>
			<FormHeadingText>Attribute Type Expressions</FormHeadingText>
			<div>
				<Button
					icon={<PlusOutlined />}
					type="primary"
					style={{
						paddingRight: "12px",
						marginRight: "12px",
					}}
					onClick={onOpenAddAttributeTypeExpressionForm}
				>
					Add
				</Button>
			</div>
		</FormHeading>
		<AttributeTypeExpressionTable
			attributeTypeExpressions={attributeTypeExpressions !== undefined ? attributeTypeExpressions : {}}
			attributeTypes={attributeTypes}
			canDelete={true}
			canEdit={true}
			onDelete={onDeleteAttributeTypeExpression}
			onEdit={onOpenEditAttributeTypeExpressionForm}
		/>
		<div
			style={{
				width: "100%",
				display: "grid",
				gridTemplateColumns: "repeat(auto-fit, 1fr)",
				gap: "4px 12px",
				padding: 0,
				margin: 0,
				overflowY: "visible",
			}}
		>
			<div>
				<LabelledElement labelText="Logical Operator To Apply to Attribute Type Expressions">
					<Select
						key={`input-attribute-expression-operator`}
						size="large"
						placeholder="Please select"
						value={attributeTypeExpressionOperator}
						onChange={onAttributeTypeExpressionOperatorChanged}
						getPopupContainer={(node) => {
							let popupContainer: HTMLElement | null =
								window.document.documentElement;
							if (node && node.parentElement) {
								popupContainer = node.parentElement;
							}
							return popupContainer as HTMLElement;
						}}
					>
						<Select.Option key="and">and</Select.Option>
						<Select.Option key="or">or</Select.Option>
					</Select>
				</LabelledElement>
			</div>
		</div>

	</>;
}

export default AttributeTypeQueryRestrictions;