import React from "react";
import { RouteProps, Navigate } from "react-router";
import { useLocation } from "react-router-dom"

import queryString from 'query-string';

type UnauthenticatedRouteProps = RouteProps  & {
  isAuthenticated: boolean;
  children?: any;
}

export const UnauthenticatedRoute = (props: UnauthenticatedRouteProps) => {
	const { isAuthenticated, children } = props

	const location = useLocation();

	if (children && location) {
		const queryStringValues = queryString.parse(location.search);

		// Grab the redirect string if we have it
		const redirect = queryStringValues.redirect !== undefined &&
		queryStringValues.redirect !== null &&
		queryStringValues.redirect !== ""
		? queryStringValues.redirect.toString() : "/";

		// If we're authenticated then we want to redirect the page that's
		// mentioned in the "redirect" param
		if (isAuthenticated) {
			return <Navigate to={redirect} />
		}

		// Otherwise we want to show the unauthenticated component
		return children;
	}

	return null;
}
