/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";

import { List } from "antd";

import * as visibilityStateLib from "@lib/box/visibility-state";

interface VisibilityStatesListProps {
	onVisibilityStateEdit: (visibilityStateKey: string) => void;
	onVisibilityStateDelete: (visibilityStateKey: string) => void;
	visibilityStateMap: visibilityStateLib.VisibilityStateMap | undefined;
	canEdit: boolean;
	canDelete: boolean;
	currentVisibilityStateKey: string;
}

interface VisibilityStatesListItem {
	visibilityStateKey: string;
	name: string;
}

export class VisibilityStatesList extends React.Component<
	VisibilityStatesListProps
> {
	public render() {
		// Build the box type list items
		const visibilityStateListItems: VisibilityStatesListItem[] = this.buildVisibilityStatesListItems(
			this.props.visibilityStateMap
		);

		return (
			<div style={{}}>
				<List
					itemLayout="horizontal"
					dataSource={visibilityStateListItems}
					renderItem={(item: VisibilityStatesListItem) => {
						// Build the actions
						const actions: React.ReactNode[] = [];
						if (this.props.canEdit) {
							actions.push(
								<a
									onClick={() =>
										this.handleVisibilityStateEdit(
											item.visibilityStateKey
										)
									}
								>
									Edit
								</a>
							);
						}
						if (this.props.canDelete && item.visibilityStateKey !== this.props.currentVisibilityStateKey) {
							actions.push(
								<a
									onClick={() =>
										this.handleVisibilityStateDelete(
											item.visibilityStateKey
										)
									}
								>
									Delete
								</a>
							);
						}

						return (
							<List.Item actions={actions}>
								<List.Item.Meta title={item.name} />
							</List.Item>
						);
					}}
				/>
			</div>
		);
	}

	private handleVisibilityStateEdit = (visibilityStateKey: string) => {
		// Notify the callback that the box type should be edited
		this.props.onVisibilityStateEdit(visibilityStateKey);
	};

	private handleVisibilityStateDelete = (visibilityStateKey: string) => {
		// Notify the callback that the box type should be removed
		this.props.onVisibilityStateDelete(visibilityStateKey);
	};

	private buildVisibilityStatesListItems = (
		visibilityStateMap: visibilityStateLib.VisibilityStateMap | undefined
	) => {
		// If we don't have a box map there's no list items
		if (!visibilityStateMap) {
			return [];
		}

		// Render each of the box types
		const renderedVisibilityStates = Object.keys(visibilityStateMap).map(
			(visibilityStateKey) => {
				// Get the box type
				const visibilityState = visibilityStateMap[visibilityStateKey];

				// Build the box type list item
				const visibilityStateListItem: VisibilityStatesListItem = {
					visibilityStateKey,
					name: visibilityState.name,
				};

				return visibilityStateListItem;
			}
		);

		return renderedVisibilityStates;
	};
}
