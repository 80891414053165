import * as CSS from "csstype";

import { TextSizing, TextAlignment } from "@lib/style/text";
import {
	BadgeProperties,
	PROPERTIES_DEFAULT_BADGE_ATTRIBUTE_TYPE,
	PROPERTIES_DEFAULT_BADGE_BACKGROUND_COLOR,
	PROPERTIES_DEFAULT_BADGE_TEXT,
	PROPERTIES_DEFAULT_BADGE_TEXT_COLOR,
	PROPERTIES_DEFAULT_BADGE_FONT_FAMILY,
	PROPERTIES_DEFAULT_BADGE
} from "../badge/badge";

export type PropertyValue = string;
export type PropertyMap = { [key: string]: PropertyValue | undefined };

export enum ChildLayout {
	HORIZONTAL = "horizontal",
	VERTICAL = "vertical",
	GRID = "grid",
}

export interface Properties {
	[key: string]: any;

	// The child layout
	childLayout: ChildLayout;
	// The text
	text: string;
	// The text color
	textColor: string;
	// The text size
	textSizeInPixels: number;
	// The text sizing method
	textSizing: TextSizing;
	// How the text is aligned
	textAlignment: TextAlignment;
	// The padding between the aligned text and the edge of the box
	textAlignmentPaddingInPixels: number;
	// Whether the text is vertical
	textIsVertical: boolean;
	// The font properties
	fontFamily: CSS.Property.FontFamily;
	fontStyle: CSS.Property.FontStyle;
	fontWeight: CSS.Property.FontWeight;
	// The background color
	backgroundColor: string;
	// The background image
	backgroundImage: string;
	// The background image position and size, and whether it repeats
	backgroundImagePosition: string;
	backgroundImageSize: string;
	backgroundImageRepeat: string;
	// The border color
	borderColor: string;
	// The border radius
	borderRadius: number;
	// The border size (in pixels)
	borderSizeInPixels: number;
	// The border style
	borderStyle: string;

	// The attribute to display in the badge and the badge background and text
	// colors
	badgeAttributeType: string;
	badgeBackgroundColor: string;
	badgeTextColor: string;
	badgeText: string;

	// The badges
	badge1: BadgeProperties | undefined;
	badge2: BadgeProperties | undefined;
	badge3: BadgeProperties | undefined;
	badge4: BadgeProperties | undefined;
	badge5: BadgeProperties | undefined;
	badge6: BadgeProperties | undefined;
	badge7: BadgeProperties | undefined;
	badge8: BadgeProperties | undefined;
	badge9: BadgeProperties | undefined;
	badge10: BadgeProperties | undefined;
	badge11: BadgeProperties | undefined;
	badge12: BadgeProperties | undefined;
	badge13: BadgeProperties | undefined;
	badge14: BadgeProperties | undefined;
	badge15: BadgeProperties | undefined;
	badge16: BadgeProperties | undefined;
	badge17: BadgeProperties | undefined;
	badge18: BadgeProperties | undefined;
	badge19: BadgeProperties | undefined;
	badge20: BadgeProperties | undefined;
	badge21: BadgeProperties | undefined;
	badge22: BadgeProperties | undefined;
	badge23: BadgeProperties | undefined;
	badge24: BadgeProperties | undefined;
	badge25: BadgeProperties | undefined;
	badge26: BadgeProperties | undefined;
	badge27: BadgeProperties | undefined;
	badge28: BadgeProperties | undefined;
	badge29: BadgeProperties | undefined;
	badge30: BadgeProperties | undefined;
	badge31: BadgeProperties | undefined;
	badge32: BadgeProperties | undefined;

	// The layout weight of the box, used to determine the size of the box in the
	// layout. Set to:
	//  'default' or empty to use the default layout weight
	//  'children' to calculate the weight based on the weights of the boxes
	// children
	//   <number> to manually specify a weight
	layoutWeight: string;

	// The styles, a comma separated string listing the UUIDs of the box
	// styles to apply
	styles: string;

	// The maximum number of columns in a grid layout
	maximumGridColumns: string | undefined;
}

export const PROPERTIES_DEFAULT_CHILD_LAYOUT = ChildLayout.VERTICAL;

export const PROPERTIES_DEFAULT_TEXT: string = "";
export const PROPERTIES_DEFAULT_TEXT_COLOR: string = "#000";
export const PROPERTIES_DEFAULT_TEXT_SIZE: number = 16;
export const PROPERTIES_DEFAULT_TEXT_SIZING: TextSizing = TextSizing.MANUAL;
export const PROPERTIES_DEFAULT_TEXT_ALIGNMENT: TextAlignment = TextAlignment.CENTER;
export const PROPERTIES_DEFAULT_TEXT_PADDING_IN_PIXELS = 8;
export const PROPERTIES_DEFAULT_TEXT_IS_VERTICAL: boolean = false;

export const PROPERTIES_DEFAULT_FONT_FAMILY: CSS.Property.FontFamily = "Arial" as CSS.Property.FontFamily;
export const PROPERTIES_DEFAULT_FONT_STYLE: CSS.Property.FontStyle = "normal" as CSS.Property.FontStyle;
export const PROPERTIES_DEFAULT_FONT_WEIGHT: CSS.Property.FontWeight = "normal" as CSS.Property.FontWeight;

export const PROPERTIES_DEFAULT_BACKGROUND_COLOR: string = "#FFFFFF";

export const PROPERTIES_DEFAULT_BACKGROUND_IMAGE: string = ""; // See https://developer.mozilla.org/en-US/docs/Web/CSS/background-image
export const PROPERTIES_DEFAULT_BACKGROUND_IMAGE_POSITION: string = "top left"; // See https://developer.mozilla.org/en-US/docs/Web/CSS/background-position
export const PROPERTIES_DEFAULT_BACKGROUND_IMAGE_SIZE: string = "contain"; // See https://developer.mozilla.org/en-US/docs/Web/CSS/background-size
export const PROPERTIES_DEFAULT_BACKGROUND_IMAGE_REPEAT: string = "no-repeat"; // repeat|repeat-x|repeat-y|no-repeat

export const PROPERTIES_DEFAULT_BORDER_COLOR: string = "#D0ECFF";
export const PROPERTIES_DEFAULT_BORDER_RADIUS: number = 8;
export const PROPERTIES_DEFAULT_BORDER_SIZE_IN_PIXELS: number = 1;
export const PROPERTIES_DEFAULT_BORDER_STYLE: string = "solid";

export const PROPERTIES_DEFAULT_LAYOUT_WEIGHT: string = "default";

export const PROPERTIES_DEFAULT_STYLES: string = "";

export const PROPERTIES_DEFAULT_MAXIMUM_GRID_COLUMNS: string = "1";


export const getPropertyMapFromProperties = (
	boxProperties: Properties
): PropertyMap => ({
	// The child layout
	childLayout: String(boxProperties.childLayout),

	// The text
	text: boxProperties.text,

	// The text color
	textColor: boxProperties.textColor,

	// The text size
	textSizeInPixels: String(boxProperties.textSizeInPixels),

	// How the text is sized
	textSizing: String(boxProperties.textSizing),

	// How the text is aligned
	textAlignment: String(boxProperties.textAlignment),

	// The padding between the aligned text and the edge of the box
	textAlignmentPaddingInPixels: String(boxProperties.textAlignmentPaddingInPixels),

	// Whether the text is vertical
	textIsVertical: String(boxProperties.textIsVertical),

	// The font properties
	fontFamily: String(boxProperties.fontFamily),
	fontStyle: String(boxProperties.fontStyle),
	fontWeight: String(boxProperties.fontWeight),

	// The background color
	backgroundColor: boxProperties.backgroundColor,

	// The background image
	backgroundImage: boxProperties.backgroundImage,

	// The background image position and size, and whether it repeats
	backgroundImagePosition: boxProperties.backgroundImagePosition,
	backgroundImageSize: boxProperties.backgroundImageSize,
	backgroundImageRepeat: boxProperties.backgroundImageRepeat,

	// The border color
	borderColor: boxProperties.borderColor,

	// The border radius
	borderRadius: String(boxProperties.borderRadius),

	// The border size (in pixels)
	borderSizeInPixels: String(boxProperties.borderSizeInPixels),

	// The border style
	borderStyle: boxProperties.borderStyle,

	// The attribute to display in the badge and the badge background and text
	// colors
	badgeAttributeType: boxProperties.badgeAttributeType,
	badgeBackgroundColor: boxProperties.badgeBackgroundColor,
	badgeTextColor: boxProperties.badgeTextColor,
	badgeText: boxProperties.badgeText,

	// The badges
	badge1: boxProperties.badge1 ? JSON.stringify(boxProperties.badge1) : "",
	badge2: boxProperties.badge2 ? JSON.stringify(boxProperties.badge2) : "",
	badge3: boxProperties.badge3 ? JSON.stringify(boxProperties.badge3) : "",
	badge4: boxProperties.badge4 ? JSON.stringify(boxProperties.badge4) : "",
	badge5: boxProperties.badge5 ? JSON.stringify(boxProperties.badge5) : "",
	badge6: boxProperties.badge6 ? JSON.stringify(boxProperties.badge6) : "",
	badge7: boxProperties.badge7 ? JSON.stringify(boxProperties.badge7) : "",
	badge8: boxProperties.badge8 ? JSON.stringify(boxProperties.badge8) : "",
	badge9: boxProperties.badge9 ? JSON.stringify(boxProperties.badge9) : "",
	badge10: boxProperties.badge10 ? JSON.stringify(boxProperties.badge10) : "",
	badge11: boxProperties.badge11 ? JSON.stringify(boxProperties.badge11) : "",
	badge12: boxProperties.badge12 ? JSON.stringify(boxProperties.badge12) : "",
	badge13: boxProperties.badge13 ? JSON.stringify(boxProperties.badge13) : "",
	badge14: boxProperties.badge14 ? JSON.stringify(boxProperties.badge14) : "",
	badge15: boxProperties.badge15 ? JSON.stringify(boxProperties.badge15) : "",
	badge16: boxProperties.badge16 ? JSON.stringify(boxProperties.badge16) : "",
	badge17: boxProperties.badge17 ? JSON.stringify(boxProperties.badge17) : "",
	badge18: boxProperties.badge18 ? JSON.stringify(boxProperties.badge18) : "",
	badge19: boxProperties.badge19 ? JSON.stringify(boxProperties.badge19) : "",
	badge20: boxProperties.badge20 ? JSON.stringify(boxProperties.badge20) : "",
	badge21: boxProperties.badge21 ? JSON.stringify(boxProperties.badge21) : "",
	badge22: boxProperties.badge22 ? JSON.stringify(boxProperties.badge22) : "",
	badge23: boxProperties.badge23 ? JSON.stringify(boxProperties.badge23) : "",
	badge24: boxProperties.badge24 ? JSON.stringify(boxProperties.badge24) : "",
	badge25: boxProperties.badge25 ? JSON.stringify(boxProperties.badge25) : "",
	badge26: boxProperties.badge26 ? JSON.stringify(boxProperties.badge26) : "",
	badge27: boxProperties.badge27 ? JSON.stringify(boxProperties.badge27) : "",
	badge28: boxProperties.badge28 ? JSON.stringify(boxProperties.badge28) : "",
	badge29: boxProperties.badge29 ? JSON.stringify(boxProperties.badge29) : "",
	badge30: boxProperties.badge30 ? JSON.stringify(boxProperties.badge30) : "",
	badge31: boxProperties.badge31 ? JSON.stringify(boxProperties.badge31) : "",
	badge32: boxProperties.badge32 ? JSON.stringify(boxProperties.badge32) : "",

	// The layout weight of the box
	layoutWeight: boxProperties.layoutWeight,

	// The styles
	styles: boxProperties.styles,

	// The maximum number of columns in a grid layout
	maximumGridColumns: boxProperties.maximumGridColumns
});

export const createBoxDefaultPropertyMap = (): PropertyMap => ({
	// The child layout
	childLayout: PROPERTIES_DEFAULT_CHILD_LAYOUT,

	// The text
	text: PROPERTIES_DEFAULT_TEXT,

	// The text color
	textColor: PROPERTIES_DEFAULT_TEXT_COLOR,

	// The text size
	textSizeInPixels: String(PROPERTIES_DEFAULT_TEXT_SIZE),

	// How the text is sized
	textSizing: String(PROPERTIES_DEFAULT_TEXT_SIZING),

	// How the text is aligned
	textAlignment: String(PROPERTIES_DEFAULT_TEXT_ALIGNMENT),

	// The padding between the aligned text and the edge of the box
	textAlignmentPaddingInPixels: String(PROPERTIES_DEFAULT_TEXT_PADDING_IN_PIXELS),

	// Whether the text is vertical
	textIsVertical: String(PROPERTIES_DEFAULT_TEXT_IS_VERTICAL),

	// The font properties
	fontFamily: String(PROPERTIES_DEFAULT_FONT_FAMILY),
	fontStyle: String(PROPERTIES_DEFAULT_FONT_STYLE),
	fontWeight: String(PROPERTIES_DEFAULT_FONT_WEIGHT),

	// The background color
	backgroundColor: PROPERTIES_DEFAULT_BACKGROUND_COLOR,

	// The background image
	backgroundImage: PROPERTIES_DEFAULT_BACKGROUND_IMAGE,

	// The background image position and size, and whether it repeats
	backgroundImagePosition: PROPERTIES_DEFAULT_BACKGROUND_IMAGE_POSITION,
	backgroundImageSize: PROPERTIES_DEFAULT_BACKGROUND_IMAGE_SIZE,
	backgroundImageRepeat: PROPERTIES_DEFAULT_BACKGROUND_IMAGE_REPEAT,

	// The border color
	borderColor: PROPERTIES_DEFAULT_BORDER_COLOR,

	// The border radius
	borderRadius: String(PROPERTIES_DEFAULT_BORDER_RADIUS),

	// The border size (in pixels)
	borderSizeInPixels: String(PROPERTIES_DEFAULT_BORDER_SIZE_IN_PIXELS),

	// The border style
	borderStyle: String(PROPERTIES_DEFAULT_BORDER_STYLE),

	// The attribute to display in the badge and the badge background and text
	// colors
	badgeAttributeType: PROPERTIES_DEFAULT_BADGE_ATTRIBUTE_TYPE,
	badgeBackgroundColor: PROPERTIES_DEFAULT_BADGE_BACKGROUND_COLOR,
	badgeTextColor: PROPERTIES_DEFAULT_BADGE_TEXT_COLOR,
	badgeText: PROPERTIES_DEFAULT_BADGE_TEXT,

	// The badges
	badge1: PROPERTIES_DEFAULT_BADGE,
	badge2: PROPERTIES_DEFAULT_BADGE,
	badge3: PROPERTIES_DEFAULT_BADGE,
	badge4: PROPERTIES_DEFAULT_BADGE,
	badge5: PROPERTIES_DEFAULT_BADGE,
	badge6: PROPERTIES_DEFAULT_BADGE,
	badge7: PROPERTIES_DEFAULT_BADGE,
	badge8: PROPERTIES_DEFAULT_BADGE,
	badge9: PROPERTIES_DEFAULT_BADGE,
	badge10: PROPERTIES_DEFAULT_BADGE,
	badge11: PROPERTIES_DEFAULT_BADGE,
	badge12: PROPERTIES_DEFAULT_BADGE,
	badge13: PROPERTIES_DEFAULT_BADGE,
	badge14: PROPERTIES_DEFAULT_BADGE,
	badge15: PROPERTIES_DEFAULT_BADGE,
	badge16: PROPERTIES_DEFAULT_BADGE,
	badge17: PROPERTIES_DEFAULT_BADGE,
	badge18: PROPERTIES_DEFAULT_BADGE,
	badge19: PROPERTIES_DEFAULT_BADGE,
	badge20: PROPERTIES_DEFAULT_BADGE,
	badge21: PROPERTIES_DEFAULT_BADGE,
	badge22: PROPERTIES_DEFAULT_BADGE,
	badge23: PROPERTIES_DEFAULT_BADGE,
	badge24: PROPERTIES_DEFAULT_BADGE,
	badge25: PROPERTIES_DEFAULT_BADGE,
	badge26: PROPERTIES_DEFAULT_BADGE,
	badge27: PROPERTIES_DEFAULT_BADGE,
	badge28: PROPERTIES_DEFAULT_BADGE,
	badge29: PROPERTIES_DEFAULT_BADGE,
	badge30: PROPERTIES_DEFAULT_BADGE,
	badge31: PROPERTIES_DEFAULT_BADGE,
	badge32: PROPERTIES_DEFAULT_BADGE,

	// The layout weight of the box
	layoutWeight: PROPERTIES_DEFAULT_LAYOUT_WEIGHT,

	// The styles
	styles: PROPERTIES_DEFAULT_STYLES,

	// The maximum number of columns in a grid layout
	maximumGridColumns: PROPERTIES_DEFAULT_MAXIMUM_GRID_COLUMNS
});

export const createBoxEmptyPropertyMap = (): PropertyMap => ({
	// The child layout
	childLayout: undefined,

	// The text
	text: undefined,

	// The text color
	textColor: undefined,

	// The text size
	textSizeInPixels: undefined,

	// How the text is sized
	textSizing: undefined,

	// How the text is aligned
	textAlignment: undefined,

	// The padding between the aligned text and the edge of the box
	textAlignmentPaddingInPixels: undefined,

	// Whether the text is vertical
	textIsVertical: undefined,

	// The font properties
	fontFamily: undefined,
	fontStyle: undefined,
	fontWeight: undefined,

	// The background color
	backgroundColor: undefined,

	// The background image
	backgroundImage: undefined,

	// The background image position and size, and whether it repeats
	backgroundImagePosition: undefined,
	backgroundImageSize: undefined,
	backgroundImageRepeat: undefined,

	// The border color
	borderColor: undefined,

	// The border radius
	borderRadius: undefined,

	// The border size (in pixels)
	borderSizeInPixels: undefined,

	// The border style
	borderStyle: undefined,

	// The attribute to display in the badge and the badge background and text
	// colors
	badgeAttributeType: undefined,
	badgeBackgroundColor: undefined,
	badgeTextColor: undefined,
	badgeText: undefined,

	// The badges
	badge1: undefined,
	badge2: undefined,
	badge3: undefined,
	badge4: undefined,
	badge5: undefined,
	badge6: undefined,
	badge7: undefined,
	badge8: undefined,
	badge9: undefined,
	badge10: undefined,
	badge11: undefined,
	badge12: undefined,
	badge13: undefined,
	badge14: undefined,
	badge15: undefined,
	badge16: undefined,
	badge17: undefined,
	badge18: undefined,
	badge19: undefined,
	badge20: undefined,
	badge21: undefined,
	badge22: undefined,
	badge23: undefined,
	badge24: undefined,
	badge25: undefined,
	badge26: undefined,
	badge27: undefined,
	badge28: undefined,
	badge29: undefined,
	badge30: undefined,
	badge31: undefined,
	badge32: undefined,

	// The layout weight of the box
	layoutWeight: undefined,

	// The styles
	styles: undefined,

	// The maximum number of columns in a grid layout
	maximumGridColumns: undefined,	
});

export const createBoxDefaultProperties = () => {
	// The box properties
	const boxProperties: Properties = {
		// The child layout
		childLayout: PROPERTIES_DEFAULT_CHILD_LAYOUT,

		// The text
		text: PROPERTIES_DEFAULT_TEXT,

		// The text color
		textColor: PROPERTIES_DEFAULT_TEXT_COLOR,

		// The text size
		textSizeInPixels: PROPERTIES_DEFAULT_TEXT_SIZE,

		// How the text is sized
		textSizing: PROPERTIES_DEFAULT_TEXT_SIZING,

		// How the text is aligned
		textAlignment: PROPERTIES_DEFAULT_TEXT_ALIGNMENT,

		// The padding between the aligned text and the edge of the box
		textAlignmentPaddingInPixels: PROPERTIES_DEFAULT_TEXT_PADDING_IN_PIXELS,

		// Whether the text is vertical
		textIsVertical: PROPERTIES_DEFAULT_TEXT_IS_VERTICAL,

		// The font properties
		fontFamily: PROPERTIES_DEFAULT_BADGE_FONT_FAMILY,
		fontStyle: PROPERTIES_DEFAULT_FONT_STYLE,
		fontWeight: PROPERTIES_DEFAULT_FONT_WEIGHT,

		// The background color
		backgroundColor: PROPERTIES_DEFAULT_BACKGROUND_COLOR,

		// The background image
		backgroundImage: PROPERTIES_DEFAULT_BACKGROUND_IMAGE,

		// The background image position and size, and whether it repeats
		backgroundImagePosition: PROPERTIES_DEFAULT_BACKGROUND_IMAGE_POSITION,
		backgroundImageSize: PROPERTIES_DEFAULT_BACKGROUND_IMAGE_SIZE,
		backgroundImageRepeat: PROPERTIES_DEFAULT_BACKGROUND_IMAGE_REPEAT,

		// The border color
		borderColor: PROPERTIES_DEFAULT_BORDER_COLOR,

		// The border radius
		borderRadius: PROPERTIES_DEFAULT_BORDER_RADIUS,

		// The border size (in pixels)
		borderSizeInPixels: PROPERTIES_DEFAULT_BORDER_SIZE_IN_PIXELS,

		// The border style
		borderStyle: PROPERTIES_DEFAULT_BORDER_STYLE,

		// The attribute to display in the badge and the badge background and text
		// colors
		badgeAttributeType: PROPERTIES_DEFAULT_BADGE_ATTRIBUTE_TYPE,
		badgeBackgroundColor: PROPERTIES_DEFAULT_BADGE_BACKGROUND_COLOR,
		badgeTextColor: PROPERTIES_DEFAULT_BADGE_TEXT_COLOR,
		badgeText: PROPERTIES_DEFAULT_BADGE_TEXT,

		// The badges
		badge1: undefined,
		badge2: undefined,
		badge3: undefined,
		badge4: undefined,
		badge5: undefined,
		badge6: undefined,
		badge7: undefined,
		badge8: undefined,
		badge9: undefined,
		badge10: undefined,
		badge11: undefined,
		badge12: undefined,
		badge13: undefined,
		badge14: undefined,
		badge15: undefined,
		badge16: undefined,
		badge17: undefined,
		badge18: undefined,
		badge19: undefined,
		badge20: undefined,
		badge21: undefined,
		badge22: undefined,
		badge23: undefined,
		badge24: undefined,
		badge25: undefined,
		badge26: undefined,
		badge27: undefined,
		badge28: undefined,
		badge29: undefined,
		badge30: undefined,
		badge31: undefined,
		badge32: undefined,

		// The layout weight of the box
		layoutWeight: PROPERTIES_DEFAULT_LAYOUT_WEIGHT,

		// The styles
		styles: PROPERTIES_DEFAULT_STYLES,

		// The maximum number of columns in a grid layout
		maximumGridColumns: PROPERTIES_DEFAULT_MAXIMUM_GRID_COLUMNS
	};

	return boxProperties;
};

export const setBoxPropertiesFromBoxProperties = (
	boxProperties: Properties | undefined,
	boxPropertiesToSet: Properties | undefined
) => {
	// If we don't have box properties there's nothing to set
	if (!boxProperties) {
		return;
	}

	// Merge the box properties we want to set
	Object.assign(boxProperties, boxProperties, boxPropertiesToSet);
};

export const createBoxDefaultPropertyNameMap = (): Record<string, string> => ({
	// The child layout
	childLayout: "Child Layout",

	// The text
	text: "Text",

	// The text color
	textColor: "Text Color",

	// The text size
	textSizeInPixels: "Text Size",

	// How the text is sized
	textSizing: "Text Sizing",

	// How the text is aligned
	textAlignment: "Text Alignment",

	// How the text is aligned
	textAlignmentPaddingInPixels: "Text Alignment Padding",

	// Whether the text is vertical
	textIsVertical: "Text Is Vertical",

	// The font properties
	fontFamily: "Font Family",
	fontStyle: "Font Style",
	fontWeight: "Font Weight",

	// The background color
	backgroundColor: "Background Color",

	// The background image
	backgroundImage: "Background Image",

	// The background image position and size, and whether it repeats
	backgroundImagePosition: "Background Image Position",
	backgroundImageSize: "Background Image Size",
	backgroundImageRepeat: "Background Image Repeat",

	// The border color
	borderColor: "Border Colour",

	// The border radius
	borderRadius: "Border Radius",

	// The border size (in pixels)
	borderSizeInPixels: "Border Size",

	// The border style
	borderStyle: "Border Style",

	// The attribute to display in the badge and the badge background and text
	// colors
	badgeAttributeType: "Badge Attribute Type",
	badgeBackgroundColor: "Badge Background Colour",
	badgeTextColor: "Badge Text Color",

	// The badges
	badge1: "Badge 1",
	badge2: "Badge 2",
	badge3: "Badge 3",
	badge4: "Badge 4",
	badge5: "Badge 5",
	badge6: "Badge 6",
	badge7: "Badge 7",
	badge8: "Badge 8",
	badge9: "Badge 9",
	badge10: "Badge 10",
	badge11: "Badge 11",
	badge12: "Badge 12",
	badge13: "Badge 13",
	badge14: "Badge 14",
	badge15: "Badge 15",
	badge16: "Badge 16",
	badge17: "Badge 17",
	badge18: "Badge 18",
	badge19: "Badge 19",
	badge20: "Badge 20",
	badge21: "Badge 21",
	badge22: "Badge 22",
	badge23: "Badge 23",
	badge24: "Badge 24",
	badge25: "Badge 25",
	badge26: "Badge 26",
	badge27: "Badge 27",
	badge28: "Badge 28",
	badge29: "Badge 29",
	badge30: "Badge 30",
	badge31: "Badge 31",
	badge32: "Badge 32",

	// The layout weight of the box
	layoutWeight: "Layout Weight",

	// The styles to apply to a box
	styles: "Styles",

	// The maximum number of columns in a grid layout
	maximumGridColumns: "TypeWriter Mode Cols",
});

export const getBackgroundImage = (boxProperties: Properties): string => {
	// If the background image looks like it's referencing an image, wrap it in
	// a URL, otherwise just pass it through
	let backgroundImage = "";
	if (boxProperties.backgroundImage) {
		if (
			boxProperties.backgroundImage.startsWith("http://") ||
			boxProperties.backgroundImage.startsWith("https://")
		) {
			backgroundImage = `url(${boxProperties.backgroundImage}`;
		} else backgroundImage = boxProperties.backgroundImage;
	}

	return backgroundImage
}

export const getChildLayout = (boxParentProperties?: Properties): ChildLayout => {
	// Get the child layout from the parent properies (defaulting to VERTICAL
	// if there are none)
	const childLayout = boxParentProperties
		? boxParentProperties.childLayout
		: ChildLayout.VERTICAL;

	return childLayout;
}
