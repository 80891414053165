import { Illustration } from '@contracts/illustration';
import illustrationService from '@services/illustration';
import { Button, Table } from 'antd';
import React, { useEffect, useState } from 'react';
export interface IllustrationListProps {
	projectId: string | null;
	onSelectIllustration : (illustration : Illustration) => void
};

const IllustrationList: React.FC<IllustrationListProps> = (props) => {
	const [data, setData] = useState<Illustration[]>([]);

	useEffect(() => {
		if (props.projectId) {
			illustrationService.getListByProjectId(props.projectId, (illustrations) => {
				if (illustrations) {
					setData(illustrations);
				}
			});
		} 
		else {
			setData([]);
		}
	}, [props.projectId]);


	const columns = [{
		title: 'Name',
		dataIndex: 'name',
		key: '_id',
		render: (text: any, record: any) => {
			return <b>{text}</b>;
		},
	}, {
		title: 'Action',
		key: 'action',
		render: (text: any, record: any) => {
			return (
				<Button size="large" type="primary" onClick={()=>{props.onSelectIllustration(record)}}>Select</Button>
			);
		},
	}];

	return <Table
		columns={columns}
		pagination={false}
		rowKey="_id"
		dataSource={data}
	/>
}

export default IllustrationList;