import * as React from "react";
import { Key } from "react";

import { Tree } from "antd";
import {
	DataNode,
	EventDataNode,
} from "antd/lib/tree";

import * as lensesLib from "@lib/lenses/lenses";

const TreeNode = Tree.TreeNode;

interface LensGroupVisibilityListProps {
	onLensVisibilityChange: (lensKey: string, isVisible: boolean) => void;
	onLensGroupVisibilityChange: (
		lensGroupKey: string,
		isVisible: boolean
	) => void;
	lensMap: lensesLib.LensMap | undefined;
	lensVisibilityMap: lensesLib.LensVisibilityMap | undefined;
	lensGroupMap: lensesLib.LensGroupMap | undefined;
	lensGroupVisibilityMap: lensesLib.LensGroupVisibilityMap | undefined;
	textColor: string;
}

interface LensGroupVisibilityListState {
	expandedKeys: string[];
	autoExpandParent: boolean;
	selectedKeys: string[];
}

export class LensGroupVisibilityList extends React.Component<
	LensGroupVisibilityListProps,
	LensGroupVisibilityListState
> {
	constructor(props: LensGroupVisibilityListProps) {
		super(props);

		this.state = {
			expandedKeys: [],
			autoExpandParent: true,
			selectedKeys: [],
		};
	}
	private onExpand = (expandedKeys: any) => {
		// if not set autoExpandParent to false, if children expanded, parent can not collapse.
		// or, you can remove all expanded children keys.
		this.setState({
			expandedKeys,
			autoExpandParent: false,
		});
	};

	// May need to deal with | { checked: string[]; halfChecked: string[]; } for
	private onCheck = (
		checkedKeys:
			| Key[]
			| {
				checked: (string | number)[];
				halfChecked: (string | number)[];
			},
		info: any
	) => {
		const key = String(info.node.key) || "";
		const checked = info.checked || false;

		// Split the key into the actual key and the key type
		const splitKey = key.split("_");

		// If we have a length greater than 2 then we need to figure out what we've clicked on
		if (splitKey.length >= 2) {
			// Get the actual key
			const actualKey = splitKey[0];

			/// Get the type of the key
			const type = splitKey[1];

			// Figure out what to do based on the type
			if (type === "lensGroup") {
				// Get the lens group keys
				const lensGroupKeys = this.props.lensGroupMap
					? Object.keys(this.props.lensGroupMap)
					: [];

				// Is the key a lens group key?
				if (lensGroupKeys.indexOf(actualKey) >= 0) {
					// Notify the callback that the lens group visibility has changed
					this.props.onLensGroupVisibilityChange(actualKey, checked);
				}
			} else if (type === "lens") {
				// Notify the callback that the lens visibility has changed
				this.props.onLensVisibilityChange(actualKey, checked);
			}
		}
	};

	private onSelect = (
		selectedKeys: Key[],
		info: {
			event: "select";
			selected: boolean;
			node: EventDataNode<any>;
			selectedNodes: DataNode[];
			nativeEvent: MouseEvent;
		}
	) => {
		const expandedKeys = JSON.parse(
			JSON.stringify(this.state.expandedKeys)
		);

		// TODO: Do we need to do something with this key?
		// const key = String(info.node.key);

		// If the selected key is already expanded, unexpand it, otherwise expand it
		selectedKeys.forEach((key: Key) => {
			const keyIndex = expandedKeys.indexOf(key);
			if (keyIndex > -1) {
				expandedKeys.splice(keyIndex, 1);
			} else {
				expandedKeys.push(key);
			}
		});

		this.setState({ expandedKeys });
	};

	public render() {
		// Get the lens group keys
		const lensGroupKeys = this.props.lensGroupMap
			? Object.keys(this.props.lensGroupMap)
			: [];

		// Build the checked keys for the lens groups
		const checkedKeys: string[] = lensGroupKeys
			.filter((lensGroupKey: string) =>
				this.props.lensGroupVisibilityMap
					? this.props.lensGroupVisibilityMap[lensGroupKey]
					: false
			)
			.map((lensGroupKey: string) => lensGroupKey + "_lensGroup");

		// Build the checked keys for the lenses
		lensGroupKeys.forEach((lensGroupKey: string) => {
			// Get the lens group
			const lensGroup = this.props.lensGroupMap
				? this.props.lensGroupMap[lensGroupKey]
				: undefined;

			// Get the keys that belong to the lens group
			const lensKeys = lensGroup && lensGroup.lensKeys ? lensGroup.lensKeys : [];
		
			

			// If the lens is visible, add it to the checked keys
			lensKeys.forEach((lensKey: string) => {
				if (
					this.props.lensVisibilityMap &&
					this.props.lensVisibilityMap[lensKey]
				) {
					checkedKeys.push(`${lensKey}_lens_${lensGroupKey}_lensGroup`);
				}
			});
		});

		// Render the visibility types
		const renderedLensVisibilities = this.renderLensGroupVisibilities(
			this.props.lensGroupMap,
			this.props.lensGroupVisibilityMap
		);

		return (
			<Tree
				checkable
				checkStrictly
				onExpand={this.onExpand}
				expandedKeys={this.state.expandedKeys}
				autoExpandParent={this.state.autoExpandParent}
				onCheck={this.onCheck}
				checkedKeys={checkedKeys}
				onSelect={this.onSelect}
				selectedKeys={this.state.selectedKeys}
			>
				{renderedLensVisibilities}
			</Tree>
		);
	}

	private renderLensGroupVisibilities = (
		lensGroupMap: lensesLib.LensGroupMap | undefined,
		lensGroupVisibilityMap: lensesLib.LensGroupVisibilityMap | undefined
	) => {
		// If we don't have lens groups, there's nothing to render
		if (!lensGroupMap) {
			return null;
		}

		// Render each of the visibility types, sorted by order
		const renderedLensGroupVisibilities = Object.keys(lensGroupMap)
			.sort(
				(lensGroupKeyA: string, lensGroupKeyB: string) => {
					return lensGroupMap[lensGroupKeyA] && lensGroupMap[lensGroupKeyB] ? lensGroupMap[lensGroupKeyA].order -
					lensGroupMap[lensGroupKeyB].order : 0
				}

			)
			.map((lensGroupKey) => {
				// Get the lens group
				const lensGroup = lensGroupMap[lensGroupKey];
				if (lensGroup) {
					// Get the lens group visibility
					const lensGroupVisibility = lensGroupVisibilityMap
						? lensGroupVisibilityMap[lensGroupKey]
						: false;

					// Render the lens group visibility
					const renderedLens = this.renderLensGroupVisibility(
						lensGroupKey,
						lensGroup,
						lensGroupVisibility
					);

					return renderedLens;
				}

				return null;
			});

		return renderedLensGroupVisibilities;
	};

	private renderLensGroupVisibility = (
		lensGroupKey: string,
		lensGroup: lensesLib.LensGroup | undefined,
		lensGroupVisibility: boolean
	) => {
		// If we don't have a lens group there's nothing to render
		if (!lensGroup) {
			return null;
		}

		const renderedLensVisibilities = lensGroup.lensKeys.sort(
			(lensKeyA: string, lensKeyB: string) => {
				return this.props.lensMap !== undefined && this.props.lensMap[lensKeyA] && this.props.lensMap[lensKeyB] ? this.props.lensMap[lensKeyA].order -
				this.props.lensMap[lensKeyB].order : 0;
			}
		).map(
			(lensKey: string) => {
				const lens = this.props.lensMap
					? this.props.lensMap[lensKey]
					: undefined;

				const lensName = lens ? lens.name : "";

				return (
					<TreeNode
						key={`${lensKey}_lens_${lensGroupKey}_lensGroup`}
						title={
							<span
								style={{
									color: this.props.textColor,
								}}
							>
								{lensName}
							</span>
						}
						disabled={false}
						style={{
							color: this.props.textColor,
						}}
					/>
				);
			}
		);

		// Render the lens group
		const renderedLensGroup = (
			<TreeNode
				key={lensGroupKey + "_lensGroup"}
				title={
					<span
						style={{
							color: this.props.textColor,
						}}
					>
						{lensGroup.name}
					</span>
				}
				disabled={false}
				style={{
					color: this.props.textColor,
				}}
			>
				{renderedLensVisibilities}
			</TreeNode>
		);

		return renderedLensGroup;
	};
}
