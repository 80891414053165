import * as valueTypeLib from "@lib/box/value-type";
import * as boxTypeLib from "@lib/box/box-type";
import * as boxLib from "@lib/box/box";
import * as propertyLib from "@lib/box/box-properties";
import * as attributeLib from "@lib/box/attribute";
import * as boxVisualisationLib from "@lib/box/box-visualisation";
import * as visibilityStateLib from "@lib/box/visibility-state";
import { BoxStyleMap } from '@lib/box/box-style';

import * as lensesLib from "@lib/lenses/lenses";
import * as configurationLib from "@lib/configuration/configuration";

export enum IllustrationState {
	EDIT_BOX = 0,
	EDIT_MULTI_BOX,
	EDIT_ASSOCIATIONS,
	SIMPLE_EDIT_ASSOCIATIONS,
	VIEW,
}

export enum IllustrationPersistentState {
	EDIT_BOX = 0,
	VIEW,
}

export interface Illustration {
	valueTypes: valueTypeLib.ValueTypeMap | undefined;
	boxTypes: boxTypeLib.BoxTypeMap | undefined;
	boxes: boxLib.BoxMap | undefined;
	boxVisualisations: boxVisualisationLib.BoxVisualisationMap | undefined;
	defaultProperties: propertyLib.PropertyMap | undefined;
	attributes: attributeLib.AttributeMap | undefined;
	boxStyles: BoxStyleMap | undefined;
	lenses: lensesLib.LensMap | undefined;
	lensGroups: lensesLib.LensGroupMap | undefined;
	configuration: configurationLib.Configuration | undefined;
	visibilityStateMap: visibilityStateLib.VisibilityStateMap | undefined;
	currentVisibilityStateKey: string;
	persistentState: IllustrationPersistentState;
	simpleLensGroups: lensesLib.LensGroupMap | undefined;
	simpleLenses: lensesLib.LensMap | undefined;
	unprocessedSimpleLensGroups: lensesLib.SimpleLensGroupMap | undefined;
}
