/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";

import { List } from "antd";

import * as boxTypeLib from "@lib/box/box-type";

interface BoxTypeListProps {
	onBoxTypeEdit: (boxTypeKey: string) => void;
	onBoxTypeDelete: (boxTypeKey: string) => void;
	boxTypeMap: boxTypeLib.BoxTypeMap | undefined;
	canEdit: boolean;
	canDelete: boolean;
}

interface BoxTypeListItem {
	boxTypeKey: string;
	name: string;
	description: string;
}

export class BoxTypeList extends React.Component<BoxTypeListProps> {
	public render() {
		// Build the box type list items
		const boxTypeListItems: BoxTypeListItem[] = this.buildBoxTypeListItems(
			this.props.boxTypeMap
		);

		return (
			<div style={{}}>
				<List
					itemLayout="horizontal"
					dataSource={boxTypeListItems}
					renderItem={(item: BoxTypeListItem) => {
						// Build the actions
						const actions: React.ReactNode[] = [];
						if (this.props.canEdit) {
							actions.push(
								<a
									onClick={() =>
										this.handleBoxTypeEdit(item.boxTypeKey)
									}
								>
									Edit
								</a>
							);
						}
						if (this.props.canDelete) {
							actions.push(
								<a
									onClick={() =>
										this.handleBoxTypeDelete(
											item.boxTypeKey
										)
									}
								>
									Delete
								</a>
							);
						}

						return (
							<List.Item actions={actions}>
								<List.Item.Meta
									title={item.name}
									description={item.description}
								/>
							</List.Item>
						);
					}}
				/>
			</div>
		);
	}

	private handleBoxTypeEdit = (boxTypeKey: string) => {
		// Notify the callback that the box type should be edited
		this.props.onBoxTypeEdit(boxTypeKey);
	};

	private handleBoxTypeDelete = (boxTypeKey: string) => {
		// Notify the callback that the box type should be removed
		this.props.onBoxTypeDelete(boxTypeKey);
	};

	private buildBoxTypeListItems = (
		boxTypeMap: boxTypeLib.BoxTypeMap | undefined
	) => {
		// If we don't have a box map there's no list items
		if (!boxTypeMap) {
			return [];
		}

		// Render each of the box types
		const renderedBoxTypes = Object.keys(boxTypeMap).map((boxTypeKey) => {
			// Get the box type
			const boxType = boxTypeMap[boxTypeKey];

			// Build the box type list item
			const boxTypeListItem: BoxTypeListItem = {
				boxTypeKey,
				name: boxType.name,
				description: boxType.description,
			};

			return boxTypeListItem;
		});

		return renderedBoxTypes;
	};
}
