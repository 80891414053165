import * as React from "react";

import { PlusOutlined } from "@ant-design/icons";

export interface BoxButtonAddProps {
	onMouseEnter: (event: React.MouseEvent<HTMLDivElement>) => void;
	onMouseLeave: (event: React.MouseEvent<HTMLDivElement>) => void;
	onClick: (event: React.MouseEvent<HTMLDivElement>) => void;

	canAddChildren: boolean;
	isAddBoxButtonHovered: boolean;

	buttonSizeInPixels: number;
	buttonBorderRadiusInPixels: number;
	buttonOffsetInPixels: number;
}

export const BoxButtonAdd = React.forwardRef((props: BoxButtonAddProps,
	ref: React.ForwardedRef<HTMLDivElement>) => {
	const {
		onMouseEnter,
		onMouseLeave,
		onClick,
		canAddChildren,
		isAddBoxButtonHovered,
		buttonSizeInPixels,
		buttonBorderRadiusInPixels,
		buttonOffsetInPixels
	} = props;

	if (!canAddChildren) {
		return null
	}

	// Determine the Add Box button text and background color
	const addBoxTextColor = isAddBoxButtonHovered
	? "#FFF"
	: "#FFF";
	const addBoxBackgroundColor = isAddBoxButtonHovered
		? "#4CFF00"
		: "#267F00";

	// Render the Add Box button in the same position as the default badge
	return (
		<div
			ref={ref}
			style={{
				display: "inline",
				position: "absolute",
				top: -buttonOffsetInPixels,
				left: -buttonOffsetInPixels,
				width: buttonSizeInPixels,
				height: buttonSizeInPixels,
				backgroundColor: addBoxBackgroundColor,
				borderRadius: buttonBorderRadiusInPixels,
				textAlign: "center",
				paddingTop: 5,
				paddingLeft: 1,
			}}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={onClick}
		>
			<PlusOutlined
				style={{
					color: addBoxTextColor,
				}}
			/>
		</div>
	);
});