import * as React from 'react';
import { FileAddOutlined } from '@ant-design/icons';
import { Table, Divider, Modal, Card, Layout, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Link } from "react-router-dom";

import clientService from '../../../services/client';
import { Client, CreateClientCommand, UpdateClientCommand } from '@contracts/client';

import * as authorizationService from '../../../services/authorization';

import { renderBreadcrumbsInPortal } from '../../organisms/Breadcrumbs';

import { withRouter } from '../../../routes/withRouter';

import AddEditClientDialog from './AddEditClientDialog';

const confirm = Modal.confirm;
export interface ListClientsPageProps {
	match: any,
	abilities: authorizationService.AbilitiesMap | undefined,
}

export interface ListClientsPageState {
	modalClient: any,
	clients: any,
	isEditClientModalVisible: boolean,
	isClientAdding: boolean
}

class ListClientsPageC extends React.Component<ListClientsPageProps, any> {
	private columns = [{
		title: 'Name',
		dataIndex: 'name',
		key: '_id',
		render: (text: any, record: any) => {
			// Get the client key
			const clientKey = record._id;

			return authorizationService
				.renderIfAbilityPermitted(this.props.abilities,
					authorizationService.AbilityType.ClientView,
					clientKey,
					() => <Link to={"/clients/" + clientKey}>{text}</Link>);
		},
	}, {
		title: 'Action',
		key: 'action',
		render: (text: any, record: any) => {
			// Get the client key
			const clientKey = record._id;

			return (
				<span>
					{
						authorizationService.renderIfAbilityPermitted(this.props.abilities,
							authorizationService.AbilityType.ClientEdit,
							clientKey,
							// eslint-disable-next-line jsx-a11y/anchor-is-valid
							() => <a onClick={(e) => this.handleEditClientClick(record, e)}>Edit</a>)
					}
					{
						authorizationService.renderIfAllAbilitiesPermitted(this.props.abilities,
							[
								authorizationService.AbilityType.ClientEdit,
								authorizationService.AbilityType.ClientDelete,
							],
							clientKey,
							() => <Divider type="vertical" />)
					}
					{
						authorizationService.renderIfAbilityPermitted(this.props.abilities,
							authorizationService.AbilityType.ClientDelete,
							clientKey,
							// eslint-disable-next-line jsx-a11y/anchor-is-valid
							() => <a onClick={(e) => this.handleRemoveClientClick(record, e)}>Delete</a>)
					}
				</span>
			);
		},
	}];

	private refreshClientList = () => {
		clientService.getList((clients) => {
			if (clients) {
				// Filter out clients that can't be viewed
				const permittedClients = clients
					.filter((client: Client) => {
						// Can we view the client?
						return authorizationService.isAbilityPermitted(this.props.abilities,
							authorizationService.AbilityType.ClientView,
							client._id)
					});

				this.setState({ clients: permittedClients });
			}
		}, (message) => {
			console.log(`Error occurred: ${message}.`);

			notification.open({
				message: 'Error: Could not load Client List',
				description:
					`Failed to load client list.`,
				icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
			});
		});
	};

	private handleRemoveClientClick = (client: any, e: any) => {

		confirm({
			title: 'Are you sure delete this client?',
			okText: 'Yes',
			okType: 'danger',
			cancelText: 'No',
			onOk: () => {
				clientService.remove(client._id, (data: any) => {
					this.refreshClientList();
				}, (message) => {
					console.log(`Error occurred: ${message}.`);

					notification.open({
						message: 'Error: Could not delete Client List',
						description:
							`Failed to delete Cliend Id ${client._id}.`,
						icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
					});
				});
			},
			onCancel: () => {
			},
		});
	};


	constructor(props: ListClientsPageProps) {
		super(props);
		this.state = {
			clients: [],
			modalClient: null,
			isEditClientModalVisible: false
		};
	}

	componentDidMount() {
		this.refreshClientList();
	}

	private renderBreadcrumbs = () => {
		const BREADCRUMBS = [
			{
				onGetPath: (params: any) => "/",
				onGetDisplayName: (params: any) => "Clients"
			}
		];

		return renderBreadcrumbsInPortal(BREADCRUMBS,
			this.props.match.params)
	}

	public render() {
		// Determine the client actions based on permissions
		const clientActions = (authorizationService.isAbilityPermitted(this.props.abilities,
			authorizationService.AbilityType.ClientAdd,
			'*'))
			? [<FileAddOutlined onClick={this.handleAddClientClick} />]
			: []

		const breadcrumbs = this.renderBreadcrumbs()

		return (
			<Layout
				style={{
					width: "100%",
					height: "100%"
				}}
			>
				{breadcrumbs}
				<Layout.Content>
					<Card
						title="All Clients"
						style={{ margin: 16 }}
						actions={clientActions}
					>
						<Table columns={this.columns} pagination={false} rowKey="_id" dataSource={this.state.clients} />
					</Card>
					{
						this.state.modalClient ?
							<AddEditClientDialog
								onAddClientOkButtonClick={this.handleAddClientModalOK}
								onEditClientOkButtonClick={this.handleEditClientModalOK}
								onCancelButtonClick={this.handleEditClientCancel}
								isVisible={this.state.isEditClientModalVisible}
								client={this.state.modalClient}
								isAdding={this.state.isClientAdding}
							/> : null

					}
				</Layout.Content>
			</Layout>

		);

	}
	private handleEditClientModalOK = (clientId: string, cmd: UpdateClientCommand) => {

		// If we had an ID then we are updating
		clientService.put(clientId, cmd, (res: any) => {
			this.refreshClientList();
			// Update our state and close the box modal dialog
			this.setState({
				modalClient: null,
				isEditClientModalVisible: false,
			});
		}, (message) => {
			console.log(`Error occurred: ${message}.`)
			notification.open({
				message: 'Error: Could not edit Client',
				description:
					`Failed to edit Client Id ${clientId}.`,
				icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
			});
		});
	};

	private handleAddClientModalOK = (cmd: CreateClientCommand) => {
		clientService.post(cmd, (res: any) => {
			this.refreshClientList();
			// Update our state and close the box modal dialog
			this.setState({
				modalClient: null,
				isEditClientModalVisible: false,
			});
		}, (message) => {
			console.log(`Error occurred: ${message}.`);
			notification.open({
				message: 'Error: Could not create Client',
				description:
					`Failed to create new Client.`,
				icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
			});
		});
	};

	private handleEditClientCancel = () => {
		// Close the box modal dialog
		this.setState({
			modalClient: null,
			isEditClientModalVisible: false,
		})
	};

	private handleEditClientClick = (client: any, e: any) => {

		// Open the box modal dialog
		this.setState({
			modalClient: client,
			isEditClientModalVisible: true,
			isClientAdding: false
		});
	};

	private handleAddClientClick = () => {
		// Open the box modal dialog
		this.setState({
			modalClient: { name: "", description: "" },
			isEditClientModalVisible: true,
			isClientAdding: true
		});
	};
}

export const ListClientsPage = withRouter(ListClientsPageC);
