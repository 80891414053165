import React from "react";
import Editor from 'react-prism-editor';


export interface JsonEditorProps {
	code: string;
	onChange: (code: string) => void;
	style: any;
}

const JsonEditor: React.FC<JsonEditorProps> = (props) => {

	return <Editor
		language="json"
		theme="default"
		readOnly={false}
		style={props.style}
		lineNumber={true}
		clipboard={true}
		showLanguage={false}
		code={props.code}
		changeCode={props.onChange}
	/>
};

export default JsonEditor;