import * as React from "react";
import * as illustrationLib from "@lib/illustration/illustration";
import { Modal, Input, Upload } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { InboxOutlined } from "@ant-design/icons";
export interface ImportExportIllustrationDialogProps {
	onImportButtonClick: (illustrationData: any) => void;
	onCloseOrCancelButtonClick: () => void;
	isImporting: boolean;
	isVisible: boolean;
	illustrationData: illustrationLib.Illustration | undefined;
	illustrationName: string;
}

interface ImportExportIllustrationDialogState {
	downloadLink: string;
	downloadFileName: string;
	importFileList: UploadFile<any>[];
	illustrationJSONText: string;
	illustrationJSONIsValid: boolean;
}

const isJSONValid = (json: string) => {
	// Get whether the JSON is valid (assume it's not)
	let jsonIsValid = false;
	try {
		JSON.parse(json);
		jsonIsValid = true;
	} catch (e) {
		jsonIsValid = false;
	}

	return jsonIsValid;
};

export class ImportExportIllustrationDialog extends React.Component<
	ImportExportIllustrationDialogProps,
	ImportExportIllustrationDialogState
> {

	constructor(props: ImportExportIllustrationDialogProps) {
		super(props);

		// Get the current illustration JSON
		const illustrationJSONText =
			props.isVisible && !props.isImporting
				? JSON.stringify(props.illustrationData, null, 2)
				: "";


		// Create the File for the download link
		const data = new Blob([illustrationJSONText], {
			type: "text/json",
		});

		const downloadLink = window.URL.createObjectURL(data);

		// Get whether the JSON is valid
		const illustrationJSONIsValid = isJSONValid(illustrationJSONText);

		const illustrationName = props.illustrationName;		
		const downloadFileName = `${illustrationName}.json`;

		// Set up our state
		this.state = {
			illustrationJSONText,
			illustrationJSONIsValid,
			importFileList: [],
			downloadLink,
			downloadFileName,
		};
	}

	public componentWillReceiveProps(
		nextProps: ImportExportIllustrationDialogProps
	) {
		// Only update if we're showing the dialog
		if (!this.props.isVisible && nextProps.isVisible) {
			// Get the current illustration JSON
			const illustrationJSONText =
				nextProps.isVisible && !nextProps.isImporting
					? JSON.stringify(nextProps.illustrationData, null, 2)
					: "";

			// Get whether the JSON is valid
			const illustrationJSONIsValid = isJSONValid(illustrationJSONText);

			// Create the File for the download link
			const data = new Blob([illustrationJSONText], {
				type: "text/json",
			});

			// Remove the old download link to avoid memory leaks
			if (this.state.downloadLink !== "")
				window.URL.revokeObjectURL(this.state.downloadLink);

			const downloadLink = window.URL.createObjectURL(data);

			const illustrationName = nextProps.illustrationName;		
			const downloadFileName = `${illustrationName}.json`;

			// Set up our state
			this.setState({
				illustrationJSONText,
				illustrationJSONIsValid,
				downloadLink,
				downloadFileName,
			});
		}
	}

	private renderUpload = () => {
		return (
			<Upload.Dragger
				name="file"
				accept=".json"
				multiple={false}
				action=""
				beforeUpload={this.handleBeforeUpload}
				fileList={this.state.importFileList}
				onChange={this.handleUploadChange}
				showUploadList={false}
			>
				<p className="ant-upload-drag-icon">
					<InboxOutlined />
				</p>
				<p className="ant-upload-text">
					Click or drag a JSON file to this area to upload
				</p>
				<p className="ant-upload-hint">
					Only JSON files previously exported by Enterprise Lens are
					supported
				</p>
			</Upload.Dragger>
		);
	};

	private handleUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
		console.log(info);

		this.readImportedJsonFile(info.file).then((value: string)=> {
			this.updateJSON(value);
		});
	};

	private readImportedJsonFile = (file: any) => {
		return new Promise(
			(
				resolve: (jsonString: string) => void,
				reject: (reason: any) => void
			) => {
				const reader = new FileReader();

				reader.onload = (event: any) => {
					try {
						// Get the loaded data
						const loadedData = event.target.result;
						if (!loadedData) {
							reject(new Error("Unable to load file"));
							return;
						}
												
						// We've loaded the file
						resolve(loadedData);
					} catch (e) {
						reject(e);
					}
				};

				reader.readAsText(file);
			}
		);
	};

	private handleBeforeUpload = (file: any) => {
		this.setState({ importFileList: [file] });
		return false;
	};

	public render() {
		// Get the title
		const title = this.props.isImporting
			? "Import Illustration JSON"
			: "Export Illustration JSON";

		// Get the input style
		const inputStyle = this.state.illustrationJSONIsValid
			? {}
			: {
				color: "white",
				backgroundColor: "red",
			};

		// Whether the input is read-only
		const isInputReadOnly = !this.props.isImporting;

		// Get the OK button text
		const okButtonText = this.props.isImporting ? "Import" : "Close";

		// The OK button is disabled if the JSON is invalid
		const isOKButtonDisabled = !this.state.illustrationJSONIsValid;

		// The cancel button is disabled if we're not importing
		const isCancelButtonDisabled = !this.props.isImporting;

		return (
			<Modal
				title={title}
				open={this.props.isVisible}
				okText={okButtonText}
				okButtonProps={{ disabled: isOKButtonDisabled }}
				onOk={this.handleOKButtonClick}
				cancelButtonProps={{ disabled: isCancelButtonDisabled }}
				onCancel={this.props.onCloseOrCancelButtonClick}
				zIndex={9999}
				width={"75%"}
			>
				<div>
					<Input.TextArea
						rows={24}
						value={this.state.illustrationJSONText}
						style={inputStyle}
						readOnly={isInputReadOnly}
						onChange={(
							event: React.ChangeEvent<HTMLTextAreaElement>
						) => this.handleIllustrationJSONChange(event)}
					/>
				</div>
				<div>{this.props.isImporting ? this.renderUpload() : null}</div>
				
				{this.props.isImporting === false ?
					<div>
						<br />
						Filename:{" "}
						<Input
							onChange={(
								event: React.ChangeEvent<HTMLInputElement>
							) => this.handleDownloadFilenameChange(event)}
							value={this.state.downloadFileName}
						/>
						<a
							download={this.state.downloadFileName}
							href={this.state.downloadLink}
						>
							Download
						</a>
					</div> : null}
			</Modal>
		);
	}

	private handleOKButtonClick = () => {
		// console.log('handleOKButtonClick');
		// console.log(this.state.box);

		// Only take action if the JSON is valid
		if (this.state.illustrationJSONIsValid) {
			// Are we importing
			if (this.props.isImporting) {
				// Notify the parent that the Iport button has been clicked, passing the new
				// Illustration (if any)
				const illustrationData = JSON.parse(this.state.illustrationJSONText);

				this.props.onImportButtonClick(illustrationData);
			} else {
				// Notify the parent that the Close button has been clicked
				this.props.onCloseOrCancelButtonClick();
			}
		}
	};

	private handleDownloadFilenameChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const downloadFileName = event.target.value;
		const illustrationJSONText = this.state.illustrationJSONText;
		const illustrationJSONIsValid = this.state.illustrationJSONIsValid;
		const downloadLink = this.state.downloadLink;
		// Update the state with the new box type name
		this.setState({
			illustrationJSONText,
			illustrationJSONIsValid,
			downloadLink,
			downloadFileName,
		});
	};

	private updateJSON = (illustrationJSONText: string) => {
		// Get whether the JSON is valid
		const illustrationJSONIsValid = isJSONValid(illustrationJSONText);

		// Create the File for the download link
		const data = new Blob([illustrationJSONText], {
			type: "text/json",
		});

		// Remove the old download link to avoid memory leaks
		if (this.state.downloadLink !== "")
			window.URL.revokeObjectURL(this.state.downloadLink);

		const downloadLink = window.URL.createObjectURL(data);
		const downloadFileName = this.state.downloadFileName;
		// Update the state with the new box type name
		this.setState({
			illustrationJSONText,
			illustrationJSONIsValid,
			downloadLink,
			downloadFileName,
		});
	}

	private handleIllustrationJSONChange = (
		event: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		// Get the new illustration JSON
		const illustrationJSONText = event.target.value;

		this.updateJSON(illustrationJSONText)
		// Get whether the JSON is valid
		const illustrationJSONIsValid = isJSONValid(illustrationJSONText);

		// Create the File for the download link
		const data = new Blob([illustrationJSONText], {
			type: "text/json",
		});

		// Remove the old download link to avoid memory leaks
		if (this.state.downloadLink !== "")
			window.URL.revokeObjectURL(this.state.downloadLink);

		const downloadLink = window.URL.createObjectURL(data);
		const downloadFileName = this.state.downloadFileName;
		// Update the state with the new box type name
		this.setState({
			illustrationJSONText,
			illustrationJSONIsValid,
			downloadLink,
			downloadFileName,
			importFileList:[]
		});
	};
}
