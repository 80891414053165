import React from "react";
import { Modal, Input } from "antd";
import LabelledElement from "@components/molecules/LabelledElement";

interface AddEditProjectDialogProps {
	project: any | undefined,
	onOKButtonClick: ((project: any) => void),
	onCancelButtonClick: (() => void),
	isVisible: boolean,
};

interface AddEditProjectDialogState {
	project: any
};


export default class AddEditProjectDialog extends React.Component<AddEditProjectDialogProps, AddEditProjectDialogState> {
	constructor(props: AddEditProjectDialogProps) {
		super(props);

		// Get the Project
		const project = (props.project)
			? JSON.parse(JSON.stringify(props.project))
			: undefined;

		// Set up our state
		this.state = {
			project: project,
		};
	}

	public componentWillReceiveProps(nextProps: AddEditProjectDialogProps) {
		// Only update if we're showing the dialog
		if ((!this.props.isVisible) && (nextProps.isVisible)) {
			// Get the Project
			const project = (nextProps.project)
				? JSON.parse(JSON.stringify(nextProps.project))
				: undefined;

			// Set up our state
			this.setState({
				project: project,
			});
		}
	}

	public render() {
		return (

			<Modal
				title={'Edit Project'}
				open={this.props.isVisible}
				onOk={this.handleOKButtonClick}
				onCancel={this.props.onCancelButtonClick}
				zIndex={9999}
			>
				{this.state.project ?
					<div
						style={{
							width: "100%",
							display: "grid",
							gridTemplateColumns: "1fr",
							gap: "4px 12px",
							padding: 0,
							margin: 0,
							overflowY: "visible",
						}}>
						<LabelledElement labelText="Name">
							<Input name="name" value={this.state.project.name} onChange={this.handleInputChange} />
						</LabelledElement>
						<LabelledElement labelText="Description">
							<Input.TextArea name="description" value={this.state.project.description} onChange={this.handleInputChange} />
						</LabelledElement>
					</div>
					: null}
			</Modal>
		);
	}

	private handleInputChange = (event: any) => {
		var project = JSON.parse(JSON.stringify(this.state.project));
		project[event.target.name] = event.target.value;
		this.setState({ project: project });
	};

	private handleOKButtonClick = () => {
		// Notify the callback that the OK button has been clicked, passing the new
		// box name and box type key
		this.props.onOKButtonClick(this.state.project);
	};
}