import React from "react";

import {
	Modal,
	Tabs,
	Descriptions,
	Tag,
} from "antd";

import { getUrlWithPrefix } from "@lib/url";

import * as HtmlToReact from "html-to-react";

import * as boxLib from "@lib/box/box";
import * as boxTypeLib from "@lib/box/box-type";
import * as attributeLib from "@lib/box/attribute";
import * as attributeTypeLib from "@lib/box/attribute-type";

const { TabPane } = Tabs;

interface ViewBoxDialogProps {
	onOKButtonClick: () => void;
	onCancelButtonClick: () => void;
	isVisible: boolean;
	boxKey: string;
	box: boxLib.Box;
	boxTypes: boxTypeLib.BoxTypeMap | undefined;
	boxes: boxLib.BoxMap | undefined;
	smartPageKey: string;
}

interface ViewBoxDialogState {}

export default class ViewBoxDialog extends React.Component<
	ViewBoxDialogProps,
	ViewBoxDialogState
> {
	constructor(props: ViewBoxDialogProps) {
		super(props);

		const updatedState = this.updateState();

		this.state = updatedState;
	}

	private updateState(): any {
		return {};
	}

	public componentWillReceiveProps(nextProps: ViewBoxDialogProps) {
		const updatedState = this.updateState();

		this.setState(updatedState);
	}

	renderAttributeValue(attributeKey: string,
		attributeType: attributeTypeLib.AttributeType | null,
		attributeValue: attributeLib.AttributeValue): JSX.Element | JSX.Element[] | null {
		let displayValue: JSX.Element | JSX.Element[] | null = null;

		if (attributeType) {
			switch (attributeType.valueType) {
				case "associations": {
					if (attributeValue) {
						let { associationsType } = attributeType; 
						if (!associationsType) {
							associationsType = attributeTypeLib.AssociationType.Uuids;
						}
					
						// For UUID associations, get the list of boxes by name.
						// For name or attribute value lookups, use the attribute value.
						// For reverse lookups, display nothing.
						if (associationsType === attributeTypeLib.AssociationType.Uuids) {
							displayValue = String(attributeValue)
								.split(",")
								.map((associationBoxKey) => {
									return (
										<Tag key={associationBoxKey}>
											{this.props.boxes && this.props.boxes[associationBoxKey]
												? this.props.boxes[associationBoxKey].name
												: "none"}
										</Tag>
									);
								});
						} else if (associationsType === attributeTypeLib.AssociationType.NamesLookup ||
							associationsType === attributeTypeLib.AssociationType.AttributeValuesLookup) {
							displayValue = String(attributeValue)
								.split(",")
								.map((value) => {
									return (
										<Tag key={value}>
											{value}
										</Tag>
									);
								});
						} else {
							displayValue = null;
						}
					}
					break;
				}
				case attributeTypeLib.ValueType.Url: {
					if (attributeValue) {
						const attributeUrlValue = attributeValue.toString(); 

						// If the attribute value doesn't start with an http:// then add it
						const href = getUrlWithPrefix(attributeUrlValue);

						displayValue = <a href={href} target='_blank' rel="noreferrer">{attributeUrlValue}</a>;
					} else {
						displayValue = null;
					}
					break;
				}
				default: {
					displayValue = (
						<span>
							{attributeValue}
						</span>
					);
					break;
				}
			}
		}	
		
		return displayValue;
	}

	public render() {
		// If we don't have a box or boxTypes there's nothing to render
		if (!this.props.box || !this.props.boxTypes) {
			return null;
		}

		let defaultActiveKey = "";

		if (
			this.props.box !== undefined &&
			this.props.box.smartPages !== undefined
		) {
			defaultActiveKey = this.props.smartPageKey;

			if (defaultActiveKey === "") {
				const smartPageKeys = Object.keys(this.props.box.smartPages);
				if (smartPageKeys.length > 0) {
					defaultActiveKey = smartPageKeys[0];
				}
			}
		}

		const boxType = this.props.box && this.props.boxTypes
			? this.props.boxTypes[this.props.box.boxType]
			: null;

		const boxAttributeTypes = boxTypeLib.getBoxTypeAttributeTypeCacheForType(this.props.box.boxType);

		return (
			<Modal
				title={this.props.box.name}
				open={this.props.isVisible}
				onOk={this.handleOKButtonClick}
				onCancel={this.props.onCancelButtonClick}
				width="75%"
				zIndex={9999}
			>
				<div>
					<Tabs
						defaultActiveKey={defaultActiveKey}
						tabPosition={"left"}
						style={{ height: "100%" }}
					>
						<TabPane tab="Summary" key="summaryView">
							<div>
								{this.props.box &&
								this.props.box.attributes &&
								this.props.boxTypes &&
								boxType
									? Object.keys(this.props.box.attributes)
											.filter((attributeKey) => {
												return (
													boxAttributeTypes[attributeKey] &&
													boxAttributeTypes[attributeKey].showInSummary
												);
											})
											.sort((attributeAKey: string,
												attributeBKey: string): number => {
													// Get the attributes
													const attributeA = boxAttributeTypes[attributeAKey];
													const attributeB = boxAttributeTypes[attributeBKey];

													// If we don't actually have attributes, leave the order unchanged
													if (!attributeA || !attributeB) {
														return 0;
													}

													// Get the orders (use the .order value as the order)
													const attributeAOrder = attributeA.order;
													const attributeBOrder = attributeB.order;

													// If the order of A is before that of B, put A first
													if (attributeAOrder < attributeBOrder) {
														return -1;
													}

													// If the order of A is after that of B, put box B first
													if (attributeAOrder > attributeBOrder) {
														return 1;
													}

													// Otherwise, don't change the order
													return 0;
												}
											)
											.map((attributeKey) => {
												const attributeType = boxAttributeTypes[attributeKey]
													? boxAttributeTypes[attributeKey]
													: null;

												const attributeName = attributeType
													? attributeType.name
													: "";
												const attributeValue = this
													.props.box!.attributes![
													attributeKey
												];

												const displayValue = this
													.renderAttributeValue(attributeKey,
														attributeType,
														attributeValue)

												// Don't render anything if there's no display value.
												if (displayValue === null) {
													return null;
												}

												return (
													<Descriptions
														key={attributeKey}
														size={"small"}
														column={1}
													>
														<Descriptions.Item
															label={
																attributeName
															}
														>
															<span>
																{displayValue}
															</span>
														</Descriptions.Item>
													</Descriptions>
												);
											})
									: null}
							</div>
						</TabPane>

						{this.props.box.smartPages !== undefined
							? Object.keys(this.props.box.smartPages)
									.sort((a, b) => {
										const n1 = this.props.box!.smartPages![
											a
										].order;
										const n2 = this.props.box!.smartPages![
											b
										].order;

										if (n1 > n2) {
											return 1;
										}

										if (n1 < n2) {
											return -1;
										}

										return 0;
									})
									.map((smartPageKey) => (
										<TabPane
											tab={`${
												this.props.box!.smartPages![
													smartPageKey
												].name
											}`}
											key={smartPageKey}
										>
											{this.getParsedHtmlContentForSmartPageKey(
												this.props.box!.smartPages![
													smartPageKey
												].content,
												this.props.box
											)}
										</TabPane>
									))
							: null}
					</Tabs>
				</div>
			</Modal>
		);
	}

	private getParsedHtmlContentForSmartPageKey(
		content: string,
		box: boxLib.Box
	) {
		let newContent = content;

		newContent = this.replaceBoxAttributesWithValues(newContent, box);
		newContent = this.replaceBoxPropertiesWithValues(newContent, box);

		return new HtmlToReact.Parser().parse(newContent);
	}

	private replaceBoxPropertiesWithValues = (
		content: string,
		box: boxLib.Box
	): string => {
		let newContent = content;

		if (box.defaultProperties) {
			Object.keys(box.defaultProperties).forEach((propertyKey) => {
				const propertyValue = box.defaultProperties![propertyKey];
				const defaultPropertyValue =
					propertyValue !== undefined ? propertyValue : "";

				newContent = newContent.replace(
					"${" + propertyKey + "}",
					defaultPropertyValue
				);
			});
		}

		return newContent;
	};

	private replaceBoxAttributesWithValues = (
		content: string,
		box: boxLib.Box
	): string => {
		let newContent = content;

		if (box.attributes) {
			Object.keys(box.attributes).forEach((attributeKey) => {
				newContent = newContent.replace(
					"${" + attributeKey + "}",
					String(box.attributes![attributeKey])
				);
			});
		}

		return newContent;
	};

	private handleInputChange = (event: any) => {
		// var illustration = JSON.parse(JSON.stringify(this.state.illustration));
		// illustration[event.target.name] = event.target.value;
		// this.setState({ illustration: illustration });
	};

	private handleOKButtonClick = () => {
		// Notify the callback that the OK button has been clicked
		this.props.onOKButtonClick();
	};
}
