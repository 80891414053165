import React from "react";

import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { Form } from 'antd';

import { Input, Button, Checkbox } from "antd";

interface LoginFormProps {
	login: (username: string, password: string) => void;
	hasLoginFailed: boolean;
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
	const handleSubmit = (values: any) => {
		const login = (props as LoginFormProps).login;
		if (!login) return; 

		login(values.userName, values.password);
	};

	return (
		<Form onFinish={handleSubmit} className="login-form" initialValues={{ remember: true }}>
			<Form.Item
				name="userName"
				rules={[
					{
						required: true,
						message: "Please input your username!",
					},
				]}
				label="Username"
			>
				<Input
					prefix={
						<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
					}
					placeholder="Username"
				/>
			</Form.Item>
			<Form.Item
				name="password"
				rules={[
					{
						required: true,
						message: "Please input your Password!",
					},
				]}
				label="Password"
			>
				<Input
					prefix={
						<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
					}
					type="password"
					placeholder="Password"
				/>
			</Form.Item>
			{props.hasLoginFailed && (
				<div
					style={{
						color: "red",
						textAlign: "center",
						marginBottom: "1em",
					}}
				>
					Please enter a valid username and password
				</div>
			)
			}
			<Form.Item name="login" style={{ textAlign: "center", width: "100%" }}>
				<Button
					type="primary"
					htmlType="submit"
					className="login-form-button"
					style={{
						width: "100%",
						paddingTop: "1em",
						paddingBottom: "1em",
						height: "4em",
					}}
				>
					<h1 style={{ color: "#FFF", lineHeight: "1em" }}>
						Log in
					</h1>
				</Button>
			</Form.Item>
			<Form.Item name="remember" style={{ textAlign: "center", width: "100%" }}>
				<Checkbox>Remember me</Checkbox>
			</Form.Item >
		</Form >
	);

}

export default LoginForm;