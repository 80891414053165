/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { Divider, Modal, Table } from "antd";
import * as renderFunctionLib from "@lib/box/render-function";
const confirm = Modal.confirm;

interface RenderFunctionTableProps {
	renderFunctions: renderFunctionLib.RenderFunctionInfoMap | undefined,
	onEdit: (renderFunctionKey: string) => void,
	onDelete: (renderFunctionKey: string) => void,
};



const RenderFunctionTable: React.FC<RenderFunctionTableProps> = (props) => {
	if (!props.renderFunctions) {
		return null;
	}
	const attributeTableColumns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			render: (text: string, record: any) => {
				return <>
					{renderFunctionLib.TYPES[text] ? renderFunctionLib.TYPES[text].name : ""}
				</>
			}
		},
		{
			title: "Order",
			dataIndex: "order",
			key: "order",
		},


		{
			title: "Action",
			key: "action",
			render: (text: string, record: any) => (
				<span>
					<a onClick={() => {
						(props.onEdit(record.key))
					}}>
						Edit
					</a>
					<Divider type="vertical" />
					<a
						onClick={() => {
							confirm({
								title:
									"Are you sure delete this Render Function?",
								okText: "Yes",
								okType: "danger",
								cancelText: "No",
								zIndex: 9999999,
								onOk: () => { props.onDelete(record.key) },
								onCancel: () => { },
							});
						}}
					>
						Delete
					</a>
				</span>
			),
		},
	];

	const dataSource = Object.keys(props.renderFunctions).map(
		(key: string) => {
			const data = props.renderFunctions![
				key
			];
			return {
				key: key,
				name: data.name,
				type: data.type,
				order: data.order,
			};
		});

	return <Table
		dataSource={dataSource}
		pagination={false}
		columns={attributeTableColumns}
	/>
}

export default RenderFunctionTable;