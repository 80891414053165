import { useEffect, useState } from "react";
import { BoxTypeExpression } from "../AddEditBoxTypeExpressionDialog";
import * as lensesLib from "@lib/lenses/lenses";

const useBoxTypeQueryRestrictions = (query: lensesLib.Query) => {
	// State derived from the query
	const [boxTypeExpressionOperator, setBoxTypeExpressionOperator] = useState(query.boxTypeExpressionOperator);
	const [boxTypeExpressions, setBoxTypeExpressions] = useState(query.boxTypeExpressions);
	const [applyBoxTypeVisibility, setApplyBoxTypeVisibility] = useState(query.applyBoxTypeVisibility);
	const [boxTypeAreBoxesInLayout, setBoxTypeAreBoxesInLayout] = useState(query.boxTypeAreBoxesInLayout);
	const [boxTypeAreBoxesVisible, setBoxTypeAreBoxesVisible] = useState(query.boxTypeAreBoxesVisible);
	const [boxTypeIsVisible, setBoxTypeIsVisible] = useState(query.boxTypeIsVisible);

	// Add/Edit Box Type Expression Form Variables
	const [isAddEditBoxTypeExpressionDialogVisible, setIsAddEditBoxTypeExpressionDialogVisible] = useState(false);
	const [isAddEditBoxTypeExpressionDialogAdding, setIsAddEditBoxTypeExpressionDialogAdding] = useState(false);
	const [addEditBoxTypeExpression, setAddEditBoxTypeExpression] = useState<BoxTypeExpression | null>(null);

	// Initialise all values when the query is updated
	useEffect(() => {
		// Reset the query values
		setBoxTypeExpressionOperator(query.boxExpressionOperator);
		setBoxTypeExpressions(query.boxTypeExpressions);
		setApplyBoxTypeVisibility(query.applyBoxTypeVisibility);
		setBoxTypeAreBoxesInLayout(query.boxTypeAreBoxesInLayout);
		setBoxTypeAreBoxesVisible(query.boxTypeAreBoxesVisible)
		setBoxTypeIsVisible(query.boxTypeIsVisible);

		// Reset the form values		
		setIsAddEditBoxTypeExpressionDialogVisible(false);
		setIsAddEditBoxTypeExpressionDialogAdding(false);
		setAddEditBoxTypeExpression(null);

	}, [query]);


	const onBoxTypeExpressionOperatorChanged = (value: lensesLib.BooleanOperator) => {
		setBoxTypeExpressionOperator(value);
	};

	const onOpenAddBoxTypeExpressionForm = () => {
		setIsAddEditBoxTypeExpressionDialogAdding(false);
		setAddEditBoxTypeExpression(null);
		setIsAddEditBoxTypeExpressionDialogVisible(true);
	}

	const onOpenEditBoxTypeExpressionForm = (key: string) => {
		if (boxTypeExpressions !== undefined) {
			setAddEditBoxTypeExpression({ key, value: boxTypeExpressions[key] })
			setIsAddEditBoxTypeExpressionDialogAdding(false);
			setIsAddEditBoxTypeExpressionDialogVisible(true);
		}
	}

	const onCloseAddEditBoxTypeExpressionForm = () => {
		setIsAddEditBoxTypeExpressionDialogVisible(false);
		setIsAddEditBoxTypeExpressionDialogAdding(false);
		setAddEditBoxTypeExpression(null);
	};

	const onAddEditBoxTypeExpressionFormOk = (boxTypeExpression: BoxTypeExpression) => {
		const newBoxTypeExpressions = boxTypeExpressions !== undefined && boxTypeExpressions !== null ? JSON.parse(JSON.stringify(boxTypeExpressions)) : {};

		// If we have changed the key, delete the old value)
		if (addEditBoxTypeExpression !== null && addEditBoxTypeExpression.key !== boxTypeExpression.key) {
			delete newBoxTypeExpressions[addEditBoxTypeExpression.key];
		}

		newBoxTypeExpressions[boxTypeExpression.key] = boxTypeExpression.value;
		setBoxTypeExpressions(newBoxTypeExpressions);
		setIsAddEditBoxTypeExpressionDialogVisible(false);
		setAddEditBoxTypeExpression(null);
	};


	const onDeleteBoxTypeExpression = (key: string) => {
		const newBoxTypeExpressions = JSON.parse(JSON.stringify(boxTypeExpressions));
		delete newBoxTypeExpressions[key];
		setBoxTypeExpressions(newBoxTypeExpressions)
	};

	const onApplyBoxTypeVisibilityChanged = (value: boolean) => {
		setApplyBoxTypeVisibility(value);
	};

	const onApplyBoxTypeIsVisibleChanged = (value: boolean) => {
		setBoxTypeIsVisible(value);
	};

	const onBoxTypeAreBoxesVisibleChanged = (value: boolean) => {
		setBoxTypeAreBoxesVisible(value);
	};

	const onBoxTypeAreBoxesInLayoutChanged = (value: boolean) => {
		setBoxTypeAreBoxesInLayout(value);
	};

	return {
		boxTypeExpressions,
		isAddEditBoxTypeExpressionDialogVisible,
		isAddEditBoxTypeExpressionDialogAdding,
		addEditBoxTypeExpression,
		boxTypeAreBoxesInLayout,
		boxTypeAreBoxesVisible,
		applyBoxTypeVisibility,
		boxTypeExpressionOperator,
		boxTypeIsVisible,

		onApplyBoxTypeVisibilityChanged,
		onBoxTypeExpressionOperatorChanged,
		onBoxTypeAreBoxesVisibleChanged,
		onBoxTypeAreBoxesInLayoutChanged,
		onApplyBoxTypeIsVisibleChanged,
		
		onCloseAddEditBoxTypeExpressionForm,
		onOpenEditBoxTypeExpressionForm,
		onAddEditBoxTypeExpressionFormOk,
		onDeleteBoxTypeExpression,		
		onOpenAddBoxTypeExpressionForm,
	};
};



export default useBoxTypeQueryRestrictions;