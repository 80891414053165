import { DeleteOutlined } from "@ant-design/icons";
import { BoxTypeMap } from "@lib/box/box-type";
import { Button } from "antd";
import React from "react";
import { BoxTypeList } from "../../../molecules/BoxTypeList";
import RightMenuHeading from "../../../organisms/RightMenuHeading";
import RightMenuHeadingText from "../../../organisms/RightMenuHeadingText";
export interface AssociationsMenuProps {
	canDeleteTypes: boolean
	onBoxTypeDelete: (boxTypeKey: string) => void,
	boxTypeMap: BoxTypeMap | undefined,
	onDeleteAllAssociations: () => void

}

const AssociationsMenu: React.FC<AssociationsMenuProps> = (props) => {
	return <><RightMenuHeading>
		<RightMenuHeadingText>Associations</RightMenuHeadingText>
		<Button
			icon={<DeleteOutlined />}
			danger
			disabled={!(props.canDeleteTypes)}
			type="primary"
			style={{
				paddingRight: "12px",
			}}
			onClick={props.onDeleteAllAssociations}
		>
			Delete All
		</Button>
	</RightMenuHeading>
		<div
			style={{
				padding: "12px",
			}}
		>
			<BoxTypeList
				onBoxTypeEdit={
					() => { }
				}
				onBoxTypeDelete={
					props
						.onBoxTypeDelete
				}
				boxTypeMap={
					props.boxTypeMap
				}
				canEdit={
					false
				}
				canDelete={
					props.canDeleteTypes
				}
			/>
		</div>
	</>
};

export default AssociationsMenu;