import { Project } from '@contracts/project';
import projectService from '@services/project';
import { Button, Table } from 'antd';
import React, { useEffect, useState } from 'react';
export interface ProjectListProps {
	clientId: string | null;
	onSelectProject : (project : Project) => void;
};

const ProjectList: React.FC<ProjectListProps> = (props) => {
	const [data, setData] = useState<Project[]>([]);

	useEffect(() => {
		if (props.clientId) {
			projectService.getListByClientId(props.clientId, (illustrations) => {
				if (illustrations) {
					setData(illustrations);
				}
			});
		} 
		else {
			setData([]);
		}
	}, [props.clientId]);



	const columns = [{
		title: 'Name',
		dataIndex: 'name',
		key: '_id',
		render: (text: any, record: any) => {
			return <b>{text}</b>;
		},
	}, {
		title: 'Action',
		key: 'action',
		render: (text: any, record: any) => {
			return (
				<Button size="large" type="primary" onClick={()=>{props.onSelectProject(record)}}>Select</Button>
			);
		},
	}];



	return <Table
		columns={columns}
		pagination={false}
		rowKey="_id"
		dataSource={data}
	/>
}

export default ProjectList;