import * as React from "react";

import { Modal, Input } from "antd";

import * as visibilityStateLib from "@lib/box/visibility-state";

export interface AddEditVisibilityStateDialogProps {
	onOKButtonClick: (
		visibilityState: visibilityStateLib.VisibilityState,
		visibilityStateKey: string
	) => void;
	onCancelButtonClick: () => void;
	isAdding: boolean;
	isVisible: boolean;
	visibilityStateKey: string;
	visibilityStateMap: visibilityStateLib.VisibilityStateMap | undefined;
	currentVisibilityState: visibilityStateLib.VisibilityState;
}

interface AddEditVisibilityStateDialogState {
	visibilityState: visibilityStateLib.VisibilityState | undefined;
}

const getVisibilityState = (
	visibilityStateKey: string,
	visibilityStateMap: visibilityStateLib.VisibilityStateMap | undefined,
	currentVisibilityState: visibilityStateLib.VisibilityState,
	isAdding: boolean
): visibilityStateLib.VisibilityState => {
	// Are we adding or we don't have a visibility state map?
	if (isAdding || visibilityStateMap === undefined) {
		// Create a new visibility state based on the current one
		const visibilityState = JSON.parse(
			JSON.stringify(currentVisibilityState)
		);
		visibilityState.name = "New";

		return visibilityState;
	}

	// Otherwise, try and retrieve an existing visibility state
	return visibilityStateMap[visibilityStateKey];
};

export class AddEditVisibilityStateDialog extends React.Component<
	AddEditVisibilityStateDialogProps,
	AddEditVisibilityStateDialogState
> {
	constructor(props: AddEditVisibilityStateDialogProps) {
		super(props);

		// Get the visibility state
		const visibilityState = getVisibilityState(
			props.visibilityStateKey,
			props.visibilityStateMap,
			props.currentVisibilityState,
			props.isAdding
		);

		// Set up our state
		this.state = {
			visibilityState,
		};
	}

	public componentWillReceiveProps(
		nextProps: AddEditVisibilityStateDialogProps
	) {
		// Only update if we're showing the dialog
		if (!this.props.isVisible && nextProps.isVisible) {
			// Get the visibility state
			const visibilityState = getVisibilityState(
				nextProps.visibilityStateKey,
				nextProps.visibilityStateMap,
				nextProps.currentVisibilityState,
				nextProps.isAdding
			);

			// Update our state
			this.setState({
				visibilityState,
			});
		}
	}

	public render() {
		// If we don't have a visibility state, don't render anything
		if (!this.state.visibilityState) {
			return null;
		}

		// Get the title
		const title = this.props.isAdding ? "Add Story" : "Edit Story";

		return (
			<Modal
				title={title}
				open={this.props.isVisible}
				onOk={this.handleOKButtonClick}
				onCancel={this.props.onCancelButtonClick}
				zIndex={9999}
				width="75%"
			>
				<div>
					<Input
						addonBefore={"Name"}
						value={this.state.visibilityState.name}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>
						) => this.handleVisibilityStateNameChange(event)}
					/>
					<Input
						addonBefore={"UUID"}
						value={this.props.visibilityStateKey}
						readOnly
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>
						) => {}}
					/>
				</div>
			</Modal>
		);
	}

	private handleOKButtonClick = () => {
		// console.log('handleOKButtonClick');
		// console.log(this.state.box);

		// Notify the callback that the OK button has been clicked, passing the new
		// visibility state (if we have one)
		if (this.state.visibilityState) {
			this.props.onOKButtonClick(
				this.state.visibilityState,
				this.props.visibilityStateKey
			);
		}
	};

	private handleVisibilityStateNameChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		// Get the new visibility state name
		// TODO: Disable the OK button if the visibility state name is empty
		const visibilityStateName = event.target.value;

		// Get a copy of the visibility state
		const updatedVisibilityState = JSON.parse(
			JSON.stringify(this.state.visibilityState)
		);

		// Set the new name of the visibility state
		updatedVisibilityState.name = visibilityStateName;

		// Update the state with the new visibility state name
		this.setState({
			visibilityState: updatedVisibilityState,
		});
	};
}
