import React from "react";
import LoginForm from "../../organisms/LoginForm";
import config from "../../../config";

export interface LoginPageProps {
	login: (username: string, password: string) => void;
	hasLoginFailed: boolean;
}

export class LoginPage extends React.Component<LoginPageProps> {
	public render() {
		console.log("Login");

		return (
			<div
				style={{
					padding: "16px",
					backgroundColor: "#FFFFFF",
					width: "100%",
					height: "100%",
				}}
			>
				<div
					style={{
						//height: "",
						width: "775px",
						height: "356px",
						backgroundImage: `url('${config.loginImage}')`,
						backgroundSize: "775px 356px",
						backgroundRepeat: "no-repeat",
						color: "#FFFFFF",
						marginLeft: "auto",
						marginRight: "auto",
						marginTop: 16,
						marginBottom: 0,
					}}
				>
					&nbsp;
				</div>
				<div
					style={{
						width: "384px",
						marginLeft: "auto",
						marginRight: "auto",
					}}
				>
					<LoginForm
						login={this.props.login}
						hasLoginFailed={this.props.hasLoginFailed}
					/>
				</div>
			</div>
		);
	}
}
