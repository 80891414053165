import * as React from "react";

import { Divider, Modal, Table } from "antd";

import * as lensesLib from "@lib/lenses/lenses";
import * as boxLib from "@lib/box/box";
import Link from "antd/lib/typography/Link";

const confirm = Modal.confirm;

interface BoxExpressionTableProps {
	onEdit: (key: string) => void;
	onDelete: (key: string) => void;
	boxExpressions: lensesLib.BoxExpressionMap | undefined;
	boxes: boxLib.BoxMap;
	canEdit: boolean;
	canDelete: boolean;
}

interface BoxRow {
	key: string;
	name: string;
	value: string;
}

const BoxExpressionTable : React.FC<BoxExpressionTableProps> = (props) => {
	// Build the box type list items

	const handleEdit = (index: string) => {
		// Notify the callback that the box type should be edited
		props.onEdit(index);
	};

	const handleDelete = (key: string) => {
		// Notify the callback that the box type should be removed
		props.onDelete(key);
	};

	const buildRows = (
		map: lensesLib.BoxTypeExpressionMap | undefined
	) : BoxRow[] => {
		// If we don't have a box map there's no list items
		if (!map) {
			return [];
		}

		// Render each of the box types
		const rows = Object.keys(map).map(
			(key, index) => {
				const boxExpression = map[key];
				// Get the box
				// Build the box list item
				const row: BoxRow = {
					key: key,
					name: props.boxes[key].name,
					value: boxExpression
				};

				return row;
			}
		);

		return rows;
	};

	const queries: BoxRow[] = buildRows(
		props.boxExpressions
	);

	const queryTableColumns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Value",
			dataIndex: "value",
			key: "value",
		},
		{
			title: "Action",
			key: "action",
			render: (text: string, record: BoxRow) => (
				<span>
					<Link onClick={() => {
						(handleEdit(record.key))
					}}>
						Edit
					</Link>
					<Divider type="vertical" />
					<Link
						onClick={() => {
							confirm({
								title:
									"Are you sure delete this Box Expression?",
								okText: "Yes",
								okType: "danger",
								cancelText: "No",
								zIndex: 9999999,
								onOk: () => { handleDelete(record.key) },
								onCancel: () => { },
							});
						}}
					>
						Delete
					</Link>
				</span>
			),
		},
	];

	return (
		<Table
			dataSource={queries}
			pagination={false}
			columns={queryTableColumns}
		/>
	);
}

export default BoxExpressionTable;