import { ModelService, makeModelService } from './model-service';
import { User } from '@contracts/user';

const entityName = 'users';

export interface UserService extends ModelService<User, User, User, User> {
}

const genericService = makeModelService<User, User, User, User>(entityName);

const userService: UserService = { ...genericService };

export default userService;