import React, { useEffect, useState } from "react";

import { Modal, Input, Button, InputNumber, Tree, Select, Checkbox } from "antd";

import FormHeading from "@components/organisms/FormHeading";
import FormHeadingText from "@components/organisms/FormHeadingText";
import LabelledElement from "@components/molecules/LabelledElement";
import * as lensesLib from "@lib/lenses/lenses";
// import AddEditPowerLensDialog from "./add-edit-simple-lens-dialog/AddEditPowerLensDialog";
import { v4 as uuid } from "uuid";
import TextArea from "antd/lib/input/TextArea";
import { DataNode } from "antd/lib/tree";
import { Key } from "react";
import * as boxLib from "@lib/box/box";
import * as boxTypeLib from "@lib/box/box-type";

export interface AddBoxTypeVisibilityPowerLensQueryDialogProps {
	onOKButtonClick: (query: lensesLib.Query) => void;
	onCancelButtonClick: (queryType: lensesLib.QueryType) => void;
	isVisible: boolean;
	isAdding: boolean;
	query: lensesLib.Query;
	flattenedBoxMap: boxLib.BoxMap;
	boxTypeMap: boxTypeLib.BoxTypeMap;
}

const getBoxTypeFromQuery = (query: lensesLib.Query): string | undefined => {
	let value = undefined;

	if (query.boxTypeExpressions) {
		const keys = Object.keys(query.boxTypeExpressions);

		if (keys.length > 0) {
			value = keys[0];
		}
	}

	return value;	
};

const getAttributeTypeFromQuery = (query: lensesLib.Query): string | undefined => {
	return  query.attributeExpression ? query.attributeExpression.attributeType : undefined;
};

const getOperatorFromQuery = (query: lensesLib.Query): lensesLib.AttributeExpressionOperator => {
	return  query.attributeExpression ? query.attributeExpression.operator : lensesLib.AttributeExpressionOperator.EQUAL;
};


const getAttributeValueFromQuery = (query: lensesLib.Query): string => {
	return  query.attributeExpression ? query.attributeExpression.attributeValue.toString() : "";
};

const AddBoxTypeVisibilityPowerLensQueryDialog: React.FC<AddBoxTypeVisibilityPowerLensQueryDialogProps> = (props) => {
	// Get the title
	const title = `${props.isAdding ? "Add" : "Edit"} Box Type Query`;

	const [name, setName] = useState(props.query.name);
	const [boxTypeAreBoxesInLayout, setBoxTypeAreBoxesInLayout] = useState(props.query.boxTypeAreBoxesInLayout);
	const [boxTypeAreBoxesVisible, setBoxTypeAreBoxesVisible] = useState(props.query.boxTypeAreBoxesVisible);
	const [boxTypeIsVisible, setBoxTypeIsVisible] = useState(props.query.boxTypeIsVisible);

	const [boxTypeKey, setBoxTypeKey] = useState<string | undefined>(getBoxTypeFromQuery(props.query));

	// Add the useEffect to update stuff from props
	// Base it on the object and the key coming in.

	useEffect(() => {
		const copiedObject: lensesLib.Query = JSON.parse(JSON.stringify(props.query));
		setName(copiedObject.name);
		setBoxTypeAreBoxesInLayout(copiedObject.boxTypeAreBoxesInLayout);
		setBoxTypeAreBoxesVisible(copiedObject.boxTypeAreBoxesVisible);
		setBoxTypeIsVisible(copiedObject.boxTypeIsVisible);
		setBoxTypeKey(getBoxTypeFromQuery(props.query));
	}, [props.query, props.isVisible]);

	// Configure the state
	const handleOKButtonClick = () => {

		if (boxTypeKey !== undefined) {
			const copiedQuery: lensesLib.Query = JSON.parse(JSON.stringify(props.query));
			copiedQuery.name = name;
			copiedQuery.type = lensesLib.QueryType.BOX_TYPE;
			copiedQuery.boxTypeExpressions = {};
			copiedQuery.boxTypeExpressions[boxTypeKey] = lensesLib.EqualityOperator.EQUAL;
			copiedQuery.applyBoxTypeVisibility = true;
			copiedQuery.boxTypeAreBoxesInLayout = boxTypeAreBoxesInLayout;
			copiedQuery.boxTypeAreBoxesVisible = boxTypeAreBoxesVisible;
			copiedQuery.boxTypeIsVisible = boxTypeIsVisible;
			copiedQuery.applyBoxVisibility = true;
			copiedQuery.boxIsInLayout = true;

			copiedQuery.boxIsVisible = true;


			props.onOKButtonClick(copiedQuery);
		}
	};

	const boxTypesArray = props.boxTypeMap === undefined ? [] : Object.keys(props.boxTypeMap)
		.map((boxTypeKey) => {
			return {
				key: boxTypeKey,
				name: props.boxTypeMap !== undefined &&
					props.boxTypeMap[boxTypeKey] !== undefined ?
					props.boxTypeMap[boxTypeKey].name : ""
			}
		})
		.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}

			if (a.name > b.name) {
				return 1;
			}

			return 0;
		});

	const okButtonDisabled = !boxTypeKey;


	return <Modal
		title={title}
		open={props.isVisible}
		onOk={handleOKButtonClick}
		okButtonProps={{disabled: okButtonDisabled}}
		onCancel={() => { props.onCancelButtonClick(lensesLib.QueryType.ATTRIBUTE); }}
		zIndex={9999}
		width="75%"
	>
		<div>
			<div
				style={{
					width: "100%",
					display: "grid",
					gridTemplateColumns: "0.1fr 0.9fr",
					gap: "4px 12px",
					padding: 0,
					margin: 0,
					overflowY: "visible",
				}}
			>
				<LabelledElement labelText="Name">
					<Input

						value={name}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>
						) => { setName(event.target.value) }}
					/>
				</LabelledElement>
				<LabelledElement labelText="Box Type">
					<Select
						key={`input-operator`}
						size="large"
						placeholder="Please select"
						value={boxTypeKey ? boxTypeKey : ""}
						onChange={(newKey: string) => {
							setBoxTypeKey(newKey !== "" ? newKey : undefined);
						}}
						style={{ width: "100%" }}
						getPopupContainer={(node) => {
							let popupContainer: HTMLElement | null =
								window.document.documentElement;
							if (node && node.parentElement) {
								popupContainer = node.parentElement;
							}
							return popupContainer as HTMLElement;
						}}
					>
						<Select.Option key="" value="">- Please select -</Select.Option>

						{
							boxTypesArray.map((attributeType, index) => {
								return <Select.Option key={attributeType.key} value={attributeType.key}>{attributeType.name}</Select.Option>
							})
						}
					</Select>
				</LabelledElement>
				<LabelledElement labelText="Show Box Type">
					<Checkbox checked={boxTypeIsVisible} onChange={(e) => setBoxTypeIsVisible(e.target.checked)} />

				</LabelledElement>
				<LabelledElement labelText="Show Boxes">
					<Checkbox checked={boxTypeAreBoxesVisible} onChange={(e) => setBoxTypeAreBoxesVisible(e.target.checked)} />

				</LabelledElement>
				<LabelledElement labelText="Keep Hidden Boxes In Illustration">
					<Checkbox checked={boxTypeAreBoxesInLayout} onChange={(e) => setBoxTypeAreBoxesInLayout(e.target.checked)}/>
				</LabelledElement>

			</div>
		</div>
	</Modal>
}

export default AddBoxTypeVisibilityPowerLensQueryDialog;