import * as React from "react";

import { CloseOutlined } from "@ant-design/icons";

export interface BoxButtonRemoveProps {
	onMouseEnter: (event: React.MouseEvent<HTMLDivElement>) => void;
	onMouseLeave: (event: React.MouseEvent<HTMLDivElement>) => void;
	onClick: (event: React.MouseEvent<HTMLDivElement>) => void;

	canRemove: boolean;
	isRemoveBoxButtonHovered: boolean;

	buttonSizeInPixels: number;
	buttonBorderRadiusInPixels: number;
	buttonOffsetInPixels: number;
}

export const BoxButtonRemove = React.forwardRef((props: BoxButtonRemoveProps,
	ref: React.ForwardedRef<HTMLDivElement>) => {
	const {
		onMouseEnter,
		onMouseLeave,
		onClick,
		canRemove,
		isRemoveBoxButtonHovered,
		buttonSizeInPixels,
		buttonBorderRadiusInPixels,
		buttonOffsetInPixels
	} = props;

	if (!canRemove) {
		return null
	}

	// Determine the Remove Box button text and background color
	const removeBoxTextColor = isRemoveBoxButtonHovered
		? "#FFF"
		: "#FFF";
	const removeBoxBackgroundColor = isRemoveBoxButtonHovered
		? "#FF0000"
		: "#7F0000";

	// Render the Remove Box button in the same position as the default badge
	return (
		<div
			ref={ref}
			style={{
				display: "inline",
				position: "absolute",
				top: -buttonOffsetInPixels,
				right: -buttonOffsetInPixels,
				width: buttonSizeInPixels,
				height: buttonSizeInPixels,
				backgroundColor: removeBoxBackgroundColor,
				borderRadius: buttonBorderRadiusInPixels,
				textAlign: "center",
				paddingTop: 5,
				paddingLeft: 1,
			}}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={onClick}
		>
			<CloseOutlined
				style={{
					color: removeBoxTextColor,
				}}
			/>
		</div>
	);
});