import React from "react";
import {
	DeleteOutlined,
	EditOutlined,
	FileAddOutlined,
} from "@ant-design/icons";
import {
	Table,
	Modal,
	Divider,
	Row,
	Col,
	Card,
	Layout,
	notification
} from "antd";

import { ExclamationCircleOutlined } from '@ant-design/icons';

import EditProjectDialog from "./AddEditProjectDialog";
import AddEditIllustrationDialog from "../../illustration/AddEditIllustrationDialog";

import * as authorizationService from "../../../../services/authorization";
import clientService from "../../../../services/client";
import projectService from "../../../../services/project";
import illustrationService from "../../../../services/illustration";

import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Illustration } from "@contracts/illustration";
import { Client } from "@contracts/client";
import { Project } from "@contracts/project";

import { renderBreadcrumbsInPortal } from "../../../organisms/Breadcrumbs";

import { withRouter } from '../../../../routes/withRouter';

const confirm = Modal.confirm;
export interface ViewProjectPageProps {
	match: {
		params: {
			clientId: string;
			projectId: string;
		};
	};
	history: any;
	abilities: authorizationService.AbilitiesMap | undefined;
}

export interface ViewProjectPageState {
	project: Project | null;
	client: Client | null;
	illustrations: Illustration[];
	isEditProjectModalVisible: boolean;
	isEditIllustrationModalVisible: boolean;
	isAddingIllustration: boolean;
	modalName: string;
	modalDescription: string;
	modalIllustrationId: string;
}

class ViewProjectPageClass extends React.Component<
	ViewProjectPageProps,
	ViewProjectPageState
> {
	constructor(props: ViewProjectPageProps) {
		super(props);
		this.state = {
			illustrations: [],
			client: null,
			project: null,
			isEditProjectModalVisible: false,
			isEditIllustrationModalVisible: false,
			modalDescription: "",
			modalName: "",
			modalIllustrationId: "",
			isAddingIllustration: false
		};

		this.getClientId = this.getClientId.bind(this);
		this.getProjectId = this.getProjectId.bind(this);
	}

	getProjectId() {
		return this.props.match.params.projectId;
	}

	getClientId() {
		return this.props.match.params.clientId;
	}

	refreshIllustrationList = (projectId: string) => {
		// Get the client ID
		const clientId = this.getClientId();

		if (
			!authorizationService.isAbilityPermitted(
				this.props.abilities,
				authorizationService.AbilityType.ProjectView,
				clientId
			)
		) {
			return;
		}

		illustrationService.getListByProjectId(projectId, (illustrations) => {
			if (illustrations) {
				// Get the client ID
				const clientId = this.getClientId();

				// Filter out illustrations that can't be viewed
				const permittedIllustrations = illustrations.filter(
					(illustration: Illustration) => {
						// Can we view the illustration?
						return authorizationService.isAbilityPermitted(
							this.props.abilities,
							authorizationService.AbilityType.IllustrationView,
							clientId
						);
					}
				);

				this.setState({ illustrations: permittedIllustrations });
			}
		});
	};

	columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (text: any, record: any): ReactNode => {
				return (
					<Link
						to={
							"/clients/" +
							this.getClientId() +
							"/projects/" +
							this.getProjectId() +
							"/illustrations/" +
							record._id
						}
					>
						{text}
					</Link>
				);
			},
		},
		{
			title: "Action",
			key: "action",
			render: (text: any, record: any) => {
				// Get the client ID
				const clientId = this.getClientId();

				return (
					<span>
						{authorizationService.renderIfAbilityPermitted(
							this.props.abilities,
							authorizationService.AbilityType.IllustrationEdit,
							clientId,
							() => (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								<a
									onClick={(e) => {
										this.handleEditIllustrationClick(
											record,
											e
										);
									}}
								>
									Edit
								</a>
							)
						)}
						{authorizationService.renderIfAllAbilitiesPermitted(
							this.props.abilities,
							[
								authorizationService.AbilityType
									.IllustrationEdit,
								authorizationService.AbilityType
									.IllustrationDelete,
							],
							clientId,
							() => (
								<Divider type="vertical" />
							)
						)}
						{authorizationService.renderIfAbilityPermitted(
							this.props.abilities,
							authorizationService.AbilityType.IllustrationDelete,
							clientId,
							() => (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								<a
									onClick={(e) => {
										this.handleRemoveIllustrationClick(
											record,
											e
										);
									}}
								>
									Delete
								</a>
							)
						)}
					</span>
				);
			},
		},
	];

	componentDidMount() {
		let clientId = this.getClientId();

		if (
			!authorizationService.isAbilityPermitted(
				this.props.abilities,
				authorizationService.AbilityType.ProjectView,
				clientId
			)
		) {
			return;
		}

		let projectId = this.getProjectId();

		if (projectId) {
			projectService.get(projectId, (project) => {
				if (project) {
					this.setState({ project: project });

					if (project.clientId) {
						clientService.get(
							project.clientId,
							(result: Client | null) => {
								if (result) {
									this.setState({ client: result });
								}
							}, (message) => {
								console.log(`Error occurred: ${message}.`);
								notification.open({
									message: 'Error: Could not get Client',
									description:
										`Failed to get Client Id ${project.clientId}.`,
									icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
								});
							}
						);
					}
				}
			}, (message) => {
				console.log(`Error occurred: ${message}.`);
				notification.open({
					message: 'Error: Could not get Project',
					description:
						`Failed to get Project Id ${projectId}.`,
					icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
				});
			});

			this.refreshIllustrationList(projectId);
		} else {
			console.log("no project Id");
		}
	}

	private renderBreadcrumbs = () => {
		const BREADCRUMBS = [
			{
				onGetPath: (params: any) => "/",
				onGetDisplayName: (params: any) => "Clients",
			},
			{
				onGetPath: (params: any) => `/clients/${params.clientId}`,
				onGetDisplayName: (params: any) =>
					this.state.client ? this.state.client.name : "",
			},
			{
				onGetPath: (params: any) =>
					`/clients/${params.clientId}#projects`,
				onGetDisplayName: (params: any) => "Projects",
			},
			{
				onGetPath: (params: any) =>
					`/clients/${params.clientId}/projects/${params.projectId}`,
				onGetDisplayName: (params: any) =>
					this.state.project ? this.state.project.name : "",
			},
		];

		return renderBreadcrumbsInPortal(BREADCRUMBS, this.props.match.params);
	};

	public render() {
		// Get the client ID
		const clientId = this.getClientId();

		// Determine the project details actions based on permissions
		const projectDetailsActions = [];
		if (
			authorizationService.isAbilityPermitted(
				this.props.abilities,
				authorizationService.AbilityType.ProjectDetailsEdit,
				clientId
			)
		) {
			projectDetailsActions.push(
				<EditOutlined onClick={this.handleEditProjectClick} />
			);
		}

		if (
			authorizationService.isAbilityPermitted(
				this.props.abilities,
				authorizationService.AbilityType.ProjectDetailsDelete,
				clientId
			)
		) {
			projectDetailsActions.push(
				<DeleteOutlined onClick={this.handleRemoveProjectClick} />
			);
		}

		// Determine the illustration actions based on permissions
		const illustrationActions = authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.IllustrationAdd,
			clientId
		)
			? [<FileAddOutlined onClick={this.handleAddIllustrationClick} />]
			: [];

		// Get the project details
		const projectName = this.state.project ? this.state.project.name : "";
		const projectDescription = this.state.project
			? this.state.project.description
			: "";
		const projectCreatedAt = this.state.project
			? this.state.project.createdAt
			: "";
		const projectUpdatedAt = this.state.project
			? this.state.project.updatedAt
			: "";

		const breadcrumbs = this.renderBreadcrumbs();

		return (
			<Layout
				style={{
					width: "100%",
					height: "100%",
				}}
			>
				{breadcrumbs}
				<Layout.Content>
					<Row>
						<Col span={6}>
							<Card
								title="Project Details"
								style={{ margin: 16 }}
								actions={projectDetailsActions}
							>
								{this.state.project
									? authorizationService.renderIfAbilityPermitted(
										this.props.abilities,
										authorizationService.AbilityType
											.ProjectDetailsView,
										clientId,
										() => (
											<div>
												<Row gutter={6}>
													<Col span={24}>
														<span
															style={{
																color:
																	"rgba(0, 0, 0, 0.45)",
															}}
														>
															Name
														</span>
														<br />
														<span
															style={{
																color:
																	"rgba(0, 0, 0, 0.85)",
															}}
														>
															{projectName
																? projectName
																: " "}
														</span>
														<br />
														<br />
													</Col>
												</Row>
												<Row gutter={6}>
													<Col span={24}>
														<span
															style={{
																color:
																	"rgba(0, 0, 0, 0.45)",
															}}
														>
															Description
														</span>
														<br />
														<span
															style={{
																color:
																	"rgba(0, 0, 0, 0.85)",
															}}
														>
															{projectDescription
																? projectDescription
																: " "}
														</span>
														<br />
														<br />
													</Col>
												</Row>
												<Row gutter={6}>
													<Col span={24}>
														<span
															style={{
																color:
																	"rgba(0, 0, 0, 0.45)",
															}}
														>
															Created At
														</span>
														<br />
														<span
															style={{
																color:
																	"rgba(0, 0, 0, 0.85)",
															}}
														>
															{projectCreatedAt
																? new Date(
																	projectCreatedAt
																).toLocaleString(
																	"en-AU"
																)
																: " "}
														</span>
														<br />
														<br />
													</Col>
												</Row>
												<Row gutter={6}>
													<Col span={24}>
														<span
															style={{
																color:
																	"rgba(0, 0, 0, 0.45)",
															}}
														>
															Updated At
														</span>
														<br />
														<span
															style={{
																color:
																	"rgba(0, 0, 0, 0.85)",
															}}
														>
															{projectUpdatedAt
																? new Date(
																	projectUpdatedAt
																).toLocaleString(
																	"en-AU"
																)
																: " "}
														</span>
														<br />
														<br />
													</Col>
												</Row>
											</div>
										)
									)
									: null}
							</Card>
						</Col>

						<Col span={18}>
							<Card
								title="Illustrations"
								style={{
									marginTop: 16,
									marginBottom: 16,
									marginRight: 16,
									marginLeft: 0,
								}}
								actions={illustrationActions}
							>
								<Table
									columns={this.columns}
									pagination={false}
									rowKey="_id"
									dataSource={this.state.illustrations}
								/>
							</Card>
						</Col>
					</Row>

					<EditProjectDialog
						onOKButtonClick={this.handleEditProjectModalOK}
						onCancelButtonClick={this.handleEditProjectCancel}
						isVisible={this.state.isEditProjectModalVisible}
						project={this.state.project}
					/>

					{this.state.project ? (
						<AddEditIllustrationDialog
							illustrationId={this.state.modalIllustrationId}
							isAdding={this.state.isAddingIllustration}
							onAddOkButtonClick={this.handleAddIllustrationModalOK}
							onEditOkButtonClick={this.handleEditIllustrationModalOK}
							onCancelButtonClick={
								this.handleEditIllustrationCancel
							}
							isVisible={
								this.state.isEditIllustrationModalVisible
							}
							name={this.state.modalName}
							description={this.state.modalDescription}
							projectId={this.state.project._id}
						/>
					) : null}
				</Layout.Content>
			</Layout>
		);
	}
	private handleEditProjectModalOK = (editedProject: any) => {
		projectService.put(editedProject._id, editedProject, (res: any) => {
			projectService.get(editedProject._id, (returnedProject: any) => {
				// Update our state and close the box modal dialog
				this.setState({
					project: returnedProject,
					isEditProjectModalVisible: false,
				});
			}, (message) => {
				console.log(`Error occurred: ${message}.`);
				notification.open({
					message: 'Error: Could not get Project',
					description:
						`Failed to get Project Id ${editedProject._id}.`,
					icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
				});
			});
		}, (message) => {
			console.log(`Error occurred: ${message}.`);
			notification.open({
				message: 'Error: Could not edit Project',
				description:
					`Failed to edit Project Id ${editedProject._id}.`,
				icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
			});
		});
	};

	private handleEditProjectCancel = () => {
		// Close the box modal dialog
		this.setState({
			isEditProjectModalVisible: false,
		});
	};

	private handleEditProjectClick = () => {
		// Open the box modal dialog
		this.setState({
			isEditProjectModalVisible: true,
		});
	};

	private handleRemoveIllustrationClick = (illustration: any, e: any) => {
		confirm({
			title: "Are you sure delete this illustration?",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				illustrationService.remove(illustration._id, (data: any) => {
					this.refreshIllustrationList(this.getProjectId());
				}, (message) => {
					console.log(`Error occurred: ${message}.`);
					notification.open({
						message: 'Error: Could not remove Illustration',
						description:
							`Failed to remove Illustration Id ${illustration._id}.`,
						icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
					});
				});
			},
			onCancel: () => { },
		});
	};

	private handleRemoveProjectClick = () => {
		confirm({
			title: "Are you sure delete this project?",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				projectService.remove(this.getProjectId(), (data: any) => {
					this.props.history.push("/clients/" + this.getClientId());
				}, (message) => {
					console.log(`Error occurred: ${message}.`);
					notification.open({
						message: 'Error: Could not remove Project',
						description:
							`Failed to remove Project Id ${this.getProjectId()}.`,
						icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
					});
				});
			},
			onCancel: () => { },
		});
	};

	private handleAddIllustrationModalOK = (projectId: string, name: string, description: string, templateId: string | undefined) => {

		illustrationService.post({projectId, name, description, templateId}, (res: any) => {
			this.refreshIllustrationList(this.getProjectId());
			// Update our state and close the box modal dialog
			this.setState({
				modalDescription: "",
				modalName: "",
				modalIllustrationId: "",
				isEditIllustrationModalVisible: false,
			});
		}, (message) => {
			console.log(`Error occurred: ${message}.`);
			notification.open({
				message: 'Error: Could not create Illustration',
				description:
					`Failed to create new Illustration.`,
				icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
			});
		});
	};

	private handleEditIllustrationModalOK = (illustrationId: string, name: string, description: string) => {
		const editedIllustration = this.state.illustrations.find((i) => { return i._id === illustrationId; });
		
		if (editedIllustration) {

			const copy = JSON.parse(JSON.stringify(editedIllustration));
			copy.name = name;
			copy.description = description;

			illustrationService.put(
				copy._id,
				copy,
				(res: any) => {
					this.refreshIllustrationList(this.getProjectId());
					// Update our state and close the box modal dialog
					this.setState({
						modalDescription: "",
						modalName: "",
						modalIllustrationId: "",
						isEditIllustrationModalVisible: false,
					});
				}, (message) => {
					console.log(`Error occurred: ${message}.`);
					notification.open({
						message: 'Error: Could not edit Illustration',
						description:
							`Failed to edit Illustration Id ${editedIllustration._id}.`,
						icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
					});
				});
		}

	


	};

	private handleEditIllustrationCancel = () => {
		// Close the box modal dialog
		this.setState({
			modalDescription: "",
			modalName: "",
			modalIllustrationId: "",
			isEditIllustrationModalVisible: false,
		});
	};

	private handleEditIllustrationClick = (illustration: any, e: any) => {
		// Open the box modal dialog
		this.setState({
			isAddingIllustration: false,
			modalDescription: illustration.description,
			modalName: illustration.name,
			modalIllustrationId: illustration._id,
			isEditIllustrationModalVisible: true,
		});
	};

	private handleAddIllustrationClick = () => {
		// Open the box modal dialog
		this.setState({
			isAddingIllustration: true,
			modalDescription: "",
			modalName: "",
			modalIllustrationId: "",
			isEditIllustrationModalVisible: true,
		});
	};
}

export const ViewProjectPage = withRouter(ViewProjectPageClass);
