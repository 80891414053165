import * as React from "react";

import { Modal, InputNumber } from "antd";

import { v4 as uuid } from "uuid";

import * as boxLib from "@lib/box/box";
import * as boxTypeLib from "@lib/box/box-type";
import * as boxTypeCounterLib from "@lib/box/box-type-counter";

export interface AddChildBoxesDialogProps {
	onAddButtonClick: (box: boxLib.Box) => void;
	onCloseOrCancelButtonClick: () => void;
	isVisible: boolean;
	boxKey: string;
	boxChildTypeKey: string;
	box: boxLib.Box | undefined;
	boxMap: boxLib.BoxMap | undefined;
	boxTypeMap: boxTypeLib.BoxTypeMap | undefined;
	boxTypeCounters: boxTypeCounterLib.BoxTypeCounters;
}

interface AddChildBoxesDialogState {
	childBoxAddCount: number;
}

export class AddChildBoxesDialog extends React.Component<
	AddChildBoxesDialogProps,
	AddChildBoxesDialogState
> {
	constructor(props: AddChildBoxesDialogProps) {
		super(props);

		// Set up our state
		this.state = {
			childBoxAddCount: 1,
		};
	}

	public componentWillReceiveProps(
		nextProps: AddChildBoxesDialogProps
	) {
		// Only update if we're showing the dialog
		if (!this.props.isVisible && nextProps.isVisible) {
			// Set up our state
			this.setState({
				childBoxAddCount: 1,
			});
		}
	}

	public render() {
		// Get the title
		const title = 'Add Child Boxes';

		// Get the OK button text
		const okButtonText = 'Add';

		// If we're importing and no files are selected, disable the import button
		const okButtonDisabled = this.state.childBoxAddCount <= 0;

		return (
			<Modal
				title={title}
				open={this.props.isVisible}
				okText={okButtonText}
				okButtonProps={{ disabled: okButtonDisabled }}
				onOk={this.handleOKButtonClick}
				cancelButtonProps={{ disabled: false }}
				onCancel={this.props.onCloseOrCancelButtonClick}
				zIndex={9999}
				width={"25%"}
			>
				<span style={{ marginRight: '12px' }}>Number of child boxes:</span>
				<InputNumber
					value={this.state.childBoxAddCount}
					min={1}
					max={10000}
					onChange={this.handleAddBoxCountChange}
				/>
			</Modal>
		);
	}

	private handleAddBoxCountChange = (value: number | null) => {
		if (value !== null) {
			this.setState({ childBoxAddCount: value });
		}
	};

	private handleOKButtonClick = () => {
		if (this.props.box &&
			this.props.boxMap &&
			this.props.boxTypeMap) {
			const updatedBox = JSON.parse(JSON.stringify(this.props.box)) as boxLib.Box;

			// If there's no children, set up the child box map.
			if (!updatedBox.children) {
				updatedBox.children = {}
			}

			const updatedBoxChildren = updatedBox.children;

			// New boxes are last in the box order
			let highestOrder = boxLib.getHighestBoxOrder(updatedBoxChildren);

			// New IDs are the highest for the box type
			let highestId = boxTypeCounterLib.getCounter(this.props.boxTypeCounters,
				this.props.boxChildTypeKey);

			for (let i=0; i < this.state.childBoxAddCount; i += 1) {
				highestOrder += 1;

				const newChildBox = boxLib.createDefaultBox(this.props.boxChildTypeKey,
					this.props.boxTypeMap,
					highestOrder)

				if (!newChildBox.attributes) {
					newChildBox.attributes = {}
				}
				newChildBox.attributes["ID"] = highestId.toString();

				const newChildBoxKey = uuid();

				updatedBoxChildren[newChildBoxKey] = newChildBox;

				highestId += 1;
			}

			this.props.onAddButtonClick(updatedBox);
		}
	};
}
