import * as React from "react";

import {
	Properties,
	PROPERTIES_DEFAULT_FONT_WEIGHT,
	PROPERTIES_DEFAULT_BACKGROUND_IMAGE,
	PROPERTIES_DEFAULT_BACKGROUND_IMAGE_POSITION,
	PROPERTIES_DEFAULT_BACKGROUND_IMAGE_SIZE,
	PROPERTIES_DEFAULT_BACKGROUND_IMAGE_REPEAT,
	PROPERTIES_DEFAULT_BORDER_COLOR,
} from "@lib/box/box-properties";
import { AttributeTypeVisibilityMap } from "@lib/box/attribute-type";
import { Box } from "@lib/box/box"

import {
	BadgeProperties,
	PROPERTIES_DEFAULT_BADGE_WIDTH_IN_PIXELS,
	PROPERTIES_DEFAULT_BADGE_HEIGHT_IN_PIXELS,
	PROPERTIES_DEFAULT_BADGE_TEXT_SIZE_IN_PIXELS,
	PROPERTIES_DEFAULT_BADGE_TEXT_SIZING,
	PROPERTIES_DEFAULT_BADGE_TEXT_IS_VERTICAL,
	PROPERTIES_DEFAULT_BADGE_FONT_FAMILY,
	PROPERTIES_DEFAULT_BADGE_FONT_STYLE,
	PROPERTIES_DEFAULT_BADGE_BORDER_SIZE_IN_PIXELS,
	PROPERTIES_DEFAULT_BADGE_BORDER_STYLE,
	PROPERTIES_DEFAULT_BADGE_HORIZONTAL_POSITION_IN_PERCENT,
	PROPERTIES_DEFAULT_BADGE_VERTICAL_POSITION_IN_PERCENT,
	PROPERTIES_DEFAULT_BADGE_VERTICAL_ALIGNMENT,
	PROPERTIES_DEFAULT_BADGE_HORIZONTAL_ALIGNMENT,
	PROPERTIES_DEFAULT_BADGE_TEXT_ALIGNMENT,
	PROPERTIES_DEFAULT_BADGE_TEXT_ALIGNMENT_PADDING_IN_PIXELS,
} from "@lib/badge/badge";

import { MemoizedBadge } from "./Badge";

export interface BadgesProps {
	box?: Box;
	boxProperties: Properties | undefined;
	attributeTypeVisibilityMap:
		| AttributeTypeVisibilityMap
		| undefined;
	boxWidthInPixels: number;
	boxHeightInPixels: number;
}

export const Badges = (props: BadgesProps) => {
	const {
		box,
		boxProperties,
		attributeTypeVisibilityMap,
		boxWidthInPixels,
		boxHeightInPixels,
	} = props;

	// If we don't have inputs, there's nothing to render
	if (!boxProperties || !attributeTypeVisibilityMap) {
		return null;
	}

	// Build badge properties for the legacy badge
	const legacyBadgeProperties: BadgeProperties = {
		attributeType: boxProperties.badgeAttributeType,
		width: undefined,
		height: undefined,
		widthInPixels: PROPERTIES_DEFAULT_BADGE_WIDTH_IN_PIXELS,
		heightInPixels: PROPERTIES_DEFAULT_BADGE_HEIGHT_IN_PIXELS,
		text: boxProperties.badgeText,
		backgroundColor: boxProperties.badgeBackgroundColor,
		textColor: boxProperties.badgeTextColor,
		textSizeInPixels:
			PROPERTIES_DEFAULT_BADGE_TEXT_SIZE_IN_PIXELS,
		textSizing: PROPERTIES_DEFAULT_BADGE_TEXT_SIZING,
		textAlignment: PROPERTIES_DEFAULT_BADGE_TEXT_ALIGNMENT,
		textAlignmentPaddingInPixels: PROPERTIES_DEFAULT_BADGE_TEXT_ALIGNMENT_PADDING_IN_PIXELS,
		textIsVertical: PROPERTIES_DEFAULT_BADGE_TEXT_IS_VERTICAL,
		fontFamily: PROPERTIES_DEFAULT_BADGE_FONT_FAMILY,
		fontStyle: PROPERTIES_DEFAULT_BADGE_FONT_STYLE,
		fontWeight: PROPERTIES_DEFAULT_FONT_WEIGHT,
		backgroundImage: PROPERTIES_DEFAULT_BACKGROUND_IMAGE,
		backgroundImagePosition:
			PROPERTIES_DEFAULT_BACKGROUND_IMAGE_POSITION,
		backgroundImageSize:
			PROPERTIES_DEFAULT_BACKGROUND_IMAGE_SIZE,
		backgroundImageRepeat:
			PROPERTIES_DEFAULT_BACKGROUND_IMAGE_REPEAT,
		borderColor: PROPERTIES_DEFAULT_BORDER_COLOR,
		borderRadius: PROPERTIES_DEFAULT_BADGE_WIDTH_IN_PIXELS / 2,
		borderSizeInPixels:
			PROPERTIES_DEFAULT_BADGE_BORDER_SIZE_IN_PIXELS,
		borderStyle: PROPERTIES_DEFAULT_BADGE_BORDER_STYLE,
		horizontalPositionInPercent:
			PROPERTIES_DEFAULT_BADGE_HORIZONTAL_POSITION_IN_PERCENT,
		verticalPositionInPercent:
			PROPERTIES_DEFAULT_BADGE_VERTICAL_POSITION_IN_PERCENT,
		verticalAlignment:
			PROPERTIES_DEFAULT_BADGE_VERTICAL_ALIGNMENT,
		horizontalAlignment:
			PROPERTIES_DEFAULT_BADGE_HORIZONTAL_ALIGNMENT,
	};

	// Render the legacy badge
	const renderedLegacyBadge = (
		<MemoizedBadge
			key={"legacy"}
			box={box}
			badgeKey={"legacy"}
			badgeProperties={legacyBadgeProperties}
			attributeTypeVisibilityMap={attributeTypeVisibilityMap}
			boxWidthInPixels={boxWidthInPixels}
			boxHeightInPixels={boxHeightInPixels}
		/>
	);

	// The badges
	const renderedBadges = [renderedLegacyBadge];

	// Render each of the badges
	// TODO: Fix this up
	for (let i = 1; i <= 32; ++i) {
		const badgeKey = `badge${i}`;
		const badgeProperties = boxProperties[badgeKey];
		const renderedBadge = (
			<MemoizedBadge
				key={badgeKey}
				box={box}
				badgeKey={badgeKey}
				badgeProperties={badgeProperties}
				attributeTypeVisibilityMap={attributeTypeVisibilityMap}
				boxWidthInPixels={boxWidthInPixels}
				boxHeightInPixels={boxHeightInPixels}
			/>
		);
		renderedBadges.push(renderedBadge);
	}

	return (<>{renderedBadges}</>);
}

export const MemoizedBadges = React.memo(Badges)
