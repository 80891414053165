import React, {  } from "react";

import { Modal } from "antd";

export interface ImportAttributeTypesDialogProps {
	onOKButtonClick: (

	) => void;
	onCancelButtonClick: () => void;
	isVisible: boolean;
}

const ImportAttributeTypesDialog: React.FC<ImportAttributeTypesDialogProps> = (props) => {
	// Get the title
	const title = `Import Attributes`;

	const handleOKButtonClick = () => {
		// Notify the callback that the OK button has been clicked 
		props.onOKButtonClick();
	};

	return (
		<Modal
			title={title}
			open={props.isVisible}
			onOk={handleOKButtonClick}
			onCancel={props.onCancelButtonClick}
			zIndex={9999}
			width="75%"
		>

		</Modal>);
};

export default ImportAttributeTypesDialog;