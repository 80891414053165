/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Menu as AntMenu, Dropdown } from "antd";

import { Link } from "react-router-dom";

export interface MenuProps {
  isLoggedIn: boolean;
  logout: (event: any) => void;
  handleChangePasswordClick: (event: any) => void;
}

export class Menu extends React.Component<MenuProps> {
  public render() {
    if (!this.props.isLoggedIn) {
      return null;
    }

    const menu = (
      <AntMenu
        theme="dark"
        mode="vertical"
      >
        <AntMenu.Item key="menu-clients">
          <Link to="/clients/">Clients</Link>
        </AntMenu.Item>
        <AntMenu.SubMenu key="menu-security" title={<span>Security</span>}>
          <AntMenu.Item>
            <Link to="/security/users/">Users</Link>
          </AntMenu.Item>
          <AntMenu.Item key="menu-roles">
            <Link to="/security/roles/">Roles</Link>
          </AntMenu.Item>
        </AntMenu.SubMenu>
        <AntMenu.Item key="menu-change-password">
          <div onClick={this.props.handleChangePasswordClick}>Change Password</div>
        </AntMenu.Item>
        <AntMenu.Item key="menu-logout">
          <div onClick={this.props.logout}>Logout</div>
        </AntMenu.Item>
      </AntMenu>
    )

    return (
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        trigger={["click"]}
      >
        <a
          className="ant-dropdown-link"
          onClick={e => e.preventDefault()}
          style={{
            color: '#FFF'
          }}
        >
          <MenuOutlined />
        </a>
      </Dropdown>
    );
  }
}