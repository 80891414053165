import React from "react";
import { Modal, Input } from "antd";
import LabelledElement from "@components/molecules/LabelledElement";

interface AddEditUserDialogProps {
	user: any | undefined,
	onOKButtonClick: ((user: any) => void),
	onCancelButtonClick: (() => void),
	isVisible: boolean,
};

interface AddEditUserDialogState {
	user: any
};


export default class AddEditUserDialog extends React.Component<AddEditUserDialogProps, AddEditUserDialogState> {
	constructor(props: AddEditUserDialogProps) {
		super(props);

		// Get the User
		const user = (props.user)
			? JSON.parse(JSON.stringify(props.user))
			: undefined;

		// Set up our state
		this.state = {
			user: user,
		};
	}

	public componentWillReceiveProps(nextProps: AddEditUserDialogProps) {
		// Only update if we're showing the dialog
		if ((!this.props.isVisible) && (nextProps.isVisible)) {
			// Get the User
			const user = (nextProps.user)
				? JSON.parse(JSON.stringify(nextProps.user))
				: undefined;

			// Set up our state
			this.setState({
				user: user,
			});
		}
	}

	private isAddMode(): boolean {
		return !(this.state.user._id);
	}

	private handleAddRoleAssociationClick = () => { };

	public render() {

		return (

			<Modal
				title={this.isAddMode() ? 'Add User' : 'Edit User'}
				open={this.props.isVisible}
				onOk={this.handleOKButtonClick}
				onCancel={this.props.onCancelButtonClick}
				zIndex={9999}
			>
				{this.state.user ?
					<div
						style={{
							width: "100%",
							display: "grid",
							gridTemplateColumns: "1fr",
							gap: "4px 12px",
							padding: 0,
							margin: 0,
							overflowY: "visible",
						}}>
						<LabelledElement labelText="Name">
							<Input name="name" value={this.state.user.name} onChange={this.handleInputChange} />
						</LabelledElement>
						<LabelledElement labelText="Email">
							<Input name="email" value={this.state.user.email} onChange={this.handleInputChange} />
						</LabelledElement>
						{this.isAddMode() ?
							<LabelledElement labelText="Password">
								<Input name="password" type="password" value={this.state.user.password} onChange={this.handleInputChange} />
							</LabelledElement>
							: null}

					</div>
					: null}
			</Modal>
		);
	}

	private handleInputChange = (event: any) => {
		var user = JSON.parse(JSON.stringify(this.state.user));
		user[event.target.name] = event.target.value;
		this.setState({ user: user });
	};

	private handleOKButtonClick = () => {
		// Notify the callback that the OK button has been clicked, passing the new
		// box name and box type key
		this.props.onOKButtonClick(this.state.user);
	};
}