import * as React from 'react'
import { Component } from 'react'
import fitty, { Options, FittyInstance } from 'fitty'

export type Props = Partial<Options> & { text?: string } & React.AllHTMLAttributes<HTMLSpanElement>

export const style: React.CSSProperties = {
  display: 'inline-block',
  whiteSpace: 'nowrap',
}

export class Fitty extends Component<Props> {
  elementRef = React.createRef<HTMLDivElement>();
  thing: FittyInstance | undefined = undefined;
  fitted: boolean = false;

  componentDidMount() {
    this.fit();
  }

  componentDidUpdate() {
    this.fit();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.children !== this.props.children
      || nextProps.text !== this.props.text
      || nextProps.minSize !== this.props.minSize
      || nextProps.maxSize !== this.props.maxSize
      || nextProps.multiLine !== this.props.multiLine) {
      this.fitted = false;

      if (nextProps.minSize !== this.props.minSize
        || nextProps.maxSize !== this.props.maxSize
        || nextProps.multiLine !== this.props.multiLine) {
        this.finalize();
      }
    }
  }

  componentWillUnmount() {
    if (this.thing) {
      this.thing.unsubscribe();
    }
  }

  initialize = () => {
    if (this.elementRef
      && this.elementRef.current
      && this.props.maxSize
      && (this.props.maxSize > 0)) {
      this.thing = fitty(this.elementRef.current,
        {
          multiLine: this.props.multiLine,
          minSize: this.props.minSize,
          maxSize: this.props.maxSize,
        });
    }
  }

  finalize = () => {
    if (this.thing) {
      this.thing.unsubscribe();
      this.thing = undefined;
      this.fitted = false;
    }
  }

  fit = () => {
    if (this.fitted) {
      return;
    }

    if (!this.thing) {
      this.initialize();
    }

    if (this.thing) {
      this.thing.fit();
      this.fitted = true;
    }
  }

  render() {
    const { children, text, minSize, maxSize, multiLine, ...rest } = this.props

    const fittedText = (text)
      ? text
      : ''

    return (
      <div {...rest}>
        <span
          ref={this.elementRef}
          style={style}
          dangerouslySetInnerHTML={{ __html: fittedText }}
        >
          {children}
        </span>
      </div>
    )
  }
}