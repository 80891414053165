import * as React from "react";
import {
	DeleteOutlined,
	EditOutlined,
	FileAddOutlined,
	UserOutlined,
} from "@ant-design/icons";
import {
	Table,
	Divider,
	Modal,
	Col,
	Row,
	Card,
	Layout,
	notification
} from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons';
	
import userService from "../../../../services/user";
import clientService from "../../../../services/client";
import roleService from "../../../../services/role";

import * as authorizationService from "../../../../services/authorization";
import authService from "../../../../services/auth";
import { User, UserRole } from "@contracts/user";
import { Client } from "@contracts/client";
import { Role } from "@contracts/role";
import AddEditUserDialog from "./AddEditUserDialog";
import ChangePasswordDialog from "./ChangePasswordDialog";
import AddEditAssociatedRoleDialog from "./AddEditAssociatedRoleDialog";

import { getRoleNameForRoleId } from "./role";
import { getClientNameForClientId } from "./client";

import { renderBreadcrumbsInPortal } from "../../../organisms/Breadcrumbs";

import { withRouter } from '../../../../routes/withRouter';


const confirm = Modal.confirm;
export interface ViewUserPageProps {
	match: any;
	history: any;
	abilities: authorizationService.AbilitiesMap | undefined;
}

export interface ViewUserPageState {
	user: User | undefined;
	roles: Role[];
	clients: Client[];

	isEditUserModalVisible: boolean;
	isChangePasswordModalVisible: boolean;
	isAddEditAssociatedRoleModalVisible: boolean;
	isAddEditAssociatedRoleModalInAddMode: boolean;
	associatedRoles: UserRole[];
	modalUserRole: UserRole | undefined;
	modalUserRoleIndex: number;
}

class ViewUserPageC extends React.Component<
	ViewUserPageProps,
	ViewUserPageState
> {
	private associatedRolesColumns = [
		{
			title: "Role",
			dataIndex: "role",
			key: "role",
			render: (text: any, record: any) => {
				// Get the client key
				const clientKey = "*";

				return authorizationService.renderIfAbilityPermitted(
					this.props.abilities,
					authorizationService.AbilityType.UserView,
					clientKey,
					() => <span>{text}</span>
				);
			},
		},
		{
			title: "Client",
			dataIndex: "client",
			key: "client",
			render: (text: any, record: any) => {
				// Get the client key
				const clientKey = "*";

				return authorizationService.renderIfAbilityPermitted(
					this.props.abilities,
					authorizationService.AbilityType.UserView,
					clientKey,
					() => <span>{text}</span>
				);
			},
		},

		{
			title: "Action",
			key: "action",
			render: (text: any, record: any) => {
				// Get the client key
				const domainKey = "*";

				// Get the user role index
				const userRoleIndex = record.index;

				return (
					<span>
						{authorizationService.renderIfAbilityPermitted(
							this.props.abilities,
							authorizationService.AbilityType.UserEdit,
							domainKey,
							() => (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								<a
									onClick={(e) =>
										this.handleEditAssociatedRoleClick(
											userRoleIndex
										)
									}
								>
									Edit
								</a>
							)
						)}
						<Divider type="vertical" />
						{authorizationService.renderIfAbilityPermitted(
							this.props.abilities,
							authorizationService.AbilityType.UserEdit,
							domainKey,
							() => (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								<a
									onClick={(e) =>
										this.handleRemoveAssociatedRoleClick(
											userRoleIndex
										)
									}
								>
									Delete
								</a>
							)
						)}
					</span>
				);
			},
		},
	];

	constructor(props: ViewUserPageProps) {
		super(props);
		this.state = {
			user: undefined,
			roles: [],
			clients: [],
			isEditUserModalVisible: false,
			isChangePasswordModalVisible: false,
			isAddEditAssociatedRoleModalVisible: false,
			isAddEditAssociatedRoleModalInAddMode: false,
			associatedRoles: [],
			modalUserRole: undefined,
			modalUserRoleIndex: -1,
		};
		this.getUserId = this.getUserId.bind(this);
	}

	getUserId() {
		return this.props.match.params.userId;
	}

	private refreshUser = () => {
		// Get the ID of the user
		let userId = this.getUserId();
		if (userId) {
			// Get the user
			userService.get(userId, (user) => {
				if (user) {
					console.log("User is " + JSON.stringify(user));

					// Get the roles
					roleService.getList((roles: Role[] | null) => {
						if (roles) {
							// Get the clients
							clientService.getList(
								(clients: Client[] | null) => {
									if (clients) {
										// Build the associated roles
										const associatedRoles: UserRole[] = user.roles.map(
											(
												userRole: UserRole,
												userRoleIndex: number
											) => {
												// Get the role ID and name
												const roleId = userRole.roleId;
												const roleName = getRoleNameForRoleId(
													roles,
													roleId
												);

												// Get the client ID and name
												// If the role has a wildcard client ID, the client ID is
												// not returned as part of the user role
												const clientId = Object.prototype.hasOwnProperty.call(
													userRole,
													"clientId"
												)
													? userRole.clientId
													: "*";
												const clientName = getClientNameForClientId(
													clients,
													clientId
												);

												return {
													index: userRoleIndex,
													roleId,
													role: roleName,
													clientId,
													client: clientName,
												};
											}
										);

										this.setState({
											user: user,
											roles,
											clients,
											associatedRoles,
										});
									}
								}, (message) => {
									console.log(`Error occurred: ${message}.`);
									notification.open({
										message: 'Error: Could not load Client List',
										description:
											`Failed to load Client List.`,
										icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
									});
								});
						}
					}, (message) => {
						console.log(`Error occurred: ${message}.`);
						notification.open({
							message: 'Error: Could not load Role List',
							description:
								`Failed to load Role List.`,
							icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
						});
					});
				} else {
					console.log("no user?");
					notification.open({
						message: 'Error: Could not find user',
						description:
							`Get User call returned a null User.`,
						icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
					});
				}
			}, (message) => {
				console.log(`Error occurred: ${message}.`);
				notification.open({
					message: 'Error: Could not load User',
					description:
						`Could not load User Id ${userId}.`,
					icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
				});
			});
		} else {
			console.log("Error: No User Id");
		}
	};

	componentDidMount() {
		this.refreshUser();
	}

	private renderBreadcrumbs = () => {
		const BREADCRUMBS = [
			{
				onGetPath: (params: any) => "/security/users/",
				onGetDisplayName: (params: any) => "Users",
			},
			{
				onGetPath: (params: any) => `/security/users/${params.userId}`,
				onGetDisplayName: (params: any) =>
					this.state.user ? this.state.user.name : "",
			},
		];

		return renderBreadcrumbsInPortal(BREADCRUMBS, this.props.match.params);
	};

	public render() {
		// Determine the associated abilities actions based on permissions
		const associatedAbilitiesActions = authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.UserEdit,
			"*"
		)
			? [<FileAddOutlined onClick={this.handleAddAssociatedRoleClick} />]
			: [];

		const breadcrumbs = this.renderBreadcrumbs();

		return (
			<Layout
				style={{
					width: "100%",
					height: "100%",
				}}
			>
				{breadcrumbs}
				<Layout.Content>
					<Row>
						<Col span={6}>
							<Card
								title="User Details"
								style={{ margin: 16 }}
								actions={[
									<EditOutlined
										onClick={this.handleEditUserClick}
									/>,
									<UserOutlined
										onClick={this.handleChangePasswordClick}
									/>,
									<DeleteOutlined
										onClick={this.handleRemoveUserClick}
									/>,
								]}
							>
								{this.state.user ? (
									<div>
										<Row gutter={6}>
											<Col span={24}>
												<span
													style={{
														color:
															"rgba(0, 0, 0, 0.45)",
													}}
												>
													Name
												</span>
												<br />
												<span
													style={{
														color:
															"rgba(0, 0, 0, 0.85)",
													}}
												>
													{this.state.user.name
														? this.state.user.name
														: " "}
												</span>
												<br />
												<br />
											</Col>
										</Row>
										<Row gutter={6}>
											<Col span={24}>
												<span
													style={{
														color:
															"rgba(0, 0, 0, 0.45)",
													}}
												>
													Email
												</span>
												<br />
												<span
													style={{
														color:
															"rgba(0, 0, 0, 0.85)",
													}}
												>
													{this.state.user.email
														? this.state.user.email
														: " "}
												</span>
												<br />
												<br />
											</Col>
										</Row>
										<Row gutter={6}>
											<Col span={24}>
												<span
													style={{
														color:
															"rgba(0, 0, 0, 0.45)",
													}}
												>
													Created At
												</span>
												<br />
												<span
													style={{
														color:
															"rgba(0, 0, 0, 0.85)",
													}}
												>
													{this.state.user.createdAt
														? new Date(
															this.state.user.createdAt
														).toLocaleString(
															"en-AU"
														)
														: " "}
												</span>
												<br />
												<br />
											</Col>
										</Row>
										<Row gutter={6}>
											<Col span={24}>
												<span
													style={{
														color:
															"rgba(0, 0, 0, 0.45)",
													}}
												>
													Updated At
												</span>
												<br />
												<span
													style={{
														color:
															"rgba(0, 0, 0, 0.85)",
													}}
												>
													{this.state.user.updatedAt
														? new Date(
															this.state.user.updatedAt
														).toLocaleString(
															"en-AU"
														)
														: " "}
												</span>
												<br />
												<br />
											</Col>
										</Row>
									</div>
								) : null}
							</Card>
						</Col>
						<Col span={18}>
							<Card
								title="Associated Abilities"
								style={{ margin: 16 }}
								actions={associatedAbilitiesActions}
							>
								<Table
									columns={this.associatedRolesColumns}
									rowKey="index"
									pagination={false}
									dataSource={this.state.associatedRoles}
								/>
							</Card>
						</Col>
					</Row>

					{this.state.user ? (
						<AddEditUserDialog
							onOKButtonClick={this.handleEditUserModalOK}
							onCancelButtonClick={this.handleEditUserCancel}
							isVisible={this.state.isEditUserModalVisible}
							user={this.state.user}
						/>
					) : null}
					{this.state.user ? (
						<ChangePasswordDialog
							onOKButtonClick={this.handleChangePasswordModalOK}
							onCancelButtonClick={
								this.handleChangePasswordCancel
							}
							isVisible={this.state.isChangePasswordModalVisible}
							userId={this.state.user._id}
						/>
					) : null}
					{this.state.user ? (
						<AddEditAssociatedRoleDialog
							userRole={this.state.modalUserRole}
							clients={this.state.clients}
							roles={this.state.roles}
							onOKButtonClick={
								this.handleAddEditAssociatedRoleModalOK
							}
							onCancelButtonClick={
								this.handleAddEditAssociatedRoleModalCancel
							}
							isVisible={
								this.state.isAddEditAssociatedRoleModalVisible
							}
							isAddMode={
								this.state.isAddEditAssociatedRoleModalInAddMode
							}
						/>
					) : null}
				</Layout.Content>
			</Layout>
		);
	}

	private handleChangePasswordModalOK = (
		userId: string,
		password: string
	) => {
		authService.resetPassword(userId, password, (result) => {
			this.setState({
				isChangePasswordModalVisible: false,
			});
		});
	};

	private handleChangePasswordCancel = () => {
		// Close the box modal dialog
		this.setState({
			isChangePasswordModalVisible: false,
		});
	};

	private handleChangePasswordClick = () => {
		// Open the box modal dialog
		this.setState({
			isChangePasswordModalVisible: true,
		});
	};

	private handleEditUserModalOK = (editedUser: any) => {
		userService.put(editedUser._id, editedUser, (user: any) => {
			userService.get(editedUser._id, (returnedUser: any) => {
				// Update our state and close the box modal dialog
				this.setState({
					user: returnedUser,
					isEditUserModalVisible: false,
				});
			}, (message) => {
				console.log(`Error occurred: ${message}.`);
				notification.open({
					message: 'Error: Could not load User',
					description:
						`Failed to load User Id ${editedUser._id}.`,
					icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
				});
			});
		}, (message) => {
			console.log(`Error occurred: ${message}.`);
			notification.open({
				message: 'Error: Could not edit User',
				description:
					`Failed to edit User Id ${editedUser._id}.`,
				icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
			});
		});
	};

	private handleAddAssociatedRoleClick = () => {
		// Open the Add/Edit Associated Role modal dialog in Add mode
		this.setState({
			isAddEditAssociatedRoleModalVisible: true,
			isAddEditAssociatedRoleModalInAddMode: true,
			modalUserRole: undefined,
			modalUserRoleIndex: -1,
		});
	};

	private handleRemoveAssociatedRoleClick = (userRoleIndex: number) => {
		if (this.state.user) {
			confirm({
				title: "Are you sure delete this role association?",
				okText: "Yes",
				okType: "danger",
				cancelText: "No",
				onOk: () => {
					// Get a copy of the user
					const updatedUser = JSON.parse(
						JSON.stringify(this.state.user)
					);

					// Remove the user role
					updatedUser.roles.splice(userRoleIndex, 1);

					// Update the user and close the Add/Edit Associated Role modal dialog
					this.setState(
						{
							user: updatedUser,
						},
						() => {
							// Update the user
							userService.put(
								this.getUserId(),
								updatedUser,
								(data: any) => {
									this.refreshUser();
								}, (message) => {
									console.log(`Error occurred: ${message}.`);
									notification.open({
										message: 'Error: Could not update User',
										description:
											`Failed to update User Id ${this.getUserId()}.`,
										icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
									});
								});
						}
					);
				},
				onCancel: () => { },
			});
		}
	};

	private handleEditAssociatedRoleClick = (userRoleIndex: number) => {
		// Get the user role
		const userRole = this.state.user
			? JSON.parse(JSON.stringify(this.state.user.roles[userRoleIndex]))
			: undefined;

		// Open the Add/Edit Associated Role modal dialog in Edit mode
		this.setState({
			isAddEditAssociatedRoleModalVisible: true,
			isAddEditAssociatedRoleModalInAddMode: false,
			modalUserRole: userRole,
			modalUserRoleIndex: userRoleIndex,
		});
	};

	private handleAddEditAssociatedRoleModalOK = (userRole: UserRole) => {
		// Get a copy of the user
		const updatedUser = JSON.parse(JSON.stringify(this.state.user));

		// Add or update the user role
		if (this.state.isAddEditAssociatedRoleModalInAddMode) {
			updatedUser.roles.push(userRole);
		} else {
			updatedUser.roles[this.state.modalUserRoleIndex] = userRole;
		}

		// Update the user and close the Add/Edit Associated Role modal dialog
		this.setState(
			{
				user: updatedUser,
				isAddEditAssociatedRoleModalVisible: false,
				isAddEditAssociatedRoleModalInAddMode: false,
				modalUserRole: undefined,
				modalUserRoleIndex: -1,
			},
			() => {
				// Update the user
				userService.put(this.getUserId(), updatedUser, (data: any) => {
					this.refreshUser();
				}, (message)=>{
					console.log(`Error occurred: ${message}.`);
					notification.open({
						message: 'Error: Could not update User',
						description:
							`Failed to update User Id ${this.getUserId()}.`,
						icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
					});
				});
			}
		);
	};

	private handleAddEditAssociatedRoleModalCancel = () => {
		// Close the Add/Edit Associated Role modal dialog
		this.setState({
			isAddEditAssociatedRoleModalVisible: false,
			isAddEditAssociatedRoleModalInAddMode: false,
			modalUserRole: undefined,
			modalUserRoleIndex: -1,
		});
	};

	private handleRemoveUserClick = () => {
		confirm({
			title: "Are you sure delete this user?",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				userService.remove(this.getUserId(), (data: any) => {
					this.props.history.push("/security/users/");
				}, (message)=>{
					console.log(`Error occurred: ${message}.`);
					notification.open({
						message: 'Error: Could not remove User',
						description:
							`Failed to remove User Id ${this.getUserId()}.`,
						icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
					});
				});
			},
			onCancel: () => { },
		});
	};

	private handleEditUserCancel = () => {
		// Close the box modal dialog
		this.setState({
			isEditUserModalVisible: false,
		});
	};

	private handleEditUserClick = () => {
		// TODO: Update user

		// Open the box modal dialog
		this.setState({
			isEditUserModalVisible: true,
		});
	};
}

export const ViewUserPage = withRouter(ViewUserPageC);
