import * as React from "react";
import { Key } from "react";

import { Tree } from "antd";
import {
	DataNode,
	EventDataNode,
} from "antd/lib/tree";

import * as boxTypeLib from "@lib/box/box-type";

const TreeNode = Tree.TreeNode;

const SHOW_BOXES_SUFFIX = "show-boxes";
const KEEP_BOXES_SUFFIX = "keep-boxes";

interface CheckInfo {
	event: "check";
	node: EventDataNode<any>;
	checked: boolean;
	nativeEvent: MouseEvent;
	checkedNodes: DataNode[];
	checkedNodesPositions?: {
		node: DataNode;
		pos: string;
	}[];
	halfCheckedKeys?: Key[];
}

interface BoxTypeTreeViewProps {
	onBoxTypeBoxesVisibilityOrLayoutChange: (
		boxTypeKeyHierarchy: string[],
		areBoxesVisible: boolean,
		areBoxesInLayout: boolean
	) => void;
	onBoxTypeVisibilityChange: (
		boxTypeKeyHierarchy: string[],
		isVisible: boolean,
		includeAttributes: boolean,
	) => void;
	onAttributeTypeVisibilityChange: (
		boxTypeKeyHierarchy: string[],
		attributeTypeKey: string,
		isVisible: boolean
	) => void;
	boxTypeMap: boxTypeLib.BoxTypeMap | undefined;
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined;
	textColor: string;
	showAttributes: boolean;
}

export default class BoxTypeTreeView extends React.Component<
	BoxTypeTreeViewProps
> {
	state = {
		expandedKeys: [],
		autoExpandParent: true,
		selectedKeys: [],
	};
	private onExpand = (expandedKeys: any) => {
		//console.log('onExpand', arguments);
		// if not set autoExpandParent to false, if children expanded, parent can not collapse.
		// or, you can remove all expanded children keys.
		this.setState({
			expandedKeys,
			autoExpandParent: false,
		});
	};

	// May need to deal with | { checked: string[]; halfChecked: string[]; } for
	private onCheck = (
		checkedKeys:
			| Key[]
			| {
					checked: (string | number)[];
					halfChecked: (string | number)[];
			  },
		info: CheckInfo
	) => {
		const key = String(info.node.key) || "";
		const checked = info.checked || false;

		var splitKey = key.split("_");

		let actualKey = splitKey[0];

		// Get the box type key hierarchy
		const boxTypeKeyHierarchy = actualKey.split(",");

		// If we have a length of 2 then we need to figure out what we've clicked on
		if (splitKey.length === 2) {
			const suffix = splitKey[1];

			if (suffix === KEEP_BOXES_SUFFIX) {
				this.props.onBoxTypeBoxesVisibilityOrLayoutChange(
					boxTypeKeyHierarchy,
					checked,
					checked
				);
			} else if (suffix === SHOW_BOXES_SUFFIX) {
				this.props.onBoxTypeBoxesVisibilityOrLayoutChange(
					boxTypeKeyHierarchy,
					checked,
					true
				);
			}
			// Otherwise we must have selected an individual attribute (and the suffix )
			else {
				this.props.onAttributeTypeVisibilityChange(
					boxTypeKeyHierarchy,
					suffix,
					checked
				);
			}
		}
		// Otherwise it's going to have a length of 1, which means we've clicked on a type
		else {
			// Notify the callback that the box type visibility has changed
			this.props.onBoxTypeVisibilityChange(boxTypeKeyHierarchy, checked, this.props.showAttributes);
		}
	};

	private onSelect = (
		selectedKeys: Key[],
		info: {
			event: "select";
			selected: boolean;
			node: EventDataNode<any>;
			selectedNodes: DataNode[];
			nativeEvent: MouseEvent;
		}
	) => {
		const expandedKeys = JSON.parse(
			JSON.stringify(this.state.expandedKeys)
		);

		// TODO: may need to do something with this key later
		// const key = String(info.node.key);

		// If the selected key is already expanded, unexpand it, otherwise expand it
		selectedKeys.forEach((key: Key) => {
			const keyIndex = expandedKeys.indexOf(key);
			if (keyIndex > -1) {
				expandedKeys.splice(keyIndex, 1);
			} else {
				expandedKeys.push(key);
			}
		});

		this.setState({ expandedKeys });
	};

	private renderBoxTypeAttributeTypes = (
		boxTypeTreeNodeKey: string,
		boxType: boxTypeLib.BoxType | undefined,
		boxTypeVisibility: boxTypeLib.BoxTypeVisibility | undefined
	) => {
		// If we don't have a box types or attribute types then there's nothing to
		// render
		if (
			!boxType ||
			!boxType.attributeTypes ||
			Object.keys(boxType.attributeTypes).length <= 0
		) {
			return null;
		}

		// Render the attribute types
		const renderedAttributeTypes = Object.keys(boxType.attributeTypes)
			.sort((keyA: string, keyB: string): number => {
				const orderA =
					boxType.attributeTypes[keyA].order !== undefined
						? boxType.attributeTypes[keyA].order
						: 0;
				const orderB =
					boxType.attributeTypes[keyB].order !== undefined
						? boxType.attributeTypes[keyB].order
						: 0;

				return orderA - orderB;
			})
			.map((attributeKey: string) => {
				// Get the attribute type
				const attributeType = boxType.attributeTypes[attributeKey];

				// Build the attribute type name
				const attributeTypeName = `${attributeType.name}`;

				// Disable the checkboxes if we don't have a box type visibility
				const isDisabled = boxTypeVisibility === undefined;

				// Render a tree node for the attribute type
				return (
					<TreeNode
						key={boxTypeTreeNodeKey + "_" + attributeKey}
						title={
							<span
								style={{
									color: this.props.textColor,
								}}
							>
								{attributeTypeName}
							</span>
						}
						disabled={isDisabled}
						style={{
							color: this.props.textColor,
						}}
					/>
				);
			});

		return renderedAttributeTypes;
	};

	private renderBoxTypeTreeNodes = (
		boxTypeMap: boxTypeLib.BoxTypeMap | undefined,
		boxTypeKeys: string[],
		boxTypeParentKeys: string[],
		boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined,
		isMixinBoxType: boolean
	) => {
		// console.log('renderBoxTypeTreeNodes')

		// If we don't have a box type map there's nothing to render
		if (!boxTypeMap) {
			return null;
		}

		// Render tree nodes for each of the box types
		return boxTypeKeys.map((boxTypeKey: any) => {
			// console.log(`boxTypeKey=${boxTypeKey}`)

			// Get the box type
			const boxType = boxTypeMap[boxTypeKey];
			if (!boxType) {
				// If we don't have a box type there's nothing to render
				return null;
			}

			// Build the box type key hierarchy
			const boxTypeKeyHierarchy = [...boxTypeParentKeys, boxTypeKey];

			// console.log(`boxTypeKeyHierarchy=${boxTypeKeyHierarchy.join(',')}`)

			//  Get the box type name
			const boxTypeName = !isMixinBoxType
				? boxType.name
				: `Mixin: ${boxType.name}`;

			// Get the box type visibility
			const boxTypeVisibility = boxTypeLib.getBoxTypeVisibilityForBoxTypeKeyHierarchy(
				boxTypeVisibilityMap,
				boxTypeKeyHierarchy
			);

			// Disable the checkboxes if we don't have a box type visibility
			const isDisabled = boxTypeVisibility === undefined;

			// console.log(`isVisible=${isVisible}, areBoxesVisible=${areBoxesVisible}, areBoxesInLayout=${areBoxesInLayout}, isDisabled=${isDisabled}`)

			// Build the box type treenode key from the key hierarchy
			const boxTypeTreeNodeKey = boxTypeKeyHierarchy.join(",");

			// Render the box layout controls
			const renderedBoxLayoutControls = !isMixinBoxType
				? [
						<TreeNode
							key={boxTypeTreeNodeKey + "_" + SHOW_BOXES_SUFFIX}
							title={
								<span
									style={{
										color: this.props.textColor,
									}}
								>
									Show Boxes
								</span>
							}
							disabled={isDisabled}
							style={{
								color: this.props.textColor,
							}}
						/>,
						<TreeNode
							key={boxTypeTreeNodeKey + "_" + KEEP_BOXES_SUFFIX}
							title={
								<span
									style={{
										color: this.props.textColor,
									}}
								>
									Keep Hidden Boxes In Illustration
								</span>
							}
							disabled={isDisabled}
							style={{
								color: this.props.textColor,
							}}
						/>,
				  ]
				: null;

			// Get the keys of the mixin box types
			const mixinBoxTypeKeys = boxTypeLib.getMixinBoxTypeKeys(
				boxType.mixinBoxTypes
			);

			// Get the visibilities of the mixin box types
			const mixinBoxTypeVisibilityMap = boxTypeVisibility
				? boxTypeVisibility.mixinBoxTypeVisibilityMap
				: {};

			// Render the attribute types
			const renderedAttributeTypes = this.props.showAttributes ? this.renderBoxTypeAttributeTypes(
				boxTypeTreeNodeKey,
				boxType,
				boxTypeVisibility
			) : null;

			// Render the mixins las because we're using recursion, and so want to
			// render everything else in this node first
			const renderedBoxTypeMixins = this.props.showAttributes ? this.renderBoxTypeTreeNodes(
				boxTypeMap,
				mixinBoxTypeKeys,
				boxTypeKeyHierarchy,
				mixinBoxTypeVisibilityMap,
				true
			) : null;

			// console.log('')

			return (
				<TreeNode
					key={boxTypeTreeNodeKey}
					title={
						<span
							style={{
								color: this.props.textColor,
							}}
						>
							{boxTypeName}
						</span>
					}
					disabled={isDisabled}
					style={{
						color: this.props.textColor,
					}}
				>
					{this.props.showAttributes ? renderedBoxLayoutControls : null}
					{this.props.showAttributes ? renderedBoxTypeMixins : null}
					{this.props.showAttributes ? renderedAttributeTypes : null}
				</TreeNode>
			);
		});
	};

	buildCheckedKeys = (
		boxTypeMap: boxTypeLib.BoxTypeMap | undefined,
		boxTypeKeys: string[],
		boxTypeParentKeys: string[],
		boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined
	) => {
		// If we don't have a box type map there's nothing to do
		if (!boxTypeMap) {
			return [];
		}

		// The checked keys
		let checkedKeys: string[] = [];

		// Build the checked keys of each box type
		boxTypeKeys.forEach((boxTypeKey: any) => {
			// Get the box type
			const boxType = boxTypeMap[boxTypeKey];
			if (!boxType) {
				// If we don't have a box type there's nothing to do
				return;
			}

			// Build the box type key hierarchy
			const boxTypeKeyHierarchy = [...boxTypeParentKeys, boxTypeKey];

			// Build the box type treenode key from the key hierarchy
			const boxTypeTreeNodeKey = boxTypeKeyHierarchy.join(",");

			// Get the box type visibility
			const boxTypeVisibility = boxTypeLib.getBoxTypeVisibilityForBoxTypeKeyHierarchy(
				boxTypeVisibilityMap,
				boxTypeKeyHierarchy
			);
			if (boxTypeVisibility) {
				// Is the box type visible?
				if (boxTypeVisibility.isVisible) {
					checkedKeys.push(boxTypeTreeNodeKey);
				}

				// Are the boxes of the box type visible?
				if (boxTypeVisibility.areBoxesVisible) {
					checkedKeys.push(
						boxTypeTreeNodeKey + "_" + SHOW_BOXES_SUFFIX
					);
				}

				// Is the box type showing boxes of the type in the layout?
				if (boxTypeVisibility.areBoxesInLayout) {
					checkedKeys.push(
						boxTypeTreeNodeKey + "_" + KEEP_BOXES_SUFFIX
					);
				}

				// Get the attribute type visibilities
				const attributeTypeVisibilityMap =
					boxTypeVisibility.attributeTypeVisibilityMap;
				if (attributeTypeVisibilityMap) {
					// Add any attributes of the box type
					Object.keys(attributeTypeVisibilityMap).forEach(
						(attributeKey: any) => {
							if (attributeTypeVisibilityMap[attributeKey]) {
								checkedKeys.push(
									boxTypeTreeNodeKey + "_" + attributeKey
								);
							}
						}
					);
				}

				// Get the keys of the mixin box types
				const mixinBoxTypeKeys = boxTypeLib.getMixinBoxTypeKeys(
					boxType.mixinBoxTypes
				);

				// Get the visibilities of the mixin box types
				const mixinBoxTypeVisibilityMap = boxTypeVisibility
					? boxTypeVisibility.mixinBoxTypeVisibilityMap
					: {};

				// Get the checked keys of the mixins
				const mixinCheckedKeys = this.buildCheckedKeys(
					boxTypeMap,
					mixinBoxTypeKeys,
					boxTypeKeyHierarchy,
					mixinBoxTypeVisibilityMap
				);

				// Add the mixin checked keys to our checked keys
				checkedKeys = checkedKeys.concat(mixinCheckedKeys);
			}
		});

		return checkedKeys;
	};

	render() {
		// Get the box type keys
		const boxTypeKeys = this.props.boxTypeMap
			? Object.keys(this.props.boxTypeMap)
			: [];

		// Build the checked keys
		const checkedKeys: string[] = this.buildCheckedKeys(
			this.props.boxTypeMap,
			boxTypeKeys,
			[],
			this.props.boxTypeVisibilityMap
		);

		return (
			<Tree
				checkable
				checkStrictly
				onExpand={this.onExpand}
				expandedKeys={this.state.expandedKeys}
				autoExpandParent={this.state.autoExpandParent}
				onCheck={this.onCheck}
				checkedKeys={checkedKeys}
				onSelect={this.onSelect}
				selectedKeys={this.state.selectedKeys}
			>
				{this.props.boxTypeMap
					? this.renderBoxTypeTreeNodes(
							this.props.boxTypeMap,
							boxTypeKeys,
							[],
							this.props.boxTypeVisibilityMap,
							false
					  )
					: null}
			</Tree>
		);
	}
}
