import { PlusOutlined } from "@ant-design/icons";
import * as lensesLib from "@lib/lenses/lenses";
import { Button, Modal } from "antd";
import React, { useState } from "react";
import LensGroupList from "../../../molecules/LensGroupList";
import RightMenuHeading from "../../../organisms/RightMenuHeading";
import RightMenuHeadingText from "../../../organisms/RightMenuHeadingText";
import AddEditPowerLensGroupDialog from "./AddEditPowerLensGroupDialog";
import { v4 as uuid } from "uuid";
const confirm = Modal.confirm;
export interface PowerLensesMenuProps {
	onEdit: (lensGroupKey: string, lensGroup: lensesLib.LensGroup) => void,
	onDelete: (lensGroupKey: string) => void,
	lensGroupMap: lensesLib.LensGroupMap | undefined;
	lensMap: lensesLib.LensMap | undefined;
	canEdit: boolean,
	canDelete: boolean,
	canAdd: boolean,
	onAdd: (lensGroupKey: string, powerLensGroup: lensesLib.LensGroup) => void,
}

const PowerLensGroupsMenu: React.FC<PowerLensesMenuProps> = (props) => {
	const [addEditPowerLensGroupDialogIsVisibile, setAddEditPowerLensGroupDialogIsVisibile] = useState(false);
	const [addEditPowerLensGroupDialogIsAdding, setAddEditPowerLensGroupDialogIsAdding] = useState(true);

	const [addEditPowerLensGroup, setAddEditPowerLensGroup] = useState<lensesLib.LensGroup | null>(null);
	const [addEditPowerLensGroupKey, setAddEditPowerLensGroupKey] = useState<string | null>(null);

	const createNewPowerLensGroup = (): lensesLib.LensGroup => {
		return {
			name: "",
			description: "",
			order: 0,
			lensKeys: []
		};
	};

	return <>
		{addEditPowerLensGroup ?
			<AddEditPowerLensGroupDialog
				isAdding={addEditPowerLensGroupDialogIsAdding}
				isVisible={addEditPowerLensGroupDialogIsVisibile}
				onCancelButtonClick={() => { setAddEditPowerLensGroupDialogIsVisibile(false); }}
				lenses={props.lensMap}
				powerLensGroup={addEditPowerLensGroup}
				onOKButtonClick={(powerLensGroup) => {
					// here we want to hide the form and then tell the parent control that we're adding a group lens

					const newLensGroup = JSON.parse(JSON.stringify(powerLensGroup));
					if (addEditPowerLensGroupKey !== null) {
						if (addEditPowerLensGroupDialogIsAdding) {
							props.onAdd(addEditPowerLensGroupKey, newLensGroup);
						}
						else {
							props.onEdit(addEditPowerLensGroupKey, newLensGroup);
						}
					}

					setAddEditPowerLensGroup(null);
					setAddEditPowerLensGroupKey(null);
					setAddEditPowerLensGroupDialogIsVisibile(false);
				}}
			/> : null}

		<RightMenuHeading>
			<RightMenuHeadingText>Power Lens Groups</RightMenuHeadingText>
			<Button
				icon={<PlusOutlined />}
				disabled={!(props.canAdd)}
				type="primary"
				style={{
					paddingRight: "12px",
				}}
				onClick={
					() => {
						setAddEditPowerLensGroup(createNewPowerLensGroup());
						setAddEditPowerLensGroupDialogIsAdding(true);
						setAddEditPowerLensGroupKey(uuid());
						setAddEditPowerLensGroupDialogIsVisibile(true);
					}}
			>
				Add
			</Button>
		</RightMenuHeading>
		<div
			style={{
				padding: "12px",
			}}
		>
			<LensGroupList
				onLensGroupEdit={
					(lensGroupKey: string) => {
						if (props.lensGroupMap !== undefined) {
							setAddEditPowerLensGroup(JSON.parse(JSON.stringify(props.lensGroupMap[lensGroupKey])));
							setAddEditPowerLensGroupDialogIsAdding(false);
							setAddEditPowerLensGroupKey(lensGroupKey);
							setAddEditPowerLensGroupDialogIsVisibile(true);
						}
					}
				}
				onLensGroupDelete={
					(lensGroupKey: string) => {
						confirm({
							title:
								"Are you sure delete this Lens Group?",
							okText: "Yes",
							okType: "danger",
							cancelText: "No",
							zIndex: 9999999,
							onOk: () => { props.onDelete(lensGroupKey) },
							onCancel: () => { },
						});

					}
				}
				lensGroupMap={
					props.lensGroupMap
				}
				canEdit={
					props.canEdit
				}
				canDelete={
					props.canDelete
				}
			/>
		</div>
	</>
};

export default PowerLensGroupsMenu;