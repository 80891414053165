import axios, { AxiosResponse } from 'axios';
import config from '../config';
import { BoxTypeMap } from '@lib/box/box-type';

export interface BoxTypeService {
	getBoxTypesByIllustrationId: (illustrationId: string, success: (results: BoxTypeMap) => void) => void,
}

const getBoxTypesByIllustrationId = (illustrationId: string, success: (results : BoxTypeMap) => void) => {
  const url = `${config.apiURL}box-types/${illustrationId}`;

  axios.get(url, { withCredentials: true }).then((res: AxiosResponse<any>) => {
    success(res.data);
  });
};


const boxTypeService : BoxTypeService =  { getBoxTypesByIllustrationId };

export default boxTypeService;