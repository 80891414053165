import * as React from "react";

import { Divider, Modal, Table } from "antd";

import * as lensesLib from "@lib/lenses/lenses";
import Link from "antd/lib/typography/Link";

const confirm = Modal.confirm;

interface SimpleLensesTableProps {
	onEdit: (key: string) => void;
	onDelete: (key: string) => void;
	simpleLensMap: lensesLib.SimpleLensMap | undefined;
	canEdit: boolean;
	canDelete: boolean;
}

interface LensListItem {
	key: string;
	name: string;
	description: string;
	order: number;
}

const SimpleLensesTable : React.FC<SimpleLensesTableProps> = (props) => {
	// Build the box type list items

	const handleEdit = (key: string) => {
		// Notify the callback that the box type should be edited
		props.onEdit(key);
	};

	const handleDelete = (key: string) => {
		// Notify the callback that the box type should be removed
		props.onDelete(key);
	};

	const buildLensesListItems = (
		lensMap: lensesLib.SimpleLensMap | undefined
	) => {
		// If we don't have a box map there's no list items
		if (!lensMap) {
			return [];
		}

		// Render each of the box types
		const renderedLenses = Object.keys(lensMap).map(
			(lensKey) => {
				// Get the box type
				const lens = lensMap[lensKey];

				// Build the box type list item
				const listItem: LensListItem = {
					key: lensKey,
					name: lens.name,
					description: lens.description,
					order: lens.order
				};

				return listItem;
			}
		);

		return renderedLenses;
	};

	const lensListItems: LensListItem[] = buildLensesListItems(
		props.simpleLensMap
	);


	const simpleLensTableColumns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
		},

		{
			title: "Order",
			dataIndex: "order",
			key: "order",
		},

		{
			title: "Action",
			key: "action",
			render: (text: string, record: any) => (
				<span>
					<Link onClick={() => {
						(handleEdit(record.key))
					}}>
						Edit
					</Link>
					<Divider type="vertical" />
					<Link
						onClick={() => {
							confirm({
								title:
									"Are you sure delete this Lens?",
								okText: "Yes",
								okType: "danger",
								cancelText: "No",
								zIndex: 9999999,
								onOk: () => { handleDelete(record.key) },
								onCancel: () => { },
							});
						}}
					>
						Delete
					</Link>
				</span>
			),
		},
	];

	return (
		<Table
			dataSource={lensListItems}
			pagination={false}
			columns={simpleLensTableColumns}
		/>
	);
}

export default SimpleLensesTable;