import * as React from "react";
import { List } from "antd";
import Link from "antd/lib/typography/Link";
import * as lensesLib from "@lib/lenses/lenses";

interface LensGroupListProps {
	onLensGroupEdit: (lensGroupKey: string) => void;
	onLensGroupDelete: (lensGroupKey: string) => void;
	lensGroupMap: lensesLib.LensGroupMap | undefined;
	canEdit: boolean;
	canDelete: boolean;
}

interface ListItem {
	lensGroupKey: string;
	name: string;
}

const LensGroupList : React.FC<LensGroupListProps> = (props) => {
	const handleSimpleLensGroupEdit = (lensGroupKey: string) => {
		// Notify the callback that the box type should be edited
		props.onLensGroupEdit(lensGroupKey);
	};

	const handleSimpleLensGroupDelete = (lensGroupKey: string) => {
		// Notify the callback that the box type should be removed
		props.onLensGroupDelete(lensGroupKey);
	};

	const buildVisibilityStatesListItems = (
		lensGroupMap: lensesLib.LensGroupMap | undefined
	) => {
		// If we don't have a box map there's no list items
		if (!lensGroupMap) {
			return [];
		}

		// Render each of the box types
		const renderedVisibilityStates = Object.keys(lensGroupMap).map(
			(lensGroupKey) => {
				// Get the box type
				const lensGroup = lensGroupMap[lensGroupKey];

				// Build the box type list item
				const visibilityStateListItem: ListItem = {
					lensGroupKey,
					name: lensGroup.name,
				};

				return visibilityStateListItem;
			}
		);

		return renderedVisibilityStates;
	};

	// Build the box type list items
	const visibilityStateListItems: ListItem[] = buildVisibilityStatesListItems(
		props.lensGroupMap
	);


	return (
		<div style={{}}>
			<List
				itemLayout="horizontal"
				dataSource={visibilityStateListItems}
				renderItem={(item: ListItem) => {
					// Build the actions
					const actions: React.ReactNode[] = [];
					if (props.canEdit) {
						actions.push(
							<Link
								onClick={() =>
									handleSimpleLensGroupEdit(
										item.lensGroupKey
									)
								}
							>
								Edit
							</Link>
						);
					}
					if (props.canDelete) {
						actions.push(
							<Link
								onClick={() =>
									handleSimpleLensGroupDelete(
										item.lensGroupKey
									)
								}
							>
								Delete
							</Link>
						);
					}

					return (
						<List.Item actions={actions}>
							<List.Item.Meta title={item.name} />
						</List.Item>
					);
				}}
			/>
		</div>
	);

};

export default LensGroupList;