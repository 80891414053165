import * as React from "react";
import "rc-drawer/assets/index.css";

import { Layout } from "antd";

import {
	BrowserRouter,
	Route,
	Routes,
} from "react-router-dom";

import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import "./App.css";

import { CurrentIllustrationPage } from "./components/pages/illustration/CurrentIllustrationPage";
import { ListClientsPage } from "./components/pages/clients/ListClientsPage";
import { ViewClientPage } from "./components/pages/clients/ViewClientPage";
import { ViewProjectPage } from "./components/pages/clients/projects/ViewProjectPage";
import { BrowseLibraryPage } from "./components/pages/library/BrowseLibraryPage";
import { ListRolesPage } from "./components/pages/security/roles/ListRolesPage";
import { ListUsersPage } from "./components/pages/security/users/ListUsersPage";
import { ProfilePage } from "./components/pages/profile/ProfilePage";
import { LoginPage } from "./components/pages/profile/LoginPage";

import authService from "./services/auth";

import axios from "axios";
import { AuthenticatedRoute } from "./routes/AuthenticatedRoute";
import { UnauthenticatedRoute } from "./routes/UnauthenticatedRoute";

import * as authorizationService from "./services/authorization";
import { ViewUserPage } from "./components/pages/security/users/ViewUserPage";
import ChangePasswordDialog from "./components/pages/security/users/ChangePasswordDialog";
import EULADialog from "./components/pages/security/users/EULADialog";

import { AppHeader } from "./components/organisms/AppHeader";


const { Content } = Layout;
// Configure Axios so that it uses credentials (passes browser cookies etc.)
axios.defaults.withCredentials = true;

interface AppState {
	isLoggedIn: boolean;
	hasLoginFailed: boolean;
	hasAcceptedEULA: boolean;
	abilities: authorizationService.AbilitiesMap | undefined;
	isChangePasswordModalVisible: boolean;
}
class App extends React.Component<any, AppState> {
	constructor(props: any) {
		super(props);
		this.state = {
			isLoggedIn: authService.isLoggedIn(),
			hasLoginFailed: false,
			hasAcceptedEULA: authService.hasAcceptedEULA(),
			abilities: authorizationService.loadAbilitiesFromSessionStorage(),
			isChangePasswordModalVisible: false,
		};
		this.logout = this.logout.bind(this);
		this.login = this.login.bind(this);

		// console.log("Is logged in: (constructor) " + authService.isLoggedIn());
		// console.log(abilities);
	}

	elementDidMount() {
		this.setState({
			isLoggedIn: authService.isLoggedIn(),
			hasAcceptedEULA: authService.hasAcceptedEULA(),
			abilities: authorizationService.loadAbilitiesFromSessionStorage(),
		});

		// console.log(
		// 	"Is logged in (elementDidMount): " + authService.isLoggedIn()
		// );
		// console.log(abilities);
	}

	logout() {
		authService.logout();
		this.setState({ isLoggedIn: false });
	}

	login(username: string, password: string): void {
		authService.login(
			username,
			password,
			(
				success: boolean,
				abilities: authorizationService.AbilitiesMap | undefined
			) => {
				// console.log("login");
				// console.log(success);
				// console.log(abilities);
				authorizationService.saveAbilitiesToSessionStorage(abilities);
				this.setState({
					isLoggedIn: success,
					hasLoginFailed: !success,
					hasAcceptedEULA: authService.hasAcceptedEULA(),
					abilities,
				});
			}
		);
	}

	private handleAcceptButtonClick = () => {
		authService.acceptEULA();
		this.setState({
			hasAcceptedEULA: true,
		});
	};

	private handleChangePasswordModalOK = (
		userId: string,
		password: string
	) => {
		authService.resetPassword(userId, password, (result) => {
			this.setState({
				isChangePasswordModalVisible: false,
			});
		});
	};

	private handleChangePasswordCancel = () => {
		// Close the box modal dialog
		this.setState({
			isChangePasswordModalVisible: false,
		});
	};

	private handleChangePasswordClick = () => {
		// Open the box modal dialog
		this.setState({
			isChangePasswordModalVisible: true,
		});
	};

	public render() {
		const { isLoggedIn, hasAcceptedEULA, hasLoginFailed, abilities } = this.state;

		// If the user has logged in but hasn't accepted a EULA, display one.
		const isEULAVisible = isLoggedIn && !hasAcceptedEULA;

		// TODO: Fix the how we calculate the width/height of an Illustration

		/*            style={{
			width: "100vw",
			height: "100vh"
		}}*/

		return (
			<DndProvider backend={HTML5Backend}>
				<BrowserRouter>
					<Layout
						style={{
							width: "100vw",
							height: "100vh",
						}}
					>
						<EULADialog
							onAcceptButtonClick={this.handleAcceptButtonClick}
							isVisible={isEULAVisible}
						/>
						<ChangePasswordDialog
							onOKButtonClick={this.handleChangePasswordModalOK}
							onCancelButtonClick={
								this.handleChangePasswordCancel
							}
							isVisible={this.state.isChangePasswordModalVisible}
							userId=""
						/>
						<AppHeader
							onChangePassword={this.handleChangePasswordClick}
							onLogout={this.logout}
							isLoggedIn={isLoggedIn}
						/>
						<Content>
							<div
								style={{
									width: "100%",
									height: "calc(100vh - 66px)",
								}}
							>
								<Routes>
									<Route path="/" element={
										<AuthenticatedRoute
											isAuthenticated={isLoggedIn}
										>
											<ListClientsPage abilities={abilities} />
										</AuthenticatedRoute>
									} />

									<Route path="/library/" element={
										<AuthenticatedRoute
											isAuthenticated={isLoggedIn}
										>
											<BrowseLibraryPage />
										</AuthenticatedRoute>
									} />

									<Route path="/clients/:clientId" element={
										<AuthenticatedRoute
											isAuthenticated={isLoggedIn}
										>
											<ViewClientPage abilities={abilities} />
										</AuthenticatedRoute>
									} />

									<Route path="/clients/" element={
										<AuthenticatedRoute
											isAuthenticated={isLoggedIn}
										>
											<ListClientsPage abilities={abilities} />
										</AuthenticatedRoute>
									} />

									<Route path="/clients/:clientId/projects/:projectId" element={
										<AuthenticatedRoute
											isAuthenticated={isLoggedIn}
										>
											<ViewProjectPage abilities={abilities} />
										</AuthenticatedRoute>
									} />

									<Route path="/clients/:clientId/projects/:projectId/illustrations/:illustrationId" element={
										<AuthenticatedRoute
											isAuthenticated={isLoggedIn}
										>
											<CurrentIllustrationPage abilities={abilities} />
										</AuthenticatedRoute>
									} />

									<Route path="/security/roles/" element={
										<AuthenticatedRoute
											isAuthenticated={isLoggedIn}
											
										>
											<ListRolesPage abilities={abilities} />
										</AuthenticatedRoute>
									} />

									<Route path="/security/users/" element={
										<AuthenticatedRoute
											isAuthenticated={isLoggedIn}
										>
											<ListUsersPage abilities={abilities}/>
										</AuthenticatedRoute>
									} />

									<Route path="/security/users/:userId" element={
										<AuthenticatedRoute
											isAuthenticated={isLoggedIn}											
										>
											<ViewUserPage abilities={abilities} />
										</AuthenticatedRoute>
									} />

									<Route path="/profile/" element={
										<AuthenticatedRoute
											isAuthenticated={isLoggedIn}
										>
											<ProfilePage />
										</AuthenticatedRoute>
									} />

									<Route
										path="/login/"
										element={
											<UnauthenticatedRoute isAuthenticated={isLoggedIn}>
												<LoginPage login={this.login} hasLoginFailed={hasLoginFailed}/>
											</UnauthenticatedRoute>
										}
									/>

								</Routes>
							</div>
						</Content>
					</Layout>
				</BrowserRouter>
			</DndProvider>
		);
	}
}

export default App;
