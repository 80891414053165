import * as lensesLib from "@lib/lenses/lenses";
import { useEffect, useState } from "react";

const useQuery = (query: lensesLib.Query) => {
	const [name, setName] = useState(query.name);
	const [operator, setOperator] = useState(query.operator);

	const onNameChange = (value: string) => { setName(value); };
	const onOperatorChange = (value: string) => { 
		setOperator(value === lensesLib.BooleanOperator.AND.toString() ? lensesLib.BooleanOperator.AND : lensesLib.BooleanOperator.OR); 
	};

	useEffect(() => {
		setName(query.name);
		setOperator(query.operator);
	}, [query])

	return {
		name,
		operator,
		onNameChange,
		onOperatorChange,
	};

};

export default useQuery;