import { BoxTypeMap } from './box-type';

const BOX_TYPE_COUNTER_INITIAL_VALUE = 1;

export type BoxTypeCounters = Record<string, number>;

export const createCounters = (boxTypes: BoxTypeMap): BoxTypeCounters => {
  const boxTypeCounters: BoxTypeCounters = {};

  Object
    .keys(boxTypes)
    .forEach((boxTypeKey) => {
      boxTypeCounters[boxTypeKey] = BOX_TYPE_COUNTER_INITIAL_VALUE;
    });

  return boxTypeCounters;
}

export const getCounter = (boxTypeCounters: BoxTypeCounters, boxTypeKey: string): number => {
  if (Object.prototype.hasOwnProperty.call(boxTypeCounters, boxTypeKey)) {
    return boxTypeCounters[boxTypeKey];
  }

  return BOX_TYPE_COUNTER_INITIAL_VALUE;
}
