import axios, { AxiosResponse } from 'axios';
import config from '../config';

import { ModelService, makeModelService } from './model-service';
import { CreateIllustrationCommand, Illustration } from '@contracts/illustration';

export interface IllustrationService extends ModelService<Illustration, Illustration, CreateIllustrationCommand, Illustration> {
  getListByProjectId: (projectId: string, success: (results: Illustration[]) => void) => void,
}

const entityName = 'illustrations';

const getListByProjectId = (projectId: string, success: (results: Illustration[]) => void) => {
  const url = `${config.apiURL}${entityName}?projectId=${projectId}`;

  axios.get(url, { withCredentials: true }).then((res: AxiosResponse<any>) => {
    success(res.data);
  });
};

const genericService = makeModelService<Illustration, Illustration, CreateIllustrationCommand, Illustration>(entityName);

const illustrationService: IllustrationService = { ...genericService, getListByProjectId };

export default illustrationService;