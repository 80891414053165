import * as React from "react";
import {
	DeleteOutlined,
	EditOutlined,
	FileAddOutlined,
} from "@ant-design/icons";
import {
	Table,
	Divider,
	Modal,
	Col,
	Row,
	Card,
	Layout,
	notification
} from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import { Link } from "react-router-dom";

import AddEditClientDialog from "./AddEditClientDialog";
import AddEditProjectDialog from "./projects/AddEditProjectDialog";

import clientService from "../../../services/client";
import projectService from "../../../services/project";
import { Project } from "@contracts/project";

import * as authorizationService from "../../../services/authorization";

import { renderBreadcrumbsInPortal } from "../../organisms/Breadcrumbs";

import { withRouter } from '../../../routes/withRouter';

import { ColumnProps } from "antd/lib/table";
import { ReactNode } from "react";
import { Client, CreateClientCommand, UpdateClientCommand } from "@contracts/client";
const confirm = Modal.confirm;
export interface ViewClientPageProps {
	match: any;
	history: any;
	abilities: authorizationService.AbilitiesMap | undefined;
}

export interface ViewClientPageState {
	projects: any;
	modalProject: any;
	client: Client | null;
	isEditClientModalVisible: boolean;
	isEditProjectModalVisible: boolean;
}

class ViewClientPageC extends React.Component<
	ViewClientPageProps,
	ViewClientPageState
> {
	columns: ColumnProps<any>[] = [
		{
			title: "Name",
			dataIndex: "name",
			key: "_id",
			render: (text: any, record: any): ReactNode => {
				// Get the client ID
				const clientId = this.getClientId();

				// Get the project ID
				const projectId = record._id;

				return authorizationService.renderIfAbilityPermitted(
					this.props.abilities,
					authorizationService.AbilityType.ProjectView,
					clientId,
					() => (
						<Link
							to={
								"/clients/" +
								clientId +
								"/projects/" +
								projectId
							}
						>
							{text}
						</Link>
					)
				);
			},
		},
		{
			title: "Action",
			key: "action",
			render: (text: any, record: any) => {
				// Get the client ID
				const clientId = this.getClientId();

				return (
					<span>
						{authorizationService.renderIfAbilityPermitted(
							this.props.abilities,
							authorizationService.AbilityType.ProjectEdit,
							clientId,
							() => (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								<a
									onClick={(e) =>
										this.handleEditProjectClick(record, e)
									}
								>
									Edit
								</a>
							)
						)}
						{authorizationService.renderIfAllAbilitiesPermitted(
							this.props.abilities,
							[
								authorizationService.AbilityType.ProjectEdit,
								authorizationService.AbilityType.ProjectDelete,
							],
							clientId,
							() => (
								<Divider type="vertical" />
							)
						)}
						{authorizationService.renderIfAbilityPermitted(
							this.props.abilities,
							authorizationService.AbilityType.ProjectDelete,
							clientId,
							() => (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								<a
									onClick={(e) =>
										this.handleRemoveProjectClick(record, e)
									}
								>
									Delete
								</a>
							)
						)}
					</span>
				);
			},
		},
	];

	constructor(props: ViewClientPageProps) {
		super(props);
		this.state = {
			projects: [],
			client: null,
			isEditClientModalVisible: false,
			isEditProjectModalVisible: false,
			modalProject: null
		};
		this.getClientId = this.getClientId.bind(this);
	}

	getClientId() {
		return this.props.match.params.clientId;
	}

	refreshProjectList = (clientId: string) => {
		if (
			!authorizationService.isAbilityPermitted(
				this.props.abilities,
				authorizationService.AbilityType.ProjectView,
				clientId
			)
		) {
			return;
		}

		projectService.getListByClientId(clientId, (projects) => {
			if (projects) {
				// Filter out projects that can't be viewed
				const permittedProjects = projects.filter(
					(project: Project) => {
						// Can we view the project?
						return authorizationService.isAbilityPermitted(
							this.props.abilities,
							authorizationService.AbilityType.ProjectView,
							clientId
						);
					}
				);

				this.setState({ projects: permittedProjects });
			}
		});
	};

	componentDidMount() {
		let clientId = this.getClientId();

		if (
			!authorizationService.isAbilityPermitted(
				this.props.abilities,
				authorizationService.AbilityType.ClientView,
				clientId
			)
		) {
			return;
		}

		if (clientId) {
			clientService.get(clientId, (client) => {
				if (client) {
					this.setState({ client: client });
				} else {
					console.log("no client?");
				}
			}, (message)=>{
				console.log(`Error occurred: ${message}.`);
				notification.open({
					message: 'Error: Could not load Client',
					description:
						`Failed to load ClientId ${clientId}.`,
					icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
				});
			});

			this.refreshProjectList(clientId);
		} else {
			console.log("Error: No Client Id");
		}
	}

	private renderBreadcrumbs = () => {
		const BREADCRUMBS = [
			{
				onGetPath: (params: any) => "/",
				onGetDisplayName: (params: any) => "Clients",
			},
			{
				onGetPath: (params: any) => `/clients/${params.clientId}`,
				onGetDisplayName: (params: any) =>
					this.state.client ? this.state.client.name : "",
			},
		];

		return renderBreadcrumbsInPortal(BREADCRUMBS, this.props.match.params);
	};

	public render() {
		console.log(JSON.stringify(this.state.client));

		// Get the client ID
		const clientId = this.getClientId();

		// Determine the client details actions based on permissions
		const clientDetailsActions = [];
		if (
			authorizationService.isAbilityPermitted(
				this.props.abilities,
				authorizationService.AbilityType.ClientDetailsEdit,
				clientId
			)
		) {
			clientDetailsActions.push(
				<EditOutlined onClick={this.handleEditClientClick} />
			);
		}

		if (
			authorizationService.isAbilityPermitted(
				this.props.abilities,
				authorizationService.AbilityType.ClientDetailsDelete,
				clientId
			)
		) {
			clientDetailsActions.push(
				<DeleteOutlined onClick={this.handleRemoveClientClick} />
			);
		}

		// Determine the project actions based on permissions
		const projectActions = authorizationService.isAbilityPermitted(
			this.props.abilities,
			authorizationService.AbilityType.ProjectAdd,
			clientId
		)
			? [<FileAddOutlined onClick={this.handleAddProjectClick} />]
			: [];

		const breadcrumbs = this.renderBreadcrumbs();

		return (
			<Layout
				style={{
					width: "100%",
					height: "100%",
				}}
			>
				{breadcrumbs}
				<Layout.Content>
					<Row>
						<Col span={6}>
							<Card
								title="Client Details"
								style={{ margin: 16 }}
								actions={clientDetailsActions}
							>
								{this.state.client
									? authorizationService.renderIfAbilityPermitted(
											this.props.abilities,
											authorizationService.AbilityType
												.ClientDetailsView,
											clientId,
											() => (
												<div>
													<Row gutter={6}>
														<Col span={24}>
															<span
																style={{
																	color:
																		"rgba(0, 0, 0, 0.45)",
																}}
															>
																Name
															</span>
															<br />
															<span
																style={{
																	color:
																		"rgba(0, 0, 0, 0.85)",
																}}
															>
																{this.state
																	.client && this.state.client.name
																	? this.state
																			.client
																			.name
																	: " "}
															</span>
															<br />
															<br />
														</Col>
													</Row>
													<Row gutter={6}>
														<Col span={24}>
															<span
																style={{
																	color:
																		"rgba(0, 0, 0, 0.45)",
																}}
															>
																Description
															</span>
															<br />
															<span
																style={{
																	color:
																		"rgba(0, 0, 0, 0.85)",
																}}
															>
																{this.state
																	.client && this.state.client.description
																	? this.state
																			.client
																			.description
																	: " "}
															</span>
															<br />
															<br />
														</Col>
													</Row>
													<Row gutter={6}>
														<Col span={24}>
															<span
																style={{
																	color:
																		"rgba(0, 0, 0, 0.45)",
																}}
															>
																Template Client
															</span>
															<br />
															<span
																style={{
																	color:
																		"rgba(0, 0, 0, 0.85)",
																}}
															>
																{this.state.client && this.state.client.templateClientName ? 
																this.state.client.templateClientName : "Not Found"}
															</span>
															<br />
															<br />
														</Col>
													</Row>
													<Row gutter={6}>
														<Col span={24}>
															<span
																style={{
																	color:
																		"rgba(0, 0, 0, 0.45)",
																}}
															>
																Template Project
															</span>
															<br />
															<span
																style={{
																	color:
																		"rgba(0, 0, 0, 0.85)",
																}}
															>
																{this.state.client && this.state.client.templateProjectName ? 
																this.state.client.templateProjectName : "Not Found"}
															</span>
															<br />
															<br />
														</Col>
													</Row>
													<Row gutter={6}>
														<Col span={24}>
															<span
																style={{
																	color:
																		"rgba(0, 0, 0, 0.45)",
																}}
															>
																Created At
															</span>
															<br />
															<span
																style={{
																	color:
																		"rgba(0, 0, 0, 0.85)",
																}}
															>
																{this.state
																	.client && this.state.client.createdAt
																	? new Date(
																			this.state.client.createdAt
																	  ).toLocaleString(
																			"en-AU"
																	  )
																	: " "}
															</span>
															<br />
															<br />
														</Col>
													</Row>
													<Row gutter={6}>
														<Col span={24}>
															<span
																style={{
																	color:
																		"rgba(0, 0, 0, 0.45)",
																}}
															>
																Updated At
															</span>
															<br />
															<span
																style={{
																	color:
																		"rgba(0, 0, 0, 0.85)",
																}}
															>
																{this.state
																	.client && this.state.client.updatedAt
																	? new Date(
																			this.state.client.updatedAt
																	  ).toLocaleString(
																			"en-AU"
																	  )
																	: " "}
															</span>
															<br />
															<br />
														</Col>
													</Row>
												</div>
											)
									  )
									: null}
							</Card>
						</Col>

						<Col span={18}>
							<Card
								title="Projects"
								style={{
									marginTop: 16,
									marginBottom: 16,
									marginRight: 16,
									marginLeft: 0,
								}}
								actions={projectActions}
							>
								<Table
									columns={this.columns}
									pagination={false}
									rowKey="_id"
									dataSource={this.state.projects}
								/>
							</Card>
						</Col>
					</Row>
					{this.state.client &&
					<AddEditClientDialog
						onAddClientOkButtonClick={this.handleAddClientModalOK}
						onEditClientOkButtonClick={this.handleEditClientModalOK}
						onCancelButtonClick={this.handleEditClientCancel}
						isVisible={this.state.isEditClientModalVisible}
						client={this.state.client}
						isAdding={false}
					/>}

					{this.state.modalProject ? (
						<AddEditProjectDialog
							onOKButtonClick={this.handleEditProjectModalOK}
							onCancelButtonClick={this.handleEditProjectCancel}
							isVisible={this.state.isEditProjectModalVisible}
							project={this.state.modalProject}
						/>
					) : null}
				</Layout.Content>
			</Layout>
		);
	}

	private handleEditClientModalOK = (clientId: string, cmd: UpdateClientCommand) => {
		clientService.put(clientId, cmd, (result: Client | null) => {
			clientService.get(clientId, (returnedClient: any) => {
				// Update our state and close the box modal dialog
				this.setState({
					client: returnedClient,
					isEditClientModalVisible: false,
				});
			}, (message)=>{
				console.log(`Error occurred: ${message}.`);
				notification.open({
					message: 'Error: Could not load Client',
					description:
						`Failed to load Client Id ${clientId}.`,
					icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
				});
			});
		}, (message)=>{
			console.log(`Error occurred: ${message}.`);
			notification.open({
				message: 'Error: Could not save Client',
				description:
					`Failed to save Client Id ${clientId}.`,
				icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
			});
		});
	};


	private handleAddClientModalOK = (cmd: CreateClientCommand) => {
		// not required on this page
	};

	private handleRemoveClientClick = () => {
		confirm({
			title: "Are you sure delete this project?",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				clientService.remove(this.getClientId(), (data: any) => {
					this.props.history.push("/clients/");
				}, (message)=>{
					console.log(`Error occurred: ${message}.`);
					notification.open({
						message: 'Error: Could not delete Client',
						description:
							`Failed to delete Client Id ${this.getClientId()}.`,
						icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
					});
				});
			},
			onCancel: () => {},
		});
	};

	private handleEditClientCancel = () => {
		// Close the box modal dialog
		this.setState({
			isEditClientModalVisible: false,
		});
	};

	private handleEditClientClick = () => {
		// Open the box modal dialog
		this.setState({
			isEditClientModalVisible: true,
		});
	};

	private handleEditProjectModalOK = (editedProject: any) => {
		// If we had an ID then we are updating
		if (editedProject._id) {
			projectService.put(editedProject._id, editedProject, (res: any) => {
				this.refreshProjectList(this.getClientId());
				// Update our state and close the box modal dialog
				this.setState({
					modalProject: null,
					isEditProjectModalVisible: false,
				});
			}, (message)=>{
				console.log(`Error occurred: ${message}.`);
				notification.open({
					message: 'Error: Could not edit Project',
					description:
						`Failed to edit Project Id ${editedProject._id}.`,
					icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
				});
			});
		}
		// Otherwise we are creating a new record
		else {
			projectService.post(editedProject, (res: any) => {
				this.refreshProjectList(this.getClientId());
				// Update our state and close the box modal dialog
				this.setState({
					modalProject: null,
					isEditProjectModalVisible: false,
				});
			}, (message)=>{
				console.log(`Error occurred: ${message}.`);
				notification.open({
					message: 'Error: Could not create Project',
					description:
						`Failed to create a new Project.`,
					icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
				});
			});
		}
	};

	private handleEditProjectCancel = () => {
		// Close the box modal dialog
		this.setState({
			modalProject: null,
			isEditProjectModalVisible: false,
		});
	};

	private handleEditProjectClick = (project: any, e: any) => {
		// Open the box modal dialog
		this.setState({
			modalProject: project,
			isEditProjectModalVisible: true,
		});
	};

	private handleAddProjectClick = () => {
		// Open the box modal dialog
		this.setState({
			modalProject: {
				name: "",
				description: "",
				clientId: this.getClientId(),
			},
			isEditProjectModalVisible: true,
		});
	};

	private handleRemoveProjectClick = (project: any, e: any) => {
		confirm({
			title: "Are you sure delete this project?",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				projectService.remove(project._id, (data: any) => {
					this.refreshProjectList(this.getClientId());
				}, (message)=>{
					console.log(`Error occurred: ${message}.`);
					notification.open({
						message: 'Error: Could not remove Project',
						description:
							`Failed to remove Project Id ${project._id}.`,
						icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
					});
				});
			},
			onCancel: () => {},
		});
	};
}

export const ViewClientPage = withRouter(ViewClientPageC);
