import React, { useEffect, useState } from "react";
import { Modal, Input, Select } from "antd";
import { Project } from "@contracts/project";
import projectService from "@services/project";
import { Client, CreateClientCommand, UpdateClientCommand } from "@contracts/client";
import clientService from "@services/client";
import LabelledElement from "@components/molecules/LabelledElement";

interface AddEditClientDialogProps {
	client: Client | undefined,
	isAdding: boolean,
	onAddClientOkButtonClick: ((cmd: CreateClientCommand) => void),
	onEditClientOkButtonClick: ((clientId: string, cmd: UpdateClientCommand) => void),
	onCancelButtonClick: (() => void),
	isVisible: boolean,
};

const AddEditClientDialog: React.FC<AddEditClientDialogProps> = (props) => {
	// Get the Client
	const [client, setClient] = useState(props.client !== undefined
		? JSON.parse(JSON.stringify(props.client))
		: undefined);

	const [name, setName] = useState<string>(props.client !== undefined && props.client !== null ? props.client.name : "");
	const [description, setDescription] = useState<string>(props.client !== undefined && props.client !== null ? props.client.description : "");
	const [templateProjectId, setTemplateProjectId] = useState<string>(
		props.client !== undefined && props.client !== null && props.client.templateProjectId !== undefined &&
			props.client.templateProjectId !== null && props.client.templateProjectId ? props.client.templateProjectId : "");

	const [templateClientId, setTemplateClientId] = useState<string>("")

	const [clientList, setClientList] = useState<Client[]>([]);
	const [projectList, setProjectList] = useState<Project[]>([]);


	// Reset the values when we show the page
	useEffect(() => {
		if (props.isVisible) {

			setClient(props.client !== undefined && props.client !== null ? JSON.parse(JSON.stringify(props.client)) : undefined);
			setName(props.client !== undefined && props.client !== null ? props.client.name : "");
			setDescription(props.client !== undefined && props.client !== null ? props.client.description : "");

			if (!props.isAdding) {
				const newTemplateProjectId = props.client !== undefined &&
					props.client.templateProjectId !== undefined &&
					props.client.templateProjectId !== null &&
					props.client.templateProjectId !== "" ? props.client.templateProjectId : "";

				setTemplateProjectId(newTemplateProjectId);

				clientService.getList((results) => {
					setClientList(results !== null ? results : []);


					setProjectList([]);

					// Set the template client id to be the client id of the template project id if it's set.
					if (newTemplateProjectId !== "") {

						projectService.get(newTemplateProjectId, (project) => {
							if (project !== null) {

								setTemplateClientId(project.clientId);

								projectService.getListByClientId(project.clientId, (results) => {
									setProjectList(results !== null ? results : []);
					
									// If our current project Id isn't in this list then set it to the first one 
									// OR set it to a blank string
									setTemplateProjectId((currentVal) => {
										let retVal = "";
										if (newTemplateProjectId !== undefined && newTemplateProjectId !== null && newTemplateProjectId !== "" && results !== null && results.length > 0) {
											// Check to see if the template project is in there
											const foundProject = results.find((project) => { return project._id === newTemplateProjectId });
					
											// If we couldn't find the project, set it to the first one in the list
											if (foundProject === undefined) {
												retVal = results[0]._id;
											}
											else {
												retVal = foundProject._id;
											}
										}
										return retVal;
									});
								});
							}
							else {
								setTemplateClientId("");
							}
						}, (error) => { })
					} else {
						setTemplateClientId("");
					}
				}, (error) => { });
			}
			else {
				setClientList([]);
				setProjectList([]);
				setTemplateClientId("");
				setTemplateProjectId("");

			}
		}

	}, [props.client, props.isAdding, props.isVisible]);

	useEffect(() => {
		if (templateClientId !== undefined && templateClientId !== null &&
			templateClientId !== "") {
			projectService.getListByClientId(templateClientId, (results) => {
				setProjectList(results !== null ? results : []);

				// If our current project Id isn't in this list then set it to the first one 
				// OR set it to a blank string

				setTemplateProjectId((currentVal) => {
					let retVal = "";
					if (currentVal !== undefined && currentVal !== null && currentVal !== "" && results !== null && results.length > 0) {
						// Check to see if the template project is in there
						const foundProject = results.find((project) => { return project._id === currentVal });

						// If we couldn't find the project, set it to the first one in the list
						if (foundProject === undefined) {
							retVal = results[0]._id;
						}
						else {
							retVal = foundProject._id;
						}
					}
					return retVal;
				});
			});
		} else {
			setProjectList([]);
			setTemplateProjectId("");
		}

	}, [templateClientId]);

	const handleOKButtonClick = () => {
		// Notify the callback that the OK button has been clicked, passing the new
		// box name and box type key

		if (props.isAdding) {
			props.onAddClientOkButtonClick({
				name: name,
				description: description
			});
		}
		else {
			if (client) {
				props.onEditClientOkButtonClick(
					client._id,
					{
						name: name,
						description: description,
						templateProjectId: templateProjectId !== "" ? templateProjectId : null
					}
				);
			}
		}
	}

	return (

		<Modal
			title={props.isAdding ? 'Add Client' : 'Edit Client'}
			open={props.isVisible}
			onOk={handleOKButtonClick}
			onCancel={props.onCancelButtonClick}
			zIndex={9999}
		>
			{client ?
				<div
					style={{
						width: "100%",
						display: "grid",
						gridTemplateColumns: "1fr",
						gap: "4px 12px",
						padding: 0,
						margin: 0,
						overflowY: "visible",
					}}>
					<LabelledElement labelText="Name">
						<Input
							name="name"
							value={name}
							onChange={(event) => {
								setName(event.target.value);
							}}
						/>

					</LabelledElement>
					<LabelledElement labelText="Description">
						<Input.TextArea
							name="description"
							value={description}
							onChange={(event) => {
								setDescription(event.target.value);
							}}
						/>
					</LabelledElement>
					{!props.isAdding &&

						<LabelledElement labelText="Template Client">

							<Select
								onChange={(value) => {
									setTemplateClientId(value);
								}}
								value={templateClientId}
								style={{ width: "100%" }}
								getPopupContainer={(node) => {
									let popupContainer: HTMLElement | null =
										window.document.documentElement;
									if (node && node.parentElement) {
										popupContainer = node.parentElement;
									}
									return popupContainer as HTMLElement;
								}}
							>
								<Select.Option value="">-- No Value --</Select.Option>
								{clientList !== null && clientList.length > 0 &&
									clientList.map(
										(client) => {
											return <Select.Option key={`client-${client._id}`} value={client._id}>{client.name}</Select.Option>
										}
									)}
							</Select>

						</LabelledElement>}

					{!props.isAdding &&
						<LabelledElement labelText="Template Project">
							{projectList !== null && projectList.length > 0 ? <Select
								onChange={(value) => {
									setTemplateProjectId(value);
								}}
								value={templateProjectId}
								style={{ width: "100%" }}
								getPopupContainer={(node) => {
									let popupContainer: HTMLElement | null =
										window.document.documentElement;
									if (node && node.parentElement) {
										popupContainer = node.parentElement;
									}
									return popupContainer as HTMLElement;
								}}
							>
								<Select.Option value="">-- No Value --</Select.Option>
								{projectList.map(
									(project) => {
										return <Select.Option key={`project-${project._id}`} value={project._id}>{project.name}</Select.Option>
									}
								)}
							</Select> : <>No Template Projects Available</>}
						</LabelledElement>
					}
				</div>
				: null}
		</Modal>
	);
}

export default AddEditClientDialog;