import { useEffect, useState } from "react";
import { BoxExpression } from "../AddEditBoxExpressionDialog";

import * as lensesLib from "@lib/lenses/lenses";

// ## Box Expressions: A list, where you can pick the operator
// - boxExpressions
// - applyBoxVisibility
// - boxIsVisible
// - boxIsInLayout

const useBoxQueryRestrictions = (query: lensesLib.Query) => {

	const [boxExpressions, setBoxExpressions] = useState<lensesLib.BoxExpressionMap | undefined>(query.boxExpressions)
	const [applyBoxVisibility, setApplyBoxVisibility] = useState(query.applyBoxVisibility);	
	const [boxIsInLayout, setBoxIsInLayout] = useState(query.boxIsInLayout);
	const [boxIsVisible, setBoxIsVisible] = useState(query.boxIsVisible);
	const [boxExpressionOperator, setBoxExpressionOperator] = useState(query.boxExpressionOperator);


	// Add/Edit Attribute Type Expression Form Variables
	const [isAddEditBoxExpressionDialogVisible, setIsAddEditBoxExpressionDialogVisible] = useState(false);
	const [isAddEditBoxExpressionDialogAdding, setIsAddEditBoxExpressionDialogAdding] = useState(false);
	const [addEditBoxExpression, setAddEditBoxExpression] = useState<BoxExpression | null>(null);

	useEffect(() => {
		setBoxExpressions(query.boxExpressions)
		setApplyBoxVisibility(query.applyBoxTypeVisibility);
		setBoxIsInLayout(query.boxIsInLayout);
		setBoxIsVisible(query.boxIsVisible);

		// Reset the form values		
		setIsAddEditBoxExpressionDialogVisible(false);
		setIsAddEditBoxExpressionDialogAdding(false);
		setAddEditBoxExpression(null);
	}, [query])

	const onApplyBoxVisibilityChanged = (value: boolean) => {
		setApplyBoxVisibility(value);
	};

	const onBoxIsInLayoutChanged = (value: boolean) => {
		setBoxIsInLayout(value);
	};
	const onBoxIsVisibleChanged = (value: boolean) => {
		setBoxIsVisible(value);
	};

	const onBoxExpressionOperatorChanged = (value: lensesLib.BooleanOperator) => {
		setBoxExpressionOperator(value);
	};

	const onOpenAddBoxExpressionForm = () => {
		setIsAddEditBoxExpressionDialogAdding(false);
		setAddEditBoxExpression(null);
		setIsAddEditBoxExpressionDialogVisible(true);
	}

	const onOpenEditBoxExpressionForm = (key: string) => {
		if (boxExpressions !== undefined) {
			setAddEditBoxExpression({ key, value: boxExpressions[key] })
			setIsAddEditBoxExpressionDialogAdding(false);
			setIsAddEditBoxExpressionDialogVisible(true);
		}
	}

	const onCloseAddEditBoxExpressionForm = () => {
		setIsAddEditBoxExpressionDialogVisible(false);
		setIsAddEditBoxExpressionDialogAdding(false);
		setAddEditBoxExpression(null);
	};

	const onAddEditBoxExpressionFormOk = (boxExpression: BoxExpression) => {
		const newBoxExpressions = boxExpressions !== undefined && boxExpressions !== null ? JSON.parse(JSON.stringify(boxExpressions)) : {};

		// If we have changed the key, delete the old value)
		if (addEditBoxExpression !== null && addEditBoxExpression.key !== boxExpression.key) {
			delete newBoxExpressions[addEditBoxExpression.key];
		}

		newBoxExpressions[boxExpression.key] = boxExpression.value;
		setBoxExpressions(newBoxExpressions)
		setIsAddEditBoxExpressionDialogVisible(false);
		setAddEditBoxExpression(null);
	};


	const onDeleteBoxExpression = (key: string) => {
		const newBoxExpressions = JSON.parse(JSON.stringify(boxExpressions));
		delete newBoxExpressions[key];
		setBoxExpressions(newBoxExpressions);
	};

	const onParentFormOk = (query: lensesLib.Query) => {
		return query;
	};

	
	return {
		boxExpressions,
		applyBoxVisibility,
		boxIsInLayout,
		boxIsVisible,
		boxExpressionOperator,

		isAddEditBoxExpressionDialogVisible,
		isAddEditBoxExpressionDialogAdding,
		addEditBoxExpression,

		onApplyBoxVisibilityChanged,
		onBoxIsInLayoutChanged,
		onBoxIsVisibleChanged,
		onBoxExpressionOperatorChanged,
		
		onDeleteBoxExpression,
		onAddEditBoxExpressionFormOk,
		onCloseAddEditBoxExpressionForm,
		onOpenEditBoxExpressionForm,
		onOpenAddBoxExpressionForm,

		onParentFormOk
	};
};

export default useBoxQueryRestrictions;