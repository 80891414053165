import React from "react";
import { Button, Checkbox, Input, InputNumber, Modal, Select } from "antd";
import * as renderFunctionLib from "@lib/box/render-function";
import { useEffect, useState } from "react";
import FormHeading from "@components/organisms/FormHeading";
import FormHeadingText from "@components/organisms/FormHeadingText";
import LabelledElement from "@components/molecules/LabelledElement";
import RenderFunctionInputsEditor from "./RenderFunctionInputsEditor";
import { RenderFunctionInputsType } from "@lib/box/render-function";
import { BoxStyleMap } from '@lib/box/box-style';

export interface AddEditRenderFunctionDialogProps {
	attributeTypeKey: string,
	attributeTypeName: string,
	isVisible: boolean,
	isAdding: boolean,
	renderFunction: renderFunctionLib.RenderFunctionInfo,
	renderFunctionKey: string,
	onOKButtonClick: (
		renderFunction: renderFunctionLib.RenderFunctionInfo | undefined,
		renderFunctionKey: string
	) => void;
	onCancelButtonClick: () => void;
	boxStyles: BoxStyleMap | undefined;
}


const AddEditRenderFunctionDialog: React.FC<AddEditRenderFunctionDialogProps> = (props) => {
	const [renderFunctionKey, setRenderFunctionKey] = useState(props.renderFunctionKey);


	const [name, setName] = useState(props.renderFunction.name !== undefined ? props.renderFunction.name : '');
	const [inputs, setInputs] = useState(renderFunctionLib.TYPES[props.renderFunction.type].toDisplayString(props.renderFunction.inputs));
	const [order, setOrder] = useState(props.renderFunction.order);
	const [enableLatchMode, setEnableLatchMode] = useState(props.renderFunction.enableLatchMode !== null ? props.renderFunction.enableLatchMode : false);
	const [type, setType] = useState(props.renderFunction.type);

	const [isInputValid] = useState(true);


	useEffect(() => {
		setOrder(props.renderFunction.order);
		setName(props.renderFunction.name !== undefined ? props.renderFunction.name : '')
		setInputs(renderFunctionLib.TYPES[props.renderFunction.type].toDisplayString(props.renderFunction.inputs));
		setType(props.renderFunction.type);
		setEnableLatchMode(props.renderFunction.enableLatchMode !== null ? props.renderFunction.enableLatchMode : false);
		setRenderFunctionKey(props.renderFunctionKey);
	}, [props.renderFunction, props.renderFunctionKey])


	const title = `${props.isAdding ? "Add Render Function" : "Edit Render Function"} - Attribute Type: ${props.attributeTypeName} (UUID: ${props.attributeTypeKey})`;


	const typeObject = renderFunctionLib.TYPES[type];

	const isObject = typeObject.inputsType === RenderFunctionInputsType.OBJECT;

	return <Modal
		title={title}
		open={props.isVisible}
		onOk={() => {
			const newRenderFunctionType = JSON.parse(JSON.stringify(props.renderFunction));
			newRenderFunctionType.name = name;
			newRenderFunctionType.type = type;
			newRenderFunctionType.order = order;
			newRenderFunctionType.enableLatchMode = enableLatchMode;
			const convertInputsToValue = typeObject.toValue;
			newRenderFunctionType.inputs = convertInputsToValue ? convertInputsToValue(inputs) : newRenderFunctionType.inputs;
			props.onOKButtonClick(newRenderFunctionType, renderFunctionKey);

		}}
		onCancel={props.onCancelButtonClick}
		zIndex={9999}
		centered
		width="75%">
		<FormHeading>
			<FormHeadingText>Render Function Details</FormHeadingText>
		</FormHeading>
		<div
			style={{
				width: "100%",
				display: "grid",
				gridTemplateColumns: "0.5fr 1fr 0.5fr 1fr",
				gap: "4px 12px",
				padding: 0,
				margin: 0,
				overflowY: "visible",
			}}
		>
			<LabelledElement labelText="Name">
				<Input
					value={name}

					onChange={(e) => {
						setName(e.target.value);
					}}
				/>
			</LabelledElement>
			<LabelledElement labelText="Type">
				<Select
					size="large"
					placeholder="Please select"
					value={type}
					onChange={(newType) => {
						setType(newType);
					}}

					getPopupContainer={(node) => {
						let popupContainer: HTMLElement | null =
							window.document.documentElement;
						if (node && node.parentElement) {
							popupContainer = node.parentElement;
						}
						return popupContainer as HTMLElement;
					}}
				>
					{
						Object.keys(
							renderFunctionLib.TYPES
						).map((renderFunctionTypeKey) => {
							// Get the render function type
							const renderFunctionType = renderFunctionLib.TYPES[
								renderFunctionTypeKey
							];

							return (
								<Select.Option
									key={renderFunctionTypeKey}
									value={renderFunctionTypeKey}
								>
									{renderFunctionType.name}
								</Select.Option>
							);
						})}
				</Select>
			</LabelledElement>
			<LabelledElement labelText="Enable Latch Mode">
				<Checkbox
					checked={enableLatchMode}
					onChange={(e) => {
						setEnableLatchMode(e.target.checked)
					}}
				/>
			</LabelledElement>
			<LabelledElement labelText="Order">
				<InputNumber
					value={order}

					onChange={(value: number | null) => {
						if (value !== null) {
							setOrder(value);
						}
					}}
				/>
			</LabelledElement>
			{isObject ?
				<div
					style={{ gridColumnStart: "2", gridColumnEnd: "5" }}
				>
					<Button onClick={() => { navigator.clipboard.writeText(props.attributeTypeKey) }} size="large" type="primary">Copy Attribute UUID to Clipboard</Button>&nbsp;
					<Button onClick={() => { setInputs(JSON.stringify(JSON.parse(inputs), null, 2)) }} size="large" type="primary">Format Inputs</Button>&nbsp;
					<Button onClick={() => { setInputs(typeObject.toDisplayString(typeObject.defaultValue)) }} size="large" type="primary">Reset to Blank Template</Button>
				</div> : null}
			<div
				style={{ gridColumnStart: "1", gridColumnEnd: "5" }}>
				<LabelledElement labelText="Inputs">
					<RenderFunctionInputsEditor
						inputs={inputs}
						type={type}
						isValid={isInputValid}
						onChange={(value: string) => {
							setInputs(value);
						}}
						boxStyles={props.boxStyles}

					/>

				</LabelledElement>
			</div>
		</div>
	</Modal >
}
export default AddEditRenderFunctionDialog;