/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { Divider, Modal, Table } from "antd";
import * as attributeTypeLib from "@lib/box/attribute-type";
const confirm = Modal.confirm;

interface AttributeTypeTableProps {
	attributes: attributeTypeLib.AttributeTypeMap | undefined,
	onEdit: (attributeTypeKey: string) => void,
	onDelete: (attributeTypeKey: string) => void,
};


const AttributeTypeTable: React.FC<AttributeTypeTableProps> = (props) => {
	if (!props.attributes) {
		return null;
	}

	const attributeTableColumns = [
		// name: string;
		// description: string;
		// choices: string;
		// order: number;
		// showInLegend: boolean;
		// showInSummary: boolean;
		// valueType: string;
		// defaultValue: string;
		// permittedAssociationBoxTypes: string;
		// renderFunctions: renderFunctionLib.RenderFunctionInfoMap;

		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
		},

		{
			title: "Order",
			dataIndex: "order",
			key: "order",
		},

		{
			title: "Action",
			key: "action",
			render: (text: string, record: any) => (
				<span>
					<a onClick={() => {
						(props.onEdit(record.key))
					}}>
						Edit
					</a>
					<Divider type="vertical" />
					<a
						onClick={() => {
							confirm({
								title:
									"Are you sure delete this Attribute?",
								okText: "Yes",
								okType: "danger",
								cancelText: "No",
								zIndex: 9999999,
								onOk: () => { props.onDelete(record.key) },
								onCancel: () => { },
							});
						}}
					>
						Delete
					</a>
				</span>
			),
		},
	];

	const dataSource = Object.keys(props.attributes).map(
		(key: string) => {
			const data = props.attributes![
				key
			];
			return {
				key: key,
				name: data.name,
				description: data.description,
				order: data.order,
				showInLegend: data.showInLegend,
				showInSummary: data.showInSummary,
				valueType: data.valueType,
				permittedAssociationBoxTypes: data.permittedAssociationBoxTypes,
			};
		});

	return <Table
		dataSource={dataSource}
		pagination={false}
		columns={attributeTableColumns}
	/>
}

export default AttributeTypeTable;