import { NameDtoBase, makeNameDtoBase } from "./nameDtoBase";

export interface UserRole {
    roleId: string,
    clientId: string,
};

export interface User extends NameDtoBase {
    email: string,
    roles: UserRole[]    
};

export const makeUser = () : User => {
    const base = makeNameDtoBase();

    return {
        ...base,
        email: "",
        roles: []
    }
};