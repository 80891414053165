import React from "react";
import { Modal, Input } from "antd";

interface ChangePasswordDialogProps {
  userId: string,
  onOKButtonClick: ((userId: string, password: string) => void),
  onCancelButtonClick: (() => void),
  isVisible: boolean,
};

interface ChangePasswordDialogState {
  userId: string,
  password: string
};

export default class ChangePasswordDialog extends React.Component<ChangePasswordDialogProps, ChangePasswordDialogState> {
  constructor(props: ChangePasswordDialogProps) {
    super(props);

    // Set up our state
    this.state = {
      userId: props.userId,
      password: ""
    };
  }

  public componentWillReceiveProps(nextProps: ChangePasswordDialogProps) {
    // Only update if we're showing the dialog
    if ((!this.props.isVisible) && (nextProps.isVisible)) {
      // Set up our state
      this.setState({
        userId: nextProps.userId,
        password: ""
      });
    }
  }

  private handleAddRoleAssociationClick = () => { };

  public render() {

    return (

      <Modal
        title={'Change Password'}
        open={this.props.isVisible}
        onOk={this.handleOKButtonClick}
        onCancel={this.props.onCancelButtonClick}
        zIndex={9999}
      >
        <div>
          <div style={{ marginBottom: 16 }}>
            <Input name="password" type="password" placeholder="Password" value={this.state.password} onChange={this.handleInputChange} />
          </div>
        </div>
      </Modal>
    );
  }

  private handleInputChange = (event: any) => {
    var password = event.target.value;
    this.setState({ password });
  };

  private handleOKButtonClick = () => {
    // Notify the callback that the OK button has been clicked, passing the new
    // box name and box type key
    this.props.onOKButtonClick(this.state.userId, this.state.password);
  };
}