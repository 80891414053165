import * as React from "react";

import { ChildLayout } from "@lib/box/box-properties";
import { DownOutlined, NumberOutlined, RightOutlined } from "@ant-design/icons";

export interface BoxButtonToggleChildLayoutProps {
	onMouseEnter: (event: React.MouseEvent<HTMLDivElement>) => void;
	onMouseLeave: (event: React.MouseEvent<HTMLDivElement>) => void;
	onClick: (event: React.MouseEvent<HTMLDivElement>) => void;

	canToggleChildLayout: boolean;
	isToggleChildLayoutButtonHovered: boolean;

	childLayout: ChildLayout;

	boxInternalWidthInPixels: number;
	buttonSizeInPixels: number;
	buttonBorderRadiusInPixels: number;
	buttonOffsetInPixels: number;
}

export const BoxButtonToggleChildLayout = React.forwardRef((props: BoxButtonToggleChildLayoutProps,
	ref: React.ForwardedRef<HTMLDivElement>) => {
	const {
		onMouseEnter,
		onMouseLeave,
		onClick,
		canToggleChildLayout,
		isToggleChildLayoutButtonHovered,
		childLayout,
		boxInternalWidthInPixels,
		buttonSizeInPixels,
		buttonBorderRadiusInPixels,
		buttonOffsetInPixels
	} = props;

	if (!canToggleChildLayout) {
		return null
	}

	// Determine the Toggle Child Layout button text and background color
	const toggleChildLayoutTextColor = isToggleChildLayoutButtonHovered
		? "#FFF"
		: "#FFF";
	const toggleChildLayoutBackgroundColor = isToggleChildLayoutButtonHovered
		? "#0094FF"
		: "#004A7F";

	// Determine the name of the Toggle Child Layout icon
	// TODO: Find better icons
	// We probably should also remove the use of AntDesign icons as they're
	// hosted remotely at http://iconfont.cn/

	let IconControl = DownOutlined;
	
	if (childLayout === "horizontal") {
		IconControl = RightOutlined;
	} else if (childLayout === "vertical") {
		IconControl = DownOutlined;
	} else if (childLayout === "grid") {
		IconControl = NumberOutlined;
	}

	// Render the ToggleChildLayout Box button in the same position as the default badge
	return (
		<div
			ref={ref}
			style={{
				display: "inline",
				position: "absolute",
				top: -buttonOffsetInPixels,
				left:
					-buttonOffsetInPixels +
					boxInternalWidthInPixels / 2,
				width: buttonSizeInPixels,
				height: buttonSizeInPixels,
				backgroundColor: toggleChildLayoutBackgroundColor,
				borderRadius: buttonBorderRadiusInPixels,
				textAlign: "center",
				paddingTop: 5,
				paddingLeft: 1,
			}}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={onClick}
		>
			<IconControl
				style={{
					color: toggleChildLayoutTextColor,
				}}
			/>
		</div>
	);
});