import React from "react";
import * as boxTypeLib from '@lib/box/box-type';
import BoxTypeOptions from "./BoxTypeOptions";
import LabelledElement from "@components/molecules/LabelledElement";
export interface MixinBoxTypesProps {
	mixinBoxTypes: string | undefined,
	boxTypes: boxTypeLib.BoxTypeMap | undefined,
	onMixinBoxTypesInputChange: (value: string[]) => void
}

const MixinBoxTypes: React.FC<MixinBoxTypesProps> = (props) => {

	if (!props.boxTypes) {
		return null;
	}

	// Get the keys of the mixin box types
	const selectedValues = boxTypeLib.getMixinBoxTypeKeys(props.mixinBoxTypes);

	return (
		<>

			<LabelledElement labelText="Global Lens Type">

				<BoxTypeOptions selectedValues={selectedValues} onMixinBoxTypesInputChange={props.onMixinBoxTypesInputChange} boxTypes={props.boxTypes} />

			</LabelledElement>
		</>
	);
};

export default MixinBoxTypes;