import React from "react";

import { Modal, Card, Row, Col } from "antd";

import * as boxLib from "@lib/box/box";
import * as boxTypeLib from "@lib/box/box-type";

const DEFAULT_ASSOCIATION_TYPE = "DefaultAssociation";

interface GenericMap<T> {
	[key: string]: T;
}

interface BoxTypeKeyMap {
	[key: string]: string[];
}

interface MappingViewModel {
	name: string;
	from: BoxTypeViewModel;
	to: BoxTypeViewModel;
}

interface BoxTypeViewModel {
	name: string;
	attributeTypes: GenericMap<AttributeTypeViewModel>;
}

interface AttributeTypeViewModel {
	name: string;
}

interface Mapping {
	selectedSourceAttributeTypes: string[];
	selectedDestinationBoxTypeAttributes: BoxTypeKeyMap;
}

interface CreateSimpleAssociationsDialogProps {
	// illustration: any | undefined,
	onOKButtonClick: (boxes: boxLib.BoxMap | undefined) => void;
	onCancelButtonClick: () => void;
	isVisible: boolean;
	firstSelectedBoxKey: string;
	boxSelectionInfoMap: any;
	flattenedBoxMap: boxLib.BoxMap;
	boxTypeMap: boxTypeLib.BoxTypeMap;
}

interface CreateSimpleAssociationsDialogState {
	sourceBox: boxLib.Box;
	sourceBoxType: boxTypeLib.BoxType;
	destinationBoxes: boxLib.BoxMap;
	destinationBoxTypes: boxTypeLib.BoxTypeMap;
	mappings: GenericMap<Mapping>;
	mappingViewModels: GenericMap<MappingViewModel>;
}

export default class CreateAssociationsDialog extends React.Component<
	CreateSimpleAssociationsDialogProps,
	CreateSimpleAssociationsDialogState
> {
	constructor(props: CreateSimpleAssociationsDialogProps) {
		super(props);

		const updatedState = this.updateState(
			this.props.firstSelectedBoxKey,
			this.props.boxSelectionInfoMap,
			this.props.flattenedBoxMap,
			this.props.boxTypeMap
		);

		this.state = updatedState;
	}

	private updateState(
		firstSelectedBoxKey: string,
		boxSelectionInfoMap: any,
		flattenedBoxMap: boxLib.BoxMap,
		boxTypeMap: boxTypeLib.BoxTypeMap
	): any {
		const updatedBoxTypeMap = JSON.parse(JSON.stringify(boxTypeMap));

		const updatedFlattenedBoxMap = JSON.parse(
			JSON.stringify(flattenedBoxMap)
		);

		// Get the box for our first box key
		const firstSelectedBox = updatedFlattenedBoxMap[firstSelectedBoxKey];

		const destinationBoxKeys = JSON.parse(
			JSON.stringify(boxSelectionInfoMap)
		);

		// Delete the first box from the info map
		delete destinationBoxKeys[firstSelectedBoxKey];

		const destinationBoxes: boxLib.BoxMap = {};

		const destinationBoxTypes: boxTypeLib.BoxTypeMap = {};

		// Build the destination boxes list and the destination box types list
		for (let key of Object.keys(destinationBoxKeys)) {
			destinationBoxes[key] = updatedFlattenedBoxMap[key];
			let boxType = destinationBoxes[key].boxType;
			destinationBoxTypes[boxType] = updatedBoxTypeMap[boxType];
		}

		const sourceBoxTypeKey = firstSelectedBox.boxType;

		const sourceBoxType: boxTypeLib.BoxType | null =
			updatedBoxTypeMap[sourceBoxTypeKey];

		return {
			sourceBox: firstSelectedBox,
			sourceBoxType: sourceBoxType,
			destinationBoxes: destinationBoxes,
			destinationBoxTypes: destinationBoxTypes,
		};
	}

	private addStringToArray(key: string, array: string[]) {
		// console.log(`adding ${key} to ${JSON.stringify(array)}`);
		const arrayCopy = JSON.parse(JSON.stringify(array));

		const index = arrayCopy.indexOf(key, 0);

		// If the item doesn't exist on the array
		if (index < 0) {
			// Add it
			arrayCopy.push(key);
		}

		return arrayCopy;
	}

	public componentWillReceiveProps(
		nextProps: CreateSimpleAssociationsDialogProps
	) {
		// Only update if we're showing the dialog
		if (!this.props.isVisible && nextProps.isVisible) {
			const updatedState = this.updateState(
				this.props.firstSelectedBoxKey,
				this.props.boxSelectionInfoMap,
				this.props.flattenedBoxMap,
				this.props.boxTypeMap
			);

			this.setState(updatedState);
		}
	}

	public render() {
		return (
			<Modal
				title={"Create Associations"}
				open={this.props.isVisible}
				onOk={this.handleOKButtonClick}
				onCancel={this.props.onCancelButtonClick}
				width="75%"
				zIndex={9999}
			>
				<div>
					{this.state.sourceBox && this.state.destinationBoxes ? (
						<div>
							<h1>Creating Two Way Associations</h1>
							<Row gutter={6}>
								<Col span={12}>
									<Card
										title="From"
										style={{ marginLeft: "16px" }}
									>
										{this.state.sourceBox.name}
									</Card>
								</Col>
								<Col span={12}>
									<Card title="To">
										<ul>
											{Object.keys(
												this.state.destinationBoxes
											).map((key) => {
												return (
													<li key={key}>
														{
															this.state
																.destinationBoxes[
																key
															].name
														}
													</li>
												);
											})}
										</ul>
									</Card>
								</Col>
							</Row>
						</div>
					) : null}
				</div>
			</Modal>
		);
	}

	private getBoxKeysFromAttributeValue = (attributeValue: string) => {
		return attributeValue && attributeValue.length > 0
			? attributeValue.split(",")
			: [];
	};

	private addNewBoxKeysToBox = (
		box: boxLib.Box,
		newBoxKeys: string[],
		attributeTypeKey: string
	) => {
		if (box.attributes !== undefined && box.attributes !== null) {
			// Grab the existing value
			const attributeValue = String(box.attributes[attributeTypeKey]);

			let boxKeys = this.getBoxKeysFromAttributeValue(attributeValue);

			// For every destination box, add it's key to the attribute
			newBoxKeys.forEach((boxKey: string) => {
				boxKeys = this.addStringToArray(boxKey, boxKeys);
			});
			box.attributes[attributeTypeKey] = boxKeys.join(",");
		}
	};

	private handleOKButtonClick = () => {
		// We want to add the source box to every attribute we've selected on the destination boxes
		const updatedBoxMap: boxLib.BoxMap = this.state.destinationBoxes;

		const sourceBoxKey = this.props.firstSelectedBoxKey;
		// console.log("Keys are!" + Object.keys(this.state.selectedDestinationBoxTypeAttributes));

		const updatedSourceBox = this.state.sourceBox;

		const attributeTypeKey = DEFAULT_ASSOCIATION_TYPE;

		// We want to add the destination boxes to our default association type
		if (updatedSourceBox) {
			this.addNewBoxKeysToBox(
				updatedSourceBox,
				Object.keys(this.state.destinationBoxes),
				attributeTypeKey
			);

			// We want to add the source box to every destination box
			Object.keys(this.state.destinationBoxes).forEach(
				(boxKey: string) => {
					const box = this.state.destinationBoxes[boxKey];

					this.addNewBoxKeysToBox(
						box,
						[this.props.firstSelectedBoxKey],
						attributeTypeKey
					);
				}
			);

			// Add the source box to our list of updated boxes
			updatedBoxMap[sourceBoxKey] = updatedSourceBox;
		}

		// Notify the callback that the OK button has been clicked, passing the new
		// box name and box type key
		this.props.onOKButtonClick(updatedBoxMap);
	};
}
