import { PlusOutlined } from "@ant-design/icons";
import * as lensesLib from "@lib/lenses/lenses";
import { Button, Modal } from "antd";
import React, { useState } from "react";
import RightMenuHeading from "../../../organisms/RightMenuHeading";
import RightMenuHeadingText from "../../../organisms/RightMenuHeadingText";
import AddEditSimpleLensGroupDialog from "./add-edit-simple-lens-group-dialog/AddEditSimpleLensGroupDialog";
import * as boxTypeLib from "@lib/box/box-type";
import { v4 as uuid } from "uuid";
import SimpleLensGroupList from "@components/molecules/SimpleLensGroupList";
const confirm = Modal.confirm;

export interface SimpleLensesMenuProps {
	onEdit: ((lensGroupKey: string, simpleLensGroup: lensesLib.SimpleLensGroup) => void),
	onDelete: (lensGroupKey: string) => void,
	lensGroupMap: lensesLib.SimpleLensGroupMap | undefined;
	canEdit: boolean,
	canDelete: boolean,
	canAdd: boolean,
	onAdd: (lensGroupKey: string, simpleLensGroup: lensesLib.SimpleLensGroup) => void,
	boxTypes: boxTypeLib.BoxTypeMap | undefined,
}

const SimpleLensesMenu: React.FC<SimpleLensesMenuProps> = (props) => {
	const [addEditSimpleLensGroupDialogIsVisibile, setAddEditSimpleLensGroupDialogIsVisibile] = useState(false);
	const [addEditSimpleLensGroupDialogIsAdding, setAddEditSimpleLensGroupDialogIsAdding] = useState(true);

	const [addEditSimpleLensGroup, setAddEditSimpleLensGroup] = useState<lensesLib.SimpleLensGroup | null>(null);
	const [addEditSimpleLensGroupKey, setAddEditSimpleLensGroupKey] = useState<string | null>(null);

	const createNewSimpleLensGroup = (): lensesLib.SimpleLensGroup => {
		return {
			name: "",
			description: "",
			order: 0,
			lenses: {}
		};
	};

	return <>
		{props.boxTypes && addEditSimpleLensGroup ?
			<AddEditSimpleLensGroupDialog
				isAdding={addEditSimpleLensGroupDialogIsAdding}
				boxTypes={props.boxTypes}
				isVisible={addEditSimpleLensGroupDialogIsVisibile}
				onCancelButtonClick={() => { setAddEditSimpleLensGroupDialogIsVisibile(false); }}

				simpleLensGroup={addEditSimpleLensGroup}
				onOKButtonClick={(simpleLensGroup) => {
					// here we want to hide the form and then tell the parent control that we're adding a group lens

					const newLensGroup = JSON.parse(JSON.stringify(simpleLensGroup));
					if (addEditSimpleLensGroupKey !== null) {
						if (addEditSimpleLensGroupDialogIsAdding) {
							props.onAdd(addEditSimpleLensGroupKey, newLensGroup);
						}
						else {
							props.onEdit(addEditSimpleLensGroupKey, newLensGroup);
						}
					}
					
					setAddEditSimpleLensGroup(null);
					setAddEditSimpleLensGroupKey(null);
					setAddEditSimpleLensGroupDialogIsVisibile(false);
				}}
			/> : null}

		<RightMenuHeading>
			<RightMenuHeadingText>Lens Groups</RightMenuHeadingText>
			<Button
				icon={<PlusOutlined />}
				disabled={!(props.canAdd)}
				type="primary"
				style={{
					paddingRight: "12px",
				}}
				onClick={
					() => {
						setAddEditSimpleLensGroup(createNewSimpleLensGroup());
						setAddEditSimpleLensGroupDialogIsAdding(true);
						setAddEditSimpleLensGroupKey(uuid());
						setAddEditSimpleLensGroupDialogIsVisibile(true);
					}}
			>
				Add
			</Button>
		</RightMenuHeading>
		<div
			style={{
				padding: "12px",
			}}
		>
			<SimpleLensGroupList
				onSimpleLensGroupEdit={
					(lensGroupKey : string) => {
						if (props.lensGroupMap !== undefined) {
							setAddEditSimpleLensGroup(props.lensGroupMap[lensGroupKey]);
							setAddEditSimpleLensGroupDialogIsAdding(false);
							setAddEditSimpleLensGroupKey(lensGroupKey);
							setAddEditSimpleLensGroupDialogIsVisibile(true);
						}
					}
				}
				onSimpleLensGroupDelete={
					(lensGroupKey : string) => {
						confirm({
							title:
								"Are you sure delete this Lens Group?",
							okText: "Yes",
							okType: "danger",
							cancelText: "No",
							zIndex: 9999999,
							onOk: () => { props.onDelete(lensGroupKey) },
							onCancel: () => { },
						});

					}
				}
				lensGroupMap={
					props.lensGroupMap
				}
				canEdit={
					props.canEdit
				}
				canDelete={
					props.canDelete
				}
			/>
		</div>
	</>
};

export default SimpleLensesMenu;