import { Button } from "antd";
import React from "react";
import RightMenuHeading from "../../../organisms/RightMenuHeading";
import RightMenuHeadingText from "../../../organisms/RightMenuHeadingText";

export interface JsonEditorMenuProps {
	isJSONImportButtonsDisabled: boolean,
	onIllustrationImportButtonClick: any,
	isJSONExportButtonsDisabled: boolean,
	isExcelExportButtonsDisabled: boolean,
	isExcelImportButtonsDisabled: boolean,
	onIllustrationExportButtonClick: any,
	onIllustrationImportExcelButtonClick: any;
	onIllustrationExportExcelButtonClick: any
}

const JsonEditorMenu: React.FC<JsonEditorMenuProps> = (props) => {
	return <>
		<RightMenuHeading>
			<RightMenuHeadingText>Import/Export</RightMenuHeadingText>
		</RightMenuHeading>
		<div
			style={{

				display: "grid",
				gridTemplateColumns: "1fr 1fr",
				gap: "6px 6px",
				paddingBottom: "12px",
				margin: "2px",
			}}
		>
			{ props.isJSONImportButtonsDisabled ? <></> : <Button
				size="small"
				type="primary"
				disabled={
					props.isJSONImportButtonsDisabled
				}
				onClick={
					props
						.onIllustrationImportButtonClick
				}
			>
				Import
			</Button> }

			{ props.isExcelImportButtonsDisabled ? <></> : <Button
				size="small"
				type="primary"
				disabled={
					props.isExcelImportButtonsDisabled
				}
				onClick={
					props
						.onIllustrationImportExcelButtonClick
				}
			>
				Import (Excel)
			</Button> }

			{ props.isJSONExportButtonsDisabled ? <></> : <Button
				size="small"
				type="primary"
				disabled={
					props.isJSONExportButtonsDisabled
				}
				onClick={
					props
						.onIllustrationExportButtonClick
				}
			>
				Export
			</Button> }

			{ props.isExcelExportButtonsDisabled ? <></> : <Button
				size="small"
				type="primary"
				disabled={
					props.isExcelExportButtonsDisabled
				}
				onClick={
					props
						.onIllustrationExportExcelButtonClick
				}
			>
				Export (Excel)
			</Button> }
		</div>
	</>
};

export default JsonEditorMenu;