import axios, { AxiosResponse } from 'axios';
import config from '../config';

export interface ModelService<TListItem, TItem, TCreateCommand, TUpdateCommand> {
	getList: (success: (results: TListItem[] | null) => void, failure: (error: string) => void) => void,
	get: (id: string, success: (result: TItem | null) => void, failure: (error: string) => void) => void,
	put: (id: string, data: TUpdateCommand, success: (result: TItem | null) => void, failure: (error: string) => void) => void,
	post: (data: TCreateCommand, success: (result: TItem | null) => void, failure: (error: string) => void) => void,
	remove: (id: string, success: (result: any | null) => void, failure: (error: string) => void) => void,
}

const getList = <TListItem>(entityName: string, success: (results: TListItem[] | null) => void, failure: (error: string) => void): void => {
	const url = config.apiURL + entityName;
	try {
		axios.get(url, { withCredentials: true }).then((res: AxiosResponse<TListItem[]>): void => {
			success(res.data);
		}).catch((error) => {
			console.log("This is an error")
			console.log(error);
			failure(error);
		});
	} catch (err) {
		console.log("Didn't get caught by axios");
		console.log(err);
		failure("HTTP action failed.");
	}
};

const get = <TItem>(entityName: string, id: string, success: (result: TItem | null) => void, failure: (error: string) => void): void => {
	const controller = new AbortController();

	const timeout = setTimeout(() => {
	  controller.abort();
	}, config.webServiceTimeout);
	
	const url = `${config.apiURL}${entityName}/${id}`;
	try {
		axios.get(url, { withCredentials: true, signal: controller.signal }).then((res: AxiosResponse<any>) => {
			clearTimeout(timeout);
			success(res.data);
		}).catch((error) => {
			console.log("This is an error")
			console.log(error);
			failure(error);
		});
	} catch (err) {
		console.log("Didn't get caught by axios");
		console.log(err);
		failure("HTTP action failed.");
	}
};

const put = <TUpdateCommand, TItem>(entityName: string, id: string, data: TUpdateCommand, success: (result: TItem | null) => void, failure: (error: string) => void): void => {
	const controller = new AbortController();

	const timeout = setTimeout(() => {
	  controller.abort();
	}, config.webServiceTimeout);

	const url = `${config.apiURL}${entityName}/${id}`;

	try {
		axios.put(url, data, { withCredentials: true, signal: controller.signal }).then((res: AxiosResponse<any>) => {
			clearTimeout(timeout);
			console.log(res);
			success(res.data);
		}).catch((error) => {
			console.log("This is an error")
			console.log(error);
			failure(error);
		});
	} catch (err) {
		console.log("Didn't get caught by axios");
		console.log(err);
		failure("HTTP action failed.");
	}

};

// TODO: Figure out what we are returning when we delete
const remove = (entityName: string, id: string, success: (result: any | null) => void, failure: (error: string) => void): void => {
	const controller = new AbortController();

	const timeout = setTimeout(() => {
	  controller.abort();
	}, config.webServiceTimeout);

	const url = `${config.apiURL}${entityName}/${id}`;

	try {
		axios.delete(url, { withCredentials: true, signal: controller.signal }).then((res: AxiosResponse<any>) => {
			clearTimeout(timeout);
			console.log(res);
			success(res.data);
		}).catch((error) => {
			console.log("This is an error")
			console.log(error);
			failure(error);
		});
	} catch (err) {
		console.log("Didn't get caught by axios");
		console.log(err);
		failure("HTTP action failed.");
	}

};

const post = <TCreateCommand, TItem>(entityName: string, data: TCreateCommand, success: (result: TItem | null) => void, failure: (error: string) => void): void => {
	const controller = new AbortController();

	const timeout = setTimeout(() => {
	  controller.abort();
	}, config.webServiceTimeout);

	const url = `${config.apiURL}${entityName}`;

	try {
		axios.post(url, data, { withCredentials: true, signal: controller.signal }).then((res: AxiosResponse<any>) => {
			clearTimeout(timeout);
			console.log(res);
			success(res.data);
		}).catch((error) => {
			console.log("This is an error")
			console.log(error);
			failure(error);
		});
	} catch (err) {
		console.log("Didn't get caught by axios");
		console.log(err);
		failure("HTTP action failed.");
	}
};

export const makeModelService = <TListItem, TItem, TCreateCommand, TUpdateCommand>(entityName: string): ModelService<TListItem, TItem, TCreateCommand, TUpdateCommand>  => {
	return ({
		getList: (success: (results: TListItem[] | null) => void, failure: (error: string) => void) => { return getList<TListItem>(entityName, success, failure) },
		get: (id: string, success: (result: TItem | null) => void, failure: (error: string) => void) => { return get<TItem>(entityName, id, success, failure) },
		put: (id: string, data: TUpdateCommand, success: (result: TItem | null) => void, failure: (error: string) => void) => { return put<TUpdateCommand, TItem>(entityName, id, data, success, failure) },
		post: (data: TCreateCommand, success: (result: TItem | null) => void, failure: (error: string) => void) => { return post<TCreateCommand, TItem>(entityName, data, success, failure) },
		remove: (id: string, success: (result: any | null) => void, failure: (error: string) => void) => { return remove(entityName, id, success, failure) },
	})
};