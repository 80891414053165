import React from "react";
import { MixinVisibilityItem } from "../hooks/useMixinVisibilityList";
import { BoxTypeMap } from "@lib/box/box-type";
import * as boxTypeLib from "@lib/box/box-type";
import FormHeading from "@components/organisms/FormHeading";
import FormHeadingText from "@components/organisms/FormHeadingText";
import { Button } from "antd";
import { PlusOutlined, PropertySafetyFilled } from "@ant-design/icons";
import MixinVisibilityTable from "@components/molecules/MixinVisibilityTable";
import AddEditMixinVisibilityDialog from "../AddEditMIxinVisibilityDialog";
interface MixinVisibilityListProps {
	boxTypes: BoxTypeMap,
	mixinVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined,

	isAddEditMixinVisibilityItemDialogVisible: boolean,
	isAddEditMixinVisibilityItemDialogAdding: boolean,
	addEditMixinVisibilityItem: MixinVisibilityItem | null,

	onDeleteMixinVisibilityItem: (key: string) => void,
	onAddEditMixinVisibilityItemFormOk: (mixinVisibilityItem: MixinVisibilityItem) => void,
	onCloseAddEditMixinVisibilityForm: () => void,
	onOpenEditMixinVisibilityForm: (key: string) => void,
	onOpenAddMixinVisibilityForm: () => void
}

// ## Mixin Visibility - List, where you can pick the box type and a true/false value


const MixinVisibilityList: React.FC<MixinVisibilityListProps> = ({ 
	boxTypes,
	mixinVisibilityMap,

	isAddEditMixinVisibilityItemDialogVisible,
	isAddEditMixinVisibilityItemDialogAdding,
	addEditMixinVisibilityItem,

	onDeleteMixinVisibilityItem,
	onAddEditMixinVisibilityItemFormOk,
	onCloseAddEditMixinVisibilityForm,
	onOpenEditMixinVisibilityForm,
	onOpenAddMixinVisibilityForm
}) => {

	return <>
		<AddEditMixinVisibilityDialog
			onCancelButtonClick={onCloseAddEditMixinVisibilityForm}
			isAdding={isAddEditMixinVisibilityItemDialogAdding}
			isVisible={isAddEditMixinVisibilityItemDialogVisible}
			
			boxTypes={boxTypes}
			onOKButtonClick={onAddEditMixinVisibilityItemFormOk}

			mixinVisibilityItem={addEditMixinVisibilityItem}
		/>
		<FormHeading>
			<FormHeadingText>Mixin Visibility</FormHeadingText>
			<div>
				<Button
					icon={<PlusOutlined />}
					type="primary"
					style={{
						paddingRight: "12px",
						marginRight: "12px",
					}}
					onClick={onOpenAddMixinVisibilityForm}
				>
					Add
				</Button>
			</div>
		</FormHeading>
		<MixinVisibilityTable
			mixinVisibilityMap={mixinVisibilityMap !== undefined ? mixinVisibilityMap : {}}
			boxTypes={boxTypes}
			canDelete={true}
			canEdit={true}
			onDelete={onDeleteMixinVisibilityItem}
			onEdit={onOpenEditMixinVisibilityForm}
		/> 
	</>;
}

export default MixinVisibilityList;