import { PlusOutlined } from "@ant-design/icons";
import { VisibilityStateMap } from "@lib/box/visibility-state";
import { Button } from "antd";
import React from "react";
import { VisibilityStatesList } from "../../../molecules/VisibilityStatesList";
import RightMenuHeading from "../../../organisms/RightMenuHeading";
import RightMenuHeadingText from "../../../organisms/RightMenuHeadingText";

export interface StoriesMenuProps {
	onVisibilityStateEdit: ((visibilityStateKey: string) => void),
	onVisibilityStateDelete: (visibilityStateKey: string) => void,
	visibilityStateMap: VisibilityStateMap | undefined,
	canEdit: boolean,
	canDelete: boolean,
	canAdd: boolean,
	currentVisibilityStateKey: string,
	onVisibilityStateAdd: () => void

}

const StoriesMenu: React.FC<StoriesMenuProps> = (props) => {
	return <><RightMenuHeading>
		<RightMenuHeadingText>Stories</RightMenuHeadingText>
		<Button
			icon={<PlusOutlined />}
			disabled={!(props.canAdd)}
			type="primary"
			style={{
				paddingRight: "12px",
			}}
			onClick={props.onVisibilityStateAdd}
		>
			Add
		</Button>
	</RightMenuHeading>
		<div
			style={{
				padding: "12px",
			}}
		>
			<VisibilityStatesList
				onVisibilityStateEdit={
					props.onVisibilityStateEdit
				}
				onVisibilityStateDelete={
					props.onVisibilityStateDelete
				}
				visibilityStateMap={
					props.visibilityStateMap
				}
				canEdit={
					props.canEdit
				}
				canDelete={
					props.canDelete
				}
				currentVisibilityStateKey={
					props.currentVisibilityStateKey
				}
			/>
		</div>
	</>
};

export default StoriesMenu;