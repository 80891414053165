import { useState, useEffect } from "react";
import * as boxTypeLib from "@lib/box/box-type";
// ## Attribute Type Visiblity - List, where you can pick the attribute type and whether or not it is 
// - mixinVisibilitys - list
// - mixinVisibilityOperator - dropdown

export interface MixinVisibilityItem {
	key: string;
	value:  boxTypeLib.BoxTypeVisibility;
}

const createMixinVisibilityItemList = (visibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined) => {
	return visibilityMap !== undefined ? Object.keys(visibilityMap).map((key) => {
		return { key: key, value: visibilityMap[key] };
	}) : [];
};

const useMixinVisibilityList = (sourceVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined) => {
	// Cache this so that we can return it at the end
	const [mixinVisibilityMap, setMixinVisibilityMap] = useState<boxTypeLib.BoxTypeVisibilityMap | undefined>(sourceVisibilityMap);

		// Add/Edit Attribute Type Visibility Form Variables
	const [isAddEditMixinVisibilityItemDialogVisible, setIsAddEditMixinVisibilityItemDialogVisible] = useState(false);
	const [isAddEditMixinVisibilityItemDialogAdding, setIsAddEditMixinVisibilityItemDialogAdding] = useState(false);
	const [addEditMixinVisibilityItem, setAddEditMixinVisibilityItem] = useState<MixinVisibilityItem | null>(null);


	useEffect(() => {
		setMixinVisibilityMap(sourceVisibilityMap);

		// Reset the form values
		setIsAddEditMixinVisibilityItemDialogVisible(false);
		setIsAddEditMixinVisibilityItemDialogAdding(false);
		setAddEditMixinVisibilityItem(null);
	}, [sourceVisibilityMap])

	const onOpenAddMixinVisibilityForm = () => {
		setIsAddEditMixinVisibilityItemDialogAdding(false);
		setAddEditMixinVisibilityItem(null);
		setIsAddEditMixinVisibilityItemDialogVisible(true);
	}

	const onOpenEditMixinVisibilityForm = (key: string) => {
		if (mixinVisibilityMap !== undefined) {
			setAddEditMixinVisibilityItem({ key, value: mixinVisibilityMap[key] });
			setIsAddEditMixinVisibilityItemDialogAdding(false);
			setIsAddEditMixinVisibilityItemDialogVisible(true);
		}
	}

	const onCloseAddEditMixinVisibilityForm = () => {
		setIsAddEditMixinVisibilityItemDialogVisible(false);
		setIsAddEditMixinVisibilityItemDialogAdding(false);
		setAddEditMixinVisibilityItem(null);
	};

	const onAddEditMixinVisibilityItemFormOk = (mixinVisibilityItem: MixinVisibilityItem) => {
		const newLocalVisibilityMap = mixinVisibilityMap !== undefined && mixinVisibilityMap !== null ? JSON.parse(JSON.stringify(mixinVisibilityMap)) : {};

		// If we have changed the key, delete the old value)
		if (addEditMixinVisibilityItem !== null && addEditMixinVisibilityItem.key !== mixinVisibilityItem.key) {
			delete newLocalVisibilityMap[addEditMixinVisibilityItem.key];
		}

		newLocalVisibilityMap[mixinVisibilityItem.key] = mixinVisibilityItem.value;
		setMixinVisibilityMap(newLocalVisibilityMap)
		setIsAddEditMixinVisibilityItemDialogVisible(false);
	};

	const onDeleteMixinVisibilityItem = (key: string) => {
		const newMixinVisibilitys = JSON.parse(JSON.stringify(mixinVisibilityMap));
		delete newMixinVisibilitys[key];
		setMixinVisibilityMap(newMixinVisibilitys);
		setIsAddEditMixinVisibilityItemDialogVisible(false);
	};


	return {
		mixinVisibilityMap,

		isAddEditMixinVisibilityItemDialogVisible,
		isAddEditMixinVisibilityItemDialogAdding,
		addEditMixinVisibilityItem,

		onDeleteMixinVisibilityItem,
		onAddEditMixinVisibilityItemFormOk,
		onCloseAddEditMixinVisibilityForm,
		onOpenEditMixinVisibilityForm,
		onOpenAddMixinVisibilityForm,
	};
}

export default useMixinVisibilityList;