import { v4 as uuid } from "uuid";

import * as attributeTypeLib from "./attribute-type";
import { RenderFunctionLatchModeVisibilityMap } from "./render-function";

export interface BoxType {
	name: string;
	description: string;
	attributeTypes: attributeTypeLib.AttributeTypeMap;
	mixinBoxTypes: string;
	disableBoxCreation: boolean;
}

export type BoxTypeMap = { [key: string]: BoxType };

export interface BoxTypeVisibility {
	isVisible: boolean;
	areBoxesVisible: boolean;
	areBoxesInLayout: boolean;
	mixinBoxTypeVisibilityMap: BoxTypeVisibilityMap | undefined;
	attributeTypeVisibilityMap:
	| attributeTypeLib.AttributeTypeVisibilityMap
	| undefined;
	renderFunctionLatchModeVisibilityMap : RenderFunctionLatchModeVisibilityMap | undefined;
}

export type BoxTypeVisibilityMap = {
	[key: string]: BoxTypeVisibility;
};

// Used to cache attribute types
let private_boxTypeAttributeTypeCache: Record<string, attributeTypeLib.AttributeTypeMap> = {};

export const initializeBoxTypeAttributeTypeCache = (boxTypes?: BoxTypeMap) => {
	if(!boxTypes) {
		return;
	}

	private_boxTypeAttributeTypeCache = {};

	const boxTypeKeys = Object.keys(boxTypes);
	for (let i=0; i < boxTypeKeys.length; i += 1) {
		const boxTypeKey = boxTypeKeys[i];
		// Get the box attribute types
		const attributeTypes: attributeTypeLib.AttributeTypeMap = {};
		setAttributeTypesForBoxTypeRecursive(attributeTypes,
			boxTypeKey,
			boxTypes);

		private_boxTypeAttributeTypeCache[boxTypeKey] = attributeTypes;
	};
}

export const clearBoxTypeAttributeTypeCache = () => {
	private_boxTypeAttributeTypeCache = {};
}

export const clearBoxTypeAttributeTypeCacheForType = (boxTypeKey: string) => {
	delete private_boxTypeAttributeTypeCache[boxTypeKey];
}

export const setBoxTypeAttributeTypeCacheForType = (boxTypeKey: string,
	attributeTypes: attributeTypeLib.AttributeTypeMap) => {
	private_boxTypeAttributeTypeCache[boxTypeKey] = attributeTypes;
}

export const getBoxTypeAttributeTypeCacheForType = (boxTypeKey: string): attributeTypeLib.AttributeTypeMap => {
	const cachedAttributeTypes = private_boxTypeAttributeTypeCache[boxTypeKey];
	if (!cachedAttributeTypes) {
		return {};
	}

	return cachedAttributeTypes;
}


export const createDefaultBoxType = (): BoxType => {
	return {
		name: "",
		description: "",
		attributeTypes: {},
		mixinBoxTypes: "",
		disableBoxCreation: false
	};
};

export const getNewBoxTypeKey = () => uuid();

let private_mixinBoxTypeCachingEnabled = false;
let private_mixinBoxTypeCache: Record<string, string[]> = {};

export const setMixinBoxTypeCacheEnabled = (isEnabled: boolean): void => {
	private_mixinBoxTypeCachingEnabled = isEnabled;
}

export const clearMixinBoxTypeCache = (): void => {
	private_mixinBoxTypeCache = {};
}

const getCachedMixinBoxTypes = (boxTypeKey: string): string[] | undefined => {
	if (!private_mixinBoxTypeCachingEnabled) {
		return undefined;
	}

	const cachedMixinBoxTypes = private_mixinBoxTypeCache[boxTypeKey];
	return cachedMixinBoxTypes;
}

const setCachedMixinBoxTypes = (boxTypeKey: string,
	mixinBoxTypes: string[]): void => {
	if (private_mixinBoxTypeCachingEnabled) {
		private_mixinBoxTypeCache[boxTypeKey] = mixinBoxTypes;
	}
}

export const getMixinBoxTypeKeys = (mixinBoxTypes: string | undefined): string[] => {
	// Get the box types we'll be using as mixins
	const boxTypes = mixinBoxTypes ? mixinBoxTypes : "";

	const cachedMixinBoxTypes = getCachedMixinBoxTypes(boxTypes);
	if (cachedMixinBoxTypes) {
		return cachedMixinBoxTypes;
	}

	// Get them as an array, sorted alphabetically
	const mixinBoxTypeKeys: string[] =
		boxTypes.length > 0 ? boxTypes.split(",").sort() : [];

	// Cache the mixin box types
	setCachedMixinBoxTypes(boxTypes, mixinBoxTypeKeys);

	return mixinBoxTypeKeys;
};

export const getMixinBoxTypeKeysRecursive = (boxTypeKey: string,
	boxTypes: BoxTypeMap | undefined
): string[] => {
	// If we don't have box types, do nothing
	if (!boxTypes) {
		return [];
	}

	// Get the box type
	const boxType: BoxType = boxTypes[boxTypeKey];
	if (!boxType) {
		return [];
	}

	// Get the keys of the mixin box types
	const mixinBoxTypeKeys = getMixinBoxTypeKeys(boxType.mixinBoxTypes);

	// Get the parent mixin box types of each of the box type mixins
	const parentMixinBoxTypeKeys = mixinBoxTypeKeys
		.reduce((acc: string[], mixinBoxTypeKey: string): string[] => {
			const otherMixinBoxTypeKeys = getMixinBoxTypeKeysRecursive(mixinBoxTypeKey,
				boxTypes);

			return [
				...acc,
				...otherMixinBoxTypeKeys
			]
		}, []);

	return [
		...mixinBoxTypeKeys,
		...parentMixinBoxTypeKeys,
	]
}

export const getBoxTypeKeyinObj = (obj: Record<string, unknown>,
	boxTypeKey: string,
	boxType: BoxType): string => {
	if (Object.prototype.hasOwnProperty.call(obj, boxTypeKey)) {
		return boxTypeKey;
	}

	const mixinBoxTypeKeys = getMixinBoxTypeKeys(boxType.mixinBoxTypes);
	for (let i=0; i < mixinBoxTypeKeys.length; i += 1) {
		const mixinBoxTypeKey = mixinBoxTypeKeys[i];
		if (Object.prototype.hasOwnProperty.call(obj, mixinBoxTypeKey)) {
			return mixinBoxTypeKey;
		}
	}
	
	return '';
}

// Used to cache box type name lookups.
const private_boxTypeNameToTypeKeyCache: Record<string, string> = {}

export const findBoxTypeKeyForName = (
	boxTypeMap: BoxTypeMap,
	boxTypeName: string
): string => {
	const cachedResult = private_boxTypeNameToTypeKeyCache[boxTypeName];
	if (cachedResult && cachedResult !== "") {
		return cachedResult;
	}

	const boxTypeKeys = Object.keys(boxTypeMap);
	const foundIndex = boxTypeKeys.findIndex((boxTypeKey: string) => {
		return boxTypeMap[boxTypeKey].name === boxTypeName;
	});

	if (foundIndex < 0) {
		return "";
	}

	private_boxTypeNameToTypeKeyCache[boxTypeName] = boxTypeKeys[foundIndex];

	return boxTypeKeys[foundIndex];
};

export const setAttributeTypesForBoxTypeRecursive = (
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	boxTypeKey: string,
	boxTypes: BoxTypeMap | undefined,
	isMixinBoxType?: boolean,
) => {
	// If we don't have attribute types or box types, do nothing
	if (!attributeTypes || !boxTypes) {
		return;
	}

	// Get the box type
	const boxType: BoxType = boxTypes[boxTypeKey];
	if (boxType) {
		// Get the box type attribute types
		const boxTypeAttributeTypes = boxType.attributeTypes;
		if (boxTypeAttributeTypes) {
			const boxTypeAttributeTypeKeys = Object.keys(boxTypeAttributeTypes);

			// Add the attribute types from the box type
			for (let i=0; i < boxTypeAttributeTypeKeys.length; i += 1) {
				const boxAttributeTypeKey = boxTypeAttributeTypeKeys[i];

				// Do we have an attribute type?
				if (boxTypeAttributeTypes[boxAttributeTypeKey]) {
					// Get the attribute type
					let boxAttributeType = boxTypeAttributeTypes[boxAttributeTypeKey];

					// If we're adding a mixin attribute type, we need to handle
					// "default" attribute like `ID` or `DefaultAssociation`. To allow
					// the mixin default attribute to co-exist with the common attribute
					// of the box type, we can specialize the mixin attribute type by
					// prefixing the mixin type key to it.						
					let actualBoxAttributeTypeKey = boxAttributeTypeKey;

					if (isMixinBoxType && attributeTypeLib.isDefaultAttributeTypeKey(boxAttributeTypeKey)) {
						// We only want to do this for the Default Association Attribute Type
						if (!attributeTypeLib.isDefaultAssociationAttributeTypeKey(boxAttributeTypeKey)) {
							continue;
						}

						// Take a copy so we don't modify the original object
						boxAttributeType = JSON.parse(JSON.stringify(boxAttributeType));

						actualBoxAttributeTypeKey = attributeTypeLib.prefixAttributeTypeKey(boxAttributeTypeKey, boxTypeKey);
						boxAttributeType.name = `${boxType.name}.${boxAttributeType.name}`;
					}

					// Add it to the atrribute types
					attributeTypes[actualBoxAttributeTypeKey] = boxAttributeType;
				}
			}
		}

		// Get the keys of the mixin box types
		const mixinBoxTypeKeys = getMixinBoxTypeKeys(boxType.mixinBoxTypes);

		// Get the attribute types of each of the box type mixins
		for (let i=0; i < mixinBoxTypeKeys.length; i += 1) {
			const mixinBoxTypeKey = mixinBoxTypeKeys[i];

			// Set the attribute types for the box type
			setAttributeTypesForBoxTypeRecursive(
				attributeTypes,
				mixinBoxTypeKey,
				boxTypes,
				true
			);
		}
	}
};

export const isContainerBoxType = (boxTypeName: string): boolean => {
	// A box type is a container type if it has 'container' in it's name
	return boxTypeName.toLocaleLowerCase().includes("container");
};

export const setDefaultAttributeTypesForBoxType = (boxType: BoxType): void => {
	// For the ID attribute, don't add the attribute if the box type already has
	// it, and ignore any box types that are containers.
	if (!Object.prototype.hasOwnProperty.call(boxType.attributeTypes, "ID")) {
		const boxTypeName = boxType.name;
		if (!isContainerBoxType(boxTypeName)) {
			const idAttributeType: attributeTypeLib.AttributeType = {
				name: "ID",
				description: "ID",
				choices: "",
				order: -4,
				showInLegend: false,
				showInSummary: true,
				valueType: "text",
				defaultValue: "0",
				permittedAssociationBoxTypes: "",
				renderFunctions: {
					default: {
						order: 0,
						type: "setBoxBadge32Property",
						inputs:
							'{"attributeType": "ID", "horizontalPositionInPercent": 0, "verticalPositionInPercent": 50, "verticalAlignment": "none", "horizontalAlignment": "none","textColor": "#000000","borderRadius":5, "backgroundColor": "#E60D2E00", "widthInPixels": 25, "heightInPixels": 16, "fontFamily": "Arial", "textSizeInPixels": 14 }',
					},
				},
			};

			boxType.attributeTypes["ID"] = idAttributeType;
		}
	}

	// For the 'Is Canvas' attribute, don't add it if the box type already
	// has it.
	if (
		!Object.prototype.hasOwnProperty.call(
			boxType.attributeTypes,
			"Is Canvas"
		)
	) {
		const idAttributeType: attributeTypeLib.AttributeType = {
			name: "Is Canvas",
			description: "Whether the box represents a canvas",
			choices: "",
			order: -3,
			showInLegend: false,
			showInSummary: true,
			valueType: "choice",
			defaultValue: "False",
			permittedAssociationBoxTypes: "",
			renderFunctions: {
				default: {
					order: 0,
					type: "mapChoiceAttributeToBoxProperty",
					inputs: {
						True: {
							boxPropertyKey: "badge30",
							boxPropertyValue:
								'{"text": "q", "horizontalPositionInPercent": 0, "verticalPositionInPercent": 0, "verticalAlignment": "none", "horizontalAlignment": "none","textColor": "#202A45","borderRadius":2, "borderSizeInPixels":1, "borderStyle":"none", "backgroundColor": "#00000000", "widthInPixels": 35, "heightInPixels": 30, "fontFamily": "Wingdings", "textSizeInPixels": 26 }',
						},
						False: {
							boxPropertyKey: "badge30",
							boxPropertyValue: '{"text": "" }',
						},
					},
				},
			},
		};

		boxType.attributeTypes["Is Canvas"] = idAttributeType;
	}

	// For the 'Has Lens Pages' attribute, don't add it if the box type already
	// has it.
	if (
		!Object.prototype.hasOwnProperty.call(
			boxType.attributeTypes,
			"Has Lens Pages"
		)
	) {
		const idAttributeType: attributeTypeLib.AttributeType = {
			name: "Has Lens Pages",
			description: "Whether the box has one or more Lens Pages",
			choices: "",
			order: -2,
			showInLegend: false,
			showInSummary: true,
			valueType: "choice",
			defaultValue: "False",
			permittedAssociationBoxTypes: "",
			renderFunctions: {
				default: {
					order: 0,
					type: "mapChoiceAttributeToBoxProperty",
					inputs: {
						True: {
							boxPropertyKey: "badge31",
							boxPropertyValue:
								'{"text": "4", "horizontalPositionInPercent": 100, "verticalPositionInPercent": 0, "verticalAlignment": "none", "horizontalAlignment": "none","textColor": "#202A45","borderRadius":2, "borderSizeInPixels":1, "borderStyle":"none", "backgroundColor": "#00000000", "widthInPixels": 35, "heightInPixels": 30, "fontFamily": "Wingdings", "textSizeInPixels": 26 }',
						},
						False: {
							boxPropertyKey: "badge31",
							boxPropertyValue: '{"text": "" }',
						},
					},
				},
			},
		};

		boxType.attributeTypes["Has Lens Pages"] = idAttributeType;
	}

	// For the 'DefaultAssociation' attribute, don't add it if the box type already
	// has it.
	if (
		!Object.prototype.hasOwnProperty.call(
			boxType.attributeTypes,
			"DefaultAssociation"
		)
	) {
		const idAttributeType: attributeTypeLib.AttributeType = {
			name: "DefaultAssociation",
			description: "Highlights the box",
			choices: "",
			order: -1,
			showInLegend: false,
			showInSummary: true,
			valueType: "associations",
			defaultValue: "",
			permittedAssociationBoxTypes: "",
			renderFunctions: {
				default: {
					order: 0,
					type: "setBoxStylesProperty",
					inputs: "DefaultAssociation",
				},
			},
		};

		boxType.attributeTypes["DefaultAssociation"] = idAttributeType;
	}
};

export const setDefaultAttributeTypesForBoxTypes = (
	boxTypes: BoxTypeMap | undefined
): void => {
	// If we don't have box types, do nothing
	if (!boxTypes) {
		return;
	}

	const boxTypeKeys = Object.keys(boxTypes)
	for (let i=0; i < boxTypeKeys.length; i += 1) {
		const boxTypeKey = boxTypeKeys[i];
	
		const boxType = boxTypes[boxTypeKey];
		setDefaultAttributeTypesForBoxType(boxType);
	};
};

let private_attributeTypeVisibilityCachingEnabled = false;
let private_attributeTypeVisibilityCache: Record<string, attributeTypeLib.AttributeTypeVisibilityMap> = {};

export const setAttributeTypeVisibilityCacheEnabled = (isEnabled: boolean): void => {
	private_attributeTypeVisibilityCachingEnabled = isEnabled;
}

export const clearAttributeTypeVisibilityCache = (): void => {
	private_attributeTypeVisibilityCache = {};
}

const getCachedAttributeTypeVisibilityKey  = (boxTypeKey: string,
	isMixinBoxType: boolean): string => {
	return ''.concat(boxTypeKey, String(isMixinBoxType));
}

const getCachedAttributeTypeVisibility = (boxTypeKey: string,
	isMixinBoxType: boolean): attributeTypeLib.AttributeTypeVisibilityMap | undefined => {
	if (!private_attributeTypeVisibilityCachingEnabled) {
		return undefined;
	}

	const cachedAssociationKey = getCachedAttributeTypeVisibilityKey(boxTypeKey,
		isMixinBoxType);

	const cachedAttributeTypeVisibilityMap = private_attributeTypeVisibilityCache[cachedAssociationKey];
	return cachedAttributeTypeVisibilityMap;
}

const setCachedAttributeTypeVisibility = (boxTypeKey: string,
	isMixinBoxType: boolean,
	attributeTypeVisibilityMap: attributeTypeLib.AttributeTypeVisibilityMap): void => {
	if (private_attributeTypeVisibilityCachingEnabled) {
		const cachedAssociationKey = getCachedAttributeTypeVisibilityKey(boxTypeKey,
			isMixinBoxType);
	
		private_attributeTypeVisibilityCache[cachedAssociationKey] = attributeTypeVisibilityMap;
	}
}

export const setAttributeTypeVisibilityMapForBoxTypeRecursive = (
	attributeTypeVisibilityMap:
		| attributeTypeLib.AttributeTypeVisibilityMap
		| undefined,
	boxTypes: BoxTypeMap | undefined,
	boxTypeKey: string,
	boxTypeVisibilityMap: BoxTypeVisibilityMap | undefined,
	isMixinBoxType?: boolean,
) => {
	// If we don't have an attribute type visibility map or box types, do nothing
	if (!attributeTypeVisibilityMap || !boxTypes || !boxTypeVisibilityMap) {
		return;
	}

	// Get the box type
	const boxType: BoxType = boxTypes[boxTypeKey];
	if (boxType) {
		// console.log(`setAttributeTypeVisibilityMapForBoxTypeRecursive(${boxType.name})`)

		// Get the box type visibility
		const boxTypeVisibility = boxTypeVisibilityMap[boxTypeKey];
		if (boxTypeVisibility) {
			// Get the box type attribute type visibilities
			const boxTypeAttributeTypeVisibilityMap =
				boxTypeVisibility.attributeTypeVisibilityMap;
			if (boxTypeAttributeTypeVisibilityMap) {
				// Add the attribute types visibilities
				const boxAttributeTypeKeys = Object.keys(boxTypeAttributeTypeVisibilityMap);
				for (let i=0; i < boxAttributeTypeKeys.length; i += 1) {
					const boxAttributeTypeKey = boxAttributeTypeKeys[i];

					// Get the attribute type visibility
					const boxAttributeTypeVisibility =
						boxTypeAttributeTypeVisibilityMap[
						boxAttributeTypeKey
						];

					// console.log(`${boxType.name} - ${boxAttributeTypeKey} - ${boxAttributeTypeVisibility}`)

					// If we're adding a mixin attribute type, we need to handle
					// "default" attribute like `ID` or `DefaultAssociation`. To allow
					// the mixin default attribute to co-exist with the common attribute
					// of the box type, we can specialize the mixin attribute type by
					// prefixing the mixin type key to it.						
					let actualBoxAttributeTypeKey = boxAttributeTypeKey;

					if (isMixinBoxType && attributeTypeLib.isDefaultAttributeTypeKey(boxAttributeTypeKey)) {
						if (!attributeTypeLib.isDefaultAssociationAttributeTypeKey(boxAttributeTypeKey)) {
							continue;
						}

						actualBoxAttributeTypeKey = attributeTypeLib.prefixAttributeTypeKey(boxAttributeTypeKey,
							boxTypeKey)
					}

					// Does the attribute type visibility not already exist?
					if (
						!Object.prototype.hasOwnProperty.call(
							attributeTypeVisibilityMap,
							actualBoxAttributeTypeKey
						)
					) {
						// Add it to the attribute type visibilities
						attributeTypeVisibilityMap[
							actualBoxAttributeTypeKey
						] = boxAttributeTypeVisibility;
					} else {
						// The attribute type visibility takes precendence over the
						// existing visibility only if it's visible
						if (boxAttributeTypeVisibility) {
							attributeTypeVisibilityMap[boxAttributeTypeKey] = true;
						}
					}
				}
			}

			// Get the keys of the mixin box types
			const mixinBoxTypeKeys = getMixinBoxTypeKeys(boxType.mixinBoxTypes);

			// Get the visibilities of the mixin box types
			// The mixin box type visibility will override the global box visibility
			const mixinBoxTypeVisibilityMap = boxTypeVisibility
				?
					{
						...boxTypeVisibilityMap,
						...boxTypeVisibility.mixinBoxTypeVisibilityMap
					}
				: {};

			// Get the attribute type visibilities of each of the box type mixins on
			// this box type
			for (let i=0; i < mixinBoxTypeKeys.length; i += 1) {
				const mixinBoxTypeKey = mixinBoxTypeKeys[i];

				// console.log(`leaf mixinBoxType=${boxTypes[mixinBoxTypeKey].name}`)

				// Set the attribute types for the box type
				setAttributeTypeVisibilityMapForBoxTypeRecursive(
					attributeTypeVisibilityMap,
					boxTypes,
					mixinBoxTypeKey,
					mixinBoxTypeVisibilityMap,
					true
				);
			};
		}
	}
};

export const getAttributeTypeVisibilityMapForBoxTypeRecursive = (
	boxTypes: BoxTypeMap | undefined,
	boxTypeKey: string,
	boxTypeVisibilityMap: BoxTypeVisibilityMap | undefined,
	isMixinBoxType?: boolean,
): attributeTypeLib.AttributeTypeVisibilityMap => {
	const attributeTypeVisibilityMap: attributeTypeLib.AttributeTypeVisibilityMap = {};
	
	// If we don't have an attribute type visibility map or box types, do nothing
	if (!boxTypes || !boxTypeVisibilityMap) {
		return attributeTypeVisibilityMap;
	}

	const cachedAttributeTypeVisibility = getCachedAttributeTypeVisibility(boxTypeKey, !!isMixinBoxType);
	if (cachedAttributeTypeVisibility) {
		return cachedAttributeTypeVisibility;
	}

	setAttributeTypeVisibilityMapForBoxTypeRecursive(attributeTypeVisibilityMap,
		boxTypes,
		boxTypeKey,
		boxTypeVisibilityMap,
		isMixinBoxType);

	setCachedAttributeTypeVisibility(boxTypeKey, !!isMixinBoxType, attributeTypeVisibilityMap)

	return attributeTypeVisibilityMap;
};

export const getDefaultBoxTypeVisibility = (
	boxType: BoxType,
	boxTypes: BoxTypeMap | undefined
): BoxTypeVisibility => {
	// Get the keys of the mixin box types
	const mixinBoxTypeKeys = getMixinBoxTypeKeys(boxType.mixinBoxTypes);

	// Build the mixin box type visibility map
	const mixinBoxTypeVisibilityMap = buildBoxTypeVisibilityMap(
		boxTypes,
		mixinBoxTypeKeys
	);

	// Get the attribute types of the box type
	const attributeTypes = boxType.attributeTypes;

	// Build the attribute type visibility map
	const attributeTypeVisibilityMap: attributeTypeLib.AttributeTypeVisibilityMap = attributeTypeLib.buildAttributeTypeVisibilityMap(
		attributeTypes
	);

	if (
		Object.prototype.hasOwnProperty.call(
			attributeTypeVisibilityMap,
			"DefaultAssociation"
		)
	) {
		// Turn on default association by default.
		attributeTypeVisibilityMap["DefaultAssociation"] = true;
	}

	const renderFunctionLatchModeVisibilityMap = {};

	// The box type is visible by default
	const defaultBoxTypeVisibility = {
		isVisible: true,
		areBoxesVisible: true,
		areBoxesInLayout: true,
		mixinBoxTypeVisibilityMap,
		attributeTypeVisibilityMap,
		renderFunctionLatchModeVisibilityMap
	};

	return defaultBoxTypeVisibility;
};

export const buildBoxTypeVisibilityMap = (
	boxTypes: BoxTypeMap | undefined,
	boxTypeKeys: string[]
): BoxTypeVisibilityMap | undefined => {
	// If we don't have box types, there's nothing to build
	if (!boxTypes) {
		return undefined;
	}

	// Get the box map types at this level of the tree
	const boxTypeVisibilityMap: BoxTypeVisibilityMap = boxTypeKeys.reduce(
		(
			reducedBoxTypeVisibilityMap: BoxTypeVisibilityMap,
			boxTypeKey: string
		) => {
			// Get the box type
			const boxType = boxTypes[boxTypeKey];

			// Do we not already have the box type in our map?
			if (
				!Object.prototype.hasOwnProperty.call(
					reducedBoxTypeVisibilityMap,
					boxTypeKey
				)
			) {
				// Add the new box type
				reducedBoxTypeVisibilityMap[
					boxTypeKey
				] = getDefaultBoxTypeVisibility(boxType, boxTypes);
			}

			return reducedBoxTypeVisibilityMap;
		},
		{}
	);

	return boxTypeVisibilityMap;
};

export const getUpdatedBoxTypeVisibilityMapForBoxTypes = (
	boxTypeVisibilityMap: BoxTypeVisibilityMap | undefined,
	currentBoxTypeMap: BoxTypeMap | undefined,
	previousBoxTypeMap: BoxTypeMap | undefined
): BoxTypeVisibilityMap | undefined => {
	// If our inputs are invalid there's nothing to do
	if (!boxTypeVisibilityMap || !currentBoxTypeMap || !previousBoxTypeMap) {
		return undefined;
	}

	// Get a copy of the current box type visibility map
	const updatedBoxTypeVisibilityMap = JSON.parse(
		JSON.stringify(boxTypeVisibilityMap)
	);

	// Remove any box type visibilities that are no longer present
	const previousBoxTypeMapKeys = Object.keys(previousBoxTypeMap);
	for (let i=0; i < previousBoxTypeMapKeys.length; i += 1) {
		const boxTypeKey = previousBoxTypeMapKeys[i];

		if (
			!Object.prototype.hasOwnProperty.call(currentBoxTypeMap, boxTypeKey)
		) {
			delete updatedBoxTypeVisibilityMap[boxTypeKey];
		}
	};

	// Add any new box type visibilities, defaulting them to visible by default
	if (boxTypeVisibilityMap) {
		const currentBoxTypeMapKeys = Object.keys(currentBoxTypeMap);
		for (let i=0; i < currentBoxTypeMapKeys.length; i += 1) {
			const boxTypeKey = currentBoxTypeMapKeys[i];

			if (
				!Object.prototype.hasOwnProperty.call(
					previousBoxTypeMap,
					boxTypeKey
				)
			) {
				if (
					!Object.prototype.hasOwnProperty.call(
						updatedBoxTypeVisibilityMap,
						boxTypeKey
					)
				) {
					// Get the box type
					const boxType = currentBoxTypeMap[boxTypeKey];

					// Add the new box type
					updatedBoxTypeVisibilityMap[
						boxTypeKey
					] = getDefaultBoxTypeVisibility(boxType, currentBoxTypeMap);
				}
			}
		}
	}

	return updatedBoxTypeVisibilityMap;
};

export const getBoxTypeVisibilityForBoxTypeKeyHierarchy = (
	boxTypeVisibilityMap: BoxTypeVisibilityMap | undefined,
	boxTypeKeyHierarchy: string[]
): BoxTypeVisibility | undefined => {
	// The box type visibility
	let boxTypeVisibility: BoxTypeVisibility | undefined = undefined;

	// Do we have box type visibility map?
	if (boxTypeVisibilityMap) {
		// The current box type visibility
		let currentBoxTypeVisibilityMap: BoxTypeVisibilityMap = boxTypeVisibilityMap;

		// Loop thru the box type visibility map, walking the hierarchy
		for (let i=0; i < boxTypeKeyHierarchy.length; i += 1) {
			const boxTypeKey = boxTypeKeyHierarchy[i];

			// Get the box type visibility
			boxTypeVisibility = currentBoxTypeVisibilityMap[boxTypeKey];
			if (boxTypeVisibility) {
				// If the box type visibility has visibility map for mixins, use that as
				// the current box type visibility map
				if (boxTypeVisibility.mixinBoxTypeVisibilityMap) {
					currentBoxTypeVisibilityMap =
						boxTypeVisibility.mixinBoxTypeVisibilityMap;
				}
			}
		}
	}

	return boxTypeVisibility;
};

export const setDefaultBoxTypeVisibility = (
	boxTypeKey: string,
	boxTypeVisibilityMap: BoxTypeVisibilityMap | undefined
): void => {
	// If we don't have box type visibilities, there's nothing to do.
	if (!boxTypeVisibilityMap) {
		return;
	}

	const boxTypeVisibility = boxTypeVisibilityMap[boxTypeKey];
	if (boxTypeVisibility) {
		if (boxTypeVisibility.attributeTypeVisibilityMap) {
			if (
				!Object.prototype.hasOwnProperty.call(
					boxTypeVisibility.attributeTypeVisibilityMap,
					"DefaultAssociation"
				)
			) {
				// Turn on default association by default.
				boxTypeVisibility.attributeTypeVisibilityMap[
					"DefaultAssociation"
				] = true;
			}
		}
	}
};

export const setDefaultBoxTypeVisibilities = (
	boxTypeVisibilityMap: BoxTypeVisibilityMap | undefined
): void => {
	// If we don't have box type visibilities, there's nothing to do.
	if (!boxTypeVisibilityMap) {
		return;
	}

	const boxTypeVisibilityMapKeys = Object.keys(boxTypeVisibilityMap);

	for (let i=0; i < boxTypeVisibilityMapKeys.length; i += 1) {
		const boxTypeKey = boxTypeVisibilityMapKeys[i];

		setDefaultBoxTypeVisibility(boxTypeKey, boxTypeVisibilityMap);
	}
};
