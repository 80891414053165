import * as CSS from "csstype";

import { TextSizing, TextAlignment } from "../style/text";

export enum BadgeHorizontalAlignment {
	NONE = "none",
	LEFT = "left",
	RIGHT = "right",
}

export enum BadgeVerticalAlignment {
	NONE = "none",
	TOP = "top",
	BOTTOM = "bottom",
}

export interface BadgeProperties {
	attributeType: string;
	width: number | string | undefined;
	height: number | string | undefined;
	widthInPixels: number;
	heightInPixels: number;
	text: string;
	textColor: string;
	textSizeInPixels: number;
	textSizing: TextSizing;
	textAlignment: TextAlignment;
	textAlignmentPaddingInPixels: number;
	textIsVertical: boolean;
	fontFamily: CSS.Property.FontFamily;
	fontStyle: CSS.Property.FontStyle;
	fontWeight: CSS.Property.FontWeight;
	backgroundColor: string;
	backgroundImage: string;
	backgroundImagePosition: string;
	backgroundImageSize: string;
	backgroundImageRepeat: string;
	borderColor: string;
	borderRadius: number;
	borderSizeInPixels: number;
	borderStyle: string;
	horizontalPositionInPercent: number;
	verticalPositionInPercent: number;
	verticalAlignment: BadgeVerticalAlignment;
	horizontalAlignment: BadgeHorizontalAlignment;
}

export const PROPERTIES_DEFAULT_BADGE_ATTRIBUTE_TYPE: string = "";
export const PROPERTIES_DEFAULT_BADGE_WIDTH_IN_PIXELS: number = 24;
export const PROPERTIES_DEFAULT_BADGE_HEIGHT_IN_PIXELS: number = 24;
export const PROPERTIES_DEFAULT_BADGE_TEXT: string = "";
export const PROPERTIES_DEFAULT_BADGE_TEXT_COLOR: string = "#FFF";
export const PROPERTIES_DEFAULT_BADGE_TEXT_SIZE_IN_PIXELS: number = 10;
export const PROPERTIES_DEFAULT_BADGE_TEXT_SIZING: TextSizing =
	TextSizing.MANUAL;
export const PROPERTIES_DEFAULT_BADGE_TEXT_ALIGNMENT: TextAlignment = TextAlignment.CENTER;
export const PROPERTIES_DEFAULT_BADGE_TEXT_ALIGNMENT_PADDING_IN_PIXELS = 8;
export const PROPERTIES_DEFAULT_BADGE_TEXT_IS_VERTICAL: boolean = false;
export const PROPERTIES_DEFAULT_BADGE_FONT_FAMILY: CSS.Property.FontFamily = "Arial" as CSS.Property.FontFamily;
export const PROPERTIES_DEFAULT_BADGE_FONT_STYLE: CSS.Property.FontStyle = "normal" as CSS.Property.FontStyle;
export const PROPERTIES_DEFAULT_BADGE_FONT_WEIGHT: CSS.Property.FontWeight = "bold" as CSS.Property.FontWeight;
export const PROPERTIES_DEFAULT_BADGE_BACKGROUND_COLOR: string = "";
export const PROPERTIES_DEFAULT_BADGE_BACKGROUND_IMAGE: string = ""; // See https://developer.mozilla.org/en-US/docs/Web/CSS/background-image
export const PROPERTIES_DEFAULT_BADGE_BACKGROUND_IMAGE_POSITION: string =
	"top left"; // See https://developer.mozilla.org/en-US/docs/Web/CSS/background-position
export const PROPERTIES_DEFAULT_BADGE_BACKGROUND_IMAGE_SIZE: string = "contain"; // See https://developer.mozilla.org/en-US/docs/Web/CSS/background-size
export const PROPERTIES_DEFAULT_BADGE_BACKGROUND_IMAGE_REPEAT: string =
	"no-repeat"; // repeat|repeat-x|repeat-y|no-repeat

export const PROPERTIES_DEFAULT_BADGE_BORDER_COLOR: string = "#8CBFD9 ";
export const PROPERTIES_DEFAULT_BADGE_BORDER_RADIUS: number = 0;
export const PROPERTIES_DEFAULT_BADGE_BORDER_SIZE_IN_PIXELS: number = 0;
export const PROPERTIES_DEFAULT_BADGE_BORDER_STYLE: string = "none";
export const PROPERTIES_DEFAULT_BADGE_HORIZONTAL_POSITION_IN_PERCENT: number = 100;
export const PROPERTIES_DEFAULT_BADGE_VERTICAL_POSITION_IN_PERCENT: number = 0;
export const PROPERTIES_DEFAULT_BADGE_VERTICAL_ALIGNMENT: BadgeVerticalAlignment =
	BadgeVerticalAlignment.TOP;
export const PROPERTIES_DEFAULT_BADGE_HORIZONTAL_ALIGNMENT: BadgeHorizontalAlignment =
	BadgeHorizontalAlignment.RIGHT;

export const PROPERTIES_DEFAULT_BADGE: string = "";

export const getDefaultBadgeProperties = () => {
	const defaultBadgeProperties: BadgeProperties = {
		attributeType: PROPERTIES_DEFAULT_BADGE_ATTRIBUTE_TYPE,
		width: undefined,
		height: undefined,
		widthInPixels: PROPERTIES_DEFAULT_BADGE_WIDTH_IN_PIXELS,
		heightInPixels: PROPERTIES_DEFAULT_BADGE_HEIGHT_IN_PIXELS,
		text: PROPERTIES_DEFAULT_BADGE_TEXT,
		textColor: PROPERTIES_DEFAULT_BADGE_TEXT_COLOR,
		textSizeInPixels: PROPERTIES_DEFAULT_BADGE_TEXT_SIZE_IN_PIXELS,
		textSizing: PROPERTIES_DEFAULT_BADGE_TEXT_SIZING,
		textAlignment: PROPERTIES_DEFAULT_BADGE_TEXT_ALIGNMENT,
		textAlignmentPaddingInPixels: PROPERTIES_DEFAULT_BADGE_TEXT_ALIGNMENT_PADDING_IN_PIXELS,
		textIsVertical: PROPERTIES_DEFAULT_BADGE_TEXT_IS_VERTICAL,
		fontFamily: PROPERTIES_DEFAULT_BADGE_FONT_FAMILY,
		fontStyle: PROPERTIES_DEFAULT_BADGE_FONT_STYLE,
		fontWeight: PROPERTIES_DEFAULT_BADGE_FONT_WEIGHT,
		backgroundColor: PROPERTIES_DEFAULT_BADGE_BACKGROUND_COLOR,
		backgroundImage: PROPERTIES_DEFAULT_BADGE_BACKGROUND_IMAGE,
		backgroundImagePosition: PROPERTIES_DEFAULT_BADGE_BACKGROUND_IMAGE_POSITION,
		backgroundImageSize: PROPERTIES_DEFAULT_BADGE_BACKGROUND_IMAGE_SIZE,
		backgroundImageRepeat: PROPERTIES_DEFAULT_BADGE_BACKGROUND_IMAGE_REPEAT,
		borderColor: PROPERTIES_DEFAULT_BADGE_BORDER_COLOR,
		borderRadius: PROPERTIES_DEFAULT_BADGE_BORDER_RADIUS,
		borderSizeInPixels: PROPERTIES_DEFAULT_BADGE_BORDER_SIZE_IN_PIXELS,
		borderStyle: PROPERTIES_DEFAULT_BADGE_BORDER_STYLE,
		horizontalPositionInPercent: PROPERTIES_DEFAULT_BADGE_HORIZONTAL_POSITION_IN_PERCENT,
		verticalPositionInPercent: PROPERTIES_DEFAULT_BADGE_VERTICAL_POSITION_IN_PERCENT,
		verticalAlignment: PROPERTIES_DEFAULT_BADGE_VERTICAL_ALIGNMENT,
		horizontalAlignment: PROPERTIES_DEFAULT_BADGE_HORIZONTAL_ALIGNMENT,
	};

	return defaultBadgeProperties;
};