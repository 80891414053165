import { PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import RightMenuHeading from "../../../organisms/RightMenuHeading";
import RightMenuHeadingText from "../../../organisms/RightMenuHeadingText";
import * as boxTypeLib from "@lib/box/box-type";
import { BoxTypeList } from "../../../molecules/BoxTypeList";
import { BoxTypeMap } from "@lib/box/box-type";
import { Button } from "antd";
import TypeImportDialog from "./ImportTypesDialog/ImportTypesDialog";

export interface TypesMenuProps {
	canAddTypes: boolean,
	canEditTypes: boolean,
	canDeleteTypes: boolean,
	onBoxTypeAdd: () => void,
	onBoxTypeEdit: (boxTypeKey: string) => void,
	onBoxTypeDelete: (boxTypeKey: string) => void,
	boxTypeMap: BoxTypeMap | undefined,
	onImportBoxTypes: (importedBoxTypes: boxTypeLib.BoxTypeMap, mergeOntoTypes: Array<string> | null) => void
}

const TypesMenu: React.FC<TypesMenuProps> = (props) => {
	const [showImportTypesDialog, setShowImportTypesDialog] = useState(false);
	
	return <><RightMenuHeading>
		<RightMenuHeadingText>Box Types</RightMenuHeadingText>
		<div>
		<Button type="primary" style={{marginRight: "6px"}} onClick={()=>{setShowImportTypesDialog(true);}}>Import</Button>
		<Button
			icon={<PlusOutlined />}
			color="red"
			type="primary"
			onClick={props.onBoxTypeAdd}
		>
			Add
		</Button>
		</div>

	</RightMenuHeading>
		<div
			style={{
				padding: "12px",
			}}
		>
						<TypeImportDialog isVisible={showImportTypesDialog} 
				onCancelButtonClick={() => {setShowImportTypesDialog(false)}} 
				onOKButtonClick={(boxTypes: boxTypeLib.BoxTypeMap, mergeOntoTypes: Array<string> | null) => {
					props.onImportBoxTypes(boxTypes, mergeOntoTypes);
					setShowImportTypesDialog(false);
				}}
				existingBoxTypes={props.boxTypeMap}
			/>
			
			<BoxTypeList
				onBoxTypeEdit={
					props
						.onBoxTypeEdit
				}
				onBoxTypeDelete={
					props
						.onBoxTypeDelete
				}
				boxTypeMap={
					props.boxTypeMap
				}
				canEdit={
					props.canEditTypes
				}
				canDelete={
					props.canDeleteTypes
				}
			/>
		</div>
	</>
};

export default TypesMenu;