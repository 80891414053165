import React from 'react';

export interface FormHeadingProps { }

const RightMenuHeadingText: React.FC<FormHeadingProps> = (props) => {
	return <span
		style={{
			paddingTop: "2px",
		}}
	>
		{props.children}
	</span>
}
export default RightMenuHeadingText;