import axios, { AxiosResponse } from 'axios';
import config from '../config';
import { ModelService, makeModelService } from './model-service';
import { Project } from '@contracts/project';

const entityName = 'projects';

export interface ProjectService extends ModelService<Project, Project, Project, Project> {
  getListByClientId: (clientId: string, success: (results: Project[]) => void) => void,
}

const getListByClientId = (clientId: string, success: (projects: Project[]) => void) => {
  const url = `${config.apiURL}${entityName}?clientId=${clientId}`;

  axios.get(url, { withCredentials: true }).then((res: AxiosResponse<any>) => {
    success(res.data);
  });

};

const genericService = makeModelService<Project, Project, Project, Project>(entityName);

const projectService: ProjectService = { ...genericService, getListByClientId };

export default projectService;