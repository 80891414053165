import { v4 as uuid } from "uuid";

import * as valueTypeLib from "./value-type";
import * as renderFunctionLib from "./render-function";
import * as boxTypeLib from './box-type';
import * as attributeLib from './attribute';

export enum AssociationType {
	/**
	 * Checks the values specified by the attribute value against the UUIDs of
	 * other boxes, creating associations with those boxes if they match 
	 * (default).
	 */
	 Uuids = 'uuids',
	/**
	 * Checks the values specified by the attribute value against the names of
	 * other boxes, creating associations with those boxes if they match. 
	 */
	NamesLookup = 'namesLookup',
	/**
	 * Checks the values specified by the attribute value against the attribute
	 * values of other boxes, creating associations with those boxes if they
	 * match.
	 */
	AttributeValuesLookup = 'attributeValuesLookup',
	/**
	 * Checks the ID of the box this attribute belongs to against the values
	 * of associations in other boxes, creating associations if the box UUID
	 * matches one of those values.
	 */
	UuidReverseLookup = 'uuidReverseLookup',
	/**
	 * Checks the name of the box this attribute belongs to against the values
	 * of associations in other boxes, creating associations if the box name
	 * matches one of those values.
	 */
	NameReverseLookup = 'nameReverseLookup',
	/**
	 * Checks the value of another attribute in the box this attribute belongs
	 * to against the values of associations in other boxes, creating
	 * associations if the attribute value matches one of those values.
	 */
	AttributeValueReverseLookup = 'attributeValueReverseLookup',
}

export enum ValueType {
	Url = 'url',
	File = 'file',
	Presentation = 'presentation',
	Text = 'text',
	CalculatedField = 'calculatedField',
	Choice = 'choice',
	Associations = 'associations'
}

export interface AttributeType {
	name: string;
	description: string;
	choices: string;
	order: number;
	showInLegend: boolean;
	showInSummary: boolean;
	valueType: string;
	defaultValue: attributeLib.AttributeValue;

	/**
	 * A list of the box types an association can operate on. Used to narrow
	 * down the search for associated boxes when using non-`Uuids` association
	 * types.
	 */
	permittedAssociationBoxTypes: string;

	/**
	 * The type of association
	 */
	associationsType?: AssociationType;

	/**
	 * The name of the attribute to use when performing `AttributeValues` and 
	 * `AttributeValueReverseLookup` associations.
	 */
	associationsAttributeName?: string;

	/**
	 * The render functions associated with the attribute.
	 */
	renderFunctions: renderFunctionLib.RenderFunctionInfoMap;
}

export type AttributeTypeMap = { [key: string]: AttributeType };

export type AttributeTypeVisibilityMap = { [key: string]: boolean };

export const createDefaultAttributeType = (valueType: valueTypeLib.ValueTypeKey = valueTypeLib.ValueTypeKey.Text): AttributeType => {
	return {
		name: "",
		description: "",
		choices: "",
		order: 0,
		showInLegend: false,
		showInSummary: true,
		valueType,
		defaultValue: "",
		permittedAssociationBoxTypes: "",
		renderFunctions: {
			default: {
				inputs: {
					boxPropertyKey: "text"
				}, 
				order: 0, 
				type: renderFunctionLib.RenderFunctionTypeKey.SET_BOX_PROPERTY_FROM_ATTRIBUTE,
				name: "Show Attribute as Text"
			}
		},
	};
};

export const getNewAttributeTypeKey = () => uuid();

export const isDefaultAttributeTypeKeyExcludingDefaultAssociation = (attributeTypeKey: string): boolean => {
	return attributeTypeKey === "ID" ||
		attributeTypeKey === "Is Canvas" ||
		attributeTypeKey === "Has Lens Pages"; 
		
}

export const isDefaultAssociationAttributeTypeKey = (attributeTypeKey: string): boolean => {
	return attributeTypeKey === "DefaultAssociation";
}

export const isNonDefaultAttributeTypeKeyExcludingDefaultAssociation = (attributeTypeKey: string): boolean => {
	return !isDefaultAttributeTypeKeyExcludingDefaultAssociation(attributeTypeKey);
};

export const isNonDefaultAttributeTypeKey = (attributeTypeKey: string): boolean => {
	return !isDefaultAttributeTypeKey(attributeTypeKey);
}

export const isDefaultAttributeTypeKey = (attributeTypeKey: string): boolean => {
	return attributeTypeKey === "ID" ||
		attributeTypeKey === "Is Canvas" ||
		attributeTypeKey === "Has Lens Pages" || 
		attributeTypeKey === "DefaultAssociation";
}

export const prefixAttributeTypeKey = (
	attributeTypeKey: string,
	boxTypeKey: string,
): string => {
	return `${boxTypeKey}.${attributeTypeKey}`;
};

export const prefixDefaultAttributeTypeKey = (
	attributeTypeKey: string,
	boxTypeKey: string,
): string => {
	return isDefaultAttributeTypeKeyExcludingDefaultAssociation(attributeTypeKey)
		? prefixAttributeTypeKey(boxTypeKey, attributeTypeKey)
		: attributeTypeKey;
};

export const prefixDefaultAttributeTypeKeys = (
	attributeTypeKeys: string[],
	boxTypeKey: string,
): string[] => {
	return attributeTypeKeys.map((attributeTypeKey: string) => {
		return prefixDefaultAttributeTypeKey(attributeTypeKey, boxTypeKey);
	});
};

export const getAttributeTypeKeys = (boxType: boxTypeLib.BoxType,
	boxTypeKey: string,
	isMixinBoxType?: boolean): string[] => {
	const attributeTypes = boxType.attributeTypes;
	if (!attributeTypes) {
		return []
	}

	const attributeTypeKeys = Object.keys(attributeTypes);

	return (isMixinBoxType)
		? prefixDefaultAttributeTypeKeys(attributeTypeKeys,
			boxTypeKey)
		: attributeTypeKeys;
}

export const getNonAssociationAttributeTypeKeys = (
	attributeTypes: AttributeTypeMap
): string[] => {
	return Object.keys(attributeTypes).filter(
		(attributeTypeKey: string) =>
			attributeTypes[attributeTypeKey]
			&&
			attributeTypes[attributeTypeKey].valueType !==
				valueTypeLib.ValueTypeKey.Associations
	);
};

export const getNonDefaultAttributeTypeKeysIncludingDefaultAssociation = (
	attributeTypeKeys: string[]
): string[] => {
	return attributeTypeKeys.filter(isNonDefaultAttributeTypeKeyExcludingDefaultAssociation);
};

export const buildAttributeTypeVisibilityMap = (
	attributeTypes: AttributeTypeMap | undefined
): AttributeTypeVisibilityMap => {
	// Get the attribute type keys
	const attributeTypeKeys = attributeTypes ? Object.keys(attributeTypes) : [];

	// Get the attribute map types at this level of the tree
	const attributeTypeVisibilityMap = attributeTypeKeys.reduce(
		(
			reducedAttributeTypeVisibilityMap: AttributeTypeVisibilityMap,
			attributeTypeKey: string
		) => {
			// Do we not already have the attribute type in our map?
			if (
				!Object.prototype.hasOwnProperty.call(
					reducedAttributeTypeVisibilityMap,
					attributeTypeKey
				)
			) {
				// Add it, setting to visible by default
				reducedAttributeTypeVisibilityMap[attributeTypeKey] = false;
			}

			return reducedAttributeTypeVisibilityMap;
		},
		{}
	);

	return attributeTypeVisibilityMap;
};

// Used to cache attribute type name lookups.
let private_attributeTypeNameToTypeKeyCache: Record<string, Record<string, string>> = {}

export const clearAttributeTypeCache = () => {
	private_attributeTypeNameToTypeKeyCache = {}
}

export const findAttributeTypeKeyForName = (
	boxTypeKey: string,
	attributeTypeMap: AttributeTypeMap,
	attributeTypeName: string
): string => {
	// console.log(`findAttributeTypeKeyForName - start ${boxTypeKey}, ${attributeTypeName}`);

	const cachedBoxType = private_attributeTypeNameToTypeKeyCache[boxTypeKey];
	if (cachedBoxType) {
		const cachedResult = cachedBoxType[attributeTypeName];
		if (cachedResult && cachedResult !== "") {
			// console.log(`findAttributeTypeKeyForName - end cache hit ${boxTypeKey}, ${attributeTypeName}`);

			return cachedResult;
		}
	}

	const attributeTypeKeys = Object.keys(attributeTypeMap);

	const foundIndex = attributeTypeKeys.findIndex(
		(attributeTypeKey: string) => {
			return (attributeTypeMap[attributeTypeKey].name === attributeTypeName);
		}
	);

	if (foundIndex < 0) {
		return "";
	}

	const foundAttributeTypeKey = attributeTypeKeys[foundIndex];

	if (!private_attributeTypeNameToTypeKeyCache[boxTypeKey]) {
		private_attributeTypeNameToTypeKeyCache[boxTypeKey] = {}
	}
	private_attributeTypeNameToTypeKeyCache[boxTypeKey][attributeTypeName] = foundAttributeTypeKey;

	// console.log(`findAttributeTypeKeyForName - end ${boxTypeKey}, ${attributeTypeName}`);

	return foundAttributeTypeKey;
};

export const getChoicesForAttributeType = (attributeType: AttributeType) => {
	// Build the list of unique choices

	if (!attributeType.renderFunctions) {
		return [];
	}

	const choiceMap = new Map<string, boolean>();

	// Display a message if no options are available.
	const renderFunctionKeys = Object.keys(attributeType.renderFunctions);
	for (let i=0; i < renderFunctionKeys.length; i += 1) {
		const renderFunctionKey = renderFunctionKeys[i];

		const renderFunction = attributeType.renderFunctions[renderFunctionKey];
		if (renderFunction.type ===renderFunctionLib.RenderFunctionTypeKey.MAP_CHOICE_ATTRIBUTE_TO_BOX_PROPERTY) {
			Object
				.keys(renderFunction.inputs)
				.forEach((input: string) => {
					if (!choiceMap.has(input)) {
						choiceMap.set(input, true);
					}
				});
		}
	};

	const choices: string[] = Array.from(choiceMap.keys());

	// Sort the choices alphabetically and add a "None" choice at the top
	const sortedChoices = choices.sort((choiceA: string, choiceB: string) => {
		if (choiceA < choiceB) {
			return -1;
		}

		if (choiceA > choiceB) {
			return 1;
		}

		return 0;
	});

	return sortedChoices;
};

