import { PlusOutlined } from "@ant-design/icons";
import BoxTypeExpressionTable from "@components/molecules/BoxTypeExpressionTable";
import LabelledElement from "@components/molecules/LabelledElement";
import FormHeading from "@components/organisms/FormHeading";
import FormHeadingText from "@components/organisms/FormHeadingText";
import { Button, Select, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React from "react";
import AddEditBoxTypeExpressionDialog, { BoxTypeExpression } from "../AddEditBoxTypeExpressionDialog";
import * as lensesLib from "@lib/lenses/lenses";
import * as boxTypeLib from "@lib/box/box-type";
import useBoxTypeQueryRestrictions from "../hooks/useBoxTypeQueryRestrictions";

interface BoxTypeQueryRestrictionsProps {

	boxTypes: boxTypeLib.BoxTypeMap;
	boxTypeExpressions: lensesLib.BoxTypeExpressionMap | undefined,
	isAddEditBoxTypeExpressionDialogVisible: boolean,
	isAddEditBoxTypeExpressionDialogAdding: boolean,
	addEditBoxTypeExpression: BoxTypeExpression | null,
	boxTypeAreBoxesInLayout: boolean,
	boxTypeAreBoxesVisible: boolean,
	applyBoxTypeVisibility: boolean,
	boxTypeExpressionOperator: lensesLib.BooleanOperator,
	boxTypeIsVisible: boolean,
	onApplyBoxTypeVisibilityChanged: (value: boolean) => void,
	onBoxTypeExpressionOperatorChanged: (value: lensesLib.BooleanOperator) => void,
	onBoxTypeAreBoxesVisibleChanged: (value: boolean) => void,
	onBoxTypeAreBoxesInLayoutChanged: (value: boolean) => void,
	onApplyBoxTypeIsVisibleChanged: (value: boolean) => void,
	onCloseAddEditBoxTypeExpressionForm: () => void,
	onOpenAddBoxTypeExpressionForm: () => void,
	onOpenEditBoxTypeExpressionForm: (key: string) => void,
	onAddEditBoxTypeExpressionFormOk: (boxTypeExpression: BoxTypeExpression) => void,
	onDeleteBoxTypeExpression: (key: string) => void
};


// # Query Restrictions - Where Clause
// ## Box Type expressions - List of Box Type expressions that you can add to, where you can pick the operator
// - boxTypeExpressions - list
// - boxTypeExpressionOperator - dropdown
// - boxTypeIsVisible
// - boxTypeAreBoxesVisible
// - boxTypeAreBoxesInLayout

const BoxTypeQueryRestrictions: React.FC<BoxTypeQueryRestrictionsProps> = ({ 
	boxTypes,
	boxTypeExpressions,
	isAddEditBoxTypeExpressionDialogVisible,
	isAddEditBoxTypeExpressionDialogAdding,
	addEditBoxTypeExpression,
	boxTypeAreBoxesInLayout,
	boxTypeAreBoxesVisible,
	applyBoxTypeVisibility,
	boxTypeExpressionOperator,
	boxTypeIsVisible,
	onApplyBoxTypeVisibilityChanged,
	onBoxTypeExpressionOperatorChanged,
	onBoxTypeAreBoxesVisibleChanged,
	onBoxTypeAreBoxesInLayoutChanged,
	onApplyBoxTypeIsVisibleChanged,
	onCloseAddEditBoxTypeExpressionForm,
	onOpenAddBoxTypeExpressionForm,
	onOpenEditBoxTypeExpressionForm,
	onAddEditBoxTypeExpressionFormOk,
	onDeleteBoxTypeExpression
}) => {
	return <>

		<AddEditBoxTypeExpressionDialog
			onCancelButtonClick={onCloseAddEditBoxTypeExpressionForm}
			isAdding={isAddEditBoxTypeExpressionDialogAdding}
			isVisible={isAddEditBoxTypeExpressionDialogVisible}
			boxTypes={boxTypes}
			onOKButtonClick={onAddEditBoxTypeExpressionFormOk}
			boxTypeExpression={addEditBoxTypeExpression}
		/>
		<FormHeading>
			<FormHeadingText>Box Type Expressions</FormHeadingText>
			<div>
				<Button
					icon={<PlusOutlined />}
					type="primary"
					style={{
						paddingRight: "12px",
						marginRight: "12px",
					}}
					onClick={onOpenAddBoxTypeExpressionForm}
				>
					Add
				</Button>
			</div>
		</FormHeading>

		<BoxTypeExpressionTable
			boxTypeExpressions={boxTypeExpressions !== undefined ? boxTypeExpressions : {}}
			canDelete={true}
			boxTypes={boxTypes}
			canEdit={true}
			onDelete={onDeleteBoxTypeExpression}
			onEdit={onOpenEditBoxTypeExpressionForm}
		/>
		<div
			style={{
				width: "100%",
				display: "grid",
				gridTemplateColumns: "repeat(auto-fit, 1fr)",
				gap: "4px 12px",
				padding: 0,
				margin: 0,
				overflowY: "visible",
			}}
		>
			<div>
			<LabelledElement labelText="Logical Operator To Apply to Box Type Expressions">
				<Select
					size="large"
					placeholder="Please select"
					value={boxTypeExpressionOperator}
					onChange={onBoxTypeExpressionOperatorChanged}
					getPopupContainer={(node) => {
						let popupContainer: HTMLElement | null =
							window.document.documentElement;
						if (node && node.parentElement) {
							popupContainer = node.parentElement;
						}
						return popupContainer as HTMLElement;
					}}
				>
					<Select.Option key="and">and</Select.Option>
					<Select.Option key="or">or</Select.Option>
				</Select>
			</LabelledElement>
			</div>
			<div>
			<LabelledElement labelText="Should Matched Box Types be Visible?">
				<Checkbox
					checked={boxTypeIsVisible}
					onChange={(event: CheckboxChangeEvent) => {
						onApplyBoxTypeIsVisibleChanged(event.target.checked);
					}
					}
				/>
			</LabelledElement>
			</div>

			<div>
			<LabelledElement labelText="Enable Box Visibility Controls for Matched Box Types">
				<Checkbox checked={applyBoxTypeVisibility} onChange={(e) => {
					onApplyBoxTypeVisibilityChanged(e.target.checked)
				}}></Checkbox>
			</LabelledElement>
			</div>
			{applyBoxTypeVisibility && <div>
			<LabelledElement labelText="Should boxes within Matched Box Types be Visible?">
				<Checkbox
					checked={boxTypeAreBoxesVisible}
					onChange={
						(event: CheckboxChangeEvent) => {
							onBoxTypeAreBoxesVisibleChanged(event.target.checked);
						}
					}
				/>
			</LabelledElement>
			</div> }
			{applyBoxTypeVisibility && <div>
			<LabelledElement labelText="Should boxes within Matched Box Types take up space in the layout if they are hidden?">
				<Checkbox
					checked={boxTypeAreBoxesInLayout}
					onChange={(event: CheckboxChangeEvent) => {
						onBoxTypeAreBoxesInLayoutChanged(event.target.checked);
					}
					}
				/>
			</LabelledElement>
			</div> }

		</div>
	</>;
}

export default BoxTypeQueryRestrictions;