/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import FormHeading from "./FormHeading";
import {
	DeleteOutlined,
	PlusSquareOutlined,
	ImportOutlined,
	ExportOutlined,
	PlusOutlined
} from "@ant-design/icons";

import {
	Input,
	Select,
	Modal,
	Checkbox,
	Table,
	Button,
	Divider,
	Tag,

} from "antd";

import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";

import { v4 as uuid } from "uuid";

import * as propertyLib from "@lib/box/box-properties";
import * as attributeLib from "@lib/box/attribute";
import * as attributeTypeLib from "@lib/box/attribute-type";
import * as boxLib from "@lib/box/box";
import * as boxTypeLib from "@lib/box/box-type";
import * as boxTypeCounterLib from "@lib/box/box-type-counter";
import * as lensesLib from "@lib/lenses/lenses";

import { BoxStyleMap } from '@lib/box/box-style';

import { SmartPage } from '@lib/smart-pages/smart-page';

import { AttributeList } from "../molecules/AttributeList";
import { PropertyList } from "../molecules/PropertyList";
import AddEditSmartPageDialog from "../pages/illustration/edit-mode/AddEditSmartPageDialog";
import FormHeadingText from "./FormHeadingText";

import { ImportExportChildBoxesExcelDialog } from '../pages/illustration/edit-mode/ImportExportChildBoxesExcelDialog'
import { AddChildBoxesDialog } from '../pages/illustration/edit-mode/AddChildBoxesDialog'
import CreateAutomaticFilterChildBoxesDialog, { AssociationToEnable } from "@components/pages/illustration/edit-mode/CreateAutomaticFilterChildBoxes";
import features from "../../config/features";
import * as authorizationService from "../../services/authorization";


const confirm = Modal.confirm;

interface SmartPageRow extends SmartPage {
	key: string;
}

interface EditBoxDialogProps {
	onOKButtonClick: (
		box: boxLib.Box | undefined,
		boxTypes: boxTypeLib.BoxTypeMap | undefined, 
		associationsToEnable: AssociationToEnable[],
		lensGroupsToAdd: lensesLib.LensGroupMap,
		lensesToAdd: lensesLib.LensMap
	) => void;
	onCancelButtonClick: () => void;
	isVisible: boolean;
	box: boxLib.Box | undefined;
	boxMap: boxLib.BoxMap | undefined;
	flattenedBoxMap: boxLib.BoxMap | undefined;
	boxTypes: boxTypeLib.BoxTypeMap | undefined;
	boxStyles: BoxStyleMap | undefined;
	boxKey: string | undefined;
	boxTypeCounters: boxTypeCounterLib.BoxTypeCounters;
	abilities: authorizationService.AbilitiesMap | undefined;
	clientId: string;
}

interface EditBoxDialogState {
	box: boxLib.Box | undefined;
	boxTypes: boxTypeLib.BoxTypeMap | undefined;
	currentBoxTypeKey: string;
	currentBoxDefaultChildTypeKey: string;
	isAddEditSmartPageDialogVisible: boolean;
	addEditSmartPageDialogSmartPageKey: string;
	addEditSmartPageDialogSmartPage: SmartPage | undefined;
	isImportExportChildBoxesDialogShown: boolean;
	isImporting: boolean;
	isAddChildBoxesDialogShown: boolean;
	isCreateAutomaticFilterChildBoxesDialogShown: boolean;
	associationsToEnable: AssociationToEnable[];
	lensGroupsToAdd: lensesLib.LensGroupMap;
	lensesToAdd: lensesLib.LensMap;

}

const KEY_CODE_ENTER: number = 13;

type BoxTypeOnChangeHandler = (boxTypeKey: string) => void;

export class EditBoxDialog extends React.Component<
	EditBoxDialogProps,
	EditBoxDialogState
> {
	nameInput: any | null = null;

	constructor(props: EditBoxDialogProps) {
		super(props);

		// Get the box
		const editBox: boxLib.Box = props.box
			? JSON.parse(JSON.stringify(props.box))
			: undefined;

		// Get the current box type key
		let currentBoxTypeKey = editBox ? editBox.boxType : "";
		if (!currentBoxTypeKey) {
			// Get the box type keys
			const boxTypeKeys = props.boxTypes
				? Object.keys(props.boxTypes)
				: [];

			if (boxTypeKeys.length > 0) {
				currentBoxTypeKey = boxTypeKeys[0];
			} else {
				currentBoxTypeKey = "";
			}
		}

		// Set the current box type
		if (editBox) {
			editBox.boxType = currentBoxTypeKey;
		}

		// Get the box default child type
		const currentBoxDefaultChildTypeKey = (editBox && editBox.defaultChildBoxType)
			? editBox.defaultChildBoxType
			: currentBoxTypeKey;

		// Set up our state
		this.state = {
			box: editBox,
			boxTypes: props.boxTypes,
			currentBoxTypeKey,
			currentBoxDefaultChildTypeKey,
			isAddEditSmartPageDialogVisible: false,
			addEditSmartPageDialogSmartPage: undefined,
			addEditSmartPageDialogSmartPageKey: "",
			isImportExportChildBoxesDialogShown: false,
			isImporting: false,
			isAddChildBoxesDialogShown: false,
			isCreateAutomaticFilterChildBoxesDialogShown: false,
			associationsToEnable: [],
			lensGroupsToAdd: {},
			lensesToAdd: {}
		};
	}

	public componentWillReceiveProps(nextProps: EditBoxDialogProps) {
		// Only update if we're showing the dialog
		if (!this.props.isVisible && nextProps.isVisible) {
			// Get the box
			const editBox: boxLib.Box = nextProps.box
				? JSON.parse(JSON.stringify(nextProps.box))
				: undefined;

			// Get the current box type key
			let currentBoxTypeKey = editBox ? editBox.boxType : "";
			if (!currentBoxTypeKey) {
				// Get the box type keys
				const boxTypeKeys = nextProps.boxTypes
					? Object.keys(nextProps.boxTypes)
					: [];

				if (boxTypeKeys.length > 0) {
					currentBoxTypeKey = boxTypeKeys[0];
				} else {
					currentBoxTypeKey = "";
				}
			}

			// Set the current box type
			if (editBox) {
				editBox.boxType = currentBoxTypeKey;
			}

			// Get the box default child type
			const currentBoxDefaultChildTypeKey = (editBox && editBox.defaultChildBoxType)
				? editBox.defaultChildBoxType
				: currentBoxTypeKey;

			// Set up our state
			this.setState(
				{
					box: editBox,
					boxTypes: nextProps.boxTypes,
					currentBoxTypeKey,
					currentBoxDefaultChildTypeKey,
					associationsToEnable: [],
					lensesToAdd: {},
					lensGroupsToAdd: {}
				},
				() => {
					if (this.nameInput) {
						// The "autoFocus" attrbiute doesn't work on consecutive dialog opens
						// so we need to manually focus the name input
						this.nameInput.focus();
					}
				}
			);
		}
	}

	private handleAddEditSmartPageOK = (
		smartPageKey: string,
		smartPage: SmartPage
	) => {
		const box: boxLib.Box = JSON.parse(JSON.stringify(this.state.box));

		if (box.smartPages === undefined) {
			box.smartPages = {};
		}

		box.smartPages[smartPageKey] = smartPage;

		this.hideAddEditSmartPageDialog();
		this.setState({ box });
	};
	private handleAddEditSmartPageCancel = () => {
		this.hideAddEditSmartPageDialog();
	};

	private showAddEditSmartPageDialog = (
		smartPageKey: string,
		smartPage: SmartPage
	) => {
		this.setState({
			isAddEditSmartPageDialogVisible: true,
			addEditSmartPageDialogSmartPage: JSON.parse(
				JSON.stringify(smartPage)
			),
			addEditSmartPageDialogSmartPageKey: smartPageKey,
		});
	};

	private deleteSmartPage = (smartPageKey: string) => {
		const box: boxLib.Box | undefined = JSON.parse(
			JSON.stringify(this.state.box)
		);

		if (box !== undefined && box.smartPages !== undefined && box.smartPages) {
			delete box.smartPages[smartPageKey];
		}

		this.setState({ box });
	};

	private hideAddEditSmartPageDialog = () => {
		this.setState({ isAddEditSmartPageDialogVisible: false });
	};

	public render() {
		// Render the box types
		const renderedBoxTypes = this.renderBoxTypes(this.state.currentBoxTypeKey,
			this.handleBoxTypeChange,
			this.state.boxTypes);
		const renderedBoxDefaultChildTypes = this.renderBoxTypes(this.state.currentBoxDefaultChildTypeKey,
			this.handleBoxDefaultChildTypeChange,
			this.props.boxTypes);

		// Get the box attribute types
		const boxAttributeTypes = boxTypeLib.getBoxTypeAttributeTypeCacheForType(this.state.currentBoxTypeKey);

		// Render the box properties
		// TODO: Use parent properties as defaults when inherit is set?
		const renderedBoxProperties = this.renderBoxDefaultProperties(
			boxAttributeTypes
		);

		// Render the box attribute types
		// TODO: Use parent attributes as defaults when inherit is set?
		const renderedBoxNonAssociationAttributeTypes = this.renderBoxNonAssociationAttributeTypes();
		const renderedBoxAssociationAttributeTypes = this.renderBoxAssociationAttributeTypes();

		// Get the box name
		const boxName = this.state.box ? this.state.box.name : "";
		const boxUrl = this.state.box && this.state.box.url ? this.state.box.url : "";

		// Get the box inheritance states
		const inheritParentProperties = this.state.box
			? this.state.box.inheritParentProperties
			: false;
		const inheritParentAttributes = this.state.box
			? this.state.box.inheritParentAttributes
			: false;

		// Format our datasource and
		const smartPageTableColumns = [
			{
				title: "Name",
				dataIndex: "name",
				key: "name",
			},
			{
				title: "Order",
				dataIndex: "order",
				key: "order",
			},
			{
				title: "Is Bookmarked",
				dataIndex: "isBookmarked",
				key: "isBookmarked",
				render: (text: string, record: any) => {
					return (
						<Tag color={record.isBookmarked ? "#87d068" : "#f50"}>
							{record.isBookmarked ? "True" : "False"}
						</Tag>
					);
				},
			},
			{
				title: "Bookmark Order",
				dataIndex: "bookmarkOrder",
				key: "bookmarkOrder",
			},

			{
				title: "Action",
				key: "action",
				render: (text: string, record: any) => (
					<span>
						<a
							onClick={() => {
								const typedRecord: SmartPageRow = record as SmartPageRow;

								if (typedRecord) {
									this.showAddEditSmartPageDialog(
										typedRecord.key,
										typedRecord
									);
								}
							}}
						>
							Edit
						</a>
						<Divider type="vertical" />
						<a
							onClick={() => {
								confirm({
									title:
										"Are you sure delete this Lens Page?",
									okText: "Yes",
									okType: "danger",
									cancelText: "No",
									zIndex: 9999999,
									onOk: () => {
										const typedRecord: SmartPageRow = record as SmartPageRow;

										if (typedRecord) {
											this.deleteSmartPage(
												typedRecord.key
											);
										}
									},
									onCancel: () => { },
								});
							}}
						>
							Delete
						</a>
					</span>
				),
			},
		];

		let smartPageTableData: SmartPageRow[] = [];

		if (this.state.box !== undefined && this.state.box) {
			if (
				this.state.box.smartPages !== undefined &&
				this.state.box.smartPages
			) {
				smartPageTableData = Object.keys(this.state.box.smartPages).map(
					(smartPageKey: string) => {
						const smartPage = this.state.box!.smartPages![
							smartPageKey
						];
						return {
							key: smartPageKey,
							name: smartPage.name,
							content: smartPage.content,
							order: smartPage.order,
							isBookmarked: smartPage.isBookmarked,
							bookmarkOrder: smartPage.bookmarkOrder,
						};
					}
				);
			}
		}

		// Display the UUID to the right of the dialog title
		const boxUUID =
			this.props.boxKey !== "root" ? this.props.boxKey : "background";

		const dialogTitle = (
			<div
				style={{
					width: "100%",
					boxSizing: "border-box",
					display: "grid",
					gridTemplateColumns: "1fr 1fr",
					gap: "4px 4px",
					padding: 0,
					margin: 0,
					overflowY: "visible",
				}}
			>
				<div>Edit Box</div>
				{this.props.boxKey !== undefined ? (
					<div style={{ textAlign: "right", paddingRight: "24px" }}>
						UUID: <i>{boxUUID}</i>
					</div>
				) : (
					<div />
				)}
			</div>
		);

		const smartPageTitle = (
			<FormHeading>
				<FormHeadingText>Lens Pages</FormHeadingText>
				<Button
					icon={<PlusSquareOutlined />}
					color="green"
					type="primary"
					style={{
						paddingRight: "12px",
					}}
					onClick={() => {
						const smartPage: SmartPage = {
							name: "",
							content: "",
							bookmarkOrder: 0,
							isBookmarked: false,
							order: 0,
						};

						const smartPageKey = uuid();

						this.showAddEditSmartPageDialog(
							smartPageKey,
							smartPage
						);
					}}
				>
					Add
				</Button>
			</FormHeading>
		);

		const smartPages = (
			<>
				<Table
					dataSource={smartPageTableData}
					pagination={false}
					columns={smartPageTableColumns}
				/>
				{this.state.addEditSmartPageDialogSmartPage !== undefined &&
					this.state.addEditSmartPageDialogSmartPage !== null ? (
					<AddEditSmartPageDialog
						isVisible={this.state.isAddEditSmartPageDialogVisible}
						onOKButtonClick={this.handleAddEditSmartPageOK}
						onCancelButtonClick={this.handleAddEditSmartPageCancel}
						smartPageKey={
							this.state.addEditSmartPageDialogSmartPageKey
						}
						smartPage={this.state.addEditSmartPageDialogSmartPage}
					/>
				) : null}
			</>
		);

		const attributesTitle = (
			<FormHeading>
				<FormHeadingText>
					Attributes
				</FormHeadingText>
				<Checkbox
					style={{ paddingTop: "4px", color: "#202A45" }}
					checked={inheritParentAttributes}
					onChange={this.handleBoxInheritParentAttributesChange}
				>
					Inherit Parent Attributes
				</Checkbox>
			</FormHeading>
		);

		const associationsTitle = (
			<FormHeading>
				<FormHeadingText>
					Associations
				</FormHeadingText>
				<Button
					icon={<DeleteOutlined />}
					danger
					type="primary"
					style={{
						paddingRight: "12px",
					}}
					onClick={this.handleDeleteAssociations}
				>
					Delete All
				</Button>
			</FormHeading>
		);

		const propertiesTitle = (
			<FormHeading>
				<FormHeadingText>
					Properties
				</FormHeadingText>
				<Checkbox
					style={{ paddingTop: "4px", color: "#202A45" }}
					checked={inheritParentProperties}
					onChange={this.handleBoxInheritParentPropertiesChange}
				>
					Inherit Parent Properties
				</Checkbox>
			</FormHeading>
		);

		return (
			<Modal
				title={dialogTitle}
				open={this.props.isVisible}
				onOk={this.handleOKButtonClick}
				onCancel={this.props.onCancelButtonClick}
				width={"90%"}
				zIndex={9999}
				centered
				bodyStyle={{
					maxHeight: "80vh",
					overflowY: "auto",
					boxSizing: "border-box",
				}}

			>
				<div onKeyUp={this.handleKeyUp}>

					<FormHeading>
						<FormHeadingText>
							Box Details
						</FormHeadingText>
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							{features.automaticFilters &&
								<Button
									icon={<PlusOutlined />}
									type="default"
									style={{
										marginRight: "12px",
									}}
									onClick={this.handleCreateAutomaticFilterChildBoxes}
								>
									Create Automatic Filters
								</Button>
							}
							<Button
								icon={<PlusOutlined />}
								type="default"
								style={{
									marginRight: "12px",
								}}
								onClick={this.handleAddChildren}
							>
								Add Child Boxes
							</Button>
							{authorizationService.isAbilityPermitted(
								this.props.abilities,
								authorizationService.AbilityType.ExportChildBoxes,
								this.props.clientId
							) && <Button
								icon={<ExportOutlined />}
								type="default"
								style={{
									marginRight: "12px",
								}}
								onClick={this.handleExportChildren}
							>
								Export Child Boxes
							</Button>}
							{authorizationService.isAbilityPermitted(
								this.props.abilities,
								authorizationService.AbilityType.ImportChildBoxes,
								this.props.clientId
							) && <Button
								icon={<ImportOutlined />}
								type="primary"
								onClick={this.handleImportChildren}
							>
								Import Child Boxes
							</Button>}
						</div>
					</FormHeading>
					<div
						style={{
							width: "100%",
							display: "grid",
							gridTemplateColumns: "1fr 1fr",
							gap: "0px 12px",
							paddingBottom: "12px",
							margin: 0,
						}}
					>
						<div
							style={{
								display: "flex",
								justifyContent: "flex-start",
							}}
						>
							<b
								style={{
									width: '88px',
									textAlign: "left",
									paddingTop: "4px",
								}}
							>
								Name:
							</b>
							<Input
								ref={(input) => {
									this.nameInput = input;
								}}
								value={boxName}
								autoFocus
								width="50%"
								onChange={(
									event: React.ChangeEvent<HTMLInputElement>
								) => this.handleBoxNameChange(event)}
							/>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "flex-start",
							}}
						>
							<b
								style={{
									width: '88px',
									textAlign: "left",
									paddingTop: "4px",
								}}
							>
								URL:
							</b>
							<Input
								ref={(input) => {
									this.nameInput = input;
								}}
								value={boxUrl}
								autoFocus
								width="50%"
								onChange={(
									event: React.ChangeEvent<HTMLInputElement>
								) => this.handleBoxUrlChange(event)}
							/>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "100%",
								paddingTop: "4px",
							}}
						>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-start",
									width: "50%"
								}}
							>
								<b
									style={{
										width: '79px',
										textAlign: "left",
										paddingTop: "4px",
									}}
								>
									Type:
								</b>
								{renderedBoxTypes}
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									width: "50%"
								}}
							>
								<b
									style={{
										width: '88px',
										textAlign: "left",
										paddingTop: "4px",
									}}
								>
									Child Type:
								</b>
								{renderedBoxDefaultChildTypes}
							</div>
						</div>
					</div>
					<div
						style={{
							width: "100%",
							display: "grid",
							gridTemplateColumns: "1fr 1fr",
							gap: "0px 12px",
							paddingBottom: "12px",
							margin: 0,
							overflowY: "visible",
						}}
					>
						{attributesTitle}
						{associationsTitle}
						<div
							style={{
								paddingLeft: "12px",
							}}
						>
							{renderedBoxNonAssociationAttributeTypes}
						</div>
						<div
							style={{
								paddingLeft: "12px",
							}}
						>
							{renderedBoxAssociationAttributeTypes}
						</div>
					</div>
					{smartPageTitle}
					{smartPages}
					{propertiesTitle}
					<div
						style={{
							paddingLeft: "12px",
						}}
					>
						{renderedBoxProperties}
					</div>
				</div>
				{this.state.isImportExportChildBoxesDialogShown &&
					this.props.boxKey &&
					<ImportExportChildBoxesExcelDialog
						isImporting={this.state.isImporting}
						isVisible
						onImportButtonClick={this.handleImportChildBoxesDialogImportButtonClick}
						onCloseOrCancelButtonClick={this.handleImportChildBoxesDialogCloseOrCancelButtonClick}
						boxKey={this.props.boxKey}
						boxChildTypeKey={this.state.currentBoxDefaultChildTypeKey}
						box={this.state.box}
						boxMap={this.props.boxMap}
						flattenedBoxMap={this.props.flattenedBoxMap}
						boxTypeMap={this.state.boxTypes}
					/>
				}
				{this.state.isAddChildBoxesDialogShown &&
					this.props.boxKey &&
					<AddChildBoxesDialog
						isVisible
						onAddButtonClick={this.handleAddChildBoxesDialogAddButtonClick}
						onCloseOrCancelButtonClick={this.handleAddChildBoxesDialogCloseOrCancelButtonClick}
						boxKey={this.props.boxKey}
						boxChildTypeKey={this.state.currentBoxDefaultChildTypeKey}
						box={this.state.box}
						boxMap={this.props.boxMap}
						boxTypeMap={this.state.boxTypes}
						boxTypeCounters={this.props.boxTypeCounters}
					/>
				}

				{this.state.isCreateAutomaticFilterChildBoxesDialogShown &&
					this.props.boxKey &&
					this.props.flattenedBoxMap !== undefined &&
					this.state.boxTypes !== undefined &&
					<CreateAutomaticFilterChildBoxesDialog
						isVisible
						onAddButtonClick={this.handleCreateAutomaticFilterChildBoxesDialogAddButtonClick}
						onCloseOrCancelButtonClick={this.handleCreateAutomaticFilterChildBoxesDialogCloseOrCancelButtonClick}
						boxKey={this.props.boxKey}
						boxChildTypeKey={this.state.currentBoxDefaultChildTypeKey}
						box={this.state.box}
						flattenedBoxMap={this.props.flattenedBoxMap}
						boxTypeMap={this.state.boxTypes}
						boxTypeCounters={this.props.boxTypeCounters}
						attributeTypes={{}}
					/>
				}
			</Modal>
		);
	}

	private handleOKButtonClick = () => {
		// console.log('handleOKButtonClick');
		// console.log(this.state.box);

		// Notify the callback that the OK button has been clicked, passing the new
		// box name and box type key

		// console.log(
		// 	"Lens Pages: " + JSON.stringify(this.state.box!.smartPages)
		// );

		this.props.onOKButtonClick(
			this.state.box,
			this.state.boxTypes,
			this.state.associationsToEnable,
			this.state.lensGroupsToAdd,
			this.state.lensesToAdd
		);
	};

	private handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
		// Don't do anything if the Lens Page dialog is open
		if (this.state.isAddEditSmartPageDialogVisible) {
			return;
		}

		const keyCode = event.keyCode;

		// Enter is the same as clicking OK.
		if (keyCode === KEY_CODE_ENTER) {
			this.handleOKButtonClick();
		}
	};

	private handleBoxNameChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		// Get the new box name
		// TODO: Disable the OK button if the box name is empty
		const boxName = event.target.value;

		// Get the box
		const updatedBox = JSON.parse(JSON.stringify(this.state.box));

		// Set the new name of the box
		updatedBox.name = boxName;

		// Update the state with the new box name
		this.setState({
			box: updatedBox,
		});
	};
	private handleBoxUrlChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		// Get the new box name
		// TODO: Disable the OK button if the box name is empty
		const boxUrl = event.target.value;

		// Get the box
		const updatedBox = JSON.parse(JSON.stringify(this.state.box));

		// Set the new name of the box
		updatedBox.url = boxUrl;

		// Update the state with the new box name
		this.setState({
			box: updatedBox,
		});
	};

	private handleBoxTypeChange = (boxTypeKey: string) => {
		// Get the box
		const updatedBox = JSON.parse(JSON.stringify(this.state.box));

		// Set the new type of the box
		updatedBox.boxType = boxTypeKey;

		// Update the state with the new box type key
		this.setState({
			currentBoxTypeKey: boxTypeKey,
			box: updatedBox,
		});
	};

	private handleBoxDefaultChildTypeChange = (boxTypeKey: string) => {
		// Get the box
		const updatedBox = JSON.parse(JSON.stringify(this.state.box));

		// Set the new default child type of the box
		updatedBox.defaultChildBoxType = boxTypeKey;

		// Update the state with the new box type key
		this.setState({
			currentBoxDefaultChildTypeKey: boxTypeKey,
			box: updatedBox,
		});
	};

	private handlePropertyChange = (
		propertyKey: string,
		propertyValue: propertyLib.PropertyValue | undefined
	) => {
		// Get the box
		const updatedBox = JSON.parse(JSON.stringify(this.state.box));

		// Update the default properties
		if (propertyValue !== undefined) {
			updatedBox.defaultProperties[propertyKey] = propertyValue;
		}

		// Update our state
		this.setState({
			box: updatedBox,
		});
	};

	private handleAttributeChange = (
		attributeKey: string,
		attributeValue: attributeLib.AttributeValue | undefined
	) => {
		// Get the box
		const updatedBox = JSON.parse(JSON.stringify(this.state.box));

		// Update the attribute
		updatedBox.attributes[attributeKey] = attributeValue;

		// Update our state
		this.setState({
			box: updatedBox,
		});
	};

	private handleDeleteAssociations = () => {
		const self = this;

		Modal.confirm({
			zIndex: 9999,
			content: "Are you sure you want to delete associations?",
			onOk() {
				// Get the box
				const updatedBox = JSON.parse(JSON.stringify(self.state.box));

				// Delete the box associations
				boxLib.deleteBoxAssociations(updatedBox, self.props.boxTypes);

				self.setState({
					box: updatedBox,
				});
			},
			onCancel() {
				// console.log('Cancel');
			},
		});
	};

	private handleExportChildren = () => {
		this.setState({ isImportExportChildBoxesDialogShown: true, isImporting: false });
	}

	private handleImportChildren = () => {
		this.setState({ isImportExportChildBoxesDialogShown: true, isImporting: true });
	}

	private handleImportChildBoxesDialogImportButtonClick = (box: boxLib.Box,
		boxTypes: boxTypeLib.BoxTypeMap): void => {
		if (this.state.isImporting) {
			this.setState({ box, boxTypes, isImportExportChildBoxesDialogShown: false });
		} else {
			this.setState({ isImportExportChildBoxesDialogShown: false });
		}
	}

	private handleImportChildBoxesDialogCloseOrCancelButtonClick = (): void => {
		this.setState({ isImportExportChildBoxesDialogShown: false, isImporting: false });
	}

	private handleAddChildren = () => {
		this.setState({ isAddChildBoxesDialogShown: true });
	}

	private handleCreateAutomaticFilterChildBoxes = () => {
		this.setState({ isCreateAutomaticFilterChildBoxesDialogShown: true });
	}

	private handleAddChildBoxesDialogAddButtonClick = (box: boxLib.Box): void => {
		this.setState({ box, isAddChildBoxesDialogShown: false });
	}

	private handleAddChildBoxesDialogCloseOrCancelButtonClick = (): void => {
		this.setState({ isAddChildBoxesDialogShown: false });
	}

	private handleCreateAutomaticFilterChildBoxesDialogAddButtonClick = (
		box: boxLib.Box,
		editedBoxTypes: boxTypeLib.BoxTypeMap,
		associationsToEnable: AssociationToEnable[],
		lensGroupsToAdd: lensesLib.LensGroupMap,
		lensesToAdd: lensesLib.LensMap
	): void => {

		const newAssociationsToEnable = [...this.state.associationsToEnable, ...associationsToEnable];
		
		this.setState({
			box,
			boxTypes: editedBoxTypes,
			isCreateAutomaticFilterChildBoxesDialogShown: false,
			associationsToEnable: newAssociationsToEnable,
			lensGroupsToAdd: {...this.state.lensGroupsToAdd, ...lensGroupsToAdd},
			lensesToAdd: {...this.state.lensesToAdd, ...lensesToAdd}

		});
	}

	private handleCreateAutomaticFilterChildBoxesDialogCloseOrCancelButtonClick = (): void => {
		this.setState({ isCreateAutomaticFilterChildBoxesDialogShown: false });
	}


	private renderBoxTypes = (selectedBoxTypeKey: string,
		onChangeHandler: BoxTypeOnChangeHandler,
		boxTypes: boxTypeLib.BoxTypeMap | undefined) => {
		// if (boxTypes) console.log(Object.keys(boxTypes));

		const canCreateBoxTypes = (currentBoxTypeKey: string) => {
			return boxTypes && boxTypes[currentBoxTypeKey] && boxTypes[currentBoxTypeKey].disableBoxCreation ? boxTypes[currentBoxTypeKey].disableBoxCreation === false : true;
		};

		// if (boxTypes) console.log(Object.keys(boxTypes).filter(canCreateBoxTypes));

		// Render each of the box types as an option
		const renderedBoxTypeOptions = boxTypes
			? Object.keys(boxTypes)
				.filter(canCreateBoxTypes)
				.map((currentBoxTypeKey) => {
					// Get the box tyoe
					const boxType = boxTypes[currentBoxTypeKey];

					// Get the name of the box type
					const boxTypeName = boxType.name;

					// Render the box type option
					return (
						<Select.Option
							key={currentBoxTypeKey}
							value={currentBoxTypeKey}
						>
							{boxTypeName}
						</Select.Option>
					);
				})
			: null;

		// TODO: Figure out why select menu isn't being displayed
		// Do we need to set getPopupContainer={(triggerNode: Element) =>
		// popupContainer} ?

		return (
			<Select
				onChange={onChangeHandler}
				value={selectedBoxTypeKey}
				style={{ width: "75%" }}
				getPopupContainer={(node) => {
					let popupContainer: HTMLElement | null =
						window.document.documentElement;
					if (node && node.parentElement) {
						popupContainer = node.parentElement;
					}
					return popupContainer as HTMLElement;
				}}
			>
				{renderedBoxTypeOptions}
			</Select>
		);
	};

	private handleBoxInheritParentPropertiesChange = (
		event: CheckboxChangeEvent
	) => {
		// console.log('handleBoxInheritParentPropertiesChange')

		// Get the new state
		const inheritParentProperties = event.target.checked;

		// console.log(`inheritParentProperties=${inheritParentProperties}`)

		// Get the box
		const updatedBox = JSON.parse(JSON.stringify(this.state.box));

		// Update the box
		updatedBox.inheritParentProperties = inheritParentProperties;

		// console.log(updatedBox);

		// Update the state with the new box properties
		this.setState({
			box: updatedBox,
		});
	};

	private renderBoxDefaultProperties = (
		attributeTypes: attributeTypeLib.AttributeTypeMap | undefined
	) => {
		// If we don't have a box then there's nothing to render
		if (!this.state.box) {
			return null;
		}

		return (
			<PropertyList
				key="properties"
				onPropertyChange={this.handlePropertyChange}
				defaultProperties={this.state.box.defaultProperties}
				attributeTypes={attributeTypes}
				boxStyles={this.props.boxStyles}
			/>
		);
	};

	private handleBoxInheritParentAttributesChange = (
		event: CheckboxChangeEvent
	) => {
		// Get the new state
		const inheritParentAttributes = event.target.checked;

		// Get the box
		const updatedBox = JSON.parse(JSON.stringify(this.state.box));

		// Update the box
		updatedBox.inheritParentAttributes = inheritParentAttributes;

		// Update the state with the new box properties
		this.setState({
			box: updatedBox,
		});
	};

	private renderBoxNonAssociationAttributeTypes = () => {
		// If we don't have any box types there's nothing to render
		if (!this.state.boxTypes) {
			return null;
		}

		// Get the the box attributes
		const boxAttributes = this.state.box
			? this.state.box.attributes
			: undefined;

		return (
			<AttributeList
				key="attributes-nonassociation"
				onAttributeChange={this.handleAttributeChange}
				attributes={boxAttributes}
				boxTypeKey={this.state.currentBoxTypeKey}
				boxTypes={this.state.boxTypes}
				boxMap={this.props.boxMap}
				displayAssociations={false}
				displayNonAssociations
			/>
		);
	};

	private renderBoxAssociationAttributeTypes = () => {
		// If we don't have any box types there's nothing to render
		if (!this.state.boxTypes) {
			return null;
		}

		// Get the the box attributes
		const boxAttributes = this.state.box
			? this.state.box.attributes
			: undefined;

		return (
			<AttributeList
				key="attributes-association"
				onAttributeChange={this.handleAttributeChange}
				attributes={boxAttributes}
				boxTypeKey={this.state.currentBoxTypeKey}
				boxTypes={this.state.boxTypes}
				boxMap={this.props.boxMap}
				displayAssociations
				displayNonAssociations={false}
			/>
		);
	};
}
