import { Button } from "antd";
import React, { useState } from "react";
import RightMenuHeading from "../../../organisms/RightMenuHeading";
import RightMenuHeadingText from "../../../organisms/RightMenuHeadingText";
import JsonEditorDialog from "./JsonEditorDialog";

export interface JsonEditorMenuProps {
	onBoxTypesChange: (boxTypes: any) => void,
	onValueTypesChange: (valueTypes: any) => void,
	onBoxesChange: (boxex: any) => void,
	onBoxVisualisationsChange: (boxVisualisations: any) => void,
	onBoxStylesChange: (boxStyles: any) => void,
	onLensesChange: (lenses: any) => void,
	onLensGroupsChange: (lensGroups: any) => void,
	areJSONEditorInputsDisabled: boolean,
	isJSONImportButtonsDisabled: boolean,
	boxTypes?: any,
	valueTypes?: any,
	boxes?: any,
	boxVisualisations?: any,
	boxStyles?: any,
	lenses?: any,
	lensGroups?: any,
}

const JsonEditorMenu: React.FC<JsonEditorMenuProps> = (props) => {

	const [jsonString, setJsonString] = useState("{}");
	const [jsonType, setJsonType] = useState("");
	const [showDialog, setShowDialog] = useState(false);
	const defaultHandler = (jsonString: string) => {
		console.log(jsonString);
	};
	const [handleExecuteChange, setHandleExecuteChange] = useState(() => { return defaultHandler });

	const openDialog = (jsonString: string, jsonType: string, executeChange: (updatedData: any) => void) => {
		setHandleExecuteChange(() => { return executeChange; });
		setJsonString(jsonString);
		setJsonType(jsonType)

		setShowDialog(true);
	}

	const handleOKButtonClick = (localJsonString: string) => {
		const updatedData = JSON.parse(localJsonString);
		handleExecuteChange(updatedData);
		setShowDialog(false);
	};

	return (props.areJSONEditorInputsDisabled ? <></> : 
		<>
			<RightMenuHeading>
				<RightMenuHeadingText>JSON Editor</RightMenuHeadingText>
			</RightMenuHeading>

			<JsonEditorDialog
				jsonString={jsonString}
				jsonType={jsonType}
				isVisible={showDialog}
				onOKButtonClick={handleOKButtonClick}
				onCancelButtonClick={(() => { setShowDialog(false); })}
			/>
			<div
				style={{

					display: "grid",
					gridTemplateColumns: "1fr 1fr",
					gap: "6px 6px",
					marginLeft: "2px", marginRight: "2px"
				}}
			>
				<Button
					size="small"
					type="primary"
					onClick={() => {
						if (props.lenses) {
							const lensesJSONText = JSON.stringify(props.lenses, null, 2);
							openDialog(lensesJSONText, "Power Lenses", props.onLensesChange);
						}
					}}
				>
					Power Lenses
				</Button>
				<Button
					size="small"
					type="primary"
					onClick={() => {
						if (props.lensGroups) {
							const lensGroupsJSONText = JSON.stringify(props.lensGroups, null, 2);
							openDialog(lensGroupsJSONText, "Lens Groups", props.onLensGroupsChange);
						}
					}}
				>
					Power Lens Groups
				</Button>
				<Button
					size="small"
					type="primary"
					onClick={() => {
						if (props.boxTypes) {
							const boxTypesJSONText = JSON.stringify(props.boxTypes, null, 2);
							openDialog(boxTypesJSONText, "Box Types", props.onBoxTypesChange);
						}
					}}
				>
					Box Types
				</Button>
				<Button
					size="small"
					type="primary"
					onClick={() => {
						if (props.boxes) {
							const boxesJSONText = JSON.stringify(props.boxes, null, 2);
							openDialog(boxesJSONText, "Boxes", props.onBoxesChange);
						}
					}}
				>
					Boxes
				</Button>
				<Button
					size="small"
					type="primary"
					onClick={() => {
						if (props.valueTypes) {
							const valueTypesJSONText = JSON.stringify(props.valueTypes, null, 2);
							openDialog(valueTypesJSONText, "Value Types", props.onValueTypesChange);
						}
					}}
				>
					Values Types
				</Button>
				<Button
					size="small"
					type="primary"
					onClick={() => {
						if (props.boxStyles) {
							const boxStylesJSONText = JSON.stringify(props.boxStyles, null, 2);
							openDialog(boxStylesJSONText, "Box Styles", props.onBoxStylesChange);
						}
					}}
				>
					Box Styles
				</Button>
			</div>
			<div style={{ paddingTop: "4px", paddingBottom: "12px", paddingLeft: "2px", paddingRight: "2px" }}>
				<Button
					size="small"
					block
					type="primary"
					onClick={() => {
						if (props.boxVisualisations) {
							const boxVisualisationsJSONText = JSON.stringify(props.boxVisualisations, null, 2);
							openDialog(boxVisualisationsJSONText, "Box Visualisations", props.onBoxVisualisationsChange);
						}
					}}
				>
					Box Visualisations
				</Button>
			</div>

		</>);
};

export default JsonEditorMenu;