export const openInNewTab = (url: string) => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
	if (newWindow) {
		newWindow.opener = null;
	}
};

export const getUrlWithPrefix = (url: string) => {
	// If the attribute value doesn't start with an http:// then add it
	const href = url.toLowerCase().startsWith('http://') ||
		url.toLowerCase().startsWith('https://') ||
		url.toLowerCase().startsWith('//') ||
		url.toLowerCase().startsWith('file://')
		? url.toLowerCase() : `//${url}`;

	return href;
};