import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

import { v4 as uuid } from "uuid";

import * as valueTypeLib from "@lib/box/value-type";
import * as attributeTypeLib from "@lib/box/attribute-type";
import * as attributeLib from '@lib/box/attribute';
import * as boxLib from "@lib/box/box";
import * as boxTypeLib from "@lib/box/box-type";
import * as illustrationLib from "@lib/illustration/illustration";

interface AttributeRow {
	[index: string]: attributeLib.AttributeValue;

	"Box Name": string;
	"Box UUID": string;
	"Box URL": string;
}

interface BoxAttributes {
	boxName: string | undefined;
	boxURL: string | undefined;
	boxTypeKey: string;
	attributes: attributeLib.AttributeMap;
}

interface BoxAttributesMap {
	[boxKey: string]: BoxAttributes;
}

interface BoxTypeAttributesMap {
	[boxTypeName: string]: AttributeRow[];
}

interface IllustrationAttributes {
	boxAttributes: BoxAttributesMap;
	boxTypeAttributes: BoxTypeAttributesMap;
}

interface WorksheetNameToBoxTypeKeyMap {
	[safeWorksheetName: string]: string;
}

type AttributeColumnOffsetMap = Record<string, number>;

interface WorksheetState {
	// Keeps track of the column offsets of the attributes of each box type.
	attributeColumnOffsets: AttributeColumnOffsetMap;

	// The index of the next column an attribute can be added at (zero based)
	nextAttributeColumnIndex: number;

	// The current row (zero based, skip the header)
	currentRow: number;
}

// The header rows
const BOX_TYPE_HEADER_ROW = 0;
const ATTRIBUTE_HEADER_ROW = 1;

// The indices of the columns in an imported hierarchy spreadsheet.
const IMPORT_BOX_NAME_COLUMN_INDEX = 4;

// The default height of a row
const DEFAULT_ROW_HEIGHT = 15;


const getAttributeValueForTypeImport = (attributeValue: attributeLib.AttributeValue,
	attributeType: attributeTypeLib.AttributeType,
	boxNameToUUIDMap: Record<string, string>): attributeLib.AttributeValue => {
	if (attributeType.valueType === valueTypeLib.ValueTypeKey.Number) {
		// Always represent numbers as strings internally.
		return String(attributeValue);
	}
	
	if (attributeType.valueType !== valueTypeLib.ValueTypeKey.Associations) {
		return String(attributeValue);
	}

	let { associationsType } = attributeType; 
	if (!associationsType) {
		associationsType = attributeTypeLib.AssociationType.Uuids;
	}

	if (associationsType === attributeTypeLib.AssociationType.Uuids) {
		return String(attributeValue)
			.split(',')
			.map((boxName: string) => {
				// Handle whitespace.
				const actualBoxName = boxName.trim() 
				return Object.prototype.hasOwnProperty.call(boxNameToUUIDMap, actualBoxName)
					? boxNameToUUIDMap[actualBoxName]
					: ''
			})
			.filter((boxKey: string) => boxKey !== '')
			.join(',')
	} else if (associationsType === attributeTypeLib.AssociationType.NamesLookup ||
		associationsType === attributeTypeLib.AssociationType.AttributeValuesLookup) {
		return String(attributeValue);
	}

	return "";
}

const getAttributeValueForTypeExport = (attributeValue: string,
	attributeType: attributeTypeLib.AttributeType,
	allBoxesMap : boxLib.BoxMap): string | number => {
	if (attributeType.valueType !== valueTypeLib.ValueTypeKey.Associations) {
		if (attributeType.valueType === valueTypeLib.ValueTypeKey.Number) {
			return Number(attributeValue);
		}

		return attributeValue;
	}

	let { associationsType } = attributeType; 
	if (!associationsType) {
		associationsType = attributeTypeLib.AssociationType.Uuids;
	}

	if (associationsType === attributeTypeLib.AssociationType.Uuids) {
		return String(attributeValue)
			.split(',')
			.map((boxKey: string) => allBoxesMap.hasOwnProperty(boxKey)
				? allBoxesMap[boxKey].name
				: '')
			.join(', ')
	} else if (associationsType === attributeTypeLib.AssociationType.NamesLookup ||
		associationsType === attributeTypeLib.AssociationType.AttributeValuesLookup) {
		return attributeValue;
	}

	return "";
}

const doesAttributeRowHaveNonBlankValues = (attributeRow: attributeLib.AttributeMap,
	attributeTypes: attributeTypeLib.AttributeTypeMap,
	boxTypeKey: string,
	boxNameToUUIDMap: Record<string, string>): boolean => {
	return Object.keys(attributeRow).some(
		(attributeName: string) => {
			if (attributeName !== undefined &&
				attributeName !== null &&
				attributeName !== '' &&
				attributeName.toLocaleLowerCase() !== 'blank' &&
				attributeName.toLocaleLowerCase().indexOf('blank_') < 0 &&
				attributeName.toLocaleLowerCase().indexOf('__blank') < 0 &&
				attributeName.toLocaleLowerCase().indexOf('__empty') < 0 &&
				attributeName.toLocaleLowerCase() !== '__rownum__') {

				if (attributeName === "Box UUID" ||
					attributeName === "Box Name" ||
					attributeName === "Box URL" ||
					attributeTypeLib.isDefaultAttributeTypeKeyExcludingDefaultAssociation(attributeName)) {
					return attributeRow[attributeName] !== ''
				}

				const attributeTypeKey = attributeTypeLib
					.findAttributeTypeKeyForName(boxTypeKey, attributeTypes, attributeName);

				const attributeType = attributeTypes[attributeTypeKey];
				if (attributeType) {
					const attributeValue = attributeRow[attributeName];

					const actualAttributeValue = getAttributeValueForTypeImport(attributeValue,
						attributeType,
						boxNameToUUIDMap);

					if (actualAttributeValue !== undefined &&
						actualAttributeValue !== null &&
						actualAttributeValue !== '') {
						return true;
					}
				}
			}

			return false;
		});
}

const doesArrayAttributeRowHaveNonBlankValues = (attributeRow: attributeLib.AttributeValue[],
	headerRow: attributeLib.AttributeValue[],
	attributeTypes: attributeTypeLib.AttributeTypeMap,
	boxTypeKey: string,
	boxNameToUUIDMap: Record<string, string>): boolean => {
	return attributeRow.some(
		(attributeColumnValue: attributeLib.AttributeValue, attributeColumnIndex: number) => {
			const attributeName = String(headerRow[attributeColumnIndex]);
			const lowerCaseAttributeName = attributeName.toLocaleLowerCase();

			if (attributeName !== undefined &&
				attributeName !== null &&
				attributeName !== '' &&
				lowerCaseAttributeName !== 'blank' &&
				lowerCaseAttributeName.indexOf('blank_') < 0 &&
				lowerCaseAttributeName.indexOf('__blank') < 0 &&
				lowerCaseAttributeName.indexOf('__empty') < 0 &&
				lowerCaseAttributeName !== '__rownum__') {

				if (attributeName === "Box UUID" ||
					attributeName === "Box Name" ||
					attributeName === "Box URL" ||
					attributeTypeLib.isDefaultAttributeTypeKeyExcludingDefaultAssociation(attributeName)) {
					return attributeColumnValue !== ''
				}

				const attributeTypeKey = attributeTypeLib
					.findAttributeTypeKeyForName(boxTypeKey, attributeTypes, attributeName);

				const attributeType = attributeTypes[attributeTypeKey];
				if (attributeType) {
					const attributeValue = attributeColumnValue;

					const actualAttributeValue = getAttributeValueForTypeImport(attributeValue,
						attributeType,
						boxNameToUUIDMap);

					if (actualAttributeValue !== undefined &&
						actualAttributeValue !== null &&
						actualAttributeValue !== '') {
						return true;
					}
				}
			}

			return false;
		});
}

const setBoxAttributes = (boxAttributesMap: BoxAttributesMap,
	boxTypeAttributesMap: BoxTypeAttributesMap,
	box: boxLib.Box,
	boxKey: string,
	boxTypeMap: boxTypeLib.BoxTypeMap,
	includeUnnamedBoxes: boolean,
	includeAssociations: boolean
): void => {
	if (box.name !== "" || includeUnnamedBoxes === true) {
		// Get the box type
		const boxTypeKey = box.boxType;
		if (boxTypeKey) {
			// Get the box type
			const boxType = boxTypeMap[boxTypeKey];
			if (boxType) {
				// Get the attribute rows of the box type
				const boxTypeAttributeRows = boxTypeAttributesMap[boxTypeKey];

				// Get the box type attribute types
				const attributeTypes: attributeTypeLib.AttributeTypeMap =
					boxType.attributeTypes;
				if (attributeTypes) {
					const boxAttributes = box.attributes;
					if (boxAttributes) {
						const attributeRow: AttributeRow = {
							"Box Name": box.name,
							"Box UUID": boxKey,
							"Box URL": box.url || ""
						};

						// Get all attribute types
						Object.keys(attributeTypes).forEach(
							(attributeTypeKey) => {
								const attribute = boxAttributes[
									attributeTypeKey
								]
									? boxAttributes[attributeTypeKey]
									: "";

								// Get the attribute type
								const boxAttributeType =
									attributeTypes[attributeTypeKey];
								if (boxAttributeType) {
									// Are we dealing with an associations attribute?
									if (
										includeAssociations || boxAttributeType.valueType !==
										valueTypeLib.ValueTypeKey
											.Associations
									) {
										attributeRow[
											attributeTypeKey
										] = attribute;
									}
								}
							}
						);

						// Set the box attributes
						boxAttributesMap[boxKey] = {
							boxName: box.name,
							boxURL: box.url || '',
							boxTypeKey,
							attributes: attributeRow,
						};

						// Record the box type attributes
						boxTypeAttributeRows.push(attributeRow);
					}
				}
			}
		}
	}
}

const setBoxTypeAttributesForType = (boxTypeAttributesMap: BoxTypeAttributesMap,
	boxTypeKey: string,
	boxTypeMap: boxTypeLib.BoxTypeMap
): void => {
	// Get the box type
	const boxType = boxTypeMap[boxTypeKey];
	if (boxType) {
		// Get the attribute rows of the box type
		if (!boxTypeAttributesMap[boxTypeKey]) {
			boxTypeAttributesMap[boxTypeKey] = [];
		}
	}
}

export const getBoxChildAttributesForType = (box: boxLib.Box,
	boxTypeKey: string,
	boxTypeMap: boxTypeLib.BoxTypeMap | undefined,
	includeUnnamedBoxes: boolean,
	includeAssociations: boolean): IllustrationAttributes => {
	const boxChildAttributes: IllustrationAttributes = {
		boxAttributes: {},
		boxTypeAttributes: {},
	};

	if (!boxTypeMap) {
		return boxChildAttributes;
	}

	const boxChildren = box.children;
	if (!boxChildren || (Object.keys(boxChildren).length <= 0)) {
		// Just set the type
		setBoxTypeAttributesForType(boxChildAttributes.boxTypeAttributes,
			boxTypeKey,
			boxTypeMap);
		return boxChildAttributes;
	}

	const boxChildrenKeys = Object.keys(boxChildren);
	if (boxChildrenKeys.length <= 0) {
		return boxChildAttributes;
	}

	boxChildAttributes.boxTypeAttributes[boxTypeKey] = [];

	boxChildrenKeys
		.forEach((boxChildKey: string) => {
			// Get the box
			const childBox = boxChildren[boxChildKey];
			if (childBox) {
				if (childBox.boxType === boxTypeKey) {
					setBoxAttributes(boxChildAttributes.boxAttributes,
						boxChildAttributes.boxTypeAttributes,
						childBox,
						boxChildKey,
						boxTypeMap,
						includeUnnamedBoxes,
						includeAssociations);
				}
			}
		});

	return boxChildAttributes;
}

// TODO: This needs to return a map of box ID -> attributes instead of an array
// of attribute rows
export const getIllustrationAttributes = (
	illustration: illustrationLib.Illustration,
	flattenedBoxMap: boxLib.BoxMap | undefined,
	boxTypeMap: boxTypeLib.BoxTypeMap | undefined,
	includeUnnamedBoxes: boolean,
	includeAssociations: boolean
): IllustrationAttributes => {
	const illustrationAttributes: IllustrationAttributes = {
		boxAttributes: {},
		boxTypeAttributes: {},
	};

	if (!boxTypeMap) {
		return illustrationAttributes;
	}

	illustrationAttributes.boxTypeAttributes = Object.keys(boxTypeMap).reduce(
		(
			reducedBoxTypeAttributes: BoxTypeAttributesMap,
			boxTypeKey: string
		) => {
			reducedBoxTypeAttributes[boxTypeKey] = [];
			return reducedBoxTypeAttributes;
		},
		{}
	);

	if (!illustration.boxes || !flattenedBoxMap) {
		return illustrationAttributes;
	}

	Object.keys(flattenedBoxMap).forEach((boxKey: string) => {
		// Get the box
		const box = flattenedBoxMap[boxKey];
		if (box) {
			setBoxAttributes(illustrationAttributes.boxAttributes,
				illustrationAttributes.boxTypeAttributes,
				box,
				boxKey,
				boxTypeMap,
				includeUnnamedBoxes,
				includeAssociations);
		}
	});

	return illustrationAttributes;
};

const getSafeWorksheetName = (name: string): string => {
	// Worksheet names are limited to 31 characters and cannot contain the
	// following: \ / ? * [ ]
	const worksheetName = name
		.substring(0, 31)
		.replace("\\", "")
		.replace("/", "")
		.replace("?", "")
		.replace("*", "")
		.replace(":", "")
		.replace(";", "")
		.replace("[", "")
		.replace("]", "");

	return worksheetName;
};

export const getWorksheetNameToBoxTypeKeyMap = (
	boxTypeMap: boxTypeLib.BoxTypeMap
): WorksheetNameToBoxTypeKeyMap => {
	const worksheetNameToBoxTypeKeyMap: WorksheetNameToBoxTypeKeyMap = {};

	Object.keys(boxTypeMap).forEach((boxTypeKey: string) => {
		const boxType = boxTypeMap[boxTypeKey];
		const safeWorksheetName = getSafeWorksheetName(boxType.name);
		worksheetNameToBoxTypeKeyMap[safeWorksheetName] = boxTypeKey;
	});

	return worksheetNameToBoxTypeKeyMap;
};

export const getWorksheetNameForBoxTypeKey = (boxTypeKey: string,
	worksheetNameToBoxTypeKeyMap: WorksheetNameToBoxTypeKeyMap): string | undefined => {
		const workSheetNames = Object.keys(worksheetNameToBoxTypeKeyMap);

		for (let i=0; i < workSheetNames.length; i += 1) {
			const workSheetName = workSheetNames[i];
			const workSheetBoxTypeKey = worksheetNameToBoxTypeKeyMap[workSheetName];
			if (workSheetBoxTypeKey === boxTypeKey) {
				return workSheetName;
			}
		}

		return undefined
	}

const getDefaultAttributeKeys = (): string[] => [
	"Box UUID",
	"ID",
	"Has Lens Pages",
	"Is Canvas",
	"Box Name",
	"Box URL"
];

const getAttributeTypeKeysWithoutDefaults = (
	attributeTypeKeys: string[]
): string[] => {
	const attributeTypeKeysWithoutDefaults = attributeTypeKeys.filter(
		(attributeKey: string) =>
			attributeKey !== "Box Name" &&
			attributeKey !== "Box UUID" &&
			attributeKey !== "Box URL" &&
			attributeKey !== "ID" &&
			attributeKey !== "Is Canvas" &&
			attributeKey !== "Has Lens Pages"
	);

	return attributeTypeKeysWithoutDefaults;
};

const getOrderedAttributeTypeKeys = (attributeTypeKeys: string[]): string[] => {
	const attributeTypeKeysWithoutDefaults = getAttributeTypeKeysWithoutDefaults(
		attributeTypeKeys
	);

	const defaultAttributeKeys = getDefaultAttributeKeys();

	const orderedAttributeTypeKeys = [
		...defaultAttributeKeys,
		...attributeTypeKeysWithoutDefaults,
	];

	return orderedAttributeTypeKeys;
};

const getInitialWorksheetState = (): WorksheetState => ({
	attributeColumnOffsets: {},
	nextAttributeColumnIndex: 0,
	currentRow: 0,
});

const setWorksheetCell = (
	worksheet: XlsxPopulate.Sheet,
	row: number,
	column: number,
	value: string | number,
	indent: number = 0
): void => {
	// Spreadsheets use 1-based indexing
	const c = worksheet.cell(row + 1, column + 1);
	c.value(value);
	c.style({
		indent,
	});
};

const setWorksheetColumnChoices = (
	worksheet: XlsxPopulate.Sheet,
	initialRow: number,
	column: number,
	boxAttributeType: attributeTypeLib.AttributeType
): void => {
	// Select a range of the first 1000 rows
	const range = worksheet.range(initialRow + 1,
		column + 1,
		initialRow + 1000,
		column + 1);

	const choices = attributeTypeLib.getChoicesForAttributeType(
		boxAttributeType
	);
	if (choices.length > 0) {
		// Get the choices as a comma separated list, note
		// that due to a bug in xlsx-populate they need to be
		// also wrapped in quotes. See: https://github.com/dtjohnson/xlsx-populate/issues/104
		const commaSeparatedChoices = `"${choices.join(",")}"`;

		range.dataValidation({
			type: "list",
			allowBlank: false,
			showInputMessage: false,
			prompt: "",
			promptTitle: "",
			showErrorMessage: false,
			error: "",
			errorTitle: "",
			operator: "",
			formula1: commaSeparatedChoices, //Required
			formula2: "",
		});
	}
};

const setWorksheetHeaderForDefaultAttributes = (worksheetState: WorksheetState,
	worksheet: XlsxPopulate.Sheet,
	attributeHeaderRowIndex: number,
	defaultAttributeKeys: string[]): void => {
	// Set up the initial header
	defaultAttributeKeys.forEach((attributeKey: string) => {
		setWorksheetCell(
			worksheet,
			attributeHeaderRowIndex,
			worksheetState.nextAttributeColumnIndex,
			attributeKey
		);

		worksheetState.nextAttributeColumnIndex += 1;
	});
}

const setWorksheetHeaderForAttributeRow = (
	worksheet: XlsxPopulate.Sheet,
	boxAttributeTypeKeys: string[],
	boxAttributeTypes: attributeTypeLib.AttributeTypeMap,
	initialRow: number,
	initialColumn: number,
	includeDefaults: boolean
): void => {
	const headerOrderedAttributeTypeKeys = includeDefaults
		? getOrderedAttributeTypeKeys(boxAttributeTypeKeys)
		: getAttributeTypeKeysWithoutDefaults(boxAttributeTypeKeys);

	headerOrderedAttributeTypeKeys.forEach(
		(attributeTypeKey: string, headerIndex: number) => {
			const header = Object.prototype.hasOwnProperty.call(
				boxAttributeTypes,
				attributeTypeKey
			)
				? boxAttributeTypes[attributeTypeKey].name
				: attributeTypeKey;

			const currentRow = initialRow;
			const currentColumn = initialColumn + headerIndex;

			setWorksheetCell(worksheet, currentRow, currentColumn, header);

			const isBoxAttributeType = Object.prototype.hasOwnProperty.call(
				boxAttributeTypes,
				attributeTypeKey);

			if (isBoxAttributeType) {
				const boxAttributeType = boxAttributeTypes[attributeTypeKey];
				setWorksheetColumnChoices(worksheet,
					initialRow,
					currentColumn,
					boxAttributeType);
			}
		}
	);
};

const setWorksheetRowForAttributeRow = (
	worksheet: XlsxPopulate.Sheet,
	attributeRow: AttributeRow | any,
	boxAttributeTypeKeys: string[],
	boxAttributeTypes: attributeTypeLib.AttributeTypeMap,
	row: number,
	rowOffset: number,
	nonDefaultAttributeInitialColumn: number,
	includeDefaults: boolean,
	indent: number = 0,
	boxMap : boxLib.BoxMap,
	allBoxesMap : boxLib.BoxMap
): void => {
	const cellRow = row + rowOffset;

	if (includeDefaults) {
		const defaultAttributeKeys = getDefaultAttributeKeys();

		defaultAttributeKeys.forEach(
			(attributeTypeKey: string, attributeIndex: number) => {
				if (!Object.prototype.hasOwnProperty.call(attributeRow, attributeTypeKey)) {
					return;
				}

				const cellColumn = attributeIndex;

				const attributeValue = attributeRow[attributeTypeKey];
				const attributeIndent = attributeIndex === IMPORT_BOX_NAME_COLUMN_INDEX ? indent : 0;

				setWorksheetCell(worksheet,
					cellRow,
					cellColumn,
					attributeValue,
					attributeIndent);
			}
		);
	}

	const attributeTypeKeys = getAttributeTypeKeysWithoutDefaults(boxAttributeTypeKeys);

	attributeTypeKeys.forEach(
		(attributeTypeKey: string, attributeIndex: number) => {
			// Skip the header
			const cellRow = row + rowOffset;
			const cellColumn = nonDefaultAttributeInitialColumn + attributeIndex;

			if (Object.prototype.hasOwnProperty.call(attributeRow, attributeTypeKey) 
				&& attributeRow[attributeTypeKey] !== undefined 
				&& attributeRow[attributeTypeKey] !== null) {				
				const attributeValue = getAttributeValueForTypeExport(attributeRow[attributeTypeKey],
					boxAttributeTypes[attributeTypeKey],
					allBoxesMap);

				// console.log(`(${cellRow}, ${cellColumn}) : ${attributeTypeKey} = ${attributeValue}`)

				setWorksheetCell(worksheet, cellRow, cellColumn, attributeValue, 0);
			}
		}
	);

	// All box rows should be the default height
	worksheet.row(cellRow).height(DEFAULT_ROW_HEIGHT);
};

const addBoxTypeAttributesToWorksheet = (
	workbook: XlsxPopulate.Workbook,
	exportBoxMap: boxLib.BoxMap,
	allBoxesMap: boxLib.BoxMap,
	boxAttributesMap: BoxAttributesMap,
	boxTypeMap: boxTypeLib.BoxTypeMap,
	boxTypeKey: string,
	includeAssociations: boolean
): void => {
	const boxType = boxTypeMap[boxTypeKey];
	if (boxType) {
		const boxTypeName = boxType.name;

		// The default attribute key are the first in the header
		const defaultAttributeKeys = getDefaultAttributeKeys();

		// Use the box type name as the worksheet name, but make sure it's
		// acceptable to Excel
		const worksheetName = getSafeWorksheetName(boxTypeName);

		// Add a worksheet
		const worksheet = workbook.addSheet(worksheetName);

		// Set up the worksheet state
		const worksheetState = getInitialWorksheetState();

		// Set up the initial header
		setWorksheetHeaderForDefaultAttributes(worksheetState,
			worksheet,
			ATTRIBUTE_HEADER_ROW,
			defaultAttributeKeys);

		// The current depth (zero based)
		const currentDepth = 0;

		// We want to export every box that has the assigned box type
		const exportBoxKeys = boxLib.getBoxKeysForBoxTypeKey(exportBoxMap, boxTypeKey);

		if (exportBoxKeys.length > 0) {
			// We can use the hierarcy export, but not include any children
			addBoxTypeAttributeHierarchyToWorksheetRecursive(worksheetState,
				worksheet,
				exportBoxKeys,
				exportBoxMap,
				boxAttributesMap,
				boxTypeMap,
				currentDepth,
				false,
				includeAssociations,
				allBoxesMap);
		} else {
			addBoxTypeAttributeHeaderToWorksheet(worksheetState,
				worksheet,
				boxTypeKey,
				boxTypeMap,
				includeAssociations);
		}

		// Set the style of the worksheet
		//
		//  Hide Columns A,B,C, and D
		//  Make Column E - a width of 35
		//  Make All other columns a width of 14
		//  Make Row 1 and Row 2 a height each of 27
		//  For Row 1 and 2 - Bold all text
		//  For Row 1 and 2 - Make the Background Colour #202A45 or RGB 32,42,69
		//  For Row 1 and 2 - Make the Text Colour #FFFFFF or RGB 255,255,255 (White)
		//  Center all other columns except E
		worksheet.column("A").hidden(true);
		worksheet.column("B").hidden(true);
		worksheet.column("C").hidden(true);
		worksheet.column("D").hidden(true);
		worksheet.column("E").width(35);

		// Everything after column E (column 5) should be centered and have wrapped
		// text
		for (let i = 1; i < worksheetState.nextAttributeColumnIndex; i += 1) {
			worksheet
				.column(i)
				.width(i === 5 ? 35 : 14)
				.style({
					horizontalAlignment: i <= 5 ? "left" : "center",
					wrapText: i > 5 ? true : false,
				});
		}

		worksheet
			.row(1)
			.height(27)
			.style({
				fill: {
					type: "solid",
					color: "202A45",
				},
				fontColor: "FFFFFF",
				bold: true,
			});
		worksheet
			.row(2)
			.height(27)
			.style({
				fill: {
					type: "solid",
					color: "202A45",
				},
				fontColor: "FFFFFF",
				bold: true,
			});
	}
};

const addBoxTypeAttributeHeaderToWorksheet = (
	worksheetState: WorksheetState,
	worksheet: XlsxPopulate.Sheet,
	boxTypeKey: string,
	boxTypeMap: boxTypeLib.BoxTypeMap,
	includeAssociations: boolean,
): void => {
	const boxType = boxTypeMap[boxTypeKey];
	if (boxType) {
		// Get the keys of the mixin box types
		const mixinBoxTypeKeys = boxTypeLib.getMixinBoxTypeKeysRecursive(boxTypeKey,
			boxTypeMap);

		const allBoxTypeKeys = [boxTypeKey, ...mixinBoxTypeKeys]

		// Get the box attribute types
		const boxAttributeTypes = boxTypeLib.getBoxTypeAttributeTypeCacheForType(boxTypeKey);	

		allBoxTypeKeys.forEach((currentBoxTypeKey: string) => {
			const currentBoxType = boxTypeMap[currentBoxTypeKey];
			if (!currentBoxType) {
				return
			}

			const currentAttributeTypes = currentBoxType.attributeTypes;

			let currentAttributeTypeKeys = attributeTypeLib
				.getNonDefaultAttributeTypeKeysIncludingDefaultAssociation(
					includeAssociations ? Object.keys(currentAttributeTypes) : attributeTypeLib
						.getNonAssociationAttributeTypeKeys(currentAttributeTypes)
				);

			// If the box type is a mixin, ignore the default association
			if(includeAssociations && currentBoxTypeKey !== boxTypeKey) {
				currentAttributeTypeKeys = currentAttributeTypeKeys
					.filter((attributeTypeKey) => attributeTypeKey !== 'DefaultAssociation');
			}


			// If we haven't yet set the header for this box type, fill it out
			if (
				!Object.prototype.hasOwnProperty.call(
					worksheetState.attributeColumnOffsets,
					currentBoxTypeKey
				)
			) {
				// Store the initial column offset for this box type
				const boxTypeAttributeColumnIndex = worksheetState.nextAttributeColumnIndex;
				worksheetState.attributeColumnOffsets[currentBoxTypeKey] = boxTypeAttributeColumnIndex;
				worksheetState.nextAttributeColumnIndex += currentAttributeTypeKeys.length;

				// Set the box type header
				setWorksheetCell(
					worksheet,
					BOX_TYPE_HEADER_ROW,
					boxTypeAttributeColumnIndex,
					currentBoxType.name
				);

				// Set the attribute headers for the type
				setWorksheetHeaderForAttributeRow(
					worksheet,
					currentAttributeTypeKeys,
					boxAttributeTypes,
					ATTRIBUTE_HEADER_ROW,
					boxTypeAttributeColumnIndex,
					false
				);
			}
		})
	}
};

const addBoxTypeAttributeHierarchyToWorksheetRecursive = (
	worksheetState: WorksheetState,
	worksheet: XlsxPopulate.Sheet,
	boxKeys: string[],
	boxMap: boxLib.BoxMap,
	boxAttributesMap: BoxAttributesMap,
	boxTypeMap: boxTypeLib.BoxTypeMap,
	currentDepth: number,
	includeChildren: boolean,
	includeAssociations: boolean,
	allBoxesMap: boxLib.BoxMap,
): void => {
	boxKeys.forEach((boxKey: string) => {
		// Get the box
		const box = boxMap[boxKey];
		if (!box) {
			return;
		}

		// Get the box type
		const boxTypeKey = box.boxType;

		const boxType = boxTypeMap[boxTypeKey];
		if (boxType) {
			// Get the keys of the mixin box types
			const mixinBoxTypeKeys = boxTypeLib.getMixinBoxTypeKeysRecursive(boxTypeKey,
				boxTypeMap);

			const allBoxTypeKeys = [boxTypeKey, ...mixinBoxTypeKeys]

			// Get the box attribute types
			const boxAttributeTypes = boxTypeLib.getBoxTypeAttributeTypeCacheForType(boxTypeKey);	

			// Get the box attributes
			const boxAttributes = boxAttributesMap[boxKey];
			if (boxAttributes) {
				allBoxTypeKeys.forEach((currentBoxTypeKey: string, currentBoxTypeKeyIndex: number) => {
					const currentBoxType = boxTypeMap[currentBoxTypeKey];
					if (!currentBoxType) {
						return
					}

					const currentAttributeTypes = currentBoxType.attributeTypes;

					let currentAttributeTypeKeys = attributeTypeLib
						.getNonDefaultAttributeTypeKeysIncludingDefaultAssociation(
							includeAssociations
								? Object.keys(currentAttributeTypes)
								: attributeTypeLib.getNonAssociationAttributeTypeKeys(currentAttributeTypes)
						);

					// If the box type is a mixin, ignore the default association
					if(includeAssociations && currentBoxTypeKey !== boxTypeKey) {
						currentAttributeTypeKeys = currentAttributeTypeKeys
							.filter((attributeTypeKey) => attributeTypeKey !== 'DefaultAssociation');
					}

					// If we haven't yet set the header for this box type, fill it out
					if (
						!Object.prototype.hasOwnProperty.call(
							worksheetState.attributeColumnOffsets,
							currentBoxTypeKey
						)
					) {
						// Store the initial column offset for this box type
						const boxTypeAttributeColumnIndex = worksheetState.nextAttributeColumnIndex;
						worksheetState.attributeColumnOffsets[currentBoxTypeKey] = boxTypeAttributeColumnIndex;
						worksheetState.nextAttributeColumnIndex += currentAttributeTypeKeys.length;

						// Set the box type header
						setWorksheetCell(
							worksheet,
							BOX_TYPE_HEADER_ROW,
							boxTypeAttributeColumnIndex,
							currentBoxType.name
						);

						// Set the attribute headers for the type
						setWorksheetHeaderForAttributeRow(
							worksheet,
							currentAttributeTypeKeys,
							boxAttributeTypes,
							ATTRIBUTE_HEADER_ROW,
							boxTypeAttributeColumnIndex,
							false
						);
					}

					const initialColumnIndex = worksheetState.attributeColumnOffsets[currentBoxTypeKey];

					const currentAttributes = {
						...boxAttributes.attributes,
						...box.attributes
					}

					// Set the row for the box attributes. For the hierarchy sheet,
					// we have two headers and so use a row offset of 2.
					const includeDefaults = (currentBoxTypeKeyIndex === 0)
					setWorksheetRowForAttributeRow(
						worksheet,
						currentAttributes,
						currentAttributeTypeKeys,
						currentAttributeTypes,
						worksheetState.currentRow,
						2,
						initialColumnIndex,
						includeDefaults,
						currentDepth,
						boxMap,
						allBoxesMap
					);
				})

				// Update the row
				worksheetState.currentRow += 1;
			}
		}

		if (includeChildren && box.children) {
			const childDepth = currentDepth + 1;

			const childBoxKeys = Object.keys(box.children);

			addBoxTypeAttributeHierarchyToWorksheetRecursive(worksheetState,
				worksheet,
				childBoxKeys,
				box.children,
				boxAttributesMap,
				boxTypeMap,
				childDepth,
				includeChildren,
				includeAssociations, 
				allBoxesMap);
		}
	});
};

const addBoxTypeAttributeHierarchyToWorksheet = (
	workbook: XlsxPopulate.Workbook,
	boxMap: boxLib.BoxMap,
	flattenedBoxMap: boxLib.BoxMap,
	boxKeys: string[],
	boxAttributesMap: BoxAttributesMap,
	boxTypeMap: boxTypeLib.BoxTypeMap,
	includeAssociations: boolean
): void => {
	// The default attribute key are the first in the header
	const defaultAttributeKeys = getDefaultAttributeKeys();

	// Add each the boxes as a root of the hierarchy
	boxKeys.forEach((boxKey: string) => {
		// Get the worksheet name
		const box = flattenedBoxMap[boxKey];
		const worksheetName = getSafeWorksheetName(box.name);

		// Add a worksheet
		const worksheet = workbook.addSheet(worksheetName);

		// Set up the worksheet state
		const worksheetState = getInitialWorksheetState();

		// Set up the initial header
		setWorksheetHeaderForDefaultAttributes(worksheetState,
			worksheet,
			ATTRIBUTE_HEADER_ROW,
			defaultAttributeKeys);

		let exportBoxMap: boxLib.BoxMap | undefined = undefined;

		if (boxKey === "root") {
			exportBoxMap = boxMap;
		} else {
			const box = boxLib.findBoxInBoxMapForKey(boxMap, boxKey);
			if (box) {
				exportBoxMap = {
					[boxKey]: box,
				};
			}
		}

		if (exportBoxMap) {
			// The current depth (zero based)
			const currentDepth = 0;

			const exportBoxKeys = Object.keys(exportBoxMap);

			addBoxTypeAttributeHierarchyToWorksheetRecursive(worksheetState,
				worksheet,
				exportBoxKeys,
				exportBoxMap,
				boxAttributesMap,
				boxTypeMap,
				currentDepth,
				true,
				includeAssociations,
				flattenedBoxMap);
		}

		// Set the style of the worksheet
		//
		//  Hide Columns A,B,C, and D
		//  Make Column E - a width of 35
		//  Make All other columns a width of 14
		//  Make Row 1 and Row 2 a height each of 27
		//  For Row 1 and 2 - Bold all text
		//  For Row 1 and 2 - Make the Background Colour #202A45 or RGB 32,42,69
		//  For Row 1 and 2 - Make the Text Colour #FFFFFF or RGB 255,255,255 (White)
		//  Center all other columns except E
		worksheet.column("A").hidden(true);
		worksheet.column("B").hidden(true);
		worksheet.column("C").hidden(true);
		worksheet.column("D").hidden(true);
		worksheet.column("E").width(35);

		// Everything after column E (column 5) should be centered and have wrapped
		// text
		for (let i = 1; i < worksheetState.nextAttributeColumnIndex; i += 1) {
			worksheet
				.column(i)
				.width(i === 5 ? 35 : 14)
				.style({
					horizontalAlignment: i <= 5 ? "left" : "center",
					wrapText: i > 5 ? true : false,
				});
		}

		worksheet
			.row(1)
			.height(27)
			.style({
				fill: {
					type: "solid",
					color: "202A45",
				},
				fontColor: "FFFFFF",
				bold: true,
			});
		worksheet
			.row(2)
			.height(27)
			.style({
				fill: {
					type: "solid",
					color: "202A45",
				},
				fontColor: "FFFFFF",
				bold: true,
			});
	});
};

export const convertBoxHierarchiesToExcel = (
	boxMap: boxLib.BoxMap,
	flattenedBoxMap: boxLib.BoxMap,
	boxKeys: string[],
	illustrationAttributes: IllustrationAttributes,
	boxTypeMap: boxTypeLib.BoxTypeMap,
	includeAssociations: boolean
): Promise<XlsxPopulate.Workbook> => {
	// Create a workbook
	return XlsxPopulate.fromBlankAsync().then(
		(workbook: XlsxPopulate.Workbook) => {
			if (boxTypeMap) {
				// Create a worksheet with all the box types and their attributes in a
				// hierarchy
				addBoxTypeAttributeHierarchyToWorksheet(
					workbook,
					boxMap,
					flattenedBoxMap,
					boxKeys,
					illustrationAttributes.boxAttributes,
					boxTypeMap,
					includeAssociations
				);

				// Remove the default worksheet
				workbook.deleteSheet("Sheet1");
			}

			return workbook;
		}
	);
};

export const convertBoxTypeAttributesToExcel = (
	exportBoxMap: boxLib.BoxMap,
	allBoxesMap: boxLib.BoxMap,
	illustrationAttributes: IllustrationAttributes,
	boxTypeMap: boxTypeLib.BoxTypeMap,
	includeAssociations: boolean
): Promise<XlsxPopulate.Workbook> => {
	// Create a workbook
	return XlsxPopulate.fromBlankAsync().then(
		(workbook: XlsxPopulate.Workbook) => {
			if (boxTypeMap) {
				// // Create a worksheet for each box type
				Object.keys(illustrationAttributes.boxTypeAttributes).forEach(
					(boxTypeKey: string) => {
						addBoxTypeAttributesToWorksheet(
							workbook,
							exportBoxMap,
							allBoxesMap,
							illustrationAttributes.boxAttributes,
							boxTypeMap,
							boxTypeKey,
							includeAssociations
						);
					}
				);

				// Remove the default worksheet
				workbook.deleteSheet("Sheet1");
			}

			return workbook;
		}
	);
};

const buildBoxNameToUUIDMap = (allBoxesMap: boxLib.BoxMap): Record<string, string> => {
	const boxNameToUUIDMap = Object
		.keys(allBoxesMap)
		.reduce((acc: Record<string, string>, boxKey: string) => {
			const box = allBoxesMap[boxKey];
			const boxName = box.name;
			
			if (boxName && boxName !== '') {
				// Don't overwrite the box name if we already have one.
				if (!Object.prototype.hasOwnProperty.call(acc, boxName)) {
					acc[boxName] = boxKey;
				}
			}
			
			return acc;
		}, {});

	return boxNameToUUIDMap;
}

export const convertExcelToBoxAttributeMap = (
	workbook: XLSX.WorkBook,
	allBoxesMap: boxLib.BoxMap,
	boxTypeMap: boxTypeLib.BoxTypeMap,
	worksheetNameToBoxTypeKeyMap: WorksheetNameToBoxTypeKeyMap,
	createBoxKeyIfNone?: boolean,
): {
	boxAttributeMap: BoxAttributesMap,
	boxTypeMap: boxTypeLib.BoxTypeMap
} => {
	// The box attributes we'll be building
	const boxAttributeMap: BoxAttributesMap = {};

	// Clear the box and attribute type cache
	boxTypeLib.clearBoxTypeAttributeTypeCache();
	attributeTypeLib.clearAttributeTypeCache();

	// Copy the box type map so we can add import new attributes 
	const updatedBoxTypeMap = JSON.parse(JSON.stringify(boxTypeMap)) as boxTypeLib.BoxTypeMap;

	// Build a map of box names to UUIDs
	const boxNameToUUIDMap = buildBoxNameToUUIDMap(allBoxesMap);

	// Build box attributes from the sheets
	workbook.SheetNames.forEach((workSheetName) => {
		if (
			Object.prototype.hasOwnProperty.call(
				worksheetNameToBoxTypeKeyMap,
				workSheetName
			)
		) {
			// Get the box type
			const boxTypeKey = worksheetNameToBoxTypeKeyMap[workSheetName];
			const boxType = updatedBoxTypeMap[boxTypeKey];
			if (boxType) {
				// Get the box attribute types
				const attributeTypes: attributeTypeLib.AttributeTypeMap = {};
				boxTypeLib.setAttributeTypesForBoxTypeRecursive(attributeTypes,
					boxTypeKey,
					updatedBoxTypeMap);

				// Get the worksheet
				const worksheet = workbook.Sheets[workSheetName];

				// Convert it to attribute rows, skipping the firt row of the header
				const attributeRows: attributeLib.AttributeMap[] = XLSX.utils.sheet_to_json(worksheet, {
					range: 1,
					defval: "",
				});

				// Build attributes for each of the attribute rows
				attributeRows.forEach((attributeRow: attributeLib.AttributeMap) => {
					// Ignore the row if it doesn't have any values in non-blank columns
					if (!doesAttributeRowHaveNonBlankValues(attributeRow,
						attributeTypes,
						boxTypeKey,
						boxNameToUUIDMap)) {
						return
					}

					let boxKey = attributeRow["Box UUID"]
					if ((!boxKey || boxKey === '') && createBoxKeyIfNone) {
						boxKey = uuid()
					}

					if (boxKey && boxKey !== '') {
						const boxNameRawValue = attributeRow["Box Name"];
						const boxName = (boxNameRawValue !== undefined && boxNameRawValue !== null)
							? String(boxNameRawValue)
							: undefined;
						const boxURLRawValue = attributeRow["Box URL"];
						const boxURL = (boxURLRawValue !== undefined && boxURLRawValue !== null)
							? String(boxURLRawValue)
							: undefined;

						delete attributeRow["Box UUID"];
						delete attributeRow["Box Name"];
						delete attributeRow["Box URL"];

						const attributes: attributeLib.AttributeMap = Object.keys(attributeRow).reduce(
							(reducedAttributes: attributeLib.AttributeMap, attributeName: string) => {
								let attributeTypeKey = attributeTypeLib
									.findAttributeTypeKeyForName(boxTypeKey, attributeTypes, attributeName);

								const attributeValue = attributeRow[attributeName];

								if (attributeTypeKey === '' &&
									!attributeTypeLib.isDefaultAttributeTypeKey(attributeName) &&
									attributeName !== undefined &&
									attributeName !== null &&
									attributeName !== '' &&
									attributeName.toLocaleLowerCase() !== 'blank' &&
									attributeName.toLocaleLowerCase().indexOf('blank_') < 0 &&
									attributeName.toLocaleLowerCase().indexOf('__blank') < 0 &&
									attributeName.toLocaleLowerCase().indexOf('__empty') < 0 &&
									attributeName.toLocaleLowerCase() !== '__rownum__') {
									const valueType: valueTypeLib.ValueTypeKey = typeof attributeValue === 'number'
										? valueTypeLib.ValueTypeKey.Number
										: valueTypeLib.ValueTypeKey.Text

									// Create a new attribute type named after the column
									attributeTypeKey = attributeTypeLib.getNewAttributeTypeKey(); 
									const newAttribute = attributeTypeLib.createDefaultAttributeType(valueType);
									newAttribute.name = attributeName;
									
									// Store the attribute type in the box type
									updatedBoxTypeMap[boxTypeKey].attributeTypes[attributeTypeKey] = newAttribute
									
									// Store it in the local map of recursive attribute types
									attributeTypes[attributeTypeKey] = newAttribute

									// console.log(`Adding to ${workSheetName} - ${attributeTypeKey} for ${attributeName}`)
									// console.log(newAttribute)
								}

								const attributeType = attributeTypes[attributeTypeKey];
								if (attributeType) {
									const actualAttributeValue = getAttributeValueForTypeImport(attributeValue,
										attributeType,
										boxNameToUUIDMap);

									reducedAttributes[attributeTypeKey] = actualAttributeValue;
								}

								return reducedAttributes;
							}, {});

						boxAttributeMap[boxKey] = {
							boxName,
							boxURL,
							boxTypeKey,
							attributes,
						};
					}
				});
			}
		}
	});

	// Update the box type cache.
	boxTypeLib.initializeBoxTypeAttributeTypeCache(updatedBoxTypeMap);

	return {
		boxAttributeMap,
		boxTypeMap: updatedBoxTypeMap
	};
};

export const convertExcelHierarchyToBoxAttributeMap = (
	workbook: XLSX.WorkBook,
	flattenedBoxMap: boxLib.BoxMap | undefined,
	boxTypeMap: boxTypeLib.BoxTypeMap
): {
	boxAttributeMap: BoxAttributesMap,
	boxTypeMap: boxTypeLib.BoxTypeMap
} => {
	// The box attributes we'll be building
	const boxAttributeMap: BoxAttributesMap = {};

	if (flattenedBoxMap === undefined) {
		return {
			boxAttributeMap,
			boxTypeMap
		};
	}

	// Disabled the box and attribute type cache
	boxTypeLib.clearBoxTypeAttributeTypeCache();
	attributeTypeLib.clearAttributeTypeCache();

	// Copy the box type map so we can add import new attributes 
	const updatedBoxTypeMap = JSON.parse(JSON.stringify(boxTypeMap)) as boxTypeLib.BoxTypeMap;

	// Build a map of box names to UUIDs
	const boxNameToUUIDMap = buildBoxNameToUUIDMap(flattenedBoxMap);

	const defaultAttributeCount = getDefaultAttributeKeys().length;

	// Build box attributes from the sheets
	workbook.SheetNames.forEach((workSheetName) => {
		// Get the worksheet
		const worksheet = workbook.Sheets[workSheetName];

		// Convert it to attribute rows
		const attributeRows = XLSX.utils.sheet_to_json(worksheet, {
			header: 1,
			defval: "",
		}) as attributeLib.AttributeValue[][];
		if (attributeRows.length > 2) {
			// Build box type offsets from first row
			const attributeColumnOffsets: AttributeColumnOffsetMap = {};

			const boxTypeColumns = attributeRows[0];
			boxTypeColumns.forEach(
				(columnValue: attributeLib.AttributeValue, columnIndex: number) => {
					if (columnValue) {
						const columnName = String(columnValue);
						if (columnName !== "") {
							const boxTypeKey = boxTypeLib.findBoxTypeKeyForName(
								updatedBoxTypeMap,
								columnName
							);
							if (boxTypeKey) {
								attributeColumnOffsets[boxTypeKey] = columnIndex;
							}
						}
					}
				}
			);

			// The attribute names are stored in the second row
			const headerRow = attributeRows[1];

			// Find the indexes of the box UUID, name, and URL
			const boxKeyIndex = headerRow.findIndex((headerValue: attributeLib.AttributeValue) => {
				return headerValue.toString().toLocaleLowerCase() === "box uuid";
			})
			const boxNameIndex = headerRow.findIndex((headerValue: attributeLib.AttributeValue) => {
				return headerValue.toString().toLocaleLowerCase() === "box name";
			})
			const boxUrlIndex = headerRow.findIndex((headerValue: attributeLib.AttributeValue) => {
				return headerValue.toString().toLocaleLowerCase() === "box url";
			})

			// Build attributes for each of the attribute rows
			attributeRows.forEach(
				(attributeRow: attributeLib.AttributeValue[], attributeRowIndex: number) => {
					// Skip the first two rows
					if (attributeRowIndex <= 1) {
						return;
					}

					// The attribute type keys we've already found
					const foundAttributeTypeKeys: string[] = [];

					const boxKeyRawValue = boxKeyIndex >= 0 ? attributeRow[boxKeyIndex] : undefined;
					const boxKey = (boxKeyRawValue !== undefined && boxKeyRawValue !== null)
						? String(boxKeyRawValue)
						: "";

					const boxNameRawValue = boxNameIndex >= 0 ? attributeRow[boxNameIndex] : undefined;
					const boxName = (boxNameRawValue !== undefined && boxNameRawValue !== null)
						? String(boxNameRawValue)
						: undefined;

					const boxUrlRawValue = boxUrlIndex >= 0 ? attributeRow[boxUrlIndex] : undefined;
					const boxURL = (boxUrlRawValue !== undefined && boxUrlRawValue !== null)
						? String(boxUrlRawValue)
						: undefined;

					let attributes: attributeLib.AttributeMap = {};

					const box = flattenedBoxMap[boxKey];
					if (box) {
						const boxTypeKey = box.boxType;

						const boxType = updatedBoxTypeMap[boxTypeKey];
						if (boxType) {
							const actualBoxTypeKey = boxTypeLib
								.getBoxTypeKeyinObj(attributeColumnOffsets,
									boxTypeKey,
									boxType)
							if (actualBoxTypeKey !== '') {
								const attributeColumnOffset = attributeColumnOffsets[actualBoxTypeKey];

								// Get the box attribute types
								const attributeTypes: attributeTypeLib.AttributeTypeMap = {};
								boxTypeLib.setAttributeTypesForBoxTypeRecursive(attributeTypes,
									actualBoxTypeKey,
									updatedBoxTypeMap);

								// Ignore the row if it doesn't have any values in non-blank columns
								if (!doesArrayAttributeRowHaveNonBlankValues(attributeRow,
									headerRow,
									attributeTypes,
									boxTypeKey,
									boxNameToUUIDMap)) {
									return
								}

								attributes = attributeRow.reduce((reducedAttributes: attributeLib.AttributeMap,
									attributeValue: attributeLib.AttributeValue,
									attributeColumnIndex: number) => {
									// Only consider the attribute if it's a default or one of the box type
									if (attributeColumnIndex < defaultAttributeCount ||
										attributeColumnIndex >= attributeColumnOffset) {
										const attributeName = String(headerRow[attributeColumnIndex]);

										let attributeTypeKey = attributeTypeLib
											.findAttributeTypeKeyForName(boxTypeKey,
												attributeTypes,
												attributeName);

										if (attributeTypeKey === '' &&
											!attributeTypeLib.isDefaultAttributeTypeKey(attributeName) &&
											attributeName !== undefined &&
											attributeName !== null &&
											attributeName !== '' &&	
											attributeName.toLocaleLowerCase() !== 'box uuid' &&
											attributeName.toLocaleLowerCase() !== 'box name' &&
											attributeName.toLocaleLowerCase() !== 'box url' &&
											attributeName.toLocaleLowerCase() !== 'blank' &&
											attributeName.toLocaleLowerCase().indexOf('blank_') < 0 &&
											attributeName.toLocaleLowerCase().indexOf('__blank') < 0 &&
											attributeName.toLocaleLowerCase().indexOf('__empty') < 0 &&
											attributeName.toLocaleLowerCase() !== '__rownum__') {
											const valueType: valueTypeLib.ValueTypeKey = typeof attributeValue === 'number'
												? valueTypeLib.ValueTypeKey.Number
												: valueTypeLib.ValueTypeKey.Text
		
											// Create a new attribute type named after the column
											attributeTypeKey = attributeTypeLib.getNewAttributeTypeKey(); 
											const newAttribute = attributeTypeLib.createDefaultAttributeType(valueType);
											newAttribute.name = attributeName;

											// Store the attribute type in the box type
											updatedBoxTypeMap[boxTypeKey].attributeTypes[attributeTypeKey] = newAttribute

											// Store it in the local map of recursive attribute types
											attributeTypes[attributeTypeKey] = newAttribute
		
											// console.log(`Adding to ${workSheetName} - ${attributeTypeKey} for ${attributeName}`)
											// console.log(newAttribute)
										}
								
										if (attributeTypeKey &&
											foundAttributeTypeKeys.indexOf(attributeTypeKey) < 0) {
											const attributeType = attributeTypes[attributeTypeKey];
											if (attributeType) {
												const actualAttributeValue = getAttributeValueForTypeImport(attributeValue,
													attributeType,
													boxNameToUUIDMap);
					
												reducedAttributes[attributeTypeKey] = actualAttributeValue;
												foundAttributeTypeKeys.push(attributeTypeKey);

												// console.log(
												// 	`importing row ${attributeRowIndex}, ${boxName}.${attributeName}(${boxKey}.${attributeTypeKey}) = ${attributeValue} from column ${attributeColumnIndex}`
												// );
											}
										}
									}
									return reducedAttributes;
								}, {});
							}
						}

						boxAttributeMap[boxKey] = {
							boxName,
							boxURL,
							boxTypeKey,
							attributes,
						};
					}
				}
			);
		}
	});

	// Update the box type cache.
	boxTypeLib.initializeBoxTypeAttributeTypeCache(updatedBoxTypeMap);

	return {
		boxAttributeMap,
		boxTypeMap: updatedBoxTypeMap
	};
};

export const updateBoxForBoxAttributeMap = (boxKey: string,
	box: boxLib.Box,
	boxAttributeMap: BoxAttributesMap): void => {
	const updatedBoxAttributes = boxAttributeMap[boxKey];

	if (box.name !== updatedBoxAttributes.boxName && updatedBoxAttributes.boxName !== undefined) {
		// console.log(
		// 	`Updating box name from ${box.name} to ${updatedBoxAttributes.boxName}`
		// );
		box.name = updatedBoxAttributes.boxName;
	}

	if (box.url !== updatedBoxAttributes.boxURL && updatedBoxAttributes.boxURL !== undefined) {
		// console.log(
		// 	`Updating box URL from ${box.url} to ${updatedBoxAttributes.boxURL}`
		// );
		box.url = updatedBoxAttributes.boxURL;
	}

	const boxAttributes = box.attributes;
	if (boxAttributes) {
		Object.keys(updatedBoxAttributes.attributes).forEach(
			(boxAttributeKey: string) => {
				// If the box has the attribute, update it, otherwise add it
				if (
					Object.prototype.hasOwnProperty.call(
						boxAttributes,
						boxAttributeKey
					)
				) {
					if (
						boxAttributes[boxAttributeKey] !==
						updatedBoxAttributes.attributes[boxAttributeKey]
					) {
						// console.log(
						// 	`Updating box ${boxKey} (${box.name}) attribute ${boxAttributeKey} from ${boxAttributes[boxAttributeKey]} to ${updatedBoxAttributes.attributes[boxAttributeKey]}`
						// );
						boxAttributes[boxAttributeKey] =
							updatedBoxAttributes.attributes[
							boxAttributeKey
							];
					}
				} else {
					if (
						updatedBoxAttributes.attributes[boxAttributeKey]
					) {
						// console.log(
						// 	`Adding box ${boxKey} (${box.name}) attribute ${boxAttributeKey} with value ${updatedBoxAttributes.attributes[boxAttributeKey]}`
						// );
						boxAttributes[boxAttributeKey] =
							updatedBoxAttributes.attributes[
							boxAttributeKey
							];
					}
				}
			}
		);
	}
}

export const applyBoxAttributeMapToBoxChildren = (box: boxLib.Box,
	boxTypeKey: string,
	boxTypeMap: boxTypeLib.BoxTypeMap,
	boxAttributeMap: BoxAttributesMap): boxLib.Box => {
		const updatedBox = JSON.parse(JSON.stringify(box)) as boxLib.Box;

		const boxAttributeKeys = Object.keys(boxAttributeMap)
		if (boxAttributeKeys.length > 0) {
			// If there's no children, set up the child box map.
			if (!updatedBox.children) {
				updatedBox.children = {}
			}

			const updatedBoxChildren = updatedBox.children;

			// New boxes are last in the box order
			let highestOrder = boxLib.getHighestBoxOrder(updatedBoxChildren);

			boxAttributeKeys.forEach((boxKey: string) => {
				// Only apply boxes of the specific type
				const boxAttributes = boxAttributeMap[boxKey];
				if (boxAttributes.boxTypeKey !== boxTypeKey) {
					return;
				}

				if (!Object.prototype.hasOwnProperty.call(updatedBoxChildren, boxKey)) {
					highestOrder += 1;

					const newChildBox = boxLib.createDefaultBox(boxTypeKey,
							boxTypeMap,
							highestOrder)

					updatedBoxChildren[boxKey] = newChildBox;
				}

				const box = boxLib.findBoxInBoxMapForKey(
					updatedBoxChildren,
					boxKey
				);
				if (box) {
					updateBoxForBoxAttributeMap(boxKey, box, boxAttributeMap);
				}
			})
		}

		return updatedBox;
	}

export const applyBoxAttributeMapToIllustration = (
	illustration: illustrationLib.Illustration,
	boxAttributeMap: BoxAttributesMap,
	boxTypeMap: boxTypeLib.BoxTypeMap
): illustrationLib.Illustration => {
	const updatedIllustration = JSON.parse(JSON.stringify(illustration)) as illustrationLib.Illustration;

	updatedIllustration.boxTypes = boxTypeMap;

	Object.keys(boxAttributeMap).forEach((boxKey: string) => {
		const box = boxLib.findBoxInBoxMapForKey(
			updatedIllustration.boxes,
			boxKey
		);
		if (box) {
			updateBoxForBoxAttributeMap(boxKey, box, boxAttributeMap);
		}
	});

	return updatedIllustration;
};

export const downloadExcel = (
	workbookPromise: Promise<XlsxPopulate.Workbook>,
	filename: string
): void => {
	workbookPromise.then((workbook: XlsxPopulate.Workbook) => {
		// Write the workbook as a blob
		workbook
			.outputAsync("blob")
			.then(
				(
					workbookBlob:
						| string
						| Uint8Array
						| ArrayBuffer
						| Blob
						| Buffer
				) => {
					// Save the download
					saveAs(workbookBlob as Blob, filename);
				}
			);
	});
};
