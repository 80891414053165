import { Client } from '@contracts/client';
import { Illustration } from '@contracts/illustration';
import { Project } from '@contracts/project';
import { Button } from 'antd';
import React from 'react';
export interface IllustrationBreadcrumbsProps {
	client: Client | null;
	project: Project | null;
	illustration: Illustration | null;
	onSelectClient: (client: Client | null) => void;
	onSelectProject: (project: Project | null) => void;
	onSelectIllustration: (illustration: Illustration | null) => void;
};

const IllustrationBreadcrumbs: React.FC<IllustrationBreadcrumbsProps> = (props) => {
	const showProjectListButton = props.client !== null;
	const showIllustrationListButton = props.client !== null && props.project !== null;

	return <>
		<Button type="link" onClick={() => { props.onSelectClient(null) }}>Clients</Button>
		{props.client ?
			<span>&gt;&nbsp;{props.client.name}&nbsp;</span> : null}

		{showProjectListButton ?
			<span>&gt;
				<Button type="link"
					onClick={() => { props.onSelectProject(null) }}>
					Projects
				</Button>
			</span>
			: null}

		{props.project ?
			<span> &gt;&nbsp;{props.project.name}&nbsp;</span> : null}
		{showIllustrationListButton ?
			<span>&gt;
				<Button type="link"
					onClick={() => { props.onSelectIllustration(null) }}>
					Illustrations
				</Button>
			</span>
			: null}

		{props.illustration ? <span> &gt;&nbsp;{props.illustration.name}</span> : null}
	</>
}

export default IllustrationBreadcrumbs;