var environment = process.env.REACT_APP_ENVIRONMENT || "local";

// console.log("Environment is " + environment);

interface IDictionary<TValue> {
	[id: string]: TValue;
}

interface ConfigItem {
	apiURL: string;
	headerImage: string;
	loginImage: string;
	favIcon: string;
	webServiceTimeout: number;
}

const config: IDictionary<ConfigItem> = {
	local: {
		apiURL: "http://localhost:5001/api/v1/",
		headerImage: "/whitelabel/images/el-header-logo.png",
		loginImage: "/whitelabel/images/el-login-logo.png",
		favIcon: "dev-favicon.ico",
		webServiceTimeout: 200000,
	},
	localProd: {
		apiURL: "http://localhost:5001/api/v1/",
		headerImage: "/whitelabel/images/el-header-logo.png",
		loginImage: "/whitelabel/images/el-login-logo.png",
		favIcon: "dev-favicon.ico",
		webServiceTimeout: 200000,
	},
	development: {
		apiURL: "https://dev-api.myenterpriselens.com/api/v1/",
		headerImage: "/whitelabel/images/el-header-logo.png",
		loginImage: "/whitelabel/images/el-login-logo.png",
		favIcon: "dev-favicon.ico",
		webServiceTimeout: 200000,
	},
	test: {
		apiURL: "https://test-api.myenterpriselens.com/api/v1/",
		headerImage: "/whitelabel/images/el-header-logo.png",
		loginImage: "/whitelabel/images/el-login-logo.png",
		favIcon: "dev-favicon.ico",
		webServiceTimeout: 200000,
	},
	production: {
		apiURL: "https://api.myenterpriselens.com/api/v1/",
		headerImage: "/whitelabel/images/el-header-logo.png",
		loginImage: "/whitelabel/images/el-login-logo.png",
		favIcon: "favicon.ico",
		webServiceTimeout: 200000,
	},
	dxcProduction: {
		apiURL: "https://dxc-api.myenterpriselens.com/api/v1/",
		headerImage: "/whitelabel/images/dxc-header-logo.png",
		loginImage: "/whitelabel/images/dxc-login-logo.png",
		favIcon: "favicon.ico",
		webServiceTimeout: 200000,
	},
	rioProduction: {
		apiURL: "https://rio-api.myenterpriselens.com/api/v1/",
		headerImage: "/whitelabel/images/riotinto-header-logo.png",
		loginImage: "/whitelabel/images/riotinto-login-logo.png",
		favIcon: "favicon.ico",
		webServiceTimeout: 200000,
	},
	cscProduction: {
		apiURL: "https://csc-api.myenterpriselens.com/api/v1/",
		headerImage: "/whitelabel/images/csc-header-logo.svg",
		loginImage: "/whitelabel/images/csc-login-logo.svg",
		favIcon: "favicon.ico",
		webServiceTimeout: 200000,
	},	
	iqgroupProduction: {
		apiURL: "https://iqgroup-api.myenterpriselens.com/api/v1/",
		headerImage: "/whitelabel/images/iqgroup-header-logo.svg",
		loginImage: "/whitelabel/images/iqgroup-login-logo.svg",
		favIcon: "favicon.ico",
		webServiceTimeout: 200000,
	},
	catharaProduction: {
		apiURL: "https://cathara-api.myenterpriselens.com/api/v1/",
		headerImage: "/whitelabel/images/cathara-header-logo.png",
		loginImage: "/whitelabel/images/cathara-login-logo.png",
		favIcon: "favicon.ico",
		webServiceTimeout: 200000,
	},	
	nbnProduction: {
		apiURL: "https://nbn-api.myenterpriselens.com/api/v1/",
		headerImage: "/whitelabel/images/nbn-header-logo.svg",
		loginImage: "/whitelabel/images/nbn-login-logo.svg",
		favIcon: "favicon.ico",
		webServiceTimeout: 200000,
	},	
	stjohnwaProduction: {
		apiURL: "https://stjohnwa-api.myenterpriselens.com/api/v1/",
		headerImage: "/whitelabel/images/stjohnwa-header-logo.png",
		loginImage: "/whitelabel/images/stjohnwa-login-logo.png",
		favIcon: "favicon.ico",
		webServiceTimeout: 200000,
	}

};

const defaultConfig = config[environment];

export default defaultConfig;
