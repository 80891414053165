import * as React from "react";
import { Key } from "react";

import { Tree } from "antd";
import {
	DataNode,
	EventDataNode,
} from "antd/lib/tree";

import * as boxTypeLib from "@lib/box/box-type";
import { RenderFunctionLatchModeVisibilityMap } from "@lib/box/render-function";

const TreeNode = Tree.TreeNode;

const SHOW_BOXES_SUFFIX = "show-boxes";
const KEEP_BOXES_SUFFIX = "keep-boxes";

interface CheckInfo {
	event: "check";
	node: EventDataNode<any>;
	checked: boolean;
	nativeEvent: MouseEvent;
	checkedNodes: DataNode[];
	checkedNodesPositions?: {
		node: DataNode;
		pos: string;
	}[];
	halfCheckedKeys?: Key[];
}

interface LatchModeViewProps {
	onLatchChange: (
		boxTypeKey: string,
		attributeTypeKey: string,
		renderFunctionKey: string,
		latchEnabled: boolean
	) => void;
	boxTypeMap: boxTypeLib.BoxTypeMap | undefined;
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined;
	textColor: string;
	showAttributes: boolean;
}

export default class LatchModeView extends React.Component<
	LatchModeViewProps
> {
	state = {
		selectedKeys: [],
	};
	// May need to deal with | { checked: string[]; halfChecked: string[]; } for
	private onCheck = (
		checkedKeys:
			| Key[]
			| {
				checked: (string | number)[];
				halfChecked: (string | number)[];
			},
		info: CheckInfo
	) => {

		const key = String(info.node.key) || "";
		const checked = info.checked || false;

		var splitKey = key.split("_");

		// If we have a length of 3 then it's a proper key
		if (splitKey.length === 3) {
			const boxTypeKey = splitKey[0];
			const attributeTypeKey = splitKey[1];
			const renderFunctionKey = splitKey[2]
			this.props.onLatchChange(boxTypeKey, attributeTypeKey, renderFunctionKey, checked);
		}
	};


	buildRenderFunctionLatchModeVisibilityMap = (
		boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined
	) : RenderFunctionLatchModeVisibilityMap => {
		let renderFunctionLatchModeVisibilityMap: RenderFunctionLatchModeVisibilityMap = {}

		if (!boxTypeVisibilityMap) {
			return renderFunctionLatchModeVisibilityMap;
		}

		Object.keys(boxTypeVisibilityMap).forEach(boxTypeKey => {
			const boxTypeVisibility = boxTypeVisibilityMap[boxTypeKey];
			renderFunctionLatchModeVisibilityMap = boxTypeVisibility.renderFunctionLatchModeVisibilityMap ?
				{ ...renderFunctionLatchModeVisibilityMap, ...boxTypeVisibility.renderFunctionLatchModeVisibilityMap } :
				renderFunctionLatchModeVisibilityMap;
		})

		return renderFunctionLatchModeVisibilityMap;
	};

	buildCheckedKeys = (
		boxTypeMap: boxTypeLib.BoxTypeMap | undefined,
		renderFunctionLatchModeVisibilityMap: RenderFunctionLatchModeVisibilityMap | undefined,
	) => {
		// If we don't have a box type map there's nothing to do
		if (!renderFunctionLatchModeVisibilityMap || !boxTypeMap) {
			return [];
		}

		const enabledVisibilityMapKeys: string[] = Object.keys(renderFunctionLatchModeVisibilityMap).filter(latchKey => {
			return renderFunctionLatchModeVisibilityMap[latchKey] === true
		}).map(latchKey => { return latchKey; });

		const disabledVisibilityMapKeys: string[] = Object.keys(renderFunctionLatchModeVisibilityMap).filter(latchKey => {
			return renderFunctionLatchModeVisibilityMap[latchKey] === false
		}).map(latchKey => { return latchKey; });


		const combinedKeysForComparison = [...enabledVisibilityMapKeys, ...disabledVisibilityMapKeys];

		const defaultKeys: string[] = [];

		// If we have a default enabledLatchMode that's NOT disabled and NOT already in the enabledVisibilityMapKeys then push
		// it to the checked keys
		boxTypeMap && Object.keys(boxTypeMap).forEach(
			(boxTypeKey) => {
				if (this.props.boxTypeMap) {
					const boxType = boxTypeMap[boxTypeKey];
					const attributeTypes = boxType.attributeTypes;

					Object.keys(attributeTypes).forEach((attributeTypeKey) => {
						const attributeType = attributeTypes[attributeTypeKey];
						const renderFunctions = attributeType.renderFunctions;

						return Object.keys(renderFunctions).filter(renderFunctionKey => {
							return renderFunctions[renderFunctionKey].enableLatchMode
						}).forEach((renderFunctionKey) => {
							const fullKey = `${boxTypeKey}_${attributeTypeKey}_${renderFunctionKey}`;

							// If the key hasn't been overridden
							if (combinedKeysForComparison.includes(fullKey) === false) {
								defaultKeys.push(fullKey);
							}
						})
					});
				}
			}
		)

		// The checked keys
		let checkedKeys: string[] = [...defaultKeys, ...enabledVisibilityMapKeys];

		return checkedKeys;
	};

	render() {

		const renderFunctionLatchModeVisibilityMap = this.buildRenderFunctionLatchModeVisibilityMap(this.props.boxTypeVisibilityMap);

		// Build the checked keys
		const checkedKeys: string[] = this.buildCheckedKeys(
			this.props.boxTypeMap,
			renderFunctionLatchModeVisibilityMap
		);

		return (
			<Tree
				checkable
				checkStrictly
				// onExpand={this.onExpand}
				// expandedKeys={this.state.expandedKeys}
				// autoExpandParent={this.state.autoExpandParent}
				onCheck={this.onCheck}
				checkedKeys={checkedKeys}
				// onSelect={this.onSelect}
				selectedKeys={this.state.selectedKeys}
			>

				{this.props.boxTypeMap && Object.keys(this.props.boxTypeMap).map((boxTypeKey) => {
					if (this.props.boxTypeMap) {
						const boxType = this.props.boxTypeMap[boxTypeKey];
						const attributeTypes = boxType.attributeTypes;

						return Object.keys(attributeTypes).map((attributeTypeKey) => {
							const attributeType = attributeTypes[attributeTypeKey];
							const renderFunctions = attributeType.renderFunctions;

							return Object.keys(renderFunctions).filter(renderFunctionKey => {
								return renderFunctions[renderFunctionKey].enableLatchMode
							}).map((renderFunctionKey) => {

								const treeNodeKey = boxTypeKey + "_" + attributeTypeKey + "_" + renderFunctionKey;
								const renderFunction = renderFunctions[renderFunctionKey];

								const renderFunctionName = boxType.name + " - " + attributeType.name + " - " + (renderFunction.name ? renderFunction.name : "Default");
								return <TreeNode
									key={treeNodeKey}
									title={
										<span
											style={{
												color: this.props.textColor,
											}}
										>
											{renderFunctionName}
										</span>
									}
									style={{
										color: this.props.textColor,
									}}
								/>
							})
						});
					} else {
						return null;
					}
				})}
			</Tree>
		);
	}
}
