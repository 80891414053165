import React from "react";

import { Form } from "antd";
import { Modal, Input, InputNumber, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { EditorState } from "braft-editor";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";

import { SmartPage } from '@lib/smart-pages/smart-page';

interface ViewBoxDialogProps {
	onOKButtonClick: (
		smartPageKey: string,
		smartPage: SmartPage
	) => void;
	onCancelButtonClick: () => void;
	isVisible: boolean;
	smartPageKey: string;
	smartPage: SmartPage;
}

interface ViewBoxDialogState extends SmartPage {
	editorState: EditorState;
}

export default class ViewBoxDialog extends React.Component<
	ViewBoxDialogProps,
	ViewBoxDialogState
> {
	constructor(props: ViewBoxDialogProps) {
		super(props);

		const updatedState = this.updateState(this.props.smartPage);

		// If we already have Lens Page content, supply it to the editor, otherwise
		// just default to an empty paragraph.
		const editorContent = this.props.smartPage.content
			? this.props.smartPage.content
			: "<p></p>";

		updatedState.editorState = BraftEditor.createEditorState(editorContent);

		this.state = updatedState;
	}

	private onChange = (editorState: EditorState) =>
		this.setState({
			editorState,
		});

	private updateState = (smartPage: SmartPage | undefined): any => {
		let newState = undefined;

		if (smartPage !== undefined) {
			newState = {
				name: smartPage.name,
				content: smartPage.content,
				order: smartPage.order,
				isBookmarked: smartPage.isBookmarked,
				bookmarkOrder: smartPage.bookmarkOrder,
			};
		}
		return newState;
	};

	public componentWillReceiveProps(nextProps: ViewBoxDialogProps) {

		const updatedState = {
			name: nextProps.smartPage.name,
			content: nextProps.smartPage.content,
			order: nextProps.smartPage.order,
			isBookmarked: nextProps.smartPage.isBookmarked,
			bookmarkOrder: nextProps.smartPage.bookmarkOrder,
			editorState: BraftEditor.createEditorState(
				nextProps.smartPage.content
			),
		};
		this.setState(updatedState);
	}

	private onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e != null) {
			this.setState({ name: e.target.value });
		}
	};

	private onOrderChange = (value: number | null) => {
		if (value !== null && typeof value === "number") {
			this.setState({ order: value });
		}
	};

	private onBookmarkOrderChange = (value: number | null) => {
		if (value !== null && typeof value === "number") {
			this.setState({ bookmarkOrder: value });
		}
	};

	private onIsBookmarkedChange = (e: CheckboxChangeEvent) => {
		if (e != null && e.target.name !== undefined) {
			this.setState({ isBookmarked: e.target.checked });
		}
	};

	private updateContent = (value: string) => {
		this.setState({ content: value });
	};

	//private jodit: JoditEditor | undefined = undefined;
	//setRef = (jodit: JoditEditor) => this.jodit = jodit;

	config = {
		readonly: false,
		commandToHotkeys: {
			removeFormat: ["ctrl+shift+m", "cmd+shift+m"],
			insertOrderedList: ["ctrl+shift+7", "cmd+shift+7"],
			insertUnorderedList: ["ctrl+shift+8, cmd+shift+8"],
			selectall: ["ctrl+a", "cmd+a"],
		}, // all options from https://xdsoft.net/jodit/doc/
	};

	public render() {
		/* typescript-disable TS2322 */
		return (
			<Modal
				title={"Edit Lens Page"}
				open={this.props.isVisible}
				onOk={this.handleOKButtonClick}
				onCancel={this.props.onCancelButtonClick}
				width="75%"
				zIndex={9999}
			>
				<div>
					<Form layout="inline">
						<Form.Item label="Name">
							<Input
								name="name"
								value={this.state.name}
								onChange={this.onNameChange}
							/>
						</Form.Item>
						<Form.Item label="Order">
							<InputNumber
								name="order"
								value={this.state.order}
								onChange={this.onOrderChange}
								// onBlur={() => this.setState({ order: this.state.order })}
							/>
						</Form.Item>
						<Form.Item label="Is Bookmarked">
							<Checkbox
								checked={this.state.isBookmarked}
								name="isBookmarked"
								onChange={this.onIsBookmarkedChange}
							/>
						</Form.Item>
						<Form.Item label="Bookmark Order">
							<InputNumber
								name="bookmarkOrder"
								onChange={this.onBookmarkOrderChange}
								// onBlur={() => this.setState({ bookmarkOrder: this.state.bookmarkOrder })}
								value={this.state.bookmarkOrder}
							/>
						</Form.Item>
						<BraftEditor
							language="en"
							value={this.state.editorState}
							onChange={this.onChange}
						/>
					</Form>
				</div>
			</Modal>
		);
	}

	private handleInputChange = (event: any) => {
		// var illustration = JSON.parse(JSON.stringify(this.state.illustration));
		// illustration[event.target.name] = event.target.value;
		// this.setState({ illustration: illustration });
	};

	private handleOKButtonClick = () => {
		const smartPage: SmartPage = {
			name: this.state.name,
			bookmarkOrder: this.state.bookmarkOrder,
			content: this.state.editorState.toHTML(),
			isBookmarked: this.state.isBookmarked,
			order: this.state.order,
		};

		// Notify the callback that the OK button has been clicked
		this.props.onOKButtonClick(this.props.smartPageKey, smartPage);
	};
}
