import axios from 'axios';
import config from '../config';
import * as HttpStatus from 'http-status-codes';

import * as authorizationService from './authorization'

const loggedInSessionKey = 'IsLoggedIn';
const hasAcceptedEULASessionKey = 'HasAcceptedEULA';

const setLoggedIn = (val: boolean): void => {
	sessionStorage.setItem(loggedInSessionKey, String(val));
};

const isLoggedIn = (): boolean => {
	var loggedIn = false;

	// console.log("sessionKey from storage: " + sessionStorage.getItem(loggedInSessionKey));

	loggedIn = sessionStorage.getItem(loggedInSessionKey) ? sessionStorage.getItem(loggedInSessionKey) === 'true' : false;

	return loggedIn;
}

const hasAcceptedEULA = (): boolean => {
	const hasAcceptedEULAString = localStorage.getItem(hasAcceptedEULASessionKey)

	return (hasAcceptedEULAString === 'true')
}

const acceptEULA = (): void => {
	localStorage.setItem(hasAcceptedEULASessionKey, String(true));
}

const login = (username: string,
	password: string,
	next: (success: boolean,
		abilities: authorizationService.AbilitiesMap | undefined) => void) => {
	const loginUrl = config.apiURL + 'auth/login';
	axios.post(loginUrl, {
		email: username,
		password: password
	}).then((response) => {
		if (response.status === HttpStatus.OK) {
			authorizationService.getAbilities((abilitiesMap: authorizationService.AbilitiesMap) => {
				setLoggedIn(true);
				// console.log("is logged in (API call)" + isLoggedIn());
				// console.log(abilitiesMap);
				next(true, abilitiesMap);
			},
				() => {
					sessionStorage.removeItem(loggedInSessionKey);
					//   setLoggedIn(false);
					next(false, undefined);
					//   console.log('nay! abilities!');
				})
		} else {
			sessionStorage.removeItem(loggedInSessionKey);
			//   setLoggedIn(false);
			next(false, undefined);
			//   console.log('nay!');
		}
	}).catch((error: any) => {
		sessionStorage.removeItem(loggedInSessionKey);
		//   setLoggedIn(false);
		next(false, undefined);
	});
};


const resetPassword = (userId: string, password: string, success: (result: any) => void) => {
	const url = config.apiURL + "password/" + userId;

	axios.put(url, { password })
		.then((response) => {
			success(response);
		})
		.catch((error: any) => {
			console.log(error);
		})

}

const logout = () => {
	sessionStorage.removeItem(loggedInSessionKey);
}



const auth = {
	login,
	logout,
	isLoggedIn,
	hasAcceptedEULA,
	acceptEULA,
	resetPassword
};

export default auth;