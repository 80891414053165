import * as React from "react";

import { Select } from "antd";

import * as visibilityStateLib from "@lib/box/visibility-state";

interface VisibilityStateSelectorProps {
	onVisibilityStateChange: (visibilityStateKey: string) => void;
	visibilityStateMap: visibilityStateLib.VisibilityStateMap;
	currentVisibilityStateKey: string;
}

export class VisibilityStateSelector extends React.Component<
	VisibilityStateSelectorProps
> {
	public render() {
		// Render the visibility state options
		const renderedVisibilityStateOptions = Object.keys(
			this.props.visibilityStateMap
		)
			.sort(
				(visibilityStateKeyA: string, visibilityStateKeyB: string) => {
					// Get the names of the visibility states
					const visibilityStateNameA = this.props.visibilityStateMap[
						visibilityStateKeyA
					].name;
					const visibilityStateNameB = this.props.visibilityStateMap[
						visibilityStateKeyB
					].name;

					// If the order of visibility state A is before that of visibility state
					// B, put visibility state A first
					if (visibilityStateNameA < visibilityStateNameB) {
						return -1;
					}

					// If the order of visibility state A is after that of visibility state
					// B, put visibility state B first
					if (visibilityStateNameA > visibilityStateNameB) {
						return 1;
					}

					// Otherwise, don't change the order
					return 0;
				}
			)
			.map((visibilityStateKey: string) => {
				// Get the visibility state
				const visibilityState = this.props.visibilityStateMap[
					visibilityStateKey
				];

				return (
					<Select.Option
						key={visibilityStateKey}
						value={visibilityStateKey}
					>
						{visibilityState.name}
					</Select.Option>
				);
			});

		return (
			<div>
				<Select
					size="large"
					placeholder="Please select"
					value={this.props.currentVisibilityStateKey}
					onChange={(visibilityStateKey) =>
						this.props.onVisibilityStateChange(visibilityStateKey)
					}
					style={{ width: "100%" }}
					getPopupContainer={(node) => {
						let popupContainer: HTMLElement | null =
							window.document.documentElement;
						if (node && node.parentElement) {
							popupContainer = node.parentElement;
						}
						return popupContainer as HTMLElement;
					}}
				>
					{renderedVisibilityStateOptions}
				</Select>
			</div>
		);
	}
}
