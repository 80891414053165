import React, { useMemo } from 'react'

import { Properties } from "@lib/box/box-properties";

import { TextSizing, getTextStyle, wordwrap } from "@lib/style/text";

import { Fitty } from "../text/Fitty";

const KEY_CODE_ENTER: number = 13;
const KEY_CODE_ESCAPE: number = 27;

export interface BoxHeaderProps {
	onBoxHeaderTextChange: (boxText: string) => void;
	onBoxHeaderTextAccept: () => void;
	onBoxHeaderTextCancel: () => void;

	boxKey: string,
	boxProperties: Properties,
	boxText: string,

	textWidthInPixels: number,
	headerHeightInPixels: number,
	headerFontSizeInPixels: number,

	currentlyEditingInPlaceBoxKey: string,
	currentlyEditingInPlaceBoxHeaderText: string,

	isHeaderVisible: boolean,
	showHeader: boolean,
	hasChildren?: boolean,
}

interface BoxHeaderDimensions {
	actualTextWidthInPixels: number;
	actualTextHeightInPixels: number;
	wrappedBoxTextMaxSize: number;
	wrappedBoxTextLineCount: number;
}

const getWordWrappedBoxText = (boxText: string, hasChildren?: boolean): string => {
	return !hasChildren ? wordwrap(boxText) : boxText;
}

const calculateBoxHeaderDimensions = (boxProperties: Properties,
	wordWrappedBoxText: string,
	textWidthInPixels: number,
	headerHeightInPixels: number,
	hasChildren?: boolean): BoxHeaderDimensions => {

	let actualTextWidthInPixels = textWidthInPixels;
	actualTextWidthInPixels = textWidthInPixels - boxProperties.borderRadius <= 0
		? 0
		: textWidthInPixels - boxProperties.borderRadius;

	let actualTextHeightInPixels = headerHeightInPixels;
	actualTextHeightInPixels = headerHeightInPixels - boxProperties.borderRadius <= 0
		? 0
		: headerHeightInPixels - boxProperties.borderRadius;

	// Only wrap text if the box has no children
	let wrappedBoxTextLineCount = wordWrappedBoxText.split("<br/>").length;
	if (!hasChildren) {
		if (wrappedBoxTextLineCount < 3) {
			wrappedBoxTextLineCount = 3;
		}
	}

	const wrappedBoxTextMaxSize = Math.floor(
		actualTextHeightInPixels / wrappedBoxTextLineCount
	);

	const boxHeaderDimesions: BoxHeaderDimensions = {
		actualTextWidthInPixels,
		actualTextHeightInPixels,
		wrappedBoxTextMaxSize,
		wrappedBoxTextLineCount,
	}

	return boxHeaderDimesions;
}

const getWrappedBoxHeaderText = (wordWrappedBoxText: string,
	wrappedBoxTextLineCount: number,
	hasChildren?: boolean): string => {
	if (hasChildren) {
		return wordWrappedBoxText;
	}

	if (wrappedBoxTextLineCount === 1) {
		return `<br/>${wordWrappedBoxText}<br/>`;
	}

	if (wrappedBoxTextLineCount === 2) {
		return `${wordWrappedBoxText}<br/>`;
	}

	return wordWrappedBoxText;
}

export const BoxHeader = (props: BoxHeaderProps) => {
	const {
		onBoxHeaderTextChange,
		onBoxHeaderTextAccept,
		onBoxHeaderTextCancel,
		boxKey,
		boxProperties,
		boxText,
		textWidthInPixels,
		headerHeightInPixels,
		headerFontSizeInPixels,
		currentlyEditingInPlaceBoxKey,
		currentlyEditingInPlaceBoxHeaderText,
		isHeaderVisible,
		showHeader,
		hasChildren
	} = props;

	if (!(isHeaderVisible && showHeader)) {
		return null;
	}

	// Build the text style
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const textStyle: React.CSSProperties = useMemo(() => getTextStyle(
		textWidthInPixels,
		headerHeightInPixels,
		boxProperties.textAlignment,
		boxProperties.textAlignmentPaddingInPixels,
		boxProperties.textIsVertical,
		true,
		boxProperties.fontFamily,
		boxProperties.fontStyle,
		headerFontSizeInPixels,
		boxProperties.fontWeight,
		boxProperties.borderSizeInPixels
	), [
		textWidthInPixels,
		headerHeightInPixels,
		boxProperties.textAlignment,
		boxProperties.borderSizeInPixels,
		boxProperties.textAlignmentPaddingInPixels,
		boxProperties.textIsVertical,
		boxProperties.fontFamily,
		boxProperties.fontStyle,
		headerFontSizeInPixels,
		boxProperties.fontWeight
	]);

	const wordWrappedBoxText = getWordWrappedBoxText(boxText, hasChildren);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const boxHeaderDimensions = useMemo(() => calculateBoxHeaderDimensions(boxProperties,
		wordWrappedBoxText,
		textWidthInPixels,
		headerHeightInPixels,
		hasChildren),
		[
			boxProperties,
			wordWrappedBoxText,
			textWidthInPixels,
			headerHeightInPixels,
			hasChildren
		]);

	const onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
		const keyCode = event.keyCode;

		// Enter accepts the changes, escape cancels them.
		if (keyCode === KEY_CODE_ENTER) {
			onBoxHeaderTextAccept();
		} else if (keyCode === KEY_CODE_ESCAPE) {
			onBoxHeaderTextCancel();
		}
	};

	let renderedHeader = null;
	if (currentlyEditingInPlaceBoxKey !== boxKey) {
		if (boxProperties.textSizing === TextSizing.AUTO) {
			const wrappedBoxHeaderText = getWrappedBoxHeaderText(wordWrappedBoxText,
				boxHeaderDimensions.wrappedBoxTextLineCount,
				hasChildren)

			renderedHeader = (
				<Fitty
					style={textStyle}
					text={wrappedBoxHeaderText}
					multiLine={true}
					minSize={1}
					maxSize={boxHeaderDimensions.wrappedBoxTextMaxSize}
				/>
			);
		} else {
			renderedHeader = (
				<div
					style={textStyle}
					dangerouslySetInnerHTML={{ __html: boxText }}
				/>
			);
		}
	} else {
		const style = {
			...textStyle,
			border: 0,
			backgroundColor: "#FFF",
			color: "#000",
		}

		renderedHeader = (
			<input
				style={style}
				type="text"
				autoFocus
				value={currentlyEditingInPlaceBoxHeaderText}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
					onBoxHeaderTextChange(event.target.value)}
				onBlur={() => onBoxHeaderTextCancel()}
				onKeyUp={onKeyUp}
			/>
		);
	}

	return renderedHeader
}

export const MemoizedBoxHeader = React.memo(BoxHeader)
