import React, { useEffect, useState } from "react";

import { Modal, Select } from "antd";
import * as lensesLib from "@lib/lenses/lenses";
import * as boxTypeLib from "@lib/box/box-type";
import * as attributeTypeLib from "@lib/box/attribute-type";
import LabelledElement from "@components/molecules/LabelledElement";

export interface AttributeTypeExpression {
	key: string,
	value: lensesLib.EqualityOperator
}

export interface AddEditAttributeTypeExpressionDialogProps {
	onOKButtonClick: (boxTypeExpression: AttributeTypeExpression) => void;
	onCancelButtonClick: () => void;
	isAdding: boolean;
	isVisible: boolean;
	attributeTypeExpression: AttributeTypeExpression | null,
	attributeTypes: attributeTypeLib.AttributeTypeMap;
}

const createAttributeTypesArray = (attributeTypes: attributeTypeLib.AttributeTypeMap) => {
	return Object.keys(attributeTypes)
	.map((attributeTypeKey) => {
		return { key: attributeTypeKey, name: attributeTypes[attributeTypeKey].name }
	})
	.sort((a, b) => {
		if (a.name < b.name) {
			return -1;
		}

		if (a.name > b.name) {
			return 1;
		}

		return 0;
	})
};

const AddEditAttributeTypeExpressionDialog: React.FC<AddEditAttributeTypeExpressionDialogProps> = (props) => {
	// Get the title
	const title = props.isAdding ? "Add Attribute Type Expression" : "Edit Attribute Type Expression";

	const [value, setValue] = useState<lensesLib.EqualityOperator>(props.attributeTypeExpression !== null  && props.attributeTypeExpression.value != null ? props.attributeTypeExpression.value : lensesLib.EqualityOperator.EQUAL);
	
	const [key, setKey] = useState<string>(
		props.attributeTypeExpression !== null ? props.attributeTypeExpression.key :
			Object.keys(props.attributeTypes)[0]
	);

	const [attributeTypesArray, setAttributeTypesArray] = useState(createAttributeTypesArray(props.attributeTypes));

	// Update the main form values.
	// Note that we want to do this when form visibility changes to ensure that they actually update.
	useEffect(() => {
		const newAttributeTypesArray = createAttributeTypesArray(props.attributeTypes);

		setAttributeTypesArray(newAttributeTypesArray);


		setValue(props.attributeTypeExpression ? props.attributeTypeExpression.value : lensesLib.EqualityOperator.EQUAL);
		
		if (newAttributeTypesArray.length > 0) {
			setKey(
				props.attributeTypeExpression !== null ? props.attributeTypeExpression.key :
				newAttributeTypesArray[0].key
			);
		}

	}, [props.attributeTypeExpression, props.attributeTypes, props.isVisible])

	const handleOKButtonClick = () => {

		if (key !== undefined && value !== undefined) {
			const newAttributeTypeExpression = { key, value };

			props.onOKButtonClick(newAttributeTypeExpression);
		}
	};

	return <Modal
		title={title}
		open={props.isVisible}
		onOk={handleOKButtonClick}
		onCancel={props.onCancelButtonClick}
		zIndex={9999}
		width="75%"
	>
		<div>
			<div
				style={{
					width: "100%",
					display: "grid",
					gridTemplateColumns: "0.1fr 0.9fr",
					gap: "4px 12px",
					padding: 0,
					margin: 0,
					overflowY: "visible",
				}}
			>
				<LabelledElement labelText="Attribute Type">
					<Select
						key={`input-operator`}
						size="large"
						placeholder="Please select"
						value={key}
						onChange={(newKey: string) => {
							setKey(newKey);
						}}
						style={{ width: "100%" }}
						getPopupContainer={(node) => {
							let popupContainer: HTMLElement | null =
								window.document.documentElement;
							if (node && node.parentElement) {
								popupContainer = node.parentElement;
							}
							return popupContainer as HTMLElement;
						}}
					>
						{
							attributeTypesArray.map((attributeType, index) => {
								return <Select.Option key={attributeType.key} value={attributeType.key}>{attributeType.name}</Select.Option>
							})
						}
					</Select>
				</LabelledElement>

				<LabelledElement labelText="Equality Operator">
					<Select
						key={`input-operator`}
						size="large"
						placeholder="Please select"
						value={value}
						onChange={(newValue: lensesLib.EqualityOperator) => {
							setValue(newValue);
						}}
						style={{ width: "100%" }}
						getPopupContainer={(node) => {
							let popupContainer: HTMLElement | null =
								window.document.documentElement;
							if (node && node.parentElement) {
								popupContainer = node.parentElement;
							}
							return popupContainer as HTMLElement;
						}}
					>
						<Select.Option value={lensesLib.EqualityOperator.EQUAL}>{lensesLib.EqualityOperator.EQUAL.toString()}</Select.Option>
						<Select.Option value={lensesLib.EqualityOperator.NOT_EQUAL}>{lensesLib.EqualityOperator.NOT_EQUAL.toString()}</Select.Option>
					</Select>
				</LabelledElement>
			</div>

		</div>
	</Modal>
}

export default AddEditAttributeTypeExpressionDialog;