import * as React from "react";

import {
	DragSource,
	DropTarget,
	DragPreviewImage,
} from "react-dnd";

import {
	BoxDragSourceCollectedProps,
	BoxDropTargetCollectedProps,
	BoxDragDropProps,
	BoxDragDrop,
	DragTypes,
	boxDragSource,
	boxDragSourceCollect,
	boxDropTarget,
	boxDropTargetCollect,
} from './BoxDragDrop'

import { dragPreviewImage } from "./drag-preview-image";

export type BoxProps = BoxDragDropProps &
	BoxDragSourceCollectedProps &
	BoxDropTargetCollectedProps;

// This component needs to be class-based otherwise `react-dnd` will report a
// `null` component in the `hover` event.
class TheBox extends React.Component<BoxProps> {
	render() {
		const {
			connectDragSource,
			connectDragPreview,
			connectDropTarget,
			canDrag,
			canDragChildren,
		} = this.props;

		// Determine the drag connection function based on whether we can drag or
		// our children can be dragged
		const dragAndDropConnectionFn = (element: React.ReactElement): React.ReactElement | null => {
			if (!element) {
				return null;
			}

			if(!(canDrag || canDragChildren)) {
				return element;
			}

			return connectDropTarget(connectDragSource(element))
		}

		return (
			<>
				<DragPreviewImage connect={connectDragPreview} src={dragPreviewImage} />
				<BoxDragDrop
					{...this.props}
					onRender={dragAndDropConnectionFn}
				/>
			</>
		)
	}
}

const TheDragSource = DragSource(
	DragTypes.BOX,
	boxDragSource,
	boxDragSourceCollect
)(TheBox);

const TheDropTarget = DropTarget(
	DragTypes.BOX,
	boxDropTarget,
	boxDropTargetCollect
)(TheDragSource);

export const Box = TheDropTarget
