import React, { useEffect, useState } from "react";

import { Modal, Input, Button, InputNumber } from "antd";

import FormHeading from "@components/organisms/FormHeading";
import FormHeadingText from "@components/organisms/FormHeadingText";
import SimpleLensesTable from "@components/molecules/SimpleLensesTable";
import LabelledElement from "@components/molecules/LabelledElement";
import { PlusOutlined } from "@ant-design/icons";
import * as lensesLib from "@lib/lenses/lenses";
import AddEditSimpleLensDialog from "./add-edit-simple-lens-dialog/AddEditSimpleLensDialog";
import * as boxTypeLib from "@lib/box/box-type";
import { v4 as uuid } from "uuid";
import TextArea from "antd/lib/input/TextArea";

export interface AddEditSimpleLensGroupDialogProps {
	onOKButtonClick: (simpleLensGroup: lensesLib.SimpleLensGroup) => void;
	onCancelButtonClick: () => void;
	isAdding: boolean;
	isVisible: boolean;
	boxTypes: boxTypeLib.BoxTypeMap;
	simpleLensGroup: lensesLib.SimpleLensGroup;
}

const AddEditSimpleLensGroupDialog: React.FC<AddEditSimpleLensGroupDialogProps> = (props) => {
	// Get the title
	const title = props.isAdding ? "Add Lens Group" : "Edit Lens Group";

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [order, setOrder] = useState(0);
	const [lensMap, setLensMap] = useState<lensesLib.SimpleLensMap>({});

	const [addEditSimpleLensDialogIsVisible, setAddEditSimpleLensDialogIsVisible] = useState(false);
	const [addEditSimpleLensDialogIsAdding, setAddEditSimpleLensDialogIsAdding] = useState(true);

	const [addEditSimpleLens, setAddEditSimpleLens] = useState<lensesLib.SimpleLens | null>(null);
	const [addEditSimpleLensKey, setAddEditSimpleLensKey] = useState<string | null>(null);

	// Add the useEffect to update stuff from props
	// Base it on the object and the key coming in.

	// We actually need a list of type/attributes

	const createNewSimpleLens  = () : lensesLib.SimpleLens => {
		return {
			name: "",
			description: "",
			order: 0,
			entries: []
		};
	}

	useEffect(()=>{
		setName(props.simpleLensGroup.name);
		setDescription(props.simpleLensGroup.description);
		setOrder(props.simpleLensGroup.order);
		setLensMap(props.simpleLensGroup.lenses);
	}, [props.simpleLensGroup])


	// Configure the state
	const handleOKButtonClick = () => {
		const lensGroup : lensesLib.SimpleLensGroup = {
			name, description, order, lenses: JSON.parse(JSON.stringify(lensMap))
		};
		props.onOKButtonClick(lensGroup);
	};

	const handleAddLens = () => {
		setAddEditSimpleLensDialogIsAdding(true);
		setAddEditSimpleLens(createNewSimpleLens());
		setAddEditSimpleLensKey(uuid());

		setAddEditSimpleLensDialogIsVisible(true);
	};
	const handleEditLens = (key: string) => {

		setAddEditSimpleLensKey(key);
		setAddEditSimpleLens(lensMap[key]);

		setAddEditSimpleLensDialogIsAdding(false);
		setAddEditSimpleLensDialogIsVisible(true);
	};

	const handleDeleteLens = (key: string) => {
		const newLensMap = JSON.parse(JSON.stringify(lensMap));
		delete newLensMap[key];

		setLensMap(newLensMap);
	};

	return <Modal
		title={title}
		open={props.isVisible}
		onOk={handleOKButtonClick}
		onCancel={props.onCancelButtonClick}
		zIndex={9999}
		width="75%"
	>
		<div>
			<FormHeading>
				<FormHeadingText>Lens Group Details</FormHeadingText>
			</FormHeading>
			<div
				style={{
					width: "100%",
					display: "grid",
					gridTemplateColumns: "0.1fr 0.9fr",
					gap: "4px 12px",
					padding: 0,
					margin: 0,
					overflowY: "visible",
				}}
			>
				<LabelledElement labelText="Name">
					<Input

						value={name}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>
						) => { setName(event.target.value) }}
					/>
				</LabelledElement>
				<LabelledElement labelText="Description">
					<TextArea
						value={description}
						onChange={(
							event: React.ChangeEvent<HTMLTextAreaElement>
						) => { setDescription(event.target.value) }}
					/>	
				</LabelledElement>
				<LabelledElement labelText="Order">
					<InputNumber
						value={order}
						onChange={(value: number | null) => { if (value !== null) { setOrder(value); } }}
					/>
				</LabelledElement>
			</div>
			<FormHeading>
				<FormHeadingText>Lenses</FormHeadingText>
				<Button
					icon={<PlusOutlined />}
					type="primary"
					style={{
						paddingRight: "12px",
						marginRight: "12px",
					}}
					onClick={handleAddLens}
				>
					Add
				</Button>
			</FormHeading>

			{addEditSimpleLens && addEditSimpleLensKey ?
				<AddEditSimpleLensDialog
					isAdding={addEditSimpleLensDialogIsAdding}
					isVisible={addEditSimpleLensDialogIsVisible}
					simpleLens={addEditSimpleLens}
					onCancelButtonClick={() => { setAddEditSimpleLensDialogIsVisible(false); }}
					onOKButtonClick={(simpleLens) => {
						const newLensMap = JSON.parse(JSON.stringify(lensMap));
						newLensMap[addEditSimpleLensKey] = simpleLens;

						setLensMap(newLensMap);

						setAddEditSimpleLens(null);
						setAddEditSimpleLensKey(null);

						setAddEditSimpleLensDialogIsVisible(false);
					}}
					simpleLensKey={addEditSimpleLensKey}
					boxTypes={props.boxTypes}
				/> : null}

			<SimpleLensesTable
				onEdit={handleEditLens}
				onDelete={handleDeleteLens}
				simpleLensMap={lensMap}
				canEdit={true}
				canDelete={true} />
		</div>
	</Modal>
}

export default AddEditSimpleLensGroupDialog;