import * as React from "react";

import { createPortal } from "react-dom";

import { Link } from "react-router-dom";

import { Breadcrumb as AntdBreadcrumb } from "antd";

import { Breadcrumb } from "@lib/breadcrumb";

const BREADCRUMBS_PORTAL_DOM_ELEMENT_ID = "breadcrumbs-portal";

interface BreadcrumbProps {
	breadcrumbs: Breadcrumb[];
	currentPathParams: any;
}

export const Breadcrumbs = (props: BreadcrumbProps) => {
	const renderedBreadcrumbs = props.breadcrumbs.map(
		(breadcrumb: Breadcrumb) => {
			const path = breadcrumb.onGetPath(props.currentPathParams);
			const displayName = breadcrumb.onGetDisplayName(
				props.currentPathParams
			);

			return (
				<AntdBreadcrumb.Item key={path}>
					<Link to={path}>
						<span style={{ color: "#FFF" }}>{displayName}</span>
					</Link>
				</AntdBreadcrumb.Item>
			);
		}
	);

	return (
		<AntdBreadcrumb
			style={{ paddingTop: "20px" }}
			separator={<span style={{ color: "#FFF" }}>/</span>}
		>
			{renderedBreadcrumbs}
		</AntdBreadcrumb>
	);
};

export const BreadcrumbsPortal = () => {
	return (
		<div
			id={BREADCRUMBS_PORTAL_DOM_ELEMENT_ID}
			style={{
				float: "left",
			}}
		/>
	);
};

export const renderBreadcrumbsInPortal = (
	breadcrumbs: Breadcrumb[],
	currentPathParams: any
) => {
	const breadcrumbsRootDOMElement = document.getElementById(
		BREADCRUMBS_PORTAL_DOM_ELEMENT_ID
	);
	if (!breadcrumbsRootDOMElement) {
		return null;
	}

	const renderedBreadcrumbs = (
		<Breadcrumbs
			breadcrumbs={breadcrumbs}
			currentPathParams={currentPathParams}
		/>
	);

	return createPortal(renderedBreadcrumbs, breadcrumbsRootDOMElement);
};
