import * as React from "react";

import { Modal, Checkbox, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { UploadFile } from "antd/lib/upload/interface";

import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate";

import * as illustrationLib from "@lib/illustration/illustration";
import * as boxLib from "@lib/box/box";
import * as boxTypeLib from "@lib/box/box-type";

import { CanvasList } from "../../../molecules/CanvasList";

import {
	getWorksheetNameToBoxTypeKeyMap,
	convertExcelToBoxAttributeMap,
	convertExcelHierarchyToBoxAttributeMap,
	convertBoxHierarchiesToExcel,
	convertBoxTypeAttributesToExcel,
	applyBoxAttributeMapToIllustration,
	getIllustrationAttributes,
	downloadExcel
} from './import-export-excel'

export interface ImportExportIllustrationExcelDialogProps {
	onImportButtonClick: (illustration: illustrationLib.Illustration) => void;
	onCloseOrCancelButtonClick: () => void;
	isImporting: boolean;
	isVisible: boolean;
	illustration: illustrationLib.Illustration;
	illustrationName: string;
	boxMap: boxLib.BoxMap | undefined;
	flattenedBoxMap: boxLib.BoxMap | undefined;
	boxTypeMap: boxTypeLib.BoxTypeMap | undefined;
}

interface ImportExportIllustrationExcelDialogState {
	includeUnnamedBoxes: boolean;
	exportCanvases: boolean;
	exportCanvasesBoxKeys: string[];
	importCanvases: boolean;
	importFileList: UploadFile<any>[];
	importWorkBook: XLSX.WorkBook | undefined;
	includeAssociations: boolean;
}

export class ImportExportIllustrationExcelDialog extends React.Component<
	ImportExportIllustrationExcelDialogProps,
	ImportExportIllustrationExcelDialogState
> {
	constructor(props: ImportExportIllustrationExcelDialogProps) {
		super(props);

		// Set up our state
		this.state = {
			includeUnnamedBoxes: false,
			importCanvases: false,
			importFileList: [],
			importWorkBook: undefined,
			exportCanvases: false,
			exportCanvasesBoxKeys: [],
			includeAssociations: false
		};
	}

	public componentWillReceiveProps(
		nextProps: ImportExportIllustrationExcelDialogProps
	) {
		// Only update if we're showing the dialog
		if (!this.props.isVisible && nextProps.isVisible) {
			// Set up our state
			this.setState({
				includeUnnamedBoxes: false,
				importFileList: [],
				importWorkBook: undefined,
				exportCanvases: false,
				exportCanvasesBoxKeys: [],
				includeAssociations: false
			});
		}
	}

	public render() {
		// Get the title
		const title = this.props.isImporting
			? "Import Illustration From Excel"
			: "Export Illustration To Excel";

		// Get the OK button text
		const okButtonText = this.props.isImporting ? "Import" : "Export";

		// If we're importing and no files are selected, disable the import button
		const okButtonDisabled = this.props.isImporting
			? this.state.importFileList.length <= 0
			: false;

		return (
			<Modal
				title={title}
				open={this.props.isVisible}
				okText={okButtonText}
				okButtonProps={{ disabled: okButtonDisabled }}
				onOk={this.handleOKButtonClick}
				cancelButtonProps={{ disabled: false }}
				onCancel={this.props.onCloseOrCancelButtonClick}
				zIndex={9999}
				width={"50%"}
			>
				{!this.props.isImporting && (
					<React.Fragment>
						<div
							style={{
								width: "100%",
								display: "grid",
								gridTemplateColumns: "1fr 1fr 1fr",
								gap: "0px 12px",
								paddingBottom: "12px",
								margin: 0,
								overflowY: "visible",
							}}
						>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-start",
								}}
							>
								<Checkbox
									checked={this.state.includeUnnamedBoxes}
									name="includeUnnamedBoxes"
									onChange={
										this.handleIncludeUnnamedBoxesChange
									}
								>
									Include Unnamed Boxes
								</Checkbox>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-start",
								}}
							>
								<Checkbox
									checked={this.state.exportCanvases}
									name="exportCanvases"
									onChange={this.handleExportHierarchyChange}
								>
									Export Canvases
								</Checkbox>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-start",
								}}
							>
								<Checkbox
									checked={this.state.includeAssociations}
									name="includeAssociations"
									onChange={this.handleIncludeAssociationsChange}
								>
									Include Associations
								</Checkbox>
							</div>
						</div>
						{this.state.exportCanvases && (
							<React.Fragment>
								<div
									style={{
										width: "100%",
										display: "grid",
										gridTemplateColumns: "1fr",
										gap: "0px 12px",
										paddingBottom: "12px",
										margin: 0,
										overflowY: "visible",
									}}
								>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-start",
										}}
									>
										<b
											style={{
												textAlign: "left",
												paddingTop: "4px",
												paddingBottom: "4px",
												paddingRight: "12px",
											}}
										>
											Select Canvases to Export:
										</b>
									</div>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-start",
										}}
									>
										<CanvasList
											onCanvasBoxSelectionChange={
												this
													.handleCanvasBoxSelectionChange
											}
											flattenedBoxMap={
												this.props.flattenedBoxMap
											}
											selectedCanvasBoxKeys={
												this.state.exportCanvasesBoxKeys
											}
										/>
									</div>
								</div>
							</React.Fragment>
						)}
					</React.Fragment>
				)}
				{this.props.isImporting && (
					<div
						style={{
							marginTop: "1em",
						}}
					>
						{this.renderUpload()}
						<div
							style={{
								display: "flex",
								justifyContent: "flex-start",
								paddingTop: "8px",
							}}
						>
							<Checkbox
								checked={this.state.importCanvases}
								name="importCanvases"
								onChange={this.handleImportHierarchyChange}
							>
								Import Canvases
							</Checkbox>
						</div>
					</div>
				)}
			</Modal>
		);
	}

	private renderUpload = () => {
		return (
			<Upload.Dragger
				name="file"
				accept=".xls,.xlsx"
				multiple={false}
				action=""
				beforeUpload={this.handleBeforeUpload}
				fileList={this.state.importFileList}
			>
				<p className="ant-upload-drag-icon">
					<InboxOutlined />
				</p>
				<p className="ant-upload-text">
					Click or drag an Excel file to this area to upload
				</p>
				<p className="ant-upload-hint">
					Only Excel files previously exported by Enterprise Lens are
					supported
				</p>
			</Upload.Dragger>
		);
	};

	private handleIncludeUnnamedBoxesChange = (e: CheckboxChangeEvent) => {
		if (e != null && e.target.name !== undefined) {
			this.setState({ includeUnnamedBoxes: e.target.checked });
		}
	};


	private handleExportHierarchyChange = (e: CheckboxChangeEvent) => {
		if (e != null && e.target.name !== undefined) {
			this.setState({ exportCanvases: e.target.checked });
		}
	};

	private handleIncludeAssociationsChange = (e: CheckboxChangeEvent) => {
		if (e != null && e.target.name !== undefined) {
			this.setState({ includeAssociations: e.target.checked });
		}
	};

	private handleCanvasBoxSelectionChange = (
		boxKey: string,
		isSelected: boolean
	): void => {
		if (isSelected) {
			const foundIndex = this.state.exportCanvasesBoxKeys.findIndex(
				(k: string) => k === boxKey
			);
			if (foundIndex < 0) {
				const exportCanvasesBoxKeys = JSON.parse(
					JSON.stringify(this.state.exportCanvasesBoxKeys)
				) as string[];
				exportCanvasesBoxKeys.push(boxKey);
				this.setState({ exportCanvasesBoxKeys });
			}
		} else {
			const foundIndex = this.state.exportCanvasesBoxKeys.findIndex(
				(k: string) => k === boxKey
			);
			if (foundIndex >= 0) {
				const exportCanvasesBoxKeys = JSON.parse(
					JSON.stringify(this.state.exportCanvasesBoxKeys)
				) as string[];
				exportCanvasesBoxKeys.splice(foundIndex, 1);
				this.setState({ exportCanvasesBoxKeys });
			}
		}
	};

	private handleOKButtonClick = () => {
		// Are we importing
		if (this.props.isImporting) {
			this.readImportedExcelFile(this.state.importFileList[0])
				.then((workbook: XLSX.WorkBook) => {
					if (this.props.boxTypeMap &&
						this.props.flattenedBoxMap) {
						const worksheetNameToBoxTypeKeyMap = getWorksheetNameToBoxTypeKeyMap(
							this.props.boxTypeMap
						);

						// Convert the workbook to a box attribute map
						const { boxAttributeMap, boxTypeMap } = !this.state.importCanvases
							? convertExcelToBoxAttributeMap(
								workbook,
								this.props.flattenedBoxMap,
								this.props.boxTypeMap,
								worksheetNameToBoxTypeKeyMap
							)
							: convertExcelHierarchyToBoxAttributeMap(
								workbook,
								this.props.flattenedBoxMap,
								this.props.boxTypeMap
							);

						const updatedIllustration = applyBoxAttributeMapToIllustration(
							this.props.illustration,
							boxAttributeMap,
							boxTypeMap
						);

						// Notify the parent that the Import button has been clicked, passing the new
						// Illustration
						this.props.onImportButtonClick(updatedIllustration);
					}
				})
				.catch((err: Error) => {
					console.log(err);
				});
		} else {
			if (
				this.props.boxMap &&
				this.props.flattenedBoxMap &&
				this.props.boxTypeMap
			) {
				// Get the attributes of the illustration
				const illustrationAttributes = getIllustrationAttributes(
					this.props.illustration,
					this.props.flattenedBoxMap,
					this.props.boxTypeMap,
					this.state.includeUnnamedBoxes,
					this.state.includeAssociations
				);

				let workbookPromise:
					| Promise<XlsxPopulate.Workbook>
					| undefined = undefined;

				if (this.state.exportCanvases) {
					workbookPromise = convertBoxHierarchiesToExcel(
						this.props.boxMap,
						this.props.flattenedBoxMap,
						this.state.exportCanvasesBoxKeys,
						illustrationAttributes,
						this.props.boxTypeMap,
						this.state.includeAssociations
					);
				} else {
					// Convert them to an Excel spreadsheet
					workbookPromise = convertBoxTypeAttributesToExcel(
						this.props.flattenedBoxMap,
						this.props.flattenedBoxMap,
						illustrationAttributes,
						this.props.boxTypeMap,
						this.state.includeAssociations
					);
				}

				if (workbookPromise) {
					// Build the spreadsheet name
					const spreadsheetName = `${this.props.illustrationName}.xlsx`;

					// Download the spreadsheet
					downloadExcel(workbookPromise, spreadsheetName);
				}
			}

			// Notify the parent that the Close button has been clicked
			this.props.onCloseOrCancelButtonClick();
		}
	};

	private handleImportHierarchyChange = (e: CheckboxChangeEvent) => {
		if (e != null && e.target.name !== undefined) {
			this.setState({ importCanvases: e.target.checked });
		}
	};

	private readImportedExcelFile = (file: any) => {
		return new Promise(
			(
				resolve: (workbook: XLSX.WorkBook) => void,
				reject: (reason: any) => void
			) => {
				const reader = new FileReader();

				reader.onload = (event: any) => {
					try {
						// Get the loaded data
						const loadedData = event.target.result;
						if (!loadedData) {
							reject(new Error("Unable to load file"));
							return;
						}

						// Convert it to a workbook
						const workbook = XLSX.read(loadedData, {
							type: "binary",
						});

						// We've loaded the workbook
						resolve(workbook);
					} catch (e) {
						reject(e);
					}
				};

				reader.readAsBinaryString(file);
			}
		);
	};

	private handleBeforeUpload = (file: any) => {
		this.setState({ importFileList: [file] });
		return false;
	};
}
