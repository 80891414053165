import { ModelService, makeModelService } from './model-service';
import { Role } from '@contracts/role';

const entityName = 'roles';

export interface RoleService extends ModelService<Role, Role, Role, Role> {
}

const genericService = makeModelService<Role, Role, Role, Role>(entityName);

const roleService: RoleService = { ...genericService };

export default roleService;