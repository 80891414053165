import React from "react";
import { Modal, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import LabelledElement from "@components/molecules/LabelledElement";

interface AddEditRoleDialogProps {
	role: any | undefined,
	onOKButtonClick: ((user: any) => void),
	onCancelButtonClick: (() => void),
	isVisible: boolean,
};

interface AddEditRoleDialogState {
	role: any
};


export default class AddEditRoleDialog extends React.Component<AddEditRoleDialogProps, AddEditRoleDialogState> {
	constructor(props: AddEditRoleDialogProps) {
		super(props);

		// Get the Role
		const role = (props.role)
			? JSON.parse(JSON.stringify(props.role))
			: undefined;

		// Set up our state
		this.state = {
			role: role,
		};
	}

	public componentWillReceiveProps(nextProps: AddEditRoleDialogProps) {
		// Only update if we're showing the dialog
		if ((!this.props.isVisible) && (nextProps.isVisible)) {
			// Get the Role
			const role = (nextProps.role)
				? JSON.parse(JSON.stringify(nextProps.role))
				: undefined;

			// Set up our state
			this.setState({
				role: role,
			});
		}
	}

	private isAddMode(): boolean {
		return !(this.state.role._id);
	}

	public render() {
		return (

			<Modal
				title={this.isAddMode() ? 'Add Role' : 'Edit Role'}
				open={this.props.isVisible}
				onOk={this.handleOKButtonClick}
				onCancel={this.props.onCancelButtonClick}
				zIndex={9999}
			>
				{this.state.role ?
					<div
						style={{
							width: "100%",
							display: "grid",
							gridTemplateColumns: "1fr",
							gap: "4px 12px",
							padding: 0,
							margin: 0,
							overflowY: "visible",
						}}>
						<LabelledElement labelText="Name">
							<Input name="name" value={this.state.role.name} onChange={this.handleInputChange} />
						</LabelledElement>
						<LabelledElement labelText="Description">
							<Input name="description" value={this.state.role.description} onChange={this.handleInputChange} />
						</LabelledElement>
						<LabelledElement labelText="Abilities">
							<TextArea rows={4} name="abilities" value={this.state.role.abilities.join(', ')} onChange={this.handleInputChange} />
						</LabelledElement>
					</div>
					: null}
			</Modal>
		);
	}

	private handleInputChange = (event: any) => {
		var role = JSON.parse(JSON.stringify(this.state.role));

		// Get the value
		let value = event.target.value;

		// If we're dealing with abilities, convert it back to an array
		if (event.target.name === 'abilities') {
			value = event.target.value.replace(/\s/g, '').split(',');
		}

		role[event.target.name] = value;

		this.setState({ role: role });
	};

	private handleOKButtonClick = () => {
		// Notify the callback that the OK button has been clicked, passing the new
		// box name and box type key
		this.props.onOKButtonClick(this.state.role);
	};
}