import * as React from "react";
import { FileAddOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Table, Divider, Modal, Card, Layout, notification } from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons';

import userService from "../../../../services/user";
import { User, makeUser } from "@contracts/user";
import * as authorizationService from "../../../../services/authorization";
import AddEditUserDialog from "./AddEditUserDialog";
import authService from "../../../../services/auth";
import ChangePasswordDialog from "./ChangePasswordDialog";

import { renderBreadcrumbsInPortal } from "../../../organisms/Breadcrumbs";

import { withRouter } from '../../../../routes/withRouter';

const confirm = Modal.confirm;
export interface ListUsersPageProps {
	match: any;
	abilities: authorizationService.AbilitiesMap | undefined;
}

export interface ListUsersPageState {
	users: User[];
	modalUser: User | null;
	isEditUserModalVisible: boolean;
	isChangePasswordModalVisible: boolean;
}

class ListUsersPageC extends React.Component<
	ListUsersPageProps,
	ListUsersPageState
> {
	private columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "_id",
			render: (text: any, record: any) => {
				// Get the client key
				const clientKey = "*";

				return authorizationService.renderIfAbilityPermitted(
					this.props.abilities,
					authorizationService.AbilityType.UserView,
					clientKey,
					() => (
						<Link to={"/security/users/" + record._id}>{text}</Link>
					)
				);
			},
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			render: (text: any, record: any) => {
				// Get the client key
				const clientKey = "*";

				return authorizationService.renderIfAbilityPermitted(
					this.props.abilities,
					authorizationService.AbilityType.UserView,
					clientKey,
					() => <a href={`mailto://${text}`}>{text}</a>
				);
			},
		},

		{
			title: "Action",
			key: "action",
			render: (text: any, record: any) => {
				// Get the client key
				const domainKey = "*";

				return (
					<span>
						{authorizationService.renderIfAbilityPermitted(
							this.props.abilities,
							authorizationService.AbilityType.UserEdit,
							domainKey,
							() => (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								<a
									onClick={(e) =>
										this.handleEditUserClick(record, e)
									}
								>
									Edit
								</a>
							)
						)}
						{authorizationService.renderIfAllAbilitiesPermitted(
							this.props.abilities,
							[
								authorizationService.AbilityType.UserEdit,
								authorizationService.AbilityType.UserDelete,
							],
							domainKey,
							() => (
								<Divider type="vertical" />
							)
						)}
						{authorizationService.renderIfAbilityPermitted(
							this.props.abilities,
							authorizationService.AbilityType.UserEdit,
							domainKey,
							() => (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								<a
									onClick={(e) =>
										this.handleChangePasswordClick(
											record,
											e
										)
									}
								>
									Change Password
								</a>
							)
						)}
						{authorizationService.renderIfAllAbilitiesPermitted(
							this.props.abilities,
							[
								authorizationService.AbilityType.UserEdit,
								authorizationService.AbilityType.UserDelete,
							],
							domainKey,
							() => (
								<Divider type="vertical" />
							)
						)}

						{authorizationService.renderIfAbilityPermitted(
							this.props.abilities,
							authorizationService.AbilityType.UserDelete,
							domainKey,
							() => (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								<a
									onClick={(e) =>
										this.handleRemoveUserClick(record, e)
									}
								>
									Delete
								</a>
							)
						)}
					</span>
				);
			},
		},
	];

	private refreshUserList = () => {
		userService.getList((users) => {
			if (users) {
				console.log("ListUsersPage");
				console.log(this.props.abilities);
				console.log(users);

				this.setState({ users: users });
			}
		}, (message)=>{
			console.log(`Error occurred: ${message}.`);
			notification.open({
				message: 'Error: Could not load User List',
				description:
					`Failed to load User List.`,
				icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
			});
		});
	};

	private handleRemoveUserClick = (user: any, e: any) => {
		confirm({
			title: "Are you sure delete this user?",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				userService.remove(user._id, (data: any) => {
					this.refreshUserList();
				}, (message)=>{
					console.log(`Error occurred: ${message}.`);
					notification.open({
						message: 'Error: Could not remove User',
						description:
							`Failed to remove User Id ${user._id}.`,
						icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
					});
				});
			},
			onCancel: () => {},
		});
	};

	constructor(props: ListUsersPageProps) {
		super(props);
		this.state = {
			users: [],
			modalUser: null,
			isEditUserModalVisible: false,
			isChangePasswordModalVisible: false,
		};
	}

	componentDidMount() {
		this.refreshUserList();
	}

	private renderBreadcrumbs = () => {
		const BREADCRUMBS = [
			{
				onGetPath: (params: any) => "/security/users/",
				onGetDisplayName: (params: any) => "Users",
			},
		];

		return renderBreadcrumbsInPortal(BREADCRUMBS, this.props.match.params);
	};

	public render() {
		const breadcrumbs = this.renderBreadcrumbs();

		return (
			<Layout
				style={{
					width: "100%",
					height: "100%",
				}}
			>
				{breadcrumbs}
				<Layout.Content>
					<Card
						title="All Users"
						style={{ margin: 16 }}
						actions={[
							<FileAddOutlined
								onClick={this.handleAddUserClick}
							/>,
						]}
					>
						<Table
							columns={this.columns}
							rowKey="_id"
							pagination={false}
							dataSource={this.state.users}
						/>
					</Card>
					{this.state.modalUser ? (
						<AddEditUserDialog
							onOKButtonClick={this.handleEditUserModalOK}
							onCancelButtonClick={this.handleEditUserCancel}
							isVisible={this.state.isEditUserModalVisible}
							user={this.state.modalUser}
						/>
					) : null}

					{this.state.modalUser ? (
						<ChangePasswordDialog
							onOKButtonClick={this.handleChangePasswordModalOK}
							onCancelButtonClick={
								this.handleChangePasswordCancel
							}
							isVisible={this.state.isChangePasswordModalVisible}
							userId={this.state.modalUser._id}
						/>
					) : null}
				</Layout.Content>
			</Layout>
		);
	}
	private handleEditUserModalOK = (editedUser: any) => {
		// If we had an ID then we are updating
		if (editedUser._id) {
			userService.put(editedUser._id, editedUser, (res: any) => {
				this.refreshUserList();
				// Update our state and close the box modal dialog
				this.setState({
					modalUser: null,
					isEditUserModalVisible: false,
				});
			}, (message)=>{
				console.log(`Error occurred: ${message}.`);
				notification.open({
					message: 'Error: Could not edit User',
					description:
						`Failed to edit User Id ${editedUser._id}.`,
					icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
				});
			});
		}
		// Otherwise we are creating a new record
		else {
			userService.post(editedUser, (res: any) => {
				this.refreshUserList();
				// Update our state and close the box modal dialog
				this.setState({
					modalUser: null,
					isEditUserModalVisible: false,
				});
			}, (message)=>{
				console.log(`Error occurred: ${message}.`);
				notification.open({
					message: 'Error: Could not create User',
					description:
						`Failed to create new User.`,
					icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
				});
			});
		}
	};

	private handleEditUserCancel = () => {
		// Close the box modal dialog
		this.setState({
			modalUser: null,
			isEditUserModalVisible: false,
		});
	};

	private handleEditUserClick = (user: any, e: any) => {
		// Open the box modal dialog
		this.setState({
			modalUser: user,
			isEditUserModalVisible: true,
		});
	};
	private handleAddUserClick = () => {
		// Open the box modal dialog
		this.setState({
			modalUser: makeUser(),
			isEditUserModalVisible: true,
		});
	};

	private handleChangePasswordModalOK = (
		userId: string,
		password: string
	) => {
		authService.resetPassword(userId, password, (result) => {
			this.setState({
				isChangePasswordModalVisible: false,
				modalUser: null,
			});
		});
	};

	private handleChangePasswordCancel = () => {
		// Close the box modal dialog
		this.setState({
			isChangePasswordModalVisible: false,
			modalUser: null,
		});
	};

	private handleChangePasswordClick = (user: any, event: any) => {
		// Open the box modal dialog
		this.setState({
			isChangePasswordModalVisible: true,
			modalUser: user,
		});
	};
}

export const ListUsersPage = withRouter(ListUsersPageC);
