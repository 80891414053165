export interface DtoBase {
  _id: string,
  createdById: string | undefined,
  createdBy: string | undefined,
  createdAt: Date | null | undefined,
  updatedById: string | undefined,
  updatedBy: string | undefined,
  updatedAt: Date | null | undefined,
};

export const makeDtoBase = () : DtoBase => {
  return {
      _id: "",
      createdById: "",
      createdBy: "",
      createdAt: null,
      updatedById: "",
      updatedBy: "",
      updatedAt: null, 
  }
}