import React, { useEffect, useState } from "react";

import { Checkbox, Modal, Select } from "antd";
import LabelledElement from "@components/molecules/LabelledElement";
import { AttributeTypeVisibilityItem } from "./hooks/useAttributeTypeVisibilityList";
import { BoxTypeMap, BoxTypeVisibility, BoxTypeVisibilityMap } from "@lib/box/box-type";
import { MixinVisibilityItem } from "./hooks/useMixinVisibilityList";
import { AttributeTypeVisibilityMap } from "@lib/box/attribute-type";

export interface AddEditMixinVisibilityDialogProps {
	onOKButtonClick: (mixinVisibilityItem: MixinVisibilityItem) => void;
	onCancelButtonClick: () => void;
	isAdding: boolean;
	isVisible: boolean;
	mixinVisibilityItem: MixinVisibilityItem | null;
	boxTypes: BoxTypeMap;
}

// export interface BoxTypeVisibility {
// 	isVisible: boolean;
// 	areBoxesVisible: boolean;
// 	areBoxesInLayout: boolean;
// 	mixinBoxTypeVisibilityMap: BoxTypeVisibilityMap | undefined;
// 	attributeTypeVisibilityMap:
// 	| attributeTypeLib.AttributeTypeVisibilityMap
// 	| undefined;
// }


const AddEditMixinVisibilityDialog: React.FC<AddEditMixinVisibilityDialogProps> = (props) => {
	// Get the title
	// TODO: This needs to be set to Attribute Type or the mixin stuff
	const title = props.isAdding ? "Add Mixin Visibility" : "Edit Mixin Visibility";

	// const [operator, setOperator] = useState<lensesLib.AttributeExpressionOperator>(
	// 	props.attributeExpression !== null && props.attributeExpression.operator !== null ? props.attributeExpression.operator : lensesLib.AttributeExpressionOperator.EQUAL);


	// const [attributeParameter, setAttributeParameter] = useState<string | undefined>(
	// 	props.attributeExpression !== null ? props.attributeExpression.attributeParameter : undefined
	// );

	const [boxType, setBoxType] = useState<string | undefined>(
		props.mixinVisibilityItem !== null ? props.mixinVisibilityItem.key : undefined
	);

	const [isVisible, setIsVisible] = useState<boolean | undefined>(
		props.mixinVisibilityItem !== null ? props.mixinVisibilityItem.value.isVisible : undefined
	);

	const [areBoxesVisible, setAreBoxesVisible] = useState<boolean | undefined>(
		props.mixinVisibilityItem !== null ? props.mixinVisibilityItem.value.areBoxesVisible : undefined
	);

	const [areBoxesInLayout, setAreBoxesInLayout] = useState<boolean | undefined>(
		props.mixinVisibilityItem !== null ? props.mixinVisibilityItem.value.areBoxesInLayout : undefined
	);


	const [mixinBoxTypeVisibilityMap, setMixinBoxTypeVisibilityMap] = useState<BoxTypeVisibilityMap | undefined>(
		props.mixinVisibilityItem !== null ? props.mixinVisibilityItem.value.mixinBoxTypeVisibilityMap : undefined
	);

	const [attributeTypeVisibilityMap, setAttributeTypeVisibilityMap] = useState<AttributeTypeVisibilityMap | undefined>(
		props.mixinVisibilityItem !== null ? props.mixinVisibilityItem.value.attributeTypeVisibilityMap : undefined
	);

	// const [visibilityValue, setVisibilityValue] = useState<boolean>(
	// 	props.attributeExpression !== null ? props.attributeExpression.attributeValue.toString() : undefined
	// );

	useEffect(() => {
		setBoxType(
			props.mixinVisibilityItem !== null ? props.mixinVisibilityItem.key : 
			Object.keys(props.boxTypes)[0]
		);
	}, [ props.boxTypes, props.mixinVisibilityItem])

	const handleOKButtonClick = () => {

		// if (boxType !== undefined && value !== undefined) {
		// 	const visibilityItem: AttributeTypeVisibilityItem = { key: boxType, value };

		// 	props.onOKButtonClick(visibilityItem);
		// }

	};


	const boxTypesArray = Object.keys(props.boxTypes)
		.map((boxTypeKey) => {
			return { key: boxTypeKey, name: props.boxTypes[boxTypeKey].name }
		})
		.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}

			if (a.name > b.name) {
				return 1;
			}

			return 0;
		});

	return <Modal
		title={title}
		open={props.isVisible}
		onOk={handleOKButtonClick}
		onCancel={props.onCancelButtonClick}
		zIndex={9999}
		width="75%"
	>
		<div>
			<div
				style={{
					width: "100%",
					display: "grid",
					gridTemplateColumns: "0.1fr 0.9fr",
					gap: "4px 12px",
					padding: 0,
					margin: 0,
					overflowY: "visible",
				}}
			>
				<LabelledElement labelText="Box Type">
					<Select
						key={`input-operator`}
						size="large"
						placeholder="Please select"
						value={boxType}
						onChange={(newKey: string) => {
							setBoxType(newKey);
						}}
						style={{ width: "100%" }}
						getPopupContainer={(node) => {
							let popupContainer: HTMLElement | null =
								window.document.documentElement;
							if (node && node.parentElement) {
								popupContainer = node.parentElement;
							}
							return popupContainer as HTMLElement;
						}}
					>
						{
							boxTypesArray.map((attributeType, index) => {
								return <Select.Option key={attributeType.key} value={attributeType.key}>{attributeType.name}</Select.Option>
							})
						}
					</Select>
				</LabelledElement>

				<LabelledElement labelText="Is Box Type Visible?">
					<Checkbox checked={isVisible} onChange={(e) => {setIsVisible(e.target.checked);}} />
				</LabelledElement>
				<LabelledElement labelText="Are Boxes Visible?">
					<Checkbox checked={areBoxesVisible} onChange={(e) => {setAreBoxesVisible(e.target.checked);}} />
				</LabelledElement>

				<LabelledElement labelText="Are Boxes In Layout?">
					<Checkbox checked={areBoxesInLayout} onChange={(e) => {setAreBoxesInLayout(e.target.checked);}} />
				</LabelledElement>
			</div>

		</div>
	</Modal>
}

export default AddEditMixinVisibilityDialog;