import * as React from 'react';

export interface ProfilePageProps {}

export class ProfilePage extends React.Component<ProfilePageProps> {
  public render() {
    return (
      <div style={{ padding: "16px", backgroundColor: "#FFFFFF" }}>
        <h1>Profile</h1>
      </div>
    );
  }
}