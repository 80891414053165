import React, { useEffect, useState } from "react";

import { Checkbox, Input, Modal, Select } from "antd";
import * as lensesLib from "@lib/lenses/lenses";
import * as boxLib from "@lib/box/box";
import LabelledElement from "@components/molecules/LabelledElement";
import { AttributeValue } from "@lib/box/attribute";
import { AttributeTypeMap } from "@lib/box/attribute-type";
import { parameters } from "@lib/3xpr";
import { AttributeTypeVisibilityItem } from "./hooks/useAttributeTypeVisibilityList";

export interface AddEditAttributeVisibilityDialogProps {
	onOKButtonClick: (attributeTypeVisibilityItem: AttributeTypeVisibilityItem) => void;
	onCancelButtonClick: () => void;
	isAdding: boolean;
	isVisible: boolean;
	attributeTypeVisibilityItem: AttributeTypeVisibilityItem | null;
	attributeTypes: AttributeTypeMap;
}

const AddEditAttributeVisibilityDialog: React.FC<AddEditAttributeVisibilityDialogProps> = (props) => {
	// Get the title
	// TODO: This needs to be set to Attribute Type or the mixin stuff
	const title = props.isAdding ? "Add Attribute Type Visibility" : "Edit Attribute Type Visibility";

	// const [operator, setOperator] = useState<lensesLib.AttributeExpressionOperator>(
	// 	props.attributeExpression !== null && props.attributeExpression.operator !== null ? props.attributeExpression.operator : lensesLib.AttributeExpressionOperator.EQUAL);


	// const [attributeParameter, setAttributeParameter] = useState<string | undefined>(
	// 	props.attributeExpression !== null ? props.attributeExpression.attributeParameter : undefined
	// );

	const [attributeType, setAttributeType] = useState<string | undefined>(
		props.attributeTypeVisibilityItem !== null ? props.attributeTypeVisibilityItem.key : undefined
	);

	const [value, setValue] = useState<boolean | undefined>(
		props.attributeTypeVisibilityItem !== null ? props.attributeTypeVisibilityItem.value : undefined
	);


	// const [visibilityValue, setVisibilityValue] = useState<boolean>(
	// 	props.attributeExpression !== null ? props.attributeExpression.attributeValue.toString() : undefined
	// );

	// useEffect(() => {
	// 	setValue(props.attributeExpression? props.attributeExpression.value : lensesLib.EqualityOperator.EQUAL);
	// 	setKey(
	// 		props.attributeExpression !== null ? props.attributeExpression.key : 
	// 		Object.keys(props.boxes)[0]
	// 	);
	// }, [props.attributeExpression, props.boxes])

	const handleOKButtonClick = () => {

		if (attributeType !== undefined && value !== undefined) {
			const visibilityItem: AttributeTypeVisibilityItem = { key: attributeType, value };

			props.onOKButtonClick(visibilityItem);
		}

	};


	const attributeTypesArray = Object.keys(props.attributeTypes)
		.map((attributeTypeKey) => {
			return { key: attributeTypeKey, name: props.attributeTypes[attributeTypeKey].name }
		})
		.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}

			if (a.name > b.name) {
				return 1;
			}

			return 0;
		});

	return <Modal
		title={title}
		open={props.isVisible}
		onOk={handleOKButtonClick}
		onCancel={props.onCancelButtonClick}
		zIndex={9999}
		width="75%"
	>
		<div>
			<div
				style={{
					width: "100%",
					display: "grid",
					gridTemplateColumns: "0.1fr 0.9fr",
					gap: "4px 12px",
					padding: 0,
					margin: 0,
					overflowY: "visible",
				}}
			>
				<LabelledElement labelText="Attribute Type">
					<Select
						key={`input-operator`}
						size="large"
						placeholder="Please select"
						value={attributeType}
						onChange={(newKey: string) => {
							setAttributeType(newKey);
						}}
						style={{ width: "100%" }}
						getPopupContainer={(node) => {
							let popupContainer: HTMLElement | null =
								window.document.documentElement;
							if (node && node.parentElement) {
								popupContainer = node.parentElement;
							}
							return popupContainer as HTMLElement;
						}}
					>
						{
							attributeTypesArray.map((attributeType, index) => {
								return <Select.Option key={attributeType.key} value={attributeType.key}>{attributeType.name}</Select.Option>
							})
						}
					</Select>
				</LabelledElement>

				<LabelledElement labelText="Visible">
					<Checkbox checked={value} onChange={(e) => {setValue(e.target.checked);}} />
				</LabelledElement>

			</div>

		</div>
	</Modal>
}

export default AddEditAttributeVisibilityDialog;