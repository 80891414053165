import { Select } from 'antd';
import React from 'react';
import * as boxTypeLib from '@lib/box/box-type';

export interface BoxTypeOptionsProps {
	boxTypes: boxTypeLib.BoxTypeMap;
	onMixinBoxTypesInputChange: (value: string[]) => void,
	selectedValues: string[]
};

const BoxTypeOptions: React.FC<BoxTypeOptionsProps> = (props) => {
	// Build the box type options
	const boxTypeOptions = Object.keys(props.boxTypes)
		.sort((boxTypeKeyA: string, boxTypeKeyB: string) => {
			// Get the names of the box types
			const boxTypeNameA = props.boxTypes[boxTypeKeyA].name;
			const boxTypeNameB = props.boxTypes[boxTypeKeyB].name;

			// If the order of box type A is before that of box type B, put box type A first
			if (boxTypeNameA < boxTypeNameB) {
				return -1;
			}

			// If the order of box type A is after that of box type B, put box type B first
			if (boxTypeNameA > boxTypeNameB) {
				return 1;
			}

			// Otherwise, don't change the order
			return 0;
		})
		.map((boxTypeKey) => {
			// Get the box type
			const optionBoxType = props.boxTypes[boxTypeKey];

			return (
				<Select.Option key={boxTypeKey} value={boxTypeKey}>
					{optionBoxType.name}
				</Select.Option>
			);
		});

	return <Select
		mode="multiple"
		size="large"
		allowClear
		placeholder="Please select"
		value={props.selectedValues}
		onChange={(value: string[]) =>
			props.onMixinBoxTypesInputChange(value)
		}
		filterOption={(inputValue, option) => {
			const optionBoxType = option && props.boxTypes && option.value !== undefined && option.value !== null ? props.boxTypes[option.value] : null;

			// Note: some of the names are numbers!
			// Convert it to a string just to be sure
			const optionBoxNameAsString = optionBoxType != null &&
				optionBoxType.name !== undefined &&
				optionBoxType.name !== null ? String(optionBoxType.name) : "";

			return optionBoxNameAsString.toLowerCase().includes(inputValue.toLowerCase());
		}}

		style={{ width: "100%" }}
		getPopupContainer={(node) => {
			let popupContainer: HTMLElement | null =
				window.document.documentElement;
			if (node && node.parentElement) {
				popupContainer = node.parentElement;
			}
			return popupContainer as HTMLElement;
		}}
	>{boxTypeOptions}				</Select>;

}
export default BoxTypeOptions;