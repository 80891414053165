import React, { useEffect, useState } from "react";

import { Modal, Input, Button, InputNumber, Tree, Select, Checkbox } from "antd";

import FormHeading from "@components/organisms/FormHeading";
import FormHeadingText from "@components/organisms/FormHeadingText";
import LabelledElement from "@components/molecules/LabelledElement";
import * as lensesLib from "@lib/lenses/lenses";
// import AddEditPowerLensDialog from "./add-edit-simple-lens-dialog/AddEditPowerLensDialog";
import { v4 as uuid } from "uuid";
import TextArea from "antd/lib/input/TextArea";
import { DataNode } from "antd/lib/tree";
import { Key } from "react";
import * as boxLib from "@lib/box/box";
import * as boxTypeLib from "@lib/box/box-type";

export interface AddRestrictValuePowerLensQueryDialogProps {
	onOKButtonClick: (query: lensesLib.Query) => void;
	onCancelButtonClick: (queryType: lensesLib.QueryType) => void;
	isVisible: boolean;
	isAdding: boolean;
	query: lensesLib.Query;
	flattenedBoxMap: boxLib.BoxMap;
	boxTypeMap: boxTypeLib.BoxTypeMap;
}

const getBoxTypeFromQuery = (query: lensesLib.Query, boxTypeMap: boxTypeLib.BoxTypeMap): string | undefined => {
	let value = undefined;


	const isRestrictingByBoxType = getRestrictByBoxTypeFromQuery(query);

	if (isRestrictingByBoxType && query.boxTypeExpressions) {
		const keys = Object.keys(query.boxTypeExpressions);

		if (keys.length > 0) {
			value = keys[0];
		}
	}
	// If we aren't restricting by box type
	else if (isRestrictingByBoxType === false) {
		const attributeTypeToFind = getAttributeTypeFromQuery(query);

		if (attributeTypeToFind) {
			// Try to find the first instance of the attribute in the box type map
			for (let boxTypeKey of Object.keys(boxTypeMap)) {
				const boxType = boxTypeMap[boxTypeKey];

				const indexOfAttributeType = Object.keys(boxType.attributeTypes).findIndex((attributeTypeKey) => {
					return attributeTypeKey === attributeTypeToFind;
				});

				// If we've found the attribute in the box type, set the box type key and break out of the loop
				if (indexOfAttributeType > -1) {
					value = boxTypeKey;
					break;
				}
			};
		}
	}

	return value;	
};

const getAttributeTypeFromQuery = (query: lensesLib.Query): string | undefined => {
	return  query.attributeExpression ? query.attributeExpression.attributeType : undefined;
};

const getOperatorFromQuery = (query: lensesLib.Query): lensesLib.AttributeExpressionOperator => {
	return  query.attributeExpression ? query.attributeExpression.operator : lensesLib.AttributeExpressionOperator.EQUAL;
};

const getAttributeValueFromQuery = (query: lensesLib.Query): string => {
	return  query.attributeExpression ? query.attributeExpression.attributeValue.toString() : "";
};

const getRestrictByBoxTypeFromQuery = (query: lensesLib.Query): boolean => {
	return query.boxTypeExpressions !== undefined && Object.keys(query.boxTypeExpressions).length > 0;
};


const AddEditRestrictValuePowerLensQueryDialog: React.FC<AddRestrictValuePowerLensQueryDialogProps> = (props) => {
	// Get the title
	const title = `${props.isAdding ? "Add" : "Edit"} Attribute Query`;

	const [name, setName] = useState(props.query.name);

	const [boxTypeKey, setBoxTypeKey] = useState<string | undefined>(getBoxTypeFromQuery(props.query, props.boxTypeMap));
	const [attributeTypeKey, setAttributeTypeKey] = useState<string | undefined>(getAttributeTypeFromQuery(props.query));

	const [attributeValue, setAttributeValue] = useState<string>(getAttributeValueFromQuery(props.query));
	const [operator, setOperator] = useState<lensesLib.AttributeExpressionOperator>(getOperatorFromQuery(props.query));

	const [restrictByBoxType, setRestrictByBoxType] = useState(getRestrictByBoxTypeFromQuery(props.query));

	// Add the useEffect to update stuff from props
	// Base it on the object and the key coming in.

	useEffect(() => {
		const copiedObject: lensesLib.Query = JSON.parse(JSON.stringify(props.query));
		setName(copiedObject.name);
		setBoxTypeKey(getBoxTypeFromQuery(props.query, props.boxTypeMap));
		setAttributeTypeKey(getAttributeTypeFromQuery(props.query));
		setOperator(getOperatorFromQuery(props.query));
		setAttributeValue(getAttributeValueFromQuery(props.query));
	}, [props.query, props.isVisible, props.boxTypeMap]);

	// Configure the state
	const handleOKButtonClick = () => {

		if (attributeTypeKey !== undefined && boxTypeKey !== undefined) {
			const copiedQuery: lensesLib.Query = JSON.parse(JSON.stringify(props.query));
			copiedQuery.name = name;
			copiedQuery.type = lensesLib.QueryType.ATTRIBUTE;
			copiedQuery.boxTypeExpressions = {};

			if (restrictByBoxType) {
				copiedQuery.boxTypeExpressions[boxTypeKey] = lensesLib.EqualityOperator.EQUAL;
			}

			copiedQuery.applyBoxTypeVisibility = true;
			copiedQuery.boxTypeIsVisible = true;
			copiedQuery.applyBoxVisibility = true;
			copiedQuery.boxIsInLayout = true;
			copiedQuery.boxTypeAreBoxesInLayout = true;
			copiedQuery.boxTypeAreBoxesVisible = true;
			copiedQuery.boxIsVisible = false;
			copiedQuery.attributeExpression = {
				operator: operator,
				attributeType: attributeTypeKey,
				attributeValue: attributeValue,
				attributeParameter: "",
				children: [],
				childrenExpressionOperator: lensesLib.BooleanOperator.AND,
				childrenOperator:lensesLib.BooleanOperator.AND,
			}

			props.onOKButtonClick(copiedQuery);
		}
	};

	const boxTypesArray = props.boxTypeMap === undefined ? [] : Object.keys(props.boxTypeMap)
		.map((boxTypeKey) => {
			return {
				key: boxTypeKey,
				name: props.boxTypeMap !== undefined &&
					props.boxTypeMap[boxTypeKey] !== undefined ?
					props.boxTypeMap[boxTypeKey].name : ""
			}
		})
		.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}

			if (a.name > b.name) {
				return 1;
			}

			return 0;
		});

	const attributeTypesArray = props.boxTypeMap === undefined || boxTypeKey === undefined ? [] :
		Object.keys(props.boxTypeMap[boxTypeKey].attributeTypes).map((attributeTypeKey) => {
			return {
				key: attributeTypeKey,
				name: props.boxTypeMap !== undefined &&
					boxTypeKey !== undefined &&
					props.boxTypeMap[boxTypeKey] !== undefined ?
					props.boxTypeMap[boxTypeKey].attributeTypes[attributeTypeKey].name : ""
			}
		});


	const newBoxValues: string[] = [];

	if (boxTypeKey !== undefined && attributeTypeKey !== undefined) {
		Object.keys(props.flattenedBoxMap).filter((boxkey) => {
			return props.flattenedBoxMap[boxkey].boxType === boxTypeKey;
		}).forEach((boxTypeKey) => {
			if (props.flattenedBoxMap[boxTypeKey] !== undefined) {
				const boxTypeObject = props.flattenedBoxMap[boxTypeKey];

				if (boxTypeObject.attributes !== undefined && boxTypeObject.attributes[attributeTypeKey] !== undefined) {

					const newValue = boxTypeObject.attributes[attributeTypeKey].toString();
					if (newBoxValues.indexOf(newValue) === -1) {
						newBoxValues.push(newValue);
					}
				}
			}
		});
	}

	newBoxValues.sort((a, b) => {
		if (a < b) {
			return -1;
		}

		if (a > b) {
			return 1;
		}

		return 0;
	});

	const okButtonDisabled = !attributeTypeKey || !boxTypeKey;


	return <Modal
		title={title}
		open={props.isVisible}
		onOk={handleOKButtonClick}
		okButtonProps={{disabled: okButtonDisabled}}
		onCancel={() => { props.onCancelButtonClick(lensesLib.QueryType.ATTRIBUTE); }}
		zIndex={9999}
		width="75%"
	>
		<div>
			<div
				style={{
					width: "100%",
					display: "grid",
					gridTemplateColumns: "0.1fr 0.9fr",
					gap: "4px 12px",
					padding: 0,
					margin: 0,
					overflowY: "visible",
				}}
			>
				<LabelledElement labelText="Name">
					<Input

						value={name}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>
						) => { setName(event.target.value) }}
					/>
				</LabelledElement>
				<LabelledElement labelText="Box Type">
					<Select
						key={`input-operator`}
						size="large"
						placeholder="Please select"
						value={boxTypeKey ? boxTypeKey : ""}
						onChange={(newKey: string) => {
							setAttributeTypeKey(undefined);
							setBoxTypeKey(newKey !== "" ? newKey : undefined);
						}}
						style={{ width: "100%" }}
						getPopupContainer={(node) => {
							let popupContainer: HTMLElement | null =
								window.document.documentElement;
							if (node && node.parentElement) {
								popupContainer = node.parentElement;
							}
							return popupContainer as HTMLElement;
						}}
					>
						<Select.Option key="" value="">- Please select -</Select.Option>

						{
							boxTypesArray.map((attributeType, index) => {
								return <Select.Option key={attributeType.key} value={attributeType.key}>{attributeType.name}</Select.Option>
							})
						}
					</Select>
				</LabelledElement>

				<LabelledElement labelText="Attribute Type">
					<Select
						key={`input-operator`}
						size="large"
						placeholder="Please select"
						value={attributeTypeKey ? attributeTypeKey : ""}
						onChange={(newKey: string) => {
							setAttributeTypeKey(newKey !== "" ? newKey : undefined);
						}}
						style={{ width: "100%" }}
						getPopupContainer={(node) => {
							let popupContainer: HTMLElement | null =
								window.document.documentElement;
							if (node && node.parentElement) {
								popupContainer = node.parentElement;
							}
							return popupContainer as HTMLElement;
						}}
					>
						<Select.Option key="" value="">- Please select -</Select.Option>

						{
							attributeTypesArray.map((attributeType, index) => {
								return <Select.Option key={attributeType.key} value={attributeType.key}>{attributeType.name}</Select.Option>
							})
						}
					</Select>
				</LabelledElement>

				<LabelledElement labelText="Comparison Operator">
					<Select
						key={`input-operator`}
						size="large"
						placeholder="Please select"
						value={operator}
						onChange={(newValue: lensesLib.AttributeExpressionOperator) => {
							setOperator(newValue);
						}}
						style={{ width: "100%" }}
						getPopupContainer={(node) => {
							let popupContainer: HTMLElement | null =
								window.document.documentElement;
							if (node && node.parentElement) {
								popupContainer = node.parentElement;
							}
							return popupContainer as HTMLElement;
						}}
					>
						<Select.Option value={lensesLib.AttributeExpressionOperator.EQUAL.toString()}>{lensesLib.AttributeExpressionOperator.EQUAL}</Select.Option>
						<Select.Option value={lensesLib.AttributeExpressionOperator.NOT_EQUAL.toString()}>{lensesLib.AttributeExpressionOperator.NOT_EQUAL}</Select.Option>
						<Select.Option value={lensesLib.AttributeExpressionOperator.TRUE.toString()}>{lensesLib.AttributeExpressionOperator.TRUE}</Select.Option>
						<Select.Option value={lensesLib.AttributeExpressionOperator.FALSE.toString()}>{lensesLib.AttributeExpressionOperator.FALSE}</Select.Option>
						<Select.Option value={lensesLib.AttributeExpressionOperator.INCLUDES.toString()}>{lensesLib.AttributeExpressionOperator.INCLUDES}</Select.Option>
						<Select.Option value={lensesLib.AttributeExpressionOperator.EXCLUDES.toString()}>{lensesLib.AttributeExpressionOperator.EXCLUDES}</Select.Option>
						<Select.Option value={lensesLib.AttributeExpressionOperator.GREATER_THAN.toString()}>{lensesLib.AttributeExpressionOperator.GREATER_THAN}</Select.Option>
						<Select.Option value={lensesLib.AttributeExpressionOperator.GREATER_THAN_OR_EQUAL_TO.toString()}>{lensesLib.AttributeExpressionOperator.GREATER_THAN_OR_EQUAL_TO}</Select.Option>
						<Select.Option value={lensesLib.AttributeExpressionOperator.LESS_THAN.toString()}>{lensesLib.AttributeExpressionOperator.LESS_THAN}</Select.Option>
						<Select.Option value={lensesLib.AttributeExpressionOperator.LESS_THAN_OR_EQUAL_TO.toString()}>{lensesLib.AttributeExpressionOperator.LESS_THAN_OR_EQUAL_TO}</Select.Option>
					</Select>
				</LabelledElement>

				<LabelledElement labelText="Value">
					<Input value={attributeValue} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setAttributeValue(event.target.value) }} />
				</LabelledElement>

				<LabelledElement labelText="Restrict By Box Type">
					<Checkbox checked={restrictByBoxType} onChange={(e) => setRestrictByBoxType(e.target.checked)}/>
				</LabelledElement>

			</div>
		</div>
	</Modal>
}

export default AddEditRestrictValuePowerLensQueryDialog;