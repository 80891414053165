import * as React from "react";

import { Key } from "rc-tree/lib/interface";

import { Tree } from "antd";
import {
	DataNode,
	EventDataNode,
} from "antd/lib/tree";

import * as boxLib from "@lib/box/box";

const TreeNode = Tree.TreeNode;

const SHOW_BOXES_SUFFIX = "show-boxes";
const KEEP_BOXES_SUFFIX = "keep-boxes";

interface CheckInfo {
	event: "check";
	node: EventDataNode<any>;
	checked: boolean;
	nativeEvent: MouseEvent;
	checkedNodes: DataNode[];
	checkedNodesPositions?: {
		node: DataNode;
		pos: string;
	}[];
	halfCheckedKeys?: Key[];
}

interface BoxTreeViewProps {
	onBoxVisibilityOrLayoutChange?: (
		boxKey: string,
		isVisible: boolean,
		isInLayout: boolean
	) => void;
	onBoxVisibilityChange?: (boxKey: string, isVisible: boolean) => void;
	boxes?: boxLib.BoxMap;
	boxVisibilityMap?: boxLib.BoxVisibilityMap;
	textColor: string;
	editVisibility: boolean;
	// Only used when not editing visibility
	selectedBoxKeys?: string[];
}

export default class BoxTreeView extends React.Component<BoxTreeViewProps> {
	state = {
		expandedKeys: [],
		autoExpandParent: true,
		selectedKeys: [],
	};

	onExpand = (expandedKeys: any) => {
		//console.log('onExpand', arguments);
		// if not set autoExpandParent to false, if children expanded, parent can not collapse.
		// or, you can remove all expanded children keys.
		this.setState({
			expandedKeys,
			autoExpandParent: false,
		});
	};

	private onCheck = (
		checkedKeys:
			| Key[]
			| {
					checked: (string | number)[];
					halfChecked: (string | number)[];
			  },
		info: CheckInfo
	) => {
		const key = String(info.node.key) || "";
		const checked = info.checked || false;

		var splitKey = key.split("_");

		// Do we have keys?
		if (splitKey.length > 0) {
			// Get the box key
			const boxKey = splitKey[0];

			// If we have a length of 2 then we need to figure out what we've clicked on
			if (splitKey.length === 2) {
				const suffix = splitKey[1];

				if (suffix === KEEP_BOXES_SUFFIX) {
					// console.log("keep boxes header");

					if (this.props.onBoxVisibilityOrLayoutChange) {
						this.props.onBoxVisibilityOrLayoutChange(
							boxKey,
							checked,
							checked
						);
					}
				} else if (suffix === SHOW_BOXES_SUFFIX) {
					// console.log("show boxes header");

					if (this.props.onBoxVisibilityOrLayoutChange) {
						this.props.onBoxVisibilityOrLayoutChange(
							boxKey,
							checked,
							true
						);
					}
				}
			}
			// Otherwise it's going to have a length of 1, which means we've clicked
			// on a box
			else {
				// Notify the callback that the box visibility has changed
				if (this.props.onBoxVisibilityChange) {
					this.props.onBoxVisibilityChange(boxKey, checked);
				}
			}
		}
	};

	private onSelect = (
		selectedKeys: Key[],
		info: {
			event: "select";
			selected: boolean;
			node: EventDataNode<any>;
			selectedNodes: DataNode[];
			nativeEvent: MouseEvent;
		}
	) => {
		const expandedKeys = JSON.parse(
			JSON.stringify(this.state.expandedKeys)
		);
		
		// TODO: We may need to do a comparison in the next bit of code against the current key.
		// To be tested
		// const key = String(info.node.key);

		// If the selected key is already expanded, unexpand it, otherwise expand it
		selectedKeys.forEach((key: Key) => {
			const keyIndex = expandedKeys.indexOf(key);
			if (keyIndex > -1) {
				expandedKeys.splice(keyIndex, 1);
			} else {
				expandedKeys.push(key);
			}
		});

		this.setState({ expandedKeys });
	};

	renderTreeNodes = (box: any, boxKey: string) => {
		// Get the box name
		const boxName = box.name ? box.name : "Container";

		// Get the box visibility
		const boxVisibility = this.props.boxVisibilityMap
			? this.props.boxVisibilityMap[boxKey]
			: undefined;

		// Disable the visibility checkboxes if we don't have a box visibility
		const areVisibilityCheckboxesDisabled = !boxVisibility;

		// Render the boxes children as child nodes
		let renderedBoxChildTreeNodes = undefined;
		if (box.children) {
			renderedBoxChildTreeNodes = Object.keys(box.children).map(
				(childBoxKey) => {
					return this.renderTreeNodes(
						box.children[childBoxKey],
						childBoxKey
					);
				}
			);
		}

		return (
			<TreeNode
				key={boxKey}
				title={
					<span
						style={{
							color: this.props.textColor,
						}}
					>
						{boxName}
					</span>
				}
				disabled={false}
			>
				{this.props.editVisibility && (
					<React.Fragment>
						<TreeNode
							key={boxKey + "_" + SHOW_BOXES_SUFFIX}
							title={
								<span
									style={{
										color: this.props.textColor,
									}}
								>
									Show Box
								</span>
							}
							disabled={areVisibilityCheckboxesDisabled}
							style={{
								color: this.props.textColor,
							}}
						/>
						<TreeNode
							key={boxKey + "_" + KEEP_BOXES_SUFFIX}
							title={
								<span
									style={{
										color: this.props.textColor,
									}}
								>
									Keep Hidden Box In Illustration
								</span>
							}
							disabled={areVisibilityCheckboxesDisabled}
							style={{
								color: this.props.textColor,
							}}
						/>
					</React.Fragment>
				)}
				{renderedBoxChildTreeNodes}
			</TreeNode>
		);
	};

	buildCheckedKeys = (
		boxMap: boxLib.BoxMap | undefined,
		boxKeys: string[],
		boxVisibilityMap: boxLib.BoxVisibilityMap | undefined
	) => {
		// If we don't have a box map there's nothing to do
		if (!boxMap) {
			return [];
		}

		// The checked keys
		let checkedKeys: string[] = [];

		// Build the checked keys of each box
		boxKeys.forEach((boxKey: any) => {
			// Get the box
			const box = boxMap[boxKey];
			if (!box) {
				// If we don't have a box there's nothing to do
				return;
			}

			// Are we editing the visibility?
			if (this.props.editVisibility) {
				// Get the box visibility
				const boxVisibility = boxVisibilityMap
					? boxVisibilityMap[boxKey]
					: undefined;
				if (boxVisibility) {
					// Is the box visible and in the layout?
					if (boxVisibility.isVisible && boxVisibility.isInLayout) {
						checkedKeys.push(boxKey);
					}

					// Is the box visible?
					if (boxVisibility.isVisible) {
						checkedKeys.push(boxKey + "_" + SHOW_BOXES_SUFFIX);
					}

					// Is the box in the layout?
					if (boxVisibility.isInLayout) {
						checkedKeys.push(boxKey + "_" + KEEP_BOXES_SUFFIX);
					}
				}
			} else {
				if (this.props.selectedBoxKeys) {
					if (
						this.props.selectedBoxKeys.findIndex(
							(k: string) => k === boxKey
						) >= 0
					) {
						checkedKeys.push(boxKey);
					}
				}
			}

			// Does the box have children?
			if (box.children) {
				// Get the keys of each of the boxes children
				const boxChildKeys = Object.keys(box.children);

				// Get the checked keys of the box children
				const boxChildCheckedKeys = this.buildCheckedKeys(
					box.children,
					boxChildKeys,
					boxVisibilityMap
				);

				// Add the box child checked keys to our checked keys
				checkedKeys = checkedKeys.concat(boxChildCheckedKeys);
			}
		});

		return checkedKeys;
	};

	render() {
		// Get the box keys
		const boxKeys = this.props.boxes ? Object.keys(this.props.boxes) : [];

		// Build the checked keys
		const checkedKeys: string[] = this.buildCheckedKeys(
			this.props.boxes,
			boxKeys,
			this.props.boxVisibilityMap
		);

		return this.props.boxes ? (
			<Tree
				checkable
				checkStrictly
				onExpand={this.onExpand}
				expandedKeys={this.state.expandedKeys}
				autoExpandParent={this.state.autoExpandParent}
				onCheck={this.onCheck}
				checkedKeys={checkedKeys}
				onSelect={this.onSelect}
				selectedKeys={this.state.selectedKeys}
				style={{
					color: this.props.textColor,
				}}
			>
				{this.renderTreeNodes(this.props.boxes.root, "root")}
			</Tree>
		) : null;
	}
}
