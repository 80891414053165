import { findDOMNode } from "react-dom";

import {
	ConnectDragSource,
	DragSourceSpec,
	DragSourceConnector,
	DragSourceMonitor,
	DragSourceCollector,
	ConnectDropTarget,
	DropTargetSpec,
	DropTargetConnector,
	DropTargetMonitor,
	DropTargetCollector,
	ConnectDragPreview,
} from "react-dnd";
import { XYCoord } from "dnd-core";

import { getBoxProperties } from "@lib/box/box";
import * as boxTypeLib from '@lib/box/box-type';
import { ChildLayout } from "@lib/box/box-properties";

import { calculateBoxDimensionsInfo, LayoutItem } from "@lib/layout/layout";

import {
	BoxBaseProps,
	BoxBase,
} from './BoxBase'

export const DragTypes = {
	BOX: "box",
};

export interface BoxDragSourceCollectedProps {
	isDragging: boolean;
	connectDragSource: ConnectDragSource;
	connectDragPreview: ConnectDragPreview;
}

export interface BoxDropTargetCollectedProps {
	connectDropTarget: ConnectDropTarget;
	isOver: boolean;
	isOverCurrent: boolean;
	canDrop: boolean;
}

export type BoxDragDropProps = Omit<BoxBaseProps, 'onRender'>
export const BoxDragDrop = BoxBase

interface BoxDraggingItem {
	boxKey: string;
	boxParentKey: string;
}

/**
 * Specifies the drag source contract.
 * Only `beginDrag` function is required.
 */
 export const boxDragSource: DragSourceSpec<BoxDragDropProps> = {
	canDrag(props: BoxDragDropProps) {
		// Notify the called whether we can drag
		return props.canDrag;
	},

	isDragging(props: BoxDragDropProps, monitor: DragSourceMonitor<BoxDragDropProps>) {
		// If your component gets unmounted while dragged
		// (like a card in Kanban board dragged between lists)
		// you can implement something like this to keep its
		// appearance dragged:
		return monitor.getItem().boxKey === props.boxKey;
	},

	beginDrag(props: BoxDragDropProps, monitor: DragSourceMonitor) {
		// Return the data describing the dragged item
		const item: BoxDraggingItem = {
			boxKey: props.boxKey,
			boxParentKey: props.boxParentKey,
		};

		props.onBoxDragStart(props.boxKey, props.boxParentKey);

		return item;
	},

	endDrag(props: BoxDragDropProps, monitor: DragSourceMonitor) {
		if (!monitor.didDrop()) {
			// You can check whether the drop was successful
			// or if the drag ended but nobody handled the drop
			props.onBoxDragCancel();
		}
	},
};

/**
 * Specifies which props to inject into your component.
 */
 export const boxDragSourceCollect: DragSourceCollector<BoxDragSourceCollectedProps, BoxDragDropProps> = (
	connect: DragSourceConnector,
	monitor: DragSourceMonitor
) => {
	return {
		// Call this function inside render()
		// to let React DnD handle the drag events:
		connectDragSource: connect.dragSource(),
		connectDragPreview: connect.dragPreview(),
		// You can ask the monitor about the current drag state:
		isDragging: monitor.isDragging(),
	};
};

const getBoxDragInfo = (
	boxKey: string,
	boxParentKey: string,
	boxRowBoxCounts: number[],
	boxChildLayoutItems: LayoutItem[],
	boxChildLayout: ChildLayout,
	draggingItem: BoxDraggingItem,
	component: BoxBase,
	clientOffset: XYCoord | null
): any => {
	// The box drag info
	let boxDragInfo: any = null;

	// Get the DOM node of the component
	const domNode: Element | null = findDOMNode(component) as Element;
	if (domNode) {
		// Determine rectangle on screen
		const hoverBoundingRect = domNode.getBoundingClientRect();

		// Get client position of the item
		const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;
		const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

		const hoverWidth = hoverBoundingRect.right - hoverBoundingRect.left;
		const hoverHeight = hoverBoundingRect.bottom - hoverBoundingRect.top;

		// Get the position of the item in the layout
		const layoutX = hoverClientX / hoverWidth;
		const layoutY = hoverClientY / hoverHeight;

		const layoutPosition = boxChildLayout === ChildLayout.HORIZONTAL
			? layoutX
			: layoutY;

		// Get the key of the box being dragged
		const draggingBoxKey = draggingItem.boxKey;

		// Get the position of the box being dragged
		let draggingBoxPosition = layoutPosition * 100;
		if (boxChildLayout === ChildLayout.GRID) {
			let visibleRowCounts = 0;
			boxRowBoxCounts
				.forEach((rowLayoutItemCount: number) => {
					let currentLayoutItemIndex = 0;

					for(let i=0; i < rowLayoutItemCount; i += 1) {
						const rowLayoutItemIndex = currentLayoutItemIndex + i;
						const rowLayoutItem = boxChildLayoutItems[rowLayoutItemIndex];
						if (rowLayoutItem.w > 0) {
							visibleRowCounts += 1;
							break;
						} 
					}
				});

			const draggingBoxX = layoutX * 100;
			const draggingBoxY = layoutY * 100;
			const draggingRowHeight = 100 / visibleRowCounts;

			draggingBoxPosition = draggingBoxX + (100 * Math.floor(draggingBoxY / draggingRowHeight));

			// console.log(`cx: ${hoverClientX}, cy: ${hoverClientY}, dx: ${draggingBoxX}, dy: ${draggingBoxY}, rows=${boxRowBoxCounts.length}, ${draggingBoxPosition}`);
		}

		let layoutItemColumn = 0;
		let layoutItemRow = 0;

		const boxPositions = boxChildLayoutItems.reduce(
			(
				reducedBoxPositions: any,
				boxLayout: LayoutItem,
			) => {
				// Get the box key
				const boxKey = boxLayout.i;

				if (layoutItemRow >= boxRowBoxCounts.length) {
					console.log(`error, could not handle ${boxKey}`);
					return null;
				}

				const currentRowItemCount = boxRowBoxCounts[layoutItemRow];

				// Horizontal and Grid layouts use the box width as the length
				let boxLength = boxLayout.w;
				if (boxChildLayout === ChildLayout.VERTICAL) {
					boxLength = boxLayout.h;
				}

				// Ignore the box being dragged
				if ((boxKey !== draggingBoxKey) && (boxLength > 0)) {
					// Get the box position (right for horizontal layouts, bottom for vertical)
					let startPosition = boxLayout.x;
					if (boxChildLayout === ChildLayout.VERTICAL) {
						startPosition = boxLayout.y;
					} else if (boxChildLayout === ChildLayout.GRID) {
						startPosition = boxLayout.x + (layoutItemRow * 100);
					}
					const midPosition = startPosition + (boxLength / 2);
					const stopPosition = startPosition + boxLength;

					// Add the box to our positions
					reducedBoxPositions[boxKey] = {
						startPosition,
						midPosition,
						stopPosition,
					};
				}

				layoutItemColumn += 1;
				if (layoutItemColumn >= currentRowItemCount) {
					layoutItemColumn = 0;
					layoutItemRow += 1;
				}

				return reducedBoxPositions;
			},
			{}
		);

		// console.log('boxPositions', boxPositions)

		// Sort the box keys
		const sortedBoxKeys = Object.keys(boxPositions).sort(
			(boxKeyA: string, boxKeyB) => {
				return boxPositions[boxKeyA].midPosition - boxPositions[boxKeyB].midPosition;
			}
		);

		const finalSortedBoxKeys = JSON.parse(JSON.stringify(sortedBoxKeys));

		for (var boxKeyIndex = 0; boxKeyIndex < sortedBoxKeys.length; boxKeyIndex += 1) {
			const boxKey = sortedBoxKeys[boxKeyIndex];
			const boxPosition = boxPositions[boxKey];

			// console.log(`${draggingBoxPosition} and ${boxPosition.midPosition}`);

			if (draggingBoxPosition <= boxPosition.midPosition) {
				if (boxKeyIndex > 0) {
					const lastBoxKeyIndex = boxKeyIndex - 1;
					const lastBoxKey = sortedBoxKeys[lastBoxKeyIndex];
					const lastBoxPosition = boxPositions[lastBoxKey];

					if (draggingBoxPosition > lastBoxPosition.midPosition) {
						// Insert the dragged box before the current box
						// console.log(`insert between ${lastBoxKeyIndex} and ${boxKeyIndex}`);
						finalSortedBoxKeys.splice(boxKeyIndex, 0, draggingBoxKey);	
						break;
					}
				} else if (boxKeyIndex === 0) {
					// If we're dealing with the first box, insert the dragged box at the start.
					// console.log(`insert at start`);
					finalSortedBoxKeys.splice(0, 0, draggingBoxKey);
					break;
				}
			} else {
				if (boxKeyIndex === sortedBoxKeys.length - 1) {
					// Insert the dragged box after the current box
					// console.log(`insert at end`);
					finalSortedBoxKeys.splice(sortedBoxKeys.length, 0, draggingBoxKey);	
					break;
				}
			}
		}

		// console.log('sortedBoxKeys', sortedBoxKeys)
		// console.log('finalSortedBoxKeys', finalSortedBoxKeys)

		// Set up the drag info
		boxDragInfo = {
			draggingItemBoxKey: draggingBoxKey,
			draggingItemBoxParentKey: draggingItem.boxParentKey,
			boxKey: boxKey,
			boxParentKey: boxParentKey,
			sortedBoxKeys: finalSortedBoxKeys,
		};
	}

	return boxDragInfo;
};

export const boxDropTarget: DropTargetSpec<BoxDragDropProps> = {
	canDrop(props: BoxDragDropProps, monitor: DropTargetMonitor<BoxDragDropProps>) {
		// Only handle the hover if we're over the current target
		if (!monitor.isOver({ shallow: true })) {
			return false;
		}

		// Obtain the item being dragged
		const draggingItem = monitor.getItem();

		// Ask the callback if the box can be dropped
		return props.onBoxCanDrop(
			draggingItem.boxKey,
			draggingItem.boxParentKey,
			props.boxKey,
			props.boxParentKey
		);
	},

	hover(props: BoxDragDropProps, monitor: DropTargetMonitor, component: BoxBase) {
		// Don't do anything if we don't have a component
		if (!component || !props.box) {
			return null;
		}

		// Only handle the hover if we're over the current target
		if (!monitor.isOver({ shallow: true })) {
			return null;
		}

		const {
			widthInPixels,
			heightInPixels,
			horizontalBoxGapInPixels,
			verticalBoxGapInPixels,
			boxKey,
			boxParentKey,
			box,
			boxTypes,
			boxTypeVisibilityMap,
			boxVisibilityMap,
			boxVisualisations,
			boxStyles,
			boxParentTypeKey,
			boxParentProperties,
			boxParentAttributes,
			boxAssociationsMap,
			currentDragSourceBoxKey,
			currentlyHighlightedDropTargetBoxKey,
			canHighlightAssociations,
			illustrationFlattenedBoxMap
		} = props;

		// Obtain the item being dragged
		const draggingItem: BoxDraggingItem = monitor.getItem();

		// Determine mouse position
		const clientOffset = monitor.getClientOffset();

		const boxParentAttributeTypes = boxTypeLib
			.getBoxTypeAttributeTypeCacheForType(boxParentTypeKey);

		// The box properties
		const boxProperties = getBoxProperties(boxKey,
			box,
			boxTypes,
			boxTypeVisibilityMap,
			{},
			{},
			boxStyles,
			boxParentKey,
			boxParentTypeKey,
			boxParentProperties,
			boxParentAttributeTypes,
			boxParentAttributes,
			boxAssociationsMap,
			'',
			'',
			{},
			{},
			currentlyHighlightedDropTargetBoxKey,
			canHighlightAssociations,
			illustrationFlattenedBoxMap);

		const childLayout = boxProperties.childLayout;

		let maximumGridColumns = 1;
		try {
			if (boxProperties.maximumGridColumns) {
				maximumGridColumns = parseInt(boxProperties.maximumGridColumns);
			}
		} catch (e) {}

		const boxes = (props.box.children) ? props.box.children : {};
		const boxKeys = Object.keys(boxes);
		const boxesKey = boxKey;

		const boxDimensionsInfo = calculateBoxDimensionsInfo(childLayout,
			widthInPixels,
			heightInPixels,
			horizontalBoxGapInPixels,
			verticalBoxGapInPixels,
			maximumGridColumns,
			boxKeys,
			boxesKey,
			boxes,
			boxTypes,
			boxTypeVisibilityMap,
			boxVisibilityMap,
			boxVisualisations,
			boxStyles,
			boxParentKey,
			boxParentTypeKey,
			boxParentProperties,
			boxParentAttributeTypes,
			boxParentAttributes,
			boxAssociationsMap,
			currentDragSourceBoxKey,
			currentlyHighlightedDropTargetBoxKey,
			canHighlightAssociations,
			illustrationFlattenedBoxMap);
		
		const boxRowBoxCounts = boxDimensionsInfo.boxRowBoxCounts;
		const boxChildLayoutItems = boxDimensionsInfo.layoutItems;

		// Get the box drag info
		const boxDragInfo: any = getBoxDragInfo(
			boxKey,
			boxParentKey,
			boxRowBoxCounts,
			boxChildLayoutItems,
			childLayout,
			draggingItem,
			component,
			clientOffset
		);

		const dragX = (clientOffset) ? clientOffset.x : -1;
		const dragY = (clientOffset) ? clientOffset.y : -1;

		// Do we have box drag info
		if (boxDragInfo) {
			// Notify that the box has been dragged and is hovering
			props.onBoxDragHover(
				boxDragInfo.draggingItemBoxKey,
				boxDragInfo.draggingItemBoxParentKey,
				boxDragInfo.boxKey,
				boxDragInfo.boxParentKey,
				boxDragInfo.sortedBoxKeys,
				dragX,
				dragY
			);
		}
	},

	drop(props: BoxDragDropProps, monitor: DropTargetMonitor, component: BoxBase) {
		// Don't do anything if we don't have a component
		if (!component || !props.box) {
			return null;
		}

		// Have we already dropped?
		if (monitor.didDrop()) {
			// If you want, you can check whether some nested
			// target already handled drop
			return;
		}

		const {
			widthInPixels,
			heightInPixels,
			horizontalBoxGapInPixels,
			verticalBoxGapInPixels,
			boxKey,
			boxParentKey,
			box,
			boxTypes,
			boxTypeVisibilityMap,
			boxVisibilityMap,
			boxVisualisations,
			boxStyles,
			boxParentTypeKey,
			boxParentProperties,
			boxParentAttributes,
			boxAssociationsMap,
			currentDragSourceBoxKey,
			currentlyHighlightedDropTargetBoxKey,
			canHighlightAssociations,
			illustrationFlattenedBoxMap,
		} = props;

		// Obtain the dragged item
		const draggingItem: BoxDraggingItem = monitor.getItem();

		// Determine mouse position
		const clientOffset = monitor.getClientOffset();

		const boxParentAttributeTypes = boxTypeLib
			.getBoxTypeAttributeTypeCacheForType(boxParentTypeKey);

		// The box properties
		const boxProperties = getBoxProperties(boxKey,
			box,
			boxTypes,
			boxTypeVisibilityMap,
			{},
			{},
			boxStyles,
			boxParentKey,
			boxParentTypeKey,
			boxParentProperties,
			boxParentAttributeTypes,
			boxParentAttributes,
			boxAssociationsMap,
			'',
			'',
			{},
			{},
			currentlyHighlightedDropTargetBoxKey,
			canHighlightAssociations,
			illustrationFlattenedBoxMap);

		const childLayout = boxProperties.childLayout;

		let maximumGridColumns = 1;
		try {
			if (boxProperties.maximumGridColumns) {
				maximumGridColumns = parseInt(boxProperties.maximumGridColumns);
			}
		} catch (e) {}

		const boxes = (props.box.children) ? props.box.children : {};
		const boxKeys = Object.keys(boxes);
		const boxesKey = boxKey;

		const boxDimensionsInfo = calculateBoxDimensionsInfo(childLayout,
			widthInPixels,
			heightInPixels,
			horizontalBoxGapInPixels,
			verticalBoxGapInPixels,
			maximumGridColumns,
			boxKeys,
			boxesKey,
			boxes,
			boxTypes,
			boxTypeVisibilityMap,
			boxVisibilityMap,
			boxVisualisations,
			boxStyles,
			boxParentKey,
			boxParentTypeKey,
			boxParentProperties,
			boxParentAttributeTypes,
			boxParentAttributes,
			boxAssociationsMap,
			currentDragSourceBoxKey,
			currentlyHighlightedDropTargetBoxKey,
			canHighlightAssociations,
			illustrationFlattenedBoxMap);
		
		const boxRowBoxCounts = boxDimensionsInfo.boxRowBoxCounts;
		const boxChildLayoutItems = boxDimensionsInfo.layoutItems;

		// Get the box drag info
		const boxDragInfo: any = getBoxDragInfo(
			boxKey,
			boxParentKey,
			boxRowBoxCounts,
			boxChildLayoutItems,
			childLayout,
			draggingItem,
			component,
			clientOffset
		);

		// Do we have box drag info
		if (boxDragInfo) {
			// console.log({boxDragInfo})

			// Notify that the box has been dragged and dropped
			props.onBoxDragDrop(
				boxDragInfo.draggingItemBoxKey,
				boxDragInfo.draggingItemBoxParentKey,
				boxDragInfo.boxKey,
				boxDragInfo.boxParentKey,
				boxDragInfo.sortedBoxKeys
			);

			return { moved: true };
		}

		return {};
	},
};

export const boxDropTargetCollect: DropTargetCollector<BoxDropTargetCollectedProps, BoxDragDropProps> = (
	connect: DropTargetConnector,
	monitor: DropTargetMonitor
) => {
	return {
		// Call this function inside render()
		// to let React DnD handle the drag events:
		connectDropTarget: connect.dropTarget(),
		// You can ask the monitor about the current drag state:
		isOver: monitor.isOver(),
		isOverCurrent: monitor.isOver({ shallow: true }),
		canDrop: monitor.canDrop(),
	};
};
