import React from "react";
import { FileAddOutlined } from "@ant-design/icons";
import { Table, Divider, Modal, Card, Layout, notification } from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons';

import roleService from "../../../../services/role";
import { Role, makeRole } from "@contracts/role";
import * as authorizationService from "../../../../services/authorization";
import AddEditRoleDialog from "./AddEditRoleDialog";

import { renderBreadcrumbsInPortal } from "../../../organisms/Breadcrumbs";

import { withRouter } from '../../../../routes/withRouter';


const confirm = Modal.confirm;
export interface ListRolesPageProps {
	match: any;
	abilities: authorizationService.AbilitiesMap | undefined;
}

export interface ListRolesPageState {
	roles: Role[];
	modalRole: Role | null;
	isEditRoleModalVisible: boolean;
}

class ListRolesPageC extends React.Component<
	ListRolesPageProps,
	ListRolesPageState
> {
	private columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "_id",
			render: (text: any, record: any) => {
				console.log(text);

				// Get the client key
				const clientKey = "*";

				return authorizationService.renderIfAbilityPermitted(
					this.props.abilities,
					authorizationService.AbilityType.RoleView,
					clientKey,
					() => <span>{text}</span>
				);
			},
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
			render: (text: any, record: any) => {
				// Get the client key
				const clientKey = "*";

				return authorizationService.renderIfAbilityPermitted(
					this.props.abilities,
					authorizationService.AbilityType.RoleView,
					clientKey,
					() => <span>{text}</span>
				);
			},
		},
		{
			title: "Abilities",
			dataIndex: "abilities",
			key: "abilities",
			render: (text: string[], record: any) => {
				// Get the client key
				const clientKey = "*";

				return authorizationService.renderIfAbilityPermitted(
					this.props.abilities,
					authorizationService.AbilityType.RoleView,
					clientKey,
					() => <span>{text.join(", ")}</span>
				);
			},
		},
		{
			title: "Action",
			key: "action",
			render: (text: any, record: any) => {
				// Get the client key
				const domainKey = "*";

				return (
					<span>
						{authorizationService.renderIfAbilityPermitted(
							this.props.abilities,
							authorizationService.AbilityType.RoleEdit,
							domainKey,
							() => (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								<a
									onClick={(e) =>
										this.handleEditRoleClick(record, e)
									}
								>
									Edit
								</a>
							)
						)}
						{authorizationService.renderIfAllAbilitiesPermitted(
							this.props.abilities,
							[
								authorizationService.AbilityType.RoleEdit,
								authorizationService.AbilityType.RoleDelete,
							],
							domainKey,
							() => (
								<Divider type="vertical" />
							)
						)}
						{authorizationService.renderIfAbilityPermitted(
							this.props.abilities,
							authorizationService.AbilityType.RoleDelete,
							domainKey,
							() => (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								<a
									onClick={(e) =>
										this.handleRemoveRoleClick(record, e)
									}
								>
									Delete
								</a>
							)
						)}
					</span>
				);
			},
		},
	];

	private refreshRoleList = () => {
		roleService.getList((roles) => {
			if (roles) {
				console.log("ListRolesPage");
				console.log(this.props.abilities);
				console.log(roles);

				this.setState({ roles: roles });
			}
		}, (message)=>{
			console.log(`Error occurred: ${message}.`);
			notification.open({
				message: 'Error: Could not load Role List',
				description:
					`Failed to load Role List.`,
				icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
			});
		});
	};

	private handleRemoveRoleClick = (role: any, e: any) => {
		confirm({
			title: "Are you sure delete this role?",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				roleService.remove(role._id, (data: any) => {
					this.refreshRoleList();
				}, (message)=>{
					console.log(`Error occurred: ${message}.`);
					notification.open({
						message: 'Error: Could not delete Role',
						description:
							`Failed to delete Role Id ${role._id}.`,
						icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
					});
				});
			},
			onCancel: () => {},
		});
	};

	constructor(props: ListRolesPageProps) {
		super(props);
		this.state = {
			roles: [],
			modalRole: null,
			isEditRoleModalVisible: false,
		};
	}

	componentDidMount() {
		this.refreshRoleList();
	}

	private renderBreadcrumbs = () => {
		const BREADCRUMBS = [
			{
				onGetPath: (params: any) => "/security/roles/",
				onGetDisplayName: (params: any) => "Roles",
			},
		];

		return renderBreadcrumbsInPortal(BREADCRUMBS, this.props.match.params);
	};

	public render() {
		console.log("render");
		console.log(this.state.roles);

		const breadcrumbs = this.renderBreadcrumbs();

		return (
			<Layout
				style={{
					width: "100%",
					height: "100%",
				}}
			>
				{breadcrumbs}
				<Layout.Content>
					<Card
						title="All Roles"
						style={{ margin: 16 }}
						actions={[
							<FileAddOutlined
								onClick={this.handleAddRoleClick}
							/>,
						]}
					>
						<Table
							columns={this.columns}
							rowKey="_id"
							pagination={false}
							dataSource={this.state.roles}
						/>
					</Card>
					{this.state.modalRole ? (
						<AddEditRoleDialog
							onOKButtonClick={this.handleEditRoleModalOK}
							onCancelButtonClick={this.handleEditRoleCancel}
							isVisible={this.state.isEditRoleModalVisible}
							role={this.state.modalRole}
						/>
					) : null}
				</Layout.Content>
			</Layout>
		);
	}
	private handleEditRoleModalOK = (editedRole: any) => {
		// If we had an ID then we are updating
		if (editedRole._id) {
			roleService.put(editedRole._id, editedRole, (res: any) => {
				this.refreshRoleList();
				// Update our state and close the box modal dialog
				this.setState({
					modalRole: null,
					isEditRoleModalVisible: false,
				});
			}, (message)=>{
				console.log(`Error occurred: ${message}.`);
				notification.open({
					message: 'Error: Could not edit Role',
					description:
						`Failed to edit Role Id ${editedRole._id}.`,
					icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
				});
			});
		}
		// Otherwise we are creating a new record
		else {
			roleService.post(editedRole, (res: any) => {
				this.refreshRoleList();
				// Update our state and close the box modal dialog
				this.setState({
					modalRole: null,
					isEditRoleModalVisible: false,
				});
			}, (message)=>{
				console.log(`Error occurred: ${message}.`);
				notification.open({
					message: 'Error: Could not create Role',
					description:
						`Failed to create new Role.`,
					icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
				});
			});
		}
	};

	private handleEditRoleCancel = () => {
		// Close the box modal dialog
		this.setState({
			modalRole: null,
			isEditRoleModalVisible: false,
		});
	};

	private handleEditRoleClick = (role: any, e: any) => {
		// Open the box modal dialog
		this.setState({
			modalRole: role,
			isEditRoleModalVisible: true,
		});
	};
	private handleAddRoleClick = () => {
		// Open the box modal dialog
		this.setState({
			modalRole: makeRole(),
			isEditRoleModalVisible: true,
		});
	};
}

export const ListRolesPage = withRouter(ListRolesPageC);
