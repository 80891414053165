import React from "react";
import { Modal, Button } from "antd";

interface EULADialogProps {
	onAcceptButtonClick: () => void,
	isVisible: boolean,
};

interface EULADialogState {
};

export default class EULADialog extends React.Component<EULADialogProps, EULADialogState> {
	public render() {
		return (
			<Modal
				title={'Enterprise Lens End User Licence Agreement ("Agreement")'}
				open={this.props.isVisible}
				width={"50%"}
				zIndex={9999}
				closable={false}
				footer={[
					<Button key="accept" onClick={this.props.onAcceptButtonClick}>
						Accept
					</Button>,
				]}
			>
				<div
					style={{
						marginBottom: 16,
						width: "100%",
						height: "800px",
						overflowY: "auto"
					}}
				>
	End User Licence Agreement<br />
These licence terms are an agreement between You and Jakobite Pty Ltd ABN 22 618 087 844 trading as Enterprise Lens, with place of business at 161 The Boulevard Floreat, WA, 6014, AUSTRALIA ( referred to herein as &ldquo;Enterprise Lens&rdquo;, &ldquo;Jakobite&rdquo;, &ldquo;Us&rdquo;, &ldquo;We&rdquo;, or &ldquo;Our&rdquo;). These terms apply to and govern the use of the Enterprise Lens software and its associated documentation, the media on which You receive it, if any, and any additional or supplemental application (&ldquo;App&rdquo;), updates or enhancements We make available to You for Your use whether on media, by download or by any cloud based data processing platform and for use on any data processing device, including mobile devices (collectively, the &ldquo;Software&rdquo;). The terms also apply to any Enterprise Lens updates, supplements, customisations, Internet-based services, and any consulting or support services for the Software (&ldquo;Services&rdquo;), unless other terms are agreed specifically for the supply of those Services. <br />
<br />
SOFTWARE IS SUPPLIED FOR LICENSED USE FOR A LIMITED SUBSCRIPTION TERM. BY CLICKING ON THE &ldquo;ACCEPT&rdquo; OR &ldquo;LOGIN&rdquo; OR &ldquo;OK&rdquo; BUTTON OR SIMILAR BUTTONS OR LINKS AS MAY BE DESIGNATED BY ENTERPRISE LENS, OR BY DOWNLOADING, INSTALLING, ACCESSING, ACTIVATING OR USING THE SOFTWARE YOU AGREE TO BE BOUND BY THE TERMS OF THIS END USER LICENCE AGREEMENT (&quot;AGREEMENT&quot;). &quot;YOU&quot; AND &ldquo;YOUR&rdquo; REFERS TO THE ENTITY AND ITS USERS WHO WILL BE BOUND BY THE TERMS OF THIS AGREEMENT. IF YOU ARE AGREEING TO THIS AGREEMENT ON BEHALF OF YOUR COMPANY, GOVERNMENT, ORGANIZATION OR ANY OTHER FORM OF LEGAL ENTITY FOR WHICH YOU ARE LEGALLY AUTHORISED TO ACT (WHETHER AS AN OFFICER, EMPLOYEE OR GOVERNMENTAL OFFICIAL), THEN &ldquo;YOU&rdquo; SHALL MEAN YOUR ENTITY AND THE TERMS OF THIS AGREEMENT SHALL BE BINDING ON YOU AND YOUR ENTITY AND ITS USERS. YOU SHALL BE LIABLE FOR ANY FAILURE BY ANY USERS (AS DEFINED BELOW) TO COMPLY WITH TERMS OF THIS AGREEMENT. THESE TERMS APPLY TO EACH LICENCE AND ALL COPIES AND VERSIONS OF THE SOFTWARE.<br />
Enterprise Lens reserves the right to modify, add to, change, or remove (&ldquo;Modification(s)&rdquo;) any part of this Agreement at any time, and such Modification(s) will become effective upon notice to You. Enterprise Lens will notify You by posting notice of the Modification(s) on Enterprise Lens&rsquo;s website <a href="http://enterpriselens.com">http://enterpriselens.com</a>, and/or by notifying You by email at the email address provided by You, if any, and You consent to Enterprise Lens sending such notifications of Modification(s) to this Agreement by posting notice on Enterprise Lens&rsquo;s website <a href="http://enterpriselens.com">http://enterpriselens.com</a>, or delivery to Your email address. Except as outlined above, all Modifications to this Agreement will be effective when posted (or after a notice is sent to Your email address), and Your continued use of the Software after the sending of such notice will constitute Your acceptance of, and Your agreement to be bound by, those Modification(s). If You do not agree to (or cannot comply with) this Agreement, Your sole remedy is to stop using the Software.<br />
<br />
1. DEFINITIONS.<br />
Acceptable User Policy means any current published or notified Enterprise Lens restrictions applicable to the use of the Enterprise Lens website or Software - a copy of the policy can be found at <a href="http://enterpriselens.com">http://enterpriselens.com</a>&#59;<br />
Affiliate means any company or recognised legal entity that either party to this Agreement owns, or is owned by, or is under common ownership and control, where &ldquo;ownership and control&rdquo; means the ownership of more than 50% of shares and/or the power and authority to manage and direct business operations&#59;<br />
Agreement means this Software End User Licence Agreement and all Schedules and Attachments attached hereto and all Orders amendments or revisions agreed in writing by the Parties, and which are incorporated into this Agreement by reference&#59;<br />
App means any software application provided or authorised by Enterprise Lens for access to, and use of Software on an internet connected device using an authorised web browser, including without limitation for use on desktop PC, mobile or tablet device&#59;<br />
Attachment means an attachment appended to this Agreement&#59;<br />
Commencement Date of a Subscription Term means the date agreed in the applicable Ordering Document, or if not defined, the date on which You receive required user access credentials from Enterprise Lens, or the date You activate use, or have access to the Software&#59;<br />
Confidential Information means all proprietary technical and commercial information concerning a party&rsquo;s financial, accounting, business operations, technologies, Intellectual Property Rights, as defined herein, Personal Data disclosed by a Party to the other Party or to which a party is permitted access prior to or during the Term whether in writing, orally or otherwise and regardless of the media for which the information has been provided or the manner of access to given to the information for the purpose of disclosure where the information:<br />
(i) is marked or described as being &ldquo;confidential&rdquo;&#59; or<br />
(ii) should be reasonably understood by to be confidential&#59; or<br />
(iii) is Client Data<br />
But does not include information that<br />
(iv) is or becomes publicly known other than as a result of a breach of confidentiality&#59; or,<br />
(v) is or becomes known from a third party who is not under a confidentiality obligation with respect to the information&#59; or,<br />
(vi) is or becomes known or developed independently by a party without reference to or reliance on Confidential Information of the other party<br />
Data Protection Laws means the laws and regulations, of any territory, which apply to the use, storage, processing, security, and transfer of Personal Data and any consents required in connection with Your use of Personal Data when processed by the Software, including, but not limited to, the EU General Data Protection Regulation, and the Privacy Act 1988 (Cth) of Australia and all regulations and principles&#59;<br />
Defect means an error or bug in the Software as supplied and used as authorised by Enterprise Lens having a material adverse effect on the appearance, operation, functionality of the Software or its use as approved by Enterprise Lens&#59;<br />
Documentation means any Enterprise Lens documentation in connection with the Software and its use, as revised from time to time, including without limitation technical details, User operation instructions, installation guides, release notes, help files and Acceptable Use Policy whether provided on media or online&#59;<br />
ESA (Enterprise Lens Software Assurance) means services for telephone support, Upgrades and Updates for the Software included for a Subscription Term as more fully detailed in <a href="http://enterpriselens.com">http://enterpriselens.com</a> (&ldquo;ESA Terms and Conditions&rdquo;) and are incorporated by reference&#59;<br />
Expiration Date means the date on which all rights granted to You for a Subscription Term or a Renewal Term(s) expire&#59;<br />
Fees means fees in respect of the right granted to access and use the Software, and any other fees applicable to access to and use of a Supported Environment, Third Party Software and Services supplied by Enterprise Lens as agreed in any Order, including as the case may be Enterprise Lens Software Assurance services&#59;<br />
Intellectual Property Rights means all intellectual property rights throughout the world, whether or not registered or registrable including copyright and related moral rights, database rights, confidential information, trade secrets, know-how, business names, trade names, trademarks, service marks, patents, utility models, and design rights&#59;<br />
Order or Ordering Document means any document in a form approved by Enterprise Lens that specifies Software, Services, Users, Fees, payment terms, and any additional terms and conditions regarding installation and use of the Software. For the avoidance of doubt an Order may include any of, a purchase order, a signed proposal or an online order You submit through Enterprise Lens&rsquo; website&#59; <br />
Personal Data means personal identifying information of individuals or information and data which can be used to identify individuals as defined under applicable Data Protection Laws&#59;<br />
Product Upgrades and Updates means upgrades, updates and patches for the Software defined in Enterprise Lens Product Releases and Maintenance Policy <a href="http://enterpriselens.com">http://enterpriselens.com</a>&#59; <br />
Renewal Date means the day following the Expiration Date of a Subscription Term on which a Subscription automatically renews for a Renewal Term if not otherwise terminated in accordance with the terms of this Agreement<br />
Renewal Term means any automatic or otherwise agreed renewal or extension period of a Subscription Term&#59;<br />
Schedule means a Schedule appended to this Agreement&#59;<br />
Services means any ancillary consulting, engineering, QA, and support services performed by Enterprise Lens pursuant to an Order, or as agreed in writing by the parties&#59;<br />
Subscription Term means the period for licensed access to, and use of the Software as agreed and defined in an Order&#59;<br />
Supported Environment means Enterprise Lens approved and authorised platforms required for accessing and using the Software subject to the terms of this Agreement&#59;<br />
Term means the total period of a Subscription Term and all Renewal Term(s)&#59;<br />
Third Party Materials means software programs or applications not forming part of the Software and which may be required to enable the operation of the Software, and /or links to websites, content, data, information, images, applications or materials provided owned and managed by third party providers containing features designed to interoperate the Software with Third Party Materials&#59;<br />
Trial Period means any term determined by Enterprise Lens in its sole discretion allowing access to and use of Trial Software&#59;<br />
Trial Software means any limited functionality, trial, demonstration, complimentary, not-for-resale version of the Software Enterprise Lens may make available for a limited period&#59;<br />
Users means those employees, agents, contractors and others You authorise and provide unique login and password credentials for access to and use of the Software and Services subject to the terms of this Agreement&#59;<br />
User Content means without limitation, information, images, audio, video, Personal Data, files, illustrations, lenses, canvases and associated media You or Your Users create or allow Users to access while using the Software&#59;<br />
<br />
2. LICENCE GRANT AND USE RIGHTS.<br />
a) Licence Grant. In consideration of the applicable Fees and subject to the terms and conditions herein, Enterprise Lens grants You a non-exclusive, non-assignable, non-transferable subscription licence to use the current supported version of the Software in a Supported Environment. You and Your Users are permitted to use the Software only for a Subscription Term specified in an Ordering Document and each Renewal Term thereof for the number of Users or volume of transactions as agreed. Authorised Users may only access and use the Software using a unique username and password. Documentation is provided subject to the terms of this Agreement solely for reference purposes for using the Software. The terms and conditions of this Agreement will apply to Trial Software and any App provided or made available by Enterprise Lens, subject to any further express terms specifically for downloading, installing and using Trial Software or App.<br />
b) Account and login credentials. At all times You are responsible for maintaining the security and confidentiality of all accounts and User login credentials for access to and use of Software. Use and login credentials must not be shared with or allowed for use by third parties.<br />
c) Licence Restrictions. You may use the Software only as permitted by this Agreement, the relevant Order and the Documentation. You must not and must not assist or permit any third party to (i) modify, translate the Software&#59; (ii) publish the Software for others to copy&#59; (iii) use the Software in any way that violates applicable law including Data Protection Laws obligations with respect to Personal Data (iv) violate Enterprise Lens current published or notified Acceptable Use Policy&#59; (v) use, or deploy any third party software or services to circumvent, enable, modify or provide access, permissions or rights to work around any technical limitations in the Software&#59; (vi) reverse engineer, decompile or disassemble the Software for any purpose or otherwise attempt to access or derive source code from the Software&#59; (vii) create a derivative work from the Software&#59; (viii) make the Software available for commercial software hosting services&#59; or, (ix) sell, licence, sublicence, distribute, rent, lease, or otherwise assign, transfer or make available to any third party the right to use the Software in whole or in part granted to You for any Subscription Term or Renewal Term, or transfer or supply Software licence key or user access credentials to any third party.<br />
d) Subscription Details. Details of the Subscription Term, User number and User specific details, and the relevant applicable fees will be specified in an Ordering Document agreed in writing by the parties and which will be incorporated in and form part of this Agreement. <br />
e) Enterprise Agreement. If You purchase a licence to use the Software under an Enterprise Agreement (&ldquo;EA&rdquo;) as agreed in an Ordering Document, Enterprise Lens, subject to the terms of this Agreement, and any additional terms of the EA or the Ordering Document and payment of the applicable agreed fees, you will have the right to: <br />
i. use the Software for unlimited Illustrations and to self-manage Users&#59; and<br />
ii. build and create custom Illustrations subject to the licence granted under this Agreement, with the right to edit, create, view, and store Illustrations within Enterprise Lens (&ldquo;Developer Privileges&rdquo;)<br />
Provided however that Enterprise does not warrant a timely response for large volumes of Users or large Illustrations with high volumes of content unless subject to express terms agreed separately under the EA or in an Ordering Document<br />
f) Trial Software. Trial Software may be supplied by Enterprise Lens at no fee for use on a limited term and functionality basis as determined by Enterprise Lens in its sole discretion. At the expiration of a Trial Period, You may have the option to convert Trial Software to a Subscription Term, subject to payment of the applicable fees and the terms of this Agreement. On expiration of a Trial Period all rights to use Trial Software terminate. Your right to use Trial Software terminates upon conversion to a Subscription Term from a Trial Period. Enterprise Lens reserves the right to require written confirmation of deletion, destruction or removal of Trial Software. Trial Software is supplied on condition that You bear the entire risk of using Trial Software and reliance on any results produced. <br />
g) Named Users. For the purpose of the licensed rights granted under this Agreement Users will be deemed &ldquo;Named Users&rdquo; and as such use rights may be reassigned from one Named User to another Named User if: <br />
i. the original User&rsquo;s relationship with You has been terminated&#59;<br />
ii. the original User has been transferred to a non-licensed entity&#59; or<br />
iii. once every one hundred eighty (180) calendar days.<br />
<br />
3. SUBSCRIPTION TERM AND RENEWAL.<br />
a) Subscription Term. The minimum Subscription Term is one (1) year from the Commencement Date. Each Subscription Term commences on the Commencement Date and continues until the Expiration Date as set out in the applicable Ordering Document, provided that a Subscription Term (and a Renewal Term), will renew for a further Renewal Term(s) effective from the date following each Expiration Date in accordance with clause 3b) or You provide written notice of termination under clause 3c) or otherwise in accordance with the terms of this Agreement.<br />
b) Renewal Term(s). A Subscription Term and each Renewal Term will automatically renew annually for further one (1) year terms or for such other periodic terms as agreed in the relevant Ordering Document and You will be charged or invoiced prior to the commencement of a Renewal Term unless You have terminated with written notice in accordance with clause 3c). Enterprise Lens may modify fees for any Renewal Term with thirty (30) days&rsquo; written notice prior to the Expiration Date of a current Subscription Term or Renewal Term, provided that any increase in fees shall not take effect until the commencement of the next Renewal Term. Payment must be made by the due date for payment to avoid a lapse in the Subscription Term and ESA Services. Enterprise Lens may at its discretion and with notice immediately terminate use of the Software and supply of support if payment for a Renewal Term is not received by the due date for payment. <br />
c) Termination. You may terminate a Subscription Term or a Renewal Term by providing written notice to Enterprise Lens not less than 30 days prior to the Expiration Date of the current Subscription Term or Renewal Term. Upon termination or expiration of a Subscription Term or Renewal Term, all rights to access and use the Software terminate and You must discontinue use of the Software. Enterprise Lens reserves the right to prohibit access to and use of the Software and to require Your written confirmation that you have terminated use. If Your Subscription Term ends ESA services will terminate.<br />
<br />
4. REPORTING AND AUDITING.<br />
a) Reporting. You are responsible for maintaining accurate records of use of the Software, for at least two (2) years from the expiration of the Term. <br />
b) Monitoring. You accept that Enterprise Lens may deploy technology and procedures to monitor use of the Software solely to ensure use of the Software complies with the terms of this Agreement and is in accordance with agreed terms of an Ordering Document and the current Acceptable Use Policy. Information produced will be as far as possible, encrypted, anonymised or deidentified, and will be treated as Confidential Information, and will not be disclosed to any third party, and will be retained solely for the use of Enterprise Lens for monitoring the use and operation of the Software.<br />
c) Audit Right. Not more than once in any year, Enterprise Lens, at its own expense, may conduct an audit to confirm that Your use of the Software complies with the terms of this Agreement and agreed terms of an Ordering Document. An audit will be performed with reasonable advance notice to you and conducted during normal business hours in a manner that minimizes disruption to business operations. Without restricting remedies otherwise available to Enterprise Lens, if an audit confirms noncompliance with this Agreement or agreed terms of use, You will be required to remedy the noncompliance within ten (10) business days of receiving a notice specifying the noncompliance including, as the case may be, by payment of an invoice issued by Enterprise Lens for exceeding agreed volume of use or number of users. If the audit determines that non-compliant use of Software licence exceeds by five percent (5%) or more of the total paid for licence fees, You will bear the costs for the audit. <br />
<br />
5. INTELLECTUAL PROPERTY.<br />
a) Rights Reserved. Enterprise Lens or its licensors retain ownership and all rights, title, and interest in and to the Software and Documentation including registrations, applications, renewals, and extensions of such rights. You may not remove any titles, trademarks or trade names, copyright notices, legends, or other proprietary markings on the Software and its Documentation. You are not granted any rights to any trademarks or service marks of Enterprise Lens or its licensors. Enterprise Lens retains all rights not expressly granted to You in this Agreement. <br />
b) Enterprise Lens Marks. Images, clip art, animations, sounds, music, shapes, video clips and templates provided by Enterprise Lens solely for use or in combination with the Software and must not be modified, combined with other visual media, deleted or erased other than as expressly stated in Enterprise Lens proprietary notices. <br />
c) User Content. You own the content and data that You create or store or provide to Us to add on Your behalf within the Software. No title, interest or ownership right of any nature in or to User Content is granted, transferred or assigned to Enterprise Lens. You are solely responsible for the accuracy, quality, integrity, legality, reliability, appropriateness of User Content and use of User Content, and all Intellectual Property Rights in User Content including infringement, compliance with applicable laws and the current Enterprise Lens Acceptable Use Policy. You are solely responsible for, and shall be liable under, any penalty or third party claim for loss or damages of any kind that may arise from User Content uploaded, posted, shared, transmitted, displayed or otherwise made available through, by or to Users&rsquo; and Users&rsquo; access to and use of the Software. Enterprise Lens disclaims all liability for any loss or damage, You or a third party may suffer, or which is subject of a third party claim regardless of cause of action or theory of law and directly or indirectly relating to User Content created or generated through use of the Software by You and Users.<br />
d) Technical Information Feedback. Any feedback or ideas You provide to Enterprise Lens regarding the Software or any suggested improvements thereto which are incorporated in the Software will be the property of Enterprise Lens. You agree to assign to Enterprise Lens all right, title and interest in and to such feedback or ideas in connection with the Software. At the expense of Enterprise Lens, You agree to perform all acts reasonably requested by Enterprise Lens to perfect and register such rights. <br />
<br />
6. ORDER&#59; PAYMENT TERMS.<br />
a) Orders. All orders for the Software shall be documented in an Ordering Document or Order. An Ordering Document submitted to and received by Enterprise Lens by email or in the form of a PDF document signed digitally or electronically or submitted to Enterprise Lens&rsquo; using on-line order form is valid, enforceable and non-cancellable. In the absence of an Ordering Document, Your Order will be deemed accepted upon the earlier of the date delivery of the Software or the issue of User login credentials to activate use of the Software or issuance by Enterprise Lens of an invoice for the right to use the Software for a Subscription Term. You agree that any order of the Software is neither contingent on the delivery of future functionality or features nor dependent on oral or written public comments made by Enterprise Lens regarding future functionality or features. <br />
b) Fees. Fees for Software use shall be calculated based on Enterprise Lens&rsquo;s then current retail price list and will be agreed and specified in each applicable Ordering Document. You are responsible for paying the fees for the Software on each due date for payment. We may change the price of the Software at any time and if you have a recurring purchase, we will notify you by email, or other reasonable manner, at least 15 days before the price change. If you do not agree to the price change, you must cancel and stop using the Software before the price change takes effect. If there is a fixed term and price for your Software offer, that price will remain in force for the fixed term<br />
c) Discounts. Any discount to Fees will be offered by Enterprise Lens in its sole and absolute discretion and will be valid only if agreed in writing by Enterprise Lens. Any discount offered will not be deemed a continuing or recurring discount. <br />
d) Payment. Fees for a Subscription Term or Renewal Term are due subject to invoice and must be paid in full in advance for the purchased Subscription Term or Renewal Term otherwise periodically in advance for the duration of a Subscription Term or Renewal Term as agreed in the applicable Ordering Document. All Fees for Services will be paid subject to invoice in accordance with clause 6e).<br />
e) Payment Details. Invoiced charges are due net twenty (20) calendar days from the invoice date. Unpaid invoices are subject to a finance charge of 1.5% per month on any outstanding balance, or the maximum permitted by law, whichever is greater, plus all reasonable expenses of collection. You are responsible for providing complete, up to date, and accurate billing and contact information to Enterprise Lens and notifying Enterprise Lens of any changes to such information. Your credit card or other form of payment will be charged or otherwise debited in accordance with payment terms agreed and set forth in the Ordering Document. Enterprise Lens will store all payment information in accordance with clause 6f) (e.g., credit card number, name of cardholder, bank details) for the purpose of processing payments in accordance with the payment choice. <br />
f) Payment Information. You acknowledge and agree that Enterprise Lens processing of payments may require Enterprise Lens to process or store bank details and credit card information and Personal Data, and to transmit such data internally within Enterprise Lens or to Enterprise Lens Affiliates. Such processing, storage, and transmission shall be conducted in accordance with Enterprise Lens&rsquo;s Privacy Policy, and will use industry standard encryption technology to encrypt credit card and banking information as required for processing payments. You acknowledge that Enterprise Lens cannot guarantee absolute security for the processing and transfer of funds over the Internet. <br />
g) No Refunds or Credits. All orders for the Software are non-cancellable and all fees paid are non-refundable. If You terminate a Subscription Term prior to expiration, You acknowledge and accept that Enterprise Lens will not refund any pre-paid fees or give credits to You for any unused portion of a Subscription Term. In addition, if You have not fully paid for the then-current Subscription Term, upon termination any fees owed for the remainder of the term will become immediately due and payable.<br />
h) Taxes. Unless expressly stated otherwise, Enterprise Lens&rsquo;s Fees are stated exclusive of any compulsory supply taxes such as Goods and Services Tax (GST), Value Added Tax (VAT), levies, or other duties. You are responsible for payment of any such compulsory applicable taxes itemised in invoices issued by Enterprise Lens. Where Enterprise Lens is making a supply of Software services subject to the requirements of the Australian A New Tax System (Goods and Services Tax) Act 1999 (Cth) or equivalent legislation in another jurisdiction that imposes a compulsory tax on the supply of goods and services You confirm that Enterprise Lens can rely on the &ldquo;bill to&rdquo; name and address in the Ordering Document as being the place of supply for GST purposes, if applicable.<br />
<br />
7. SUPPORT SERVICES. <br />
a) Enterprise Lens provides online support services for the Software licensed for use under this Agreement as described at <a href="http://enterpriselens.com">http://enterpriselens.com</a>. <br />
b) Enterprise Lens Software Assurance and Support. ESA services, including, but not limited to, telephone and email support, Product Upgrades and Product Updates may be purchased with a Subscription Term. An ESA will automatically renew with the renewal of a Subscription Term for Software subject to payment of the applicable Fee from the commencement of each renewal date. Supplemental software code or related materials that Enterprise Lens provides You as part of ESA support services, paid or otherwise, are to be considered part of the Software and will be incorporated for general use in the Software. <br />
c) You agree that any technical information You provide to Enterprise Lens or which Enterprise Lens derives from supply of ESA services, may be used by Enterprise Lens without restriction for ongoing product support and development of the Software. <br />
<br />
8. INDEMNIFICATION. <br />
a) You agree to indemnify and hold Enterprise Lens and Enterprise Lens Affiliates and their respective officers, directors, agents, employees, partners, independent contractors, and licensors harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of (a) User Content created, used, transmitted, or stored by You and Your Users or which is provided to Us for the purpose of Your use of the Software, (b) use of the Software by You or any of Your Users, (c) violation of this Agreement or Acceptable Use Policy by You or one of Your Users, or (d) infringement, misappropriation or violation by You or Your Users of any Intellectual Property Rights of any third party, (e) a breach of law including without limitation Data Protection Laws applicable to You and Your Users and User Content.<br />
b) Enterprise Lens agrees to defend at its expense any cause of action brought by a third party against You or Your Users, to the extent that the cause of action is based on use of the Software as authorised by Enterprise Lens, without unauthorised modification or addition, infringes a third party&rsquo;s Intellectual Property Rights. Enterprise Lens will settle and pay the reasonable legal costs and expenses You incur in defending any such claim and damages finally awarded, provided that You notify Enterprise Lens immediately upon becoming aware of a claim and grant Enterprise Lens the power and authority to defend and settle the claim. Enterprise Lens will discharge its obligations under this indemnity if at no cost or expense to You, the Software is replaced, modified, or otherwise Enterprise Lens obtains the right for You to continue using the Software without degradation to functionality and without further infringement.<br />
<br />
9. THIRD PARTY MATERIALS, SITES AND RESOURCES<br />
a) The Software and the Enterprise Lens website may display, include or provide links to Third Party Materials or content or contain features designed to interoperate with Third Party Materials. By accessing or using Third Party Materials, You acknowledge and agree that Your access and use is subject to the applicable third party terms and conditions of use (&ldquo;Third Party Terms&rdquo;) and You are responsible for complying with applicable Third Party Terms. <br />
b) You acknowledge that Enterprise Lens makes no representation and gives no warranty or remedy of any kind and is otherwise not responsible for nor liable for Your use of or reliance on the content, accuracy, completeness, quality or any other aspect of Third Party Materials or their availability or suitability for Your or Your Users use or use by any third party. Third Party Materials and links to and interoperability with other sites or resources are provided solely as a convenience and Enterprise Lens may cease providing links at its discretion and without compensation. <br />
c) Third Party Materials and services are not available in all languages or in all countries. Enterprise Lens makes no representation that any Third Party Materials or services are appropriate, consistent, or available for use in any geographical location or in any language. To the extent You choose to access Third Party Materials or services in any location, You do so at Your own risk including but not limited to compliance with Third Party terms of use and local laws. <br />
d) Enterprise Lens, and its licensors, reserve the right to change, suspend, remove, impose limits or disable access to any Third Party Materials or service providers at any time without notice. In no event will Enterprise Lens be liable for the removal of or disabling or limiting access to Third Party Materials or services. <br />
<br />
10. LIMITED WARRANTY. <br />
a) Enterprise Lens warrants the Software and the platform on which the Software is distributed or made available for use &ldquo;as is&rdquo; will be free from known Defects. Enterprise Lens warrants that for thirty (30) days from the commencement date of a Licence Term (&ldquo;Warranty Period&rdquo;) the Software when properly installed in a Supported Environment and used in accordance with the Documentation shall conform substantially to the description and functional operation in the Documentation. If during the warranty period you notify us of a confirmed Defect, Enterprise Lens shall, at its option and cost, correct the Defect or repair or replace the Software, or refund the fees paid for the Software. This warranty will be voided if (i) the Software has not been properly installed or accessed or used at all times in accordance with the Enterprise Lens published or authorised specifications&#59; or (ii) unauthorised modification, alteration, or addition has been made to the Software or Supported Environment other than as expressly authorised or approved by Enterprise Lens or its authorised representative. <br />
b) THE EXPRESS WARRANTIES IN THIS AGREEMENT ARE THE SOLE AND EXCLUSIVE WARRANTIES GIVEN BY ENTERPRISE LENS, AND TO THE MAXIMUM EXTENT PERMITTED BY LAW NO FURTHER OR ADDITIONAL EXPRESS, IMPLIED OR STATUTORY WARRANTIES OF ANY KIND ARE GIVEN OR SHALL APPLY TO THE SOFTWARE AND SERVICES SUBJECT OF THIS AGREEMENT INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, USE, OR NONINFRINGEMENT. <br />
c) ENTERPRISE LENS DOES NOT WARRANT THAT THE SOFTWARE WILL MEET ALL YOUR REQUIREMENTS, OR THAT THE OPERATION OF THE SOFTWARE WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT ALL DEFECTS IN THE SOFTWARE WILL BE CORRECTED. YOU ACKNOWLEDGE THE SOFTWARE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PERFORMANCE PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS AND THAT ENTERPRISE LENS WILL NOT BE RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR RESULTING LOSS OR DAMAGE. <br />
d) TO THE EXTENT THAT LEGISLATION IMPOSES CONSUMER PROTECTION AND OTHER WARRANTY TERMS THAT CANNOT BE CONTRACTUALLY LIMITED OR EXCLUDED (&ldquo;STATUTORY GUARANTEES&rdquo;), THIS AGREEMENT DOES NOT OPERATE TO EXCLUDE OR LIMIT SUCH STATUTORY GUARANTEES OR THE TERMS APPLICABLE TO WARRANTIES UNDER SUCH STATUTORY GUARANTEES, AND THIS AGREEMENT WILL NOT LIMIT RIGHTS OR REMEDIES PROVIDED UNDER SUCH STATUTORY GUARANTEES.<br />
e) NOTWITHSTANDING ANY OTHER TERM OF THIS AGREEMENT, YOU ACKNOWLEDGE THAT TRIAL SOFTWARE OR ANY SOFTWARE WE SUPPLY FREE OF CHARGE IS PROVIDED TO YOU &quot;AS IS&quot; WITHOUT WARRANTY OF ANY KIND AS TO MERCHANTABILITY, FITNESS FOR PURPOSE, INFRINGEMENT, ERRORS, BUGS, DEFECTS OR MALWARE, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. ENTERPRISE LENS, ITS LICENSORS, AND RESELLERS DISCLAIM ALL LIABILITY FOR LOSS AND DAMAGES RESULTING FROM YOUR, OR YOUR USERS, OR ANY THIRD PART&rsquo;S USE OR ATTEMPTED USE OF TRIAL SOFTWARE, DEMONSTRATION AND NOT FOR RESALE SOFTWARE.<br />
<br />
11. LIMITATION OF LIABILITY AND EXCLUSION OF CONSEQUENTIAL LOSS. <br />
a) ENTERPRISE LENS LIABILITY FOR LOSS OR DAMAGE UNDER THIS AGREEMENT IN RESPECT OF ALL CLAIMS AND REGARDLESS OF THE CAUSE OF ACTION OR THEORY OF LAW IS LIMITED IN AGGREGATE TO THE TOTAL AMOUNTS PAID AND PAYABLE IN THE TWELVE (12) MONTHS PRIOR TO THE EVENT GIVING RISE TO A CLAIM. <br />
b) NEITHER PARTY WILL HAVE ANY LIABILITY UNDER ANY CLAIM ARISING OUT OF OR RELATED TO THIS AGREEMENT REGARDLESS OF THE CAUSE OF ACTION OR THEORY OF LAW FOR INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL LOSS OR DAMAGES OF ANY KIND, INCLUDING WITHOUT LIMITATION, LOSS OF USE, LOST OR CORRUPT DATA, INACCURATE DATA, OR USE OR RELIANCE ON INACCURATE DATA, LOSS OF PROFITS OR REVENUES, FAILURE OF SECURITY MECHANISMS, INTERRUPTION OR LOSS OF BUSINESS, COSTS OF REMEDIATION, OR COSTS RESULTING FROM DELAYS, EVEN IF A PARTY KNEW, SHOULD HAVE KNOWN OR BECOMES AWARE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.<br />
c) The limitations under this clause 11 apply to: (i) services, content (including code) on third party Internet sites or third party programs&#59; and (ii) claims for breach of contract, breach of warranty, guarantee or condition, strict liability, negligence, or other tort to the extent permitted by applicable law. <br />
<br />
12. HIGH RISK ACTIVITIES USE EXCLUDED <br />
a) Notwithstanding any right of use granted, you acknowledge and agree that the Software is not designed for nor supplied for use in any application or situation where failure of the Software could lead to death or serious bodily injury of any person, or to severe physical or environmental damage including aircraft or other modes of human mass transportation, nuclear or chemical facilities, life support systems, implantable medical equipment, large commercial equipment, motor vehicles, or weaponry systems (&ldquo;High-Risk Activities&rdquo;). Enterprise Lens disclaims all liability for loss or damages resulting from Your or Your users or any third party&rsquo;s use of the Software in High-Risk Activities. <br />
<br />
13. PUBLICITY. <br />
a) You consent to Enterprise Lens using Your name and logo (if any) on general marketing client lists in various forms (e.g., printed, online, etc.) and in other general marketing and sales collateral, for the sole purpose of identifying Your industry and organization as a customer of Enterprise Lens, provided that at all times, Enterprise Lens will strictly comply with any logo, branding, trademark and trade dress use guidelines and policies that You may provide from time to time. Enterprise Lens will not collect, publish, transfer or in any way deal with Personal Data in contravention of Personal Data Protection Laws and without consents required under Personal Data Protection Laws. <br />
14. TERM AND TERMINATION.<br />
a) Term. This Agreement shall be effective upon the earliest of the date (i) being the Commencement Date agreed in writing by You and Enterprise Lens (ii) the date Enterprise Lens receives payment of all relevant fees required for commencement of or activation of Your use of the Software&#59; and (iii) the date You activate or click &ldquo;ACCEPT&rdquo; or receive user credentials allowing access to and activation of the Software, and expires upon expiration of the Subscription Term and all Renewal Terms (collectively, the Term).<br />
b) Termination for breach. Enterprise Lens may terminate this Agreement immediately and without notice if You fail to comply with a material term of this Agreement that cannot be remedied. Otherwise, Enterprise Lens may terminate this Agreement with twenty (20) business days&rsquo; notice if You fail to remedy a material breach that is capable of remedy. For the purpose of this clause 14b) failure to make payment by a due date for payment shall be a material breach.<br />
<br />
15. EFFECT OF TERMINATION. <br />
a) In the event of termination, Your use of access to the Software terminates. You must cease using the Software and if requested by Enterprise Lens and delete all copies of the Software in Your possession or control, its Documentation, and any licence key or user credentials provided to You. In addition, You must remove all copies of the Software, including all back-up copies, from any and all servers, computers, and terminals on which it is installed. Enterprise Lens reserves the right to request You to certify in writing such removal of the Software. <br />
<br />
16. CONFIDENTIALITY.<br />
Subject to the terms of this clause 16 each party must:<br />
a) keep Confidential Information strictly confidential and not disclose a party&rsquo;s Confidential Information without the prior written consent of the other party, and any disclosure will subject to any conditions of consent&#59;<br />
b) use the same degree of care it uses to protect its own Confidential Information, and with not less than a reasonable degree of care&#59; and,<br />
c) not use any Confidential Information for any purpose other than as is required solely for the purpose of this Agreement.<br />
d) Confidential Information may be disclosed to a party&rsquo;s officers, employees, professional advisers, insurers, agents and contractors on a &ldquo;need to know&rdquo; basis for the purpose of the management and performance of this Agreement and provided that at all times a party will be responsible for ensuring any such person it discloses Confidential Information to accepts the obligations of confidentiality as set out in this Agreement, and remains liable for a breach of confidentiality obligations by any such person.<br />
<br />
17. PRIVACY.<br />
a) You consent to the processing of Personal Data by Enterprise Lens as required for this Agreement and use of the Software. You acknowledge you are responsible for and must obtain consents as required for use and processing of Personal Data under applicable Data Protection Laws.<br />
b) At all times the parties will comply with the requirements of applicable Data Protection Law regarding the collection, use, transfer, retention, consents and processing of Personal Data in all applicable jurisdictions. You acknowledge and agree that Enterprise Lens&rsquo;s provision of Software and any support services may require Enterprise Lens to process or store Personal Data of Your authorised Users and to transmit such data internally within Enterprise Lens or to Enterprise Lens Affiliates. Such processing, shall only be to the extent necessary for, and for the sole purpose of, enabling Enterprise Lens to perform its obligations hereunder and may involve the transfer of Personal Data to Enterprise Lens and its Affiliates and platform providers located in countries outside of the European Economic Area (&ldquo;EEA&rdquo;) including the US, which may not have data protection laws equivalent to those in the EEA and You accept responsibility for obtaining any necessary consent to the transfer of Personal Data to Enterprise Lens and Enterprise Lens Affiliates that may be located in countries outside of the EEA. Any Personal Data You provide to Enterprise Lens will be processed and stored in the manner according to Enterprise Lens&rsquo;s Privacy Policy and at all times treated confidentially and in compliance with obligations under the Data Protection Laws applicable in a relevant jurisdiction.<br />
c) Each Party must at all times maintain industry standard best practice systems security methods and processes to ensure Personal Data is processed with, at least, the level of security required for compliance with applicable Data Protection Laws <br />
d) You are responsible for Users using the Software to process Personal Data in compliance with the requirements of applicable Data Protection Laws and the obligation under this clause 17.<br />
e) Enterprise Lens will not process Personal Data other than as is required solely for the purpose of this Agreement and subject to instructions received from You. At the expiration of the Term, Enterprise Lens shall, delete or destroy or otherwise encrypt, deidentify or anonymize all Personal Data in its possession as received from You and will only retain the Personal Data if and to the extent that is obliged by law to do so.<br />
f) Each party will notify the other party of any actual or suspected data security breach that may affect or compromise Personal Data on becoming aware of the breach, and each party comply with all compulsory notification, reporting and remediation requirements and cooperate in good faith to assess, remediate and mitigate the cause and potential losses and consequence of a data security breach.<br />
<br />
18. SURVIVAL<br />
a) The following provisions shall survive termination or expiration of this Agreement 8 (Indemnification), 9 (Third Party Materials, Sites And Resources), 10 (Limited Warranty), 11 (Limitation of Liability and Exclusion of Consequential Loss, 15 (Effect of Termination), 16 (Confidentiality), 17 (Personal Data Protection) and 19 (General).<br />
<br />
19. GENERAL.<br />
a) Entire Agreement. This Agreement, and any schedule, attachment, addendum or amendment hereto, including an Ordering Document and/or the ESA Terms and Conditions, or any other documentation related to Enterprise Lens (if applicable) constitute the entire agreement for the Software and support services and supersedes all prior or contemporaneous agreements or understandings, whether oral or written, including any standard terms and conditions set forth in a purchase order and any other related documents provided by You.<br />
b) Headings. Headings under this Agreement are intended only for convenience and shall not affect the interpretation of this Agreement.<br />
c) Waiver and Modification. No failure of either party to exercise or enforce any of its rights under this Agreement will act as a continuing or recurring waiver of those rights. This Agreement may only be modified with the express written agreement of the parties.<br />
d) Severability. If any provision of this Agreement is found illegal or unenforceable, it will be enforced to the maximum extent permissible, and the legality and enforceability of the other provisions of this Agreement will not be affected.<br />
e) Export Restrictions. The Software and the platforms that comprise a Supported Environment are or may become subject to Australian or other international export laws and regulations of any country that the Software is exported from. You must comply with all domestic and international export laws and regulations that apply to the Software. These laws include restrictions on sanctioned territories, end users, and end use.<br />
f) Governing Law and jurisdiction. This Agreement shall be governed by the laws of Australia and the State of Western Australia and the parties submit without objection to the federal courts of Australia and the state of Western Australia. This Agreement will not be governed by the conflict of law rules of any jurisdiction or the United Nations Convention on Contracts for the International Sale of Goods, the application of which is expressly excluded.<br />
g) Assignment. Enterprise Lens may assign this Agreement in whole or in part in the event of a merger, acquisition, or sale of the Software by Enterprise Lens or Enterprise Lens Affiliates, notified to You and subject to your continued right to use the Software under the terms of this Agreement. You may not assign your rights under this Agreement to any third party without the prior written consent of Enterprise Lens provided that such consent will not be unreasonably withheld if any such assignee provides written agreement to comply with all of the terms of this Agreement, including agreement to meet all obligations in respect of payments due and payable to Enterprise Lens under this Agreement. Any attempt to assign this Agreement in violation of this section shall be void. Subject to the foregoing, this Agreement will be binding upon and will inure to the benefit of the parties&rsquo; permitted successors and assignees. <br />
h) Force Majeure. A party is not liable for failure to perform the party's obligations (other than payment obligations) if such failure is as a result of an unforeseen event outside the reasonable control of the obligated party (&ldquo;Force Majeure Event&rdquo;). Each party will use reasonable efforts to mitigate the effect of any such Force Majeure Event. If a Force Majeure Event continues for more than one (1) calendar month, either party may cancel any Agreement and/or Ordering Document with respect to services not yet performed or products not yet provided upon written notice. For the purpose of this clause a Force Majeure Event includes, without limitation, acts of God, changes to the law, disaster, epidemic, pandemic, explosions, fires, floods, riots, terrorist attacks, wars, failures of the internet or any public telecommunications network, hacker attacks, denial of service attacks, virus or other malicious software attacks or infections, power failures, industrial disputes including restrictions or compulsory measures imposed by a government or regulatory authority in response to a Force Majeure Event or that otherwise directly limits movement, business operations or the ability of a party to perform its obligations under this Agreement. <br />
i) Execution and Counterparts. You may execute this Agreement online using an &ldquo;Accept&rdquo; option link, or by exchange of digitally or electronically signed PDF copies executed as counterparts and all counterparts shall be deemed to be one and the same Agreement. The parties agree and consent to the use of electronic or digital signatures and confirm and acknowledge that by so signing each party&rsquo;s signatory is authorised to execute and bind both parties to the terms this Agreement. <br />
j) Upgrade. The terms of this Agreement apply to any upgraded version of the Software supplied to You. All rights of use for an earlier version of the Software terminate upon supply of an upgraded version.<br />

				</div>
			</Modal>
		);
	}
}