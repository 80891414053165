import * as React from "react";

import { isString } from "lodash";

import { AttributeTypeVisibilityMap } from "@lib/box/attribute-type";
import { Box } from "@lib/box/box"

import { TextSizing, getTextStyle } from "@lib/style/text";

import {
	BadgeProperties,
	BadgeHorizontalAlignment,
	BadgeVerticalAlignment,
	getDefaultBadgeProperties
} from "@lib/badge/badge";

import { Fitty } from "../text/Fitty";

export interface BadgeProps {
	box?: Box;
	badgeKey: string;
	badgeProperties: BadgeProperties | undefined;
	attributeTypeVisibilityMap:
		| AttributeTypeVisibilityMap
		| undefined;
	boxWidthInPixels: number;
	boxHeightInPixels: number;
}

export const Badge = (props: BadgeProps) => {
	const {
		box,
		badgeKey,
		badgeProperties,
		attributeTypeVisibilityMap,
		boxWidthInPixels,
		boxHeightInPixels,
	} = props;

	// If we don't have badge properties, there's nothing to render
	if (
		!badgeProperties ||
		!attributeTypeVisibilityMap ||
		!box ||
		!box.attributes
	) {
		return null;
	}

	// The boxes badge (none by default)
	let renderedBadge = null;

	// Get the default badge properties
	const defaultBadgeProperties = getDefaultBadgeProperties();

	// Get the actual badge properties, filling in any missing properties with
	// the defaults
	const actualBadgeProperties = {
		...defaultBadgeProperties,
		...badgeProperties,
	};

	// Get the badge text
	let badgeText = actualBadgeProperties.text
		? actualBadgeProperties.text
		: "";

	// If we don't have badge text, try and look up a value using the badge
	// attribute type
	if (!badgeText) {
		// Do we have a badge attribute to show?
		if (actualBadgeProperties.attributeType) {
			// Do we have attribute type visibilities?
			if (attributeTypeVisibilityMap) {
				// Is the attribute type visible?
				if (
					attributeTypeVisibilityMap[
						actualBadgeProperties.attributeType
					]
				) {
					// Does the box have attributes?
					if (box.attributes) {
						// Do we have an attribute entry for the attribute type?
						if (
							Object.prototype.hasOwnProperty.call(
								box.attributes,
								actualBadgeProperties.attributeType
							)
						) {
							// Get the value, or use an empty string if it's undefined
							badgeText =
								box.attributes[
									actualBadgeProperties.attributeType
								] !== undefined
									? String(box.attributes[
											actualBadgeProperties
												.attributeType
										])
									: "";
						}
					}
				}
			}
		}
	}

	let renderedBadgeText = null;

	// console.log(actualBadgeProperties)

	// The `width` and `height` properties take priority over `widthInPixels`
	// and `heightInPixels`.
	const width =  (actualBadgeProperties.width !== undefined)
		? actualBadgeProperties.width
		: actualBadgeProperties.widthInPixels;
	const height =  (actualBadgeProperties.height !== undefined)
		? actualBadgeProperties.height
		: actualBadgeProperties.heightInPixels;

	// If the width or height are strings, assume they're percentages
	// The `parseFloat()` will ignore the % sign and convert to a number. 
	const widthInPixels = !isString(width)
		? width
		: boxWidthInPixels * (parseFloat(width) / 100);
	const heightInPixels = !isString(height)
		? height
		: boxHeightInPixels * (parseFloat(height) / 100);

	// Determine the position of the badge
	let topPositionInPixels =
		(boxHeightInPixels - heightInPixels) *
		(actualBadgeProperties.verticalPositionInPercent / 100);
	let leftPositionInPixels =
		(boxWidthInPixels - widthInPixels) *
		(actualBadgeProperties.horizontalPositionInPercent / 100);

	// Do we have badge text?
	if (badgeText) {
		// Get the internal width and height of the badge (in pixels)
		const badgeInternalWidthInPixels =
			widthInPixels -
			actualBadgeProperties.borderSizeInPixels * 2;
		const badgeInternalHeightInPixels =
			heightInPixels -
			actualBadgeProperties.borderSizeInPixels * 2;

		// Build the text style
		const textStyle: React.CSSProperties = getTextStyle(
			actualBadgeProperties.textIsVertical ? badgeInternalHeightInPixels : badgeInternalWidthInPixels,
			actualBadgeProperties.textIsVertical ? badgeInternalWidthInPixels : badgeInternalHeightInPixels,
			actualBadgeProperties.textAlignment,
			actualBadgeProperties.textAlignmentPaddingInPixels,
			actualBadgeProperties.textIsVertical,
			true,
			actualBadgeProperties.fontFamily,
			actualBadgeProperties.fontStyle,
			actualBadgeProperties.textSizeInPixels,
			actualBadgeProperties.fontWeight,
			0
		);

		// How are we aligning the badge vertically?
		if (
			actualBadgeProperties.verticalAlignment ===
			BadgeVerticalAlignment.TOP
		) {
			topPositionInPixels =
				topPositionInPixels -
				Math.floor(heightInPixels / 2);
		} else if (
			actualBadgeProperties.verticalAlignment ===
			BadgeVerticalAlignment.BOTTOM
		) {
			topPositionInPixels =
				topPositionInPixels +
				Math.floor(heightInPixels / 2);
		}

		// Are we alignming the badge horizontally?
		if (
			actualBadgeProperties.horizontalAlignment ===
			BadgeHorizontalAlignment.LEFT
		) {
			leftPositionInPixels =
				leftPositionInPixels -
				Math.floor(widthInPixels / 2);
		} else if (
			actualBadgeProperties.horizontalAlignment ===
			BadgeHorizontalAlignment.RIGHT
		) {
			leftPositionInPixels =
				leftPositionInPixels +
				Math.floor(widthInPixels / 2);
		}

		renderedBadgeText =
			actualBadgeProperties.textSizing === TextSizing.AUTO ? (
				<Fitty
					style={{
						...textStyle,
						verticalAlign: "middle",
						maxWidth: widthInPixels,
						height: heightInPixels,
					}}
					text={badgeText}
					multiLine={true}
					minSize={1}
					maxSize={heightInPixels}
				/>
			) : (
				<div
					style={{
						...textStyle,
						verticalAlign: "middle",
						maxWidth: actualBadgeProperties.textIsVertical ? heightInPixels : widthInPixels,
						height: actualBadgeProperties.textIsVertical ? widthInPixels : heightInPixels,
					}}
					dangerouslySetInnerHTML={{ __html: badgeText }}
				/>
			);
	}

	// If the background image looks like it's referencing an image, wrap it in
	// a URL, otherwise just pass it through
	let backgroundImage = "";
	if (actualBadgeProperties.backgroundImage) {
		if (
			actualBadgeProperties.backgroundImage.startsWith("http://") ||
			actualBadgeProperties.backgroundImage.startsWith("https://")
		) {
			backgroundImage = `url(${actualBadgeProperties.backgroundImage}`;
		} else backgroundImage = actualBadgeProperties.backgroundImage;
	}

	// Only draw the badge if it has text or a background image
	if (renderedBadgeText || backgroundImage) {
		// Render the badge
		renderedBadge = (
			<div
				key={badgeKey}
				style={{
					display: "inline",
					position: "absolute",
					top: topPositionInPixels,
					left: leftPositionInPixels,
					width: widthInPixels,
					height: heightInPixels,
					textAlign: "center",
					color: actualBadgeProperties.textColor,
					backgroundColor: actualBadgeProperties.backgroundColor,
					backgroundImage,
					backgroundRepeat:
						actualBadgeProperties.backgroundImageRepeat,
					backgroundPosition:
						actualBadgeProperties.backgroundImagePosition,
					backgroundAttachment: "scroll", // The background image will scroll with the page.
					backgroundClip: "padding-box", // The background extends to the inside edge of the border
					backgroundOrigin: "padding-box", // The background image starts from the upper left corner of the padding edge
					backgroundSize:
						actualBadgeProperties.backgroundImageSize,
					borderColor: actualBadgeProperties.borderColor,
					borderRadius: actualBadgeProperties.borderRadius,
					borderWidth: `${actualBadgeProperties.borderSizeInPixels}px`,
					borderStyle: actualBadgeProperties.borderStyle,
				}}
			>
				{renderedBadgeText}
			</div>
		);
	}

	return renderedBadge;
};

export const MemoizedBadge = React.memo(Badge)
 