import axios, { AxiosResponse } from "axios";
import config from "../config";

const get = (): void => {
	const controller = new AbortController();

	const timeout = setTimeout(() => {
	  controller.abort();
	}, config.webServiceTimeout);
	
	const url = `${config.apiURL}heartbeat`;
	try {
		axios.get(url, { withCredentials: true, signal: controller.signal }).then((res: AxiosResponse<any>) => {
			clearTimeout(timeout);
			// success(res.data);
		}).catch((error) => {
			console.log("This is an error")
			console.log(error);
			// failure(error);
		});
	} catch (err) {
		console.log("Didn't get caught by axios");
		console.log(err);
		// failure("HTTP action failed.");
	}
};

const heartbeat = { get };

export default heartbeat;