import * as React from "react";

import { Divider, Modal, Table } from "antd";

import * as lensesLib from "@lib/lenses/lenses";
import Link from "antd/lib/typography/Link";
import { AttributeExpressionViewModel } from "@components/pages/illustration/edit-mode/AddEditPowerLensDialog/AddEditQueryDialog/hooks/useAttributeQueryRestrictions";
import { AttributeTypeMap } from "@lib/box/attribute-type";

const confirm = Modal.confirm;

interface AttributeExpressionTableProps {
	onEdit: (index: number) => void;
	onDelete: (index: number) => void;
	attributeExpressionList: AttributeExpressionViewModel[] | null;
	attributeTypes: AttributeTypeMap;
	canEdit: boolean;
	canDelete: boolean;
}

interface AttributeRow extends AttributeExpressionViewModel {
	index: number;
	attributeTypeName: string;
}

const AttributeExpressionTable : React.FC<AttributeExpressionTableProps> = (props) => {
	// Build the box type list items

	const handleEdit = (index: number) => {
		// Notify the callback that the attribute expression should be edited
		props.onEdit(index);
	};

	const handleDelete = (index: number) => {
		// Notify the callback that the attribute expression should be removed
		props.onDelete(index);
	};

	const buildRows = (
		list: AttributeExpressionViewModel[] | null
	) : AttributeRow[] => {
		// If we don't have a box map there's no list items
		if (!list) {
			return [];
		}

		// Render each of the box types
		const rows = list.map(
			(attributeExpression, index) => {
				// Get the box type
				// Build the box type list item
				const row: AttributeRow = {
					index: index,
					attributeTypeName: props.attributeTypes[attributeExpression.attributeType].name,
					attributeType: attributeExpression.attributeType,
					attributeParameter: attributeExpression.attributeParameter,
					attributeValue: attributeExpression.attributeValue,
					operator: attributeExpression.operator
				};

				return row;
			}
		);

		return rows;
	};

	const queries: AttributeRow[] = buildRows(
		props.attributeExpressionList
	);

	const queryTableColumns = [
		{
			title: "Attribute Type",
			dataIndex: "attributeTypeName",
			key: "index",
		},
		{
			title: "Operator",
			dataIndex: "operator",
			key: "index",
		},
		{
			title: "Parameter",
			dataIndex: "attributeParameter",
			key: "index",
		},
		{
			title: "Value",
			dataIndex: "attributeValue",
			key: "index",
		},
		{
			title: "Action",
			key: "index",
			render: (text: string, record: AttributeRow) => (
				<span>
					<Link onClick={() => {
						(handleEdit(record.index))
					}}>
						Edit
					</Link>
					<Divider type="vertical" />
					<Link
						onClick={() => {
							confirm({
								title:
									"Are you sure delete this Attribute Expression?",
								okText: "Yes",
								okType: "danger",
								cancelText: "No",
								zIndex: 9999999,
								onOk: () => { handleDelete(record.index) },
								onCancel: () => { },
							});
						}}
					>
						Delete
					</Link>
				</span>
			),
		},
	];

	return (
		<Table
			dataSource={queries}
			pagination={false}
			columns={queryTableColumns}
		/>
	);
}

export default AttributeExpressionTable;