import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React from "react";
import RightMenuHeading from "../../../organisms/RightMenuHeading";
import RightMenuHeadingText from "../../../organisms/RightMenuHeadingText";

export interface IllustrationDetailsMenuProps {
	showEditButton: boolean,
	showDeleteButton: boolean,
	onEditIllustrationClick: any,
	onRemoveIllustrationClick: any,
	illustrationName: string,
	illustrationDescription: string,
	createdAt: any,
	updatedAt: any,
}

export interface LabelledReadOnlyFieldProps {
	title: string,
	value: string,
}

const LabelledReadOnlyField: React.FC<LabelledReadOnlyFieldProps> = (props) => {
	return <Row
		gutter={
			6
		}
	>
		<Col
			span={
				24
			}
		>
			<span
				style={{
					color:
						"rgba(0, 0, 0, 0.45)",
				}}
			>
				{props.title}
			</span>
			<br />
			<span
				style={{
					color:
						"rgba(0, 0, 0, 0.85)",
				}}
			>
				{props.value}
			</span>
			<br />
			<br />
		</Col>
	</Row>
};

const IllustrationDetailsMenu: React.FC<IllustrationDetailsMenuProps> = (props) => {
	return <>
		<RightMenuHeading>
			<RightMenuHeadingText>Illustration Details</RightMenuHeadingText>
		</RightMenuHeading>


		<div style={{ padding: "12px" }}>
			<LabelledReadOnlyField title="Name" value={props.illustrationName} />
			<LabelledReadOnlyField title="Description" value={props.illustrationDescription} />
			<LabelledReadOnlyField title="Created At" value={props.createdAt ? new Date(
				props.createdAt
			).toLocaleString(
				"en-AU"
			) : " "} />
			<LabelledReadOnlyField title="Updated At" value={props.updatedAt ? new Date(
				props.updatedAt
			).toLocaleString(
				"en-AU"
			) : " "} />


			<Button
				block
				style={{ marginBottom: "12px" }}
				size="large"
				type="primary"
				disabled={
					!(props.showEditButton)
				}
				onClick={
					props.onEditIllustrationClick
				}
			>
				<EditOutlined /> Edit Illustration Details
			</Button>
			<Button
				block
				danger
				style={{ marginBottom: "12px" }}
				size="large"
				type="primary"
				disabled={
					!(props.showDeleteButton)
				}
				onClick={
					props.onRemoveIllustrationClick
				}
			>
				<DeleteOutlined /> Delete Illustration
			</Button>
		</div>
	</>
};

export default IllustrationDetailsMenu;