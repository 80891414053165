import * as React from "react";

import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";

import * as boxLib from "@lib/box/box";

const getCanvasBoxKeys = (flattenedBoxMap: boxLib.BoxMap): string[] => {
	return Object.keys(flattenedBoxMap).filter((boxKey: string) => {
		let includeBox = false;

		const box = flattenedBoxMap[boxKey];
		if (box) {
			const attributes = box.attributes;
			if (attributes) {
				const isCanvas = String(attributes["Is Canvas"]);

				includeBox = isCanvas
					? isCanvas.toLocaleLowerCase() === "true"
					: false;
			}
		}

		return includeBox;
	});
};

interface CanvasListProps {
	onCanvasBoxSelectionChange: (boxKey: string, selected: boolean) => void;
	flattenedBoxMap: boxLib.BoxMap | undefined;
	selectedCanvasBoxKeys: string[];
}

export class CanvasList extends React.Component<CanvasListProps> {
	shouldComponentUpdate() {
		return true;
	}

	public render() {
		const flattendBoxMap = this.props.flattenedBoxMap;

		// Do we not have boxes? If so, return an empty div
		if (!flattendBoxMap) {
			return <div></div>;
		}

		// Get the boxes that are canvases
		const canvasBoxKeys = getCanvasBoxKeys(flattendBoxMap);

		// Render the canvas boxes
		const renderedCanvasBoxes = this.renderCanvasBoxes(
			flattendBoxMap,
			canvasBoxKeys,
			this.props.selectedCanvasBoxKeys
		);

		return (
			<div
				style={{
					width: "100%",
					display: "grid",
					gridTemplateColumns: "1fr",
					gap: "4px 12px",
					padding: 0,
					margin: 0,
					overflowY: "visible",
				}}
			>
				{renderedCanvasBoxes}
			</div>
		);
	}

	private handleCanvasBoxCheckboxChange = (
		event: CheckboxChangeEvent,
		boxKey: string
	) => {
		const isSelected = event.target.checked;

		// Notify the callback that the box selection has changed
		this.props.onCanvasBoxSelectionChange(boxKey, isSelected);
	};

	private renderCanvasBoxes = (
		flattenedBoxMap: boxLib.BoxMap,
		canvasBoxKeys: string[],
		selectedCanvasBoxKeys: string[]
	) => {
		// Render each of the canvase boxes alphabetically
		const renderedCanvasBoxes = canvasBoxKeys
			.sort((keyA: string, keyB: string): number => {
				const nameA =
					flattenedBoxMap[keyA].name !== undefined
						? flattenedBoxMap[keyA].name
						: "";
				const nameB =
					flattenedBoxMap[keyB].name !== undefined
						? flattenedBoxMap[keyB].name
						: "";

				return nameA.localeCompare(nameB);
			})
			.map((boxKey) => {
				// Get the box
				const box = flattenedBoxMap[boxKey];

				// Get the box name
				const boxName = box.name;

				const isSelected =
					selectedCanvasBoxKeys.findIndex(
						(selectedBoxKey: string) => selectedBoxKey === boxKey
					) >= 0;

				// Render the canvas box
				const renderedCanvasBox = this.renderCanvasBox(
					boxKey,
					boxName,
					isSelected
				);

				return renderedCanvasBox;
			});

		return renderedCanvasBoxes;
	};

	private handleCanvasBoxSelectionChange = (
		event: CheckboxChangeEvent,
		boxKey: string
	) => {
		const isSelected = event.target.value;
		this.props.onCanvasBoxSelectionChange(boxKey, isSelected);
	};

	private renderCanvasBox = (
		boxKey: string,
		boxName: string,
		isSelected: boolean
	) => {
		// Render the attribute
		const renderedAttribute = (
			<div key={`checkbox-${boxKey}`}>
				<Checkbox
					checked={isSelected}
					onChange={(event: CheckboxChangeEvent) =>
						this.handleCanvasBoxCheckboxChange(event, boxKey)
					}
				>
					{boxName}
				</Checkbox>
			</div>
		);

		return renderedAttribute;
	};
}
