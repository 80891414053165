import React, { useEffect, useState } from "react";

import { Modal, Select } from "antd";
import * as lensesLib from "@lib/lenses/lenses";
import * as boxTypeLib from "@lib/box/box-type";
import LabelledElement from "@components/molecules/LabelledElement";

export interface BoxTypeExpression {
	key: string,
	value: lensesLib.EqualityOperator
}

export interface AddEditBoxTypeExpressionDialogProps {
	onOKButtonClick: (boxTypeExpression: BoxTypeExpression) => void;
	onCancelButtonClick: () => void;
	isAdding: boolean;
	isVisible: boolean;
	boxTypeExpression: BoxTypeExpression | null,
	boxTypes: boxTypeLib.BoxTypeMap;
}

const AddEditBoxTypeExpressionDialog: React.FC<AddEditBoxTypeExpressionDialogProps> = (props) => {
	// Get the title
	const title = props.isAdding ? "Add Box Type Expression" : "Edit Box Type Expression";

	const [value, setValue] = useState<lensesLib.EqualityOperator>(props.boxTypeExpression !== null && props.boxTypeExpression.value !== null ? props.boxTypeExpression.value : lensesLib.EqualityOperator.EQUAL);
	const [key, setKey] = useState<string>(
		props.boxTypeExpression !== null ? props.boxTypeExpression.key : 
		Object.keys(props.boxTypes)[0]
	);

	useEffect(() => {
		setValue(props.boxTypeExpression? props.boxTypeExpression.value : lensesLib.EqualityOperator.EQUAL);
		setKey(
			props.boxTypeExpression !== null ? props.boxTypeExpression.key : 
			Object.keys(props.boxTypes)[0]
		);
	}, [props.boxTypeExpression, props.boxTypes, props.isVisible])

	const handleOKButtonClick = () => {

		if (key !== undefined && value !== undefined) {
			const boxTypeExpression = { key, value };

			props.onOKButtonClick(boxTypeExpression);
		}

	};

	return <Modal
		title={title}
		open={props.isVisible}
		onOk={handleOKButtonClick}
		onCancel={props.onCancelButtonClick}
		zIndex={9999}
		width="75%"
	>
		<div>
			<div
				style={{
					width: "100%",
					display: "grid",
					gridTemplateColumns: "0.1fr 0.9fr",
					gap: "4px 12px",
					padding: 0,
					margin: 0,
					overflowY: "visible",
				}}
			>
				<LabelledElement labelText="Box Type">
					<Select
						key={`input-operator`}
						size="large"
						placeholder="Please select"
						value={key}
						onChange={(newKey: string) => {
							setKey(newKey);
						}}
						style={{ width: "100%" }}
						getPopupContainer={(node) => {
							let popupContainer: HTMLElement | null =
								window.document.documentElement;
							if (node && node.parentElement) {
								popupContainer = node.parentElement;
							}
							return popupContainer as HTMLElement;
						}}
					>
						{
							Object.keys(props.boxTypes).map((k, index) => {
								return <Select.Option key={k} value={k}>{props.boxTypes[k].name}</Select.Option>
							})

						}
					</Select>
				</LabelledElement>

				<LabelledElement labelText="Equality Operator">
					<Select
						key={`input-operator`}
						size="large"
						placeholder="Please select"
						value={value}
						onChange={(newValue: lensesLib.EqualityOperator) => { 
							setValue(newValue);
						}}
						style={{ width: "100%" }}
						getPopupContainer={(node) => {
							let popupContainer: HTMLElement | null =
								window.document.documentElement;
							if (node && node.parentElement) {
								popupContainer = node.parentElement;
							}
							return popupContainer as HTMLElement;
						}}
					>
						<Select.Option value={lensesLib.EqualityOperator.EQUAL.toString()}>{lensesLib.EqualityOperator.EQUAL}</Select.Option>
						<Select.Option value={lensesLib.EqualityOperator.NOT_EQUAL.toString()}>{lensesLib.EqualityOperator.NOT_EQUAL}</Select.Option>
					</Select>
				</LabelledElement>
			</div>

		</div>
	</Modal>
}

export default AddEditBoxTypeExpressionDialog;