import * as React from "react";

import { BoxStyleMap } from '@lib/box/box-style';

import { Property } from 'csstype';

export interface LegendProps {
	boxStyles: BoxStyleMap | undefined;
}

export class Legend extends React.Component<LegendProps> {
	public render() {
		let filteredBoxStyles: BoxStyleMap = {};

		if (this.props.boxStyles) {
			Object.keys(this.props.boxStyles).forEach((boxStyleKey) => {
				// this.props.boxStyles ? console.log(this.props.boxStyles[boxStyleKey]) : null;
				if (
					this.props.boxStyles &&
					this.props.boxStyles[boxStyleKey] &&
					this.props.boxStyles[boxStyleKey].showInLegend
				) {
					filteredBoxStyles[boxStyleKey] = this.props.boxStyles[
						boxStyleKey
					];
				}
			});
		}
		// console.log("Filtered Box Styles!");
		// console.log(filteredBoxStyles);

		return Object.keys(filteredBoxStyles).length > 0 ? (
			<div className="ant-list ant-list-split ant-list-bordered ant-list-something-after-last-item">
				{Object.keys(filteredBoxStyles).map((boxStyleKey, index) => {
					const boxStyle = filteredBoxStyles[boxStyleKey];

					const itemStyle: React.CSSProperties = {
						display: "inline-block",
						position: "relative",
						width: "25px",
						height: "25px",
						lineHeight: "0px",
						borderStyle: "solid",
						// borderWidth: BOX_BORDER_SIZE_IN_PIXELS,
						borderColor: boxStyle.properties.borderColor,
						borderRadius: `${boxStyle.properties.borderRadius}px`,
						borderWidth: `${boxStyle.properties.borderSizeInPixels}px`,
						borderTopStyle: boxStyle.properties.borderStyle ?
							boxStyle.properties.borderStyle as Property.BorderTopStyle : "none",
						borderBottomStyle: boxStyle.properties.borderStyle ?
							boxStyle.properties.borderStyle as Property.BorderTopStyle : "none",
						borderLeftStyle: boxStyle.properties.borderStyle ?
							boxStyle.properties.borderStyle as Property.BorderTopStyle : "none",
						borderRightStyle: boxStyle.properties.borderStyle ?
							boxStyle.properties.borderStyle as Property.BorderTopStyle : "none",
						color: boxStyle.properties.textColor,
						backgroundColor:
							boxStyle.properties.backgroundColor,
						backgroundImage:
							boxStyle.properties.backgroundImage && (
								boxStyle.properties.backgroundImage.startsWith("http://") ||
								boxStyle.properties.backgroundImage.startsWith("https://")
							) ?
								`url(${boxStyle.properties.backgroundImage}`
								: boxStyle.properties.backgroundImage,
						backgroundRepeat: "no-repeat",//boxStyle.properties.backgroundImageRepeat,
						backgroundPosition: "center center",//boxStyle.properties.backgroundImagePosition,
						backgroundAttachment: 'scroll', // The background image will scroll with the page.
						backgroundClip: "padding-box", // The background extends to the inside edge of the border
						backgroundOrigin: "padding-box", // The background image starts from the upper left corner of the padding edge
						backgroundSize: "contain",//boxStyle.properties.backgroundImageSize,
						// marginTop: BOX_VERTICAL_MARGIN_IN_PIXELS,
						// marginLeft: BOX_HORIZONTAL_MARGIN_IN_PIXELS,
						marginRight: "10px",
						// marginBottom: BOX_VERTICAL_MARGIN_IN_PIXELS,
						// paddingTop: BOX_VERTICAL_PADDING_IN_PIXELS,
						// paddingLeft: BOX_HORIZONTAL_PADDING_IN_PIXELS,
						// paddingRight: BOX_HORIZONTAL_PADDING_IN_PIXELS,
						// paddingBottom: BOX_VERTICAL_PADDING_IN_PIXELS,
					};

					return (
						<div className="ant-list-item" style={{ display: "grid", justifyContent: "stretch", alignContent: "stretch", gridTemplateColumns: "30px auto"}} key={`legend-item-${index}`}>
							<div style={itemStyle}></div>
							<div style={{ width: "100%", color: "#000", marginLeft: "5px", textAlign: "left" }}>
								{filteredBoxStyles[boxStyleKey].name}
							</div>
						</div>
					);
				})}
			</div>
		) : null;
	}
}
