import * as React from "react";

import { Checkbox, Input, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";

import * as valueTypeLib from "@lib/box/value-type";
import * as attributeLib from "@lib/box/attribute";
import * as attributeTypeLib from "@lib/box/attribute-type";
import * as boxLib from "@lib/box/box";
import * as boxTypeLib from "@lib/box/box-type";

interface AttributeListProps {
	onAttributeChange: (
		attributeKey: string,
		attributeValue: attributeLib.AttributeValue | undefined
	) => void;
	attributes: attributeLib.AttributeMap | undefined;
	boxTypeKey: string;
	boxTypes: boxTypeLib.BoxTypeMap | undefined;
	boxMap: boxLib.BoxMap | undefined;
	displayAssociations: boolean;
	displayNonAssociations: boolean;
	displayChangedCheckboxes?: boolean;
}

type attributeTypeValueTypeRenderFunction = (
	attributeTypeKey: string,
	attributeType: attributeTypeLib.AttributeType,
	attributeValue: attributeLib.AttributeValue | undefined
) => any;

type attributeTypeValueTypeRenderFunctionMap = {
	[key: string]: attributeTypeValueTypeRenderFunction;
};

export class AttributeList extends React.Component<AttributeListProps> {
	shouldComponentUpdate() {
		return true;
	}

	public render() {
		// Do we not have attributes or box types? If so, return an empty div
		if (!this.props.attributes || !this.props.boxTypes) {
			return <div></div>;
		}

		// Get the attribute types to render and any attributes that have been set
		const boxAttributeTypes = boxTypeLib.getBoxTypeAttributeTypeCacheForType(this.props.boxTypeKey);

		// Render the attribute types
		const renderedAttributes = this.renderAttributeTypes(
			boxAttributeTypes,
			this.props.attributes
		);

		const gridTemplateColumns = this.props.displayChangedCheckboxes
			? "1fr 3fr 0.1fr"
			: "1fr 3fr";

		return (
			<div
				style={{
					width: "100%",
					display: "grid",
					gridTemplateColumns,
					gap: "4px 12px",
					padding: 0,
					margin: 0,
					overflowY: "visible",
				}}
			>
				{renderedAttributes}
			</div>
		);
	}

	private handleDefaultAttributeInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		attributeKey: string
	) => {
		// Get the new attribute value
		// TODO: Handle different types of attribute values
		const attributeValue = event.target.value;

		// Notify the callback that the attribute has changed
		this.props.onAttributeChange(attributeKey, attributeValue);
	};

	private handleDefaultAttributeCheckboxChange = (
		event: CheckboxChangeEvent,
		attributeKey: string,
		attributeValue: attributeLib.AttributeValue | undefined
	) => {
		const actualAttributeValue = event.target.checked
			? attributeValue
			: undefined;

		// Notify the callback that the attribute has changed
		this.props.onAttributeChange(attributeKey, actualAttributeValue);
	};

	private handleChoiceAttributeInputChange = (
		value: attributeLib.AttributeValue | undefined,
		attributeKey: string
	) => {
		// Notify the callback that the attribute has changed
		this.props.onAttributeChange(attributeKey, value);
	};

	private handleChoiceAttributeCheckboxChange = (
		event: CheckboxChangeEvent,
		attributeKey: string,
		attributeValue: attributeLib.AttributeValue | undefined
	) => {
		const actualAttributeValue = event.target.checked
			? attributeValue
			: undefined;

		// Notify the callback that the attribute has changed
		this.handleChoiceAttributeInputChange(
			actualAttributeValue,
			attributeKey
		);
	};

	private handleAssociationsAttributeInputChange = (
		value: string[] | undefined,
		attributeKey: string
	) => {
		// Get the new attribute value
		const attributeValue =
			value !== undefined ? value.join(",") : undefined;

		// Notify the callback that the attribute has changed
		this.props.onAttributeChange(attributeKey, attributeValue);
	};

	private handleAssociationsAttributeCheckboxChange = (
		event: CheckboxChangeEvent,
		attributeKey: string,
		value: string[] | undefined
	) => {
		const actualAttributeValue = event.target.checked ? value : undefined;

		// Notify the callback that the attribute has changed
		this.handleAssociationsAttributeInputChange(
			actualAttributeValue,
			attributeKey
		);
	};

	private renderAttributeTypes = (
		attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
		attributeMap: attributeLib.AttributeMap | undefined
	) => {
		// If we don't have attribute types or an attribute map, there's nothing to
		// render
		if (!attributeTypes || !attributeMap) {
			return null;
		}

		// Render each of the attribute types
		const renderedAttributeTypes = Object.keys(attributeTypes)
			.sort((keyA: string, keyB: string): number => {
				const orderA =
					attributeTypes[keyA].order !== undefined
						? attributeTypes[keyA].order
						: 0;
				const orderB =
					attributeTypes[keyB].order !== undefined
						? attributeTypes[keyB].order
						: 0;

				return orderA - orderB;
			})
			.map((attributeTypeKey) => {
				// Get the attribute type name
				const attributeType = attributeTypes[attributeTypeKey];

				// Get the attribute value type
				const attributeValueType = attributeType.valueType;

				if (
					(!this.props.displayAssociations &&
						attributeValueType === "associations") ||
					(!this.props.displayNonAssociations &&
						attributeValueType !== "associations")
				) {
					return null;
				}

				// Get the attribute value
				const attributeValue = Object.prototype.hasOwnProperty.call(
					attributeMap,
					attributeTypeKey
				)
					? attributeMap[attributeTypeKey]
					: undefined;

				// Render the attribute type
				const renderedAttributeType = this.renderAttributeType(
					attributeTypeKey,
					attributeType,
					attributeValue
				);

				// console.log(`Rendered Attribute type is`);
				// console.log(renderedAttributeType);

				return renderedAttributeType;
			});

		return renderedAttributeTypes;
	};

	private renderAttributeType = (
		attributeTypeKey: string,
		attributeType: attributeTypeLib.AttributeType,
		attributeValue: attributeLib.AttributeValue | undefined
	) => {
		// The attribute type value type render function map
		const renderFunctionMap: attributeTypeValueTypeRenderFunctionMap = {
			url: this.renderDefaultAttributeType,
			file: this.renderDefaultAttributeType,
			presentation: this.renderDefaultAttributeType,
			text: this.renderDefaultAttributeType,
			calculatedField: this.renderDefaultAttributeType,
			choice: this.renderChoiceAttributeType,
			associations: this.renderAssociationAttributeType,
		};

		// Get the attribute value type
		const attributeValueType = attributeType.valueType;

		// Get the render function
		const renderFunction: attributeTypeValueTypeRenderFunction = Object.prototype.hasOwnProperty.call(
			renderFunctionMap,
			attributeValueType
		)
			? renderFunctionMap[attributeValueType]
			: this.renderDefaultAttributeType;

		// Render the attribute
		// TODO: Use different render methods for different types of attributes
		const renderedAttribute = renderFunction(
			attributeTypeKey,
			attributeType,
			attributeValue
		);

		return renderedAttribute;
	};

	private renderDefaultAttributeType = (
		attributeTypeKey: string,
		attributeType: attributeTypeLib.AttributeType,
		attributeValue: attributeLib.AttributeValue | undefined
	) => {
		// Get the attribute type name
		const attributeTypeName = attributeType.name;

		const stringAttributeValue = attributeValue
			? String(attributeValue)
			: "";
		const hasAttribute = attributeValue !== undefined;

		// Render the attribute
		const renderedAttribute = (
			<React.Fragment key={`attributeType-${attributeTypeKey}`}>
				<b
					key={`label-${attributeTypeKey}`}
					style={{ textAlign: "left", paddingTop: "4px" }}
				>
					{attributeTypeName}:
				</b>
				<Input
					key={`input-${attributeTypeKey}`}
					value={stringAttributeValue}
					type={attributeType.valueType === valueTypeLib.ValueTypeKey.Number ? "number" : "text"}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						this.handleDefaultAttributeInputChange(
							event,
							attributeTypeKey
						)
					}
				/>
				{this.props.displayChangedCheckboxes && (
					<Checkbox
						key={`checkbox-${attributeTypeKey}`}
						checked={hasAttribute}
						onChange={(event: CheckboxChangeEvent) =>
							this.handleDefaultAttributeCheckboxChange(
								event,
								attributeTypeKey,
								stringAttributeValue
							)
						}
					/>
				)}
			</React.Fragment>
		);

		return renderedAttribute;
	};

	private renderChoiceAttributeType = (
		attributeTypeKey: string,
		attributeType: attributeTypeLib.AttributeType,
		attributeValue: attributeLib.AttributeValue | undefined
	) => {
		// Get the attribute type name
		const attributeTypeName = attributeType.name;

		// Build the list of unique choices
		const choices = attributeTypeLib.getChoicesForAttributeType(
			attributeType
		);

		// Display a message if no options are available.
		if (choices.length <= 0) {
			return (
				<React.Fragment
					key={`choiceAttributeType-${attributeTypeKey}`}
				>
					<b
						key={`label-${attributeTypeKey}`}
						style={{ textAlign: "left", paddingTop: "4px" }}
					>
						{attributeTypeName}:
					</b>
					<div>No options specified in render functions.</div>
				</React.Fragment>
			);
		}

		// Add a "None" as the top choice
		choices.unshift("None");

		const hasAttribute = attributeValue !== undefined;

		// Default an empty string if we can't find the current value
		const value =
			hasAttribute &&
				choices.findIndex((choice: string) => choice === String(attributeValue)) >=
				0
				? attributeValue
				: "";

		// Generate options for the select component
		const attributeOptions = choices.map(
			(choice: string, index: number) => {
				// Special case the first "None" selection
				const value = choice === "None" && index === 0 ? "" : choice;

				return (
					<Select.Option key={choice} value={value}>
						{choice}
					</Select.Option>
				);
			}
		);

		// Render the attribute
		const renderedAttribute = (
			<React.Fragment key={`baseAttribute-${attributeTypeKey}`}>
				<b
					key={`label-${attributeTypeKey}`}
					style={{ textAlign: "left", paddingTop: "4px" }}
				>
					{attributeTypeName}:
				</b>
				<Select
					key={`input-${attributeTypeKey}`}
					size="large"
					placeholder="Please select"
					value={String(value)}
					onChange={(value: string) =>
						this.handleChoiceAttributeInputChange(
							value,
							attributeTypeKey
						)
					}
					style={{ width: "100%" }}
					getPopupContainer={(node) => {
						let popupContainer: HTMLElement | null =
							window.document.documentElement;
						if (node && node.parentElement) {
							popupContainer = node.parentElement;
						}
						return popupContainer as HTMLElement;
					}}
				>
					{attributeOptions}
				</Select>
				{this.props.displayChangedCheckboxes && (
					<Checkbox
						key={`checkbox-${attributeTypeKey}`}
						checked={hasAttribute}
						onChange={(event: CheckboxChangeEvent) =>
							this.handleChoiceAttributeCheckboxChange(
								event,
								attributeTypeKey,
								value
							)
						}
					/>
				)}
			</React.Fragment>
		);

		return renderedAttribute;
	};

	private renderAssociationReverseLookupAttributeType = (
		attributeTypeKey: string,
		attributeType: attributeTypeLib.AttributeType,
	) => {
		// Get the attribute type name
		const attributeTypeName = attributeType.name;

		// Render the attribute
		const renderedAttribute = (
			<React.Fragment key={`attributeType-${attributeTypeKey}`}>
				<b
					key={`label-${attributeTypeKey}`}
					style={{ textAlign: "left", paddingTop: "4px" }}
				>
					{attributeTypeName}
				</b>
				<span></span>
				{this.props.displayChangedCheckboxes && (
					<span></span>
				)}
			</React.Fragment>
		);

		return renderedAttribute;
	};


	private renderNameAssociationAttributeType = (
		attributeTypeKey: string,
		attributeType: attributeTypeLib.AttributeType,
		attributeValue: attributeLib.AttributeValue | undefined
	) => {
		// Get the attribute type name
		const attributeTypeName = attributeType.name;

		// Get the flattend box map
		const flattenedBoxMap = boxLib.getFlattenedBoxMap(
			this.props.boxMap,
			{}
		);

		// If we don't have a flattened box map, there's nothing to render
		if (!flattenedBoxMap) {
			return null;
		}

		const hasAttribute = attributeValue !== undefined;

		// Get the box associations
		const boxAssociations = attributeValue ? String(attributeValue) : "";

		// Use those to get the select current value
		const selectValue: string[] =
			boxAssociations.length > 0 ? boxAssociations.split(",") : [];

		const permittedAssociationBoxTypes = attributeType.permittedAssociationBoxTypes.length > 0 ? 
			attributeType.permittedAssociationBoxTypes.split(',')
			.map((val) => { return val.trim(); })
			: [];

		// Build the association options
		const associationOptions = Object.keys(flattenedBoxMap)
			.filter((boxKey) => 
				// Remove things with no name	
				!!flattenedBoxMap[boxKey].name && 
				// If we have permitted association box types, make sure they are included here
				(
					permittedAssociationBoxTypes.length  <= 0 || 
					permittedAssociationBoxTypes.indexOf(flattenedBoxMap[boxKey].boxType) > -1
				)
			)
			.sort((boxKeyA: string, boxKeyB: string) => {
				// Get the names of the boxes
				const boxNameA = flattenedBoxMap[boxKeyA].name;
				const boxNameB = flattenedBoxMap[boxKeyB].name;

				// If the order of box A is before that of box B, put box A first
				if (boxNameA < boxNameB) {
					return -1;
				}

				// If the order of box A is after that of box B, put box B first
				if (boxNameA > boxNameB) {
					return 1;
				}

				// Otherwise, don't change the order
				return 0;
			})
			.map((boxKey, index) => {
				// Get the box
				const optionBox = flattenedBoxMap[boxKey];

				return (
					<Select.Option key={`boxkey-${index}`} value={optionBox.name}>
						{optionBox.name}
					</Select.Option>
				);
			});

		return (
			<React.Fragment key={`associationAttribute-${attributeTypeKey}`}>
				<b
					key={`label-${attributeTypeKey}`}
					style={{ textAlign: "left", paddingTop: "4px" }}
				>
					{attributeTypeName}:
				</b>
				<Select
					key={`input-${attributeTypeKey}`}
					mode="multiple"
					allowClear
					filterOption={(inputValue, option) => {
						const optionBox = option && option.value !== undefined && option.value !== null ? flattenedBoxMap[option.value] : null;

						// Note: some of the box names are numbers!
						// Convert it to a string just to be sure
						const optionBoxNameAsString = optionBox != null &&
							optionBox.name !== undefined &&
							optionBox.name !== null ? String(optionBox.name) : "";

						return optionBoxNameAsString.toLowerCase().includes(inputValue.toLowerCase());
					}}
					size="large"
					placeholder="Please select"
					value={selectValue}
					onChange={(value: string[]) =>
						this.handleAssociationsAttributeInputChange(
							value,
							attributeTypeKey
						)
					}
					style={{ width: "100%" }}
					getPopupContainer={(node) => {
						let popupContainer: HTMLElement | null =
							window.document.documentElement;
						if (node && node.parentElement) {
							popupContainer = node.parentElement;
						}
						return popupContainer as HTMLElement;
					}}
				>
					{associationOptions}
				</Select>
				{this.props.displayChangedCheckboxes && (
					<Checkbox
						key={`checkbox-${attributeTypeKey}`}
						checked={hasAttribute}
						onChange={(event: CheckboxChangeEvent) =>
							this.handleAssociationsAttributeCheckboxChange(
								event,
								attributeTypeKey,
								selectValue
							)
						}
					/>
				)}
			</React.Fragment>
		);
	};


	private renderAssociationAttributeType = (
		attributeTypeKey: string,
		attributeType: attributeTypeLib.AttributeType,
		attributeValue: attributeLib.AttributeValue | undefined
	) => {
		// Get the attribute type name
		const attributeTypeName = attributeType.name;

		// Get the flattend box map
		const flattenedBoxMap = boxLib.getFlattenedBoxMap(
			this.props.boxMap,
			{}
		);

		// If we don't have a flattened box map, there's nothing to render
		if (!flattenedBoxMap) {
			return null;
		}

		const associationsType = attributeType.associationsType;

		if (associationsType && associationsType !== attributeTypeLib.AssociationType.Uuids) {
			if (associationsType === attributeTypeLib.AssociationType.NamesLookup) {
				return this.renderNameAssociationAttributeType(attributeTypeKey, attributeType, attributeValue)
			} else if (associationsType === attributeTypeLib.AssociationType.AttributeValuesLookup) {
				return this.renderDefaultAttributeType(attributeTypeKey, attributeType, attributeValue);
			} else if (associationsType === attributeTypeLib.AssociationType.UuidReverseLookup ||
				associationsType === attributeTypeLib.AssociationType.NameReverseLookup ||
				associationsType === attributeTypeLib.AssociationType.AttributeValueReverseLookup) {
				return this.renderAssociationReverseLookupAttributeType(attributeTypeKey, attributeType);
			}
		}

		const hasAttribute = attributeValue !== undefined;

		// Get the box associations
		const boxAssociations = attributeValue ? String(attributeValue) : "";

		// Use those to get the select current value
		const selectValue: string[] =
			boxAssociations.length > 0 ? boxAssociations.split(",") : [];

		const permittedAssociationBoxTypes = attributeType.permittedAssociationBoxTypes.length > 0 ? 
			attributeType.permittedAssociationBoxTypes.split(',')
			.map((val) => { return val.trim(); })
			: [];

		// Build the association options
		const associationOptions = Object.keys(flattenedBoxMap)
			.filter((boxKey) => 
				// Remove things with no name	
				!!flattenedBoxMap[boxKey].name && 
				// If we have permitted association box types, make sure they are included here
				(
					permittedAssociationBoxTypes.length  <= 0 || 
					permittedAssociationBoxTypes.indexOf(flattenedBoxMap[boxKey].boxType) > -1
				)
			)
			.sort((boxKeyA: string, boxKeyB: string) => {
				// Get the names of the boxes
				const boxNameA = flattenedBoxMap[boxKeyA].name;
				const boxNameB = flattenedBoxMap[boxKeyB].name;

				// If the order of box A is before that of box B, put box A first
				if (boxNameA < boxNameB) {
					return -1;
				}

				// If the order of box A is after that of box B, put box B first
				if (boxNameA > boxNameB) {
					return 1;
				}

				// Otherwise, don't change the order
				return 0;
			})
			.map((boxKey) => {
				// Get the box
				const optionBox = flattenedBoxMap[boxKey];

				return (
					<Select.Option key={boxKey} value={boxKey}>
						{optionBox.name}
					</Select.Option>
				);
			});

		return (
			<React.Fragment key={`associationAttribute-${attributeTypeKey}`}>
				<b
					key={`label-${attributeTypeKey}`}
					style={{ textAlign: "left", paddingTop: "4px" }}
				>
					{attributeTypeName}:
				</b>
				<Select
					key={`input-${attributeTypeKey}`}
					mode="multiple"
					allowClear
					filterOption={(inputValue, option) => {
						const optionBox = option && option.value !== undefined && option.value !== null ? flattenedBoxMap[option.value] : null;

						// Note: some of the box names are numbers!
						// Convert it to a string just to be sure
						const optionBoxNameAsString = optionBox != null &&
							optionBox.name !== undefined &&
							optionBox.name !== null ? String(optionBox.name) : "";

						return optionBoxNameAsString.toLowerCase().includes(inputValue.toLowerCase());
					}}
					size="large"
					placeholder="Please select"
					value={selectValue}
					onChange={(value: string[]) =>
						this.handleAssociationsAttributeInputChange(
							value,
							attributeTypeKey
						)
					}
					style={{ width: "100%" }}
					getPopupContainer={(node) => {
						let popupContainer: HTMLElement | null =
							window.document.documentElement;
						if (node && node.parentElement) {
							popupContainer = node.parentElement;
						}
						return popupContainer as HTMLElement;
					}}
				>
					{associationOptions}
				</Select>
				{this.props.displayChangedCheckboxes && (
					<Checkbox
						key={`checkbox-${attributeTypeKey}`}
						checked={hasAttribute}
						onChange={(event: CheckboxChangeEvent) =>
							this.handleAssociationsAttributeCheckboxChange(
								event,
								attributeTypeKey,
								selectValue
							)
						}
					/>
				)}
			</React.Fragment>
		);
	};
}
