import { Role } from '@contracts/role';

export const getRoleNameForRoleId = (roles: Role[],
  roleId: string) => {
  // The name of the role
  let roleName = '';

  // Try and find the role that has the ID we're looking for
  const roleIndex = roles.findIndex((role) => role._id === roleId);
  if (roleIndex >= 0) {
    roleName = roles[roleIndex].name;
  }

  return roleName;
}
