import { NameDtoBase, makeNameDtoBase } from "./nameDtoBase";

export interface Role extends NameDtoBase {
    abilities: string[]
};

export const makeRole = () : Role => {
    const base = makeNameDtoBase();

    return {
        ...base,
        abilities: [],
    }
};