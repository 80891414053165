import React, { useEffect, useState } from "react";

import { Modal } from "antd";

import IllustrationBreadcrumbs from "./IllustrationBreadcrumbs";
import ClientList from "./ClientList";
import ProjectList from "./ProjectList";
import IllustrationList from "./IllustrationList";
import { Illustration } from "@contracts/illustration";
import { Project } from "@contracts/project";
import { Client } from "@contracts/client";

export interface ImportTypesDialogProps {
	onOKButtonClick: (
		illustrationId: string
	) => void;
	onCancelButtonClick: () => void;
	isVisible: boolean;
}

const ImportTypesDialog: React.FC<ImportTypesDialogProps> = (props) => {
	// Get the title
	const title = `Select Illustration`;

	// TODO: Add in refresh capability for the client list
	// TODO: If there's only one client, default it

	const [client, setClient] = useState<Client | null>(null);
	const [project, setProject] = useState<Project | null>(null);
	const [illustration, setIllustration] = useState<Illustration | null>(null);

	// Clear data based on visibility
	useEffect(() => {
		setClient(null);
		setProject(null);
		setIllustration(null);

	}, [props.isVisible])

	const handleOKButtonClick = () => {
		// Notify the callback that the OK button has been clicked 
		
		const illustrationId = illustration ? illustration._id : "";

		props.onOKButtonClick(illustrationId);
	};

	const handleSelectClient = (val: Client | null) => {

		console.log("Getting called");


		// Reset the Project and Illustration
		setProject(null);
		setIllustration(null);

		setClient(val);
	};
	const handleSelectProject = (val: Project | null) => {

		// Reset the Illustration
		setIllustration(null);

		setProject(val);
	};
	const handleSelectIllustration = (val: Illustration | null) => {
		setIllustration(val);
	};

	const showClientList = client === null && project === null && illustration === null;
	const showProjectList = client !== null && project === null && illustration === null;
	const showIllustrationList = client !== null && project !== null;

	return (
		<Modal
			title={title}
			open={props.isVisible}
			onOk={handleOKButtonClick}
			onCancel={props.onCancelButtonClick}
			zIndex={9999}
			width="75%"
		>
			<IllustrationBreadcrumbs
				client={client}
				project={project}
				illustration={illustration}
				onSelectClient={handleSelectClient}
				onSelectProject={handleSelectProject}
				onSelectIllustration={handleSelectIllustration}
			/>

			{showClientList ? <ClientList onSelectClient={handleSelectClient} /> : null}
			{showProjectList ? <ProjectList onSelectProject={handleSelectProject} clientId={client._id} /> : null}
			{showIllustrationList ? <IllustrationList onSelectIllustration={handleSelectIllustration} projectId={project._id} /> : null}
		</Modal>);
};

export default ImportTypesDialog;