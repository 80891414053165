import * as React from "react";

import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";

import * as lensesLib from "@lib/lenses/lenses";

interface LensVisibilityListProps {
	onLensVisibilityChange: (lensKey: string, isVisible: boolean) => void;
	lensKeys: string[];
	lensMap: lensesLib.LensMap | undefined;
	lensVisibilityMap: lensesLib.LensVisibilityMap | undefined;
	textColor: string;
}

export class LensVisibilityList extends React.Component<
	LensVisibilityListProps
> {
	public render() {
		// console.log('LensVisibilityList')
		// console.log(this.props.lensMap)

		// Render the visibility typess
		const renderedLensVisibilities = this.renderLensVisibilities(
			this.props.lensMap,
			this.props.lensVisibilityMap
		);

		return (
			<div
				style={{
					paddingLeft: "0.75em",
				}}
			>
				{renderedLensVisibilities}
			</div>
		);
	}

	private handleLensVisibilityChange = (
		event: CheckboxChangeEvent,
		lensKey: string
	) => {
		// Get the new visibility
		// TODO: Handle different types of visibility values
		const isVisible = event.target.checked;

		// TODO: Notify the callback that the visibility has changed
		this.props.onLensVisibilityChange(lensKey, isVisible);
	};

	private renderLensVisibilities = (
		lensMap: lensesLib.LensMap | undefined,
		lensVisibilityMap: lensesLib.LensVisibilityMap | undefined
	) => {
		// If we don't have lenss, there's nothing to render
		if (!lensMap) {
			return null;
		}

		// Render each of the visibility types, sorted by order
		const renderedLensVisibilities = this.props.lensKeys
			.sort(
				(lensKeyA: string, lensKeyB: string) =>
					lensMap[lensKeyA].order - lensMap[lensKeyB].order
			)
			.map((lensKey) => {
				// Get the visibility type
				const lens = lensMap[lensKey];
				if (lens) {
					// Get the lens visibility
					const lensVisibility = lensVisibilityMap
						? lensVisibilityMap[lensKey]
						: false;

					// Render the visibility type
					const renderedLens = this.renderLensVisibility(
						lensKey,
						lens,
						lensVisibility
					);

					return renderedLens;
				}

				return null;
			});

		return renderedLensVisibilities;
	};

	private renderLensVisibility = (
		lensKey: string,
		lens: lensesLib.Lens | undefined,
		lensVisibility: boolean
	) => {
		// If we don't have a lens there's nothing to render
		if (!lens) {
			return null;
		}

		// Render the lens
		const renderedLens = (
			<div key={lensKey}>
				<Checkbox
					style={{
						color: this.props.textColor,
						paddingBottom: "0.5em",
					}}
					checked={lensVisibility}
					onChange={(event: CheckboxChangeEvent) =>
						this.handleLensVisibilityChange(event, lensKey)
					}
				>
					{lens.name}
				</Checkbox>
			</div>
		);

		return renderedLens;
	};
}
