import React from "react";
import { Modal, Select } from "antd";

import { UserRole } from "@contracts/user";
import { Client } from "@contracts/client";
import { Role } from "@contracts/role";

interface AddEditAssociatedRoleDialogProps {
	userRole: UserRole | undefined;
	clients: Client[];
	roles: Role[];
	onOKButtonClick: (userRole: any) => void;
	onCancelButtonClick: () => void;
	isVisible: boolean;
	isAddMode: boolean;
}

interface AddEditAssociatedRoleDialogState {
	userRole: UserRole;
}

const getClientSelectOptions = (clients: Client[]) => {
	// The client select options
	const clientSelectOptions: JSX.Element[] = [];

	// Build the 'All Clients' select option
	const allClientsSelectOption = (
		<Select.Option key={"*"} value={"*"}>
			All
		</Select.Option>
	);

	// Add it to our select options
	clientSelectOptions.push(allClientsSelectOption);

	// Sort the clients alphabetically
	const sortedClientIndices = clients
		.map((ClientRect, index: number) => index)
		.sort((client1Index: number, client2Index: number) =>
			clients[client1Index].name.localeCompare(clients[client2Index].name)
		);

	// Add the client select options
	sortedClientIndices.forEach((clientIndex: number) => {
		// Get the client
		const client = clients[clientIndex];

		clientSelectOptions.push(
			<Select.Option key={client._id} value={client._id}>
				{client.name}
			</Select.Option>
		);
	});

	return clientSelectOptions;
};

const getRoleSelectOptions = (roles: Role[]) => {
	// The role select options
	const roleSelectOptions: JSX.Element[] = [];

	// Build the 'All Roles' select option
	const allRolesSelectOption = (
		<Select.Option key={"*"} value={"*"}>
			All
		</Select.Option>
	);

	// Add it to our select options
	roleSelectOptions.push(allRolesSelectOption);

	// Sort the roles alphabetically
	const sortedRoleIndices = roles
		.map((RoleRect, index: number) => index)
		.sort((role1Index: number, role2Index: number) =>
			roles[role1Index].name.localeCompare(roles[role2Index].name)
		);

	// Add the role select options
	sortedRoleIndices.forEach((roleIndex: number) => {
		// Get the role
		const role = roles[roleIndex];

		roleSelectOptions.push(
			<Select.Option key={role._id} value={role._id}>
				{role.name}
			</Select.Option>
		);
	});

	return roleSelectOptions;
};

export default class AddEditAssociatedRoleDialog extends React.Component<
	AddEditAssociatedRoleDialogProps,
	AddEditAssociatedRoleDialogState
> {
	constructor(props: AddEditAssociatedRoleDialogProps) {
		super(props);

		// Set up the User Role (defaulting to All roles and All clients)
		let userRole: UserRole = {
			roleId: "*",
			clientId: "*",
		};

		// Get the user role if we're editing
		if (!props.isAddMode && props.userRole) {
			if (props.userRole.roleId) {
				userRole.roleId = props.userRole.roleId;
			}
			if (props.userRole.clientId) {
				userRole.clientId = props.userRole.clientId;
			}
		}

		// Set up our state
		this.state = {
			userRole: userRole,
		};
	}

	public componentWillReceiveProps(
		nextProps: AddEditAssociatedRoleDialogProps
	) {
		console.log("componentWillReceiveProps");
		console.log(this.props);

		// Only update if we're showing the dialog
		if (!this.props.isVisible && nextProps.isVisible) {
			// Set up the User Role (defaulting to All roles and All clients)
			let userRole: UserRole = {
				roleId: "*",
				clientId: "*",
			};

			// Get the user role if we're editing
			if (!nextProps.isAddMode && nextProps.userRole) {
				if (nextProps.userRole.roleId) {
					userRole.roleId = nextProps.userRole.roleId;
				}
				if (nextProps.userRole.clientId) {
					userRole.clientId = nextProps.userRole.clientId;
				}
			}

			console.log(nextProps);

			// Set up our state
			this.setState({
				userRole: userRole,
			});
		}
	}

	public render() {
		// Get the client and role select options
		const clientSelectOptions = getClientSelectOptions(this.props.clients);
		const roleSelectOptions = getRoleSelectOptions(this.props.roles);

		console.log("this.state.userRole");
		console.log(this.state.userRole);

		return (
			<Modal
				title={
					this.props.isAddMode
						? "Add Associated Role"
						: "Edit Associated Role"
				}
				open={this.props.isVisible}
				onOk={this.handleOKButtonClick}
				onCancel={this.props.onCancelButtonClick}
				zIndex={9999}
			>
				{this.state.userRole ? (
					<div>
						<div style={{ marginBottom: 16 }}>
							Role:
							<Select
								onChange={this.handleRoleChange}
								value={this.state.userRole.roleId}
								style={{ width: "100%" }}
								getPopupContainer={(node) => {
									let popupContainer: HTMLElement | null =
										window.document.documentElement;
									if (node && node.parentElement) {
										popupContainer = node.parentElement;
									}
									return popupContainer as HTMLElement;
								}}
							>
								{roleSelectOptions}
							</Select>
						</div>
						<div style={{ marginBottom: 16 }}>
							Client:
							<Select
								onChange={this.handleClientChange}
								value={this.state.userRole.clientId}
								style={{ width: "100%" }}
								getPopupContainer={(node) => {
									let popupContainer: HTMLElement | null =
										window.document.documentElement;
									if (node && node.parentElement) {
										popupContainer = node.parentElement;
									}
									return popupContainer as HTMLElement;
								}}
							>
								{clientSelectOptions}
							</Select>
						</div>
					</div>
				) : null}
			</Modal>
		);
	}

	private handleRoleChange = (roleId: string) => {
		// Get a copy of the user role
		const updatedUserRole = JSON.parse(JSON.stringify(this.state.userRole));

		// Set the new role of the user role
		updatedUserRole.roleId = roleId;

		// Update the state with the new user role
		this.setState({
			userRole: updatedUserRole,
		});
	};

	private handleClientChange = (clientId: string) => {
		// Get a copy of the user role
		const updatedUserRole = JSON.parse(JSON.stringify(this.state.userRole));

		// Set the new client of the user role
		updatedUserRole.clientId = clientId;

		// Update the state with the new user role
		this.setState({
			userRole: updatedUserRole,
		});
	};

	private handleOKButtonClick = () => {
		// Notify the callback that the OK button has been clicked
		this.props.onOKButtonClick(this.state.userRole);
	};
}
