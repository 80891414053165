/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { BookmarkMap } from "../CurrentIllustrationPage";

export type BookmarkListProps = {
	sidebarTextColor: string;
	onBookmarkSelect: (boxKey: string, smartPageKey: string) => void;
	lensPageMap: BookmarkMap | undefined;
};

const LensPageList = (props: BookmarkListProps) => {
	const sortedBookmarks: string[] =
		props.lensPageMap !== undefined
			? Object.keys(props.lensPageMap).sort((a: string, b: string) => {
					// Get the names of the box types
					const lensPageA: number = props.lensPageMap![a]
						.bookmarkOrder;
					const lensPageB: number = props.lensPageMap![b]
						.bookmarkOrder;

					// If the order of A is before that of  B, put A first
					if (lensPageA < lensPageB) {
						return -1;
					}

					// If the order of A is after that of  B, put B first
					if (lensPageA > lensPageB) {
						return 1;
					}

					// Otherwise, don't change the order
					return 0;
			  })
			: [];

	return (
		<ul
			style={{
				color: props.sidebarTextColor,
			}}
		>
			<React.Fragment>
				{sortedBookmarks.map((lensPageKey) => {
					const lensPage = props.lensPageMap![lensPageKey];
					return (
						<li key={lensPageKey}>
							<a
								onClick={() => {
									props.onBookmarkSelect(
										lensPage.boxKey,
										lensPage.smartPageKey
									);
								}}
								style={{
									color: props.sidebarTextColor,
								}}
							>
								{lensPage.name}
							</a>
						</li>
					);
				})}
			</React.Fragment>
		</ul>
	);
};

export default LensPageList;
