import React from "react";

import * as configurationLib from "@lib/configuration/configuration";

export type ViewMenuItemHeaderProps = {
	icon: any;
	text: string;
};

const ViewMenuItemHeader = (props: ViewMenuItemHeaderProps) => (
	<>
		<props.icon
			style={{
				color: configurationLib.DEFAULT_UI_VIEW_SIDEBAR_TEXT_COLOR,
				paddingRight: "0.5em",
			}}
		/>
		<b
			style={{
				color: configurationLib.DEFAULT_UI_VIEW_SIDEBAR_TEXT_COLOR,
			}}
		>
			{props.text}
		</b>
	</>
);

export default ViewMenuItemHeader;
