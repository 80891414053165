import axios, { AxiosResponse } from 'axios';
import * as HttpStatus from 'http-status-codes';

import config from '../config';

// The types of abilities available
export enum AbilityType {
	// View top-level pages
	ClientsView = 'ClientsView',
	SecurityView = 'SecurityView',
	SettingsView = 'SettingsView',
	ProfileView = 'ProfileView',
	// Add/edit/view/delete a client
	ClientAdd = 'ClientAdd', // DONE
	ClientView = 'ClientView', // DONE
	ClientEdit = 'ClientEdit', // DONE
	ClientDelete = 'ClientDelete', // DONE
	// View/edit/delete client details
	ClientDetailsView = 'ClientDetailsView', // DONE
	ClientDetailsEdit = 'ClientDetailsEdit', // DONE
	ClientDetailsDelete = 'ClientDetailsDelete', // DONE
	// Add/edit/view/delete a project
	ProjectAdd = 'ProjectAdd', // DONE
	ProjectView = 'ProjectView', // DONE
	ProjectEdit = 'ProjectEdit', // DONE
	ProjectDelete = 'ProjectDelete', // DONE
	// View/edit/delete project details
	ProjectDetailsView = 'ProjectDetailsView', // DONE
	ProjectDetailsEdit = 'ProjectDetailsEdit', // DONE
	ProjectDetailsDelete = 'ProjectDetailsDelete', // DONE
	// Add/edit/view/delete an illustration
	IllustrationAdd = 'IllustrationAdd', // DONE
	IllustrationView = 'IllustrationView', // DONE
	IllustrationEdit = 'IllustrationEdit', // DONE
	IllustrationDelete = 'IllustrationDelete', // DONE
	// View/edit/delete illustration details
	IllustrationDetailsView = 'IllustrationDetailsView', // DONE
	IllustrationDetailsEdit = 'IllustrationDetailsEdit', // DONE
	IllustrationDetailsDelete = 'IllustrationDetailsDelete', // DONE
	// Save/copy illustration
	IllustrationSave = 'IllustrationSave', // DONE
	IllustrationCopy = 'IllustrationCopy', // N/A
	// Undo/redo illustration actions
	IllustrationActionUndo = 'IllustrationActionUndo', // N/A
	IllustrationActionRedo = 'IllustrationActionRedo', // N/A
	// Add/view/edit/delete/move illustration boxes
	IllustrationBoxesAdd = 'IllustrationBoxesAdd', // DONE
	IllustrationBoxesView = 'IllustrationBoxesView', // DONE
	IllustrationBoxesEdit = 'IllustrationBoxesEdit', // DONE
	IllustrationBoxesDelete = 'IllustrationBoxesDelete', // DONE
	IllustrationBoxesMove = 'IllustrationBoxesMove', // DONE
	// View/edit illustration configuration
	IllustrationConfigurationView = 'IllustrationConfigurationView', // DONE
	IllustrationConfigurationEdit = 'IllustrationConfigurationEdit', // DONE
	// View/edit/import/export illustration JSON
	IllustrationJSONView = 'IllustrationJSONView', // DONE
	IllustrationJSONEdit = 'IllustrationJSONEdit', // DONE
	IllustrationJSONImport = 'IllustrationJSONImport', // DONE
	IllustrationJSONExport = 'IllustrationJSONExport', // DONE
	IllustrationExcelImport = 'IllustrationExcelImport',
	IllustrationExcelExport = 'IllustrationExcelExport',
	// View illustration version history
	IllustrationVersionHistoryView = 'IllustrationVersionHistoryView', // N/A
	// Add/view/edit/delete/ illustration types
	IllustrationTypesAdd = 'IllustrationTypesAdd', // DONE
	IllustrationTypesView = 'IllustrationTypesView', // DONE
	IllustrationTypesEdit = 'IllustrationTypesEdit', // DONE
	IllustrationTypesDelete = 'IllustrationTypesDelete', // DONE
	// Add/view/edit/delete/ illustration filters
	IllustrationFiltersAdd = 'IllustrationFiltersAdd',
	IllustrationFiltersView = 'IllustrationFiltersView',
	IllustrationFiltersEdit = 'IllustrationFiltersEdit',
	IllustrationFiltersDelete = 'IllustrationFiltersDelete',
	// View/edit illustration view sidebar (required?)
	IllustrationViewSidebarView = 'IllustrationViewSidebarView', // DONE
	IllustrationViewSidebarEdit = 'IllustrationViewSidebarEdit', // DONE
	// View/edit illustration edit sidebar (required?)
	IllustrationEditSidebarView = 'IllustrationEditSidebarView', // DONE
	IllustrationEditSidebarEdit = 'IllustrationEditSidebarEdit', // DONE

	// Perform Bulk Assocation Operations on an Illustration
	IllustrationBulkAssociationEdit = 'IllustrationBulkAssociationEdit',

	// Import/Export Child Boxes
	ImportChildBoxes = 'ImportChildBoxes',
	ExportChildBoxes = 'ExportChildBoxes',

	// Do we need abilities for the toggle buttons?
	// View/edit/delete users
	UserView = 'UserView',
	UserAdd = 'UserAdd',
	UserEdit = 'UserEdit',
	UserDelete = 'UserDelete',
	// View/edit/delete roles
	RoleView = 'RoleView',
	RoleAdd = 'RoleAdd',
	RoleEdit = 'RoleEdit',
	RoleDelete = 'RoleDelete',
}

// The key under which abilities are stored in the session
const ABILITIES_SESSION_STORAGE_KEY = 'Abilities'

// Maps ability types to string key values. If a value exists for an ability
// type and and the specified key, then the ability is available. Specifying a
// '*' key makes the ability available for all keys
export interface AbilitiesMap { [abilityTypeKey: string]: string[] }

// Determines whether the ability is permitted
export const isAbilityPermitted = (abilities: AbilitiesMap | undefined,
	abilityType: AbilityType,
	abilityKey: string): boolean => {
	// console.log(`Testing ability ${abilityType} with key ${abilityKey}`)
	// If we don't have any abilities then the ability we're checking is not
	// permitted
	if (!abilities) {
		return false;;
	}

	// If the ability doesn't exist then it's not permitted
	if (!(Object.prototype.hasOwnProperty.call(abilities, abilityType))) {
		return false;
	}

	// Get the keys of the ability type
	const abilityTypeKeys = abilities[abilityType]

	// If we don't have any ability type keys then the ability is not permitted
	if ((!abilityTypeKeys) || (abilityTypeKeys.length <= 0)) {
		return false;
	}

	// Is the ability permitted for all keys?
	if (abilityTypeKeys.findIndex((key => key === '*')) >= 0) {
		return true;
	}

	// Otherwise the ability is permitted if the key is in the abilities keys
	if (abilityTypeKeys.findIndex((key => key === abilityKey)) >= 0) {
		// console.log(`\tPermitting ability ${abilityType} with key ${abilityKey}`)
		return true;
	}

	return false;
}

export const areAllAbilitiesPermitted = (abilities: AbilitiesMap | undefined,
	abilityTypes: AbilityType[],
	abilityKey: string) => {
	// If we don't have any abilities or ability types, the ability type is not
	// permitted 
	if ((!abilities) || (abilityTypes.length <= 0)) {
		return false;
	}

	// Determine whether the ability is permitted
	const isPermitted = abilityTypes
		.reduce((reducedIsPermitted: boolean, abilityType: AbilityType): boolean => {
			reducedIsPermitted = (reducedIsPermitted
				&& isAbilityPermitted(abilities, abilityType, abilityKey))
			return reducedIsPermitted
		}, true);

	return isPermitted;
}

export const areAnyAbilitiesPermitted = (abilities: AbilitiesMap | undefined,
	abilityTypes: AbilityType[],
	abilityKey: string) => {
	// If we don't have any abilities or ability types, the ability type is not
	// permitted 
	if ((!abilities) || (abilityTypes.length <= 0)) {
		return false;
	}

	// Determine whether the ability is permitted
	const isPermitted = abilityTypes
		.reduce((reducedIsPermitted: boolean, abilityType: AbilityType): boolean => {
			reducedIsPermitted = (reducedIsPermitted
				|| isAbilityPermitted(abilities, abilityType, abilityKey))
			return reducedIsPermitted
		}, false);

	return isPermitted;
}

export const renderIfAbilityPermitted = (abilities: AbilitiesMap | undefined,
	abilityType: AbilityType,
	abilityKey: string,
	renderFunction: any): any => {
	return (isAbilityPermitted(abilities, abilityType, abilityKey))
		? renderFunction()
		: null;
}

export const renderIfAllAbilitiesPermitted = (abilities: AbilitiesMap | undefined,
	abilityTypes: AbilityType[],
	abilityKey: string,
	renderFunction: any): any => {
	return (areAllAbilitiesPermitted(abilities, abilityTypes, abilityKey))
		? renderFunction()
		: null;
}

export const renderIfAnyAbilitiesPermitted = (abilities: AbilitiesMap | undefined,
	abilityTypes: AbilityType[],
	abilityKey: string,
	renderFunction: any): any => {
	return (areAnyAbilitiesPermitted(abilities, abilityTypes, abilityKey))
		? renderFunction()
		: null;
}

export const saveAbilitiesToSessionStorage = (abilities: AbilitiesMap | undefined): void => {
	const sessionStorageAbilities = (abilities)
		? JSON.stringify(abilities)
		: ''

	sessionStorage.setItem(ABILITIES_SESSION_STORAGE_KEY, sessionStorageAbilities);
};

export const loadAbilitiesFromSessionStorage = (): AbilitiesMap | undefined => {
	const sessionStorageAbilities = sessionStorage.getItem(ABILITIES_SESSION_STORAGE_KEY)

	// console.log("abilities from session storage: " + sessionStorageAbilities);

	const abilities: AbilitiesMap | undefined = (sessionStorageAbilities)
		? JSON.parse(sessionStorageAbilities)
		: undefined;

	return abilities;
}

export type AbilitiesSuccessCallback = ((abilitiesMap: AbilitiesMap) => void);
export type AbilitiesFailureCallback = (() => void);

export const getAbilities = (success: AbilitiesSuccessCallback,
	failure: AbilitiesFailureCallback) => {
	const url = `${config.apiURL}abilities`;
	// console.log(`url=${url}`)

	axios.get(url, { withCredentials: true }).then((res: AxiosResponse<any>) => {
		if ((res.status === HttpStatus.OK) && (res.data)) {
			// console.log(res.data)
			const abilitiesMap: AbilitiesMap = res.data;
			success(abilitiesMap);
		} else {
			failure();
		}
	});
};