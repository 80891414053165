import { DtoBase, makeDtoBase } from "./dtoBase";
export interface NameDtoBase extends DtoBase {
  name: string,
};

export const makeNameDtoBase = () : NameDtoBase => {
  const dtoBase = makeDtoBase();
  
  return {
      ...dtoBase,
      name: "",
  }
};

